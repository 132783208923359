import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, message, Modal, InputNumber } from 'antd'
import { ConsoleSqlOutlined, EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'
import PopConfirmOnly from "@/utils/PopConfirmOnly";
import { baseUrl, wenjuanUrl } from '@/utils/config.js'
import QRCode from 'qrcode.react';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import Qrcode from 'qrcode.react';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const zip = new JSZip();

function App(props) {
    const url = wenjuanUrl + props.match.params.projectid + '/work/answer/' + props.match.params.templateId

    const { projectid } = useRouteMatch().params

    const onSearch = () => {
        let transfer = document.createElement('input');
        document.body.appendChild(transfer);
        transfer.value = url + '/' + store.getState().user.uid;  // 这里表示想要复制的内容
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        transfer.blur();
        message.success('复制成功')
        document.body.removeChild(transfer);
    }

    const downloadClick = () => {
        // 获取base64的图片节点
        var img = document.getElementById('qrcode')
        var url = img.toDataURL('image/png');
        var downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', url);
        downloadLink.setAttribute('download', '二维码.png');
        downloadLink.click();
    }

    const [isbool, setIsbool] = useState(false)
    const [createArr, setCreateArr] = useState([0])


    const [updataurl, setUpdataurl] = useState('1')
    let count = false

    const exPort = () => {
        const arr = []
        createArr.forEach((v, j) => {
            v = Number(v)
            for (let i = 1; i <= v; i++) {
                if (arr[j]) {
                    arr[j].push(i)
                } else {
                    arr.push([i])
                }
            }
        })
        let sarr = [[]];
        let earr = []
        for (let i = 0; i < arr.length; i++) {
            var tarr = [];
            for (let j = 0; j < sarr.length; j++) {
                for (let k = 0; k < arr[i].length; k++) {
                    if (!fenpiBools || sarr[j][0] == createArr[0] || (sarr[j].length == 0 && arr[i][k] == createArr[0])) {
                        tarr.push(sarr[j].concat(arr[i][k]));
                    }
                }
            }
            sarr = tarr;
            earr = [...earr, ...tarr]
        }
        earr = earr.map(v => {
            return v.join('-')
        })
        console.log(earr)
        earr.forEach((v, i) => {
            setTimeout(() => {
                console.log(v)
                setUpdataurl(url + '/' + v)
            }, 100 * i)
        })
    }

    let [setTime,SetTIme] = useState(null)

    useEffect(() => {
        if (updataurl == 1) {
            return
        }
        clearTimeout(setTime)
        let urls = document.getElementById('qrcode1').toDataURL('image/png').substring(22);
        zip.file( updataurl.split('/')[updataurl.split('/').length - 1] + ".png", urls, {
            base64: true,
        });
        SetTIme(setTimeout(()=>{
            if(updataurl!=1)
            zip.generateAsync({ type: "blob" }).then(function (content) {
                FileSaver.saveAs(content, "qrcodes.zip");
            });
        },1000))
    }, [updataurl])

    const [fenpiBools, setFenpiBools] = useState(false)

    return (
        <div style={{ padding: '30px' }}>
            <Modal title="批量下载二维码" visible={isbool} onOk={e => { exPort() }}
                destroyOnClose={true}
                onCancel={e => {
                    setIsbool(false)
                    setCreateArr([0])
                    setFenpiBools(false)
                }}
            >
                <Checkbox onChange={e => { setFenpiBools(e.target.checked) }}>是否分批下载</Checkbox>
                {
                    createArr.map((v, i) => {
                        return <Row style={{ marginTop: '10px' }}>
                            <Col stan='8' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>第{i + 1}级</Col>
                            <Col stan='16'>
                                <Input defaultValue={v} onChange={e => {
                                    createArr[i] = e.target.value
                                }}></Input>
                            </Col>
                        </Row>
                    })
                }
                <div style={{ marginTop: '10px', paddingLeft: '10px' }}>
                    <Button tyle='link' onClick={e => { setCreateArr([...createArr, 0]) }}>添加阶级</Button>
                </div>
            </Modal>
            <div style={{ display: 'none' }}>
                <QRCode
                    id='qrcode1'
                    value={updataurl}  //value参数为生成二维码的链接
                    size={100} //二维码的宽高尺寸
                    fgColor="#000000"  //二维码的颜色
                />
            </div>
            {//二维码 以及地址
            }
            <Row style={{ padding: '10px', background: '#fff' }}>
                <Col>
                    <div style={{ padding: '10px', border: '1px solid #f2f2f2' }}>
                        <QRCode
                            id='qrcode'
                            value={url + '/' + store.getState().user.uid}  //value参数为生成二维码的链接
                            size={100} //二维码的宽高尺寸
                            fgColor="#000000"  //二维码的颜色
                        />
                    </div>
                </Col>
                <Col flex='1' style={{ padding: '10spx', }}>
                    <p style={{ fontWeight: 600 }}>
                        问卷链接与二维码
                    </p>
                    <div style={{ margin: '10px 0' }}>
                        <Input.Search style={{ width: '500px' }} enterButton="复制" value={url + '/' + store.getState().user.uid} onSearch={onSearch} style={{ width: 200 }} />
                        <Button style={{ margin: '0 10px' }} onClick={e => { window.open(url + '/' + store.getState().user.uid) }}>打开</Button>
                    </div>
                    <div>
                        <Button onClick={downloadClick}>点击下载二维码</Button>
                        <Button onClick={e => { setIsbool(true) }} style={{ marginLeft: "10px" }}>批量下载二维码</Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default App
