import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const handleShowBack = (e, text) => {
    setModalVisible(text)
    setTaskId(e.id)
  }

  // table
  const columns = [
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '项目区域',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '门店类型',
      dataIndex: 'shopLevel',
      render: text => text ? text : '-',
      sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
    },
    {
      title: '访问员',
      dataIndex: 'principal',
      render: text => text ? text : '-',
      sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '代理人',
      dataIndex: 'agent',
      render: text => text ? text : '-',
      sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },{
      title: '二审人员',
      dataIndex: 'audit2ByName',
      render: text => text ? text : '-',
      sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
      title: '退回状态',
      dataIndex: 'taskReturnStatus',
      render: (text, record) => text?.display == '已退回' ?
        record.audit1RefusedReasonType ? '已退回-' + labelValues[record.audit1RefusedReasonType] :
          record.audit2RefusedReasonType ? '已退回-' + labelValues[record.audit2RefusedReasonType] :
            '已退回-' + labelValues[record.reviewRefusedReasonType]
        : text.display
    },
    {
      title: '门店状态',
      dataIndex: 'status',
      render: (text, record) => text ? text.display : '-',
      sorter: (a, b) => a.status.value-b.status.value,
    },
    {
      title: '二审负责人',
      dataIndex: 'audit2ByName',
      render: text => text ? text : '-',
      sorter: (a, b) => a.audit2ByName?.localeCompare(b.audit2ByName),
    },
    {
      title: '退回次数',
      dataIndex: 'refusedCount',
      render: text => text ? text : '-',
      sorter: (a, b) => a.refusedCount-b.refusedCount,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => push_page(record)}>查看</Button>
          {/* <Button size='small' type='link' onClick={e => handleShowBack(record, 'yes')}>确认退回</Button> */}
          {/* <Button size='small' type='link' onClick={e => handleShowBack(record, 'no')}>取消退回</Button> */}
          {/* <Button size='small' type='link' onClick={e => handleShowBack(record)}>退回</Button> */}
        </>
      )
    },
  ]
  //

  // 查看详情函数
  const push_page = e => {
    sessionStorage.setItem('Completed', 'true')
    store.dispatch({
      type: 'selectCache_init', value: {
        page: 'jh-checkback',
        select: {
          surveyorSelect,
          targetSelect,
          codeSelect,
          dataSource,
          backStatusSelect
        }
      }
    })
    push('/jh/' + projectid + '/check/particular/' + e.id)
  }

  const [mendianstatus, setMendianstatus] = useState(null)

  // 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()
  useEffect(() => {
    axios.get('/api/sys/dict/taskStatus')
      .then(res => {
        setMendianstatus(res.data.labelValues)
      })
  }, [])

  const [getData_flag, setGetData_flag] = useState(false)

  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)

  const confirmReturn = (record) => {
    axios.post('/api/core/jhTaskAudit/confirmReturn', record, {
      params: {
        taskId: record.id
      }
    })
    message.success('退回成功')
  }

  const getData = e => {
    const queryCondition = []
    queryCondition.push('IN:taskReturnStatus=1!2')
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (mendian) queryCondition.push('EQ:status=' + mendian)
    if (audit2ByIdSelect) queryCondition.push('EQ:audit2ById=' + audit2ByIdSelect)
    // if (backStatusSelect) queryCondition.push('EQ:taskReturnStatus='+backStatusSelect)

    axios.get('/api/core/task/list', {
      params: {
				page,
				pageSize,
        queryCondition: queryCondition.join(','),
        userId: store.getState().user.uid,
        auditFlag: 1
      }
    }).then(res => {
			setTotal(res.data.totalSize)
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  useEffect(() => {
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(resa => {
        console.log(resa)
        axios.get('/api/sys/user/list', {
          params: {
            roleCode: 'jiahua_proxy',
            pageSize: 1000000,
            groupId: res.data.userGroupId,
            status: 0,
            orgCode: 'jiahua'
          }
        }).then(ress => {
          console.log(ress)
          const user = ress.data.data.filter((v => {
            return v.id == store.getState().user.uid
          }))
          axios.get('/api/sys/user/group/org', {
            params: {
              id: user[0]?.id
            }
          })
            .then(res => {
              const count = res.data.filter(v => {
                return v.groupId == res.data.userGroupId
              })[0]?.roles.filter(v => {
                return v.code == 'jiahua_proxy'
              })
              if (count?.length > 0) {
                const visi = resa.data.data.filter(v => {
                  return v.proxyId == user[0].id
                })
                console.log(visi)
                setSurveyorOption([...visi, user[0]])
              } else {
                setSurveyorOption([...resa.data.data, ...ress.data.data])
              }
            })
        })
      })
    })
  }, [updateFlag, getData_flag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [taskStatusOption, setTaskStatusOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])
  const [audit2Option, setAudit2Option] = useState([])

  const [labelValues, setlabelValues] = useState({})
  useEffect(() => {
    axios.get('/api/sys/dict/jhTaskRefusedReasonType').then(res => {
      setlabelValues(res.data.labelValues)
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setTaskStatusOption(arr)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_checker',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setAudit2Option(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          status: 0,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_proxy',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode:'jiahua'
        }
      }).then(res => {
        setProxyOption(res.data.data)
      })
    })
  }, [])


  // select
  const [targetSelect, setTargetSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [backStatusSelect, setBackStatusSelect] = useState(null)
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [proxySelect, setProxySelect] = useState(null)
  const [audit2ByIdSelect, setAudit2ByIdSelect] = useState(null)

  const clearSelect = e => {
    setAudit2ByIdSelect(null)
    setTargetSelect('')
    setCodeSelect('')
    setMendian(null)
    setBackStatusSelect(null)
    setSurveyorSelect(null)
    setProxySelect(null)
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setRemark('')
    setTaskId(null)
  }
  const [remark, setRemark] = useState('')
  // 退回
  const [taskId, setTaskId] = useState(null)
  const handleBack = e => {
    axios.post('/api/core/jhTaskAudit/confirmReturn', {
      confirmRefusedReason: remark
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    }).catch(err => {
      message.error('订单缺少信息，无法确认退回')
    })
  }
  //取消退回
  const nohandleBack = e => {
    axios.post('/api/core/jhTaskAudit/cancelReturn', {
      cancelRefusedReason: remark
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //批量退回
  const handleBackMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/jhTaskAudit/batchConfirmReturn', {
      confirmRefusedReason: remark
    }, {
      params: {
        taskIds: selectedRowKeys.join(','),
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    }).catch(err => {
      message.error('订单缺少信息，无法确认退回')
    })
  }
  //安排判断
  const handleDoBack = e => {
    if (modalVisible === 'yes') handleBack()
    if (modalVisible === 'no') nohandleBack()
    if (modalVisible === 'pi') handleBackMore()
  }

  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:taskReturnStatus=1!2')
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (backStatusSelect) queryCondition.push('LIKE:backStatusSelect=' + backStatusSelect)

    axios.get('/api/core/jhTaskAudit/export', {
      params: {
				page,
				pageSize,
        queryCondition: queryCondition.join(','),
        taskAuditExportType: 2
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出退回单.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  const [mendian, setMendian] = useState(null)

	const [pageSize,setPageSize] = useState(5)//显示数据数量
	const [page,setPage] = useState(1)//数据页数
	const [total,setTotal] = useState(null)//数据总是
  
	useEffect(()=>{
	  getData()
	},[pageSize,page])

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研对象</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
          </Col>
          {/* <Col span={8}>
            <span style={{ lineHeight: '30px' }}>退回状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={backStatusSelect} onChange={e => setBackStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                taskStatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col> */}
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>代理人</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                proxyOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>二审人员</span>
            <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '10px' }} value={audit2ByIdSelect} onChange={e => setAudit2ByIdSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
              {
                audit2Option.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>门店状态</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '240px', marginLeft: '10px' }} value={mendian} onChange={e => setMendian(e)}>
              {
                mendianstatus != null ? Object.keys(mendianstatus).map((v, index) => (
                  <Select.Option key={index} value={v}>{mendianstatus[v]}</Select.Option>
                ))
                  : null
              }
            </Select>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            {/* <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('pi')}>批量退回</Button> */}
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
					pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
						setPage(page)
						setPageSize(pageSize)
					  }
					  }}
        />

        <Modal
          width={640}
          title={modalVisible == 'yes' ? '确认' : modalVisible == 'no' ? '取消 ' : '批量' + '退回门店'}
          visible={modalVisible === 'yes' || modalVisible === 'pi' || modalVisible === 'no'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleDoBack}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}>{modalVisible == 'yes' ? '确认' : modalVisible == 'no' ? '取消 ' : '确认'}退回理由:</Col>
            <Col span={14}>
              <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
            </Col>
          </Row>
        </Modal>

      </div>
    </div>
  )
}

export default App