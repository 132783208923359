import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import PopConfirmOnly from '@/utils/PopConfirmOnly'


function App(props) {

  const { push } = useHistory()
  // table
  const [columns, setColumns] = useState([
    {
      title: '模板名称',
      dataIndex: 'projectTemplateName',
      key: 'projectTemplateName'
    },
    {
      title: '备注/描述',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => push('/home/set/projecttemplatedetail/' + record.id)}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  const [dataSource, setDataSource] = useState()
  const getData = e => {
    axios.get('/api/core/projectTemplate/list', {
      params: {
        page,
        pageSize,
      }
    }).then(res => {
      setTotal(res.data.totalSize)
      setDataSource(res.data.data)
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])

  useEffect(() => {
    getData()
  }, [])

  //删除
  const handleDelete = e => {
    axios.delete('/api/core/projectTemplate/' + e).then(res => {
      message.success()
      getData()
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button type='primary' onClick={e => push('/home/set/projecttemplatedetail/add')}><PlusOutlined />添加模板</Button>
          </Col>
          <Col>
            {/*<Button>导出列表</Button>*/}
          </Col>
        </Row>
        <Table dataSource={dataSource} columns={columns} pagination={{  defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
          setPage(page)
          setPageSize(pageSize)
          }
         }} />
      </div>
    </div>
  )
}

export default App