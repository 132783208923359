import React, { useEffect, useRef, useState } from 'react';
import { HashRouter, Switch, Route, useHistory } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Tabs, List, Typography, Avatar } from 'antd'
import DayHandle from "./component/DayHandle";
import BottomHandle from "./component/BottomHandle"
import LampHandle from "./component/LampHandle"

import Particulars from "../work/component/Particulars";
import compatStyle from "echarts/src/preprocessor/helper/compatStyle";

function App(props) {

    //切换选择栏
    const { TabPane } = Tabs;

    const [curTab, setCurTab] = useState('1','day')

    //日期选择框
    const [dateSelect, setDateSelect] = useState([])
    //列表数据
    const data = [
        {
            title: '北蔡镇'
        },
        {
            title: '蔡徐坤镇'
        },
        {
            title: '王俊凯镇'
        },
        {
            title: '唱跳rap篮球镇'
        },
        {
            title: '卢本伟广场'
        },
        {
            title: '卢本伟街道'
        },
        {
            title: '全体起立'
        }
    ]
    const tabsoncheng = e => {
        setCurTab(e)
    }
    return (

        <div style={{ padding: '30px' }}>
            <Row gutter={[20, 20]} >
                <Col span={18}>
                    <div style={{ background: 'white', padding: '20px' }} >
                        <div style={{ fontSize: '20px', fontWeight: 'bold' }}>项目实时处理情况</div>
                        <Divider />
                        <Row gutter={[24, 24]} style={{ marginLeft: '60px' }}>
                            <Col span={6}>
                                <div style={{ width: '160px', height: '58px' }}>
                                    <div style={{ opacity: '0.6', fontSize: '14px' }}>今日项目完成总数</div>
                                    <div style={{ fontSize: '24px', marginTop: '10px' }}>124,543,233</div>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div style={{ width: '160px', height: '58px' }}>
                                    <div style={{ opacity: '0.6', fontSize: '14px' }}>项目目标完成率</div>
                                    <div style={{ fontSize: '24px', marginTop: '10px' }}>95%</div>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div style={{ width: '160px', height: '58px' }}>
                                    <div style={{ opacity: '0.6', fontSize: '14px' }}>活动剩余时间</div>
                                    <div style={{ fontSize: '24px', marginTop: '10px' }}>01:10:12</div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div style={{ width: '160px', height: '58px' }}>
                                    <div style={{ opacity: '0.6', fontSize: '14px' }}>在线访问员总数</div>
                                    <div style={{ fontSize: '24px', marginTop: '10px' }}>124</div>
                                </div>
                            </Col>

                        </Row>
                        <div style={{ height: '500px', width: '100%' }}>

                        </div>
                    </div>
                </Col>

                <Col span={6}>
                    <div style={{ background: 'white', height: '338px', marginBottom: '20px', padding: '20px' }}>
                        <nav style={{ fontSize: '20px', fontWeight: 'bold' }}>每日项目情况预测</nav>

                        <Divider />
                        <nav style={{ opacity: '0.6', fontSize: '14px', marginLeft: '30px' }}>当日目标评估</nav>

                        <nav style={{ fontSize: '20px', marginLeft: '30px', marginTop: '5px', fontWeight: 'bold' }}>有望达到预期</nav>

                        <DayHandle />
                    </div>

                    <div style={{ background: 'white', height: '338px', padding: '20px'}}>
                        <nav style={{ fontSize: '20px', fontWeight: 'bold' }}>项目合格率</nav>
                        <Divider />
                        <LampHandle/>
                    </div>
                </Col>

                <Col span={24}>
                    <div style={{ background: 'white', height: '450px', padding: '20px' }}>
                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <Tabs style={{ background: '#fff' }} onChange={e => tabsoncheng(e)}>
                                    <TabPane style={{ background: '#eee' }} tab="访问员项目完成量" key="1" >

                                    </TabPane>
                                    <TabPane style={{ background: '#eee'}} tab="代理项目完成量" key="2">
                                    </TabPane>
                                </Tabs>

                            </Col>
                            <Col span={6}>
                                <Tabs style={{ background: '#fff' }} onChange={e => tabsoncheng(e)}>
                                    <TabPane style={{ background: '#eee' }} tab="今日" key="day" >
                                    </TabPane>
                                    <TabPane style={{ background: '#eee' }} tab="本周" key="week">
                                    </TabPane>
                                    <TabPane style={{ background: '#eee' }} tab="本月" key="month">
                                    </TabPane>
                                    <TabPane style={{ background: '#eee' }} tab="本年" key="year">
                                    </TabPane>
                                </Tabs>
                            </Col>
                            <Col span={6} style={{ textAlign: 'left' }}>
                                <DatePicker.RangePicker style={{ width: '240px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
                                <Divider style={{ marginTop: '12px' }} />
                            </Col>
                            <Col span={18}>
                                <BottomHandle curTab={curTab}/>
                            </Col>
                            <Col span={6}>
                                <List
                                    size={'small'}
                                    itemLayout="horizontal"
                                    dataSource={data}
                                    header={'Year past Year'}
                                    split={false}
                                    style={{fontSize:'15px'}}
                                >
                                    {
                                        data.map((v, i) => {
                                            return (<List.Item>
                                                    <div style={{ width: '20px', height: '20px', border: i < 3 ? '1px solid black' : '', borderRadius: '50%', textAlign: 'center', fontWeight: 'bold', color: i < 3 ? 'white' : 'black', background: i < 3 ? 'black' : 'rgba(229, 229, 229, 1)' }}>{i + 1}</div>
                                                    <div style={{ align: 'left' }}>{v.title}</div>
                                                    <div>A+</div>
                                                </List.Item>
                                            )
                                        })
                                    }
                                </List>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default App