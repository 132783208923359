import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, InputNumber, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'

const { Option } = Select;


function App(props) {

	const { push } = useHistory()
	const { projectid } = useRouteMatch().params

	const handleShowBack = e => {
		setModalVisible('back')
		setTaskId(e.id)
	}
	const handleShowPass = e => {
		setModalVisible('pass')
		setTaskId(e.id)
	}

	const [isbool, setIsbool] = useState(false)

	const JhCheckScord = (e) => {
		axios.get('/api/core/task/genJhCheckScore/' + e.id)
			.then(res => {
				message.success()
			})
	}

	// table
	const columns = [
		{
			title: '调研编号',
			dataIndex: 'surveyCode',
			render: text => text ? text : '-',
			sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
		},
		{
			title: '调研大区',
			dataIndex: 'surveyScope',
			render: text => text ? text : '-',
			sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
		},
		{
			title: '访问员',
			dataIndex: 'principal',
			render: text => text ? text : '-',
			sorter: (a, b) => a.principal?.localeCompare(b.principal),
		},
		{
			title: '代理人',
			dataIndex: 'agent',
			render: text => text ? text : '-',
			sorter: (a, b) => a.agent?.localeCompare(b.agent),
		},
		{
			title: '门店类型',
			dataIndex: 'shopLevel',
			render: text => text ? text : '-',
			sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
		},
		{
			title: '调研对象',
			dataIndex: 'surveyTarget',
			render: text => text ? text : '-',
			sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
		},
		{
			title: '门店地址',
			dataIndex: 'startPointDesc',
			render: text => text ? text : '-',
			sorter: (a, b) => a.startPointDesc?.localeCompare(b.startPointDesc),
		},
		{
			title: '一审状态',
			dataIndex: 'taskAudit1Status',
			render: text => text.display ? text.display : '-'
		},
		{
			title: '木糖醇标记',
			dataIndex: 'xylitolFlag',
			key: 'xylitolFlag',
			render: (text, record) => (
				<>
					{
						text ? text : '- -'
					}
				</>
			)
		},
		{
			title: '总退回次数',
			dataIndex: 'refusedCount',
			render: text => text ? text : '0',
			sorter: (a, b) => a.refusedCount-b.refusedCount,
		},
		{
			title: '操作',
			dataIndex: 'action',
			key: 'action',
			render: (text, record) => (<>
				<Button size='small' type='link' onClick={e => push_page(record)} >查看</Button>
				<Divider type='vertical' />
				<Button size='small' type='link' onClick={e => handleShowPass(record)} >通过</Button>
				<Divider type='vertical' />
				<Button size='small' type='link' onClick={e => handleShowBack(record)} >退回</Button>
				{
					isbool ? <>
						<Divider type='vertical' />
						<Button size='small' type='link' onClick={e => JhCheckScord(record)} >计算得分</Button>
					</> : null
				}
			</>
			)
		}
	]

	//// 查看详情函数
	const push_page = e => {
		store.dispatch({
			type: 'selectCache_init', value: {
				page: 'jh-checkfirst',
				select: {
					surveyorSelect,
					scopeSelect,
					dsrSelect,
					codeSelect,
					dataSource,
					startPointDesc,
					firstStatusSelect
				}
			}
		})
		push('/jh/' + projectid + '/check/particular/' + e.id)
	}

	// 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()
	useEffect(() => {
		if (store.getState().selectCache.page === 'jh-checkfirst') {
			const data = store.getState().selectCache.select
			Promise.all([
				new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
				new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
				new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
				new Promise(r => setCodeSelect(data.codeSelect, () => r())),
				new Promise(r => setStartPointDesc(data.startPointDesc, () => r())),
				new Promise(r => setFirstStatusSelect(data.firstStatusSelect, () => r())),
			]).then(res => {
				setGetData_flag(true)
				store.dispatch({ type: 'selectCache_clear' })
			})
		} else {
			setGetData_flag(true)
			store.dispatch({ type: 'selectCache_clear' })
		}
	}, [])

	const [getData_flag, setGetData_flag] = useState(false)
	const [dataSource, setDataSource] = useState([])
	const [updateFlag, setUpdateFlag] = useState(0)
	const getData = e => {
		const queryCondition = []
		queryCondition.push('IN:taskAudit1Status=0!1')
		queryCondition.push('IN:status=2!21')
		queryCondition.push('EQ:projectId=' + projectid)
		if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
		if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
		if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
		if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
		if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
		if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
		if (startPointDesc) queryCondition.push('LIKE:startPointDesc=' + startPointDesc)
		if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)

		axios.get('/api/core/task/list', {
			params: {
				page,
				pageSize,
				queryCondition: queryCondition.join(','),
				userId: store.getState().user.uid,
				auditFlag: 1
			}
		}).then(res => {
			setTotal(res.data.totalSize)
			const arr = res.data.data.map(i => { return { ...i, key: i.id } })
			setDataSource(arr)
		})
	}

	// const [shopStatus,setShopStatus] = useState([])
	const [reasonType, setReasonType] = useState([])

	useEffect(() => {
		// axios.get('/api/sys/dict/shopStatus')
		// .then(res=>{
		// 	setShopStatus(res.data.labelValues)
		// })
		axios.get('/api/sys/dict/jhTaskRefusedReasonType')
			.then(res => {
				setReasonType(res.data.labelValues)
			})
		if (store.getState().user.uid == null) {
			message.error('重新进入页面,请勿刷新')
			return
		}
		getData()
		axios.get('/api/core/project/' + projectid).then(res => {
			axios.get('/api/core/project/' + projectid).then(res => {
				axios.get('/api/sys/user/list', {
					params: {
						roleCode: 'jiahua_vistor',
						pageSize: 1000000,
						groupId: res.data.userGroupId,
						orgCode: 'jiahua'
					}
				}).then(resa => {
					axios.get('/api/sys/user/list', {
						params: {
							roleCode: 'jiahua_proxy',
							pageSize: 1000000,
							groupId: res.data.userGroupId,
							orgCode: 'jiahua'
						}
					}).then(ress => {
						const user = ress.data.data.filter((v => {
							return v.id == store.getState().user.uid
						}))
						axios.get('/api/sys/user/group/org', {
							params: {
								id: user[0]?.id
							}
						})
							.then(resc => {
								const count = resc.data.filter(v => {
									return v.groupId == res.data.userGroupId
								})[0]?.roles.filter(v => {
									return v.code == 'jiahua_proxy'
								})
								console.log(count)
								if (count?.length > 0) {
									const visi = resa.data.data.filter(v => {
										return v.proxyId == user[0].id
									})
									console.log(visi)
									setSurveyorOption([...visi, user[0]])
								} else {
									setSurveyorOption([...resa.data.data, ...ress.data.data])
								}
							})
					})
				})
			})
			axios.get('/api/sys/user/group/org', {
				params: {
					id: store.getState().user.uid
				}
			})
				.then(res => {
					res.data.forEach((v, i) => {
						if (v.groupId == res.data.userGroupId) {
							v.roles.forEach((v, i) => {
								if (v.name == '家化_管理员') {
									setIsbool(true)
								}
							});
						} else if (v.roles.length != 0 && v.roles[0].name == '超级管理员') {
							setIsbool(true)
						}
					});
				})
		})
	}, [store.getState().user.uid, updateFlag, getData_flag])

	// table select
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const handleSelectChange = e => {
		setSelectedRowKeys(e)
	}
	const handleClearSelect = e => {
		setSelectedRowKeys([])
	}

	// option
	const [surveyorOption, setSurveyorOption] = useState([])
	const [audit1StatusOption, setAudit1StatusOption] = useState([])
	const [scopeOption, setScopeOption] = useState([])
	const [proxyOption, setProxyOption] = useState([])

	const [isTimebool, setIsTimebool] = useState(false)
	const [isFenshubool, setIsFenshubool] = useState(false)

	useEffect(() => {
		axios.get('/api/core/project/' + projectid)
			.then(res => {
				console.log(res.data)
				if (res.data.autoAudit1PassedTime != null) {
					setIsTimebool(true)
				}
				console.log(res)
				if (res.data.autoAssignScore != null) {
					setIsFenshubool(true)
				}
			})
	}, [isbool])

	useEffect(() => {
		axios.get('/api/sys/dict/taskAudit1Status').then(res => {
			const arr = Object.entries(res.data.labelValues).map(i => {
				return {
					text: i[1],
					value: i[0]
				}
			})
			setAudit1StatusOption(arr)
		})
		axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
			setScopeOption(res.data)
		})
		axios.get('/api/core/project/' + projectid).then(res => {
			axios.get('/api/sys/user/list', {
				params: {
					roleCode: 'jiahua_vistor',
					pageSize: 1000000,
					groupId: res.data.userGroupId,
					orgCode: 'jiahua'
				}
			}).then(res => {
				setSurveyorOption(res.data.data)
			})
			axios.get('/api/sys/user/list', {
				params: {
					roleCode: 'jiahua_proxy',
					pageSize: 1000000,
					groupId: res.data.userGroupId,
					orgCode: 'jiahua'
				}
			}).then(res => {
				setProxyOption(res.data.data)
			})
		})
	}, [])


	// select
	const [scopeSelect, setScopeSelect] = useState(null)
	const [dsrSelect, setDsrSelect] = useState('')
	const [targetSelect, setTargetSelect] = useState('')
	const [codeSelect, setCodeSelect] = useState('')
	const [firstStatusSelect, setFirstStatusSelect] = useState(null)
	const [surveyorSelect, setSurveyorSelect] = useState(null)
	const [startPointDesc, setStartPointDesc] = useState(null)
	const [proxySelect, setProxySelect] = useState(null)

	const clearSelect = e => {
		setScopeSelect(null)
		setDsrSelect('')
		setTargetSelect('')
		setCodeSelect('')
		setFirstStatusSelect(null)
		setSurveyorSelect(null)
		setStartPointDesc(null)
		setUpdateFlag(pre => pre + 1)
		setProxySelect(null)
	}

	// 弹窗
	const [modalVisible, setModalVisible] = useState(null)
	const handleModalCancel = e => {
		setModalVisible(null)
		setRemark('')
		setTaskId(null)
	}
	const [remark, setRemark] = useState('')
	const [taskId, setTaskId] = useState(null)

	//通过
	const handlePass = e => {
		axios.get('/api/core/task/genJhCheckScore/' + taskId)
			.then(res => {
				axios.post('/api/core/jhTaskAudit/audit1Passed', {
					audit1PassedReason: remark
				}, {
					params: {
						taskId: taskId
					}
				}).then(res => {
					message.success()
					handleModalCancel()
					getData()
				})
			})
	}

	//批量通过
	const hanldePassMore = e => {
		if (selectedRowKeys.length === 0) return
		selectedRowKeys.forEach(v => {
			axios.get('/api/core/task/genJhCheckScore/' + v)
		})
		axios.post('/api/core/jhTaskAudit/batchAudit1Passed', {
			audit1PassedReason: remark
		}, {
			params: {
				taskIds: selectedRowKeys.join(',')
			}
		}).then(res => {
			message.success()
			handleModalCancel()
			getData()
		})
	}

	// 退回
	const handleBack = e => {
		axios.post('/api/core/jhTaskAudit/audit1Refused', {
			audit1RefusedReason: audit1RefusedReason[1],
			audit1RefusedReasonType: audit1RefusedReason[0],
			audit1RefusedRemark: remark
		}, {
			params: {
				taskId: taskId
			}
		}).then(res => {
			message.success()
			handleModalCancel()
			getData()
		})
	}

	//批量退回
	const handleBackMore = e => {
		if (selectedRowKeys.length === 0) return
		axios.post('/api/core/jhTaskAudit/batchAudit1Refused', {
			audit1RefusedReason: audit1RefusedReason[1],
			audit1RefusedReasonType: audit1RefusedReason[0],
			audit1RefusedRemark: remark
		}, {
			params: {
				taskIds: selectedRowKeys.join(',')
			}
		}).then(res => {
			message.success()
			handleModalCancel()
			getData()
		})
	}

	//安排判断
	const handleEnsure = e => {
		if (modalVisible === 'back') handleBack()
		if (modalVisible === 'backMore') handleBackMore()
		if (modalVisible === 'pass') handlePass()
		if (modalVisible === 'passMore') hanldePassMore()
	}

	//导出
	const handleExport = e => {
		const queryCondition = []
		queryCondition.push('IN:taskAudit1Status=1!31')
		queryCondition.push('IN:status=21')
		if (store.getState().user.uid != 4)
			queryCondition.push('EQ:agentId=' + store.getState().user.uid)
		if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
		if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
		if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
		if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
		if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
		if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
		if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
		if (startPointDesc) queryCondition.push('LIKE:startPointDesc=' + startPointDesc)

		axios.get('/api/core/jhTaskAudit/export', {
			params: {
				page,
				pageSize,
				queryCondition: queryCondition.join(','),
				taskAuditExportType: 4
			},
			responseType: 'blob'
		}).then(res => {
			let url = window.URL.createObjectURL(new Blob([res]));
			let link = document.createElement("a");
			link.style.display = "none";
			link.href = url;
			link.setAttribute("download", "导出门店.xlsx");
			document.body.appendChild(link);
			link.click();
		})
	}
	const [visibool, setvisibool] = useState(false)

	const handleOk = () => {
		// if(onegoTime==null){
		// 	axios.post('/api/sys/sysConfig',{
		// 		configName:'一审自动通过',
		// 		configKey:'autoAudit1Passed',
		// 		remark:'一审自动通过时间,单位分钟',
		// 		isSystemic:1,
		// 		configValue:Hour*60+Minute
		// 	})
		// 	.then(res=>{
		// 		console.log(res)
		// 	})
		// }else{
		axios.put('/api/core/project/updateAutoAudit1PassedTime', {}, {
			params: {
				projectId: projectid,
				autoAudit1PassedTime: Hour * 60 + Minute + ''
			}
		})
			.then(res => {
				message.success('修改成功')
				setvisibool(false)
				setIsTimebool(true)
			})
		// }
	}

	const [onegoTime, setonegoTime] = useState(null)

	const visishenhe = () => {
		setvisibool(true)
		axios.get('/api/core/project/' + projectid)
			.then(res => {
				setHour(parseInt(res.data.autoAudit1PassedTime / 60, 0))
				setMinute(res.data.autoAudit1PassedTime % 60)
			})
	}

	const [Hour, setHour] = useState(0)
	const [Minute, setMinute] = useState(0)
	const [audit1RefusedReason, setAudit1RefusedReason] = useState([])

	const [pageSize, setPageSize] = useState(5)//显示数据数量
	const [page, setPage] = useState(1)//数据页数
	const [total, setTotal] = useState(null)//数据总是

	useEffect(() => {
		getData()
	}, [pageSize, page])
	return (
		<div style={{ padding: '30px' }} >
			<div style={{ padding: '30px', background: 'white' }} >
				<Row gutter={[20, 20]} style={{ marginBottom: '20px' }} >
					<Col span={8} >
						<span style={{ lineHeight: '30px' }} >调研编号</span>
						<Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
					</Col>
					<Col span={8} >
						<span style={{ lineHeight: '30px' }} >调研大区</span>
						<AutoComplete
							onChange={e => { console.log(e); setScopeSelect(e); }}
							style={{ width: '240px', marginLeft: '10px' }}
							options={scopeOption.map(v => { return { value: v } })}
							value={scopeSelect}
						/>
					</Col>
					<Col span={8}>
						<span style={{ lineHeight: '30px' }}>访问员</span>
						<Select
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
							<Select.Option value={''}>取消选择</Select.Option>
							{
								surveyorOption.map((i, index) => (
									<Select.Option key={index} value={i.id}>{i.name}</Select.Option>
								))
							}
						</Select>
					</Col>
					<Col span={8}>
						<span style={{ lineHeight: '30px' }}>代理人</span>
						<Select
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							} style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
							<Select.Option value={''}>取消选择</Select.Option>
							{
								proxyOption.map((i, index) => (
									<Select.Option key={index} value={i.id}>{i.name}</Select.Option>
								))
							}
						</Select>
					</Col>
					<Col span={8} >
						<span style={{ lineHeight: '30px' }} >调研对象</span>
						<Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
					</Col>
					<Col span={8} >
						<span style={{ lineHeight: '30px' }} >一审状态</span>
						<Select style={{ width: '240px', marginLeft: '10px' }} value={firstStatusSelect} onChange={e => setFirstStatusSelect(e)} >
							<Select.Option value={''}>取消选择</Select.Option>
							{
								audit1StatusOption.map((i, index) => <Select.Option key={index} value={i.value} >{i.text}</Select.Option>)
							}
						</Select>
					</Col>
					<Col span={8}>
						<span style={{ lineHeight: '30px' }}>门店地址</span>
						<Input style={{ width: '240px', marginLeft: '10px' }} value={startPointDesc} onChange={e => setStartPointDesc(e.target.value)}></Input>
					</Col>
				</Row>
				<Row justify='space-between' style={{ marginBottom: '20px' }} >
					<Col>
						<Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('passMore')} >批量通过</Button>
						<Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('backMore')} >批量退回</Button>
						{
							isbool ? <Button style={{ margin: '0 10px' }} onClick={e => visishenhe()}>审核设置</Button> : null
						}
						{
							isbool ? <Button style={{ margin: '0 10px' }} onClick={e => { selectedRowKeys.forEach(v => { axios.get('/api/core/task/genJhCheckScore/' + v) }); getData() }}>批量算分</Button> : null
						}
						{
							isbool ? <Button style={{ margin: '0 10px' }} onClick={e => {
								dataSource.forEach(v => {
									if (v.taskAudit1Status.value == 0) {
										axios.post('/api/core/jhTaskAudit/submitAudit', {}, {
											params: {
												taskId: v.id
											}
										})
									}
								})
								getData()
							}}>提交审核(解决未审核问题 临时功能)</Button> : null
						}
						<Button style={{ margin: '0 10px' }} >批量下载</Button>
						<Button style={{ margin: '0 10px' }} onClick={handleExport} >批量导出</Button>
						{
							isTimebool ? <span style={{ color: '#0f0', marginRight: '10px' }}>自动通过时间已设置</span> : <span style={{ color: 'red' }}>自动通过时间未设置</span>
						}
						{
							isFenshubool ? <span style={{ color: '#0f0' }}>自动安排分数已设置</span> : <span style={{ color: 'red' }}>自动安排分数未设置</span>
						}
					</Col>
					<Col >
						<Button style={{ margin: '0 10px' }} type='primary' onClick={getData} >查询</Button>
						<Button style={{ margin: '0 10px' }} onClick={clearSelect} >重置</Button>
					</Col>
				</Row>

				{
					selectedRowKeys.length !== 0 ?
						<Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }} >
							<Col ><Button danger type='primary' onClick={handleClearSelect} >清空</Button></Col>
							<Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
						</Row> :
						null
				}
				<Table
					rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
					dataSource={dataSource}
					columns={columns}
					pagination={{
						defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true, total, current: page, onChange: (page, pageSize) => {
							setPage(page)
							setPageSize(pageSize)
						}
					}}
				/>

				<Modal
					width={640}
					title={'退回门店'}
					visible={modalVisible === 'back' || modalVisible === 'backMore'}
					onCancel={handleModalCancel}
					destroyOnClose
					maskClosable={false}
					footer={[
						<Button key="back" onClick={handleModalCancel} >取消</Button>,
						<Button key="submit" type="primary" onClick={handleEnsure} >确认</Button>,
					]}
				>
					<Row gutter={[20, 20]} style={{ lineHeight: '30px' }} >
						<Col span={6} style={{ textAlign: 'right' }} >退回理由:</Col>
						<Col span={14} >
							<Select style={{ width: 120 }} onChange={e => { setAudit1RefusedReason([e, reasonType[e]]) }}>
								{
									Object.keys(reasonType).map(v => {
										return <Option value={v} >{reasonType[v]}</Option>
									})
								}
							</Select>
						</Col>
						<Col span={6} style={{ textAlign: 'right' }} >退回备注:</Col>
						<Col span={14} >
							<Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
						</Col>
					</Row>
				</Modal>

				<Modal
					width={640}
					title={'通过门店'}
					visible={modalVisible === 'pass' || modalVisible === 'passMore'}
					onCancel={handleModalCancel}
					destroyOnClose
					maskClosable={false}
					footer={[
						<Button key="back" onClick={handleModalCancel} >取消</Button>,
						<Button key="submit" type="primary" onClick={handleEnsure} >确认</Button>,
					]}
				>
					{
						// <Col span={6} style={{ textAlign: 'right' }} >通过理由:</Col>
						// <Col span={14} >
						// 	<Select defaultValue="1" style={{ width: 120 }} onChange={e=>{setAudit1RefusedReason([e,shopStatus[e]])}}>
						// 		{
						// 			Object.keys(shopStatus).map(v=>{
						// 				return <Option value={v} >{shopStatus[v]}</Option>
						// 			})
						// 		}
						// 	</Select>
						// </Col>
					}
					<Row gutter={[20, 20]} style={{ lineHeight: '30px' }} >
						<Col span={6} style={{ textAlign: 'right' }} >通过备注:</Col>
						<Col span={14} >
							<Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
						</Col>
					</Row>
				</Modal>

				<Modal
					title={"审核设置"}
					visible={visibool}
					onOk={handleOk}
					onCancel={e => setvisibool(false)}
				>
					设置一审自动通过时间:<InputNumber min={0} max={23} style={{ margin: '0 5px' }} value={Hour} onChange={e => setHour(e)} />h
					<InputNumber min={1} max={59} style={{ margin: '0 5px' }} value={Minute} onChange={e => setMinute(e)} />min
				</Modal>
			</div>
		</div>
	)
}

export default App