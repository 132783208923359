import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { stringToNumber, filterSet } from '@/utils/common'
import { baseUrl } from '@/utils/config'
import store from '@/redux/store'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [refresh, setRefresh] = useState(false);

  useEffect(() => { //强制渲染页面
    refresh && setTimeout(() => setRefresh(false))
  }, [refresh])

  const [ data,setData ] = useState(null)


  const handleShowPlan = e => {
    setData(e)
    setModalVisible('plan')
    setTaskId(e.id)
  }

  const handleShowEdit = e => {
    setModalVisible('edit')
    setData(e)
    setTaskId(e.id)
    setAudit(e.audit2ById)
    setReview(e.reviewById)
    setRemark(e.assignRemark)
  }

  // table
  const [columns, setColumns] = useState([
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    }, {
      title: '访问员',
      dataIndex: 'principal',
      render: text => text ? text : '-',
			sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '代理人',
      dataIndex: 'agent',
      render: text => text ? text : '-',
			sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
      title: '门店类型',
      dataIndex: 'shopLevel',
      render: text => text ? text : '-',
			sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      render: text => text.display ? text.display : '-',
      sorter: (a, b) => a.status.display?.localeCompare(b.status.display),
    },
    {
      title: '开始时间',
      dataIndex: 'startAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
        a.startAt?a.startAt=a.startAt:a.startAt='1'
        b.startAt?b.startAt=b.startAt:b.startAt='1'
        return a.startAt?.localeCompare(b.startAt)
      },
    },
    {
      title: '一审',
      dataIndex: 'agent',
      render: text => text ? text : '-'
    },
    {
      title: '二审',
      dataIndex: 'audit2ByName',
      render: text => text ? text : '-'
    },
    {
      title: '复审',
      dataIndex: 'reviewByName',
      render: text => text ? text : '-'
    },
    {
      title: '自动审核通过',
      dataIndex: 'taskAudit2Status',
      render: text => text.display == '已自动审核' ? text.display : '-',
    },
    {
      title: '原始得分',
      dataIndex: 'originalScore',
      render: text => text ? (text*100)?.toFixed(2) + '%' : '-',
			sorter: (a, b) => a.originalScore-b.originalScore,
    },
    {
      title: '标准得分',
      dataIndex: 'standardScore',
      render: text => text ? (text*100)?.toFixed(2) + '%'  : '-',
			sorter: (a, b) => a.standardScore-b.standardScore,
    },
    {
      title: '考核得分',
      dataIndex: 'checkScore',
      render: text => text ? (text*100)?.toFixed(2) + '%'  : '-',
			sorter: (a, b) => a.checkScore-b.checkScore,
    },
    {
      title: '总退回次数',
      dataIndex: 'refusedCount',
      render: text => text ? text : '0',
      sorter: (a, b) => a.refusedCount-b.refusedCount,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
        {
          record.assignStatus.value === 1 ?
            <Button type='link' size='small' onClick={e => handleShowPlan(record)}>安排</Button>
            : null
        }
        {
          record.assignStatus.value === 2 ?
            <Button type='link' size='small' onClick={e => handleShowEdit(record)}>修改</Button>

            : null
        }
        </>
      )
    },
  ])
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition = []
    queryCondition.push('IN:status=21!-1')
    queryCondition.push('IN:assignStatus=1!2')
    queryCondition.push('EQ:projectId=' + projectid)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:startAt=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:startAt=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (taskAudit2Select) queryCondition.push('EQ:taskAudit2Status=' + taskAudit2Select)
    if (taskReviewSelect) queryCondition.push('EQ:taskReviewStatus=' + taskReviewSelect)
    if (audit2ById) queryCondition.push('EQ:audit2ById=' + audit2ById)
    if (reviewById) queryCondition.push('EQ:reviewById=' + reviewById)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (planSelect) queryCondition.push('IN:assignStatus='+planSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)

    axios.get('/api/core/task/list', {
      params: {
        // page,
        pageSize:100000,
        queryCondition: queryCondition.join(','),
        userId:store.getState().user.uid,
        auditFlag:1
      }
    }).then(res => {
      setTotal(res.data.totalSize)
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])

  useEffect(() => {
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [updateFlag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])
  const [taskStatusOption, setTaskStatusOption] = useState([])
  const [taskAudit2StatusOption, setTaskAudit2StatusOption] = useState([])
  const [taskReviewStatusOption, setTaskReviewStatusOption] = useState([])
  const [audit2Option, setAudit2Option] = useState([])
  const [reviewOption, setReviewOption] = useState([])
  const [scopeOption, setScopeOption] = useState([])

  const [projectdata,setprojectdata] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/taskStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setTaskStatusOption(arr)
    })
    axios.get('/api/sys/dict/taskAudit2Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setTaskAudit2StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskReviewStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setTaskReviewStatusOption(arr)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      setprojectdata(res.data)
      setisAutoAssign(res.data.isAutoAssign==1?true:false)
      setautoAssignScore(res.data.autoAssignScore)
      setDsrAvgScore(res.data.dsrAvgScore)
      setDsrTaskCount(res.data.dsrTaskCount)
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_checker',
          groupId: res.data.userGroupId,
        }
      }).then(res => {
        setAudit2Option(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_reviewer',
          groupId: res.data.userGroupId,
        }
      }).then(res => {
        setReviewOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_proxy',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode:'jiahua'
        }
      }).then(res => {
        setProxyOption(res.data.data)
      })
    })
    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
  }, [])


  // select
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [proxySelect, setProxySelect] = useState(null)
  const [dateSelect, setDateSelect] = useState([])
  const [statusSelect, setStatusSelect] = useState(null)
  const [taskAudit2Select, setTaskAudit2Select] = useState(null)
  const [taskReviewSelect, setTaskReviewSelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [planSelect, setPlanSelect] = useState(null)
  const [audit2ById, setAudit2ById] = useState(null)
  const [reviewById, setReviewById] = useState(null)
  const [codeSelect, setCodeSelect] = useState('')
  const [targetSelect, setTargetSelect] = useState('')
  const [dsrAvgScore, setDsrAvgScore] = useState('')
  const [dsrTaskCount, setDsrTaskCount] = useState('')

  const clearSelect = e => {
    setDateSelect([])
    setStatusSelect(null)
    setTaskAudit2Select(null)
    setTaskReviewSelect(null)
    setScopeSelect(null)
    setCodeSelect('')
    setSurveyorSelect(null)
    setPlanSelect(null)
    setUpdateFlag(pre => pre + 1)
    setAudit2ById(null)
    setProxySelect(null)
    setReviewById(null)
    setTargetSelect('')
    setDsrAvgScore('')
    setDsrTaskCount('')
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setTaskId(null)
    setAudit(null)
    setReview(null)
    setRemark('')
  }
  const [audit, setAudit] = useState(null)
  const [review, setReview] = useState(null)
  const [remark, setRemark] = useState('')
  const [taskId, setTaskId] = useState(null)
  // 安排
  const handlePlan = e => {
    axios.post('/api/core/jhTaskAudit/assign', {
      assignRemark: remark,
      audit2ById: audit,
      reviewById: review
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      handleModalCancel()
      message.success()
      getData()
    })
  }
  //批量安排
  const handlePlanMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/jhTaskAudit/batchAssign', {
      assignRemark: remark,
      audit2ById: audit,
      reviewById: review
    }, {
      params: {
        taskIds: selectedRowKeys.join(',')
      }
    }).then(res => {
      handleModalCancel()
      message.success()
      getData()
    })
  }
  //修改
  const handleEdit = e => {
    axios.post('/api/core/jhTaskAudit/assignUpdate', {
      assignRemark: remark?remark:data.assignRemark,
      audit2ById: audit?audit:data.audit2ById,
      reviewById: review
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      handleModalCancel()
      message.success()
      getData()
    })
  }
  //批量修改
  const handleEditMore = e => {
    if (selectedRowKeys.length === 0) return
    let count = 0
    let reviewfu = null
    let auditfu = null
    selectedRowKeys.forEach(v=>{
      console.log(review && audit)
      if(!review || !audit){
        const a=dataSource.filter(a=>{
          return a.id == v
        })[0]
        console.log(a)
        reviewfu=a.reviewById
        auditfu=a.audit2ById
      }
      axios.post('/api/core/jhTaskAudit/assignUpdate', {
        assignRemark: remark,
        audit2ById: audit?audit:auditfu,
        reviewById: review?review:reviewfu
      }, {
        params: {
          taskId: v
        }
      })
      .catch(e=>{
        message.success('修改失败')
        count++
      })
    })
    setTimeout(()=>{
      if(count == 0){
        handleModalCancel()
        message.success()
        getData()
      }else{
        handleModalCancel()
        message.error('批量修改中有'+count+'项错误')
        getData()
      }
    })
  }
  //安排判断
  const handleDoPlan = e => {
    if (modalVisible === 'plan') handlePlan()
    if (modalVisible === 'planMore') handlePlanMore()
    if (modalVisible === 'edit') handleEdit()
    if (modalVisible === 'editMore') handleEditMore()
  }
  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:status=2!21')
    queryCondition.push('EQ:projectId=' + projectid)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:startAt=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:startAt=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (planSelect) queryCondition.push('IN:assignStatus='+planSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)

    axios.get('/api/core/jhTaskAudit/export', {
      params: {
        pageSize: 1000000,
        taskAuditExportType: 1,
        queryCondition: queryCondition.join(',')
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出安排.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    console.log(usernameershen)
    usernameershen.forEach(v=>{
      axios.get('/api/sys/user/group/org',{
        params:{
          id:v.id
        }
      })
      .then(res=>{
        v.orgs=res.data.filter(v=>{
          return v.groupId == GroupId
        })
      })
    })
    usernamefuhe.forEach(v=>{
      axios.get('/api/sys/user/group/org',{
        params:{
          id:v.id
        }
      })
      .then(res=>{
        v.orgs=res.data.filter(v=>{
          return v.groupId == GroupId
        })
      })
    })
  };

  const [usernameershen,setusernameershen] = useState([])
  const [usernamefuhe,setusernamefuhe] = useState([])

  const [GroupId,setGroupId] = useState([])

  useEffect(()=>{//获取人员信息

    axios.get('/api/core/autoAssignConfig/list',{params:{queryCondition:'EQ:projectId='+projectid,pageSize:100000}})
    .then(data=>{
      axios.get('/api/core/project/'+projectid)
      .then(res=>{
        setGroupId(res.data.userGroupId)
        axios.get('/api/sys/user/list',{
          params:{
            groupId:res.data.userGroupId,
            roleCode:'jiahua_checker',
            pageSize:1000000
          }
        })
        .then(res=>{
          res.data.data.forEach(v=>{
            v.lfershenbool = false

            v.lfershenMax = 0
            v.zcershenbool = false
            v.zcershenMax = 0
            data.data.data.forEach(a=>{
              if(v.id == a.userId && a.type == 1){
                if(a.shopType==1 && a.maxTaskQty!=null){
                  v.lfershenbool = true
                  v.lfershenMax = a.maxTaskQty
                }else if(a.shopType==2 && a.maxTaskQty!=null){
                  v.zcershenbool = true
                  v.zcershenMax = a.maxTaskQty
                }
              }
            })
          })
          setusernameershen(res.data.data)
        })
        axios.get('/api/sys/user/list',{
          params:{
            groupId:res.data.userGroupId,
            roleCode:'jiahua_reviewer',
            state:'1',
            pageSize:1000000
          }
        })
        .then(res=>{
          res.data.data.forEach(v=>{
            v.lffuhebool = false
            v.zcfuhebool = false
            v.lffuheMax = 0
            v.zcfuheMax = 0
            data.data.data.forEach(a=>{
              if(v.id == a.userId && a.type == 2){
                if(a.shopType==1 && a.maxTaskQty!=null){
                  v.lffuhebool = true
                  v.lffuheMax = a.maxTaskQty
                }else if(a.shopType==2 && a.maxTaskQty!=null){
                  v.zcfuhebool = true
                  v.zcfuheMax = a.maxTaskQty
                }
              }
            })
          })
          setusernamefuhe(res.data.data)
        })
      })
    })
  },[])

  const lfershen = [
    {
      title: '名称',
      dataIndex: 'name',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: '是否选择',
      dataIndex: 'lfershenbool',
      key: 'lfershenbool',
      render: (text,record)=>(
        <Checkbox checked={record.lfershenbool} onChange={e=>{record.lfershenbool = e.target.checked;setRefresh(true)}}></Checkbox>
      ),
    },
    {
      title: '输入门店上限',
      dataIndex: 'lfershenMax',
      key: 'lfershenMax',
      render: (text,record)=>(
        <Input value={record.lfershenMax} onChange={e=>{record.lfershenMax = e.target.value;setRefresh(true)}}></Input>
      ),
    },

  ];

  const lffuhe = [
    {
      title: '名称',
      dataIndex: 'name',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: '是否选择',
      dataIndex: 'lffuhebool',
      key: 'lffuhebool',
      render: (text,record)=>(
        <Checkbox checked={record.lffuhebool} onChange={e=>{record.lffuhebool = e.target.checked;setRefresh(true)}}></Checkbox>
      ),
    },
    {
      title: '输入门店上限',
      dataIndex: 'lffuheMax',
      key: 'lffuheMax',
      render: (text,record)=>(
        <Input value={record.lffuheMax} onChange={e=>{record.lffuheMax = e.target.value;setRefresh(true)}}></Input>
      ),
    },

  ];

  const zcershen = [
    {
      title: '名称',
      dataIndex: 'name',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: '是否选择',
      dataIndex: 'zcershenbool',
      key: 'zcershenbool',
      render: (text,record)=>(
        <Checkbox checked={record.zcershenbool} onChange={e=>{record.zcershenbool = e.target.checked;setRefresh(true)}}></Checkbox>
      ),
    },
    {
      title: '输入门店上限',
      dataIndex: 'zcershenMax',
      key: 'zcershenMax',
      render: (text,record)=>(
        <Input value={record.zcershenMax} onChange={e=>{record.zcershenMax = e.target.value;setRefresh(true)}}></Input>
      ),
    },

  ];

  const zcfuhe = [
    {
      title: '名称',
      dataIndex: 'name',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: '是否选择',
      dataIndex: 'zcfuhebool',
      key: 'zcfuhebool',
      render: (text,record)=>(
        <Checkbox checked={record.zcfuhebool} onChange={e=>{record.zcfuhebool = e.target.checked;setRefresh(true)}}></Checkbox>
      ),
    },
    {
      title: '输入门店上限',
      dataIndex: 'zcfuheMax',
      key: 'zcfuheMax',
      render: (text,record)=>(
        <Input value={record.zcfuheMax} onChange={e=>{record.zcfuheMax = e.target.value;setRefresh(true)}}></Input>
      ),
    },

  ];


  const handleOk = () => {
    const shenhearr = []
    usernameershen.forEach(v=>{
      if(v.lfershenbool){
        shenhearr.push({
          projectId:projectid,
          userId:v.id,
          userName:v.name,
          type:1,
          shopType:1,
          maxTaskQty:v.lfershenMax
        })
      }
      if(v.zcershenbool){
        shenhearr.push({
          projectId:projectid,
          userId:v.id,
          userName:v.name,
          type:1,
          shopType:2,
          maxTaskQty:v.zcershenMax
        })
      }
    })
    usernamefuhe.forEach(v=>{
      if(v.lffuhebool){
        shenhearr.push({
          projectId:projectid,
          userId:v.id,
          userName:v.name,
          type:2,
          shopType:1,
          maxTaskQty:v.lffuheMax
        })
      }
      if(v.zcfuhebool){
        shenhearr.push({
          projectId:projectid,
          userId:v.id,
          userName:v.name,
          type:2,
          shopType:2,
          maxTaskQty:v.zcfuheMax
        })
      }
    })
    axios.put('/api/core/project',{
      id:projectid,
      name:projectdata.name,
      code:projectdata.code,
      autoAssignScore,
      isAutoAssign:isAutoAssign?1:0,
      autoAudit1PassedTime:projectdata.autoAudit1PassedTime,
      userGroupId:projectdata.userGroupId,
      shopGroupId:projectdata.shopGroupId,
      metricGroupId : projectdata.metricGroupId,
      productGroupId : projectdata.productGroupId,
      locationGroupId : projectdata.locationGroupId,
      calScoreId : projectdata.calScoreId,
      isAutoAudit1Passed:projectdata.isAutoAudit1Passed,
      scope:projectdata.scope,
      dsrAvgScore:projectdata.dsrAvgScore,
      dsrTaskCount:projectdata.dsrTaskCount,
      startDate:moment(projectdata.startDate),
      endDate:moment(projectdata.endDate),
      warningDate:moment(projectdata.warningDate),
      dsrAvgScore,
      dsrTaskCount
    })
    axios.post('/api/core/autoAssignConfig/batchAdd',shenhearr)
    .then(res=>{
      message.success('设置成功')
      setIsModalVisible(false);
    })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isAutoAssign,setisAutoAssign] = useState(null)

  const [autoAssignScore,setautoAssignScore] = useState(null)
  
  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>
        <Modal title="自动安排设置" width="900px" visible={isModalVisible} onOk={handleOk} destroyOnClose onCancel={handleCancel}>
             <Row>
              <Col span='5' style={{margin:'10px 0'}}>是否开启自动安排:</Col>
              <Col span='19'><Checkbox defaultChecked={isAutoAssign} onChange={e=>setisAutoAssign(e.target.checked)}></Checkbox></Col>
              <Col span='5' style={{margin:'10px 0'}}>0分店安排人员:</Col>
              <Col span='9'>
                <p>二审人员选择:</p>
                <Table
                  columns={lfershen}
                  dataSource={usernameershen}
                />
              </Col>
              <Col span='1'></Col>
              <Col span='9'>
                <p>复核人员选择:</p>
                <Table
                  columns={lffuhe}
                  dataSource={usernamefuhe}
                />
              </Col>
              <Col span='5' style={{margin:'10px 0'}}>设置分数:</Col>
              <Col span='19'><Input defaultValue={autoAssignScore} onChange={e=>setautoAssignScore(e.target.value)}></Input></Col>
              <Col span='5' style={{margin:'10px 0'}}>设置扫DSR分数:</Col>
              <Col span='19'><Input defaultValue={dsrAvgScore} onChange={e=>setDsrAvgScore(Number(e.target.value))}></Input></Col>
              <Col span='5' style={{margin:'10px 0'}}>设置标准数量:</Col>
              <Col span='19'><Input defaultValue={dsrTaskCount} onChange={e=>setDsrTaskCount(e.target.value)}></Input></Col>
              <Col span='5' style={{margin:'10px 0'}}>正常店审核:</Col>
              <Col span='9'>
                <p>二审人员选择:</p>
                <Table
                  columns={zcershen}
                  dataSource={usernameershen}
                />
              </Col>
              <Col span='1'></Col>
              <Col span='9'>
                <p>复核人员选择:</p>
                <Table
                  columns={zcfuhe}
                  dataSource={usernamefuhe}
                />
              </Col>
             </Row>
        </Modal>
        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
             style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>代理人</span>
            <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
              {
                proxyOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研大区</span>
            <AutoComplete
              onChange={e => { console.log(e); setScopeSelect(e); }}
              style={{ width: '240px', marginLeft: '10px' }}
              options={scopeOption.map(v => { return { value: v } })}
              value={scopeSelect}
          />
          </Col>
          <Col span={8} >
            <span style={{ lineHeight: '30px' }} >调研对象</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>任务状态</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '10px' }} value={statusSelect} onChange={e => setStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                taskStatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>安排状态</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '10px' }} value={planSelect} onChange={e => setPlanSelect(e)}>
                  <Select.Option value={''}>取消选择</Select.Option>
                  <Select.Option value='2'>已安排</Select.Option>
                  <Select.Option value='1'>待安排</Select.Option>
                  <Select.Option value='0'>未安排</Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>二审人员</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '10px' }} value={audit2ById} onChange={e => { setAudit2ById(e) }}>
                {//<Select.Option value={0}>无审核员</Select.Option>
                }
                {
                  audit2Option.map((i, index) => (
                    <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                  ))
                }
              </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>复核人员</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '10px' }} value={reviewById} onChange={e => { setReviewById(e) }}>
                {//<Select.Option value={0}>无审核员</Select.Option>
                }
                {
                  reviewOption.map((i, index) => (
                    <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                  ))
                }
              </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>二审状态</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '10px' }} value={taskAudit2Select} onChange={e => setTaskAudit2Select(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
              taskAudit2StatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>复核状态</span>
            <Select 
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              } style={{ width: '240px', marginLeft: '10px' }} value={taskReviewSelect} onChange={e => setTaskReviewSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
              taskReviewStatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('planMore')}>批量安排</Button>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
            <Button style={{ margin: '0 10px' }} onClick={showModal}>自动安排设置</Button>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('editMore')}>批量修改</Button>
						<Button style={{ margin: '0 10px' }} onClick={e => { selectedRowKeys.forEach(v => { axios.get('/api/core/task/genJhCheckScore/' + v) }); getData() }}>批量算分</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
          setPage(page)
          setPageSize(pageSize)
        }
        }}
        />

        <Modal
          width={640}
          title={modalVisible === 'edit' || modalVisible === 'editMore' ? '审核修改' : '审核安排'}
          visible={modalVisible === 'plan' || modalVisible === 'planMore' || modalVisible === 'edit' || modalVisible === 'editMore'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleDoPlan}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            {/*
              <Col span={6} style={{ textAlign: 'right' }}>抽样比例:</Col>
              <Col span={14}>
                <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} /> 
              </Col>
            */}
            <Col span={6} style={{ textAlign: 'right' }}>选择二审:</Col>
            <Col span={14}>
              <Select 
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              } style={{ width: '100%' }} value={audit} onChange={e => { setAudit(e) }}>
                {
                  audit2Option.map((i, index) => (
                    <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                  ))
                }
              </Select>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>选择复核:</Col>
            <Col span={14}>
              <Select 
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              } style={{ width: '100%' }} value={review} onChange={e => setReview(e)}>
                {
                  reviewOption.map((i, index) => (
                    <Select.Option value={i.id} key={i.id}>{i.name}</Select.Option>
                  ))
                }
              </Select>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>备注:</Col>
            <Col span={14}>
              <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
            </Col>
          </Row>
        </Modal>

      </div>
    </div>
  )
}

export default App