import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, message } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'


function App(props) {
    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    let banMa = []           //斑马线的数组

    let canBiaoZhu = false  //是否可以进行标注
    let pointColor = 'red'  //点的颜色
    let pointSize = 10       //点的大小

    //开始标注
    const startBiaoZhu = () => {
        canBiaoZhu = true
    }
    //完成标注
    const endBiaoZhu = () => {
        canBiaoZhu = false
    }
    //画点
    const createMarker = (x, y) => {
        var div = document.createElement('div')
        div.className = 'marker'
        div.id = 'marker' + banMa.length
        y = y + document.getElementById('myBiaoZhu').offsetTop - pointSize / 2
        x = x + document.getElementById('myBiaoZhu').offsetLeft - pointSize / 2
        div.style.width = pointSize + 'px'
        div.style.height = pointSize + 'px'
        div.style.backgroundColor = pointColor
        div.style.position = 'absolute'
        div.style.left = x + 'px'
        div.style.top = y + 'px'
        div.oncontextmenu = ((e) => {
            var id = e.target.id
            document.getElementById('myBiaoZhuDiv').removeChild(div)
            banMa = banMa.filter(item => item.id != id.slice(6, id.length))
            if (e && e.preventDefault) {
                //阻止默认浏览器动作(W3C)
                e.preventDefault()
            } else {
                //IE中阻止函数器默认动作的方式
                window.event.returnValue = false
            }
            return false
        })  //阻止冒泡行为和默认右键菜单事件，删除该点
        document.getElementById('myBiaoZhuDiv').appendChild(div)
    }

    useEffect(()=>{
        setTimeout(()=>{
            document.getElementById('myBiaoZhu').oncontextmenu=((e)=>{
                if(e && e.preventDefault) {
                  //阻止默认浏览器动作(W3C)
                  e.preventDefault()
                } else {
                  //IE中阻止函数器默认动作的方式
                  window.event.returnValue = false
                }
                return false
              })      //阻止冒泡行为和默认右键菜单事件
            document.getElementById('myBiaoZhu').onmousedown=(e)=>{
                e = e || window.event
                if(e.button !== 2){       //判断是否右击
                  if(canBiaoZhu){    //判断是否可以进行标注
                    var x = e.offsetX || e.layerX
                    var y = e.offsetY || e.layerY
                    console.log(x,y)
                    var myImg = document.querySelector("#myBiaoZhu")
                    var currWidth = myImg.clientWidth
                    var currHeight = myImg.clientHeight
                    var ProportionWidthInImg = x/currWidth
                    var ProportionHeightInImg = y/currHeight
                    console.log("图片比例高度："+ProportionHeightInImg)
                    console.log("图片比例宽度："+ProportionWidthInImg)
                    banMa.push({
                      id:banMa.length+1,
                      x,
                      y
                    })
                    createMarker(x,y)
                  }
                }
              }
        })
    })

    return (
        <div style={{ padding: '30px' }}>
            <div class="myBiaoZhu" id="myBiaoZhuDiv">
                <img id="myBiaoZhu" src="https://gee4.cn/xz/xz/file/group1/M00/09/75/L26YiGCCiQyAKycFAAkgU8mkokU76.jpeg" alt="" style={{ height: '400px', width: ' 400px' }}/>
                <button type="text" onClick={startBiaoZhu}>开始标注</button>
                <button type="text" onClick={endBiaoZhu}>标注完成</button>
            </div>
        </div >
    )
}

export default App
