import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu, Dropdown, Tabs } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import BarChart from './components/BarChart'


function App(props) {

    const { projectid, surveyScope, audit2ById } = useRouteMatch().params

    const [curTab, setCurTab] = useState('table')

    const columns = [
        {
            title: '调研大区',
            dataIndex: 'surveyScope',
            render: text => text ? text : '-'
        },
        {
            title: '审核员',
            dataIndex: 'audit2ByName',
            render: text => text ? text : '-'
        },
        {
            title: '审核日期',
            dataIndex: 'audit2at',
            render: text => text ? moment(text).format('YYYY年MM月DD日') : '-'
        },
        {
            title: '通过数量',
            dataIndex: 'passedCount',
            render: text => text ? text : '-'
        },
        {
            title: '修改数量',
            dataIndex: 'modifiedCount',
            render: text => text ? text : '-'
        },
        {
            title: '退回数量',
            dataIndex: 'refusedCount',
            render: text => text ? text : '-'
        },
    ]
    const [dataSource, setDataSource] = useState([])
    const [updateFlag, setUpdateFlag] = useState(0)
    const getData = e => {
        axios.get('/api/sxStatistic/surveyScopeAudit2StatisticItem', {
            params: {
                pageSize: 1000000,
                projectId: projectid,
                surveyScope,
                audit2ById
            }
        }).then(res => {
            const arr = res.data.map(i => { return { ...i, key: i.id } })
            setDataSource(arr)
        })
    }
    useEffect(() => {
        getData()
    }, [updateFlag])

    return (
        <>
            <div style={{ padding: '30px' }}>
                <div style={{ padding: '30px', background: 'white' }}>

                    <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
                    </Row>

                    {
                        curTab === 'table' ?
                            <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true  }} />
                            : null
                    }
                </div>
            </div>

        </>
    )
}

export default App
