import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Upload, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import file from '@/assets/gdfp.xlsx'
import { stringToNumber, filterSet } from '@/utils/common'
import PopConfirmOnly from '@/utils/PopConfirmOnly'
import store from '@/redux/store'

const { Option } = Select;

function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const handleShowEdit = e => {
    setModalVisible('edit')
    setPrincipalIdEdit(e.principalId)
    setIdEdit(e.id)
  }
  // table
  const columns = [
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '访问员',
      dataIndex: 'principal',
      width: 120,
      render: text => text ? text : '-',
      sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '代理人',
      dataIndex: 'agent',
      width: 120,
      render: text => text ? text : '-',
      sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
        title: '项目区域',
        dataIndex: 'surveyScope',
        key: 'surveyScope',
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        )
    },
    {
      title: '开始时间',
      dataIndex: 'workAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
        a.workAt?a.workAt=a.workAt:a.workAt='1'
        b.workAt?b.workAt=b.workAt:b.workAt='1'
        return a.workAt?.localeCompare(b.workAt)
      },

    },
    {
      title: '结束时间',
      dataIndex: 'doneAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
          a.doneAt?a.doneAt=a.doneAt:a.doneAt='1'
          b.doneAt?b.doneAt=b.doneAt:b.doneAt='1'
          return a.doneAt?.localeCompare(b.doneAt)
      },
    },
    {
      title: '门店地址',
      dataIndex: 'startPointDesc',
      render: text => text ? text : '-',
      sorter: (a, b) => a.startPointDesc?.localeCompare(b.startPointDesc),
    },
    {
      title: '完成状态',
      dataIndex: 'status',
      render: (text, record) => record.status?.display ? record.status?.display : '-'
    },
    {
      title: '分配情况',
      dataIndex: 'principalId',
      render: text => text ? '已分配' : '未分配',
      sorter: (a, b) => a.principalId-b.principalId,
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record) => (
        <>
          {
            record.principalId ?
              <Button type='link' size='small' style={{ margin: '0 10px' }} onClick={e => handleShowEdit(record)}>修改</Button>
              :
              <Button type='link' size='small' style={{ margin: '0 10px' }} onClick={e => handleShowEdit(record)}>分配</Button>
          }
        </>
      )
    },
  ]
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const [proxylist, setproxylist] = useState([])//代理数据

  const [surveyScopelist, setsurveyScopelist] = useState('')  // 项目区域数据
  
  const getData = e => {
    if (store.getState().user.uid == null) {
      message.error('重新进入页面,请勿刷新')
      return
    }
    const queryCondition = []
    queryCondition.push('EQ:projectId=' + projectid)
    if (situationSelect != null) queryCondition.push(situationSelect == 0 ? 'NULL:principalId=0' : 'NOTNULL:principalId=0')
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (objectSelect) queryCondition.push('LIKE:surveyTarget=' + objectSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (startPointDesc) queryCondition.push('LIKE:startPointDesc=' + startPointDesc)
    if (seleaddress) queryCondition.push('LIKE:surveyScope=' + seleaddress)
    if (provyid) queryCondition.push('EQ:agentId=' + provyid)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:planStartAt=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:planStartAt=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }

    axios.get('/api/core/task/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(','),
        userId: store.getState().user.uid,
        auditFlag: 1
      }
    }).then(res => {
      setTotal(res.data.totalSize)
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])

  useEffect(() => {
    getData()
  }, [updateFlag])

  useEffect(() => {
    axios.get('/api/core/task/scope', { params: { projectId:projectid } })
    .then(res => {
        setsurveyScopelist(res.data)
    })
    axios.get('/api/core/project/' + projectid)
    .then(res=>{
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_proxy',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(ress => {
        setproxylist(ress.data.data)
      })
    })
  }, [])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [completionStatusOption, setCompletionStatusOption] = useState([])
  const [distributionOption, setDistributionOption] = useState([])
  const [surveyorOption, setSurveyorOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/taskStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setCompletionStatusOption(arr)
    })
    setDistributionOption([{ value: 0, text: '未分配' }, { value: 1, text: '已分配' }])
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(resa => {
        console.log(resa)
        axios.get('/api/sys/user/list', {
          params: {
            roleCode: 'jiahua_proxy',
            pageSize: 1000000,
            groupId: res.data.userGroupId,
            orgCode: 'jiahua'
          }
        }).then(ress => {
          const user = ress.data.data.filter((v => {
            return v.id == store.getState().user.uid
          }))
          axios.get('/api/sys/user/group/org', {
            params: {
              id: user[0]?.id
            }
          })
            .then(resc => {
              const count = resc.data.filter(v => {
                return v.groupId == res.data.userGroupId
              })[0]?.roles.filter(v => {
                return v.code == 'jiahua_proxy'
              })
              if (count?.length > 0) {
                axios.get('/api/sys/user/group/org/proxy',{
                  params:{
                    proxyId:store.getState().user.uid,
                    groupId:res.data.userGroupId
                  }
                })
                .then(res=>{
                  let userarr = resa.data.data.filter(v=>{
                    return res.data.filter(a=>{
                      return a.userId == v.id
                    }).length!=0
                  })
                  setSurveyorOption([...userarr, user[0]])
                })
                // const visi = resa.data.data.filter(v => {
                //   return v.proxyId == user[0].id
                // })
                  // setSurveyorOption([...visi, user[0]])
              } else {
                setSurveyorOption([...resa.data.data, ...ress.data.data])
              }
            })
        })
      })
    })
  }, [])

  // select
  const [dateSelect, setDateSelect] = useState([])
  const [statusSelect, setStatusSelect] = useState(null)
  const [situationSelect, setSituationSelect] = useState(null)
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [objectSelect, setObjectSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [startPointDesc, setStartPointDesc] = useState(null)

  const [seleaddress, setseleaddress] = useState(null)//项目区域
  const [provyid, setprovyid] = useState(null)//代理人

  const clearSelect = e => {
    setprovyid(null)
    setseleaddress(null)
    setDateSelect([])
    setStatusSelect(null)
    setSituationSelect(null)
    setStartPointDesc(null)
    setObjectSelect(null)
    setCodeSelect('')
    setSurveyorSelect('')
    setUpdateFlag(pre => pre + 1)
  }

  //弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setIdEdit(null)
    setPrincipalIdEdit(null)
  }
  //分配 修改
  const [idEdit, setIdEdit] = useState(null)
  const [principalIdEdit, setPrincipalIdEdit] = useState(null)
  const handleEdit = e => {
    axios.put('/api/core/task/allot', {
      id: idEdit,
      principalId: principalIdEdit,
      principal: surveyorOption.filter(i => i.id === principalIdEdit)[0].name
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  const handleEditMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.put('/api/core/task/allot/batch', selectedRowKeys, {
      params: {
        id: idEdit,
        principalId: principalIdEdit,
        principal: surveyorOption.filter(i => i.id === principalIdEdit)[0].name
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      handleClearSelect()
      getData()
    })
  }
  const handleEditEnsure = e => {
    if (modalVisible === 'edit') handleEdit()
    if (modalVisible === 'editmore') handleEditMore()
  }

  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('EQ:projectId=' + projectid)
    if (situationSelect != null) queryCondition.push(situationSelect == 0 ? 'NULL:principalId=0' : 'NOTNULL:principalId=0')
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (objectSelect) queryCondition.push('LIKE:surveyTarget=' + objectSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (startPointDesc) queryCondition.push('LIKE:startPointDesc=' + startPointDesc)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:planStartAt=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:planStartAt=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }

    axios.get('/api/core/task/list', {
      params: {
        pageSize: 1000000,
        queryCondition: queryCondition.join(','),
        export: true,
        userId: store.getState().user.uid,
        auditFlag: 1
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出分配.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>
        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)}></Input>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研对象</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={objectSelect} onChange={e => setObjectSelect(e.target.value)}></Input>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '240px', marginLeft: '25px' }}
              value={surveyorSelect}
              onChange={e => setSurveyorSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>完成状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={statusSelect} onChange={e => setStatusSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                completionStatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>分配情况</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={situationSelect} onChange={e => setSituationSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                distributionOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>门店地址</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={startPointDesc} onChange={e => setStartPointDesc(e.target.value)}></Input>
          </Col>
          {
            store.getState().user.role!='代理人'?
              <Col span={8}>
                <span style={{ lineHeight: '30px', marginRight: '16px' }}>代理人</span>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={provyid} onChange={(e) => { setprovyid(e) }} style={{ width: '240px', marginLeft: '10px' }} >
                    <Select.Option value={''}>取消选择</Select.Option>
                    {
                        proxylist.map((v, i) => {
                            return <Option value={v.id}>{v.name}</Option>
                        })
                    }
                </Select>
              </Col>
            :null
          }
          <Col span={8}>
              <span style={{ lineHeight: '30px' }}>项目区域</span>
                  <AutoComplete
                      onChange={e=>{console.log(e);setseleaddress(e);}}
                      style={{ width: '240px', marginLeft: '10px' }}
                      options={Object.values(surveyScopelist).map(v=>{return{value:v}})}
                      value={seleaddress}
                  />
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('editmore')}>批量分配</Button>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
          </Col>
          <Col>
            <Button type='primary' style={{ margin: '0 10px' }} onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }

        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
            }
          }}
        />

        <Modal
          width={640}
          title="分配"
          visible={modalVisible === 'edit' || modalVisible === 'editmore'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleEditEnsure}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}>访问员:</Col>
            <Col span={14}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }} value={principalIdEdit} onChange={e => { setPrincipalIdEdit(e) }}>
                {
                  surveyorOption.map((i, index) => (
                    <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                  ))
                }
              </Select>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  )
}

export default App