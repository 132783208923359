import List from './component/List'
import Particulars from './component/Particulars'

import React, { useEffect, useState, } from 'react';
import { HashRouter, Switch, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Row, Col, Tabs } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import store from '@/redux/store'

const { TabPane } = Tabs;

function App(props) {
  const [projectId, setprojectId] = useState(useParams().projectid)//id
  const { projectid } = useRouteMatch().params


  const callback = (key) => {

  }

  const [data, setdata] = useState({})
  const [userlist, setuserlist] = useState([])//访问员数据
  const [proxylist, setproxylist] = useState([])//代理数据
  const [reviewerlist, setReviewerlist] = useState([])//复核数据
  const [checkerlist, setCheckerlist] = useState([])//二审数据
  const [daililist, setdaililist] = useState([])
  const [numsum, setnumsum] = useState([])
  const [labelValues, setlabelValues] = useState([])//项目状态
  const [locationlist, setlocationlist] = useState([])//门店列表

  useEffect(() => {
    (
      async () => {
        const res = await axios.get('/api/core/project/' + projectId)
        setdata(res.data)
        axios.get('/api/core/project/' + projectid).then(res => {
          axios.get('/api/sys/user/list', {
            params: {
              roleCode: 'jiahua_reviewer',
              pageSize: 1000000,
              groupId: res.data.userGroupId,
              orgCode: 'jiahua'
            }
          }).then(res => {
            setReviewerlist(res.data.data)
          })
          axios.get('/api/sys/user/list', {
            params: {
              roleCode: 'jiahua_checker',
              pageSize: 1000000,
              groupId: res.data.userGroupId,
              orgCode: 'jiahua'
            }
          }).then(res => {
            setCheckerlist(res.data.data)
          })
          axios.get('/api/sys/user/list', {
            params: {
              roleCode: 'jiahua_vistor',
              pageSize: 1000000,
              groupId: res.data.userGroupId,
              orgCode: 'jiahua'
            }
          }).then(resa => {
            axios.get('/api/sys/user/list', {
              params: {
                roleCode: 'jiahua_proxy',
                pageSize: 1000000,
                groupId: res.data.userGroupId,
                orgCode: 'jiahua'
              }
            }).then(ress => {
              setproxylist(ress.data.data)
              const user = ress.data.data.filter((v => {
                return v.id == store.getState().user.uid
              }))
              axios.get('/api/sys/user/group/org', {
                params: {
                  id: user[0]?.id
                }
              })
                .then(resc => {
                  const count = resc.data.filter(v => {
                    return v.groupId == res.data.userGroupId
                  })[0]?.roles.filter(v => {
                    return v.code == 'jiahua_proxy'
                  })
                  console.log(count)
                  if (count?.length > 0) {
                    const visi = resa.data.data.filter(v => {
                      return v.proxyId == user[0].id
                    })
                    console.log(visi)
                    setuserlist([...visi, user[0]])
                  } else {
                    setuserlist([...resa.data.data, ...ress.data.data])
                  }
                })
            })
          })
        })
        const locationlistdata = await axios.get('/api/core/shop/list', {
          params: {
            shopGroupId: res.data.shopGroupId,
            pageSize: 1000000,
          }
        })
        setlocationlist(locationlistdata.data.data)

        const dailires = await axios.get('/api/sys/user/list', {
          params: {
            roleCode: 'jiahua_proxy',
            pageSize: 1000000,
            groupId: res.data.userGroupId,
            orgCode: 'jiahua'
          }
        })
        setdaililist(dailires.data.data)
        const labelValuesdata = await axios.get('/api/sys/dict/projectStatus')
        setlabelValues(labelValuesdata.data.labelValues)
        const numsum = await axios.get('/api/core/project/summary', {
          params: {
            projectId: projectId
          }
        })
        setnumsum(numsum.data)
      }
    )()
  }, [])

  return (
    <div style={{}}>
      <Row style={{ background: "#fff", padding: '30px' }}>
        <Col span='24' style={{ display: 'flex', alignItems: 'center' }}>
          <font style={{ fontWeight: '600', fontSize: '20px' }}>{data.name}</font>
          <div style={{ margin: '0 20px', width: '5px', height: '5px', borderRadius: '50%', background: data.status != null && labelValues[data.status] != '未开始' ? 'rgba(82, 196, 25, 1)' : 'red' }}></div>
          {labelValues[data.status]}
        </Col>
        <Col span='6' style={{ margin: '10px 0', }}>项目编号:{data.code}</Col>
        <Col span='8' style={{ margin: '10px 0', }}>生效日期:{data.startDate} -- {data.endDate}</Col>
        <Col span='6' style={{ margin: '10px 0', }}>预警日期:{data.warningDate}</Col>
        <Col span='4' style={{margin: '10px 0',}}>上传数:{numsum.totalUploadCount}</Col>
        <Col span='6' style={{margin: '10px 0',}}>未开始:{numsum.pendingCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>进行中:{numsum.doingCount}</Col>
        <Col span='6' style={{margin: '10px 0',}}>工单总数:{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',fontWeight:'600'}}>一审:</Col>
        <Col span='8' style={{margin: '10px 0',fontWeight:'600'}}>二审:</Col>
        <Col span='8' style={{margin: '10px 0',fontWeight:'600'}}>复核:</Col>

        <Col span='6' style={{margin: '10px 0',}}>(待审核/总数) : {numsum.audit1PendingCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(待审核/总数) : {numsum.audit2PendingCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(待复核/总数) : {numsum.reviewPendingCount}/{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.audit1ReturnCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.audit2ReturnCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.reviewReturnCount}/{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.audit1Count}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.audit2Count}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.reviewCount}/{numsum.totalTaskCount}</Col>
      </Row>
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        style={{ background: '#fff' }}
        tabBarStyle={{ padding: '0 30px' }}>
        <TabPane style={{ background: '#eee' }} tab="门店列表" key="1">
          <List userlist={userlist} proxylist={proxylist} checkerlist={checkerlist} reviewerlist={reviewerlist} locationlist={locationlist} daililist={daililist} data={data}></List>
        </TabPane>
        {/*  <TabPane style={{ background: '#eee' }} tab="项目明细" key="2">
          <Particulars userlist={userlist} data={data}></Particulars>
        </TabPane> */}
      </Tabs>
    </div>
  )
}

export default App
