import React, { useState } from 'react';
import { Layout } from 'antd'
import HeaderBar from '../component/HeaderBar'

function App(props) {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Layout style={{ minHeight: '100vh', minWidth: '1380px' }}>
      <Layout.Sider width={240} style={{ height: '100vh', position: 'fixed', zIndex: 999 }} collapsible collapsed={collapsed} onCollapse={e => setCollapsed(e)}>
        <div style={{ height: '80px', padding: '10px 0', textAlign: 'center', color: 'white' }}>
          <p style={{ fontSize: collapsed ? '14px' : '26px', lineHeight: '40px', transition: 'all .5s' }}>旭中咨询</p>
          <p style={{ fontSize: '10px', transition: 'all .5s' }}>{collapsed ? 'XUZHONG' : 'XUZHONG·CONSULTING'}</p>
        </div>
        {props.menu}
      </Layout.Sider>
      <Layout.Sider width={240} style={{ minHeight: '100vh' }} collapsed={collapsed}></Layout.Sider>
      <Layout>
        <Layout.Header style={{ background: 'white', height: 'fit-content', padding: '0' }}>
          <HeaderBar />
        </Layout.Header>
        <Layout.Content>
          {props.content}
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default App