
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload, Radio } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import file from '@/assets/jhmddr.xlsx'
import { stringToNumber, filterSet } from '@/utils/common'
import PopConfirmOnly from '@/utils/PopConfirmOnly'

import locale from 'antd/lib/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

function App(props) {

  const { push } = useHistory()
  const [columns, setColumns] = useState([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '区域名称（省份）',
      dataIndex: 'regionName',
      key: 'regionName'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          {/* <Button size='small' type='link' onClick={e => {push('/home/set/regiondetail/' + record.id)}}>查看</Button> */}
          <Divider type='vertical' />
          <Button size='small' type='link' onClick={e => {upData(record)}}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  //

  const upData = r =>{
    setobj(Object.assign({},r))
    setaddupdatabool(true)
    setIsModalVisible(true)
  }

  const [dataSource,setdataSource] = useState([])//数据

  const [addupdatabool,setaddupdatabool] = useState(false)

  const getdata = e =>{
    let queryCondition = ''
    if(regionName&&e==undefined)queryCondition+="LIKE:regionName="+regionName

    axios.get('/api/core/region/list',{
      params:{
				pageSize: 1000000,
        queryCondition
      }
    })
    .then(res=>{
      setdataSource(res.data.data)
    })
  }

  useEffect(()=>{
    getdata()
  },[])
  
  //删除
  const handleDelete = e => {
    axios.delete('/api/core/region/' + e).then(res => {
      message.success()
      getdata()
    })
  }

  const [peopleOption, setPeopleOption] = useState([])//创建人员 
  useEffect(() => {
    axios.get('/api/sys/user/list', {
      params: {
				pageSize: 1000000,
        roleCode: 'xuzhong_admin',
      } 
    }).then(res => {
      setPeopleOption(res.data.data)
    })
  }, [])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])//多选
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    if(!obj.regionName){message.error('请检查未填写的问题');return}
    if(addupdatabool){
      axios.put('/api/core/region',obj)
      .then(res=>{
        message.success('修改成功');
        setobj({})
        setIsModalVisible(false);
        getdata()
      })
    }else{
      axios.post('/api/core/region',obj)
      .then(res=>{
        message.success('添加成功');
        setobj({})
        setIsModalVisible(false);
        getdata()
      })
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setobj({})
  };

  const [obj,setobj] = useState({})

  const [regionName,setregionName] = useState('')

  const dataobject = (name,value)=>{
    obj[name]=value
    setobj(Object.assign({},obj))
  }

  const remove = e =>{//重置
    setregionName('')
    getdata(1)
  }

  return (
    <div style={{ padding: '30px' }}>
      <Modal title={addupdatabool?'修改算分标准':"添加算分标准"} 
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <Row>
          <Col span={6} style={{ textAlign: 'right', lineHeight: '30px', marginBottom:'10px', display:'flex',  alignItems:'center' }}><font color='red'>*</font>区域名称(省份):</Col>
          <Col span={18} style={{marginBottom:'10px', display:'flex',  alignItems:'center' }}>
            <Input value={obj.regionName} onChange={e=>dataobject('regionName',e.target.value)} ></Input>
          </Col>
        </Row>
      </Modal>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            <span style={{ lineHeight: '30px' }}>区域名称/省份</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请填写信息' value={regionName} onChange={e => { setregionName(e.target.value) }}></Input>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={()=>{setIsModalVisible(true);setaddupdatabool(false)}}><PlusOutlined />添加算分标准</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e=>getdata()}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={e=>remove()}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' >清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger:true }}
        />
      </div>
    </div>
  )
}

export default App