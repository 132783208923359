import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Button, Input, Checkbox } from 'antd';
import { UserOutlined, UnlockOutlined, MobileOutlined, MailOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import BGround from '../../assets/loginBg.png'

function App(props) {

  const { push } = useHistory()

  const [type, setType] = useState('password')

  // const [autoLogin, setAutoLogin] = useState(false)
  // const handleChangeAuto = e => {
  //   setAutoLogin(e.target.checked)
  // }

  const [account, setAccount] = useState('')
  const [password, setPassword] = useState('')

  const [phoneNum, setPhoneNum] = useState('')
  const [code, setCode] = useState('')

  const clearInput = e => {
    setAccount('')
    setPassword('')
    setPhoneNum('')
    setCode('')
  }

  const handleGetCode = e => {
    if (phoneNum === '') {
      message.error('请填写手机号')
      return
    }
    axios.get('/api/common/code', { params: { phone: phoneNum } }).then(res => {
      message.success()
    })
  }

  const handleLogin = e => {
    let type_ = null
    let userCode_ = null
    let password_ = null
    if (type === 'password') {
      type_ = '1'
      userCode_ = account
      password_ = password
    }
    if (type === 'phone') {
      type_ = '0'
      userCode_ = phoneNum
      password_ = code
    }
    if (type === 'password') {
      if (account === '' || password === '') {
        message.error('请填写信息后登录')
        return
      }
    }
    if (type === 'phone') {
      if (phoneNum === '' || code === '') {
        message.error('请填写信息后登录')
        return
      }
    }

    axios.post('/api/sys/user/login', { userCode: userCode_, password: password_, type: type_ }).then((res)=>{
        
      localStorage.setItem('uid_xz', res.data.user.id)
      localStorage.setItem('token_xz', res.data.token)
      localStorage.setItem('name_xz', res.data.user.name)
      store.dispatch({ type: 'user_init', value: { uid: res.data.user.id, token: res.data.token, name: res.data.user.name } })
      axios.get('/api/sys/resource/user/list', { params: { type: '0,2' } }).then(res => {
        console.log(res)
        console.log(res.data['0'])
        console.log(res.data['2'])
        const main = res.data['0'].map(i => i.code)
        const project = res.data['2'].map(i => i.code)
        store.dispatch({ type: 'permission_init', value: { main, project } })
      })
      message.success('登陆成功')
      role(res)
      push('/home/project/list')
    }).catch(err => {
      message.error('账号密码有误')
    })
  }

  const role = (res)=>{
    axios.get('/api/sys/user/'+res.data.user.id)
        .then(resa=>{
          let arr = []
          axios.get('/api/sys/user/group/org',{
            params:{
              id:resa.data.id
            }
          })
          .then(res=>{
            res.data.forEach((v,i) => {
              v.roles.forEach((v,i) => {
                if(JSON.stringify(arr).indexOf(v.name)==-1){
                  console.log(arr)
                  arr.push(v.name)
                }
              });
            });
            arr = JSON.stringify(arr)
            console.log(arr)
            if(arr.indexOf('管理员')!=-1){
              store.dispatch({ type: 'user_init', value: { uid: store.getState().user.uid, token: store.getState().user.token, name: store.getState().user.name , role: '管理员'} })
              localStorage.setItem('role_xz', '管理员')
            }else if(arr.indexOf('复核')!=-1){
              store.dispatch({ type: 'user_init', value: { uid: store.getState().user.uid, token: store.getState().user.token, name: store.getState().user.name , role: '复核'} })
              localStorage.setItem('role_xz', '复核')
            }else if(arr.indexOf('代理人')!=-1){
              store.dispatch({ type: 'user_init', value: { uid: store.getState().user.uid, token: store.getState().user.token, name: store.getState().user.name , role: '代理人'} })
              localStorage.setItem('role_xz', '代理人')
            }else if(arr.indexOf('二审')!=-1){
              store.dispatch({ type: 'user_init', value: { uid: store.getState().user.uid, token: store.getState().user.token, name: store.getState().user.name , role: '二审'} })
              localStorage.setItem('role_xz', '二审')
            }
        })
      })
  }

  return (
    <Row align='middle' justify='center' style={{ width: '100%', height: '100vh', backgroundImage: 'url(' + BGround + ')', backgroundPosition: 'center', backgroundSize: '100%' }} >

      <Row gutter={[0, 25]} style={{ width: '400px' }}>
        <Col span={24}>
          <Row align='middle' justify='center' gutter={[10, 0]} >
            <Col><img src={require("../../assets/logo.png")} alt="" /></Col>
            <Col style={{ fontSize: '34px', fontWeight: 'bold' }}>上海旭中调研系统</Col>
          </Row>
        </Col>
        <Col span={24} style={{ fontSize: '14px', textAlign: 'center', opacity: '.4' }}>
          SHANG HAI XU ZHONG INFORMATION CO.,LTD
        </Col>
        <Col span={24}>
          <Row justify='center' gutter={[20, 0]} style={{ fontSize: '16px', marginBottom: '20px' }}>
            <Col>
              <Button type={type === 'password' ? 'primary' : ''} onClick={e => { setType('password'); clearInput() }}>账户密码登录</Button>
            </Col>
            <Col>
              <Button type={type === 'phone' ? 'primary' : ''} onClick={e => { setType('phone'); clearInput() }}>手机号登录</Button>
            </Col>
          </Row>
        </Col>
        {
          type === 'password' ?
            <>
              <Col span={24}>
                <Input size='large' prefix={<UserOutlined style={{ color: 'rgb(166, 166, 166)' }} />} placeholder='账号' value={account} onChange={e => setAccount(e.target.value)}></Input>
              </Col>
              <Col span={24}>
                <Input size='large' prefix={<UnlockOutlined style={{ color: 'rgb(166, 166, 166)' }} />} placeholder='密码' value={password} onChange={e => setPassword(e.target.value)}></Input>
              </Col>
            </>
            : null
        }
        {
          type === 'phone' ?
            <>
              <Col span={24}>
                <Input size='large' prefix={<MobileOutlined style={{ color: 'rgb(166, 166, 166)' }} />} placeholder='手机号' value={phoneNum} onChange={e => setPhoneNum(e.target.value)}></Input>
              </Col>
              <Col span={24}>
                <Row justify='space-between'>
                  <Col span={16}>
                    <Input size='large' prefix={<MailOutlined style={{ color: 'rgb(166, 166, 166)' }} />} placeholder='验证码' value={code} onChange={e => setCode(e.target.value)}></Input>
                  </Col>
                  <Col span={7}>
                    <Button size='large' onClick={handleGetCode}>获取验证码</Button>
                  </Col>
                </Row>
              </Col>
            </>
            : null
        }
        {/* <Col span={24}>
          <Checkbox checked={autoLogin} onChange={handleChangeAuto}>自动登录</Checkbox>
        </Col> */}
        <Col span={24}>
          <Button size='large' type='primary' style={{ width: '100%' }} onClick={handleLogin}>登录</Button>
        </Col>
        <Col span={24} style={{ fontSize: '14px', textAlign: 'center', opacity: '.4' ,color:'#000'}}>
          <a target="_blank" href='https://pc.qq.com/detail/1/detail_2661.html'><DownloadOutlined />请在谷歌浏览器中使用</a>
        </Col>
      </Row>
    </Row >
  )
}
export default App