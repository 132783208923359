import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'


function App(props) {
  const { push } = useHistory()
  const { audit2id } = useRouteMatch().params


  const [columns, setColumns] = useState([
      {
        title: '项目名称',
        dataIndex: 'projectName',
        sorter: (a, b) => a.projectName?.localeCompare(b.projectName),
        key: 'projectName',
      },
      {
        title: '二审已完成',
        dataIndex: 'audit2PassedCount',
        sorter: (a, b) => a.audit2PassedCount?.localeCompare(b.audit2PassedCount),
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '扫DSR完成数量',
        dataIndex: 'dsrAuditCount',
        sorter: (a, b) => a.dsrAuditCount?.localeCompare(b.dsrAuditCount),
        render:(text,record)=>{
          return text?text:'0'
        }
      },
    ])

  const [dataSource, setDataSource] = useState([])

  const getdata = () =>{
    console.log(audit2id)
    axios.get('/api/jhStatistic/auditStatisticItemByAudit2',{
      params:{
        audit2ById:audit2id
      }
    })
    .then(res=>{
      res.data.reverse()
      console.log(res.data)
      setDataSource(res.data)
      banma()
    })
  }

  const banma = ()=>{
    setTimeout(()=>{
      let domarr = document.getElementsByTagName('tr')
      domarr = [...domarr]
      domarr.forEach((v,i) => {
        if(i%2== 0){
          v.style.background='#f1f1f1'
        }else{
          v.style.background='#fff'
        }
      });
    },200)
  }

  useEffect(() => {
    getdata()
  }, [])

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
          {/* <Button>导出列表</Button> */}
          </Col>
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true }} onChange={e=>{banma()}}/>

      </div>
    </div>
  )
}

export default App
