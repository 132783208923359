import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'


function App(props) {
  const { push } = useHistory()
  const { projectid } = useRouteMatch().params


  const [columns, setColumns] = useState([
      {
        title: '项目名称',
        dataIndex: 'projectName',
        key: 'projectName',
        sorter: (a, b) => a.projectName?.localeCompare(b.projectName),
        render:(text,record)=>{
          return <Button type="link" onClick={e=>{
            sessionStorage.setItem('projectname',text)
            push('/home/data/agencydaili/'+record.projectId )
          }}>{text}</Button>
        }
      },
      {
        title: '目标量',
        dataIndex: 'masterCount',
        sorter: (a, b) => a.masterCount-b.masterCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '今日增量',
        dataIndex: 'todayDoneCount',
        sorter: (a, b) => a.todayDoneCount-b.todayDoneCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '昨日增量',
        dataIndex: 'yesterdayDoneCount',
        sorter: (a, b) => a.yesterdayDoneCount-b.yesterdayDoneCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '今日增率',
        dataIndex: 'toadyRate',
        sorter: (a, b) => a.toadyRate-b.toadyRate,
        render:(text,record)=>{
          return text?(text*100).toFixed(2)+'%':'0%'
        }
      },
      {
        title: '今日目标完成率',
        dataIndex: 'todayAudit2Count',
        sorter: (a, b) => (a.todayAudit2Count/a.yesterdayAudit2Count*100)-(b.todayAudit2Count/b.yesterdayAudit2Count*100),
        render:(text,record)=>{
          return text?(text/record.yesterdayAudit2Count*100).toFixed(2)+'%' :'0%'
        }
      },
      {
        title: '累计进店完成',
        dataIndex: 'doneCount',
        sorter: (a, b) => a.doneCount-b.doneCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '累计已上传',
        dataIndex: 'uploadedCount',
        sorter: (a, b) => a.uploadedCount-b.uploadedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '累计待提交',
        dataIndex: 'unAudit1Count',
        sorter: (a, b) => a.unAudit1Count-b.unAudit1Count,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '当前进度%（不含退回）',
        dataIndex: 'currentProgress',
        sorter: (a, b) => a.audit2TotalCount/a.masterCount-b.audit2TotalCount/b.masterCount,
        render:(text,record)=>{
          return record.audit2TotalCount && record.masterCount ?
          (record.audit2TotalCount/record.masterCount*100).toFixed(2)+'%'
          :'0%'
        }
      },
      {
        title: '共产生退回店数量',
        dataIndex: 'refusedCount',
        sorter: (a, b) => a.refusedCount-b.refusedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '未完成退回店数量',
        dataIndex: 'unReplyCount',
        sorter: (a, b) => a.unReplyCount-b.unReplyCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: 'A/A+门店目标量',
        dataIndex: 'aAplusMasterCount',
        sorter: (a, b) => a.aAplusMasterCount-b.aAplusMasterCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
          title: 'A/A+门店完成量',
          dataIndex: 'aAplusAudit2TotalCount',
          sorter: (a, b) => a.aAplusAudit2TotalCount-b.aAplusAudit2TotalCount,
          render: (text, record) => {
              return text ? text : '0'
          }
      },
      {
          title: 'A/A+门店完成率',
          dataIndex: 'aAplusRate',
          sorter: (a, b) => a.aAplusAudit2TotalCount/a.aAplusMasterCount-b.aAplusAudit2TotalCount/b.aAplusMasterCount,
          render: (text, record) => {
              return record.aAplusAudit2TotalCount && record.aAplusMasterCount ?
              (record.aAplusAudit2TotalCount/record.aAplusMasterCount*100).toFixed(2)+'%'
              :'0%'
          }
      },
      {
        title: '木糖醇门店目标量',
        dataIndex: 'xylitolMasterCount',
        sorter: (a, b) => a.xylitolMasterCount-b.xylitolMasterCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '木糖醇门店完成量',
        dataIndex: 'xylitolAudit2TotalCount',
        sorter: (a, b) => a.xylitolAudit2TotalCount-b.xylitolAudit2TotalCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '木糖醇门店完成率',
        dataIndex: 'specificRate',
        sorter: (a, b) =>(a.xylitolAudit2TotalCount/a.xylitolMasterCount*100)-(b.xylitolAudit2TotalCount/b.xylitolMasterCount*100),
        render:(text,record)=>{
          return record.xylitolAudit2TotalCount && record.xylitolMasterCount ?
          (record.xylitolAudit2TotalCount/record.xylitolMasterCount*100).toFixed(2)+'%'
          :'0%'
        }
      },
      {
        title: '特殊标记门店目标量',
        dataIndex: 'specificMasterCount',
        sorter: (a, b) => a.specificMasterCount-b.specificMasterCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '特殊标记门店完成量',
        dataIndex: 'specificAudit2TotalCount',
        sorter: (a, b) => a.specificAudit2TotalCount-b.specificAudit2TotalCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '特殊标记门店完成率',
        dataIndex: 'specificRate',
        sorter: (a, b) => a.specificAudit2TotalCount/a.specificMasterCount-b.specificAudit2TotalCount/b.specificMasterCount,
        render:(text,record)=>{
          return record.specificAudit2TotalCount && record.specificMasterCount ?
          (record.specificAudit2TotalCount/record.specificMasterCount*100).toFixed(2)+'%'
          :'0%'
        }
      },
    ])

  const [dataSource, setDataSource] = useState([])

  const getdata = () =>{
    axios.get('/api/jhStatistic/projectStatisticList')
    .then(res=>{
      res.data.reverse()
      console.log(res.data)
      setDataSource(res.data)
      banma()
    })
  }

  const banma = ()=>{
    setTimeout(()=>{
      let domarr = document.getElementsByTagName('tr')
      domarr = [...domarr]
      domarr.forEach((v,i) => {
        if(i%2== 0){
          v.style.background='#f1f1f1'
        }else{
          v.style.background='#fff'
        }
      });
    },200)
  }

  useEffect(() => {
    getdata()
  }, [])

  const Export = () => {
    axios.get('/api/jhStatistic/projectStatisticListExport')
    .then(res=>{
      console.log(res)
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            {
              //<Button onClick={Export}>导出列表</Button>
            }
          </Col>
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true }} onChange={e=>{banma()}}/>

      </div>
    </div>
  )
}

export default App
