import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Radio, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [labelValues, setlabelValues] = useState([])

  // table
  const columns = [
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      key: 'random',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    }, {
      title: '访问员',
      dataIndex: 'principal',
      render: text => text ? text : '-',
			sorter: (a, b) => a.principal?.localeCompare(b.principal),
    }, {
      title: '代理人',
      dataIndex: 'agent',
      render: text => text ? text : '-',
			sorter: (a, b) => a.agent?.localeCompare(b.agent),
    }, {
      title: '二审人员',
      dataIndex: 'audit2ByName',
      render: text => text ? text : '-',
			sorter: (a, b) => a.audit2ByName?.localeCompare(b.audit2ByName),
    },
    {
      title: '门店类型',
      dataIndex: 'shopLevel',
      render: text => text ? text : '-',
			sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '二审时间',
      dataIndex: 'audit2At',
      render: text => text ? moment(text).format('YYYY-MM-DD') : '-',
			sorter: (a, b) => a.audit2At?.localeCompare(b.audit2At),
    },
    {
      title: '木糖醇标记',
      dataIndex: 'xylitolFlag',
      key: 'xylitolFlag',
      render: (text, record) => (
        <>
          {
            text ? text : '- -'
          }
        </>
      )
    },
    {
      title: '一审状态',
      dataIndex: 'taskAudit1Status',
      render: (text, record) => text?.display ? text.display == '已退回' ? record.audit1RefusedReasonType ? '已退回-需' + record.labelValues[record.audit1RefusedReasonType] : '已退回' : text.display : '-'
    },
    {
      title: '二审状态',
      dataIndex: 'taskAudit2Status',
      render: (text, record) => text?.display ? text.display == '已退回' ? record.audit2RefusedReasonType ? '已退回-需' + record.labelValues[record.audit2RefusedReasonType] : '已退回' : text.display : '-'
    },
    {
      title: '复审状态',
      dataIndex: 'taskReviewStatus',
      render: (text, record) => text?.display ? text.display == '已退回' ? record.audit2RefusedReasonType ? '已退回-需' + record.labelValues[record.reviewRefusedReasonType] : '已退回' : text.display : '-'
    },
    {
      title: '申诉原因',
      dataIndex: 'audit1AppealRemark',
      key: 'audit1AppealRemark',
      render: (text, record) => (
        <>
          {
            text ? text : '- -'
          }
        </>
      )
    },
    {
      title: '考核得分',
      dataIndex: 'checkScore',
      render: text => text ? (text*100)?.toFixed(2) + '%'  : '-',
			sorter: (a, b) => a.checkScore-b.checkScore,
    },
    {
      title: '总退回次数',
      dataIndex: 'refusedCount',
      render: text => text ? text : '-',
      sorter: (a, b) => a.refusedCount-b.refusedCount,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button type='link' size='small' onClick={e => push_page(record)}>查看</Button>
        </>
      )
    },
  ]
  //
  // 查看详情函数
  const push_page = e => {
    store.dispatch({
      type: 'selectCache_init', value: {
        page: 'jh-checklist',
        select: {
          surveyorSelect,
          scopeSelect,
          dsrSelect,
          codeSelect,
          firstStatusSelect,
          secondStatusSelect,
          reviewStatusSelect,
          refusedCount,
          dataSource,
          audit2ByIdSelect,
          reviewByIdSelect,
          startPointDesc
        }
      }
    })
    push('/jh/' + projectid + '/check/particular/' + e.id)
  }

  // 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()
  useEffect(() => {
    if (store.getState().selectCache.page === 'jh-checklist') {
      const data = store.getState().selectCache.select
      Promise.all([
        new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
        new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
        new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
        new Promise(r => setCodeSelect(data.codeSelect, () => r())),
        new Promise(r => setFirstStatusSelect(data.firstStatusSelect, () => r())),
        new Promise(r => setSecondStatusSelect(data.secondStatusSelect, () => r())),
        new Promise(r => setReviewStatusSelect(data.reviewStatusSelect, () => r())),
        new Promise(r => setrefusedCount(data.refusedCount, () => r())),
        new Promise(r => setReviewByIdSelect(data.reviewByIdSelect, () => r())),
        new Promise(r => setAudit2ByIdSelect(data.audit2ByIdSelect, () => r())),
        new Promise(r => setStartPointDesc(data.startPointDesc, () => r())),
      ]).then(res => {
        setGetData_flag(true)
        store.dispatch({ type: 'selectCache_clear' })
      })
    } else {
      setGetData_flag(true)
      store.dispatch({ type: 'selectCache_clear' })
    }
  }, [])

  const [getData_flag, setGetData_flag] = useState(false)

  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const [vistorSelect, setVistorSelect] = useState(null)
  const [refusedCountarr, setrefusedCountarr] = useState([])
  const [refusedCount, setrefusedCount] = useState(null)

  

  let auditFlag = null

  if (store.getState().user.role == '管理员') {

  }
  if (store.getState().user.role == '复核') {
    auditFlag = 3
  }
  if (store.getState().user.role == '代理人') {
    auditFlag = 1
  }
  if (store.getState().user.role == '二审') {
    auditFlag = 2
  }



  const getData = e => {
    const queryCondition = []
    queryCondition.push('IN:status=21')
    if (store.getState().user.uid) {
      queryCondition.push('EQ:projectId=' + projectid)
    }
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (reviewStatusSelect) queryCondition.push('EQ:taskReviewStatus=' + reviewStatusSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (refusedCount) queryCondition.push('EQ:refusedCount=' + refusedCount)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (audit2ByIdSelect) queryCondition.push('EQ:audit2ById=' + audit2ByIdSelect)
    if (reviewByIdSelect) queryCondition.push('EQ:reviewById=' + reviewByIdSelect)
    if (startPointDesc) queryCondition.push('LIKE:surveyTarget=' + startPointDesc)

    axios.get('/api/core/task/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(','),
        userId: store.getState().user.uid,
        // auditFlag
      }
    }).then(resa => {
      console.log(resa)
      setTotal(resa.data.totalSize)
      const arr = []
      resa.data.data.forEach(v => {
        if (v.refusedCount != null && arr.indexOf(v.refusedCount) == -1 && v.refusedCount != 0) {
          arr.push(v.refusedCount)
        }
      });
      setrefusedCountarr(arr)
      axios.get('/api/sys/dict/' + 'jhTaskRefusedReasonType', {
        params: {
          pageSize: 1000000,
        }
      })
        .then(res => {
          const arr = resa.data.data.map(i => { return { ...i, key: i.id, labelValues: res.data.labelValues } })
          setDataSource([...arr])
        })
    })
  }




  useEffect(() => {
    if (store.getState().user.uid == null) {
      message.error('重新进入页面,请勿刷新')
      return
    }
    if (!getData_flag) return
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })

  }, [updateFlag, getData_flag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [audit1StatusOption, setAudit1StatusOption] = useState([])
  const [audit2StatusOption, setAudit2StatusOption] = useState([])
  const [reviewStatusOption, setReviewStatusOption] = useState([])
  const [scopeOption, setScopeOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])
  const [audit2Option, setAudit2Option] = useState([])
  const [reviewOption, setReviewOption] = useState([])

  useEffect(() => {
    axios.get('/api/sys/dict/taskAudit1Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit1StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskAudit2Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit2StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskReviewStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setReviewStatusOption(arr)
    })
    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_proxy',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setProxyOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_checker',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setAudit2Option(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_reviewer',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setReviewOption(res.data.data)
      })
    })
  }, [])


  // select
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [dsrSelect, setDsrSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [firstStatusSelect, setFirstStatusSelect] = useState(null)
  const [secondStatusSelect, setSecondStatusSelect] = useState(null)
  const [reviewStatusSelect, setReviewStatusSelect] = useState(null)
  const [startPointDesc, setStartPointDesc] = useState(null)
  const [proxySelect, setProxySelect] = useState(null)
  const [audit2ByIdSelect, setAudit2ByIdSelect] = useState(null)
  const [reviewByIdSelect, setReviewByIdSelect] = useState(null)

  const clearSelect = e => {
    setScopeSelect(null)
    setDsrSelect('')
    setCodeSelect('')
    setFirstStatusSelect(null)
    setSecondStatusSelect(null)
    setReviewStatusSelect(null)
    setStartPointDesc(null)
    setSurveyorSelect(null)
    setrefusedCount(null)
    setProxySelect(null)
    setUpdateFlag(pre => pre + 1)
    setReviewByIdSelect(null)
    setAudit2ByIdSelect(null)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setRemark('')
  }
  const [remark, setRemark] = useState('')
  // 退回
  const [taskId, setTaskId] = useState(null)
  const handleBack = e => {
    axios.post('/api/core/jhTaskAudit/audit1Refused', {
      taskId: taskId,
      audit1RefusedReason: remark
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //批量退回
  const handleBackMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/jhTaskAudit/batchAudit1Refused', {
      audit1RefusedReason: remark
    }, {
      params: {
        taskIds: selectedRowKeys.join(',')
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //安排判断
  const handleDoBack = e => {
    if (modalVisible === 'back') handleBack()
    if (modalVisible === 'backMore') handleBackMore()
  }

  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:status=21')
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (reviewStatusSelect) queryCondition.push('EQ:taskReviewStatus=' + reviewStatusSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (refusedCount) queryCondition.push('EQ:refusedCount=' + refusedCount)
    if (startPointDesc) queryCondition.push('LIKE:surveyTarget=' + startPointDesc)
    if (audit2ByIdSelect) queryCondition.push('EQ:audit2ById=' + audit2ByIdSelect)
    if (reviewByIdSelect) queryCondition.push('EQ:reviewById=' + reviewByIdSelect)

    axios.get('/api/core/jhTaskAudit/export', {
      params: {
        pageSize: 1000000,
        queryCondition: queryCondition.join(','),
        taskAuditExportType: 3
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出门店.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  const [pageSize, setPageSize] = useState(5)//显示数据数量
  const [page, setPage] = useState(1)//数据页数
  const [total, setTotal] = useState(null)//数据总是

  useEffect(() => {
    getData()
  }, [pageSize, page])

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研大区</span>
            <AutoComplete
              onChange={e => { console.log(e); setScopeSelect(e); }}
              style={{ width: '240px', marginLeft: '10px' }}
              options={scopeOption.map(v => { return { value: v } })}
              value={scopeSelect}
            />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>代理人</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                proxyOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          {
            auditFlag == null ?
              <Col span={8}>
                <span style={{ lineHeight: '30px' }}>二审人员</span>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } style={{ width: '240px', marginLeft: '10px' }} value={audit2ByIdSelect} onChange={e => setAudit2ByIdSelect(e)}>
                  <Select.Option value={''}>取消选择</Select.Option>
                  {
                    audit2Option.map((i, index) => (
                      <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                    ))
                  }
                </Select>
              </Col>
              : null
          }
          {
            auditFlag == null ?
              <Col span={8}>
                <span style={{ lineHeight: '30px', }}>复核人员</span>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={reviewByIdSelect} onChange={(e) => { setReviewByIdSelect(e) }} style={{ width: '240px', marginLeft: '10px' }} >
                  <Select.Option value={''}>取消选择</Select.Option>
                  {
                    reviewOption.map((v, i) => {
                      return <Select.Option value={v.id}>{v.name}</Select.Option>
                    })
                  }
                </Select>
              </Col>
              : null
          }
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>一审状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={firstStatusSelect} onChange={e => setFirstStatusSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                audit1StatusOption.map((i, index) => <Select.Option key={index} value={i.value}>{i.text}</Select.Option>)
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>二审状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={secondStatusSelect} onChange={e => setSecondStatusSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                audit2StatusOption.map((i, index) => <Select.Option key={index} value={i.value}>{i.text}</Select.Option>)
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>复核状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={reviewStatusSelect} onChange={e => setReviewStatusSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                reviewStatusOption.map((i, index) => <Select.Option key={index} value={i.value}>{i.text}</Select.Option>)
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>退回次数</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={refusedCount} onChange={e => setrefusedCount(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                refusedCountarr.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研对象</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={startPointDesc} onChange={e => setStartPointDesc(e.target.value)}></Input>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true, total, current: page, onChange: (page, pageSize) => {
              setPage(page)
              setPageSize(pageSize)
            }
          }}
        />

        <Modal
          width={640}
          title={'退回门店'}
          visible={modalVisible === 'back' || modalVisible === 'backMore'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleDoBack}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }} >退回理由:</Col>
            <Col span={14}>
              <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
            </Col>
          </Row>
        </Modal>

      </div>
    </div>
  )
}

export default App