import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { stringToNumber, filterSet } from '@/utils/common'
import { baseUrl } from '@/utils/config'
import store from '@/redux/store'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [refresh, setRefresh] = useState(false);

  useEffect(() => { //强制渲染页面
    refresh && setTimeout(() => setRefresh(false))
  }, [refresh])

  // table
  const [columns, setColumns] = useState([
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    }, {
      title: '访问员',
      dataIndex: 'principal',
      render: text => text ? text : '-',
      sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '代理人',
      dataIndex: 'agent',
      render: text => text ? text : '-',
      sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
      title: '门店类型',
      dataIndex: 'shopLevel',
      render: text => text ? text : '-',
      sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      render: text => text.display ? text.display : '-',
      sorter: (a, b) => a.status.display?.localeCompare(b.status.display),
    },
    {
      title: '一审',
      dataIndex: 'agent',
      render: text => text ? text : '-'
    },
    {
      title: '二审',
      dataIndex: 'audit2ByName',
      render: text => text ? text : '-'
    },
    {
      title: '复审',
      dataIndex: 'reviewByName',
      render: text => text ? text : '-'
    },
    {
      title: '扫dsr审核人员',
      dataIndex: 'dsrAuditByName',
      render: text => text ? text : '-'
    },
    {
      title: '二审时间',
      dataIndex: 'audit2At',
      render: text => text ? moment(text).format('YYYY-MM-DD') : '-',
      sorter: (a, b) => a.audit2At?.localeCompare(b.audit2At),
    },
    {
      title: '开始时间',
      dataIndex: 'startAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
        a.startAt?a.startAt=a.startAt:a.startAt='1'
        b.startAt?b.startAt=b.startAt:b.startAt='1'
        return a.startAt?.localeCompare(b.startAt)
      },
    },
    {
      title: '结束时间',
      dataIndex: 'endAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
          a.endAt?a.endAt=a.endAt:a.endAt='1'
          b.endAt?b.endAt=b.endAt:b.endAt='1'
          return a.endAt?.localeCompare(b.endAt)
      },
    },
    {
      title: '自动审核通过',
      dataIndex: 'taskAudit2Status',
      render: text => text.display == '已自动审核' ? text.display : '-',
    },
    {
      title: '原始得分',
      dataIndex: 'originalScore',
      render: text => text ? (text*100)?.toFixed(2) + '%' : '-',
      sorter: (a, b) => a.originalScore?.localeCompare(b.originalScore),
    },
    {
      title: '标准得分',
      dataIndex: 'standardScore',
      render: text => text ? (text*100)?.toFixed(2) + '%'  : '-',
      sorter: (a, b) => a.standardScore-b.standardScore,
    },
    {
      title: '考核得分',
      dataIndex: 'checkScore',
      render: text => text ? (text*100)?.toFixed(2) + '%'  : '-',
      sorter: (a, b) => a.checkScore-b.checkScore,
    },
    {
      title: '总退回次数',
      dataIndex: 'refusedCount',
      render: text => text ? text : '0',
      sorter: (a, b) => a.refusedCount-b.refusedCount,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      showHeader:store.getState().user.role != '代理人' && store.getState().user.role != '二审',
      render: (text, record) => (
        <>
        {store.getState().user.role != '代理人' && store.getState().user.role != '二审'?
          <Button size='small' type="link" onClick={e => push_page(record)}>查看</Button>
          :null}
        </>
      )
    },
  ])
  //

  const push_page = e => {
    store.dispatch({
      type: 'selectCache_init', value: {
        page: 'jh-Completed',
        select: {
          surveyorSelect,
          dateSelect,
          statusSelect,
          scopeSelect,
          planSelect,
          audit2ById,
          reviewById,
          codeSelect,
          targetSelect,
          audit2ByIdSelect
        }
      }
    })
    // sessionStorage.setItem('Completed',true)
    push('/jh/' + projectid + '/check/particular/' + e.id)
  }

  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)

  const getData = e => {
    if(store.getState().user.uid==null) return
    const queryCondition = []
    if((store.getState().user.role == '二审')){
      queryCondition.push('IN:status=21!3')
    }else{
      queryCondition.push('IN:status=3')
    }
    queryCondition.push('IN:assignStatus=1!2')
    queryCondition.push('EQ:projectId=' + projectid)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:audit2At=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:audit2At=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }
    if(store.getState().user.role == '二审'){
      queryCondition.push('EQ:audit2ById=' + store.getState().user.uid)
    }
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (audit2ById) queryCondition.push('EQ:audit2ById=' + audit2ById)
    if (reviewById) queryCondition.push('EQ:reviewById=' + reviewById)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (planSelect) queryCondition.push('IN:assignStatus='+planSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (audit2ByIdSelect) queryCondition.push('EQ:dsrAuditById=' + audit2ByIdSelect)

    axios.get('/api/core/task/list', {
      params: {
        pageSize: 1000000,
        queryCondition: queryCondition.join(','),
      }
    }).then(res => {
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  
  useEffect(() => {
    if (store.getState().selectCache.page === 'jh-Completed') {
      const data = store.getState().selectCache.select
      Promise.all([
        new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
        new Promise(r => setAudit2ByIdSelect(data.audit2ByIdSelect, () => r())),
        new Promise(r => setDateSelect(data.dateSelect, () => r())),
        new Promise(r => setStatusSelect(data.statusSelect, () => r())),
        new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
        new Promise(r => setPlanSelect(data.planSelect, () => r())),
        new Promise(r => setAudit2ById(data.audit2ById, () => r())),
        new Promise(r => setReviewById(data.reviewById, () => r())),
        new Promise(r => setCodeSelect(data.codeSelect, () => r())),
        new Promise(r => setTargetSelect(data.targetSelect, () => r())),
      ]).then(res => {
        store.dispatch({ type: 'selectCache_clear' })
      })
    } else {
      store.dispatch({ type: 'selectCache_clear' })
    }
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [updateFlag])

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [taskStatusOption, setTaskStatusOption] = useState([])
  const [audit2Option, setAudit2Option] = useState([])
  const [reviewOption, setReviewOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])
  const [scopeOption, setScopeOption] = useState([])

  const [projectdata,setprojectdata] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/taskStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setTaskStatusOption(arr)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      setprojectdata(res.data)
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_checker',
          groupId: res.data.userGroupId,
        }
      }).then(res => {
        setAudit2Option(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_reviewer',
          groupId: res.data.userGroupId,
        }
      }).then(res => {
        setReviewOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_proxy',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode:'jiahua'
        }
      }).then(res => {
        setProxyOption(res.data.data)
      })
    })
    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
  }, [])


  // select
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [dateSelect, setDateSelect] = useState([])
  const [statusSelect, setStatusSelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [planSelect, setPlanSelect] = useState(null)
  const [audit2ById, setAudit2ById] = useState(null)
  const [reviewById, setReviewById] = useState(null)
  const [codeSelect, setCodeSelect] = useState('')
  const [proxySelect, setProxySelect] = useState(null)
  const [targetSelect, setTargetSelect] = useState('')
  const [secondStatusSelect, setSecondStatusSelect] = useState(null)
  const [audit2ByIdSelect, setAudit2ByIdSelect] = useState(null)

  const clearSelect = e => {
    setAudit2ByIdSelect(null)
    setDateSelect([])
    setStatusSelect(null)
    setScopeSelect(null)
    setCodeSelect('')
    setSurveyorSelect(null)
    setPlanSelect(null)
    setUpdateFlag(pre => pre + 1)
    setAudit2ById(null)
    setReviewById(null)
    setProxySelect(null)
    setTargetSelect('')
    setSecondStatusSelect(null)
  }

	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const handleSelectChange = e => {
		setSelectedRowKeys(e)
	}
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>
        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
             style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研大区</span>
            <AutoComplete
              onChange={e => { console.log(e); setScopeSelect(e); }}
              style={{ width: '240px', marginLeft: '10px' }}
              options={scopeOption.map(v => { return { value: v } })}
              value={scopeSelect}
          />
          </Col>
          <Col span={8} >
            <span style={{ lineHeight: '30px' }} >调研对象</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }} >自动审核</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={secondStatusSelect} onChange={e => setSecondStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
              <Select.Option key={0} value={21} >已自动审核</Select.Option>)
            </Select>
          </Col>
          {
            store.getState().user.role == '管理员'?<>
                <Col span={8}>
                  <span style={{ lineHeight: '30px' }}>二审人员</span>
                  <Select 
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } style={{ width: '240px', marginLeft: '10px' }} value={audit2ById} onChange={e => { setAudit2ById(e) }}>
                      {//<Select.Option value={0}>无审核员</Select.Option>
                      }
                      {
                        audit2Option.map((i, index) => (
                          <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                        ))
                      }
                    </Select>
                </Col>
                <Col span={8}>
                  <span style={{ lineHeight: '30px' }}>复核人员</span>
                  <Select 
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } style={{ width: '240px', marginLeft: '10px' }} value={reviewById} onChange={e => { setReviewById(e) }}>
                      {//<Select.Option value={0}>无审核员</Select.Option>
                      }
                      {
                        reviewOption.map((i, index) => (
                          <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                        ))
                      }
                    </Select>
                </Col>
                <Col span={8}>
                  <span style={{ lineHeight: '30px' }}>代理人</span>
                  <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
                  <Select.Option value={''}>取消选择</Select.Option>
                    {
                      proxyOption.map((i, index) => (
                        <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                      ))
                    }
                  </Select>
                </Col>
                <Col span={8}>
                  <span style={{ lineHeight: '30px' }}>DSR审核</span>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } style={{ width: '240px', marginLeft: '10px' }} value={audit2ByIdSelect} onChange={e => setAudit2ByIdSelect(e)}>
                    <Select.Option value={''}>取消选择</Select.Option>
                    {
                      audit2Option.map((i, index) => (
                        <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                      ))
                    }
                  </Select>
                </Col>
            </>:null
          }
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
          
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>
        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }} >
              <Col >< Button danger type='primary' onClick={handleClearSelect} >清空</Button></Col >
              <Col >{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            : null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger:true }}
        />
      </div>
    </div>
  )
}

export default App