import React, { useEffect, useState, } from 'react';
import { HashRouter, Switch, Route, useHistory , useParams } from 'react-router-dom';
import { Row, Col, Tabs, Layout, Menu, Tree } from 'antd';
import { EditOutlined, PlusOutlined,  } from '@ant-design/icons';
import axios from '@/utils/axios';
import WangEditor from './component/wangEditor';
const { TabPane } = Tabs;
const { data } = require('./data/issue')

function App(props) {
  const [projectId, setprojectId] = useState(useParams().projectid)//id
  
  const { Header, Content, Footer, Sider } = Layout;

  const [heights,setHeights] = useState(0)
  useEffect(()=>{
    setTimeout(()=>{
      setHeights(document.getElementById('lefts').offsetHeight)
    },1000)
  },[])

  const [code,setCode] = useState(null)
  const [bools,setBools] = useState(0)

  return (
    <div style={{ width:'100%',height:'100%' ,background:'#fff', borderTop:'3px solid #ccc' }}>
      <Layout style={{ height: '100%',display:'flex'}}  id='lefts'>
        <Sider
          style={{
            overflow: 'auto',
            height: '100%',
            background:'#fff',
            borderRight:'0.5px solid #ccc',
          }}
        > 
          <Tree treeData={data} style={{height:'100%',background:'#fff'}} onSelect={(selectedKeys,e)=>{setCode(e.node.code);setBools(bools+1)}} defaultExpandAll /> 
        </Sider>
        <Layout className="site-layout" style={{flex:1, height: '100%',overflow:'hidden' , borderTop:'none' }}>
            <WangEditor code={code} bools={bools} heights={heights} typedata={data}></WangEditor>
        </Layout>
      </Layout>
    </div>
  )
}

export default App
