import React from 'react'

function listToArray(list, option) {
  const target = []
  const currentName = option?.currentName || 'id'
  const parentName = option?.parentName || 'parent'
  const childrenName = option?.childrenName || 'children'

  list.forEach((i, index) => {
    if (i[parentName] === 0) target.push({ ...i, key: i.id })
  })

  const forParent = (arr) => {
    arr.forEach((i, index) => {
      list.forEach((i_, index_) => {
        if (i_[parentName] === i[currentName]) {
          i[childrenName] = i[childrenName] || []
          i[childrenName].push({ ...i_, key: i_.id })
        }
      })
      if (i[childrenName])
        forParent(i[childrenName])
    })
  }

  forParent(target)

  return target
}

function listToArray_people(list, option) {
  const target = JSON.parse(JSON.stringify(list))
  const vistor = []
  const list_all = []

  const forParent = i => {
    i.key = i.id
    i.children = i.subOrgs
    delete i.subOrgs
    list_all.push({ id: i.id, code: i.code })
    if (i.children)
      i.children.forEach(i_ => {
        forParent(i_)
      })
  }

  const forParent_ = (i, flag) => {
    if (flag) {
      vistor.push(i.id)
      i.children && i.children.forEach(i_ => {
        forParent_(i_, true)
      })
    }
    if (i.name === '访问员') {
      vistor.push(i.id)
      i.children && i.children.forEach(i_ => {
        forParent_(i_, true)
      })
    }

    i.children && i.children.forEach(i_ => {
      forParent_(i_)
    })
  }

  forParent(target)
  forParent_(target)

  return { tree: target, vistor: vistor, list_all: list_all }
}

export { listToArray, listToArray_people }