
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useParams } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Transfer, Upload, Radio, Tag  } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import file from '@/assets/jhmddr.xlsx'
import { stringToNumber, filterSet } from '@/utils/common'
import PopConfirmOnly from '@/utils/PopConfirmOnly'
import difference from 'lodash/difference';

import locale from 'antd/lib/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

function App(props) {

  const { id } = useParams()
  const { push } = useHistory()
  const [columns, setColumns] = useState([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '算分标准ID',
      dataIndex: 'standardId',
      key: 'standardId'
    },
    {
      title: '品牌ID',
      dataIndex: 'brandId',
      key: 'brandId'
    },
    {
      title: '品牌名称',
      dataIndex: 'brandName',
      key: 'brandName'
    },
    {
      title: '标准数量',
      dataIndex: 'standardQty',
      key: 'standardQty'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Divider type='vertical' />
          <Button size='small' type='link' onClick={e => {upData(record)}}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  //

  const upData = r =>{
    axios.get('/api/core/product/list',{
      params:{
        productGroupId:id.split('-')[1],
				pageSize: 1000000,
      }
    })
    .then(res=>{
      console.log(r)
      console.log(res.data)
      const objc = res.data.data.filter(v=>{
        v.key = v.id.toString()
        return v.id==r.brandId
      })
      setchanpindata(res.data.data.filter(v=>{
        return v.parentCode.indexOf(objc[0]?.productCode)!=-1 && v.parentCode!=objc[0]?.productCode
      }).reverse())
    })
    setobj(Object.assign({},r))
    let queryCondition = 'EQ:standardId='+id.split('-')[0]+','+"EQ:standardItemId="+r.id
    axios.get('/api/core/standardItemProduct/list',{
      params:{
				pageSize: 1000000,
        queryCondition
      }
    })
    .then(res=>{
      console.log(res.data)
      setstate({
        targetKeys: res.data.data.map(v=>v.productId.toString()), 
        disabled:state.disabled,
        showSearch:state.showSearch,
      })
    })
    setaddupdatabool(true)
    setIsModalVisible(true)
  }

  const [dataSource,setdataSource] = useState([])//数据

  const [addupdatabool,setaddupdatabool] = useState(false)

  const getdata = e =>{
    let queryCondition = 'EQ:standardId='+id.split('-')[0]
    if(brandName&&e==undefined)queryCondition+="LIKE:brandName="+brandName

    axios.get('/api/core/standardItem/list',{
      params:{
				pageSize: 1000000,
        queryCondition
      }
    })
    .then(res=>{
      setdataSource(res.data.data)
    })
  }

  
  const handleOk = () => { // 添加修改
    if(!obj.brandId && !obj.brandName && !obj.standardQty ){message.error('请检查未填写的问题');return}
    if(addupdatabool){
      axios.put('/api/core/standardItem',{
        ...obj,
        standardItemProducts:state.targetKeys.map(v=>{
          return {
            productId:v,
            barCode :chanpindata.filter(a=>{
              return a.id == v
            })[0].barCode
          }
        })
      })
      .then(res=>{
        message.success('修改成功');
        setobj({})
        setIsModalVisible(false);
        setchanpindata([])
        getdata()
      })
    }else{
      obj.standardId=id.split('-')[0]
      axios.post('/api/core/standardItem',{
        ...obj,
        standardItemProducts:state.targetKeys.map(v=>{
          return {
            productId:v,
            barCode :chanpindata.filter(a=>{
              return a.id == v
            })[0].barCode
          }
        })
      })
      .then(res=>{
        message.success('添加成功');
        setobj({})
        setIsModalVisible(false);
        getdata()
        setchanpindata([])
        setstate({
          targetKeys: [], 
          disabled:state.disabled,
          showSearch:state.showSearch,
        })
      })
    }
  };

  const [Group,setGroup] = useState([])//产品组数据

  useEffect(()=>{
    getdata()
    axios.get('/api/core/product/list',{
      params:{
        productGroupId:id.split('-')[1],
        pageSize: 1000000,
      }
    })
    .then(res=>{
      console.log(res)
      setGroup(res.data.data.filter(v=>{
        return v.parentCode==''
      }).reverse())
    })
  },[])
  
  //删除
  const handleDelete = e => {
    axios.delete('/api/core/standardItem/' + e).then(res => {
      message.success()
      getdata()
    })
  }

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])//多选
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }

  const [isModalVisible, setIsModalVisible] = useState(false);


  const handleCancel = () => {
    setIsModalVisible(false);
    setobj({})
    setchanpindata([])
    setstate({
      targetKeys: [], 
      disabled:state.disabled,
      showSearch:state.showSearch,
    })
  };

  const [obj,setobj] = useState({})

  const [brandName,setbrandName] = useState('')

  const [chanpindata,setchanpindata] = useState([])

  const dataobject = (name,value)=>{
    obj[name]=value
    if(name == 'brandId')obj.brandName = Group.filter(v=>{return v.id == value})[0].productName
    if(name == 'brandId'){
      axios.get('/api/core/product/list',{
        params:{
          productGroupId:id.split('-')[1],
          pageSize: 1000000,
        }
      })
      .then(res=>{
        axios.get('/api/core/standardItemProduct/list',{
          params:{
            pageSize: 1000000,
            queryCondition:'EQ:standardId='+id.split('-')[0]
          }
        })
        .then(ress=>{
        const objc = res.data.data.filter(v=>{
          v.key = v.id.toString()
          return v.id==value
        })
        setchanpindata(res.data.data.filter(v=>{
          return v.parentCode.indexOf(objc[0]?.productCode)!=-1 && v.parentCode!=objc[0]?.productCode && JSON.stringify(ress.data.data).indexOf('d":'+v.id)==-1
        }).reverse())
      })
      })
    }
    setobj(Object.assign({},obj))
  }

  const remove = e =>{//重置
    setbrandName('')
    getdata(1)
  }

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={false}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;
  
        const rowSelection = {
          getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };
  
        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {   
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );

const originTargetKeys = Group.filter(item => +item.key % 3 > 1).map(item => item.key);

const leftTableColumns = [
  {
    dataIndex: 'productName',
    title: '产品名称',
    key:'id'
  },
  {
    dataIndex: 'specification',
    title: '规格',
    key:'id',
    render: tag => <Tag>{tag}</Tag>,
  },
];
const rightTableColumns = [
  {
    dataIndex: 'productName',
    title: '产品名称',
    key:'id'
  },
  {
    dataIndex: 'specification',
    title: '规格',
    key:'id',
    render: tag => <Tag>{tag}</Tag>,
  },
];


  const [state,setstate] = useState({ //穿梭框的数据
    targetKeys: originTargetKeys,
    disabled: false,
    showSearch: false,
  });

  const onChange = nextTargetKeys => {
    setstate({ 
      targetKeys: nextTargetKeys, 
      disabled:state.disabled,
      showSearch:state.showSearch,
    });
  };

  const triggerDisable = disabled => {
    setstate({ 
      targetKeys: state.targetKeys, 
      disabled,
      showSearch:state.showSearch,
    });
  };

  const triggerShowSearch = showSearch => {
    setstate({ 
      targetKeys: state.targetKeys, 
      disabled:state.disabled,
      showSearch,
    });
  };

  return (
    <div style={{ padding: '30px' }}>
      <Modal title={addupdatabool?'修改算分标准明细':"添加算分标准明细"} 
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        destroyOnClose={true}
        width="1000px"
      >
        <Row>
          <Col span={6} style={{ textAlign: 'right', lineHeight: '30px', marginBottom:'10px', display:'flex',  alignItems:'center' }}><font color='red'>*</font>品牌:</Col>
          <Col span={18} style={{marginBottom:'10px', display:'flex',  alignItems:'center' }}>
          <Select style={{ width: '100%' }} value={obj.brandId} onChange={e=>{dataobject('brandId',e)}}>
            {
              Group.map(v=>{
                return <Select.Option value={v.id}>{v.productName}</Select.Option>
              })
            }
          </Select>
          </Col>
          <Col span={6} style={{ textAlign: 'right', lineHeight: '30px', marginBottom:'10px', display:'flex',  alignItems:'center' }}><font color='red'>*</font>标准数量:</Col>
          <Col span={18} style={{marginBottom:'10px', display:'flex',  alignItems:'center' }}>
            <Input value={obj.standardQty} onChange={e=>dataobject('standardQty',e.target.value)} ></Input>
          </Col>
          <TableTransfer
            style={{width:'100%'}}
            dataSource={chanpindata}
            targetKeys={state.targetKeys}
            disabled={state.disabled}
            showSearch={state.showSearch}
            onChange={onChange}
            filterOption={(inputValue, item) =>
              item.title.indexOf(inputValue) !== -1 || item.tag.indexOf(inputValue) !== -1
            }
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
          />
          <Switch
            unCheckedChildren="disabled"
            checkedChildren="disabled"
            checked={state.disabled}
            onChange={triggerDisable}
            style={{ marginTop: 16 }}
          />
          <Switch
            unCheckedChildren="showSearch"
            checkedChildren="showSearch"
            checked={state.showSearch}
            onChange={triggerShowSearch}
            style={{ marginTop: 16 }}
          />
        </Row>
      </Modal>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            <span style={{ lineHeight: '30px' }}>品牌名称</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请填写信息' value={brandName} onChange={e => { setbrandName(e.target.value) }}></Input>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={()=>{setIsModalVisible(true);setaddupdatabool(false);
              setstate({
                targetKeys: [], 
                disabled:state.disabled,
                showSearch:state.showSearch,
              })}}><PlusOutlined />添加算分标准明细  </Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e=>getdata()}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={e=>remove()}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' >清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger:true }}
        />
      </div>
    </div>
  )
}

export default App