import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [isbool, setIsbool] = useState(false)

  // const handleShowBack = e => {
  //   setModalVisible('back')
  //   setTaskId(e.id)
  // }
  // const handleShowPass = e => {
  //   setModalVisible('pass')
  //   setTaskId(e.id)
  // }

  // const JhCheckScord = (e) => {
  // 	axios.get('/api/core/task/genJhCheckScore/'+e.id)
  // 	.then(res=>{
  // 		message.success()
  // 	})
  // }

  // table
  const columns = [{
    title: '调研编号',
    dataIndex: 'surveyCode',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
  },
  {
    title: '调研大区',
    dataIndex: 'surveyScope',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
  }, {
    title: '访问员',
    dataIndex: 'principal',
    render: text => text ? text : '-',
    sorter: (a, b) => a.principal?.localeCompare(b.principal),
  },
  {
    title: '代理人',
    dataIndex: 'agent',
    render: text => text ? text : '-',
    sorter: (a, b) => a.agent?.localeCompare(b.agent),
  },
  {
    title: '门店类型',
    dataIndex: 'shopLevel',
    render: text => text ? text : '-',
    sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
  },
  {
    title: '二审人员',
    dataIndex: 'audit2ByName',
    render: text => text ? text : '-',
    sorter: (a, b) => a.audit2ByName?.localeCompare(b.audit2ByName),
  },
  {
    title: '复核人员',
    dataIndex: 'reviewByName',
    render: text => text ? text : '-',
    sorter: (a, b) => a.reviewByName?.localeCompare(b.reviewByName),
  },
  {
    title: '门店地址',
    dataIndex: 'startPointDesc',
    render: text => text ? text : '-',
    sorter: (a, b) => a.startPointDesc?.localeCompare(b.startPointDesc),
  },
  {
    title: '调研对象',
    dataIndex: 'surveyTarget',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
  },
  {
    title: '一审状态',
    dataIndex: 'taskAudit1Status',
    render: text => text.display ? text.display : '-'
  },
  {
    title: '二审状态',
    dataIndex: 'taskAudit2Status',
    render: text => text.display ? text.display : '-'
  },
  {
    title: '取消退回备注',
    dataIndex: 'cancelRefusedRemark',
    render: text => text ? text : '-'
  },
  {
    title: '复核退回备注',
    dataIndex: 'reviewRefusedRemark',
    render: text => text ? text : '-'
  },
  {
    title: '总退回次数',
    dataIndex: 'refusedCount',
    render: text => text ? text : '0',
    sorter: (a, b) => a.refusedCount-b.refusedCount,
  },
  {
    title: '申诉原因',
    dataIndex: 'audit1AppealRemark',
    key: 'audit1AppealRemark',
    render: (text, record) => (
      <>
        {
          text ? text : '- -'
        }
      </>
    )
  },
  {
    title: '考核得分',
    dataIndex: 'checkScore',
    render: text => text ? (text*100)?.toFixed(2) + '%'  : '-',
    sorter: (a, b) => a.checkScore - b.checkScore,
  },
  {
    title: '木糖醇标记',
    dataIndex: 'xylitolFlag',
    key: 'xylitolFlag',
    render: (text, record) => (
      <>
        {
          text ? text : '- -'
        }
      </>
    )
  },
  {
    title: '二审退回备注',
    dataIndex: 'confirmRefusedRemark',
    key: 'confirmRefusedRemark',
    render: (text, record) => (
      <>
        {
          text ? text : '- -'
        }
      </>
    )
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    render: (text, record) => (
      <>
        <Button size='small' type='link' onClick={e => push_page(record)}>查看</Button>
        {
          isbool ? <>
            <Divider type='vertical' />
            <Button size='small' type='link' onClick={e => JhCheckScord(record)} >计算得分</Button>
          </> : null
        }
      </>
    )
  },
  ]
  //

  const JhCheckScord = (e) => {
    axios.get('/api/core/task/genJhCheckScore/' + e.id)
      .then(res => {
        message.success()
      })
  }

  // 查看详情函数
  const push_page = e => {
    store.dispatch({
      type: 'selectCache_init', value: {
        page: 'jh-checksecond',
        select: {
          surveyorSelect,
          scopeSelect,
          dsrSelect,
          codeSelect,
          targetSelect,
          firstStatusSelect,
          secondStatusSelect,
          reviewByIdSelect,
          audit2ByIdSelect
        }
      }
    })
    push('/jh/' + projectid + '/check/particular/' + e.id)
  }

  // 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()
  useEffect(() => {
    if (store.getState().selectCache.page === 'jh-checksecond') {
      const data = store.getState().selectCache.select
      Promise.all([
        new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
        new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
        new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
        new Promise(r => setCodeSelect(data.codeSelect, () => r())),
        new Promise(r => setAudit2ByIdSelect(data.audit2ByIdSelect, () => r())),
        new Promise(r => setReviewByIdSelect(data.reviewByIdSelect, () => r())),
        new Promise(r => setTargetSelect(data.targetSelect, () => r())),
        new Promise(r => setFirstStatusSelect(data.firstStatusSelect, () => r())),
        new Promise(r => setSecondStatusSelect(data.secondStatusSelect, () => r())),
      ]).then(res => {
        setGetData_flag(true)
        store.dispatch({ type: 'selectCache_clear' })
      })
    } else {
      setGetData_flag(true)
      store.dispatch({ type: 'selectCache_clear' })
    }
  }, [])

  const [getData_flag, setGetData_flag] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition = []
    queryCondition.push('IN:taskAudit2Status=1!32!31')
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (startPointDesc) queryCondition.push('LIKE:startPointDesc=' + startPointDesc)
    if (startaudit1AppealRemark) queryCondition.push('NEQ:audit1AppealRemark=null')
    if (audit2ByIdSelect) queryCondition.push('EQ:audit2ById=' + audit2ByIdSelect)
    if (reviewByIdSelect) queryCondition.push('EQ:reviewById=' + reviewByIdSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)

    axios.get('/api/core/task/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(','),
        userId: store.getState().user.uid,
        auditFlag: 2
      }
    }).then(res => {
      console.log(res)
      setTotal(res.data.totalSize)
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  useEffect(() => {
    if (store.getState().user.uid == null) {
      message.error('重新进入页面,请勿刷新')
      return
    }
    axios.get('/api/sys/user/group/org', {
      params: {
        id: store.getState().user.uid
      }
    })
      .then(res => {
        res.data.forEach((v, i) => {
          if (v.groupId == res.data.userGroupId) {
            v.roles.forEach((v, i) => {
              if (v.name == '家化_管理员') {
                setIsbool(true)
              }
            });
          } else if (v.roles.length != 0 && v.roles[0].name == '超级管理员') {
            setIsbool(true)
          }
        });
      })
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_checker',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setAudit2Option(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_reviewer',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setReviewOption(res.data.data)
      })
    })
  }, [store.getState().user.uid, updateFlag, getData_flag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [audit1StatusOption, setAudit1StatusOption] = useState([])
  const [audit2StatusOption, setAudit2StatusOption] = useState([])
  const [scopeOption, setScopeOption] = useState([])
  const [audit2Option, setAudit2Option] = useState([])
  const [reviewOption, setReviewOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/taskAudit1Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit1StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskAudit2Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit2StatusOption(arr)
    })
    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(resa => {
        console.log(resa)
        axios.get('/api/sys/user/list', {
          params: {
            roleCode: 'jiahua_proxy',
            pageSize: 1000000,
            groupId: res.data.userGroupId,
            orgCode: 'jiahua'
          }
        }).then(ress => {
          setProxyOption(ress.data.data)
          console.log(ress)
          const user = ress.data.data.filter((v => {
            return v.id == store.getState().user.uid
          }))
          axios.get('/api/sys/user/group/org', {
            params: {
              id: user[0]?.id
            }
          })
            .then(resc => {
              const count = resc.data.filter(v => {
                return v.groupId == res.data.userGroupId
              })[0]?.roles.filter(v => {
                return v.code == 'jiahua_proxy'
              })
              console.log(count)
              if (count?.length > 0) {
                const visi = resa.data.data.filter(v => {
                  return v.proxyId == user[0].id
                })
                console.log(visi)
                setSurveyorOption([...visi, user[0]])
              } else {
                setSurveyorOption([...resa.data.data, ...ress.data.data])
              }
            })
        })
      })
    })
  }, [])

  // select
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [dsrSelect, setDsrSelect] = useState('')
  const [targetSelect, setTargetSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [firstStatusSelect, setFirstStatusSelect] = useState(null)
  const [secondStatusSelect, setSecondStatusSelect] = useState(null)
  const [reviewByIdSelect, setReviewByIdSelect] = useState(null)
  const [audit2ByIdSelect, setAudit2ByIdSelect] = useState(null)
  const [proxySelect, setProxySelect] = useState(null)


  const [startPointDesc, setStartPointDesc] = useState(null)
  const [startaudit1AppealRemark, setStartaudit1AppealRemark] = useState(false)

  const clearSelect = e => {
    setScopeSelect(null)
    setDsrSelect('')
    setTargetSelect('')
    setCodeSelect('')
    setFirstStatusSelect(null)
    setSecondStatusSelect(null)
    setSurveyorSelect(null)
    setUpdateFlag(pre => pre + 1)
    setStartPointDesc(null)
    setReviewByIdSelect(null)
    setAudit2ByIdSelect(null)
    setProxySelect(null)
  }

  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:taskAudit2Status=1!31!32')
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (startPointDesc) queryCondition.push('LIKE:startPointDesc=' + startPointDesc)
    if (audit2ByIdSelect) queryCondition.push('EQ:audit2ById=' + audit2ByIdSelect)
    if (reviewByIdSelect) queryCondition.push('EQ:reviewById=' + reviewByIdSelect)

    axios.get('/api/core/jhTaskAudit/export', {
      params: {
        pageSize: 1000000,
        queryCondition: queryCondition.join(','),
        taskAuditExportType: 5,
        userId: store.getState().user.uid
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出门店.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])

	const [remark, setRemark] = useState('')

	const hanldePassMore = e => {
		if (selectedRowKeys.length === 0) return
		axios.post('/api/core/jhTaskAudit/batchAudit2Passed', {
			audit1PassedReason: remark
		}, {
			params: {
				taskIds: selectedRowKeys.join(',')
			}
		}).then(res => {
			message.success()
			handleModalCancel()
			getData()
		})
	}

	const handleModalCancel = e => {
		setModalVisible(null)
		setRemark('')
	}

	const [modalVisible, setModalVisible] = useState(null)

  return (
    <div style={{ padding: '30px' }}>
      <Modal
        width={640}
        title={'通过门店'}
        visible={ modalVisible === 'passMore'}
        onCancel={handleModalCancel}
        destroyOnClose
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleModalCancel} >取消</Button>,
          <Button key="submit" type="primary" onClick={hanldePassMore} >确认</Button>,
        ]}
      >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }} >
          <Col span={6} style={{ textAlign: 'right' }} >通过备注:</Col>
          <Col span={14} >
            <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
          </Col>
        </Row>
      </Modal>
      <div style={{ padding: '30px', background: 'white' }} >

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }} >
          <Col span={8} >
            <span style={{ lineHeight: '30px' }} >调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
          </Col>
          <Col span={8} >
            <span style={{ lineHeight: '30px' }} >调研大区</span>
            <AutoComplete
              onChange={e => { console.log(e); setScopeSelect(e); }}
              style={{ width: '240px', marginLeft: '10px' }}
              options={scopeOption.map(v => { return { value: v } })}
              value={scopeSelect}
          />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>代理人</span>
            <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
              {
                proxyOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>二审人员</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              } style={{ width: '240px', marginLeft: '10px' }} 
              value={audit2ByIdSelect} onChange={e => setAudit2ByIdSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                audit2Option.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          {
            store.getState().user.role != '二审' ?
              <Col span={8}>
                <span style={{ lineHeight: '30px',}}>复核人员</span>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={reviewByIdSelect} onChange={(e) => { setReviewByIdSelect(e) }} style={{ width: '240px', marginLeft: '10px' }} >
                  <Select.Option value={''}>取消选择</Select.Option>
                  {
                    reviewOption.map((v, i) => {
                      return <Select.Option value={v.id}>{v.name}</Select.Option>
                    })
                  }
                </Select>
              </Col>
              : null
          }
          <Col span={8} >
            <span style={{ lineHeight: '30px' }} >调研对象</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
          </Col>
          <Col span={8} >
            <span style={{ lineHeight: '30px' }} >一审状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={firstStatusSelect} onChange={e => setFirstStatusSelect(e)} >
              <Select.Option value={''}>取消选择</Select.Option>
              {
                audit1StatusOption.map((i, index) => <Select.Option key={index} value={i.value} >{i.text}</Select.Option>)
              }
            </Select>
          </Col>
          <Col span={8} >
            <span style={{ lineHeight: '30px' }} >二审状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={secondStatusSelect} onChange={e => setSecondStatusSelect(e)} >
              <Select.Option value={''}>取消选择</Select.Option>
              {
                audit2StatusOption.map((i, index) => <Select.Option key={index} value={i.value} >{i.text}</Select.Option>)
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>门店地址</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={startPointDesc} onChange={e => setStartPointDesc(e.target.value)}></Input>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }} >
          <Col>
            <Button style={{ margin: '0 10px' }} >批量下载</Button>
            <Button style={{ margin: '0 10px' }} onClick={handleExport} >批量导出</Button>
            {
              store.getState().user.role != '二审'?
                <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('passMore')} >批量通过</Button>
              :null
            }
						
            {
              isbool ? <Button style={{ margin: '0 10px' }} onClick={e => { selectedRowKeys.forEach(v => { axios.get('/api/core/task/genJhCheckScore/' + v) }) }}>批量算分</Button> : null
            }
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData} >查询</Button>
            <Checkbox checked={startaudit1AppealRemark} onChange={e => { setStartaudit1AppealRemark(e.target.checked) }}>是否筛选申诉提交</Checkbox>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect} >重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }} >
              <Col >
                < Button danger type='primary' onClick={handleClearSelect} >清空</Button>
              </Col >
              <Col >{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            : null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
          }
        }}
        />

      </div>
    </div>
  )
}

export default App