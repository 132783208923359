export const data =[
    {
        title:'选择题',
        code:'1',
        children:[
            {
                title:'单选题',
                code:'1'
            },
            {
                title:'多选题',
                code:'2'
            },
            {
                title:'单选下拉框',
                code:'3'
            },
            {
                title:'多选下拉框',
                code:'4'
            },
            {
                title:'排序',
                code:'5'
            },
            {
                title:'多级联动',
                code:'6'
            },
            {
                title:'配额',
                code:'7'
            }
        ]
    },
    {
        title:'填空题',
        code:'2',
        children:[
            {
                title:'单项填空',
                code:'21'
            },
            {
                title:'多项填空',
                code:'22'
            },
            {
                title:'矩阵填空',
                code:'23'
            },
            {
                title:'表格填空',
                code:'24'
            }
        ]
    },
    {
        title:'分页说明',
        code:'3',
        children:[
            {
                title:'分页',
                code:'31'
            },
            {
                title:'段落说明',
                code:'32'
            },
        ]
    },
    {
        title:'矩阵题',
        code:'4',
        children:[
            {
                title:'矩阵单选',
                code:'41'
            },
            {
                title:'矩阵多选',
                code:'42'
            },
        ]
    },
    {
        title:'评分题',
        code:'5',
        children:[
            {
                title:'量表题',
                code:'51'
            },
            {
                title:'NPS量表',
                code:'52'
            },
            {
                title:'评分单选',
                code:'53'
            },
            {
                title:'评分多选',
                code:'54'
            },
            {
                title:'矩阵量表',
                code:'55'
            },
        ]
    },
    {
        title:'个人信息',
        code:'6 ',
        children:[
            {
                title:'姓名',
                code:'61'
            },
            {
                title:'性别',
                code:'62'
            },
            {
                title:'手机',
                code:'63'
            },
            {
                title:'日期',
                code:'64'
            },
            // {
            //     title:'地区',
            //     code:'65'
            // },
            // {
            //     title:'地图',
            //     code:'66'
            // },
        ]
    },
]