import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Upload } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import PopConfirmOnly from "@/utils/PopConfirmOnly";

function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [labelValueslist, setlabelValueslist] = useState({})//门店等级列表
  useEffect(() => {
    axios.get('/api/sys/dict/shopLevel')
      .then(res => {
        setlabelValueslist(res.data.labelValues)
      })
  }, [])

  // table
  const [columns, setColumns] = useState([
    {
      title: '门店ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '区域',
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: '门店名称',
      dataIndex: 'shopName',
      key: 'shopName'
    },
    {
      title: 'SFA编号',
      dataIndex: 'sfaCode',
      key: 'sfaCode'
    },
    {
      title: '旭中DSR编号',
      dataIndex: 'xzDsrCode',
      key: 'xzDsrCode'
    },
    {
      title: 'DSR编号',
      dataIndex: 'dsrCode',
      key: 'dsrCode'
    },
    {
      title: '门店编号',
      dataIndex: 'shopCode',
      key: 'shopCode'
    },
    {
      title: '详细地址',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: '门店等级',
      dataIndex: 'shopLevelName',
      key: 'shopLevelName'
    },
    {
      title: '面积范围',
      dataIndex: 'acreage',
      key: 'acreage'
    },
    {
      title: '经度',
      dataIndex: 'longitude',
      key: 'longitude'
    },
    {
      title: '纬度',
      dataIndex: 'latitude',
      key: 'latitude'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const [shopGroupId, setshopGroupId] = useState(null)

  const [areaOption, setAreaOption] = useState([])//筛选区域列表

  const getData = e => {
    const queryCondition = []
    if (nameSelect) queryCondition.push('LIKE:shopName=' + nameSelect)
    if (codeSelect) queryCondition.push('EQ:shopCode=' + codeSelect)
    if (areaSelect) queryCondition.push('EQ:region=' + areaSelect)
    if (levelSelect == 0 || levelSelect) queryCondition.push('EQ:shopLevel=' + levelSelect)
    if (dsrSelect) queryCondition.push('EQ:dsrCode=' + dsrSelect)
    if (xzdsrSelect) queryCondition.push('EQ:xzDsrCode=' + xzdsrSelect)
    if (sfaSelect) queryCondition.push('EQ:sfaCode=' + sfaSelect)
    axios.get('/api/core/project/' + projectid)
      .then(res => {
        setshopGroupId(res.data.shopGroupId)
        axios.get('/api/core/shop/list', {
          params: {
            page,
            pageSize,
            shopGroupId: res.data.shopGroupId,
            queryCondition: queryCondition.join(',')
          }
        }).then(res => {
          setTotal(res.data.totalSize)
          const arr = res.data.data.map(i => { return { ...i, key: i.id } })
          if (queryCondition.length == 0) {//筛选区域列表  
            const brr = arr.map(v => {
              return v.region
            })
            setAreaOption(arr.filter(function (element, index, self) {
              return brr.indexOf(element.region) === index
            }))
          }
          setDataSource(arr)
        })
      })
  }

  const [pageSize, setPageSize] = useState(5)//显示数据数量
  const [page, setPage] = useState(1)//数据页数
  const [total, setTotal] = useState(null)//数据总是

  useEffect(() => {
    getData()
  }, [pageSize, page])

  useEffect(() => {
    getData()
  }, [updateFlag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // select
  const [areaSelect, setAreaSelect] = useState(null)
  const [nameSelect, setNameSelect] = useState('')
  const [levelSelect, setLevelSelect] = useState(null)
  const [dsrSelect, setDsrSelect] = useState('')
  const [xzdsrSelect, setxzDsrSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [sfaSelect, setSfaSelect] = useState('')

  const clearSelect = e => {
    setAreaSelect(null)
    setNameSelect('')
    setLevelSelect(null)
    setDsrSelect('')
    setxzDsrSelect('')
    setCodeSelect('')
    setSfaSelect('')
    setUpdateFlag(pre => pre + 1)
  }

  // 删除
  const handleDelete = e => {
    axios.delete('/api/core/shop/' + e).then(res => {
      message.success()
      getData()
    })
  }
  //批量删除
  const handleDeleteMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.delete('/api/core/shopGroup/batchDel', { params: { ids: selectedRowKeys.join(',') } }).then(res => {
      message.success()
      handleClearSelect()
      getData()
    })
  }
  //导出
  const handleExport = e => {
    axios.get('/api/core/shop/export', {
      params: {
        shopGroupId
        // key: selectText
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出门店.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }
  
  const uploadProps = {
    onRemove: (file) => {
      message.error('请检查表格信息')
      return []
    },
    beforeUpload: (file) => {
      const formData = new FormData()
      formData.append('file', file)
      axios.post('/api/core/shop/import',formData, { 
        params: { 
          shopGroupId
        // regionId :45,
        // regionName :'	铺货佳乐家'
      }, headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        message.success()
        getData()
      })
      .then(res=>{
        message.success('导入成功')
      })
      .catch(res=>{
        message.error('请检查表格信息')
      })
      return false
    }
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>项目区域</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={areaSelect} onChange={e => setAreaSelect(e)}>
              {
                areaOption.map((i, index) => (
                  <Select.Option key={index} value={i.region}>{i.region}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>门店名称</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={nameSelect} onChange={e => setNameSelect(e.target.value)}></Input>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>门店等级</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={levelSelect} onChange={e => setLevelSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                Object.keys(labelValueslist).map((i, index) => (
                  <Select.Option key={index} value={i}>{labelValueslist[i]}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>DSR编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={dsrSelect} onChange={e => setDsrSelect(e.target.value)}></Input>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>旭中DSR编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={xzdsrSelect} onChange={e => setxzDsrSelect(e.target.value)}></Input>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>门店编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)}></Input>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>SFA编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={sfaSelect} onChange={e => setSfaSelect(e.target.value)}></Input>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <PopConfirmOnly confirm={handleDeleteMore}>
              <Button style={{ margin: '0 10px' }} type='primary' >批量删除</Button>
            </PopConfirmOnly>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>上传文件</Button>
            </Upload>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true, total, current: page, onChange: (page, pageSize) => {
              setPage(page)
              setPageSize(pageSize)
            }
          }}
        />

      </div>
    </div>
  )
}

export default App