
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import file from '@/assets/sxzbdr.xlsx'
import { stringToNumber, filterSet } from '@/utils/common'
import PopConfirmOnly from '@/utils/PopConfirmOnly'


function App(props) {

  const { push } = useHistory()
  const handleShowEdit = e => {
    setModalVisible('edit')
    setNameEdit(e.metricGroupName)
    setCodeEdit(e.metricGroupCode)
    setIdEdit(e.id)
  }

  // table
  const [columns, setColumns] = useState([
    {
      title: '指标组名称/编号',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <p style={{ color: '#2A82E4' }}>{record.metricGroupName}</p>
          <p>{record.metricGroupCode}</p>
        </>
      )
    },
    {
      title: '创建人员',
      dataIndex: 'createdByName',
      key: 'createdByName'
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => (
        <>
          {text ? text.split('.')[0] : '-'}
        </>
      ),
      sorter: (a, b) => stringToNumber(a.createdAt) - stringToNumber(b.createdAt)
    },
    {
      title: '修改时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: text => (
        <>
          {text ? text.split('.')[0] : '-'}
        </>
      ),
      sorter: (a, b) => stringToNumber(a.updatedAt) - stringToNumber(b.updatedAt)
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => push('/home/set/targetdetail/' + record.id)}>查看</Button>
          <Divider type='vertical' />
          <Button size='small' type='link' onClick={e => handleShowEdit(record)}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition=[]
      if(peopleSelect)queryCondition.push('EQ:peopleSelect='+peopleSelect)
      if(codeSelect)queryCondition.push('LIKE:metricGroupCode='+codeSelect)
      if(nameSelect)queryCondition.push('LIKE:metricGroupName='+nameSelect)
      if(dateSelect.length!=0)queryCondition.push('GT:createdAt='+moment(dateSelect[0]).format('YYYYMMDD'))
      if(dateSelect.length!=0)queryCondition.push('LT:createdAt='+moment(dateSelect[1]).format('YYYYMMDD'))
    axios.get('/api/core/metricGroup/list', {
      params: {
        page,
        pageSize,
        queryCondition:queryCondition.join(',')
      }
    }).then(res => {
      setTotal(res.data.totalSize)
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])

  useEffect(() => {
    getData()
  }, [updateFlag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  //select
  const [dateSelect, setDateSelect] = useState([])
  const [nameSelect, setNameSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [peopleSelect, setPeopleSelect] = useState(null)
  const [peopleOption, setPeopleOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/user/list', {
      params: {
				pageSize: 1000000,
        roleCode: 'xuzhong_admin',
      }
    }).then(res => {
      setPeopleOption(res.data.data)
    })
  }, [])
  const clearSelect = e => {
    setDateSelect([])
    setNameSelect('')
    setCodeSelect('')
    setPeopleSelect(null)
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setNameAdd('')
    setCodeADD('')
    setNameEdit('')
    setCodeEdit('')
    setIdEdit('')
    setFileListAdd([])
  }
  //
  // 添加
  const [nameAdd, setNameAdd] = useState('')
  const [codeAdd, setCodeADD] = useState('')
  const handleAdd = e => {
    if (codeAdd != '' && nameAdd != '') {
      const formData = new FormData()
      formData.append('file', fileListAdd[0])
      axios.post('/api/core/metricGroup', formData, {
        params:
          { metricGroupCode: codeAdd, metricGroupName: nameAdd },
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(res => {
          message.success()
          handleModalCancel()
          getData()
        })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const [fileListAdd, setFileListAdd] = useState([])
  const uploadProps = {
    onRemove: (file) => {
      setFileListAdd([])
      return []
    },
    beforeUpload: (file) => {
      setFileListAdd([file])
      return false
    }
  }

  // 修改
  const [nameEdit, setNameEdit] = useState('')
  const [codeEdit, setCodeEdit] = useState('')
  const [idEdit, setIdEdit] = useState('')
  const handleEdit = e => {
    if (codeEdit != '' && nameEdit != '') {
      axios.put('/api/core/metricGroup', { id: idEdit, metricGroupCode: codeEdit, metricGroupName: nameEdit }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }

  //删除
  const handleDelete = e => {
    axios.delete('/api/core/metricGroup/' + e).then(res => {
      message.success()
      getData()
    })
  }
  const handleDeleteMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.delete('/api/core/metricGroup', { params: { id: selectedRowKeys.join(',') } }).then(res => {
      message.success()
      handleClearSelect()
      getData()
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
          <Col>
            <span style={{ lineHeight: '30px' }}>指标组编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请填写信息' value={codeSelect} onChange={e => setCodeSelect(e.target.value)}></Input>
          </Col>
          <Col>
            <span style={{ lineHeight: '30px' }}>指标组名称</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请填写信息' value={nameSelect} onChange={e => setNameSelect(e.target.value)}></Input>
          </Col>
          <Col>
            <span style={{ lineHeight: '30px' }}>创建人员</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} placeholder='全选' value={peopleSelect} onChange={e => { setPeopleSelect(e) }}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                peopleOption.map(i => (
                  <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('add')}><PlusOutlined />添加组</Button>
            <PopConfirmOnly confirm={handleDeleteMore}>
              <Button style={{ margin: '0 10px' }} >批量删除</Button>
            </PopConfirmOnly>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
            }
          }}
        />

        <Modal
          width={640}
          title="添加组"
          maskClosable={false}
          visible={modalVisible === 'add'}
          onCancel={handleModalCancel}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleAdd}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标组名称:</Col>
            <Col span={14}><Input value={nameAdd} onChange={e => setNameAdd(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>编号:</Col>
            <Col span={14}><Input value={codeAdd} onChange={e => setCodeADD(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>批量上传:</Col>
            <Col span={14}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>上传文件</Button>
              </Upload>
            </Col>
            <Col span={4}><Button onClick={e => window.open(file)}>下载模板</Button></Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title="修改组"
          maskClosable={false}
          visible={modalVisible === 'edit'}
          onCancel={handleModalCancel}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleEdit}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标组名称:</Col>
            <Col span={14}><Input value={nameEdit} onChange={e => setNameEdit(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>编号:</Col>
            <Col span={14}><Input value={codeEdit} onChange={e => setCodeEdit(e.target.value)}></Input></Col>
          </Row>
        </Modal>
      </div>
    </div>
  )
}

export default App