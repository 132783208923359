import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu, Dropdown, Tabs } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import BarChart from './components/BarChart'
import { registerCoordinateSystem } from 'echarts/lib/echarts';


function App(props) {


  const { push } = useHistory()
  const { projectid, surveyScope } = useRouteMatch().params

  const [curTab, setCurTab] = useState('table')

  const columns = [
    {
      title: '审核员',
      dataIndex: 'audit2ByName',
      render: (text,record) => text ? 
        <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={e => push('/sx/' + projectid + '/data/DataFirstDetails/' + record.surveyScope + '/' + record.audit2ById)}>{text}</span>
      : '-'
    },  
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-'
    },
    {
      title: '一审数量',
      dataIndex: 'audit1Count',
      render: text => text ? text : '-'
    },
    {
      title: '二审数量',
      dataIndex: 'audit2Count',
      render: text => text ? text : '-'
    },
    {
      title: '复核数量',
      dataIndex: 'reviewCount',
      render: text => text ? text : '-'
    },
  ]
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    axios.get('/api/sxStatistic/surveyScopeAudit2Statistic', {
      params: {
        pageSize: 1000000,
        projectId: projectid,
        surveyScope
      }
    }).then(res => {
      const arr = res.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  useEffect(() => {
    getData()
  }, [updateFlag])

  return (
    <>

      <div style={{ padding: '30px' }}>
        <div style={{ padding: '30px', background: 'white' }}>

          <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          </Row>

          {
            curTab === 'table' ?
              <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true  }} />
              : null
          }
        </div>
      </div>

    </>
  )
}

export default App
