import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  // table
  const columns = [
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '门店类型',
      dataIndex: 'shopLevel',
      render: text => text ? text : '-',
			sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
    },
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
			sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    },{
      title: '访问员',
      dataIndex: 'principal',
      render: text => text ? text : '-',
			sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '代理人',
      dataIndex: 'agent',
      render: text => text ? text : '-',
			sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
      title: '进场时间',
      dataIndex: 'startAt',
      render: text => text ? text : '-',
			sorter: (a, b) => a.startAt?.localeCompare(b.startAt),
    },
    {
      title: '离场时间',
      dataIndex: 'endAt',
      render: text => text ? text : '-',
			sorter: (a, b) => a.endAt?.localeCompare(b.endAt),
    },
    {
      title: <><p>素材数</p><p>照片/录音/视频/条码</p></>,
      dataIndex: 'issueCount',
      render: text => text ? text : '-',
    },
    {
      title: '进场状态',
      dataIndex: 'status',
      render: text => taskStatusMap[text]
    }
  ]
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const params = {
      page,
      pageSize,
      projectId: projectid
    }
    if (dateSelect) params.createdAtFrom = moment(dateSelect[0]).format('YYYYMMDD')
    if (dateSelect) params.createdAtTo = moment(dateSelect[1]).format('YYYYMMDD')
    if (targetSelect) params.surveyTarget = targetSelect
    if (codeSelect) params.surveyCode = codeSelect
    if (vistorSelect) params.principalId = vistorSelect
    if (proxySelect) params.agentId=proxySelect
    if (scopeSelect) params.surveyScope = scopeSelect
    axios.get('/api/core/task/innerList', {
      params:{
        ...params,
       userId:store.getState().user.uid
      },
    }).then(res => {
      setTotal(res.data.totalSize)
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      console.log(arr)
      setDataSource(arr)
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])

  useEffect(() => {
    if (store.getState().user.uid)
      getData()
  }, [store.getState().user.uid, updateFlag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [scopeOption, setScopeOption] = useState([])
  const [taskStatusMap, setTaskStatusMap] = useState({})
  const [surveyorOption, setSurveyorOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])

  const [projectName,setProjectName] = useState(null)
  useEffect(() => {
    axios.get('/api/sys/dict/taskStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setTaskStatusMap(res.data.labelValues)
    })

    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      setProjectName(res.data.name)
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_proxy',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode:'jiahua'
        }
      }).then(res => {
        setProxyOption(res.data.data)
      })
    })
  }, [])

  // select
  const [dateSelect, setDateSelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [codeSelect, setCodeSelect] = useState('')
  const [targetSelect, setTargetSelect] = useState('')
  const [vistorSelect, setVistorSelect] = useState(null)
  const [proxySelect, setProxySelect] = useState(null)

  const clearSelect = e => {
    setDateSelect(null)
    setScopeSelect(null)
    setCodeSelect('')
    setTargetSelect('')
    setVistorSelect(null)
    setUpdateFlag(pre => pre + 1)
    setProxySelect(null)
  }
  //导出
  const handleExport = e => {
    const params = {
      pageSize: 1000000,
      projectId: projectid,
      export: true
    }
    if (dateSelect) params.createdAtFrom = moment(dateSelect[0]).format('YYYY-MM-DD')
    if (dateSelect) params.createdAtTo = moment(dateSelect[1]).format('YYYY-MM-DD')
    if (targetSelect) params.surveyTarget = targetSelect
    if (codeSelect) params.surveyCode = codeSelect
    if (vistorSelect) params.principalId = vistorSelect
    if (proxySelect) params.agentId = proxySelect
    if (scopeSelect) params.surveyScope = scopeSelect
    axios.get('/api/core/task/innerList', {
      params:{
        ...params,
       userId:store.getState().user.uid
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", projectName+".xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研对象</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研大区</span>
            <AutoComplete
              onChange={e => { console.log(e); setScopeSelect(e); }}
              style={{ width: '240px', marginLeft: '10px' }}
              options={scopeOption.map(v => { return { value: v } })}
              value={scopeSelect}
          />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
              style={{ width: '240px', marginLeft: '26px' }} value={vistorSelect} onChange={e => setVistorSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>代理人</span>
            <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
              {
                proxyOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
          }
          }}
        />

      </div>
    </div>
  )
}

export default App