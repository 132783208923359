
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Upload, Checkbox, DatePicker, Popconfirm, Input, Modal, Cascader } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import {baseUrl} from '@/utils/config'
import message from '@/utils/message'
import PopConfirmOnly from "@/utils/PopConfirmOnly";

function App(props) {
    const projectid = useRouteMatch().params.projectid
    const { push } = useHistory()
    const [id, setid] = useState('')

    const handleShowEdit = (e,value) => {
        setaddAndupdata(value)
        setIdEdit(e.id)
        setNameEdit(e.locationName)
        setAreaEdit(e.region)
        setCodeEdit(e.locationCode)
        setAddressEdit(e.address)
        setlocationEnd(e.locationEnd)
        setLatitudeEdit(e.latitude)
        setLongitudeEdit(e.longitude)
        setlongitudeEnd(e.longitudeEnd)
        setlatitudenEnd(e.latitudenEnd)
        setdefaultAddress(e.defaultAddress)
    
        setprovince(e.province)
        setcity(e.city)
        setdistrict(e.district)
        settown(e.town)
        setroad(e.road)
        setalley(e.alley)
      }

    // table
    const [columns, setColumns] = useState([
        {
            title: '位置ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '小区编号',
            dataIndex: 'locationCode',
            key: 'locationCode'
        },
        {
            title: '区域',
            dataIndex: 'region',
            key: 'region',
        },
        {
            title: '小区名称',
            dataIndex: 'locationName',
            key: 'locationName'
        },
        {
            title: '详细地址',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: '结束地址',
            dataIndex: 'locationEnd',
            key: 'locationEnd'
        },
        {
            title: '问题点默认地址',
            dataIndex: 'defaultAddress',
            key: 'defaultAddress'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button size='small' type='link' onClick={e => handleShowEdit(record,'修改位置')}>修改</Button>
                    <Divider type='vertical' />
                    <PopConfirmOnly confirm={e => handleDelete(record.id)}>
                        <Button type='link' size='small'>删除</Button>
                    </PopConfirmOnly>
                </>
            )
        },
    ])
    //
    const [dataSource, setDataSource] = useState([])
    const getData = e => {
        axios.get('/api/core/project/' + projectid)
            .then(res => {
                setid(res.data.locationGroupId)
                const queryCondition = []
                if (nameSelect) queryCondition.push('LIKE:locationName=' + nameSelect)
                if (codeSelect) queryCondition.push('LIKE:locationCode=' + codeSelect)
                if (areaSelect) queryCondition.push('EQ:region=' + areaSelect)
                axios.get('/api/core/location/list', {
                    params: {
                        page,
                        pageSize,
                        locationGroupId: res.data.locationGroupId,
                        queryCondition:queryCondition.join(',')
                    }
                }).then(res => {
                    setTotal(res.data.totalSize)
                    const arr = res.data.data.map(i => { return { ...i, key: i.id } })
                    setDataSource(arr)
                })
            })
    }

    const [pageSize,setPageSize] = useState(5)//显示数据数量
    const [page,setPage] = useState(1)//数据页数
    const [total,setTotal] = useState(null)//数据总是
  
    useEffect(()=>{
      getData()
    },[pageSize,page])
  

    useEffect(() => {
        getData()
    }, [])

    // table select
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const handleSelectChange = e => {
        setSelectedRowKeys(e)
    }
    const handleClearSelect = e => {
        setSelectedRowKeys([])
    }

    // option
    const [areaOption, setAreaOption] = useState([])
    useEffect(() => {
        axios.get('/api/core/task/scope', { params: { projectId:projectid } }).then(res => {
            const arr = res.data.map((i, index) => {
                return { value: i, text: i }
            })
            setAreaOption(arr)
        })
    }, [])

    
    // select
    const [areaSelect, setAreaSelect] = useState(null)
    const [nameSelect, setNameSelect] = useState('')
    const [codeSelect, setCodeSelect] = useState('')

    useEffect(() => {
        if(areaSelect==null&&nameSelect==''&&codeSelect==''){
            getData()
        }
    }, [areaSelect,nameSelect,codeSelect])

    const clearSelect = e => {
        setAreaSelect(null)
        setNameSelect('')
        setCodeSelect('')
        getData()
    }

    // 删除
    const handleDelete = e => {
        axios.delete('/api/core/location/' + e).then(res => {
            message.success()
            getData()
        })
    }
    const handleDeleteMore = e => {
        if (selectedRowKeys.length === 0) return
        selectedRowKeys.forEach(v=>{
            axios.delete('/api/core/location/'+v).then(res => {
                message.success()
                handleClearSelect()
                getData()
            })
        })
    }

    // 弹窗
    const [modalVisible, setModalVisible] = useState(null)
    const handleModalCancel = e => {
        setaddAndupdata(null)
        setIdEdit(null)
        setAreaEdit(null)
        setNameEdit('')
        setCodeEdit('')
        setAddressEdit('')
        setlocationEnd('')
        setLongitudeEdit('')
        setLatitudeEdit('')
        setlongitudeEnd('')
        setlatitudenEnd('')
        setprovince('')
        setcity('')
        setdistrict('')
        settown('')
        setroad('')
        setalley('')
        setdefaultAddress('')
    }
    //修改
    const [addAndupdata,setaddAndupdata] = useState('')

    const [idEdit, setIdEdit] = useState(null)
    const [areaEdit, setAreaEdit] = useState(null)
    const [nameEdit, setNameEdit] = useState('')
    const [codeEdit, setCodeEdit] = useState('')
    const [addressEdit, setAddressEdit] = useState('')
    const [longitudeEdit, setLongitudeEdit] = useState('')
    const [latitudeEdit, setLatitudeEdit] = useState('')
    const [locationEnd, setlocationEnd] = useState('')
    const [longitudeEnd, setlongitudeEnd] = useState('')
    const [latitudenEnd, setlatitudenEnd] = useState('')
    const [defaultAddress, setdefaultAddress] = useState('')
  
    const [province, setprovince] = useState('')
    const [city, setcity] = useState('')
    const [district, setdistrict] = useState('')
    const [town, settown] = useState('')
    const [road, setroad] = useState('')
    const [alley, setalley] = useState('')
    
    const handleEdit = e => {
        if (codeEdit != '' && nameEdit != '' && areaEdit != '' && locationEnd!='' && addressEdit != '' && longitudeEdit != '' && latitudeEdit != '') {
            if(addAndupdata == '添加位置'){
              axios.post('/api/core/location', {
                locationCode: codeEdit,
                locationName: nameEdit,
                locationGroup:{
                  id
                },
                region: areaEdit,
                address: addressEdit,
                longitude: longitudeEdit,
                latitude: latitudeEdit,
                locationEnd,
                longitudeEnd,
                latitudenEnd,
                province,
                city,
                district,
                town,
                road,
                alley,
                defaultAddress,
              }).then(res => {
                handleModalCancel()
                message.success()
                getData()
              })
            }else{
              axios.put('/api/core/location', {
                id: idEdit,
                locationCode: codeEdit,
                locationName: nameEdit,
                region: areaEdit,
                address: addressEdit,
                longitude: longitudeEdit,
                latitude: latitudeEdit,
                locationEnd,
                longitudeEnd,
                latitudenEnd,
                province,
                city,
                district,
                town,
                road,
                alley,
                defaultAddress,
              }).then(res => {
                handleModalCancel()
                message.success()
                getData()
              })
            }
          } else {
            message.error('请完整填写信息,再次提交')
          }
    }

    const exportlist = ()=>{//导出
        axios.get('/api/core/location/export', {
            params: {
                locationGroupId: id
            },
            responseType: 'blob'
          }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "导出位置信息.xlsx");
            document.body.appendChild(link);
            link.click();
          })
    }
    
    const uploadProps = {
        onRemove: (file) => {
          return []
        },
        beforeUpload: (file) => {
          const formData = new FormData()
          formData.append('file', file)
          axios.post('/api/core/location/import', formData, { params: { locationGroupId: id }, headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
            message.success()
            handleModalCancel()
            getData()
          })
          return false
        }
      }

    return (
        <div style={{ padding: '30px' }}>
            <div style={{ padding: '30px', background: 'white' }}>

                <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>项目区域</span>
                        <Select style={{ width: '240px', marginLeft: '10px' }} value={areaSelect} onChange={e => setAreaSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                                areaOption.map((i, index) => (
                                    <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>小区名称</span>
                        <Input style={{ width: '240px', marginLeft: '10px' }} value={nameSelect} onChange={e => setNameSelect(e.target.value)}></Input>
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>小区编号</span>
                        <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)}></Input>
                    </Col>
                </Row>
                <Row justify='space-between' style={{ marginBottom: '20px' }}>
                    <Col>
                        <Button style={{ margin: '0 10px' }} type='primary' onClick={e=>handleShowEdit({},'添加位置')}>添加位置</Button>
                        <PopConfirmOnly confirm={handleDeleteMore}>
                            <Button style={{ margin: '0 10px' }} type='primary' >批量删除</Button>
                        </PopConfirmOnly>
                        <Button style={{ margin: '0 10px' }} onClick={exportlist}>批量导出</Button>
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>上传文件</Button>
                        </Upload>
                    </Col>
                    <Col>
                        <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
                        <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
                    </Col>
                </Row>

                {
                    selectedRowKeys.length !== 0 ?
                        <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
                            <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
                            <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
                        </Row>
                        :
                        null
                }
                <Table
                    rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{  defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
                        setPage(page)
                        setPageSize(pageSize)
                        }
                       }}
                />

                <Modal
                    width={640}
                    title={addAndupdata}
                    visible={addAndupdata == '添加位置' || addAndupdata == '修改位置'}
                    onCancel={handleModalCancel}
                    destroyOnClose
                    footer={[
                        <Button key="back" onClick={handleModalCancel}>取消</Button>,
                        <Button key="submit" type="primary" onClick={handleEdit}>确认</Button>,
                    ]}
                >
                    <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>项目区域:</Col>
                        <Col span={14}>
                            <Input style={{ width: '100%' }} value={areaEdit} onChange={e => setAreaEdit(e.target.value)} />
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>小区名称:</Col>
                        <Col span={14}><Input value={nameEdit} onChange={e => setNameEdit(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>小区编号:</Col>
                        <Col span={14}><Input value={codeEdit} onChange={e => setCodeEdit(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>详细地址:</Col>
                        <Col span={14}><Input value={addressEdit} onChange={e => setAddressEdit(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>结束地址:</Col>
                        <Col span={14}><Input value={locationEnd} onChange={e => setlocationEnd(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}>经度:</Col>
                        <Col span={14}><Input value={longitudeEdit} onChange={e => setLongitudeEdit(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}>纬度:</Col>
                        <Col span={14}><Input value={latitudeEdit} onChange={e => setLatitudeEdit(e.target.value)}></Input></Col>

                        <Col span={6} style={{ textAlign: 'right' }}>{/*<font color='red'>*</font>*/}结束-经度:</Col>
                        <Col span={14}><Input value={longitudeEnd} onChange={e => setlongitudeEnd(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}>{/*<font color='red'>*</font>*/}结束-纬度:</Col>
                        <Col span={14}><Input value={latitudenEnd} onChange={e => setlatitudenEnd(e.target.value)}></Input></Col>

                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>省份:</Col>
                        <Col span={14}><Input value={province} onChange={e => setprovince(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>城市:</Col>
                        <Col span={14}><Input value={city} onChange={e => setcity(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>区:</Col>
                        <Col span={14}><Input value={district} onChange={e => setdistrict(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>街镇:</Col>
                        <Col span={14}><Input value={town} onChange={e => settown(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>路:</Col>
                        <Col span={14}><Input value={road} onChange={e => setroad(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>弄:</Col>
                        <Col span={14}><Input value={alley} onChange={e => setalley(e.target.value)}></Input></Col>
                        <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>问题点默认地址:</Col>
                        <Col span={14}><Input value={defaultAddress} onChange={e => setdefaultAddress(e.target.value)}></Input></Col>
                    </Row>
                </Modal>
            </div>
        </div>
    )
}

export default App