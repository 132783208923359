import React, { useEffect, useState } from 'react';
import './App.css';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';

import Router from './router/Index'

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Router />
    </ConfigProvider>
  )
}

export default App;
