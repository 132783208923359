import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Tooltip , Divider, Select, Button, Table, Checkbox, DatePicker, Input, message, Modal, InputNumber } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'
import PopConfirmOnly from "@/utils/PopConfirmOnly";

const { Option } = Select;
const { RangePicker } = DatePicker;

function App(props) {
    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    const [columns, setColumns] = useState([
        {
            title: '问卷名称',
            dataIndex: 'title',
            render: (text, record, index) => (
                text
            ),
        },
        {
            title: '操作',
            dataIndex: 'projectId',
            render: (text, record, index) => (
                <>
                    <Button type='link' size='small' onClick={e=>{details(record)}}>修改问卷</Button>
                    <PopConfirmOnly confirm={e => { deleteWj(record) }}>
                        <Button type='link' size='small'> 删除问卷</Button>
                    </PopConfirmOnly>
                    {
                        record.status!=1?
                        <>
                            <Button type='link' size='small' onClick={e => { show(record) }}>发布问卷</Button>
                        </>
                        :<>
                            <Button type='link' size='small' onClick={e => {push('/wj/' + projectid + '/work/issue/' + record.id)}}>查看问卷信息</Button>
                            <PopConfirmOnly title='您确认取消发布吗？' confirm={e => { issueOk(true,record) }}>
                                <Button type='link' size='small'>取消发布</Button>
                            </PopConfirmOnly>
                            
                        </>
                        //
                    }
                    <Button type='link' size='small' onClick={e => { push('/wjs/' + projectid + '/work/previewy/' + record.id) }}>预览问卷</Button>
                </>
            ),
        }
    ])
    {//
    }

    

    const issueOk = (bool,intId) => { // 发布
        intId = intId?intId:id
        if(!bool){
            if(!time&&!beginAt){
                message.error('请填写完整')
                return 
            }
        }
        let status = 1
        if(bool){
            status = 2
        }
        console.log(status)
        axios.put('/api/question/template/config',{
            ...intId,
            maxTimesPerMobile:time,
            beginAt,
            endAt,
            status
        })
        .then(res=>{
            if(bool){
                message.success('取消发布成功')
            }else{
                message.success('发布成功')
            }
            if(!bool){
                push('/wj/' + projectid + '/work/issue/' + intId.id)
            }else{
                getdata()
            }
            setIssueBool(false)
        })
    }

    const [intdata,setIntdata] = useState(null) // 发布
    const [id,setId] = useState(null)
    const [beginAt,setBeginAt] = useState(null)
    const [endAt,setEndAt] = useState(null)
    const [time,setTime] = useState(null)
    const [issueBool, setIssueBool] = useState(false);

    const show = (record,bool) => { // 发布
        setIntdata(record)
        setTime(record.maxTimesPerMobile)
        setId(record.id)
        setBeginAt(record.beginAt)
        setEndAt(record.endAt)
        setIssueBool(true)
    }

    const issueCancel = () => {// 发布
        setIssueBool(false)
    }

    const deleteWj = (record) => {
        if(record.status!=1){
            axios.delete('/api/question/template/' + record.id)
            .then(res => {
                message.success('删除成功')
                getdata()
            })
        }else{
            message.error('请取消发布后在进行删除')
        }
    }

    const details = (record) => {
        if(record.status!=1){
            push('/wj/' + projectid + '/work/questionnaire/' + record.id )
        }else{
            message.error('请取消发布后在进行编辑')
        }
    }

    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        getdata()
    }, [])

    const getdata = () => {
        axios.get('/api/question/template/all', {
            params: {
                projectId: projectid,
                page,
                pageSize,
            }
        })
        .then(res => {
            setTotal(res.data.totalSize)
            setDataSource(res.data.data)
        })
    }
    
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [fuzhidata,setFuzhidata] = useState([])

    const showModal = () => {
        setIsModalVisible(true);
        axios.get('/api/question/template/all',{
            params:{
                pageSize:10000000,
                projectId:projectid
            }
        })
        .then(res => {
            console.log(res.data.data)
            const arr = []
            res.data.data.forEach(v => {
                if(arr.filter(a=>{return a.title == v.title}).length==0){
                    arr.push(v)
                }
            });
            setFuzhidata(arr)
        })
    };

    const handleOk = () => {
        setIsModalVisible(false);
        if(fuzhiId==null){
            message.error('请选择模板')
            return
        } 
        const body = fuzhidata.filter(v=>{return v.id == fuzhiId})[0]
        delete body.createdBy
        delete body.createdByName
        delete body.id
        delete body.category
        delete body.createdAt
        delete body.updatedBy
        delete body.updatedByName
        delete body.updatedAt
        delete body.status
        body.title += '(复制)'
        body.questions.forEach(v=>{
            delete v.id
            delete v.createdAt
        })
        axios.post('/api/question/template', body ,
        {
            params:{
                projectId:projectid
            }
        }).then(res=>{
            message.success('复制成功')
            getdata()
        })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setFuzhiId(null)
    };

    const [fuzhiId,setFuzhiId] = useState(null)

    const [pageSize, setPageSize] = useState(5)//显示数据数量
    const [page, setPage] = useState(1)//数据页数
    const [total, setTotal] = useState(null)//数据总是

    useEffect(() => {
        getdata()
    }, [pageSize,page])

    return (
        <div style={{ padding: '30px' }}>
            <Modal title="发布问卷" visible={issueBool} onOk={e=>(issueOk(false,intdata))} destroyOnClose onCancel={issueCancel}>
                <Row>
                    <Col span='5'>设置时间</Col>
                    <Col span='19'> 
                        <RangePicker value={beginAt!=null?[moment(beginAt),moment(endAt)]:null} onChange={e=>{
                            setBeginAt(moment(e[0]).valueOf())
                            setEndAt(moment(e[1]).valueOf())
                        }}/>
                    </Col>
                </Row>
                <Row style={{margin:'10px 0'}}>
                    <Col span='5'>每人最多次数</Col>
                    <Col span='19'> 
                        <InputNumber  defaultValue={time} onChange={e=>{setTime(e)}}></InputNumber>
                    </Col>
                </Row>
            </Modal>
            <div style={{ padding: '30px', background: 'white' }}>

                <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
                    <Col>
                        <Button type='primary' onClick={e => { push('/wj/' + projectid + '/work/questionnaire/' + null) }}>新增问卷</Button>
                        <Button type='primary' style={{margin: '0 10px',}} onClick={e => { showModal() }}>复制问卷</Button>
                    </Col>
                    <Col>
                        {/* <Button>导出列表</Button> */}
                    </Col>
                </Row>

                <Table dataSource={dataSource} columns={columns} 
                    pagination={{
                        defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true, total, current: page, onChange: (page, pageSize) => {
                        setPage(page)
                        setPageSize(pageSize)
                        }
                    }} 
                />

            </div>
            <Modal title="复制问卷" visible={isModalVisible} onOk={handleOk} destroyOnClose onCancel={handleCancel}>
                <Row>
                    <Col span='5'>选择问卷模板</Col>
                    <Col span='19'>
                        <Select style={{ width: '100%' }} onChange={e=>{ setFuzhiId(e) }}>
                            {
                                fuzhidata.map(v=>{
                                    
                                    return <Option value={v.id} ><Tooltip  style={{zIndex:100}} title={v.title}>{v.title}</Tooltip ></Option>
                                        
                                })
                            }
                        </Select>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default App
