
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload, Radio } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import PopConfirmOnly from '@/utils/PopConfirmOnly'
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
const history = creatHistory();//返回上一页这段代码

function App(props) {

  const { id } = useRouteMatch().params

  const [dataArray, setDataArr] = useState([])
  const [addText, setAddText] = useState('')
  const handleAddText = e => {
    setDataArr(pre => [...pre, addText])
    setAddText('')
  }
  const handleChangeText = (e, index) => {
    const target = dataArray.map((i, index_) => {
      return index === index_ ? e.target.value : i
    })
    setDataArr(target)
  }

  const [projectTemplateCode, setProjectTemplateCode] = useState('')
  const [projectTemplateName, setProjectTemplateName] = useState('')
  const [remark, setRemark] = useState('')
  const [isCheckedProjectCode, setIsCheckedProjectCode] = useState(0)
  const [isCheckedProjectName, setIsCheckedProjectName] = useState(0)
  const [isCheckedSurveyDateRange, setIsCheckedSurveyDateRange] = useState(0)
  const [isCheckedWarningDate, setIsCheckedWarningDate] = useState(0)

  useEffect(() => {
    handleInit()
  }, [])
  const handleBack = e => {
    window.history.back(-1);
  }
  const handleInit = e => {
    if (id !== 'add') {
      axios.get('/api/core/projectTemplateField/getByProjectTemplateId',{
        params:{
          projectTemplateId:id
        }
      })
      .then(res=>{
        setDataArr(res.data.map(v=>{
          return v.fieldName
        }))
      })
      axios.get('/api/core/projectTemplate/' + id).then(res => {
        setProjectTemplateCode(res.data.projectTemplateCode)
        setProjectTemplateName(res.data.projectTemplateName)
        setRemark(res.data.remark)
        setIsCheckedProjectCode(res.data.isCheckedProjectCode)
        setIsCheckedProjectName(res.data.isCheckedProjectName)
        setIsCheckedSurveyDateRange(res.data.isCheckedSurveyDateRange)
        setIsCheckedWarningDate(res.data.isCheckedWarningDate)
      })
    } else {
      setDataArr([])
      setAddText('')
      setProjectTemplateCode('')
      setProjectTemplateName('')
      setRemark('')
      setIsCheckedProjectCode(0)
      setIsCheckedProjectName(0)
      setIsCheckedSurveyDateRange(0)
      setIsCheckedWarningDate(0)
    }
  }

  const handleAdd = e => {
    if(projectTemplateCode!=''&&projectTemplateName!=''){
      axios.post('/api/core/projectTemplate', {
        projectTemplateName,
        projectTemplateCode,
        remark,
        isCheckedProjectCode,
        isCheckedProjectName,
        isCheckedSurveyDateRange,
        isCheckedWarningDate,
        projectTemplateFieldList: dataArray.map(i => {
          return {
            fieldName: i
          }
        })
      }).then(res => {
        message.success()
        handleInit()
        history.goBack();  //返回上一页这段代码
      })
    }else{
      message.error('请完整填写信息,再次提交')
    }
  }

  const handleEdit = e => {
    if(projectTemplateCode!=''&&projectTemplateName!=''){
      axios.put('/api/core/projectTemplate', {
        id: id,
        projectTemplateName,
        projectTemplateCode,
        remark,
        isCheckedProjectCode,
        isCheckedProjectName,
        isCheckedSurveyDateRange,
        isCheckedWarningDate,
        projectTemplateFieldList: dataArray.map(i => {
          return {
            fieldName: i
          }
        })
      }).then(res => {
        message.success()
        handleInit()
        history.goBack();  //返回上一页这段代码
      })
    }else{
      message.error('请完整填写信息,再次提交')
    }
  }

  return (
    <div style={{ padding: '30px' }}>
      <Row gutter={[0, 20]} style={{ padding: '30px', background: 'white' }}>
        <Col span={24} style={{ fontWeight: 'bold' }}>基本信息</Col>

        <Col span={24} style={{ marginLeft: '40px' }}>
          <Row gutter={[20, 20]} align='middle'>
            <Col style={{ fontSize: '14px', width: '120px' }}><font color='red'>*</font>项目模板编号</Col>
            <Col><Input style={{ width: '540px' }} value={projectTemplateCode} onChange={e => setProjectTemplateCode(e.target.value)}></Input></Col>
          </Row>
          <Row gutter={[20, 20]} align='middle'>
            <Col style={{ fontSize: '14px', width: '120px' }}><font color='red'>*</font>项目模板名称</Col>
            <Col><Input style={{ width: '540px' }} value={projectTemplateName} onChange={e => setProjectTemplateName(e.target.value)}></Input></Col>
          </Row>
          <Row gutter={[20, 20]} align='middle'>
            <Col style={{ fontSize: '14px', width: '120px' }}>备注</Col>
            <Col><Input style={{ width: '540px' }} value={remark} onChange={e => setRemark(e.target.value)}></Input></Col>
          </Row>
        </Col>

        <Col span={24}>
          <div style={{ marginLeft: '40px' }}>
            <Checkbox checked={isCheckedProjectName == 1} onChange={e => setIsCheckedProjectName(e.target.checked ? 1 : 0)}>项目名称</Checkbox>
            <Checkbox checked={isCheckedProjectCode == 1} onChange={e => setIsCheckedProjectCode(e.target.checked ? 1 : 0)}>项目编号</Checkbox>
            <Checkbox checked={isCheckedSurveyDateRange == 1} onChange={e => setIsCheckedSurveyDateRange(e.target.checked ? 1 : 0)}>调查日期范围</Checkbox>
            <Checkbox checked={isCheckedWarningDate == 1} onChange={e => setIsCheckedWarningDate(e.target.checked ? 1 : 0)}>预警日期</Checkbox>
          </div>
        </Col>

        <Divider />

        <Col span={24} style={{ fontWeight: 'bold' }}>自定义模板信息</Col>
        <Col span={24} style={{ marginLeft: '40px' }}>
          {
            dataArray.map((i, index) => (
              <Row gutter={[20, 20]} align='middle' key={index}>
                <Col style={{ fontSize: '14px' }}>自定义字段</Col>
                <Col>
                  <Input style={{ width: '540px' }} value={i} onChange={e => handleChangeText(e, index)}></Input>
                </Col>
                <Col>
                  <PopConfirmOnly children={<Button style={{ margin: '0 10px', border: 'none' }}>删除</Button>} confirm={e => setDataArr(pre => pre.filter((i, index_) => index !== index_))}></PopConfirmOnly>
                </Col>
              </Row>
            ))
          }
          <Row gutter={[20, 20]} align='middle'>
            <Col style={{ fontSize: '14px' }}>自定义字段</Col>
            <Col><Input style={{ width: '540px' }} placeholder='输入添加的字段' value={addText} onChange={e => setAddText(e.target.value)}></Input></Col>
            <Col>
              <Button style={{ margin: '0 10px', border: 'none' }} onClick={handleAddText}>新增</Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ marginLeft: '40px' }}>
          <Button style={{ margin: '0 10px' }} type='primary' onClick={id === 'add' ? handleAdd : handleEdit}>提交</Button>
          <Button style={{ margin: '0 10px' }} onClick={handleBack}>取消</Button>
        </Col>
      </Row>
    </div>
  )
}

export default App