import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'


function App(props) {
  const { push } = useHistory()
  const { projectid , DSR } = useRouteMatch().params

  const [columns, setColumns] = useState([
      {
        title: 'SFA编号',
        dataIndex: 'sfaCode',
        sorter: (a, b) => a.sfaCode?.localeCompare(b.sfaCode),
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '门店编号',
        dataIndex: 'shopCode',
        sorter: (a, b) => a.shopCode-b.shopCode,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '省份',
        dataIndex: 'province',
        sorter: (a, b) => a.province?.localeCompare(b.province),
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '城市',
        dataIndex: 'city',
        sorter: (a, b) => a.city?.localeCompare(b.city),
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '门店名称',
        dataIndex: 'shopName',
        sorter: (a, b) => a.shopName?.localeCompare(b.shopName),
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '门店地址',
        dataIndex: 'address',
        sorter: (a, b) => a.address?.localeCompare(b.address),
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '类型',
        dataIndex: 'shopLevelName',
        sorter: (a, b) => a.shopLevelName?.localeCompare(b.shopLevelName),
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '是否与陈列重复',
        dataIndex: 'reShop',
        sorter: (a, b) => a.reShop-b.reShop,
        render:(text,record)=>{
          return text?text:'--'
        }
      },
      {
        title: '是否木糖醇店',
        dataIndex: 'xylitolFlag',
        sorter: (a, b) => a.xylitolFlag-b.xylitolFlag,
        render:(text,record)=>{
          return text?text:'--'
        }
      },
      {
        title: '是否为特殊门店',
        dataIndex: 'isSpecific',
        sorter: (a, b) => a.remark3-b.remark3,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '主备店',
        dataIndex: 'isMaster',
        sorter: (a, b) => a.isMaster-b.isMaster,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
    ])

  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    if(store.getState().user.uid == null)return
      axios.get('/api/core/shop/dsrTaskShoplist',{
        params:{
          projectId:projectid,
          dsrCode:DSR,
          agentId: store.getState().user.role=='管理员'?null:store.getState().user.uid
        }
      })
      .then(res=>{ 
        console.log(res)
        setDataSource(res.data)
      })
  }, [])

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
           {//搜索模块位置
           }
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true }} />

      </div>
    </div>
  )
}

export default App
