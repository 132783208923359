import React, { useEffect, useState } from 'react';
import { Spin } from 'antd'
import { HashRouter, Switch, Route, useHistory } from 'react-router-dom'
import store from '@/redux/store'
import axios from '@/utils/axios'
import Login from '@/view/login/Login'
import MenuHome from '@/component/MenuHome'
import MenuJH from '@/component/MenuJH'
import MenuSX from '@/component/MenuSX'
import MenuWJ from '@/component/MenuWJ'

import Layout from '@/layout/Index'

import ContentHome from '@/view/home/Content'
import ContentJH from '@/view/jh/Content'
import ContentSX from '@/view/sx/Content'
import ContentWJ from '@/view/wj/Content'

import Contentdati from '@/view/wj/work/RedactAnswer'
import Contentyulan from '@/view/wj/work/RedactPreviewy'

import ContentTS from '@/view/tousu12345/Content'


function RouterContent(props) {

  const { pathname } = useHistory().location
  const { push } = useHistory()

  useEffect(() => {
    if (localStorage.getItem('uid_xz') && localStorage.getItem('token_xz')) {
      store.dispatch({ type: 'user_init', value: { uid: localStorage.getItem('uid_xz'), token: localStorage.getItem('token_xz'), name: localStorage.getItem('name_xz') ,role:localStorage.getItem('role_xz') } })
      axios.get('/api/sys/resource/user/list', { params: { type: '0,2' } }).then(res => {
        const main = res.data['0'].map(i => i.code)
        const project = res.data['2'].map(i => i.code)
        store.dispatch({ type: 'permission_init', value: { main, project } })
      })
      if (pathname === '/' || pathname === '/login' ) push('/home/project/list')
      return
    }
    if ((!localStorage.getItem('uid_xz') || !localStorage.getItem('token_xz')) && pathname != '/ts' && !pathname.includes('answer/')) push('/login')
  }, [])

  useEffect(() => {
    if (pathname.includes('/sx') && pathname.includes('/check/particular')) return
    if (pathname.includes('/jh') && pathname.includes('/check/particular')) return
    if (pathname.includes('/wj') && pathname.includes('/check/particular')) return
    store.dispatch({ type: 'selectCache_clear' })
  }, [pathname])

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/home">
        <Layout menu={<MenuHome />} content={<ContentHome />} />
      </Route>
      <Route path="/jh/:projectid">
        <Layout menu={<MenuJH />} content={<ContentJH />} />
      </Route>
      <Route path="/sx/:projectid">
        <Layout menu={<MenuSX />} content={<ContentSX />} />
      </Route>
      <Route path="/wj/:projectid">
        <Layout menu={<MenuWJ />} content={<ContentWJ />} />
      </Route>
      <Route path="/wjs/:projectid/work/previewy/:wjid">
        <Contentyulan />
      </Route>
      <Route path="/wjs/:projectid/work/answer/:wjid/:userId">
        <Contentdati />
      </Route>
      <Route path="/ts">
        <ContentTS />
      </Route>
    </Switch>
  )
}

function App(props) {
  const [loading, setLoading] = useState(false)

  // 把下面一坨 注释掉
  useEffect(() => {
    const s = store.subscribe(() => {
      setLoading(store.getState().loading)
    })
  }, [])

  return (
    <Spin spinning={loading} delay={500} style={{ zIndex: 99999 }}>
      <HashRouter>
        <RouterContent />
      </HashRouter>
    </Spin>
  )
}

export default App