import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu, Dropdown, Tabs } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import BarChart from './components/BarChart'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [curTab, setCurTab] = useState('table')

  const columns = [
    {
      title: '审核员',
      dataIndex: 'audit2ByName',
      render: text => text ? text : '-'
    },
    {
      title: '项目名称',
      dataIndex: 'projectName',
      render: text => text ? text : '-'
    },
    {
      title: '开始时间',
      dataIndex: 'workAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
        a.workAt?a.workAt=a.workAt:a.workAt='1'
        b.workAt?b.workAt=b.workAt:b.workAt='1'
        return a.workAt?.localeCompare(b.workAt)
      },
    },
    {
      title: '结束时间',
      dataIndex: 'doneAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
          a.doneAt?a.doneAt=a.doneAt:a.doneAt='1'
          b.doneAt?b.doneAt=b.doneAt:b.doneAt='1'
          return a.doneAt?.localeCompare(b.doneAt)
      },
    },
    {
      title: '总数',
      dataIndex: 'totalCount',
      render: text => text ? text : '-'
    },
    {
      title: '待审核数',
      dataIndex: 'toAudit1Count',
      render: text => text ? text : '-'
    },
    {
      title: '合格数',
      dataIndex: 'qualifiedCount',
      render: text => text ? text : '-'
    },
    {
      title: '需完善',
      dataIndex: 'perfectCount',
      render: text => text ? text : '-'
    },
    {
      title: '需补拍',
      dataIndex: 'reshootCount',
      render: text => text ? text : '-'
    },
    {
      title: '需重做',
      dataIndex: 'reformCount',
      render: text => text ? text : '-'
    }
  ]
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    axios.get('/api/statistic/task/sxAuditor2Statistic', {
      params: {
        pageSize: 1000000,
        projectId: projectid,
        audit2ById: vistorSelect,
        createdAtBegin: dateSelect[0] ? moment(dateSelect[0]).format('YYYY-MM-DD hh:mm:ss') : null,
        createdAtEnd: dateSelect[1] ? moment(dateSelect[1]).format('YYYY-MM-DD hh:mm:ss') : null
      }
    }).then(res => {
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  useEffect(() => {
    getData()
  }, [updateFlag])

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/user/list', { params: { roleCode: 'shixiao_checker' } }).then(res => {
      setSurveyorOption(res.data.data)
    })
  }, [])


  // select
  const [dateSelect, setDateSelect] = useState([])
  const [vistorSelect, setVistorSelect] = useState(null)

  const clearSelect = e => {
    setDateSelect([])
    setVistorSelect(null)
    setUpdateFlag(pre => pre + 1)
  }

  return (
    <>
      <div style={{ background: 'white', padding: '0 30px 0 30px' }}>
        <Tabs activeKey={curTab} onChange={e => setCurTab(e)} >
          <Tabs.TabPane tab="列表展示" key="table"></Tabs.TabPane>
          <Tabs.TabPane tab="图形展示" key="chart"></Tabs.TabPane>
        </Tabs>
      </div>

      <div style={{ padding: '30px' }}>
        <div style={{ padding: '30px', background: 'white' }}>

          <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
            <Col>
              <span style={{ lineHeight: '30px' }}>查询时间</span>
              <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} showTime value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
            </Col>
            <Col>
              <span style={{ lineHeight: '30px' }}>审核员</span>
              <Select style={{ width: '240px', marginLeft: '25px' }} value={vistorSelect} onChange={e => setVistorSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                  surveyorOption.map((i, index) => (
                    <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                  ))
                }
              </Select>
              <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
              <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
            </Col>
            <Col>
              <Button>批量导出</Button>
            </Col>
          </Row>

          {
            curTab === 'table' ?
              <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />
              : null
          }

          {
            curTab === 'chart' ?
              <BarChart data={dataSource} name='audit2ByName'/>
              : null
          }
        </div>
      </div>

    </>
  )
}

export default App
