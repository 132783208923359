import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'

import { audioUrl, baseUrl } from '@/utils/config'

const { SubMenu } = Menu;

function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const handleShowBack = (e, text) => {
    setModalVisible(text)
    setTaskId(e.id)
  }

  // table
  const columns = [
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    },
    {
      title: '退回状态',
      dataIndex: 'taskReturnStatus',
      render: (text, record) => text?.display == '已退回' ?
        record.audit1RefusedReasonType ? '已退回-需' + record.labelValues[record.audit1RefusedReasonType] :
          record.audit2RefusedReasonType ? '已退回-需' + record.labelValues[record.audit2RefusedReasonType] :
            '已退回-需' + record.labelValues[record.reviewRefusedReasonType]
        : text.display
    },
    {
      title: '二审负责人',
      dataIndex: 'audit2ByName',
      render: text => text ? text : '-',
      sorter: (a, b) => a.audit2ByName?.localeCompare(b.audit2ByName),
    },
    {
      title: '退回次数',
      dataIndex: 'refusedCount',
      render: text => text ? text : '-',
      sorter: (a, b) => a.refusedCount-b.refusedCount,
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      render: text => text.display ? text.display : '-',
      sorter: (a, b) => a.status.display?.localeCompare(b.status.display),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => push_page(record)}>查看</Button>
          <Button size='small' type='link' onClick={e => { showModal(record) }}>查看退回详情</Button>
          {/* <Button size='small' type='link' onClick={e => handleShowBack(record,'yes')}>确认退回</Button> */}
          {/* <Button size='small' type='link' onClick={e => handleShowBack(record,'no')}>取消退回</Button> */}
          {/* <Button size='small' type='link' onClick={e => handleShowBack(record)}>退回</Button> */}
        </>
      )
    },
  ]
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)

  const confirmReturn = (record) => {
    axios.post('/api/core/taskAudit/confirmReturn', record, {
      params: {
        taskId: record.id
      }
    })
    message.success('退回成功')
  }

  const [getData_flag, setGetData_flag] = useState(false)

  const push_page = e => {
    store.dispatch({
      type: 'selectCache_init', value: {
        page: 'sx-checkback',
        select: {
          surveyorSelect,
          targetSelect,
          statusSelect,
          codeSelect,
          //   firstStatusSelect,
          dataSource,
          backStatusSelect
        }
      }
    })
    push('/sx/' + projectid + '/check/particular/' + e.id)
  }


  const getData = e => {
    const queryCondition = []
    queryCondition.push('GTE:refusedCount=0')
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    // if (backStatusSelect) queryCondition.push('EQ:taskReturnStatus='+backStatusSelect)

    axios.get('/api/core/task/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(','),
        userId: store.getState().user.uid
      }
    }).then(resa => {
      setTotal(resa.data.totalSize)
      axios.get('/api/sys/dict/' + 'sxTaskRefusedReasonType', {
        params: {
          pageSize: 1000000,
        }
      })
        .then(res => {
          const arr = resa.data.data.map(i => { return { ...i, key: i.id, labelValues: res.data.labelValues } })
          setDataSource(arr)
        })
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])


  const [mendianstatus, setMendianstatus] = useState(null)

  useEffect(() => {
    axios.get('/api/sys/dict/taskStatus')
    .then(res => {
      setMendianstatus(res.data.labelValues)
    })
    if (store.getState().selectCache.page === 'sx-checkback') {
      const data = store.getState().selectCache.select
      Promise.all([
        new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
        new Promise(r => setSurveyorSelect(data.statusSelect, () => r())),
        new Promise(r => setCodeSelect(data.codeSelect, () => r())),
        new Promise(r => setTargetSelect(data.targetSelect, () => r())),
        new Promise(r => setDataSource(data.dataSource, () => r())),
        new Promise(r => setCodeSelect(data.backStatusSelect, () => r())),
      ]).then(res => {
        setGetData_flag(true)
        store.dispatch({ type: 'selectCache_clear' })
      })
    } else {
      setGetData_flag(true)
      store.dispatch({ type: 'selectCache_clear' })
    }
  }, [])

  useEffect(() => {
    if (!getData_flag) return
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'shixiao_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'shixiao'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [updateFlag, getData_flag])




  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [taskStatusOption, setTaskStatusOption] = useState([])
  const [surveyorOption, setSurveyorOption] = useState([])

  useEffect(() => {
    axios.get('/api/sys/dict/taskStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setTaskStatusOption(arr)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'shixiao_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'shixiao'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [])


  // select
  const [targetSelect, setTargetSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [backStatusSelect, setBackStatusSelect] = useState(null)
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [statusSelect,setStatusSelect] = useState(null)

  const clearSelect = e => {
    setTargetSelect('')
    setCodeSelect('')
    setStatusSelect('')
    setBackStatusSelect(null)
    setSurveyorSelect(null)
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setRemark('')
    setTaskId(null)
  }
  const [remark, setRemark] = useState('')
  // 退回
  const [taskId, setTaskId] = useState(null)
  const handleBack = e => {
    axios.post('/api/core/taskAudit/confirmReturn', {
      confirmRefusedReason: remark
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //取消退回
  const nohandleBack = e => {
    axios.post('/api/core/taskAudit/cancelReturn', {
      cancelRefusedReason: remark
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //批量退回
  const handleBackMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/taskAudit/batchConfirmReturn', {
      confirmRefusedReason: remark
    }, {
      params: {
        taskIds: selectedRowKeys.join(','),
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //安排判断
  const handleDoBack = e => {
    if (modalVisible === 'yes') handleBack()
    if (modalVisible === 'no') nohandleBack()
    if (modalVisible === 'pi') handleBackMore()
  }

  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:taskReturnStatus=1!2')
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)

    axios.get('/api/core/taskAudit/export', {
      params: {
        pageSize: 1000000,
        queryCondition: queryCondition.join(','),
        taskAuditExportType: 2
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出退回单.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  const [isModalVisible, setisModalVisible] = useState(false)

  const [returnGuid, setreturnGuid] = useState([])
  const [labelValues, setlabelValues] = useState({})

  const showModal = (record) => {
    setisModalVisible(true);
    axios.get('/api/core/taskReturnOrder/list', {
      params: {
        taskId: record.id,
        pageSize: 1000000,
      }
    })
      .then(res => {
        setreturnGuid(res.data.data.reverse())
      })
  };

  useEffect(() => {
    axios.get('api/sys/dict/sxTaskRefusedReasonType')
      .then(res => {
        setlabelValues(res.data.labelValues)
      })
  }, [])

  const handleOk = () => {
    setisModalVisible(false);
  };

  const handleCancel = () => {
    setisModalVisible(false);
  };

  return (
    <div style={{ padding: '30px' }}>
      <Modal title="工单退回详情" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width="1000px">
        <Menu
          style={{ width: "100%", borderBottom: '1px solid #000' }}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={[returnGuid.length - 1]}
          mode="inline">
          {
            returnGuid.map((v, i) => {
              return (
                <SubMenu style={{ width: "100%" }} key={i} title={'第' + (i + 1) + '次退回'}>
                  <Menu.Item key="1" style={{ display: 'flex', alignItems: 'center',height:'auto'}}>
                    <Row style={{width:'100%',padding:'10px 10px 10px 0'}}>
                      <Col span={24} style={{ lineHeight: '15px', }}>退回类型:{labelValues[v.taskRefusedReasonType]} {v.isSelfCheck == 1 ? '全部退回完善' : null}</Col>
                      <Col span={24} style={{ lineHeight: '15px'}}>退回备注:<p style={{whiteSpace:'normal'}}>{v.taskRefusedRemark}</p></Col>
                    </Row>
                  </Menu.Item>
                  {
                    JSON.parse(v.taskRefusedReason) instanceof Array ? JSON.parse(v.taskRefusedReason).map((v, i) => {
                      return (<Menu.Item key={i + 2} style={{ height: 'auto' }}>
                        <Row>
                          <Col span="2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #aaa' }}>{v.id}</Col>
                          <Col span="7" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #aaa' }}><img style={{ width: '100%', height: 'auto' }} src={audioUrl + v.before} /></Col>
                          <Col span="15" style={{ fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #aaa' }}>{v.description}</Col>
                        </Row>
                      </Menu.Item>)
                    }) : <Menu.Item key="2">未退回照片</Menu.Item>
                  }
                </SubMenu>
              )
            })
          }
        </Menu>
      </Modal>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研对象</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
          </Col>
          {/* <Col span={8}>
            <span style={{ lineHeight: '30px' }}>退回状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={backStatusSelect} onChange={e => setBackStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                taskStatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col> */}
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>任务状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={statusSelect} onChange={e => setStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                taskStatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
            }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
              <Select.Option value={''}>取消选择</Select.Option>
              {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            {/* <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('pi')}>批量退回</Button> */}
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>
        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
          }
          }}
        />

        <Modal
          width={640}
          title={modalVisible == 'yes' ? '确认' : modalVisible == 'no' ? '取消 ' : '批量' + '退回工单'}
          visible={modalVisible === 'yes' || modalVisible === 'pi' || modalVisible === 'no'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleDoBack}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}>{modalVisible == 'yes' ? '确认' : modalVisible == 'no' ? '取消 ' : '确认'}退回理由:</Col>
            <Col span={14}>
              <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
            </Col>
          </Row>
        </Modal>

      </div>
    </div>
  )
}

export default App