import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'


function App(props) {
  const { push } = useHistory()
  const { projectid , agentid} = useRouteMatch().params

  const [columns, setColumns] = useState([
      {
        title: 'DSR编号',
        dataIndex: 'dsrCode',
        sorter: (a, b) => a.dsrCode?.localeCompare(b.dsrCode),
        render:(text,record)=>{
          return <Button type="link" onClick={e=>{
            push('/jh/'+projectid+'/data/agencyDSR/'+record.dsrCode)
          }}>{text}</Button>
        }
      },
      {
        title: '目标量',
        dataIndex: 'masterCount',
        sorter: (a, b) => a.masterCount-b.masterCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '进店完成',
        dataIndex: 'doneCount',
        sorter: (a, b) => a.doneCount-b.doneCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '进店完成率',
        dataIndex: 's',
        sorter: (a, b) =>((a.doneCount/a.masterCount)*100)-((b.doneCount/b.masterCount)*100),
        render:(text,record)=>{
          return record.doneCount&&((record.doneCount/record.masterCount)*100).toFixed(2)!='Infinity'?((record.doneCount/record.masterCount)*100).toFixed(2) + '%':'0%'
        }
      },
      {
        title: '当前待提交',
        dataIndex: 'unAudit1Count',
        sorter: (a, b) => a.unAudit1Count-b.unAudit1Count,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '当前一审',
        dataIndex: 'audit1Count',
        sorter: (a, b) => a.audit1Count-b.audit1Count,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '当前二审',
        dataIndex: 'audit2Count',
        sorter: (a, b) => a.audit2Count-b.audit2Count,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '当前复核',
        dataIndex: 'reviewCount',
        sorter: (a, b) => a.reviewCount-b.reviewCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '当前完成（不含退回）',
        dataIndex: 'completedCount',
        sorter: (a, b) => a.completedCount-b.completedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '共产生退回店数量',
        dataIndex: 'refusedCount',
        sorter: (a, b) => a.refusedCount-b.refusedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '未完成退回店数量',
        dataIndex: 'unReplyCount',
        sorter: (a, b) => a.unReplyCount-b.unReplyCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
          title: 'A/A+门店完成量',
          dataIndex: 'aAplusAudit2TotalCount',
          sorter: (a, b) => a.aAplusAudit2TotalCount-b.aAplusAudit2TotalCount,
          render: (text, record) => {
              return text ? text : '0'
          }
      },
      {
        title: '特殊标记门店完成量',
        dataIndex: 'specificAudit2TotalCount',
        sorter: (a, b) => a.specificAudit2TotalCount-b.specificAudit2TotalCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '完成比例',
        dataIndex: 'w',
        sorter: (a, b) => ((a.audit2TotalCount/a.masterCount) * 100)-((b.audit2TotalCount/b.masterCount) * 100),
        render:(text,record)=>{
          return record.audit2TotalCount?((record.audit2TotalCount/record.masterCount) * 100).toFixed(2) +'%':'0%'
        }
      },
    ])

  const [dataSource, setDataSource] = useState([])

  const getdata = () =>{
    axios.get('/api/jhStatistic/statisticItemByDsr',{
      params:{
        projectId : projectid,
        agentId:agentid
      }
    })
    .then(res=>{
      setDataSource(res.data)
      banma()
    })
  }

  const banma = ()=>{
    setTimeout(()=>{
      let domarr = document.getElementsByTagName('tr')
      domarr = [...domarr]
      domarr.forEach((v,i) => {
        if(i%2== 0){
          v.style.background='#f1f1f1'
        }else{
          v.style.background='#fff'
        }
      });
    },200)
  }

  useEffect(() => {
    getdata()
  }, [])

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
           {//搜索模块位置
           }
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true }} onChange={e=>{banma()}} />

      </div>
    </div>
  )
}

export default App
