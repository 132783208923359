import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, useHistory , useRouteMatch} from 'react-router-dom'

import RedactQuestionnaire from './work/RedactQuestionnaire'
import RedactQuestionnaireList from './work/RedactQuestionnaireList'
import RedactAnswerList from './work/RedactAnswerList'
import RedactList from './work/RedactList'
import RedactParticulars from './work/RedactParticulars'
import RedactIssue from './work/RedactIssue'
import RedactStatistical from './work/RedactStatistical'
import RedactStatisticalList from './work/RedactStatisticalList'

import linshi from './work/linshi'

function App(props) {
  return(
    <>
      <Route path="/wj/:projectid/work/questionnairelist" component={RedactQuestionnaireList} />
      <Route path="/wj/:projectid/work/questionnaire/:wjid" component={RedactQuestionnaire} />
      <Route path="/wj/:projectid/work/answerlist" component={RedactAnswerList} />
      <Route path="/wj/:projectid/work/list" component={RedactList} />
      <Route path="/wj/:projectid/work/particulars/:taskid" component={RedactParticulars} />
      <Route path="/wj/:projectid/work/issue/:templateId" component={RedactIssue} />
      <Route path="/wj/:projectid/work/statisticallist" component={RedactStatisticalList} />
      <Route path="/wj/:projectid/work/statistical/:templateId" component={RedactStatistical} />
      <Route path="/wj/:projectid/work/linshi" component={linshi} />
      {
        // <Route path="/wj/:projectid/work/previewy/:wjid" component={RedactPreviewy} />
        // <Route path="/wj/:projectid/work/answer/:wjid" component={RedactAnswer} />
      }
    </>
  )
}
export default App
