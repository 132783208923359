import React, { useEffect, useState, Component } from 'react'
import { Button, Row, Col } from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import axios from '@/utils/axios'
import qi from '@/assets/images/qi.png'
import zhong from '@/assets/images/zhong.png'
import mu from '@/assets/images/mu.png'

class App extends Component {
  constructor() {
    super()
    this.state = {
      coordinate: [],
      data: [],
      datas: [],
      shopArr:{}
    }
  }

  MP(ak) {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://api.map.baidu.com/api?v=2.0&ak=${ak}&callback=init`;
      document.head.appendChild(script)
      window.init = () => {
        resolve(window.BMap)
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    setTimeout(function () {
      if (JSON.stringify(self.state.data) != JSON.stringify(nextProps.data)) {
        self.setState({
          data: self.state.data.length != 0 ? self.state.data : nextProps.data,
        }, () => {
          axios.get('/api/core/track/list', {
            params: {
              taskId: self.state.data.id
            }
          }).then(res => {
            self.MP("OU0kfdwRLDoqustVERTNqylTDZgwGRXg")
              .then(BMap => {
                const BMapGL = BMap
                const map = new BMapGL.Map("container"); // 创建地图实例 
                let point = new BMapGL.Point(116.404, 39.915);  // 创建点坐标 
                map.centerAndZoom(point, 15);
                if (JSON.stringify(res.data) != '[]') {
                  res.data.forEach((v) => {
                    v.point = v.point.split(',').reverse()
                  })
                  let arr = []
                  res.data.forEach((v, i) => {
                    arr.push(new BMapGL.Point(v.point[0], v.point[1]))
                  });
                  const pointtwo = new BMapGL.Point(res.data[0].point[0], res.data[0].point[1])

                  var myIcon = new BMapGL.Icon(qi, new BMap.Size(23, 82), {
                    offset: new BMapGL.Size(11, 50), // 指定定位位置
                  });

                  var marker = new BMapGL.Marker(pointtwo, { icon: myIcon });
                  map.addOverlay(marker);

                  const pointthree = new BMapGL.Point(res.data[res.data.length - 1].point[0], res.data[res.data.length - 1].point[1])

                  var myIcon = new BMapGL.Icon(zhong, new BMap.Size(23, 82), {
                    offset: new BMapGL.Size(11, 50), // 指定定位位置
                  });

                  var marker = new BMapGL.Marker(pointthree, { icon: myIcon });
                  map.addOverlay(marker);

                  var polyline = new BMapGL.Polyline(arr, { strokeColor: "red", strokeWeight: 2, strokeOpacity: 0.5 });
                  map.addOverlay(polyline);      // 创建标注  

                  map.centerAndZoom(pointtwo, 20)
                }
                map.enableScrollWheelZoom(true);
                const pointmendia = self.state.data.startPoint != null ? self.state.data.startPoint.split(',') : null
                if (pointmendia != null && pointmendia.indexOf('null') == -1) {
                  let pointtwos = new BMapGL.Point(pointmendia[1], pointmendia[0]);  // 创建点坐标      //位置 创建标注  
                  map.centerAndZoom(pointtwos, 15)
                  var myIcon = new BMapGL.Icon(mu, new BMap.Size(23, 82), {
                    offset: new BMapGL.Size(11, 50), // 指定定位位置
                  });
                  map.addOverlay(new BMapGL.Marker(pointtwos, { icon: myIcon }));      // 创建标注  
                }

              });

          })
        })
      }
    }, 0)
  }

  componentDidMount() {

  }

  render() {
    return (
      <>
        <Col span='24' style={{ border: '1px solid rgba(233, 233, 233, 1)', borderRadius: '5px', marginTop: '10px' }}>
          <div style={{ padding: '10px 20px', background: 'rgba(250, 250, 250, 1)', borderBottom: '1px solid rgba(233, 233, 233, 1)', display: 'flex', alignItems: 'center' }}>
            地图数据<CloudDownloadOutlined style={{ fontSize: '20px' }} />{/* 未加图片 标记 */}
          </div>
          <Row style={{ padding: '10px 20px' }}>
            <Col span='13' style={{ padding: '20px', height: '300px' }}>
              <div id='container' style={{ height: '100%' }}></div>{/* 地图 */}
            </Col>
            <Col span='11' flex=''>

              <div style={{ padding: '20px', height: '33.33%' }}>
                <Row>
                  <Col span='14'>
                    <p style={{ fontWeight: '600' }}>{this.props.location.pathname.includes('/jh') ? '门店' : '小区'}名称:</p>
                    <p>{this.state.data.surveyTarget}</p>
                  </Col>
                  <Col span='10'>
                    <p style={{ fontWeight: '600' }}>门店等级:<span style={{ fontWeight: '0' }}>{this.state.data.shopLevel}</span></p>
                  </Col>
                </Row>
              </div>
              <div style={{ padding: '20px', height: '33.33%' }}>
                <Row>
                  <Col span='14'>
                    <p style={{ fontWeight: '600' }}>{this.props.location.pathname.includes('/jh') ? '门店' : '小区'}地址:</p>
                    <p>{this.state.data.startPointDesc}</p>
                  </Col>
                  <Col span='10'>
                    {//<p style={{ fontWeight: '600' }}>拍照数量:<span style={{ fontWeight: '0' }}>{this.state.data.totalProgress}</span></p>
                    }
                    <p style={{ fontWeight: '600' }}>调研编号:<span style={{ fontWeight: '0' }}>{this.state.data.surveyCode}</span></p>
                  </Col>
                </Row>
              </div>
              <Row style={{ flex: '1', padding: '20px', height: '33.33%' }}>
                <Col span='11'>
                  <p style={{ fontWeight: '600' }}>开始时间:</p>
                  <p>{this.state.data.length != 0 && this.state.data.startAt != null ? this.state.data.startAt.split('.')[0] : '-'}</p>
                </Col>
                <Col span='2'></Col>
                <Col span='11'>
                  <p style={{ fontWeight: '600' }}>结束时间:</p>
                  <p>{this.state.data.length != 0 && this.state.data.endAt != null ? this.state.data.endAt.split('.')[0] : '-'}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </>
    )
  }
}

export default withRouter(App)