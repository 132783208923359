import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import PopConfirmOnly from '@/utils/PopConfirmOnly'

const { Option } = Select;

function App(props) { 

  const handleShowEdit = e => {
    setModalVisible('edit')
    setNameEdit(e.name)
    setUrlEdit(e.url)
    setMethodEdit(e.method)
    setRemarkEdit(e.remark)
    setIdEdit(e.id)
    setParentEdit(e.parent)
    setTypeEdit(e.type)
    setCodeEdit(e.code)
  }

  // table
  const [columns, setColumns] = useState([
    {
      title: '权限编号',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '权限名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      render: text => text == 0 ? '主页菜单权限' : text == 1 ? '接口权限' :'菜单权限'
    },
    {
      title: '资源编码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => handleShowEdit(record)}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    axios.get('/api/sys/resource/list', {
      params: {
				pageSize: 1000000,
        key: selectText
      }
    }).then(res => {
      setDataSource(res.data.data)
    })
  }
  useEffect(() => {
    getData()
  }, [updateFlag])

  //select
  const [selectText, setSelectText] = useState('')

  const clearSelect = e => {
    setSelectText('')
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setNameAdd('')
    setUrlAdd('')
    setMethodAdd('')
    setRemarkAdd('')
    setIdEdit('')
    setNameEdit('')
    setUrlEdit('')
    setMethodEdit('')
    setRemarkEdit('')
    setTypeAdd('')
    setCodeAdd('')
    setTypeEdit('')
    setCodeEdit('')
  }

  // 初始化弹窗 method option
  const [methodOption, setMethodOption] = useState([])
  useEffect(() => {
    if (modalVisible !== null) {
      axios.get('/api/sys/dict/resourceMethod').then(res => {
        const arr = Object.entries(res.data.labelValues).map(i => {
          return {
            text: i[1],
            value: i[0]
          }
        })
        setMethodOption(arr)
      })
    } else {
      setMethodOption([])
    }
  }, [modalVisible])
  
  //添加
  const [nameAdd, setNameAdd] = useState('')
  const [urlAdd, setUrlAdd] = useState('')
  const [methodAdd, setMethodAdd] = useState('')
  const [remarkAdd, setRemarkAdd] = useState('')
  const [typeAdd, setTypeAdd] = useState('')
  const [codeAdd, setCodeAdd] = useState('')
  const handleSave = e => {
    console.log(nameAdd,typeAdd)
    if(nameAdd!=''&&(typeEdit!=''||typeEdit==0)){
      axios.post('/api/sys/resource', { name: nameAdd, parent:0, url: urlAdd, method: methodAdd, remark: remarkAdd, code: codeAdd, type: typeAdd}).then(res => {
        handleModalCancel()
        message.success()
        getData()
      })
    }else{
      message.error('请完整填写信息,再次提交')
    }
  }
  //修改
  const [idEdit, setIdEdit] = useState('')
  const [parentEdit, setParentEdit] = useState('')
  const [nameEdit, setNameEdit] = useState('')
  const [urlEdit, setUrlEdit] = useState('')
  const [methodEdit, setMethodEdit] = useState('')
  const [remarkEdit, setRemarkEdit] = useState('')
  const [typeEdit, setTypeEdit] = useState('')
  const [codeEdit, setCodeEdit] = useState('')
  const handleEdit = e => {
    console.log(typeEdit)
    if(nameEdit!=''&&(typeEdit!=''||typeEdit==0)){
      axios.put('/api/sys/resource', { id: idEdit, parent: parentEdit, name: nameEdit, url: urlEdit, method: methodEdit, remark: remarkEdit, code: codeEdit, type: typeEdit }).then(res => {
        handleModalCancel()
        message.success()
        getData()
      })
    }else{
      message.error('请完整填写信息,再次提交')
    }
  }
  //删除
  const handleDelete = e => {
    axios.delete('/api/sys/resource/' + e).then(res => {
      message.success()
      getData()
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <span>权限名称</span>
            <Input style={{ width: '320px', margin: '0 10px' }} placeholder='请输入权限名称' value={selectText} onChange={e => setSelectText(e.target.value)}></Input>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => getData()}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('add')} icon={<PlusOutlined />}>添加权限</Button>
            {/* <Button style={{ margin: '0 10px' }}>导出列表</Button> */}
          </Col>
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />

      </div>

      <Modal
        width={640}
        title="添加权限"
        visible={modalVisible === 'add'}
        onCancel={handleModalCancel}
        destroyOnClose
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleModalCancel}>取消</Button>,
          <Button key="submit" type="primary" onClick={handleSave}>确认</Button>,
        ]}
      >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>权限名称:</Col>
          <Col span={14}><Input value={nameAdd} onChange={e => setNameAdd(e.target.value)}></Input></Col>

          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>权限类型:</Col>
          <Col span={14}>
            <Select style={{ width: 120 }} value={typeAdd} onChange={e=>{setTypeAdd(e)}}>
              <Option value={0}>主菜单</Option>
              <Option value={1}>接口</Option>
              <Option value={2}>项目菜单</Option>
            </Select>
          </Col>

          <Col span={6} style={{ textAlign: 'right' }}>资源编码  :</Col>
          <Col span={14}><Input value={codeAdd} onChange={e => setCodeAdd(e.target.value)}></Input></Col>

          <Col span={6} style={{ textAlign: 'right' }}>URL:</Col>
          <Col span={14}><Input value={urlAdd} onChange={e => setUrlAdd(e.target.value)}></Input></Col>

          <Col span={6} style={{ textAlign: 'right' }}>请求方式:</Col>
          <Col span={14}>
            <Select style={{ width: '100%' }} value={methodAdd} onChange={e => setMethodAdd(e)}>
            {
                methodOption.map((i, index) => (<Select.Option key={index} value={i.value}>{i.text}</Select.Option>))
              }
            </Select>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>备注:</Col>
          <Col span={14}><Input.TextArea rows={5} value={remarkAdd} onChange={e => setRemarkAdd(e.target.value)}></Input.TextArea></Col>
        </Row>
      </Modal>

      <Modal
        width={640}
        title="修改权限"
        visible={modalVisible === 'edit'}
        onCancel={handleModalCancel}
        destroyOnClose
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleModalCancel}>取消</Button>,
          <Button key="submit" type="primary" onClick={handleEdit}>确认</Button>,
        ]}
      >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>权限名称:</Col>
          <Col span={14}><Input value={nameEdit} onChange={e => setNameEdit(e.target.value)}></Input></Col>

          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>权限类型:</Col>
          <Col span={14}>
            <Select style={{ width: 120 }} value={typeEdit} onChange={e=>{setTypeEdit(e)}}>
              <Option value={0}>主菜单</Option>
              <Option value={1}>接口</Option>
              <Option value={2}>项目菜单</Option>
            </Select>
          </Col>

          <Col span={6} style={{ textAlign: 'right' }}>资源编码  :</Col>
          <Col span={14}><Input value={codeEdit} onChange={e => setCodeEdit(e.target.value)}></Input></Col>

          <Col span={6} style={{ textAlign: 'right' }}>URL:</Col>
          <Col span={14}><Input value={urlEdit} onChange={e => setUrlEdit(e.target.value)}></Input></Col>

          <Col span={6} style={{ textAlign: 'right' }}>请求方式:</Col>
          <Col span={14}>
            <Select style={{ width: '100%' }} value={methodEdit} onChange={e => setMethodEdit(e)}>
            {
                methodOption.map((i, index) => (<Select.Option key={i.index} value={i.value}>{i.text}</Select.Option>))
              }
            </Select>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>备注:</Col>
          <Col span={14}><Input.TextArea rows={5} value={remarkEdit} onChange={e => setRemarkEdit(e.target.value)}></Input.TextArea></Col>
        </Row>
      </Modal>
    </div>
  )
}

export default App