import React, { useRef, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Breadcrumb, Row, Col, Divider, Button, Select } from 'antd'
import { MenuFoldOutlined, SearchOutlined, BellOutlined, LeftOutlined } from '@ant-design/icons';
import store from '@/redux/store'
import axios from '@/utils/axios'


function App(props) {

  const { push, goBack } = useHistory()
  const { pathname } = useLocation()
  const { projectid } = useRouteMatch().params

  const [allPath, setAllPath] = useState(pathname)
  const [menuType, setMenuType] = useState(pathname.split('/').slice(1, 2).join(''))

  const [name, setName] = useState(store.getState().user.name)
  useEffect(() => {
    const s = store.subscribe(() => setName(store.getState().user.name))
    return () => {
      s()
    }
  }, [])

  const [title, setTitle] = useState(null)
  useEffect(() => {
    if (store.getState().header.value) setTitle(store.getState().header.value)
    const s = store.subscribe(() =>
      setTitle(store.getState().header.value)
    )
  }, [])

  const handleExit = e => {
    store.dispatch({ type: 'user_clear' })
  }

  const matchPath = e => {
    const arr = pathMap.current[menuType][Object.keys(pathMap.current[menuType]).filter(i => allPath.includes(i))[0]]
    return arr ? arr : []
  }

  useEffect(() => {
    setAllPath(pathname)
  }, [pathname])
  useEffect(() => {
    setMenuType(pathname.split('/').slice(1, 2).join(''))
  }, [pathname])

  const [projectOption, setProjectOption] = useState([])
  useEffect(() => {
    if (!projectid) return
    axios.get('/api/core/project/list', {
      params: {
				pageSize: 1000000,
       userId:store.getState().user.uid
      }
    }).then(res => {
      setProjectOption(res.data.data)
    })
  }, [projectid])
  const handleChangeProject = e => {
    const target = projectOption.filter(i => e === i.id)[0]
    const type = {
      2: 'sx',
      1: 'jh',
      4: 'wj'
    }
    push('/' + type[target.type] + '/' + e + '/index')
  }


  const pathMap = useRef({
    home: {
      '/index': ['项目概览'],
      '/project/create': ['项目管理', '创建项目'],
      '/project/look': ['项目管理', '查看项目'],
      '/project/list': ['项目管理', '项目列表'],
      '/project/edit': ['项目管理', '项目列表', '修改项目'],
      '/control/list': ['权限控制', '权限列表'],
      '/control/role': ['权限控制', '角色列表'],
      '/control/people': ['权限控制', '项目人员管理'],
      '/control/peopledetail': ['权限控制', '项目人员管理', '查看详情'],
      '/control/xuzhongdetail/0': ['权限控制', '旭中人员管理'],
      '/set/product': ['项目设置', '家化产品管理'],
      '/set/shop': ['项目设置', '家化门店管理'],
      '/set/shopdetail': ['项目设置', '家化门店管理', '查看详情'],
      '/set/target': ['项目设置', '实效指标管理'],
      '/set/targetdetail': ['项目设置', '实效指标管理', '查看详情'],
      '/set/location': ['项目设置', '实效位置管理'],
      '/set/locationdetail': ['项目设置', '实效位置管理', '查看详情'],
      '/set/productdetail': ['项目设置', '家化产品管理', '查看详情'],
      '/set/projecttemplate': ['项目设置', '项目模板'],
      '/set/apptemplate': ['项目设置', 'APP模板'],
      '/set/apptemplatedetail': ['项目设置', 'APP模板', '模版详情'],
      '/set/computetemplate': ['项目设置', '算分模板'],
      '/set/projecttemplatedetail': ['项目设置', '项目模板', '模板详情'],
      '/set/standardItemProduct': ['项目设置', '算分标准明细产品信息管理'],
      '/set/standardItem': ['项目设置', '算分标准明细管理'],
      '/set/standard': ['项目设置', '算分标准管理'],
      '/set/region': ['项目设置', '算分区域管理'],
      '/log': ['操作日志'],

      '/data/visitormutangchun': ['数据统计', '访问员业绩报告','木糖醇'],
      '/data/visitorchenlie': ['数据统计', '访问员业绩报告','陈列'],
      '/data/visitorDSR': ['数据统计', '访问员业绩报告','DSR'],
      '/data/visitor': ['数据统计', '访问员业绩报告'],

      '/data/agencymutangchun': ['数据统计', '代理业绩报告','木糖醇'],
      '/data/agencychenlie': ['数据统计', '代理业绩报告','陈列'],
      '/data/agencyDSRType': ['数据统计', '代理业绩报告','DSR列表'],
      '/data/agencyDSR': ['数据统计', '代理业绩报告','DSR'],
      '/data/agencydaili': ['数据统计', '代理业绩报告','代理'],
      '/data/agency': ['数据统计', '代理业绩报告'],

      '/data/first': ['数据统计', '审核业绩报告'],
    },
    jh: {
      '/index': ['家化项目概览'],
      '/work/list': ['门店管理', '门店列表'],
      '/work/assign': ['门店管理', '门店分配'],
      '/work/message': ['门店管理', '消息反馈'],
      '/check/plan': ['审核管理', '审核安排'],
      '/check/back': ['审核管理', '审核退回'],
      '/check/list': ['审核管理', '审核列表'],
      '/check/first': ['审核管理', '一审管理'],
      '/check/second': ['审核管理', '二审管理'],
      '/check/again': ['审核管理', '复核管理'],
      '/check/record': ['进店记录', '进店记录'],
      '/check/particular': ['审核管理', '审核列表', '查看详情'],
      '/set/people': ['项目设置', '人员管理'],
      '/set/product': ['项目设置', '产品管理'],
      '/set/shop': ['项目设置', '门店管理'],
      '/set/log': ['项目设置', '操作日志'],
      '/data/visitor': ['数据统计', '访问员业绩报告'],
      '/data/agency': ['数据统计', '代理业绩报告'],
      '/data/first': ['数据统计', '一审业绩报告'],
      '/data/second': ['数据统计', '二审业绩报告'],
      '/log': ['操作日志'],
    },
    sx: {
      '/index': ['项目概况'],
      '/work/list': ['工单管理', '工单列表'],
      '/work/assign': ['工单管理', '工单分配'],
      '/work/message': ['工单管理', '消息反馈'],
      '/check/plan': ['审核管理', '审核安排'],
      '/check/back': ['审核管理', '审核退回'],
      '/check/list': ['审核管理', '审核列表'],
      '/check/first': ['审核管理', '一审管理'],
      '/check/second': ['审核管理', '二审管理'],
      '/check/again': ['审核管理', '复核管理'],
      '/check/completed': ['审核管理', '已完成管理'],
      '/check/dsr': ['审核管理', '扫DSR管理'],
      '/check/record': ['进场记录', '进场记录'],
      '/check/particular': ['审核管理', '审核列表', '查看详情'],
      '/set/people': ['项目设置', '人员管理'],
      '/set/target': ['项目设置', '指标管理'],
      '/set/location': ['项目设置', '位置管理'],
      '/set/log': ['项目设置', '操作日志'],
      '/data/visitor': ['数据统计', '访问员业绩报告'],
      '/data/agency': ['数据统计', '代理业绩报告'],
      '/data/first': ['数据统计', '一审业绩报告'],
      '/data/second': ['数据统计', '二审业绩报告'],
      '/log': ['操作日志']
    },
    wj:{
      '/work/list': ['问卷管理', '答题管理'],
      'work/questionnairelist':['问卷管理','编辑问卷'],
      'work/answerlist':['问卷管理','问卷答题'],
      'work/statisticallist':['问卷管理','问卷统计'],

    }
  })

  return (
    <div>

      <Row justify='space-between' style={{ padding: '0 32px' }}>
        <Col>
          <MenuFoldOutlined style={{ fontSize: '20px', margin: '0 20px' }} onClick={goBack} />
          {
            projectid ?
              <>
                <LeftOutlined style={{ color: 'rgb(42, 130, 228)', margin: '0 20px' }} onClick={e => push('/home/project/list')} />
                <Select showSearch
                filterOption = {
                    (input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } style={{ width: '320px', margin: '0 20px' }} value={Number(projectid)} onChange={e => handleChangeProject(e)}>
                  <Select.Option value={''}>取消选择</Select.Option>
                  {
                    projectOption.map((i, index) => (
                      <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                    ))
                  }
                </Select>
              </>
              : null
          }
        </Col>
        <Col>
          <SearchOutlined style={{ fontSize: '20px', margin: '0 20px' }} />
          <BellOutlined style={{ fontSize: '20px', margin: '0 20px' }} />
          {
            name ?
              <>
                <span style={{ margin: '0 20px' }}>{name}</span>
                <Button style={{ border: 'none' }} onClick={handleExit}>退出</Button>
              </>
              : null
          }

        </Col>
      </Row>

      <Divider style={{ margin: '0' }} />

      <div style={{ margin: '0 40px' }}>
        <Breadcrumb style={{ margin: '15px 0' }}>
          {
            matchPath().map(i => (
              <Breadcrumb.Item>{i}</Breadcrumb.Item>
            ))

          }
        </Breadcrumb>
        <p style={{ lineHeight: '1', margin: '15px 0', fontSize: '18px', fontWeight: 'bold' }}>
          {
            title ?
              title :
              matchPath()[matchPath().length - 1]
          }
        </p>
      </div>
    </div>
  )
}

export default App