import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Radio } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { baseUrl } from '@/utils/config'
import store from '@/redux/store'
// import  session  from '@/utils/session'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [labelValues, setlabelValues] = useState([])

  // table  columns不能用usestate
  const columns = [
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      key: 'random',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    }, {
      title: '访问员',
      dataIndex: 'principal',
      render: text => text ? text : '-',
      sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '一审状态',
      dataIndex: 'taskAudit1Status',
      render: (text, record) => text?.display ? text.display == '已退回' ? '已退回-需' + record.labelValues[record.audit1RefusedReasonType] : text.display : '-'
    },
    {
      title: '二审状态',
      dataIndex: 'taskAudit2Status',
      render: (text, record, index) => (
        text?.display ? text.display == '已退回' ? '已退回-需' + record.labelValues[record.audit2RefusedReasonType] : text.display : '-'
      )
    },
    {
      title: '复审状态',
      dataIndex: 'taskReviewStatus',
      render: (text, record) => (
        text?.display ? text.display == '已退回' ? '已退回-需' + record.labelValues[record.reviewRefusedReasonType] : text.display : '-'
      )
    },
    {
      title: '总退回次数',
      dataIndex: 'refusedCount',
      render: text => text ? text : '0',
      sorter: (a, b) => a.refusedCount-b.refusedCount,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button type='link' size='small' onClick={e => push_page(record)}>查看</Button>
        </>
      )
    },
  ]
  //
  // 查看详情函数
  const push_page = e => {
    store.dispatch({
      type: 'selectCache_init', value: {
        page: 'sx-checklist',
        select: {
          surveyorSelect,
          scopeSelect,
          dsrSelect,
          codeSelect,
          firstStatusSelect,
          secondStatusSelect,
          reviewStatusSelect,
          dataSource
        }
      }
    })
    push('/sx/' + projectid + '/check/particular/' + e.id)
  }

  // select
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [dsrSelect, setDsrSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [firstStatusSelect, setFirstStatusSelect] = useState(null)
  const [secondStatusSelect, setSecondStatusSelect] = useState(null)
  const [reviewStatusSelect, setReviewStatusSelect] = useState(null)

  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const [vistorSelect, setVistorSelect] = useState(null)
  const [refusedCountarr, setrefusedCountarr] = useState([])
  const [refusedCount, setrefusedCount] = useState(null)

  const [getData_flag, setGetData_flag] = useState(false)

  const getData = e => {
    const queryCondition = []
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect?.split('-')[0]) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect.split('-')[0])
    if (secondStatusSelect?.split('-')[0]) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect.split('-')[0])
    if (reviewStatusSelect?.split('-')[0]) queryCondition.push('EQ:taskReviewStatus=' + reviewStatusSelect.split('-')[0])
    if (scopeSelect) queryCondition.push('EQ:surveyScope=' + scopeSelect)
    if (refusedCount) queryCondition.push('EQ:refusedCount=' + refusedCount)
    if (firstStatusSelect?.split('-')[1]) queryCondition.push('EQ:audit1RefusedReasonType=' + firstStatusSelect.split('-')[1])
    if (secondStatusSelect?.split('-')[1]) queryCondition.push('EQ:audit2RefusedReasonType=' + secondStatusSelect.split('-')[1])
    if (reviewStatusSelect?.split('-')[1]) queryCondition.push('EQ:reviewRefusedReasonType=' + reviewStatusSelect.split('-')[1])
    axios.get('/api/core/task/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(','),
        auditSample: true,
        userId:store.getState().user.uid
      }
    }).then(resa => {
      setTotal(resa.data.totalSize)
      const arr = []
      resa.data.data.forEach(v => {
        if (v.refusedCount != null && arr.indexOf(v.refusedCount) == -1 && v.refusedCount != 0) {
          arr.push(v.refusedCount)
        }
      });
      setrefusedCountarr(arr)
      axios.get('/api/sys/dict/' + 'sxTaskRefusedReasonType', {
        params: {
          pageSize: 1000000,
        }
      })
        .then(res => {
          const arr = resa.data.data.map(i => { return { ...i, key: i.id, labelValues: res.data.labelValues } })
          setDataSource(arr)
        })
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])


  useEffect(() => {
    if (!getData_flag) return
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'shixiao_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'shixiao'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [updateFlag, getData_flag])


  // 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()
  useEffect(() => {
    if (store.getState().selectCache.page === 'sx-checklist') {
      const data = store.getState().selectCache.select
      Promise.all([
        new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
        new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
        new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
        new Promise(r => setCodeSelect(data.codeSelect, () => r())),
        new Promise(r => setFirstStatusSelect(data.firstStatusSelect, () => r())),
        new Promise(r => setSecondStatusSelect(data.secondStatusSelect, () => r())),
        new Promise(r => setReviewStatusSelect(data.reviewStatusSelect, () => r())),
        new Promise(r => setDataSource(data.dataSource, () => r())),
      ]).then(res => {
        setGetData_flag(true)
        store.dispatch({ type: 'selectCache_clear' })
      })
    } else {
      setGetData_flag(true)
      store.dispatch({ type: 'selectCache_clear' })
    }
  }, [])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [audit1StatusOption, setAudit1StatusOption] = useState([])
  const [audit2StatusOption, setAudit2StatusOption] = useState([])
  const [reviewStatusOption, setReviewStatusOption] = useState([])
  const [scopeOption, setScopeOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/taskAudit1Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit1StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskAudit2Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit2StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskReviewStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setReviewStatusOption(arr)
    })
    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'shixiao_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'shixiao'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [])

  const clearSelect = e => {
    setScopeSelect(null)
    setDsrSelect('')
    setCodeSelect('')
    setFirstStatusSelect(null)
    setSecondStatusSelect(null)
    setReviewStatusSelect(null)
    setSurveyorSelect(null)
    setrefusedCount(null)
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setRemark('')
    setTaskId(null)
    setSampleType(null)
    setSampleScore('')
    setSamplePercent('')
    setSamplePercent_('')
  }
  const [remark, setRemark] = useState('')
  // 退回
  const [taskId, setTaskId] = useState(null)
  const handleBack = e => {
    axios.post('/api/core/taskAudit/audit1Refused', {
      taskId: taskId,
      audit1RefusedReason: remark
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //批量退回
  const handleBackMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/taskAudit/batchAudit1Refused', {
      audit1RefusedReason: remark
    }, {
      params: {
        taskIds: selectedRowKeys.join(',')
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //安排判断
  const handleDoBack = e => {
    if (modalVisible === 'back') handleBack()
    if (modalVisible === 'backMore') handleBackMore()
  }
  //抽样
  const [sampleType, setSampleType] = useState(null)
  const [sampleScore, setSampleScore] = useState('')
  const [samplePercent, setSamplePercent] = useState('')
  const [samplePercent_, setSamplePercent_] = useState('')
  const [spmpleId, setSpmpleId] = useState(null)
  const handleSaveSample = e => {
    if (!sampleType) {
      message.error('请选择抽样类型')
      return
    }
    const body = { projectId: Number(projectid), ruleType: sampleType }
    if (sampleType === 2) body.lessThanScore = sampleScore
    if (sampleType === 3) body.denominator = samplePercent
    if (sampleType === 3) body.numerator = samplePercent_
    if (spmpleId) body.id = spmpleId
    if (!spmpleId) axios.post('/api/core/auditSampleSetting', body).then(res => {
      message.success()
      getData()
      handleModalCancel()
    })
    if (spmpleId) axios.put('/api/core/auditSampleSetting', body).then(res => {
      message.success()
      getData()
      handleModalCancel()
    })

  }
  useEffect(() => {
    if (modalVisible === 'sample')
      axios.get('/api/core/auditSampleSetting/list', { params: { queryCondition: 'EQ:projectId=' + projectid } }).then(res => {
        if (res.data.data.length) {
          setSpmpleId(res.data.data[0].id)
          setSampleType(res.data.data[0].ruleType)
          if (res.data.data[0].lessThanScore) setSampleScore(res.data.data[0].lessThanScore)
          if (res.data.data[0].denominator) setSamplePercent(res.data.data[0].denominator)
          if (res.data.data[0].numerator) setSamplePercent_(res.data.data[0].numerator)
        }
      })
  }, [modalVisible])
  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:status=21')
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (reviewStatusSelect) queryCondition.push('EQ:taskReviewStatus=' + reviewStatusSelect)
    if (scopeSelect) queryCondition.push('EQ:surveyScope=' + scopeSelect)
    if (refusedCount) queryCondition.push('EQ:refusedCount=' + refusedCount)

    axios.get('/api/core/taskAudit/export', {
      params: {
        pageSize: 1000000,
        queryCondition: queryCondition.join(','),
        taskAuditExportType: 9
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "工单列表.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }
  const [data,setdata]=useState({})
  const [numsum,setnumsum]=useState([])

  useEffect(()=>{
    (
      async () => {
        const res = await axios.get('/api/core/project/' + projectid)
        setdata(res.data)
        const numsum = await axios.get('/api/core/project/summary',{
          params:{
            projectId:projectid
          }
        })
        console.log(numsum.data)
        setnumsum(numsum.data)
      }
    )()
  },[])

  return (
    <div style={{}}>
    <Row style={{background:"#fff",padding: '30px'}}>
        <Col span='24' style={{display:'flex',alignItems:'center'}}>
          <font style={{ fontWeight:'600' ,fontSize:'20px'}}>{data.name}</font>
          <div style={{margin:'0 20px',width:'5px',height:'5px',borderRadius:'50%',background:data.status!=null && labelValues[data.status]!='未开始'?'rgba(82, 196, 25, 1)':'red'}}></div>
          {labelValues[data.status]}
        </Col>
        <Col span='6' style={{margin: '10px 0',}}>项目编号:{data.code}</Col>
        <Col span='8' style={{margin: '10px 0',}}>生效日期:{data.startDate} -- {data.endDate}</Col>
        <Col span='6' style={{margin: '10px 0',}}>预警日期:{data.warningDate}</Col>
        <Col span='4' style={{margin: '10px 0',}}>上传数:{numsum.totalUploadCount}</Col>
        <Col span='6' style={{margin: '10px 0',}}>未开始:{numsum.pendingCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>进行中:{numsum.doingCount}</Col>
        <Col span='6' style={{margin: '10px 0',}}>工单总数:{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',fontWeight:'600'}}>一审:</Col>
        <Col span='8' style={{margin: '10px 0',fontWeight:'600'}}>二审:</Col>
        <Col span='8' style={{margin: '10px 0',fontWeight:'600'}}>复核:</Col>

        <Col span='6' style={{margin: '10px 0',}}>(待审核/总数) : {numsum.audit1PendingCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(待审核/总数) : {numsum.audit2PendingCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(待复核/总数) : {numsum.reviewPendingCount}/{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.audit1ReturnCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.audit2ReturnCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.reviewReturnCount}/{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.audit1Count}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.audit2Count}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.reviewCount}/{numsum.totalTaskCount}</Col>
      </Row>
      <div style={{ padding: '30px', background: 'white' , margin:'30px' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>调研编号</span>
          <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
        </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研大区</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={scopeSelect} showSearch onChange={e => setScopeSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                scopeOption.map((i, index) => (
                  <Select.Option key={index} value={i}>{i}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
             style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>一审状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={firstStatusSelect} onChange={e => setFirstStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                audit1StatusOption.map((i, index) => {
                  return i.text != "已退回" ? <Select.Option key={index} value={i.value}>{i.text}</Select.Option> :
                    <>
                      <Select.Option key={index} value={i.value}>{i.text + '(总)'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-1'}>{i.text + '-实地补拍'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-2'}>{i.text + '-数据完善'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-3'}>{i.text + '-重做'}</Select.Option>
                    </>
                })
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>二审状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={secondStatusSelect} onChange={e => setSecondStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                audit2StatusOption.map((i, index) => {
                  return i.text != "已退回" ? <Select.Option key={index} value={i.value}>{i.text}</Select.Option> :
                    <>
                      <Select.Option key={index} value={i.value}>{i.text + '(总)'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-1'}>{i.text + '-实地补拍'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-2'}>{i.text + '-数据完善'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-3'}>{i.text + '-重做'}</Select.Option>
                    </>
                })
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>复核状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={reviewStatusSelect} onChange={e => setReviewStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                reviewStatusOption.map((i, index) => {
                  return i.text != "已退回" ? <Select.Option key={index} value={i.value}>{i.text}</Select.Option> :
                    <>
                      <Select.Option key={index} value={i.value}>{i.text + '(总)'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-1'}>{i.text + '-实地补拍'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-2'}>{i.text + '-数据完善'}</Select.Option>
                      <Select.Option key={index} value={i.value + '-3'}>{i.text + '-重做'}</Select.Option>
                    </>
                })
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>退回次数</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={refusedCount} onChange={e => setrefusedCount(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                refusedCountarr.map((i, index) => (
                  <Select.Option key={index} value={i}>{i}</Select.Option>
                ))
              }
            </Select>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('sample')}>审核抽样</Button>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
          }
          }}
        />

        <Modal
          width={640}
          title={'退回工单'}
          visible={modalVisible === 'back' || modalVisible === 'backMore'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleDoBack}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }} >退回理由:</Col>
            <Col span={14}>
              <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
            </Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title={'审核抽样'}
          visible={modalVisible === 'sample'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleSaveSample}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }} >抽样规则:</Col>
            <Col span={14}>
              <Radio checked={sampleType === 1} onChange={e => setSampleType(1)}>全检</Radio>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }} ></Col>
            <Col span={14}>
              <Radio checked={sampleType === 2} onChange={e => setSampleType(2)}>得分</Radio>
              <span style={{ margin: '0 10px' }}>评分低于</span>
              <Input style={{ width: '80px' }} value={sampleScore} onChange={e => setSampleScore(e.target.value)} placeholder='60' />
              <span style={{ margin: '0 10px' }}>分</span>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }} ></Col>
            <Col span={14}>
              <Radio checked={sampleType === 3} onChange={e => setSampleType(3)}>抽选</Radio>
              <Input style={{ width: '80px' }} value={samplePercent} onChange={e => setSamplePercent(e.target.value)} placeholder='5' />
              <span style={{ margin: '0 10px' }}>取</span>
              <Input style={{ width: '80px' }} value={samplePercent_} onChange={e => setSamplePercent_(e.target.value)} placeholder='1' />
            </Col>
          </Row>
        </Modal>

      </div>
    </div>
  )
}

export default App