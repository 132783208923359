import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { stringToNumber, filterSet } from '@/utils/common'
import { audioUrl } from '@/utils/config'

function App(props) {

  const { projectid } = useRouteMatch().params

  const columns = [
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
      render: (text, record) => record.task?.surveyCode ? record.task?.surveyCode : '-'
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: (text, record) => record.task?.surveyTarget ? record.task?.surveyTarget : '-',
      sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '代理人',
      dataIndex: 'agent',
      render: (text, record) => record.task?.agent ? record.task?.agent : '-',
      sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
      title: '访问员',
      dataIndex: 'principal',
      render: (text, record) => record.task?.principal ? record.task?.principal : '-',
      sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '时间',
      dataIndex: 'createdAt',
      sorter: (a, b) => a.createdAt?.localeCompare(b.createdAt),
      render: text => text ? text.split('.')[0]: '-'
    },
    {
      title: '反馈结果',
      dataIndex: 'task',
      render: text => text ? labelValues[text.audit1RefusedReasonType!=null?text.audit1RefusedReasonType:text.audit2RefusedReasonType!=null?text.audit2RefusedReasonType:text.reviewRefusedReasonType] : '-',
    },
    {
      title: '备注',
      dataIndex: 'task',
      render: text => text.audit1RefusedRemark!=null?text.audit1RefusedRemark:text.audit2RefusedRemark!=null?text.audit2RefusedRemark:text.reviewRefusedRemark
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => handleShowDetail(record)}>查看</Button>
          <Divider type='vertical' />
          <Button size='small' type='link' >提醒</Button>
        </>
      )
    }
  ]
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition = []
    queryCondition.push('EQ:projectId=' + projectid)
    if (surveyCodeSelect) queryCondition.push('EQ:surveyCode=' + surveyCodeSelect)

    axios.get('/api/core/taskReturnOrder/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(',')
      }
    }).then(res => {
      setTotal(res.data.totalSize)
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr, () => inquire())
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])


  const [labelValues,setlabelValues] = useState([])
  useEffect(() => {
    getData()
    axios.get('/api/sys/dict/' + 'jhTaskRefusedReasonType', {
      params: {
        pageSize: 1000000,
      }
    }).then(res => {
      setlabelValues(res.data.labelValues)
    })
  }, [updateFlag])

  const [selectedRowKeys, setSelectRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectRowKeys([])
  }

  //select
  const [dateSelect, setDateSelect] = useState([])
  const [surveyCodeSelect, setSurveyCodeSelect] = useState('')

  //clearSelect
  const clearSelect = e => {
    setDateSelect([])
    setSurveyCodeSelect('')
    setUpdateFlag(pre => pre + 1)
  }

  //弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setDataDetail(null)
  }
  //详情
  const [dataDetail, setDataDetail] = useState(null)
  const [dataDetaillist, setDataDetaillist] = useState(null)
  const handleShowDetail = e => {
    setModalVisible('detail')
    setDataDetail(e)
    setDataDetaillist(
      e.task.audit1RefusedReason!=''
      ?e.task.audit1RefusedReason
      :e.task.audit2RefusedReason!=''
      ?e.task.audit2RefusedReason
      :e.task.reviewRefusedReason
    )
  }
  //查询
  const inquire = ()=>{
    if(dateSelect.length!=0){
      setDataSource(dataSource.filter(v=>{
        let date = new Date(v.createdAt).getTime()
        return v.task.surveyCode&&v.task.surveyCode.indexOf(surveyCodeSelect)!=-1&&dateSelect[0]._d.getTime()<date&&dateSelect[1]._d.getTime()>date
      }))
    }else{
      setDataSource(dataSource.filter(v=>{
        return v.task.surveyCode&&v.task.surveyCode.indexOf(surveyCodeSelect)!=-1
      }))
    }
    getData()
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>
        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ weight: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
          <Col>
            <span style={{ lineHeight: '30px' }}>调研编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} value={surveyCodeSelect} onChange={e => setSurveyCodeSelect(e.target.value)}></Input>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={inquire}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }}>批量导出</Button>
          </Col>

        </Row>
        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{  defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
            }
           }}
        />

        <Modal
          width={720}
          title="反馈结果详情"
          visible={modalVisible === 'detail'}
          onCancel={handleModalCancel}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleModalCancel}>确认</Button>,
          ]}
        >
          {
            dataDetail ?
              <>
                <Row style={{ marginBottom: '10px' }}>
                  <Col span={6}>{dataDetail.task.audit1RefusedRemark?'一审:'+dataDetail.task.agent:dataDetail.task.audit2RefusedRemark?'二审:'+dataDetail.task.audit2ByName:'复核:'+dataDetail.task.reviewByName}</Col>
                  <Col span={6}>退回理由：{labelValues[dataDetail.task.audit1RefusedReasonType?dataDetail.task.audit1RefusedReasonType:dataDetail.task.audit2RefusedReasonType?dataDetail.task.audit2RefusedReasonType:dataDetail.task.reviewRefusedReasonType]}</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>审核时间：{(dataDetail.task.reviewAt!=null?dataDetail.task.reviewAt:dataDetail.task.audit2At!=null?dataDetail.task.audit2At:dataDetail.task.audit1At)?.split('.')[0]}</Col>
                </Row>
                <div style={{ border: '1px rgba(0, 0, 0, 0.06) solid', padding: '20px' }}>
                {
                  JSON.parse(dataDetaillist) instanceof Array?
                    JSON.parse(dataDetaillist).filter(function(element,index,self){
                      return JSON.parse(dataDetaillist).map(v=>{
                        return v.id
                      }).indexOf(element.id) === index;
                    }).map(v=>{
                      return <Row style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                        <Col span={5}>
                          {
                            v.id
                          }
                        </Col>
                        <Col span={5}>
                          {
                            v.productName
                          }
                        </Col>
                        <Col span={5}>
                        {
                          v.specification
                        }
                      </Col>
                      <Col span={5}>
                          {
                            v.beizhu
                          }
                        </Col>
                      </Row>
                    })
                    : dataDetaillist
                }
                  <p>备注：{dataDetail.task.audit1RefusedRemark?dataDetail.task.audit1RefusedRemark:dataDetail.task.audit2RefusedRemark?dataDetail.task.audit2RefusedRemark:dataDetail.task.reviewRefusedRemark}</p>
                </div>
                <Divider />
                <Row style={{ marginBottom: '10px' }}>
                  <Col span={6}>访问员：{dataDetail.principal}</Col>
                  <Col span={6}>反馈：{dataDetail.feedbackType}</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>反馈时间：{dataDetail.feedbackAt?.split('.')[0]}</Col>
                </Row>
                <div style={{ border: '1px rgba(0, 0, 0, 0.06) solid', padding: '20px' }}>
                  <p>备注：{dataDetail.feedbackRemark}</p>
                </div>
              </>
              : null
          }
        </Modal>
      </div>
    </div>

  )
}
export default App
