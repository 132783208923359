import React, { Component, useEffect, useState } from 'react';
import { Button, Row, Col, Modal, Input, Tooltip, Select, Checkbox } from 'antd'
import { RobotOutlined, TrademarkOutlined } from '@ant-design/icons'
import axios from '@/utils/axios'
import { withRouter } from 'react-router-dom'
import { audioUrl, baseUrl } from '@/utils/config'
import message from '@/utils/message'
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
import store from '@/redux/store'


const history = creatHistory();//返回上一页这段代码

const { Option } = Select;
const { TextArea } = Input;

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      id: '',
      visible: false,
      value: '',
      toolbool: false,
      visiblet: false,
      selectvalue: '1',
      tuihui: [],
      getdata: '',
      labelValues: [],
      mendianstatuslist: {},
      mendianstatus: {},
      audit2RefusedRemark:'',
      isModalVisible:'',
      string:'',
      taskid:'',
      reviewRefusedType:'',
      returnbool:false,
      audit1RefusedRemark:'',
      audit1RefusedReason:'',
      audit1RefusedReasonType:'',
      isbool:false,
      projectName:null
    }

    axios.get('/api/sys/dict/' + 'jhTaskRefusedReasonType', {
      params: {
        pageSize: 1000000,
      }
    })
      .then(res => {
        this.setState({ labelValues: res.data.labelValues })
      })

    axios.get('/api/sys/dict/' + 'shopStatus')
    .then(res => {
      this.setState({
        mendianstatuslist: res.data.labelValues
      })
    })
      
    axios.get('/api/core/project/'+this.props.match.params.projectid)
    .then(res => {
      this.setState({
        projectName: res.data.name
      })
    })
  }

  mendianstatus = () => {
    if (this.state.data.targetId) {
      axios.get('/api/core/shop/' + this.state.data.targetId)
        .then(res => {
          this.setState({
            mendianstatus: res.data
          })
        })
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(store.getState().user.role)
    let self = this;
    setTimeout(function () {
      if (JSON.stringify(self.state.data) != JSON.stringify(nextProps.data)) {
        self.setState({
          data: self.state.data.length != 0 ? self.state.data : nextProps.data,
          id: self.state.id != '' ? self.state.id : nextProps.id,
          getdata: self.state.getdata != '' ? self.state.getdata : nextProps.getdata,
          isbool: self.state.isbool != '' ? self.state.isbool : nextProps.isbool,
        }, () => {
          self.mendianstatus()
          self.setState({
            audit2RefusedRemark:self.state.data.audit2RefusedRemark,
            audit1RefusedRemark:self.state.data.audit2RefusedRemark != '' ? self.state.data.audit2RefusedRemark : self.state.data.reviewRefusedRemark,
            audit1RefusedReason:self.state.data.audit2RefusedReason != '' ? self.state.data.audit2RefusedReason : self.state.data.reviewRefusedReason,
            audit1RefusedReasonType:self.state.data.audit2RefusedReasonType != '' ? self.state.data.audit2RefusedReasonType : self.state.data.reviewRefusedReasonType

          })
        })
      }
    }, 0)
  }

  showModal = () => {
    this.setState({
      visible: TrademarkOutlined
    });
  };

  showModalt = () => {
    console.log(this.state.data.taskReviewStatus)
    this.setState({
      visible: true,
      visiblet: true,
    })
  }

  handleOk = e => {
    if (this.state.data.taskAudit1Status.display != '已审核') {
      if (this.state.visiblet) {
        axios.post('/api/core/jhTaskAudit/audit1Refused', {
          audit1RefusedReasonType: this.state.selectvalue,
          audit1RefusedReason: this.state.labelValues[this.state.selectvalue],
          audit1RefusedRemark: this.state.value,
          isSelfCheck:1
        }, {
          params: {
            taskId: this.state.data.id,
          }
        })
          .then(res => {
          })
      } else {
        axios.get('/api/core/task/genJhCheckScore/'+this.state.id)
        .then(res=>{
          axios.post('/api/core/jhTaskAudit/audit1Passed', {
            audit1PassedReason: this.state.value
          }, {
            params: {
              taskId: this.state.id,
            }
          })
            .then(res => {
              message.success()
            })
        })
      }
    } else if (this.state.data.taskAudit2Status.display == '待审核' || this.state.data.taskAudit2Status.display == '复核取消退回' || this.state.data.taskAudit2Status.display == '复核已退回'  ) {
      if (this.state.visiblet) {
        axios.post('/api/core/jhTaskAudit/audit2Refused', {
          audit2RefusedReasonType: this.state.selectvalue,
          audit2RefusedReason: this.state.labelValues[this.state.selectvalue],
          audit2RefusedRemark: this.state.value,
          isSelfCheck:1
        }, {
          params: {
            taskId: this.state.data.id,
          }
        })
          .then(res => {

          })
      } else {
        axios.post('/api/core/jhTaskAudit/audit2Passed', {
          audit2PassedReason: this.state.value
        }, {
          params: {
            taskId: this.state.id
          }
        })
          .then(res => {
          })
      }
    } else if (this.state.data.taskReviewStatus.display != '已审核' && this.state.data.taskReviewStatus.display != '待DSR审核') {
      if (this.state.visiblet) {
        axios.post('/api/core/jhTaskAudit/reviewRefused', {
          reviewRefusedReasonType: this.state.selectvalue,
          reviewRefusedReason: this.state.labelValues[this.state.selectvalue],
          reviewRefusedRemark: this.state.value,
          reviewRefusedType:this.state.reviewRefusedType,
          isSelfCheck:1
        }, {
          params: {
            taskId: this.state.data.id,
          }
        })
          .then(res => {

          })
      } else {
        axios.post('/api/core/jhTaskAudit/dsrPassed', {
          dsrPassedReason: this.state.value
        }, {
          params: {
            taskId: this.state.id,
          }
        })
          .then(res => {
          })
      }
    } else {
      if (this.state.visiblet) {
        axios.post('/api/core/jhTaskAudit/reviewRefused', {
          reviewRefusedReasonType: this.state.selectvalue,
          reviewRefusedReason: this.state.labelValues[this.state.selectvalue],
          reviewRefusedRemark: this.state.value,
          reviewRefusedType:this.state.reviewRefusedType,
          isSelfCheck:1
        }, {
          params: {
            taskId: this.state.data.id,
          }
        })
          .then(res => {

          })
      } else {
        axios.post('/api/core/jhTaskAudit/dsrPassed', {
          dsrPassedReason: this.state.value
        }, {
          params: {
            taskId:  this.state.id
          }
        }).then(res => {
        })
      }
    }
    window.location.href='javascript:history.back(-1)'
    this.setState({
      visible: false,
      visiblet: false,
      selectvalue: '1'
    });
    this.state.getdata()
  };

  handleCancel = e => {
    this.setState({
      visible: false,
      visiblet: false,
      isModalVisible:'',
      value: '',
      selectvalue: '1',
      returnbool:false
    });
  };

  inval = e => {
    if (e.target.value.length >= 500) {
      this.setState({
        toolbool: true
      })
    } else {
      this.setState({
        toolbool: false
      })
    }
    this.setState({
      value: e.target.value
    })
  }

  handleChange = (value) => {
    this.setState({
      selectvalue: value
    })
  }

  exporte = () => {
    axios.get('/api/core/task/detail/export', {
      params: {
        taskId: this.state.id
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出门店" + this.state.data.surveyCode + ".xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  exporteimg = () => {
    axios.get('/api/core/issue/export', {
      params: {
        taskId: this.state.id
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出图片" + this.state.data.surveyCode + ".zip");
      document.body.appendChild(link);
      link.click();
    })
  }

affirmandcancel = () => {
  this.setState({
    isModalVisible:'',
    returnbool:false
  })
  if(this.state.string=='affirm')this.affirm(this.state.taskid)
  if(this.state.string=='cancel')this.cancel(this.state.taskid)
  if(this.state.string=='dailiaffirm')this.dailiaffirm(this.state.taskid)
  if(this.state.string=='dailicancel')this.dailicancel(this.state.taskid)
};

//确认退回
 affirm = (taskId) => {
  axios.post('/api/core/jhTaskAudit/confirmReturn',{
      reviewRefusedType:'1',
      confirmRefusedRemark:this.state.audit2RefusedRemark
  },{
      params: {
          taskId
      }
  })
  .then(res => {
      message.success('确认退回成功')
      window.location.href='javascript:history.back(-1)'
  })
}

//取消退回
 cancel = (taskId) => {
  axios.post('/api/core/jhTaskAudit/cancelReturn',{
      cancelRefusedRemark:this.state.audit2RefusedRemark
  }, {
      params: {
          taskId
      }
  })
  .then(res => {
      message.success('取消退回成功')
      window.location.href='javascript:history.back(-1)'
  })
}

//代理确认退回
dailiaffirm = (taskId) => {
  axios.post('/api/core/jhTaskAudit/audit1Refused', {
    audit1RefusedRemark:this.state.audit1RefusedRemark,
    audit1RefusedReason:this.state.returnbool?'重做':this.state.audit1RefusedReason,
    audit1RefusedReasonType:this.state.returnbool?9:this.state.audit1RefusedReasonType
  }, {
      params: {
          taskId
      }
  })
  .then(res => {
      message.success('确认退回成功')
      window.location.href='javascript:history.back(-1)'
  })
}

//代理取消退回
dailicancel = (taskId) => {
  axios.post('/api/core/jhTaskAudit/audit1Passed', {
    audit1AppealRemark: this.state.audit1RefusedRemark
  }, {
      params: {
          taskId
      }
  })
    .then(res => {
        message.success('申诉提交成功')
        window.location.href='javascript:history.back(-1)'
    })
}

showModala = (string) => {
  if(string=='affirm' || string=='dailiaffirm'){
    this.setState({
      title:'确认退回'
    })
  }else if(string=='cancel'){
    this.setState({
      title:'取消退回'
    })
  }else if(string=='dailicancel'){
    this.setState({
      title:'申诉提交'
    })
  }
  this.setState({
    isModalVisible:string,
    audit2RefusedRemark:this.state.data.audit2RefusedRemark,
    string,
    taskid:this.state.data.id
  })
};

componentWillUnmount(){
  sessionStorage.removeItem('display')
  sessionStorage.removeItem('back')
  sessionStorage.removeItem('Completed')
  sessionStorage.removeItem('diaffirm')
  axios.get('/api/core/task/genJhCheckScore/' + this.state.id)
}



  render() {
    return (
      <div style={{ padding: '20px', paddingLeft: '60px', background: 'white' }}>
      <Modal title={this.state.title} visible={this.state.isModalVisible == 'dailiaffirm' || this.state.isModalVisible == 'dailicancel'} onOk={this.affirmandcancel} onCancel={this.handleCancel} destroyOnClose>
        <Row>
            <Col span='6' style={{display:'flex',alignItems:'center',justifyContent:'center'}}>{this.state.title}备注</Col>
            <Col span="18"><Input value={this.state.audit1RefusedRemark} onChange={e=>{this.setState({audit1RefusedRemark:e.target.value})}} ></Input></Col>
            {
              this.state.isModalVisible == 'dailiaffirm'?<>
              <Col span='6' style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'10px'}}>是否重做</Col>
              <Col span='18' style={{display:'flex',alignItems:'center',marginTop:'10px'}}>
                  <Checkbox checked={this.state.returnbool} onChange={e=>{this.setState({returnbool:e.target.checked})}}></Checkbox>
              </Col>
            </>:null
            }
        </Row>
      </Modal>
        <Modal title={this.state.title} visible={this.state.isModalVisible == 'affirm' || this.state.isModalVisible == 'cancel'} onOk={this.affirmandcancel} onCancel={this.handleCancel} destroyOnClose>
          <Row>
              <Col span='6' style={{display:'flex',alignItems:'center',justifyContent:'center'}}>{this.state.title}备注</Col>
              <Col span="18"><Input value={this.state.audit2RefusedRemark} onChange={e=>{this.setState({audit2RefusedRemark:e.target.value})}} ></Input></Col>
          </Row>
        </Modal>
        <Modal
          title={this.state.visiblet ? '退回' : '通过' + "门店"}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelText='取消'
          destroyOnClose={true}
          maskClosable={false}
        >
            <Row>
              {
                this.state.visiblet ? <><Col span='5' style={{ display: 'flex', alignItems: 'center' }}>{this.state.visiblet ? '退回' : '通过'}理由:</Col>
                  <Col span='16'>
                    <Select defaultValue="1" listHeight={250} style={{ width: '100%' }} onChange={this.handleChange}>
                      {
                        Object.keys(this.state.labelValues).map((v) => {
                          return <Option value={v}>{this.state.labelValues[v]}</Option>
                        })
                      }
                    </Select>
                  </Col>
                </> : null
              }
              {
                !this.state.visiblet && this.state.projectName?.includes('陈列')? <>
                  <Col span={5} style={{margin:'10px 0'}}>陈列评分:</Col>
                  <Col span={16} style={{margin:'10px 0'}}>
                    {
                      <Select style={{ width: 120 }}
                        value={this.state.data.auditResult}
                        onChange={
                          (e) => {
                            console.log(this.state.data)
                            console.log(this.state.id)
                            axios.put('/api/core/task/auditResult',{},{
                              params:{
                                taskId:this.state.id,
                                auditResult: e
                              }
                            })
                          }
                        }
                      >
                        <Option value={1}>合格</Option>   
                        <Option value={2}>不合格</Option>  
                      </Select>
                    }</Col>
                </>//
                  : null
              }
              {
                !this.state.visiblet ? <>
                  <Col span={5}>门店状态:</Col>
                  <Col span={16}>
                    {
                      <Select style={{ width: 120 }}
                        value={JSON.stringify(this.state.mendianstatus) != '{}' ? this.state.mendianstatus.shopStatus.display : 1}
                        onChange={
                          (e) => {
                            console.log(e,this.state.mendianstatus)
                            this.setState({
                              mendianstatus: {
                                ...this.state.mendianstatus,
                                shopStatus: {
                                  value:e,
                                  display:this.state.mendianstatuslist[e]
                                }
                              }
                            })
                            axios.put('/api/core/shop',{
                              ...this.state.mendianstatus,
                              shopStatus: e
                            })
                          }
                        }
                      >
                        {
                          Object.keys(this.state.mendianstatuslist).map((v) => {
                            return <Option value={v}>{this.state.mendianstatuslist[v]}</Option>
                          })
                        }
                      </Select>
                    }</Col>
                </>//
                  : null
              }
              {
                this.state.visiblet && this.state.data.taskReviewStatus.display=='待审核'? <>
                  <Col span={5} style={{ width: '100%' , margin:'10px 0'}}>退回阶段:</Col>
                  <Col span={16} style={{ width: '100%' , margin:'10px 0'}}>
                    <Select style={{ width: '100%' }} value={this.state.reviewRefusedType} onChange={e => this.setState({reviewRefusedType:e})} >
                      <Select.Option key={1} value={1} >一审</Select.Option>
                      <Select.Option key={2} value={2} >二审</Select.Option>
                    </Select>
                  </Col>
                </>//
                  : null
                }
              <Col span='5' style={{ display: 'flex', alignItems: 'center'}}>{this.state.visiblet ? '退回' : '通过'}备注:</Col>
              <Col span='16' style={{margin:'10px 0'}} ><TextArea rows={4} onChange={this.inval} placeholder={"请输入备注"} /></Col>
            </Row>
        </Modal>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
          <div><RobotOutlined style={{ position: 'absolute', left: '-30px', fontSize: '30px' }} /><span style={{ fontSize: '20px' }}>{this.state.data.code}</span></div>
          <div>
            <Button style={{ margin: '0 10px' }} onClick={this.exporteimg}>图片导出</Button>
            <Button onClick={this.exporte}>门店导出</Button>
            {
              sessionStorage.getItem('diaffirm') != 'true' &&
              sessionStorage.getItem('Completed') != 'true' ?
                sessionStorage.getItem('display') == 'true'?<>
                  <Button style={{ margin: '0 10px' }} onClick={e => this.showModala('affirm')}>确认退回</Button>
                  <Button onClick={e => this.showModala('cancel')}>取消退回</Button>
                </>:
                sessionStorage.getItem('back') != 'true' ?<>
                {//
                }
                  <Button onClick={this.showModalt} disabled={
                    this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object && 
                      (this.state.data.taskAudit1Status?.display == '已退回' 
                        || this.state.data.taskAudit1Status?.display == '已审核' && this.state.data.assignStatus?.display == '待安排'
                        || this.state.data.taskAudit2Status?.display == '已退回' && this.state.data.taskAudit2Status?.display == '复核已退回' 
                        || this.state.data.taskAudit2Status?.display == '已退回' )
                        ? true : false } 
                        style={{ margin: '0 10px' }}>退回</Button>
                    {//
                    }
                  <Button type="primary" 
                    disabled={
                      this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object && 
                      (this.state.data.taskAudit1Status?.display == '已退回' 
                        || this.state.data.taskAudit1Status?.display == '已审核' && this.state.data.assignStatus?.display == '待安排'
                        || this.state.data.taskAudit2Status?.display == '已退回' && this.state.data.taskAudit2Status?.display != '复核已退回'
                        || this.state.data.taskAudit2Status?.display == '已退回' )
                        ? true : false } onClick={this.showModal}>通过</Button>
                </>:null
              :null
            }
            {
              sessionStorage.getItem('diaffirm') == 'true'?<>
                <Button style={{ margin: '0 10px' }} onClick={e => this.showModala('dailiaffirm')}>确认退回</Button>
                <Button onClick={e => this.showModala('dailicancel')}>申诉提交</Button>
              </>:null
            }
          </div>
        </div>
        <Row>
          <Col span={5}><b style={{ fontWeight: '550' }}>项目区域</b>: <span> {this.state.data.surveyScope}</span></Col>
          <Col span={10}><b style={{ fontWeight: '550' }}>调研编号</b>: <span> {this.state.data.surveyCode}</span></Col>
          {
            //
            store.getState().user.role != '代理人' && store.getState().user.role != '二审' && !this.state.isbool?
              <Col span={9} style={{ textAlign: 'right', color: '#aaa', height: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                  <span><b style={{ fontWeight: '550' }}>一审状态</b></span><br />
                  <span style={{ float: 'riht', color: '#000' }}> {this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object ? this.state.data.taskAudit1Status.display : null}</span>
                </div>
                <div style={{ margin: '0 10px' }}>
                  <span><b style={{ fontWeight: '550' }}>二审状态</b></span><br />
                  <span style={{ float: 'riht', color: '#000' }}> {this.state.data.length != 0 && this.state.data.taskAudit2Status instanceof Object ? this.state.data.taskAudit2Status.display : null}</span>
                </div>
                <div>
                  <span><b style={{ fontWeight: '550' }}>复核状态</b></span><br />
                  <span style={{ float: 'riht', color: '#000' }}> {this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object ? this.state.data.taskReviewStatus.display : null}</span>
                </div>
              </Col>
            :null
          }
          <Col span={5}><b style={{ fontWeight: '550' }}>调研对象</b>:<span> {this.state.data.surveyTarget}</span></Col>
          {
            store.getState().user.role != '代理人' && store.getState().user.role != '二审' && !this.state.isbool?<>
              <Col span={5}><b style={{ fontWeight: '550' }}>原始得分</b>: <span> { (this.state.data.originalScore*100)?.toFixed(2) + '%'}</span></Col>
              <Col span={5}><b style={{ fontWeight: '550' }}>标准得分</b>: <span> { (this.state.data.standardScore*100)?.toFixed(2) + '%'}</span></Col>
              <Col span={9}><b style={{ fontWeight: '550' }}>考核得分</b>: <span> { (this.state.data.checkScore*100)?.toFixed(2) + '%'}</span></Col>
            </>:null
          }
        </Row>
      </div>
    )
  }
}

export default withRouter(App)