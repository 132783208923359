import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload, Menu, Tree, TreeSelect } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { listToArray_people } from '@/utils/listToArray'
import PopConfirmOnly from '@/utils/PopConfirmOnly'
import store from '@/redux/store'

function App(props) {

  const { id } = useRouteMatch().params
  const projectType = Number(id.split('-')[0])
  const groupId = id.split('-')[1] ? Number(id.split('-')[1]) : null

  const codeMap = {
    0: 'xuzhong',
    3: 'rygl',
    2: 'shixiao',
    1: 'jiahua'
  }

  const [expression,setExpression] = useState(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)
  const [cellphone,setCellphone] = useState(false)

  const treeList = arr => {
    let count = 0
    const listFor = arr => {
      count++
      return (
        arr ? arr.map((i, v) =>
          <TreeSelect.TreeNode disabled={count === 0} value={i.id} title={i.name}>
            {
              i.children ? listFor(i.children) : null
            }
          </TreeSelect.TreeNode>
        )
          : null
      )
    }
    const target = listFor(arr)
    return target
  }

  const handleShowEdit = e => {
    axios.get('/api/sys/user/group/org',{
      params:{
        id:e.id
      }
    })
    .then(res=>{
      const arr = res.data.filter(i => i.groupId === groupId)
      setJobEdit(groupId ? arr[arr.length-1].orgId : res.data.filter(i => i.groupId === 0)[0].orgId)
      setProxyEdit(groupId ? arr[arr.length-1].proxyId : res.data.filter(i => i.groupId === 0)[0].proxyId)
      setRolesEdit(groupId ?
        res.data.filter(i => i.groupId === groupId)[0].roles.map(i => i.id) :
        res.data.filter(i => i.groupId === 0)[0].roles.map(i => i.id)
      )
      setAllOrgs(res.data)
    })
    setModalVisible('edit')
    setEditId(e.id)
    setNameEdit(e.name)
    setCodeEdit(e.code)
    setMobileEdit(e.mobile)
    setPasswordEdit(e.password)
    setStatusEdit(e.status)
    setTimeStartEdit(e.beginTime ? moment(e.beginTime, 'YYYY-MM-DD') : null)
    setTimeEndEdit(e.endTime ? moment(e.endTime, 'YYYY-MM-DD') : null)
    setSexEdit(e.sex)
    // setProxyEdit(e.proxyId)
  }
  // menu
  const [menuData, setMenuData] = useState([])
  const [menuClick, setMenuClick] = useState(null)
  const [vistorArray, setVistorArray] = useState([])
  const getDataMenu = e => {
    axios.get('/api/common/org', { params: { code: codeMap[projectType] } }).then(res => {
      console.log(listToArray_people(res.data).vistor)
      const target = listToArray_people(res.data).tree
      const vistor = listToArray_people(res.data).vistor
      setMenuData([target])
      setMenuClick(target.id)
      setVistorArray(vistor)
    })
  }

  useEffect(() => {
    getDataMenu()
  }, [])

  // menu 操作
  const handleShowMenuModal = (type, data) => {
    setModalVisible(type)
    if (type === 'addMenu') setMenuAddData({
      parentId: data.id,
      parentName: data.name,
      name: '',
      code: ''

    })
    if (type === 'editMenu') setMenuEditData({
      parentId: data.parentId,
      id: data.id,
      name: data.name,
      code: data.code
    })
  }
  const [menuEditData, setMenuEditData] = useState({
    parentId: null,
    id: null,
    name: '',
    code: ''
  })
  const [menuAddData, setMenuAddData] = useState({
    parentId: null,
    parentName: null,
    name: '',
    code: ''
  })
  const handleMenuDelete = e => {
    axios.delete('/api/common/org', { params: { id: e.id } }).then(res => {
      message.success()
      getDataMenu()
    })
  }
  const handleMenuEdit = e => {
    axios.put('/api/common/org', {
      parentId: menuEditData.parentId,
      name: menuEditData.name,
      code: menuEditData.code
    }, {
      params: {
        id: menuEditData.id
      }
    }).then(res => {
      message.success()
      getDataMenu()
      handleModalCancel()
    })
  }
  const handleMenuAdd = e => {
    if (menuAddData.parentId != '' && menuAddData.name != '' && menuAddData.code != '') {
      axios.post('/api/common/org', {
        parentId: menuAddData.parentId,
        name: menuAddData.name,
        code: menuAddData.code,
        leaf: 1
      }).then(res => {
        message.success()
        getDataMenu()
        handleModalCancel()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }

  }

  // table
  const columns = [
    {
      title: '员工ID',
      dataIndex: 'id',
      render: text => text ? text : '-'
    },
    {
      title: '账号',
      dataIndex: 'code',
      render: text => text ? text : '-'
    },
    {
      title: '员工姓名',
      dataIndex: 'name',
      render: text => text ? text : '-'
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      render: text => text ? text : '-'
    },
    {
      title: '员工状态',
      dataIndex: 'status',
      render: text => text === 0 ? '在职' : text === 1 ? '不在职' : '兼职'
    },
    // {
    //   title: '权限类型',
    //   dataIndex: 'orgs',
    //   render: text => {
    //     return text.filter(i => i.groupId === groupId)[0]?.roles.map(i => i.name).join(',')
    //   }
    // },
    // {
    //   title: '上级代理',
    //   dataIndex: 'proxyName',
    //   render: text => text ? text : '-'
    // },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => handleShowEdit(record)}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ]
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    axios.get('/api/sys/user/list', {
      params: {
				pageSize: 1000000,
        orgId: menuClick,
        groupId: groupId ? groupId : 0,
        orgCode: menuClick ? null : codeMap[projectType],
        key: nameSelect,
        status: statusSelect,
      }
    }).then(res => {
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  useEffect(() => {
    if (menuClick)
      getData()
    if (!menuClick)
      setDataSource([])

  }, [menuClick, updateFlag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  //opiton
  const [allRoleOption, setAllRoleOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])
  useEffect(() => {
    let type = null
    if (projectType === 2) type = 'shixiao_proxy'
    if (projectType === 3) type = 'jiahua_proxy'
    axios.get('/api/sys/user/list',
      {
        params: {
          roleCode: type,
          pageSize: 1000000,
          groupId: groupId ? groupId : 0,
          orgId: menuClick,
          orgCode: codeMap[projectType],
          status: 0
        }
      }).then(res => {
        setProxyOption(res.data.data)
      })
    axios.get('/api/sys/role/list', { params: { pageSize: 1000000 } }).then(res => {
      setAllRoleOption(res.data.data)
    })
  }, [])

  //select
  const [statusSelect, setStatusSelect] = useState(null)
  const [nameSelect, setNameSelect] = useState('')
  const clearSelect = e => {
    setMenuClick(null)
    setStatusSelect(null)
    setNameSelect('')
    setUpdateFlag(pre => pre + 1)
  }

  //导出
  const handleExport = e => {
    axios.get('/api/sys/user/list', {
      params: {
				pageSize: 1000000,
        orgId: menuClick,
        groupId: groupId ? groupId : 0,
        orgCode: codeMap[projectType],
        key: nameSelect,
        status: statusSelect,
        export: true
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出人员.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }
  //批量重置密码
  const handleResetPassword = e => {
    if (selectedRowKeys.length === 0) return
    axios.put('/api/sys/user/password/batch/reset', selectedRowKeys).then(res => {
      message.success()
      handleClearSelect()
      getData()
    })
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const [modalTitleMap, setModalTitleMap] = useState({
    add: '添加人员',
    edit: '修改人员',
    addMenu: '添加机构树',
    editMenu: '修改机构树'
  })
  const handleModalCancel = e => {
    setModalVisible(null)
    setEditId(null)
    setNameEdit('')
    setCodeEdit('')
    setMobileEdit('')
    setJobEdit(null)
    setPasswordEdit('')
    setStatusEdit(null)
    setTimeStartEdit(null)
    setTimeEndEdit(null)
    setRolesEdit([])
    setSexEdit(null)
    setProxyEdit(null)
    setAllOrgs([])
    setMenuAddData({
      parentId: null,
      parentName: null,
      name: '',
      code: ''
    })
    setMenuEditData({
      id: null,
      name: '',
      code: ''
    })
  }
  
  const handleModalEnsure = e => {
    if (modalVisible === 'add') handleAdd()
    if (modalVisible === 'edit') handleEdit()
    if (modalVisible === 'addMenu') handleMenuAdd()
    if (modalVisible === 'editMenu') handleMenuEdit()
  }

  //添加&&修改
  const [editId, setEditId] = useState(null)
  const [nameEdit, setNameEdit] = useState('')
  const [codeEdit, setCodeEdit] = useState('')
  const [mobileEdit, setMobileEdit] = useState('')
  const [jobEdit, setJobEdit] = useState(null)
  const [passwordEdit, setPasswordEdit] = useState('')
  const [statusEdit, setStatusEdit] = useState(null)
  const [timeStartEdit, setTimeStartEdit] = useState(null)
  const [timeEndEdit, setTimeEndEdit] = useState(null)
  const [rolesEdit, setRolesEdit] = useState([])
  const [sexEdit, setSexEdit] = useState(null)
  const [proxyEdit, setProxyEdit] = useState(null)
  const [allOrgs, setAllOrgs] = useState([])


  const handleAdd = e => {
    if (!nameEdit || !codeEdit || !mobileEdit || !(sexEdit == -1 || sexEdit == 0 || sexEdit == 1) || !timeStartEdit || !passwordEdit || !(statusEdit == 0 || statusEdit == 1 || statusEdit == 2) || rolesEdit.length === 0) {
      message.error('请完整填写信息,再次提交')
      return
    }

    if(!cellphone){
      message.error('请检查手机号是否正确')
      return
    }

    const body = {
      name: nameEdit,
      code: codeEdit,
      mobile: mobileEdit,
      sex: sexEdit,
      beginTime: timeStartEdit ? moment(timeStartEdit).format('YYYY-MM-DD 00:00:00.000') : null,
      endTime: timeEndEdit ? moment(timeEndEdit).format('YYYY-MM-DD 00:00:00.000') : null,
      password: passwordEdit,
      status: statusEdit,
      proxyId : proxyEdit==null?store.getState().user.uid:proxyEdit,
      proxyName : proxyEdit==null?store.getState().user.name:dataSource.filter(v=>{
        return v.id == proxyEdit
      })[0]?.name,
      orgs: [{
        orgId: jobEdit,
        groupId: groupId,
        roles: rolesEdit.map(i => { return { id: i } })
      }]
    }
    const params = {
      orgId: jobEdit,
      groupId: groupId ? groupId : 0
    }
    axios.post('/api/sys/user', body, { params }).then(res => {
      axios.put('/api/sys/user', res.data).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    })
  }
  const handleEdit = e => {
    if (!nameEdit || !codeEdit || !mobileEdit || !(sexEdit == -1 || sexEdit == 0 || sexEdit == 1) || !timeStartEdit || !passwordEdit || !(statusEdit == 0 || statusEdit == 1 || statusEdit == 2) || rolesEdit.length === 0) {
      message.error('请完整填写信息,再次提交')
      return
    }

    const orgs = allOrgs.map(i => {
      if (i.groupId === groupId || i.groupId === 0) {
        return Object.assign({}, i, { orgId: jobEdit, roles: rolesEdit.map(i => { return { id: i } }) })
      } else {
        return i
      }
    })
    const body = {
      id: editId,
      name: nameEdit,
      code: codeEdit,
      mobile: mobileEdit,
      sex: sexEdit,
      beginTime: timeStartEdit ? moment(timeStartEdit).format('YYYY-MM-DD 00:00:00.000') : null,
      endTime: timeEndEdit ? moment(timeEndEdit).format('YYYY-MM-DD 00:00:00.000') : null,
      password: passwordEdit,
      status: statusEdit,
      orgs: orgs,
      proxyId:proxyEdit,
      proxyName:dataSource.filter(v=>{
        return v.id == proxyEdit
      })[0]?.name
    }
    // if (vistorArray.includes(jobEdit)) body.proxyId = proxyEdit
    // if (vistorArray.includes(jobEdit)) body.proxyName = dataSource.filter(v=>{
    //   return v.id == proxyEdit
    // })[0]?.name
    const params = {}
    if (groupId) params.groupId = groupId
    axios.put('/api/sys/user', body).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }

  //删除
  const handleDelete = e => {
    axios.get('/api/sys/user/group/org',{
      params:{
        id:e
      }
    })
    .then(res=>{
      console.log(res)
      let userarr = []
      groupId ?
        userarr=res.data.filter(i => i.groupId === groupId)[0] :
        userarr=res.data.filter(i => i.groupId === 0)[0]
      console.log(userarr)
      axios.delete('/api/sys/user/user/group/org/' + userarr.id).then(res => {
        message.success()
        getData()
      })
    })
    
  }
  const handleDeleteMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.delete('/api/sys/user/batch', { data: selectedRowKeys }).then(res => {
      message.success()
      handleClearSelect()
      getData()
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>员工状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} placeholder='员工状态' value={statusSelect} onChange={e => setStatusSelect(e)}>
                <Select.Option value={''}>取消选择</Select.Option>
                <Select.Option value='0'>在职</Select.Option>
                <Select.Option value='2'>兼职</Select.Option>
                <Select.Option value='1'>不在职</Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>员工姓名</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请输入员工姓名' value={nameSelect} onChange={e => setNameSelect(e.target.value)}></Input>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('add')}><PlusOutlined />添加员工</Button>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={handleResetPassword}>批量重置密码</Button>
            <PopConfirmOnly confirm={handleDeleteMore}>
              <Button style={{ margin: '0 10px' }} >批量删除</Button>
            </PopConfirmOnly>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>
        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Row>
          <Col span={4} className='people_tree'>
            {
              menuData.length !== 0 ?
                <Tree
                  onSelect={e => setMenuClick(e[0] ? e[0] : null)}
                  treeData={menuData}
                  defaultExpandedKeys={[menuData[0]?.key]}
                  selectedKeys={[menuClick]}
                  titleRender={node =>
                    <>
                      {
                        node.parentId !== 1 && node.parentId ?
                          <PopConfirmOnly children={<DeleteOutlined style={{ margin: '0 2.5px', color: 'red' }} />} confirm={e => { handleMenuDelete(node); e.stopPropagation() }}>
                          </PopConfirmOnly>
                          : null
                      }
                      <span style={{ margin: '0 2.5px' }} >{node.name}</span>
                      <PlusOutlined style={{ margin: '0 2.5px' }} onClick={e => { handleShowMenuModal('addMenu', node); e.stopPropagation() }} />
                      <EditOutlined style={{ margin: '0 2.5px' }} onClick={e => { handleShowMenuModal('editMenu', node); e.stopPropagation() }} />
                    </>
                  }
                />
                : null
            }
          </Col>
          <Col span={20}>
            <Table
              rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
              dataSource={dataSource}
              columns={columns}
              pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger:true }}
            />
          </Col>
        </Row>

        <Modal
          width={640}
          title={modalTitleMap[modalVisible]}
          visible={modalVisible === 'addMenu'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key='back' onClick={handleModalCancel}>
              取消
          </Button>,
            <Button key='submit' type='primary' onClick={handleModalEnsure}>
              确认
          </Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>父类机构:</Col>
            <Col span={14}>
              <Input disabled value={menuAddData.parentName} />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>机构名称:</Col>
            <Col span={14}>
              <Input value={menuAddData.name} onChange={e => setMenuAddData(Object.assign({}, menuAddData, { name: e.target.value }))} />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>机构编码:</Col>
            <Col span={14}>
              <Input value={menuAddData.code} onChange={e => setMenuAddData(Object.assign({}, menuAddData, { code: e.target.value }))} />
            </Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title={modalTitleMap[modalVisible]}
          visible={modalVisible === 'editMenu'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key='back' onClick={handleModalCancel}>
              取消
          </Button>,
            <Button key='submit' type='primary' onClick={handleModalEnsure}>
              确认
          </Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}>机构名称:</Col>
            <Col span={14}>
              <Input value={menuEditData.name} onChange={e => setMenuEditData(Object.assign({}, menuEditData, { name: e.target.value }))} />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>机构编码:</Col>
            <Col span={14}>
              <Input value={menuEditData.code} onChange={e => setMenuEditData(Object.assign({}, menuEditData, { code: e.target.value }))} />
            </Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title={modalTitleMap[modalVisible]}
          visible={modalVisible === 'add' || modalVisible === 'edit'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key='back' onClick={handleModalCancel}>
              取消
          </Button>,
            <Button key='submit' type='primary' onClick={handleModalEnsure}>
              确认
          </Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>员工区域:</Col>
            <Col span={14}>
              <TreeSelect
                style={{ width: '100%' }}
                value={jobEdit}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeDefaultExpandAll
                onChange={e => setJobEdit(e)}
                disabled={modalVisible === 'edit'}
              >
                {treeList(menuData)}
              </TreeSelect>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>上级代理:</Col>
            <Col span={14}>
              <Select style={{ width: '100%' }} value={proxyEdit} onChange={e => setProxyEdit(e)}>
                <Select.Option value={''} >取消选择</Select.Option>
                {
                  proxyOption.map((i, index) => (
                    <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                  ))
                }
              </Select>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>权限类型:</Col>
            <Col span={14}>
              <Select style={{ width: '100%' }} mode='multiple' value={rolesEdit} onChange={e => setRolesEdit(e)}>
                {
                  allRoleOption.map((i, index) => (
                    <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                  ))
                }
              </Select>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>员工状态:</Col>
            <Col span={14}>
              <Select style={{ width: '100%' }} value={statusEdit} onChange={e => setStatusEdit(e)}>
                <Select.Option value={0}>在职</Select.Option>
                <Select.Option value={2}>兼职</Select.Option>
                <Select.Option value={1}>不在职</Select.Option>
              </Select>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>在职时间:</Col>
            <Col span={14}>
              <DatePicker
                style={{ width: '45%', marginRight: '10%' }}
                value={timeStartEdit}
                onChange={e => setTimeStartEdit(e)}
                placeholder='开始时间'
              ></DatePicker>
              <DatePicker
                style={{ width: '45%' }}
                value={timeEndEdit}
                onChange={e => setTimeEndEdit(e)}
                placeholder='结束时间'
              ></DatePicker>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>姓名:</Col>
            <Col span={14}><Input value={nameEdit} onChange={e => setNameEdit(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>性别:</Col>
            <Col span={14}>
              <Select style={{ width: '100%' }} value={sexEdit} onChange={e => setSexEdit(e)}>
                <Select.Option value={-1}>未知</Select.Option>
                <Select.Option value={0}>男</Select.Option>
                <Select.Option value={1}>女</Select.Option>
              </Select>
            </Col>
            {
              modalVisible === 'add' ? <>
                <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>手机号:</Col>
                <Col span={14}><Input value={mobileEdit} onChange={e => {setMobileEdit(e.target.value);expression.test(e.target.value)?setCellphone(true):setCellphone(false)}}></Input></Col>
              </>
                : null
            }
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>账户:</Col>
            <Col span={14}><Input value={codeEdit} onChange={e => setCodeEdit(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>密码:</Col>
            <Col span={14}><Input value={passwordEdit} onChange={e => setPasswordEdit(e.target.value)}></Input></Col>
          </Row>
        </Modal>

      </div>
    </div >
  )
}

export default App