import React, { useEffect, useState, useRef } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload, Popconfirm } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { baseUrl } from '@/utils/config'
import { listToArray } from '@/utils/listToArray'
import file from '@/assets/sxzbdr.xlsx'
import PopConfirmOnly from "@/utils/PopConfirmOnly";


function App(props) {
  const cancel = (e) => {
    message.error('Click on No');
  }
  const projectid = useRouteMatch().params.projectid
  const [id, setid] = useState('')

  const handleShowChildAdd = e => {
    setModalVisible('addChild')
    setAddChildData({
      pname: e.description,
      pid: e.id,
      treeLevel: e.treeLevel + 1,
      name: '',
      code: '',
      sort: ''
    })
  }
  const handleShowEdit = e => {
    setModalVisible('edit')
    // setEditData({
    //   id: e.id,
    //   name: e.description,
    //   code: e.metricCode,
    //   sort: e.weight
    // })
    setEditData(e)
  }

  const sum = (e) => {//指标排序计算ASClL大小
    const arr = e.split('')
    let num = 0
    arr.forEach(v => {
      num += v.charCodeAt()
    });
    return num
  }

  // table
  const [columns, setColumns] = useState([
    {
      title: '名称',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '指标编号',
      dataIndex: 'metricCode',
      key: 'metricCode',
      sorter: (a, b) => (sum(a.metricCode)) - (sum(b.metricCode)),
    },
    {
      title: '分数',
      dataIndex: 'score',
      key: 'score'
    },
    {
      title: '权重',
      dataIndex: 'weight',
      key: 'weight'
    },
    {
      title: '最多出现几次',
      dataIndex: 'maxNum',
      key: 'maxNum'
    },
    {
      title: '出现几次全扣',
      dataIndex: 'allDeductNum',
      key: 'allDeductNum'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          {
            record.treeLevel < 4 ?
              <Button size='small' type='link' onClick={e => handleShowChildAdd(record)}>添加子类</Button>
              : null
          }
          <Divider type='vertical' />
          <Button size='small' type='link' onClick={e => handleShowEdit(record)}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button type='link' size='small'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])

  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition = []
    if (selectText) queryCondition.push('LIKE:description=' + selectText)

    axios.get('/api/core/project/' + projectid)
      .then(res => {
        setid(res.data.metricGroupId)
        axios.get('/api/core/metric/list',
          {
            params: {
              pageSize: 1000000,
              metricGroupId: res.data.metricGroupId,
              queryCondition: queryCondition.join(',')
            }
          }).then(res => {
            setDataSource(selectText ==''? listToArray(res.data.data) : res.data.data)
          })
      })
  }
  useEffect(() => {
    setSelectText('')
    getData()
  }, [updateFlag])

  //select
  const [selectText, setSelectText] = useState('')
  const clearSelect = e => {
    setSelectText('')
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setFileListImport([])
    setNameAddParent('')
    setCodeAddParent('')
    setSortAddParent('')
    setScoreAddParent('')
    setAddChildData({
      pname: '',
      pid: '',
      name: '',
      code: '',
      sort: '',
      barCode: '',
      treeLevel: null,
      isPrice: false
    })
    setEditData({})
  }
  //导入
  const handleImport = e => {
    const formData = new FormData()
    formData.append('file', fileListImport[0])
    axios.post('/api/core/metric/import', formData, { params: { metricGroupId: id }, headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
      message.success()
      handleModalCancel()
      setFileListImport([])
      getData()
    })
  }
  const [fileListImport, setFileListImport] = useState([])
  const uploadProps = {
    onRemove: (file) => {
      setFileListImport([])
      return []
    },
    beforeUpload: (file) => {
      setFileListImport([file])
      return false
    }
  }
  //
  // 添加父类
  const handleAddParent = e => {
    if (nameAddParent != '' && codeAddParent != '' && sortAddParent != '' && scoreAddParent != '') {
      axios.post('/api/core/metric', {
        description: nameAddParent,
        metricCode: codeAddParent,
        weight: sortAddParent,
        parent: 0,
        treeLevel: 1,
        isLeaf: 0,
        score:scoreAddParent
      }, { params: { metricGroupId: id } }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const [nameAddParent, setNameAddParent] = useState('')
  const [codeAddParent, setCodeAddParent] = useState('')
  const [sortAddParent, setSortAddParent] = useState('')
  const [scoreAddParent, setScoreAddParent] = useState('')
  //添加子类
  const handleAddChild = e => {
    if (addChildData.name != ''  && addChildData.code != '' ) {
      axios.post('/api/core/metric', {
        parent: addChildData.pid,
        description: addChildData.name,
        metricCode: addChildData.code,
        weight: addChildData.sort,
        treeLevel: addChildData.treeLevel,
        score:addChildData.score,
        isLeaf: 1
      }, { params: { metricGroupId: id } }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const [addChildData, setAddChildData] = useState({
    pname: '',
    pid: '',
    name: '',
    code: '',
    sort: '',
    treeLevel: null
  })
  const handleChangeChildDate = (type, value) => {
    const target = Object.assign({}, addChildData, { [type]: value })
    setAddChildData(target)
  }
  // 修改
  const handleEdit = e => {
    if (editData.description != '' && editData.metricCode != '' && editData.weight != '') {
      const target = Object.assign({}, editData)
      delete target.key
      delete target.children

      axios.put('/api/core/metric', target, { params: { metricGroupId: id } }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const [editData, setEditData] = useState({
    id: '',
    name: '',
    code: '',
    sort: '',
  })
  const handleChangeEdit = (type, value) => {
    const target = Object.assign({}, editData, { [type]: value })
    setEditData(target)
  }

  // 删除
  const handleDelete = e => {
    axios.delete('/api/core/metric/' + e).then(res => {
      message.success()
      getData()
    })
  }


  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <span>指标类别</span>
            <Input style={{ width: '320px', margin: '0 10px' }} placeholder='请输入指标名称' value={selectText} onChange={e => setSelectText(e.target.value)}></Input>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => getData()}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('addParent')}><PlusOutlined />添加父类</Button>
            <Button style={{ margin: '0 10px' }} onClick={e => setModalVisible('import')}>导入指标</Button>
            <Button style={{ margin: '0 10px' }} onClick={e => window.open(baseUrl + '/api/core/metric/export?metricGroupId=' + id + '&appId=cms&appKey=cms@2020')}>导出列表</Button>
          </Col>
        </Row>

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger:true }}
        />


        <Modal
          width={640}
          title="添加父类"
          visible={modalVisible === 'addParent'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>
              取消
          </Button>,
            <Button key="submit" type="primary" onClick={handleAddParent}>
              确认
          </Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标类别:</Col>
            <Col span={14}><Input value={nameAddParent} onChange={e => setNameAddParent(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标编号:</Col>
            <Col span={14}><Input value={codeAddParent} onChange={e => setCodeAddParent(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标分数:</Col>
            <Col span={14}><Input value={scoreAddParent}  onChange={e => setScoreAddParent(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>权重:</Col>
            <Col span={14}><Input value={sortAddParent} onChange={e => setSortAddParent(e.target.value)}></Input></Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title="添加子类"
          visible={modalVisible === 'addChild'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>
              取消
          </Button>,
            <Button key="submit" type="primary" onClick={handleAddChild}>
              确认
          </Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>父类名称:</Col>
            <Col span={14}><Input disabled value={addChildData.pname}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标名称:</Col>
            <Col span={14}><Input value={addChildData.name} onChange={e => handleChangeChildDate('name', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标编号:</Col>
            <Col span={14}><Input value={addChildData.code} onChange={e => handleChangeChildDate('code', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>指标分数:</Col>
            <Col span={14}><Input value={addChildData.score} onChange={e => handleChangeChildDate('score', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>权重:</Col>
            <Col span={14}><Input value={addChildData.sort} onChange={e => handleChangeChildDate('sort', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>最多出现几次:</Col>
            <Col span={14}><Input value={addChildData.maxNum} onChange={e => handleChangeChildDate('maxNum', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>出现几次全扣:</Col>
            <Col span={14}><Input value={addChildData.allDeductNum} onChange={e => handleChangeChildDate('allDeductNum', e.target.value)}></Input></Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title="导入指标"
          visible={modalVisible === 'import'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleImport}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}>批量上传:</Col>
            <Col span={14}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>上传文件</Button>
              </Upload>
            </Col>
            <Col span={4}><Button onClick={e => window.open(file)}>下载模板</Button></Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title="修改"
          visible={modalVisible === 'edit'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleEdit}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标名称:</Col>
            <Col span={14}><Input value={editData.description} onChange={e => handleChangeEdit('description', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>指标编号:</Col>
            <Col span={14}><Input value={editData.metricCode} disabled onChange={e => handleChangeEdit('metricCode', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>指标分数:</Col>
            <Col span={14}><Input value={editData.score} onChange={e => handleChangeEdit('score', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>权重:</Col>
            <Col span={14}><Input value={editData.weight} onChange={e => handleChangeEdit('weight', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>最多出现几次:</Col>
            <Col span={14}><Input value={editData.maxNum} onChange={e => handleChangeChildDate('maxNum', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}>出现几次全扣:</Col>
            <Col span={14}><Input value={editData.allDeductNum} onChange={e => handleChangeChildDate('allDeductNum', e.target.value)}></Input></Col>
          </Row>
        </Modal>
      </div>
    </div >
  )
}

export default App