import React from 'react'
import { Popconfirm, message } from 'antd';

function PopConfirmOnly(props) {
  return <Popconfirm
    title={props.title?props.title:"您确定删除吗？"}
    onConfirm={props.confirm}
    onCancel={props.cancel}
    okText="Yes"
    cancelText="No"
  >
    {props.children}
  </Popconfirm> 
}

export default PopConfirmOnly