import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Radio, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'

const { Option } = Select;


function App(props) {

  const { push } = useHistory()
  const { projectid  } = useRouteMatch().params

  const handleShowBack = e => {
    setModalVisible('back')
    setTaskId(e.id)
  }
  const handleShowPass = e => {
    setModalVisible('pass')
    setTaskId(e.id)
  }
  const handleShowPlan = e => {
    setModalVisible('plan')
    setTaskId(e.id)
  }

	const JhCheckScord = (e) => {
		axios.get('/api/core/task/genJhCheckScore/'+e.id)
		.then(res=>{
			message.success()
		})
	}

  // table
  const columns = [{
    title: '调研编号',
    dataIndex: 'surveyCode',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
  },
  {
    title: '调研大区',
    dataIndex: 'surveyScope',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
  },
  {
    title: '访问员',
    dataIndex: 'principal',
    render: text => text ? text : '-',
    sorter: (a, b) => a.principal?.localeCompare(b.principal),
  },
  {
    title: '门店类型',
    dataIndex: 'shopLevel',
    render: text => text ? text : '-',
    sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
  },
  {
    title: '代理人',
    dataIndex: 'agent',
    render: text => text ? text : '-',
    sorter: (a, b) => a.agent?.localeCompare(b.agent),
  },
  {
    title: '二审人员',
    dataIndex: 'audit2ByName',
    render: text => text ? text : '-',
    sorter: (a, b) => a.audit2ByName?.localeCompare(b.audit2ByName),
  },
  {
    title: '复核人员',
    dataIndex: 'reviewByName',
    render: text => text ? text : '-',
    sorter: (a, b) => a.reviewByName?.localeCompare(b.reviewByName),
  },
  {
    title: '扫dsr审核人员',
    dataIndex: 'dsrAuditByName',
    render: text => text ? text : '-'
  },
  {
    title: '调研对象',
    dataIndex: 'surveyTarget',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
  },
  {
    title: '门店地址',
    dataIndex: 'startPointDesc',
    render: text => text ? text : '-',
    sorter: (a, b) => a.startPointDesc?.localeCompare(b.startPointDesc),
  },
  {
    title: '二审时间',
    dataIndex: 'audit2At',
    render: text => text ? moment(text).format('YYYY-MM-DD') : '-',
    sorter: (a, b) => a.audit2At?.localeCompare(b.audit2At),
  },
  {
    title: '开始时间',
    dataIndex: 'startAt',
    render: text => text && text!='1' ? text.split('.')[0] : '-',
    sorter: (a, b) => {
      a.startAt?a.startAt=a.startAt:a.startAt='1'
      b.startAt?b.startAt=b.startAt:b.startAt='1'
      return a.startAt?.localeCompare(b.startAt)
    },

  },
  {
    title: '结束时间',
    dataIndex: 'endAt',
    render: text => text && text!='1' ? text.split('.')[0] : '-',
    sorter: (a, b) => {
        a.endAt?a.endAt=a.endAt:a.endAt='1'
        b.endAt?b.endAt=b.endAt:b.endAt='1'
        return a.endAt?.localeCompare(b.endAt)
    },
  },
  {
    title: '二审状态',
    dataIndex: 'taskAudit2Status',
    render: text => text.display ? text.display : '-'
  },
  {
    title: '复核状态',
    dataIndex: 'taskReviewStatus',
    render: text => text.display ? text.display : '-'
  },
  {
    title: '木糖醇标记',
    dataIndex: 'xylitolFlag',
    key: 'xylitolFlag',
    sorter: (a, b) => a.xylitolFlag-b.xylitolFlag,
    render: (text, record) => (
      <>
        {
          text ? text : '- -'
        }
      </>
    )
  },
  {
    title: '考核得分',
    dataIndex: 'checkScore',
    render: text => text ? (text*100)?.toFixed(2) + '%'  : '-',
    sorter: (a, b) => a.checkScore-b.checkScore,
  },
  {
    title: '总退回次数',
    dataIndex: 'refusedCount',
    render: text => text ? text : '0',
    sorter: (a, b) => a.refusedCount-b.refusedCount,
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    render: (text, record) => (<>
      <Button size='small' type="link" onClick={e => push_page(record)}>查看</Button>
      <Divider type='vertical' />
      <Button size='small' type="link" disabled={record.taskReviewStatus.display != '待审核' && record.taskReviewStatus.display != 'DSR已审核'} onClick={e => handleShowPass(record)}>通过</Button>
      <Divider type='vertical' />
      <Button size='small' type="link" disabled={record.taskReviewStatus.display != '待审核' && record.taskReviewStatus.display != 'DSR已审核'} onClick={e => handleShowBack(record)}>退回</Button>
      <Divider type='vertical' />
      <Button size='small' type='link' onClick={e => JhCheckScord(record)} >计算得分</Button>
      {/*<Divider type='vertical' />
     <Button size='small' type="link" disabled={record.taskReviewStatus.display != '待审核'} onClick={e =>handleShowPlan(record)} >安排</Button>*/}
    </>
    )
  },
  ]
  //
  // 查看详情函数
const push_page = e => {
  store.dispatch({
    type: 'selectCache_init', value: {
      page: 'jh-checkagain',
      select: {
        surveyorSelect,
        proxySelect,
        scopeSelect,
        dsrSelect,
        codeSelect,
        dateSelect,
        firstStatusSelect,
        secondStatusSelect,
        reviewStatusSelect,
        startPointDesc,
        audit2ByIdSelect,
        reviewByIdSelect,
        targetSelect,
      }
    }
  })
  push('/jh/' + projectid + '/check/particular/' + e.id)
}

// 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()

useEffect(() => {
  if (store.getState().selectCache.page === 'jh-checkagain') {
    const data = store.getState().selectCache.select
    Promise.all([
      new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
      new Promise(r => setProxySelect(data.proxySelect, () => r())),
      new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
      new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
      new Promise(r => setDateSelect(data.dateSelect, () => r())),
      new Promise(r => setCodeSelect(data.codeSelect, () => r())),
      new Promise(r => setFirstStatusSelect(data.firstStatusSelect, () => r())),
      new Promise(r => setSecondStatusSelect(data.secondStatusSelect, () => r())),
      new Promise(r => setReviewStatusSelect(data.reviewStatusSelect, () => r())),
      new Promise(r => setStartPointDesc(data.startPointDesc, () => r())),
      new Promise(r => setReviewByIdSelect(data.reviewByIdSelect, () => r())),
      new Promise(r => setAudit2ByIdSelect(data.audit2ByIdSelect, () => r())),
      new Promise(r => setTargetSelect(data.targetSelect, () => r())),
    ]).then(res => {
      setGetData_flag(true)
      store.dispatch({ type: 'selectCache_clear' })
    })
  } else {
    setGetData_flag(true)
    store.dispatch({ type: 'selectCache_clear' })
  }
}, [])

  const [getData_flag, setGetData_flag] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition = []
    queryCondition.push('IN:taskReviewStatus=1!21')
    queryCondition.push('EQ:projectId=' + projectid)
    // queryCondition.push('EQ:reviewById=' + store.getState().user.uid)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:audit2At=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:audit2At=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (reviewStatusSelect) queryCondition.push('EQ:taskReviewStatus=' + reviewStatusSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (startPointDesc) queryCondition.push('LIKE:startPointDesc=' + startPointDesc)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (audit2ByIdSelect) queryCondition.push('EQ:audit2ById=' + audit2ByIdSelect)
    if (reviewByIdSelect) queryCondition.push('EQ:reviewById=' + reviewByIdSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
    if (dsrByIdSelect) queryCondition.push('EQ:dsrAuditById=' + dsrByIdSelect)
    if (mutangchunBool) {
      queryCondition.push('NOTNULL:xylitolFlag=null')
    }

    axios.get('/api/core/task/list', {
      params: {
				page,
				pageSize,
        queryCondition: queryCondition.join(','),
        userId:store.getState().user.uid,
        auditFlag:3,
        auditSample:true,
      }
    }).then(res => {
			setTotal(res.data.totalSize)
      let arr = res.data.data.map(i => { return { ...i, key: i.id } })
      if (mutangchunBool) {
        arr = arr.filter(v=>{
          return v.xylitolFlag!=''
        })
      }
      setDataSource(arr)
    })
  }
  
	const [reasonType,setReasonType] = useState([])

  useEffect(() => {
		axios.get('/api/sys/dict/jhTaskRefusedReasonType')
		.then(res=>{
			setReasonType(res.data.labelValues)
		})
    if(store.getState().user.uid==null){
      message.error('重新进入页面,请勿刷新')
      return
    }
    if (!getData_flag) return
      getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [store.getState().user.uid, updateFlag,getData_flag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])
  const [audit1StatusOption, setAudit1StatusOption] = useState([])
  const [audit2StatusOption, setAudit2StatusOption] = useState([])
  const [reviewStatusOption, setReviewStatusOption] = useState([])
  const [audit2Option, setAudit2Option] = useState([])
  const [reviewOption, setReviewOption] = useState([])
  const [scopeOption, setScopeOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/taskAudit1Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit1StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskAudit2Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit2StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskReviewStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setReviewStatusOption(arr)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_checker',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setAudit2Option(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_reviewer',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setReviewOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode:'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_proxy',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode:'jiahua'
        }
      }).then(res => {
        setProxyOption(res.data.data)
      })
    })
    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
  }, [])


  // select
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [proxySelect, setProxySelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [dsrSelect, setDsrSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [firstStatusSelect, setFirstStatusSelect] = useState(null)
  const [secondStatusSelect, setSecondStatusSelect] = useState(null)
  const [reviewStatusSelect, setReviewStatusSelect] = useState(null)
  const [startPointDesc, setStartPointDesc] = useState(null)
  const [audit2ByIdSelect, setAudit2ByIdSelect] = useState(null)
  const [dsrByIdSelect, setDsrByIdSelect] = useState(null)
  const [reviewByIdSelect, setReviewByIdSelect] = useState(null)
  const [targetSelect, setTargetSelect] = useState('')
  const [dateSelect, setDateSelect] = useState([])

  const clearSelect = e => {
    setDsrByIdSelect(null)
    setScopeSelect(null)
    setDsrSelect('')
    setCodeSelect('')
    setStartPointDesc(null)
    setFirstStatusSelect(null)
    setSecondStatusSelect(null)
    setReviewStatusSelect(null)
    setSurveyorSelect(null)
    setProxySelect(null)
    setUpdateFlag(pre => pre + 1)
    setReviewByIdSelect(null)
    setAudit2ByIdSelect(null)
    setTargetSelect('')
    setDateSelect([])
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setRemark('')
    setReviewRefusedType(null)
    setTaskId(null)
    setAudit(null)
    setReview(null)
  }
  const [audit, setAudit] = useState(null)
  const [review, setReview] = useState(null)
  const [remark, setRemark] = useState('')
  const [taskId, setTaskId] = useState(null)
  //通过
  const handlePass = e => {
    axios.post('/api/core/jhTaskAudit/reviewPassed', {
      reviewPassedReason: remark
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //批量通过
  const hanldePassMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/jhTaskAudit/batchReviewPassed', {
      reviewPassedReason: remark
    }, {
      params: {
        taskIds: selectedRowKeys.join(',')
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  // 退回
  const handleBack = e => {
    if(audit1RefusedReason.length == 0 || !reviewRefusedType) return message.error('请选择退回理由和退回阶段')
    axios.post('/api/core/jhTaskAudit/reviewRefused', {
      reviewRefusedReason: audit1RefusedReason[1],
			reviewRefusedReasonType: audit1RefusedReason[0],
			reviewRefusedRemark: remark,
      reviewRefusedType,
      isSelfCheck:1
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  //批量退回
  const handleBackMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/jhTaskAudit/batchReviewRefused', {
      reviewRefusedReason: audit1RefusedReason[1],
			reviewRefusedReasonType: audit1RefusedReason[0],
			reviewRefusedRemark: remark,
      reviewRefusedType
    }, {
      params: {
        taskIds: selectedRowKeys.join(',')
      }
    }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  // 安排
  const handlePlan = e => {
    axios.post('/api/core/jhTaskAudit/assign', {
      assignRemark: remark,
      audit2ById: audit,
      reviewById: review
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      handleModalCancel()
      message.success()
      getData()
    })
  }
  //批量安排
  const handlePlanMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/jhTaskAudit/batchAssign', {
      assignRemark: remark,
      audit2ById: audit,
      reviewById: review
    }, {
      params: {
        taskIds: selectedRowKeys.join(',')
      }
    }).then(res => {
      handleModalCancel()
      message.success()
      getData()
    })
  }
  //安排判断
  const handleEnsure = e => {
    if (modalVisible === 'back') handleBack()
    if (modalVisible === 'backMore') handleBackMore()
    if (modalVisible === 'pass') handlePass()
    if (modalVisible === 'passMore') hanldePassMore()
    if (modalVisible === 'plan') handlePlan()
    if (modalVisible === 'planMore') handlePlanMore()
  }
  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:taskReviewStatus=1!21')
    queryCondition.push('EQ:projectId=' + projectid)
    queryCondition.push('EQ:reviewById=' + store.getState().user.uid)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:audit2At=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:audit2At=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (reviewStatusSelect) queryCondition.push('EQ:taskReviewStatus=' + reviewStatusSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (audit2ByIdSelect) queryCondition.push('LIKE:audit2ById=' + audit2ByIdSelect)
    if (dsrByIdSelect) queryCondition.push('LIKE:dsrAuditById=' + dsrByIdSelect)
    if (reviewByIdSelect) queryCondition.push('EQ:reviewById=' + reviewByIdSelect)
    if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)

    axios.get('/api/core/jhTaskAudit/export', {
      params: {
				page,
				pageSize,
        taskAuditExportType: 6,
        queryCondition: queryCondition.join(',')
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出门店.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  const ExportPlop = e =>{
    axios.get('/api/core/project/export/taskData',{
      params:{
        projectId:projectid 
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出扫码数据.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }
	const [audit1RefusedReason,setAudit1RefusedReason] = useState([])

  const [reviewRefusedType,setReviewRefusedType] = useState(null)

  const handleSaveSample = e => { // 抽样
    if (!sampleType) {
      message.error('请选择抽样类型')
      return
    }
    const body = { projectId: Number(projectid), ruleType: sampleType }
    if (sampleType === 2) body.lessThanScore = sampleScore
    if (sampleType === 3) {
      body.denominator = samplePercent
      console.log(samplePercent)
    }
    if (sampleType === 3) body.numerator = samplePercent_
    if (spmpleId) body.id = spmpleId
    if (!spmpleId) axios.post('/api/core/auditSampleSetting', body).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
    if (spmpleId) axios.put('/api/core/auditSampleSetting', body).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }

  useEffect(() => {
    if (modalVisible === 'sample')
      axios.get('/api/core/auditSampleSetting/list', { params: { queryCondition: 'EQ:projectId=' + projectid } }).then(res => {
        if (res.data.data.length) {
          setSpmpleId(res.data.data[0].id)
          setSampleType(res.data.data[0].ruleType)
          if (res.data.data[0].lessThanScore) setSampleScore(res.data.data[0].lessThanScore)
          if (res.data.data[0].denominator) setSamplePercent(res.data.data[0].denominator)
          if (res.data.data[0].numerator) setSamplePercent_(res.data.data[0].numerator)
        }
      })
  }, [modalVisible])

const [sampleType, setSampleType] = useState(null)
const [sampleScore, setSampleScore] = useState('')
const [samplePercent, setSamplePercent] = useState('')
const [samplePercent_, setSamplePercent_] = useState('')
const [spmpleId, setSpmpleId] = useState(null)

const handleModalCancelon = e => {
    setModalVisible(null)
    setRemark('')
    setTaskId(null)
    setSampleType(null)
    setSampleScore('')
    setSamplePercent('')
    setSamplePercent_('')
  }

	const [pageSize,setPageSize] = useState(5)//显示数据数量
	const [page,setPage] = useState(1)//数据页数
	const [total,setTotal] = useState(null)//数据总是

  const [ mutangchunBool,setMutangchunBool] = useState(false)
  
	useEffect(()=>{
	  getData()
	},[pageSize,page])

  return (<div style={{ padding: '30px' }} >
    <div style={{ padding: '30px', background: 'white' }} >

      <Row gutter={[20, 20]} style={{ marginBottom: '20px' }} >
      <Col span={8}>
        <span style={{ lineHeight: '30px' }}>调研编号</span>
        <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
      </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>调研大区</span>
          <AutoComplete
            onChange={e => { console.log(e); setScopeSelect(e); }}
            style={{ width: '240px', marginLeft: '10px' }}
            options={scopeOption.map(v => { return { value: v } })}
            value={scopeSelect}
        />
        </Col>
        <Col span={8} >
          <span style={{ lineHeight: '30px' }} >调研对象</span>
          <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>访问员</span>
          <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
          <Select.Option value={''}>取消选择</Select.Option>
          {
              surveyorOption.map((i, index) => (
                <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>代理人</span>
          <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
          <Select.Option value={''}>取消选择</Select.Option>
            {
              proxyOption.map((i, index) => (
                <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>二审人员</span>
          <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } style={{ width: '240px', marginLeft: '10px' }} value={audit2ByIdSelect} onChange={e => setAudit2ByIdSelect(e)}>
          <Select.Option value={''}>取消选择</Select.Option>
            {
              audit2Option.map((i, index) => (
                <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px',}}>复核人员</span>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={reviewByIdSelect} onChange={(e) => { setReviewByIdSelect(e) }} style={{ width: '240px', marginLeft: '10px' }} >
            <Select.Option value={''}>取消选择</Select.Option>
            {
              reviewOption.map((v, i) => {
                return <Select.Option value={v.id}>{v.name}</Select.Option>
              })
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>DSR审核</span>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '10px' }} value={dsrByIdSelect} onChange={e => setDsrByIdSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
              audit2Option.map((i, index) => (
                <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>一审状态</span>
          <Select style={{ width: '240px', marginLeft: '10px' }} value={firstStatusSelect} onChange={e => setFirstStatusSelect(e)} >
          <Select.Option value={''}>取消选择</Select.Option>
          {
              audit1StatusOption.map((i, index) => <Select.Option key={index} value={i.value}>{i.text}</Select.Option>)
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }} >二审状态</span>
          <Select style={{ width: '240px', marginLeft: '10px' }} value={secondStatusSelect} onChange={e => setSecondStatusSelect(e)}>
          <Select.Option value={''}>取消选择</Select.Option>
          {
              audit2StatusOption.map((i, index) => <Select.Option key={index} value={i.value} >{i.text}</Select.Option>)
            }
          </Select>
        </Col>
        <Col span={8} >
          <span style={{ lineHeight: '30px' }} >复核状态</span>
          <Select style={{ width: '240px', marginLeft: '10px' }} value={reviewStatusSelect} onChange={e => setReviewStatusSelect(e)} >
          <Select.Option value={''}>取消选择</Select.Option>
          {
              reviewStatusOption.map((i, index) => <Select.Option key={index} value={i.value} >{i.text}</Select.Option>)
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>门店地址</span>
          <Input style={{ width: '240px', marginLeft: '10px' }} value={startPointDesc} onChange={e => setStartPointDesc(e.target.value)}></Input>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>是否筛选木糖醇</span>
          <Checkbox onChange={e=>setMutangchunBool(e.target.checked)}></Checkbox>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>查询时间</span>
          <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
        </Col>
      </Row>
      <Row justify='space-between'
        style={{ marginBottom: '20px' }} >
        <Col >
          <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('passMore')} >批量通过</Button>
          <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('backMore')} >批量退回</Button>
          {/*<Button style={{ margin: '0 10px' }} type='primary' onClick={e =>setModalVisible('planMore')}>批量安排</Button> */}
          {/*<Button style={{ margin: '0 10px' }} >批量下载</Button> */}
          <Button style={{ margin: '0 10px' }} onClick={handleExport} >批量导出</Button>
          <Button style={{ margin: '0 10px' }} onClick={e => {selectedRowKeys.forEach(v=>{axios.get('/api/core/task/genJhCheckScore/'+v)})}}>批量算分</Button>
          <Button style={{ margin: '0 10px' }} onClick={ExportPlop} >导出所有工单扫码数据</Button>
          {/* <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('sample')}>审核抽样</Button> */}
        </Col>
        <Col>
          <Button style={{ margin: '0 10px' }} type='primary' onClick={getData} >查询</Button>
          <Button style={{ margin: '0 10px' }} onClick={clearSelect} >重置</Button>
        </Col>
      </Row>

      {
        selectedRowKeys.length !== 0 ?
          <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }} >
            <Col >< Button danger type='primary' onClick={handleClearSelect} >清空</Button></Col >
            <Col >{'已选择' + selectedRowKeys.length + '项'}</Col>
          </Row>
          : null
      }
      <Table
        rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
        dataSource={dataSource}
        columns={columns}
				pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
						setPage(page)
						setPageSize(pageSize)
					  }
				}}
      />

      <Modal
        width={640}
        title={'退回门店'}
        visible={modalVisible === 'back' || modalVisible === 'backMore'}
        onCancel={handleModalCancel}
        destroyOnClose
        maskClosable={false}
        footer={[
          < Button key="back" onClick={handleModalCancel} >取消</Button>,
          < Button key="submit" type="primary" onClick={handleEnsure} >确认</Button>
        ]}
      >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }} >
        <Col span={6} style={{ textAlign: 'right' }} >退回理由:</Col>
        <Col span={14} >
          <Select style={{ width: 120 }} onChange={e=>{setAudit1RefusedReason([e,reasonType[e]])}}>
            {
              Object.keys(reasonType).map(v=>{
                return <Option value={v} >{reasonType[v]}</Option>
              })
            }
          </Select>
        </Col>
          <Col span={6} style={{ textAlign: 'right' }} >退回备注:</Col>
          <Col span={14} >
            <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
          </Col>
          <Col span={6} style={{ textAlign: 'right' }} >退回阶段:</Col>
          <Col span={14} >
            <Select style={{ width: '100%' }} value={reviewRefusedType} onChange={e => setReviewRefusedType(e)} >
              <Select.Option key={1} value={1} >一审</Select.Option>
              <Select.Option key={2} value={2} >二审</Select.Option>
            </Select>
          </Col>
        </Row>
      </Modal>

      <Modal
        width={640}
        title={'通过门店'}
        visible={modalVisible === 'pass' || modalVisible === 'passMore'}
        onCancel={handleModalCancel}
        destroyOnClose
        maskClosable={false}
        footer={[
          < Button key="back" onClick={handleModalCancel} >取消</Button>,
          < Button key="submit" type="primary" onClick={handleEnsure} >确认</Button>
        ]}
      >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }} >
          <Col span={6} style={{ textAlign: 'right' }} >通过理由:</Col>
          <Col span={14} >
            <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
          </Col>
        </Row>
      </Modal>

      <Modal
        width={640}
        title={'审核抽样'}
        visible={modalVisible === 'sample'}
        onCancel={handleModalCancelon}
        destroyOnClose
        maskClosable={false}
        footer={[
        <Button key="back" onClick={handleModalCancelon}>取消</Button>,
        <Button key="submit" type="primary" onClick={handleSaveSample}>确认</Button>,
        ]}
    >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
        <Col span={6} style={{ textAlign: 'right' }} >抽样规则:</Col>
        <Col span={14}>
            <Radio checked={sampleType === 1} onChange={e => setSampleType(1)}>全检</Radio>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }} ></Col>
        <Col span={14}>
            <Radio checked={sampleType === 2} onChange={e => setSampleType(2)}>得分</Radio>
            <span style={{ margin: '0 10px' }}>评分低于</span>
            <Input style={{ width: '80px' }} value={sampleScore} onChange={e => setSampleScore(e.target.value)} placeholder='60' />
            <span style={{ margin: '0 10px' }}>分</span>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }} ></Col>
        <Col span={14}>
            <Radio checked={sampleType === 3} onChange={e => setSampleType(3)}>抽选</Radio>
            <Input style={{ width: '80px' }} value={samplePercent} onChange={e => setSamplePercent(e.target.value)} placeholder='5' />
            <span style={{ margin: '0 10px' }}>取</span>
            <Input style={{ width: '80px' }} value={samplePercent_} onChange={e => setSamplePercent_(e.target.value)} placeholder='1' />
        </Col>
        </Row>
    </Modal>
    </div>
  </div>
  )
}

export default App