
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import file from '@/assets/jhcpdr.xlsx'
import PopConfirmOnly from '@/utils/PopConfirmOnly'

function App(props) {

  const handleShowEdit = e => {
    setModalVisible('edit')
    setData({
      id: e.id,
      calScoreTemplateCode: e.calScoreTemplateCode,
      calScoreTemplateName: e.calScoreTemplateName,
      downloadUrl: e.downloadUrl,
      remark: e.remark,
    })
  }

  // table
  const [columns, setColumns] = useState([
    {
      title: '模板名称',
      dataIndex: 'calScoreTemplateName',
      key: 'calScoreTemplateName'
    },
    {
      title: '备注/描述',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action', 
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => handleShowEdit(record)}>修改</Button>
          <Divider type='vertical'/>
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  const [dataSource, setDataSource] = useState()
  const getData = e => {
    axios.get('/api/core/calScoreTemplate/list', {
      params: {
				pageSize: 1000000,
      }
    }).then(res => {
      setDataSource(res.data.data)
    })
  }
  useEffect(() => {
    getData()
  }, [])

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setData({
      id: null,
      calScoreTemplateCode: '',
      calScoreTemplateName: '',
      remark: '',
      downloadUrl: null
    })
    setFileListAdd([])
  }
//
  // 添加 修改
  const [data, setData] = useState({
    id: null,
    calScoreTemplateCode: '',
    calScoreTemplateName: '',
    remark: '',
    downloadUrl: null
  })
  const handleChangeData = (type, value) => {
    setData(Object.assign({}, data, { [type]: value }))
  }

  const handleAdd = e => {
    if(data.calScoreTemplateCode!=''&&data.calScoreTemplateName!=''&&data.downloadUrl!=null&&data.remark!=''){
      axios.post('/api/core/calScoreTemplate', {
        calScoreTemplateCode: data.calScoreTemplateCode,
        calScoreTemplateName: data.calScoreTemplateName,
        downloadUrl: data.downloadUrl,
        remark: data.remark
      }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    }else{
      message.error('请完整填写信息,再次提交')
    }
  }
  const handleEdit = e => {
    if(data.calScoreTemplateCode!=''&&data.calScoreTemplateName!=''&&data.remark!=''){
      axios.put('/api/core/calScoreTemplate', {
        id: data.id,
        calScoreTemplateCode: data.calScoreTemplateCode,
        calScoreTemplateName: data.calScoreTemplateName,
        downloadUrl: data.downloadUrl,
        remark: data.remark
      }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    }else{
      message.error('请完整填写信息,再次提交')
    }
  }

  const [fileListAdd, setFileListAdd] = useState([])
  const uploadProps = {
    onRemove: (file) => {
      setFileListAdd([])
      return []
    },
    beforeUpload: (file) => {
      setFileListAdd([file])
      return false
    }
  }

  //删除
  const handleDelete = e => {
    axios.delete('/api/core/calScoreTemplate/' + e).then(res => {
      message.success()
      getData()
    })
  }


  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button type='primary' onClick={e => setModalVisible('add')}><PlusOutlined />添加模板</Button>
          </Col>
          <Col>
            {/* <Button>导出列表</Button> */}
          </Col>
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />

      </div>

      <Modal
        width={640}
        title={modalVisible === 'add' ? '添加模版' : '修改模版'}
        visible={modalVisible === 'add' || modalVisible === 'edit'}
        onCancel={handleModalCancel}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleModalCancel}>取消</Button>,
          <Button key="submit" type="primary" onClick={modalVisible === 'add' ? handleAdd : handleEdit}>确认</Button>,
        ]}
      >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>模板名称:</Col>
          <Col span={14}><Input value={data.calScoreTemplateName} onChange={e => handleChangeData('calScoreTemplateName', e.target.value)}></Input></Col>
          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>模板编号:</Col>
          <Col span={14}><Input value={data.calScoreTemplateCode} onChange={e => handleChangeData('calScoreTemplateCode', e.target.value)}></Input></Col>
          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>备注:</Col>
          <Col span={14}><Input.TextArea rows={4} value={data.remark} onChange={e => handleChangeData('remark', e.target.value)}></Input.TextArea></Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            {
              modalVisible === 'add' ?<font color='red'>*</font>:null
            }
          批量上传:</Col>
          <Col span={14}>
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>上传文件</Button>
            </Upload>
          </Col>
          <Col span={4}><Button onClick={e => window.open(file)}>下载模板</Button></Col>
        </Row>
      </Modal>

    </div>
  )
}

export default App