import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'
import { connect } from 'echarts/lib/echarts';


function App(props) {

    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    // table
    const columns = [{
        title: '调研编号',
        dataIndex: 'surveyCode',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
        title: '调研大区',
        dataIndex: 'surveyScope',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    }, {
        title: '访问员',
        dataIndex: 'principal',
        render: text => text ? text : '-',
        sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '门店类型',
      dataIndex: 'shopLevel',
      render: text => text ? text : '-',
      sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
    },
    {
      title: '代理人',
      dataIndex: 'agent',
      render: text => text ? text : '-',
      sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
      title: '二审人员',
      dataIndex: 'audit2ByName',
      render: text => text ? text : '-',
      sorter: (a, b) => a.audit2ByName?.localeCompare(b.audit2ByName),
    },
    {
        title: '调研对象',
        dataIndex: 'surveyTarget',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },{
        title: '退回类型',
        dataIndex: 'audit2RefusedReasonType',
        render: text => text ? labelValues[text] : '-'
    },
    {
        title: '退回备注',
        dataIndex: 'audit2RefusedRemark',
        render: text => text ? text : '-'
    },
    {
        title: '总退回次数',
        dataIndex: 'refusedCount',
        render: text => text ? text : '0',
        sorter: (a, b) => a.refusedCount-b.refusedCount,
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
            <>
                <Button size='small' type='link' onClick={e => push_page(record)}>查看</Button>
                <Divider type='vertical' />
                {
                    record.audit2RefusedReasonType == 10
                    ?<Button size='small' type='link' onClick={e => showModal(record,'upname')}>更换二审人员</Button>
                    :<Button size='small' type='link' onClick={e => showModal(record,'affirm')}>确认退回</Button>
                }

                <Divider type='vertical' />
                <Button size='small' type='link' onClick={e => showModal(record,'cancel')}>取消退回</Button>
            </>
        )
    },
    ]
    //
    // 查看详情函数
    const push_page = e => {
        store.dispatch({
            type: 'selectCache_init', value: {
                page: 'jh-checksecond',
                select: {
                    surveyorSelect,
                    scopeSelect,
                    dsrSelect,
                    codeSelect,
                    dataSource,
                    proxySelect,
                    audit2ByIdSelect
                }
            }
        })
        push('/jh/' + projectid + '/check/particular/' + e.id)
        sessionStorage.setItem('display',true)
    }

    // 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()
    useEffect(() => {
        if (store.getState().selectCache.page === 'jh-checksecond') {
            const data = store.getState().selectCache.select
            Promise.all([
                new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
                new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
                new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
                new Promise(r => setCodeSelect(data.codeSelect, () => r())),
                new Promise(r => setProxySelect(data.proxySelect, () => r())),
                new Promise(r => setAudit2ByIdSelect(data.audit2ByIdSelect, () => r())),
                // new Promise(r => setDataSource(data.dataSource, () => r())),
            ]).then(res => {
                setGetData_flag(true)
                store.dispatch({ type: 'selectCache_clear' })
            })
        } else {
            setGetData_flag(true)
            store.dispatch({ type: 'selectCache_clear' })
        }
    }, [])

    const [getData_flag, setGetData_flag] = useState(false)

    const [dataSource, setDataSource] = useState([])
    const [updateFlag, setUpdateFlag] = useState(0)
    const getData = e => {
        const queryCondition = []
        queryCondition.push('IN:taskAudit2Status=3')
        queryCondition.push('EQ:projectId=' + projectid)
        if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
        if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
        if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
        if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
        if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
        if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
        if (audit2ByIdSelect) queryCondition.push('EQ:audit2ById=' + audit2ByIdSelect)

        axios.get('/api/core/task/list', {
            params: {
                pageSize: 1000000,
                queryCondition: queryCondition.join(','),
                userId:store.getState().user.uid
            }
        }).then(res => {
            const arr = res.data.data.map(i => { return { ...i, key: i.id } })
            setDataSource(arr)
        })
    }

    const [labelValues,setLabelValues] = useState({})

    useEffect(() => {
        // if (store.getState().user.uid)
        //     if (!getData_flag) return
        getData()
        axios.get('/api/sys/dict/jhTaskRefusedReasonType').then(res=>{
            setLabelValues(res.data.labelValues)
        })
    }, [store.getState().user.uid, updateFlag, getData_flag])

    // table select
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const handleSelectChange = e => {
        setSelectedRowKeys(e)
    }
    const handleClearSelect = e => {
        setSelectedRowKeys([])
    }

    // option
    const [surveyorOption, setSurveyorOption] = useState([])
    const [proxyOption, setProxyOption] = useState([])
    const [shenheyuans, setShenheyuans] = useState([])

    const [shenheyuan, setShenheyuan] = useState([])

    const [scopeOption, setScopeOption] = useState([])
    useEffect(() => {
        axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
            setScopeOption(res.data)
        })
        axios.get('/api/core/project/' + projectid).then(res => {
            axios.get('/api/sys/user/list', {
                params: {
                    roleCode: 'jiahua_vistor',
                    pageSize: 1000000,
                    groupId: res.data.userGroupId,
                    orgCode: 'jiahua'
                }
            }).then(res => {
                setSurveyorOption(res.data.data)
            })
            axios.get('/api/sys/user/list', {
                params: {
                    roleCode: 'jiahua_checker',
                    pageSize: 1000000,
                    groupId: res.data.userGroupId,
                    orgCode: 'jiahua'
                }
            }).then(res => {
                setShenheyuans(res.data.data)
            })
            axios.get('/api/sys/user/list', {
              params: {
                roleCode: 'jiahua_proxy',
                pageSize: 1000000,
                groupId: res.data.userGroupId,
                orgCode:'jiahua'
              }
            }).then(res => {
              setProxyOption(res.data.data)
            })
        })
    }, [])

    // select
    const [surveyorSelect, setSurveyorSelect] = useState(null)
    const [scopeSelect, setScopeSelect] = useState(null)
    const [dsrSelect, setDsrSelect] = useState('')
    const [targetSelect, setTargetSelect] = useState('')
    const [codeSelect, setCodeSelect] = useState('')
    const [proxySelect, setProxySelect] = useState(null)
    const [audit2ByIdSelect, setAudit2ByIdSelect] = useState(null)

    const clearSelect = e => {
        setScopeSelect(null)
        setDsrSelect('')
        setTargetSelect('')
        setCodeSelect('')
        setSurveyorSelect(null)
        setUpdateFlag(pre => pre + 1)
    }
    
    const [isModalVisible, setIsModalVisible] = useState(false);//对话框开关

    const [audit2RefusedRemark,setaudit2RefusedRemark] = useState(null)//备注

    const [taskid,settaskid] = useState(null)

    const [string,setstring] = useState(null)

    const [title,settitle] = useState(null)

    const [fuhe,setFuhe] = useState(null)
    const [beizhu,setBeizhu] = useState(null)

    const showModal = (record,string) => {
        if(string=='affirm'){
            settitle('确认退回')
        }else if(string == 'cancel'){
            settitle('取消退回')
        }else if(string == 'upname'){
            settitle('更换审核员')
        }
        setIsModalVisible(true);
        setaudit2RefusedRemark(record.audit2RefusedRemark)
        setstring(string)
        settaskid(record.id)
        setShenheyuan(record.audit2ById)
        setFuhe(record.reviewById)
        setBeizhu(record.beizhu)
    };

    const handleOk = () => {
        setIsModalVisible(false);
        if(string=='affirm')affirm(taskid)
        if(string=='cancel')cancel(taskid)
        if(string=='upname')upname(taskid)
    };

    //批量确认退回
    const batchaffirm = (taskId) => {
        if(selectedRowKeys.length==0) {
            message.error('请选择需要确认退回的工单')
            return
        }
        dataSource.forEach(v=>{
            if(selectedRowKeys.includes(v.id)){
                axios.post('/api/core/jhTaskAudit/confirmReturn',{
                    reviewRefusedType:'1',
                    confirmRefusedRemark:v.audit2RefusedRemark
                },{
                    params: {
                        taskId:v.id
                    }
                })
                .then(res=>{
                    message.success('确认退回成功')
                    getData()
                })
            }
        })
    }


    //确认退回
    const affirm = (taskId) => {
        axios.post('/api/core/jhTaskAudit/confirmReturn',{
            reviewRefusedType:'1',
            confirmRefusedRemark:audit2RefusedRemark
        },{
            params: {
                taskId
            }
        })
        .then(res => {
            message.success('确认退回成功')
            getData()
        })
    }

    //取消退回
    const cancel = (taskId) => {
        axios.post('/api/core/jhTaskAudit/cancelReturn',{
            cancelRefusedRemark:audit2RefusedRemark
        }, {
            params: {
                taskId
            }
        })
        .then(res => {
            message.success('取消退回成功')
            getData()
        })
    }

    const upname = (taskId)=>{
        axios.post('/api/core/jhTaskAudit/assignUpdate', {
            audit2ById: shenheyuan,
            reviewById:fuhe,
            assignRemark:beizhu
          }, {
            params: {
              taskId
            }
          }).then(res => {
            message.success('更改成功')
            axios.post('/api/core/jhTaskAudit/cancelReturn',{
                cancelRefusedRemark:audit2RefusedRemark
            }, {
                params: {
                    taskId
                }
            })
            .then(res => {
                getData()
            })
        })
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{ padding: '30px' }}>
            <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {
                    title == '更换审核员'?<Row>
                    <Col span='6'>{title}更换审核员</Col>
                    <Col span="18">
                        <Select style={{ width: '240px', marginLeft: '25px' }} value={shenheyuan} onChange={e => setShenheyuan(e)}>
                            <Select.Option value={''}>取消选择</Select.Option>
                            {
                                shenheyuans.map((i, index) => (
                                    <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>:<Row>
                    <Col span='6'>{title}备注</Col>
                    <Col span="18"><Input value={audit2RefusedRemark} onChange={e=>{setaudit2RefusedRemark(e.target.value)}} ></Input></Col>
                </Row>
                }
            </Modal>
            <div style={{ padding: '30px', background: 'white' }} >

                <Row gutter={[20, 20]} style={{ marginBottom: '20px' }} >
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} >调研编号</span>
                        <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
                    </Col>
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} >调研大区</span>
                        <AutoComplete
                          onChange={e => { console.log(e); setScopeSelect(e); }}
                          style={{ width: '240px', marginLeft: '10px' }}
                          options={scopeOption.map(v => { return { value: v } })}
                          value={scopeSelect}
                      />
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>访问员</span>
                        <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        } 
                          style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
                            <Select.Option value={''}>取消选择</Select.Option>
                            {
                                surveyorOption.map((i, index) => (
                                    <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col span={8}>
                      <span style={{ lineHeight: '30px' }}>代理人</span>
                      <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
                      <Select.Option value={''}>取消选择</Select.Option>
                        {
                          proxyOption.map((i, index) => (
                            <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                          ))
                        }
                      </Select>
                    </Col>
                    <Col span={8}>
                      <span style={{ lineHeight: '30px' }}>二审人员</span>
                      <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      } style={{ width: '240px', marginLeft: '10px' }} value={audit2ByIdSelect} onChange={e => setAudit2ByIdSelect(e)}>
                      <Select.Option value={''}>取消选择</Select.Option>
                        {
                            shenheyuans.map((i, index) => (
                            <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                          ))
                        }
                      </Select>
                    </Col>
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} >调研对象</span>
                        <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
                    </Col>
                </Row>
                <Row justify='space-between' style={{ marginBottom: '20px' }} >
                    <Col>
                        <Button style={{ margin: '0 10px' }} onClick={batchaffirm} >批量确认退回</Button>
                    </Col>
                    <Col>
                        <Button style={{ margin: '0 10px' }} type='primary' onClick={getData} >查询</Button>
                        <Button style={{ margin: '0 10px' }} onClick={clearSelect} >重置</Button>
                    </Col>
                </Row>

                {
                    selectedRowKeys.length !== 0 ?
                        <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }} >
                            <Col >
                                < Button danger type='primary' onClick={handleClearSelect} >清空</Button>
                            </Col >
                            <Col >{'已选择' + selectedRowKeys.length + '项'}</Col>
                        </Row>
                        : null
                }
                <Table
                    rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true }}
                />
            </div>
        </div>
    )
}

export default App