
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { Menu } from 'antd';
import { HomeFilled, GoldenFilled, TeamOutlined, SolutionOutlined, FileTextOutlined, LogoutOutlined } from '@ant-design/icons';
import store from '@/redux/store'

function App() {

  const [menuList, setMenuList] = useState([]);
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { projectid } = useRouteMatch().params

  const [openKeys, setOpenKeys] = useState(['/' + pathname.split('/').slice(3, 4).join('/')])
  const [selectedKeys, setSelectedKeys] = useState(['/' + pathname.split('/').slice(3, 5).join('/')])

  useEffect(() => {
    if (pathname.includes('/check/particular')) {
      setSelectedKeys(['/check/list'])
      return
    }
    if (pathname.includes('/data/visitorDSR')) {
      setSelectedKeys(['/data/visitorDSRType'])
      return
    }
    if (pathname.includes('/data/agencyDSR')) {
      setSelectedKeys(['/data/agencydaili'])
      return
    }
    if (pathname.includes('/data/visitorDSR')) {
      setSelectedKeys(['/data/visitorDSRType'])
      return
    }
    setSelectedKeys('/' + pathname.split('/').slice(3, 5).join('/'))
  }, [pathname])
  useEffect(() => {
    setOpenKeys(['/' + pathname.split('/').slice(3, 4).join('/')])
  }, [pathname])


  useEffect(() => {
    const set = () => {
      setMenuList([
        {
          itemGroup: { link: '/work', text: '门店管理', icon: <GoldenFilled /> },
          itemList: [
            { link: '/work/list', text: '门店列表', code: 'CD_S_GONGDANGUANLI_GDLB' },
            { link: '/work/assign', text: '门店分配', code: 'CD_S_GONGDANGUANLI_GDFP' },
            // { link: '/work/message', text: '消息反馈', code: 'CD_S_GONGDANGUANLI_XXFK' }
          ]
        },
        {
          itemGroup: { link: '/check', text: '审核管理', icon: <TeamOutlined /> },
          itemList: [
            { link: '/check/plan', text: '审核安排', code: 'CD_S_SHENHEGUANLI_SHAP' },
            { link: '/check/back', text: '退回访问员清单', code: 'CD_S_SHENHEGUANLI_SHTH' },
            { link: '/check/affirm', text: '待复核确认', code: 'CD_S_TUIHUISHENHE' },
            { link: '/check/dlaffirm', text: '二审退回清单', code: 'CD_S_SHENHETUIHUIQINGDAN' },
            { link: '/check/list', text: '审核列表', code: 'CD_S_SHENHEGUANLI_SHLB' },
            { link: '/check/first', text: '一审管理', code: 'CD_S_SHENHEGUANLI_YSGL' },
            { link: '/check/second', text: '二审管理', code: 'CD_S_SHENHEGUANLI_ESGL' },
            { link: '/check/again', text: '复核管理', code: 'CD_S_SHENHEGUANLI_FHGL' },
            { link: '/check/completed', text: '已完成管理', code: '*' },//CD_S_SHENHEGUANLI_YWCGL
            { link: '/check/sdsrplan', text: '扫DSR安排', code: 'CD_S_DSRANPAI' },//CD_S_DSRANPAI
            { link: '/check/dsr', text: '扫DSR管理', code: 'CD_S_SHENHEGUANLI_SDSRGL' },
            { link: '/check/record', text: '进店记录', code: 'CD_S_SHENHEGUANLI_JCJL' }
          ]
        },
        {
          itemGroup: { link: '/set', text: '项目设置', icon: <SolutionOutlined /> },
          itemList: [
            { link: '/set/people', text: '人员管理', code: 'CD_S_XIANGMUSHEZHI_RYXQ' },
            { link: '/set/product', text: '产品管理', code: 'CD_S_XIANGMUSHEZHI_JHCPXQ' },
            { link: '/set/shop', text: '门店管理', code: 'CD_S_XIANGMUSHEZHI_JHMDXQ' },
            { link: '/set/log', text: '操作日志', code: 'CD_S_XIANGMUSHEZHI_CZRZ' }
          ]
        },
        {
          itemGroup: { link: '/data', text: '数据统计', icon: <FileTextOutlined /> },
          itemList: [
            { link: '/data/visitorDSRType', text: '访问员业绩报告', code: 'CD_S_SHUJUTONGJI_FWY' },
            { link: '/data/agencydaili', text: '代理业绩报告', code: 'CD_S_SHUJUTONGJI_DLY' },
            { link: '/data/firstuser', text: '审核业绩报告', code: 'CD_S_SHUJUTONGJI_YS' },
          ]
        }
      ])
    }
    if (store.getState().permission.have) set()
    const s = store.subscribe(() => {
      if (store.getState().permission.have) {
        set()
        s()
      }
    })
  }, []);

  const handleExit = e => {
    store.dispatch({ type: 'user_clear' })
  }

  return (
    <Menu
      className='menu'
      theme="dark"
      openKeys={openKeys}
      onOpenChange={e => setOpenKeys(e)}
      selectedKeys={selectedKeys}
      mode="inline"
    >
      {
        menuList.map(i => {
          if (i.item) {
            return (
              <Menu.Item key={i.item.link} icon={i.item.icon}>
                <Link to={'/jh/' + projectid + i.item.link}>{i.item.text}</Link>
              </Menu.Item>
            )
          }
          if (i.itemGroup) {
            return (
              <Menu.SubMenu key={i.itemGroup.link} title={i.itemGroup.text} icon={i.itemGroup.icon} >
                {
                  i.itemList.map(i => {
                    return store.getState().permission.project.includes(i.code) || i.code === '*' ?
                      <Menu.Item key={i.link}>
                        <Link to={'/jh/' + projectid + i.link}>{i.text}</Link>
                      </Menu.Item>
                      : null
                  })
                }
              </Menu.SubMenu>
            )
          }
        })
      }
      < Menu.Item icon={< LogoutOutlined />} onClick={handleExit} >
        退出
      </Menu.Item >
    </Menu >
  );
}


export default App;