import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, DatePicker, Button, Input, Checkbox } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'


function Title(props) {
  return (
    <>
      <Col span={24} style={{ padding: '15px 30px' }}>
        <Row justify='space-between' align='middle'>
          <Col style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.text}</Col>
          <Col>
            {
              props.children
            }
          </Col>
        </Row>
      </Col>

      <Divider style={{ margin: '0' }} />
    </>
  )
}
//

function App(props) {

  const { id } = useRouteMatch().params
  const { push } = useHistory()

  // options
  const [projectTypeOption, setProjectTypeOption] = useState([])
  const [projectTempOption, setProjectTempOption] = useState([])
  const [peopleOption, setPeopleOption] = useState([])
  const [shopOption, setShopOption] = useState([])
  const [targetOption, setTargetOption] = useState([])
  const [locationOption, setLocationOption] = useState([])
  const [productOption, setProductOption] = useState([])
  const [computeTempOption, setComputeTempOption] = useState([])

  useEffect(() => {
    axios.get('/api/sys/dict/projectType').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setProjectTypeOption(arr)
    })
    axios.get('/api/core/projectTemplate/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.projectTemplateName }
      })
      setProjectTempOption(arr)
    })
    axios.get('/api/core/shopGroup/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.shopGroupName }
      })
      setShopOption(arr)
    })
    axios.get('/api/core/metricGroup/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.metricGroupName }
      })
      setTargetOption(arr)
    })
    axios.get('/api/core/locationGroup/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.locationGroupName }
      })
      setLocationOption(arr)
    })
    axios.get('/api/core/productGroup/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.productGroupName }
      })
      setProductOption(arr)
    })
    axios.get('/api/core/calScoreTemplate/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.calScoreTemplateName }
      })
      setComputeTempOption(arr)
    })
    axios.get('/api/common/user/group/list', { params: { pageSize: 1000000, } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.name, status: i.status }
      })
      setPeopleOption(arr.filter(v => {
        return v.status != -1
      }))
    })
  }, [])
  
  useEffect(() => {
    axios.get('/api/core/project/' + id).then(res => {
      setProjectTypeSelect(String(res.data.type))
      setProjectTempSelect(res.data.projectTempId)
      setPeopleSelect(res.data.userGroupId)
      setShopSelect(res.data.shopGroupId)
      setTargetSelect(res.data.metricGroupId)
      setLocationSelect(res.data.locationGroupId)
      setProductSelect(res.data.productGroupId)
      setComputeTempSelect(res.data.calScoreId)
      setNameText(res.data.name)
      setCodeText(res.data.code)
      setAreaText(res.data.scope)
      setDsrAvgScore(res.data.dsrAvgScore)
      setDsrTaskCount(res.data.dsrTaskCount)

      setDate([moment(res.data.startDate, 'YYYY-MM-DD'), moment(res.data.endDate, 'YYYY-MM-DD')])
      setWarnDate(moment(res.data.warningDate, 'YYYY-MM-DD'))
      setisAutoAudit1Passed(res.data.isAutoAudit1Passed)
    })
  }, [])

  // option select
  const [projectTypeSelect, setProjectTypeSelect] = useState(null)
  const [projectTempSelect, setProjectTempSelect] = useState(null)
  const [peopleSelect, setPeopleSelect] = useState(null)
  const [shopSelect, setShopSelect] = useState(null)
  const [targetSelect, setTargetSelect] = useState([])
  const [locationSelect, setLocationSelect] = useState([])
  const [productSelect, setProductSelect] = useState([])
  const [computeTempSelect, setComputeTempSelect] = useState(null)
  // other params
  const [nameText, setNameText] = useState('')
  const [codeText, setCodeText] = useState('')
  const [areaText, setAreaText] = useState('')
  const [date, setDate] = useState([])
  const [warnDate, setWarnDate] = useState(null)
  const [dsrAvgScore,setDsrAvgScore] = useState(null)
  const [dsrTaskCount,setDsrTaskCount] = useState(null)


  const handleClear = e => {
    setProjectTypeSelect(null)
    setProjectTempSelect(null)
    setPeopleSelect(null)
    setShopSelect(null)
    setTargetSelect(null)
    setLocationSelect(null)
    setProductSelect(null)
    setComputeTempSelect(null)
    setCodeText('')
    setAreaText('')
    setDate([])
    setWarnDate(null)
  }


  const handleChangeType = e => {
    handleClear()
    setProjectTypeSelect(e)
  }

  const [isAutoAudit1Passed, setisAutoAudit1Passed] = useState(0)

  const handleSave = e => {
    const body = {
      id:id,
      code: codeText,
      name: nameText,
      type: projectTypeSelect,
      userGroupId: peopleSelect,
      projectTempId: projectTempSelect,
      startDate: date[0] ? moment(date[0]).format('YYYY-MM-DD') : '',
      endDate: date[1] ? moment(date[1]).format('YYYY-MM-DD') : '',
      warningDate: warnDate ? moment(warnDate).format('YYYY-MM-DD') : '',
      scope: areaText,
      dsrAvgScore,
      dsrTaskCount,
      isAutoAudit1Passed
    }
    if(shopSelect) body.shopGroupId = shopSelect
    if(targetSelect) body.metricGroupId = targetSelect
    if(productSelect) body.productGroupId = productSelect
    if(locationSelect) body.locationGroupId = locationSelect
    if(computeTempSelect) body.calScoreId = computeTempSelect

    axios.put('/api/core/project', body).then(res => {
      message.success()
      handleClear()
    })
  }

  return (
    <div style={{ padding: '30px', paddingBottom: '0' }}>
      <div style={{ background: 'white', marginBottom: '20px' }}>
        <Row>
          <Title text='项目基本信息'>
            <Select style={{ width: '180px' }} placeholder='请选择项目模板' value={projectTempSelect} onChange={e => setProjectTempSelect(e)}>
              {
                projectTempOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Title>
          <Col span={24} style={{ padding: '15px 30px' }}>
            <Row gutter={[0, 30]}>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>项目类型</p>
                <Select style={{ width: '280px' }} placeholder='请选择项目类型' value={projectTypeSelect} onChange={e => handleChangeType(e)}>
                  {
                    projectTypeOption.map((i, index) => (
                      i.value != 3 ? <Select.Option key={index} value={i.value}>{i.text}</Select.Option> : null
                    ))
                  }
                </Select>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>项目名称</p>
                <Input style={{ width: '280px' }} placeholder='请输入项目名称' value={nameText} onChange={e => setNameText(e.target.value)}></Input>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>项目编号</p>
                <Input style={{ width: '280px' }} placeholder='请输入项目编号' value={codeText} onChange={e => setCodeText(e.target.value)}></Input>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>生效日期</p>
                <DatePicker.RangePicker style={{ width: '280px' }} value={date} onChange={e => setDate(e)}></DatePicker.RangePicker>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>预警日期</p>
                <DatePicker style={{ width: '280px' }} placeholder='请选择预警日期' value={warnDate} onChange={e => setWarnDate(e)}></DatePicker>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>项目区域</p>
                <Input style={{ width: '280px' }} placeholder='请输入项目区域' value={areaText} onChange={e => setAreaText(e.target.value)}></Input>
              </Col>
              {
                projectTypeSelect === '1' ?
                  <Col span={8}>
                    <p style={{ lineHeight: '30px' }}>DSR平均分标准</p>
                    <Input style={{ width: '280px' }} placeholder='请输入DSR平均分标准' value={dsrAvgScore} onChange={e => setDsrAvgScore(e.target.value)}></Input>
                  </Col>
                :null
              }
              {
                projectTypeSelect === '1' ?
                  <Col span={8}>
                    <p style={{ lineHeight: '30px' }}>DSR工单数量标准字段</p>
                    <Input style={{ width: '280px' }} placeholder='请输入DSR平均分标准' value={dsrTaskCount} onChange={e => setDsrTaskCount(e.target.value)}></Input>
                  </Col>
                :null
              }
            </Row>
          </Col>
        </Row>
      </div>
      {
        projectTypeSelect === '1' || projectTypeSelect === '2' || projectTypeSelect === '3' || projectTypeSelect === '4' ?
          <div style={{ background: 'white', padding: '10px 0', marginBottom: '20px' }}>
            <Row>
              <Title text='人员导入' />
              <Col span={24} style={{ padding: '30px' }}>
                <p span={24} style={{ lineHeight: '30px' }}>本期人员</p>
                <Col span={24}>
                  <Select disabled style={{ width: '280px' }} placeholder='请选择人员' value={peopleSelect} onChange={e => setPeopleSelect(e)}>
                    {
                      peopleOption.map((i, index) => (
                        <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                      ))
                    }
                  </Select>
                  {/* <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/control/people')}><EditOutlined />编辑</span> */}
                </Col>
              </Col>
            </Row>
          </div>
          : null
      }
      {
        projectTypeSelect === '1' ?
          <div style={{ background: 'white', padding: '10px 0', marginBottom: '20px' }}>
            <Row>
              <Title text='门店导入' />
              <Col span={24} style={{ padding: '30px' }}>
                <p span={24} style={{ lineHeight: '30px' }}>本期门店</p>
                <Col span={24}>
                  <Select disabled style={{ width: '280px' }} placeholder='请选择门店' value={shopSelect} onChange={e => setShopSelect(e)}>
                    {
                      shopOption.map((i, index) => (
                        <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                      ))
                    }
                  </Select>
                  {/* <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/set/shop')}><EditOutlined />编辑</span> */}
                </Col>
              </Col>
            </Row>
          </div>
          : null
      }
      {
        projectTypeSelect === '3' || projectTypeSelect === '2' ?
          <div style={{ background: 'white', padding: '10px 0', marginBottom: '20px' }}>
            <Row>
              <Title text='指标导入' />
              <Col span={24} style={{ padding: '30px' }}>
                <p span={24} style={{ lineHeight: '30px' }}>本期指标</p>
                <Col span={24}>
                  <Select disabled style={{ width: '280px' }} placeholder='请选择指标' value={targetSelect} onChange={e => setTargetSelect(e)}>
                    {
                      targetOption.map((i, index) => (
                        <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                      ))
                    }
                  </Select>
                  {/* <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/set/target')}><EditOutlined />编辑</span> */}
                </Col>
              </Col>
            </Row>
          </div>
          : null
      }
      {
        projectTypeSelect === '3' || projectTypeSelect === '2' ?
          <div style={{ background: 'white', padding: '10px 0', marginBottom: '20px' }}>
            <Row>
              <Title text='位置导入' />
              <Col span={24} style={{ padding: '30px' }}>
                <p span={24} style={{ lineHeight: '30px' }}>本期位置</p>
                <Col span={24}>
                  <Select disabled style={{ width: '280px' }} placeholder='请选择位置' value={locationSelect} onChange={e => setLocationSelect(e)}>
                    {
                      locationOption.map((i, index) => (
                        <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                      ))
                    }
                  </Select>
                  {/* <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/set/location')}><EditOutlined />编辑</span> */}
                </Col>
              </Col>
            </Row>
          </div>
          : null
      }
      {
        projectTypeSelect === '1' ?
          <div style={{ background: 'white', padding: '10px 0', marginBottom: '20px' }}>
            <Row>
              <Title text='导入产品' />
              <Col span={24} style={{ padding: '30px' }}>
                <p span={24} style={{ lineHeight: '30px' }}>本期产品</p>
                <Col span={24}>
                  <Select disabled style={{ width: '280px' }} placeholder='请选择产品' value={productSelect} onChange={e => setProductSelect(e)}>
                    {
                      productOption.map((i, index) => (
                        <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                      ))
                    }
                  </Select>
                  {/* <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/set/product')}><EditOutlined />编辑</span> */}
                </Col>
              </Col>
            </Row>
          </div>
          : null
      }
      {
        // projectTypeSelect === '1' ?
        //   <div style={{ background: 'white', padding: '10px 0', marginBottom: '20px' }}>
        //     <Row>
        //       <Title text='算分公式' />
        //       <Col span={24} style={{ padding: '30px' }}>
        //         <p span={24} style={{ lineHeight: '30px' }}>本期算分公式</p>
        //         <Col span={24}>
        //           <Select disabled style={{ width: '280px' }} placeholder='请选择算分公式' value={computeTempSelect} onChange={e => setComputeTempSelect(e)}>
        //             {
        //               computeTempOption.map((i, index) => (
        //                 <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
        //               ))
        //             }
        //           </Select>
        //           {/* <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/set/computetemplate')}><EditOutlined />编辑</span> */}
        //         </Col>
        //       </Col>
        //     </Row>
        //   </div>
        //   : null
      }
      {
        projectTypeSelect ?
          <div style={{ background: 'white', padding: '10px 0', marginBottom: '20px' }}>
            <Row>
              <Title text='一审自动通过设置' />
              <Col span={24} style={{ padding: '30px' }}>
                <Col span={24}>
                  <Checkbox checked={isAutoAudit1Passed == 1 ? true : false} onChange={e => e.target.checked ? setisAutoAudit1Passed(1) : setisAutoAudit1Passed(0)}>是否需要一审自动通过</Checkbox>
                </Col>
              </Col>
            </Row>
          </div>
          : null
      }
      {
        projectTypeSelect ?
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={handleSave}>修改</Button>
            <Button style={{ margin: '0 10px' }} onClick={handleClear}>重置</Button>
          </div>
          : null
      }
    </div>
  )
}

export default App