
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import PopConfirmOnly from '@/utils/PopConfirmOnly'
import file from '@/assets/rydrmb.xlsx'



function App(props) {

  const { push } = useHistory()

  const codeMap = {
    3: '人员管理',
    2: '实效',
    1: '家化'
  }

  const handleShowEdit = e => {
    setModalVisible('edit')
    setDataAdd(e)
    // setNameAdd(e.name)
    // setCodeAdd(e.code)
    // setIdAdd(e.id)
    // setTypeAdd(e.projectType)
  }
  // table
  const [columns, setColumns] = useState([
    {
      title: '人员组名称/编号',
      dataIndex: 'name',
      width: 240,
      render: (text, record) => (
        <>
          <p style={{ color: '#2A82E4' }}>{record.name}</p>
          <p>{record.code}</p>
        </>
      )
    },
    {
      title: '项目类型',
      dataIndex: 'projectType',
      render: text => codeMap[text]
    },
    {
      title: '创建人员',
      dataIndex: 'createdByName',
      render: text => text ? text : '-'
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: text => text ? text : '-',
      sorter: (a, b) => a.createdAt.split('-').join('') - b.createdAt.split('-').join('')
    },
    {
      title: '修改时间',
      dataIndex: 'updatedAt',
      render: text => text ? text : '-',
      sorter: (a, b) => a.updatedAt.split('-').join('') - b.updatedAt.split('-').join('')
    },
    {
      title: '操作',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => push('/home/control/peopledetail/' + record.projectType + '-' + record.id)}>查看</Button>
          <Divider type='vertical' />
          <Button size='small' type='link' onClick={e => handleShowEdit(record)}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition = []
    if (nameSelect) queryCondition.push('LIKE:name=' + nameSelect)
    if (peopleSelect) queryCondition.push('EQ:createdBy=' + peopleSelect)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:createdAt=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:createdAt=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }

    axios.get('/api/common/user/group/list', {
      params: {
				pageSize: 1000000,
        queryCondition: queryCondition.join(',')
      }
    }).then(res => {
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr.filter(v => {
        return v.status != -1
      }))
    })
  }
  useEffect(() => {
    getData()
  }, [updateFlag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  //select
  const [dateSelect, setDateSelect] = useState([])
  const [nameSelect, setNameSelect] = useState('')
  const [peopleSelect, setPeopleSelect] = useState(null)
  const [peopleOption, setPeopleOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/user/list', { params: { orgId: 1 } }).then(res => {
      setPeopleOption(res.data.data)
    })
  }, [])
  const clearSelect = e => {
    setDateSelect([])
    setNameSelect('')
    setPeopleSelect(null)
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setDataAdd({})
    setFileListAdd([])
  }
  //
  // 添加
  const [dataAdd, setDataAdd] = useState({})
  const handleChangeDataAdd = (type, value) => {
    const target = Object.assign({}, dataAdd, { [type]: value })
    setDataAdd(target)
  }
  const [fileListAdd, setFileListAdd] = useState([])
  const handleAdd = e => {
    if (dataAdd.name && dataAdd.code && dataAdd.projectType) {
      const formData = new FormData()
      formData.append('file', fileListAdd[0])
      axios.post('/api/common/user/group/import', formData,
        {
          params: dataAdd,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(res => {
          message.success()
          handleModalCancel()
          getData()
        })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const handleEdit = e => {
    if (dataAdd.name && dataAdd.code && dataAdd.projectType) {
      const target = Object.assign({}, dataAdd)
      delete target.key
      axios.put('/api/common/user/group', target, { params: { groupId: target.id } }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const handleEnsure = e => {
    if (modalVisible === 'add') handleAdd()
    if (modalVisible === 'edit') handleEdit()
  }
  const uploadProps = {
    onRemove: (file) => {
      setFileListAdd([])
      return []
    },
    beforeUpload: (file) => {
      setFileListAdd([file])
      return false
    }
  }

  //删除
  const handleDelete = e => {
    axios.delete('/api/common/user/group', { params: { groupId: e } }).then(res => {
      message.success()
      getData()
    })
  }
  const handleDeleteMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.delete('/api/common/user/group/batch', { data: selectedRowKeys }).then(res => {
      message.success()
      handleClearSelect()
      getData()
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
          <Col>
            <span style={{ lineHeight: '30px' }}>人员组名称/编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请输入' value={nameSelect} onChange={e => setNameSelect(e.target.value)}></Input>
          </Col>
          <Col>
            <span style={{ lineHeight: '30px' }}>创建人员</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} placeholder='全选' value={peopleSelect} onChange={e => { setPeopleSelect(e) }}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                peopleOption.map(i => (
                  <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('add')}><PlusOutlined />添加组</Button>
            <PopConfirmOnly confirm={handleDeleteMore}>
              <Button style={{ margin: '0 10px' }} >批量删除</Button>
            </PopConfirmOnly>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger:true }}
        />

        <Modal
          width={640}
          title={modalVisible === 'add' ? '添加组' : '修改组'}
          visible={modalVisible === 'add' || modalVisible === 'edit'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleEnsure}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>人员组名称:</Col>
            <Col span={14}><Input value={dataAdd.name} onChange={e => handleChangeDataAdd('name', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>编号:</Col>
            <Col span={14}><Input value={dataAdd.code} onChange={e => handleChangeDataAdd('code', e.target.value)}></Input></Col>
            {
              modalVisible === 'add' ?
                <>
                  <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>项目类型:</Col>
                  <Col span={14}>
                    <Select style={{ width: '100%' }} value={dataAdd.projectType} onChange={e => handleChangeDataAdd('projectType', e)}>
                      <Select.Option value={1}>家化</Select.Option>
                      <Select.Option value={2}>实效</Select.Option>
                      <Select.Option value={3}>人员管理</Select.Option>
                    </Select>
                  </Col>
                  <Col span={6} style={{ textAlign: 'right' }}>批量上传:</Col>
                  <Col span={14}>
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>上传文件</Button>
                    </Upload>
                  </Col>
                  <Col span={4}><Button onClick={e => window.open(file)}>下载模板</Button></Col>
                </>
                : null
            }
          </Row>
        </Modal>

      </div>
    </div>
  )
}

export default App