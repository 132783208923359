import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { stringToNumber, filterSet } from '@/utils/common'
import { baseUrl } from '@/utils/config'
import store from '@/redux/store'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const handleShowPlan = e => {
    setModalVisible('plan')
    setTaskId(e.id)
  }

  const handleShowEdit = e => {
    setModalVisible('edit')
    setTaskId(e.id)
    setAudit(e.audit2ById)
    setReview(e.reviewById)
    setRemark(e.assignRemark)
  }

  // table
  const [columns, setColumns] = useState([
    {
      title: '调研编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '调研对象',
      dataIndex: 'surveyTarget',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    }, {
      title: '访问员',
      dataIndex: 'principal',
      render: text => text ? text : '-',
      sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      render: text => text.display ? text.display : '-',
      sorter: (a, b) => a.status.display?.localeCompare(b.status.display),
    },
    {
      title: '一审',
      dataIndex: 'agent',
      render: text => text ? text : '-',
    },
    {
      title: '二审',
      dataIndex: 'audit2ByName',
      render: (text,record) => text&& record.status.value==21? text : '-'
    },
    {
      title: '复审',
      dataIndex: 'reviewByName',
      render: (text,record) => text&& record.status.value==21? text : '-'
    },
    {
      title: '开始时间',
      dataIndex: 'workAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
        a.workAt?a.workAt=a.workAt:a.workAt='1'
        b.workAt?b.workAt=b.workAt:b.workAt='1'
        return a.workAt?.localeCompare(b.workAt)
      },
    },
    {
      title: '结束时间',
      dataIndex: 'doneAt',
      render: text => text && text!='1' ? text.split('.')[0] : '-',
      sorter: (a, b) => {
          a.doneAt?a.doneAt=a.doneAt:a.doneAt='1'
          b.doneAt?b.doneAt=b.doneAt:b.doneAt='1'
          return a.doneAt?.localeCompare(b.doneAt)
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          {
            record.status.value === 2 ?
              <Button type='link' size='small' onClick={e => handleShowPlan(record)}>安排</Button>
              : null
          }
          {
            record.status.value === 21 ?
              <Button type='link' size='small' onClick={e => handleShowEdit(record)}>修改</Button>

              : null
          }
        </>
      )
    },
  ])
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const [surveyCode, setsurveyCode] = useState(null)
  const getData = e => {
    const queryCondition = []
    queryCondition.push(planSelect ? 'IN:status=' + planSelect : 'IN:status=2!21')
    queryCondition.push('EQ:projectId=' + projectid)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:workAt=' + moment(dateSelect[0]).format('YYYY-MM-DD'))
      queryCondition.push('LTE:workAt=' + moment(dateSelect[1]).format('YYYY-MM-DD'))
    }
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (scopeSelect) queryCondition.push('EQ:surveyScope=' + scopeSelect)
    if (surveyCode) queryCondition.push('LIKE:surveyCode=' + surveyCode)

    axios.get('/api/core/task/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(','),
        userId:store.getState().user.uid
      }
    }).then(res => {
      setTotal(res.data.totalSize)
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      console.log(arr)
      setDataSource(arr)
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])

  useEffect(() => {
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'shixiao_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'shixiao'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [updateFlag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    console.log(e)
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [taskStatusOption, setTaskStatusOption] = useState([])
  const [audit2Option, setAudit2Option] = useState([])
  const [reviewOption, setReviewOption] = useState([])
  const [scopeOption, setScopeOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/taskStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setTaskStatusOption(arr)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'shixiao_checker',
          groupId: res.data.userGroupId,
        }
      }).then(res => {
        setAudit2Option(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'shixiao_reviewer',
          groupId: res.data.userGroupId,
        }
      }).then(res => {
        setReviewOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'shixiao_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'shixiao'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
  }, [])


  // select
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [dateSelect, setDateSelect] = useState([])
  const [statusSelect, setStatusSelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [planSelect, setPlanSelect] = useState(null)

  const clearSelect = e => {
    setDateSelect([])
    setStatusSelect(null)
    setScopeSelect(null)
    setSurveyorSelect(null)
    setPlanSelect(null)
    setsurveyCode(null)
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setTaskId(null)
    setAudit(null)
    setReview(null)
    setRemark('')
  }
  const [audit, setAudit] = useState(null)
  const [review, setReview] = useState(null)
  const [remark, setRemark] = useState('')
  const [taskId, setTaskId] = useState(null)
  // 安排
  const handlePlan = e => {
    axios.post('/api/core/taskAudit/assign', {
      assignRemark: remark,
      audit2ById: audit,
      reviewById: review
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      handleModalCancel()
      message.success()
      getData()
    })
  }
  //批量安排
  const handlePlanMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.post('/api/core/taskAudit/batchAssign', {
      assignRemark: remark,
      audit2ById: audit,
      reviewById: review
    }, {
      params: {
        taskIds: selectedRowKeys.join(',')
      }
    }).then(res => {
      handleModalCancel()
      message.success()
      getData()
    })
  }
  //修改
  const handleEdit = e => {
    axios.post('/api/core/taskAudit/assignUpdate', {
      assignRemark: remark,
      audit2ById: audit,
      reviewById: review
    }, {
      params: {
        taskId: taskId
      }
    }).then(res => {
      handleModalCancel()
      message.success()
      getData()
    })
  }
  //批量修改
  const handleEditMore = e => {
    if (selectedRowKeys.length === 0) return
    let count = 0
    selectedRowKeys.forEach(v=>{
      axios.post('/api/core/taskAudit/assignUpdate', {
        assignRemark: remark,
        audit2ById: audit,
        reviewById: review
      }, {
        params: {
          taskId: v
        }
      })
      .catch(e=>{
        message.success('修改失败')
        count++
      })
    })
    setTimeout(()=>{
      if(count == 0){
        handleModalCancel()
        message.success()
        getData()
      }else{
        handleModalCancel()
        message.error('批量修改中有'+count+'项错误')
        getData()
      }
    })
  }
  //安排判断
  const handleDoPlan = e => {
    if (modalVisible === 'plan') handlePlan()
    if (modalVisible === 'planMore') handlePlanMore()
    if (modalVisible === 'edit') handleEdit()
    if (modalVisible === 'editMore') handleEditMore()
  }
  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:status=2!21')
    queryCondition.push('EQ:projectId=' + projectid)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:planStartAt=' + moment(dateSelect[0]).format('YYYY-MM-DD'))
      queryCondition.push('LTE:planEndAt=' + moment(dateSelect[1]).format('YYYY-MM-DD'))
    }
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (scopeSelect) queryCondition.push('EQ:surveyScope=' + scopeSelect)

    axios.get('/api/core/taskAudit/export', {
      params: {
        pageSize: 1000000,
        taskAuditExportType: 7,
        queryCondition: queryCondition.join(',')
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出安排.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
              <span style={{ lineHeight: '30px' }}> 调研编号</span>
              <Input value={surveyCode} onChange={(e) => { setsurveyCode(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>调研大区</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={scopeSelect} showSearch onChange={e => setScopeSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                scopeOption.map((i, index) => (
                  <Select.Option key={index} value={i}>{i}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>访问员</span>
            <Select 
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
             style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                surveyorOption.map((i, index) => (
                  <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>任务状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={statusSelect} onChange={e => setStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                taskStatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
            }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>安排状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} value={planSelect} onChange={e => setPlanSelect(e)}>
                  <Select.Option value={''}>取消选择</Select.Option>
                  <Select.Option value='21'>已安排</Select.Option>
              <Select.Option value='2'>未安排</Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('planMore')}>批量安排</Button>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>批量导出</Button>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('editMore')}>批量修改</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
          }
          }}
        />

        <Modal
          width={640}          
          title={modalVisible === 'edit' || modalVisible === 'editMore' ? '审核修改' : '审核安排'}
          visible={modalVisible === 'plan' || modalVisible === 'planMore' || modalVisible === 'edit' || modalVisible === 'editMore'}
          onCancel={handleModalCancel}
          destroyOnClose
          maskClosable={false}
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleDoPlan}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            {/*
              <Col span={6} style={{ textAlign: 'right' }}>抽样比例:</Col>
              <Col span={14}>
                <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} /> 
              </Col>
            */}
            <Col span={6} style={{ textAlign: 'right' }}>选择二审:</Col>
            <Col span={14}>
              <Select style={{ width: '100%' }} value={audit} onChange={e => {  setAudit(e) }}>
                {
                  audit2Option.map((i, index) => (
                    <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                  ))
                }
              </Select>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>选择复核:</Col>
            <Col span={14}>
              <Select style={{ width: '100%' }} value={review} onChange={e => setReview(e)}>
                {
                  reviewOption.map((i, index) => (
                    <Select.Option value={i.id} key={i.id}>{i.name}</Select.Option>
                  ))
                }
              </Select>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>备注:</Col>
            <Col span={14}>
              <Input style={{ width: '100%' }} value={remark} onChange={e => setRemark(e.target.value)} />
            </Col>
          </Row>
        </Modal>

      </div>
    </div>
  )
}

export default App