import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu, Dropdown, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';

function App(props) {
  const chartRef_bottom = useRef(null)
  const chartRef_left = useRef(null)
  const chartRef_right = useRef(null)

  useEffect(() => {
    if (!chartRef_bottom.current) {
      const myChart = echarts.init(document.getElementById('bottom'))
      chartRef_bottom.current = myChart
    }
    if (!chartRef_left.current) {
      const myChart = echarts.init(document.getElementById('left'))
      chartRef_left.current = myChart
    }
    if (!chartRef_right.current) {
      const myChart = echarts.init(document.getElementById('right'))
      chartRef_right.current = myChart
    }

    chartRef_left.current.setOption({
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        left: 10,
        data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
      },
      series: [
        {
          name: '访问来源',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: 335, name: '直接访问' },
            { value: 310, name: '邮件营销' },
            { value: 234, name: '联盟广告' },
            { value: 135, name: '视频广告' },
            { value: 1548, name: '搜索引擎' }
          ]
        }
      ]
    })

    chartRef_right.current.setOption({
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line'
      }],
      color: ['#7c4bd8']
    })

    chartRef_bottom.current.setOption({
      legend: {
        bottom: '0'
      },
      tooltip: {},
      dataset: {
        dimensions: ['name', '完成数量', '退回数量', '录入数量', '修改数量'],
        source: [
          { name: 'Matcha Latte', '完成数量': 43.3, '退回数量': 85.8, '录入数量': 93.7, '修改数量': 120 },
          { name: 'Milk Tea', '完成数量': 83.1, '退回数量': 73.4, '录入数量': 55.1, '修改数量': 120 },
          { name: 'Cheese Cocoa', '完成数量': 86.4, '退回数量': 65.2, '录入数量': 82.5, '修改数量': 120 },
          { name: 'Walnut Brownie', '完成数量': 72.4, '退回数量': 53.9, '录入数量': 39.1, '修改数量': 120 }
        ]
      },
      xAxis: { type: 'category' },
      yAxis: {},
      series: [
        { type: 'bar', },
        { type: 'bar' },
        { type: 'bar' },
        { type: 'bar' }
      ],
      color: ['#3c90f7', '#55bfc0', '#5ebe67', '#f4cd49']
    })
  }, [props.data])

  const resize = e => {
    if (chartRef_bottom.current)
      chartRef_bottom.current.resize()
    if (chartRef_left.current)
      chartRef_left.current.resize()
    if (chartRef_right.current)
      chartRef_right.current.resize()
  }
  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <Row>
      <Col span={12}>
        <div id="left" style={{ width: '100%', height: '300px' }}></div>
      </Col>
      <Col span={12}>
        <div id="right" style={{ width: '100%', height: '300px' }}></div>
      </Col>
      <Col span={24}>
        <div id="bottom" style={{ width: '100%', height: '320px' }}></div>
      </Col>
    </Row>
  );
}

export default App;