import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';

function App(props) {
    const chartRef = useRef(null)

    const chartInit = e => {
        const myChart = echarts.init(document.getElementById('main'))
        //窗口大小改变时，触发chart实例的resize事件
        //绘制图表
        chartRef.current = myChart
        myChart.setOption({
            xAxis: {
                type: 'category',
                data: ['7月1', '7月1', '7月1', '7月1', '7月1']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: [65, 45, 53, 65, 24, 42, 77],
                type: 'line',
                color: 'rgba(62, 97, 155, 1)'
            }],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
        })
    }
    //初始化
    useEffect(() => {
        setTimeout(() => {
            chartInit()
        }, 0);
    }, [])

    const resize = e => {
        if (chartRef.current)
            chartRef.current.resize()
    }
    useEffect(() => {
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])
    //绘制图表


    return (
        <>
            <div id="main" style={{ width: '100%', height: '250px' }}></div>
        </>
    );

}

export default App;