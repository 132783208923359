import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const handleShowPlan = e => {
    setModalVisible('plan')
    setTaskId(e.id)
  }

  const handleShowPlanMove = e => {
    setModalVisible('planMove')
  }

  // table
  const columns = [{
    title: '调研编号',
    dataIndex: 'surveyCode',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
  },
  {
    title: '调研大区',
    dataIndex: 'surveyScope',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
  }, {
    title: '访问员',
    dataIndex: 'principal',
    render: text => text ? text : '-',
    sorter: (a, b) => a.principal?.localeCompare(b.principal),
  },
  {
    title: '代理人',
    dataIndex: 'agent',
    render: text => text ? text : '-',
    sorter: (a, b) => a.agent?.localeCompare(b.agent),
  },
  {
    title: '扫dsr审核人员',
    dataIndex: 'dsrAuditByName',
    render: text => text ? text : '-'
  },
  {
    title: '门店类型',
    dataIndex: 'shopLevel',
    render: text => text ? text : '-',
    sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
  },
  {
    title: '调研对象',
    dataIndex: 'surveyTarget',
    render: text => text ? text : '-',
    sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
  },
  {
    title: '安排时间',
    dataIndex: 'assignAt',
    render: text => text ? text : '-',
    sorter: (a, b) => a.assignAt?.localeCompare(b.assignAt),
  },
  {
    title: '总退回次数',
    dataIndex: 'refusedCount',
    render: text => text ? text : '0',
    sorter: (a, b) => a.refusedCount-b.refusedCount,
  },
  {
    title: '考核得分',
    dataIndex: 'checkScore',
    render: text => text ? (text * 100)?.toFixed(2) + '%' : '-',
    sorter: (a, b) => a.checkScore - b.checkScore,
  },
  {
    title: '木糖醇标记',
    dataIndex: 'xylitolFlag',
    key: 'xylitolFlag',
    sorter: (a, b) => a.xylitolFlag-b.xylitolFlag,
    render: (text, record) => (
      <>
        {
          text ? text : '- -'
        }
      </>
    )
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    render: (text, record) => (
      record.dsrAuditById ?
        <Button type='link' size='small' onClick={e => handleShowPlan(record)}>修改</Button>
        :
        <Button type='link' size='small' onClick={e => handleShowPlan(record)}>安排</Button>
    )
  },
  ]
  //
  // 查看详情函数
  const push_page = e => {
    store.dispatch({
      type: 'selectCache_init', value: {
        page: 'jh-checkagain',
        select: {
          surveyorSelect,
          scopeSelect,
          dsrSelect,
          codeSelect,
          dataSource
        }
      }
    })
    push('/jh/' + projectid + '/check/particular/' + e.id)
  }

  // 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()
  useEffect(() => {
    if (store.getState().selectCache.page === 'jh-checkagain') {
      const data = store.getState().selectCache.select
      Promise.all([
        new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
        new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
        new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
        new Promise(r => setCodeSelect(data.codeSelect, () => r())),
        new Promise(r => setDataSource(data.dataSource, () => r())),
      ]).then(res => {
        setGetData_flag(true)
        store.dispatch({ type: 'selectCache_clear' })
      })
    } else {
      setGetData_flag(true)
      store.dispatch({ type: 'selectCache_clear' })
    }
  }, [])

  const [getData_flag, setGetData_flag] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition = []
    queryCondition.push('IN:taskReviewStatus=11')
    queryCondition.push('EQ:projectId=' + projectid)
    if (planStatusSelect == 1) queryCondition.push('NOTNULL:dsrAuditById=null')
    if (planStatusSelect == 2) queryCondition.push('NULL:dsrAuditById=null')
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (reviewStatusSelect) queryCondition.push('EQ:taskReviewStatus=' + reviewStatusSelect)
    if (scopeSelect) queryCondition.push('EQ:surveyScope=' + scopeSelect)
    if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
    if (audit2ByIdSelect) queryCondition.push('EQ:dsrAuditById=' + audit2ByIdSelect)

    axios.get('/api/core/task/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(','),
        userId: store.getState().user.uid
      }
    }).then(res => {
			setTotal(res.data.totalSize)
      let arr = res.data.data.map(i => { return { ...i, key: i.id } })
      if (mutangchunBool) {
        arr = arr.filter(v=>{
          return v.xylitolFlag!=''
        })
      }
      setDataSource(arr)
    })
  }
  useEffect(() => {
    if (store.getState().user.uid)
      if (!getData_flag) return
    getData()
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_vistor',
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
    })
  }, [store.getState().user.uid, updateFlag, getData_flag])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [surveyorOption, setSurveyorOption] = useState([])
  const [audit1StatusOption, setAudit1StatusOption] = useState([])
  const [audit2StatusOption, setAudit2StatusOption] = useState([])
  const [reviewStatusOption, setReviewStatusOption] = useState([])
  const [audit2Option, setAudit2Option] = useState([])
  const [reviewOption, setReviewOption] = useState([])
  const [scopeOption, setScopeOption] = useState([])
  const [proxyOption, setProxyOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/taskAudit1Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit1StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskAudit2Status').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setAudit2StatusOption(arr)
    })
    axios.get('/api/sys/dict/taskReviewStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return {
          text: i[1],
          value: i[0]
        }
      })
      setReviewStatusOption(arr)
    })
    axios.get('/api/core/project/' + projectid).then(res => {
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_checker',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setAudit2Option(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          pageSize: 1000000,
          roleCode: 'jiahua_reviewer',
          groupId: res.data.userGroupId
        }
      }).then(res => {
        setReviewOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_vistor',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setSurveyorOption(res.data.data)
      })
      axios.get('/api/sys/user/list', {
        params: {
          roleCode: 'jiahua_proxy',
          pageSize: 1000000,
          groupId: res.data.userGroupId,
          orgCode: 'jiahua'
        }
      }).then(res => {
        setProxyOption(res.data.data)
      })
    })
    axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
      setScopeOption(res.data)
    })
  }, [])


  // select
  const [surveyorSelect, setSurveyorSelect] = useState(null)
  const [scopeSelect, setScopeSelect] = useState(null)
  const [proxySelect, setProxySelect] = useState(null)
  const [dsrSelect, setDsrSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [firstStatusSelect, setFirstStatusSelect] = useState(null)
  const [secondStatusSelect, setSecondStatusSelect] = useState(null)
  const [reviewStatusSelect, setReviewStatusSelect] = useState(null)
  const [planStatusSelect, setPlanStatusSelect] = useState(null)
  const [audit2ByIdSelect, setAudit2ByIdSelect] = useState(null)

  const clearSelect = e => {
    setScopeSelect(null)
    setDsrSelect('')
    setCodeSelect('')
    setAudit2ByIdSelect(null)
    setFirstStatusSelect(null)
    setSecondStatusSelect(null)
    setReviewStatusSelect(null)
    setSurveyorSelect(null)
    setUpdateFlag(pre => pre + 1)
    setProxySelect(null)
    setPlanStatusSelect(null)
    setMutangchunBool(false)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(false)
  const handleModalCancel = e => {
    setModalVisible(false)
    setReviewRefusedType(null)
    setTaskId(null)
  }

  const [taskId, setTaskId] = useState(null)

  //导出
  const handleExport = e => {
    const queryCondition = []
    queryCondition.push('IN:taskReviewStatus=1')
    queryCondition.push('EQ:projectId=' + projectid)
    if (store.getState().user.uid != 4)
      queryCondition.push('EQ:reviewById=' + store.getState().user.uid)
    if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
    if (proxySelect) queryCondition.push('EQ:agentId=' + proxySelect)
    if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
    if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
    if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
    if (secondStatusSelect) queryCondition.push('EQ:taskAudit2Status=' + secondStatusSelect)
    if (reviewStatusSelect) queryCondition.push('EQ:taskReviewStatus=' + reviewStatusSelect)
    if (scopeSelect) queryCondition.push('EQ:surveyScope=' + scopeSelect)
    if (audit2ByIdSelect) queryCondition.push('LIKE:dsrAuditById=' + audit2ByIdSelect)

    axios.get('/api/core/jhTaskAudit/export', {
      params: {
        pageSize: 1000000,
        taskAuditExportType: 6,
        queryCondition: queryCondition.join(',')
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出门店.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  const ExportPlop = e => {
    axios.get('/api/core/project/export/taskData', {
      params: {
        projectId: projectid
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出扫码数据.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  const [reviewRefusedType, setReviewRefusedType] = useState(null)

  const [dsrAuditById, setDsrAuditById] = useState(null)
  const [dsrAuditByName, setDsrAuditByName] = useState(null)

  const handleDoPlan = e => {
    if (e == 'plan') plan()
    if (e == 'planMove') planMove()
  }

  const plan = () => {
    axios.post('/api/core/jhTaskAudit/dsrAssign', {
      dsrAuditById,
      dsrAuditByName: audit2Option.filter(v => {
        return v.id == dsrAuditById
      })[0].name
    }, {
      params: {
        taskId: taskId
      }
    })
      .then(res => {
        getData()
        setModalVisible(null)
      })
  }
  const planMove = () => {
      if (selectedRowKeys.length === 0) return
      let count = 0
      selectedRowKeys.forEach(v=>{
        axios.post('/api/core/jhTaskAudit/dsrAssign', {
          dsrAuditById,
        }, {
          params: {
            taskId: v
          }
        })
        .catch(e=>{
          message.success('修改失败')
          count++
        })
      })
      setTimeout(()=>{
        if(count == 0){
          handleModalCancel()
          message.success()
          getData()
        }else{
          handleModalCancel()
          message.error('批量修改中有'+count+'项错误')
          getData()
        }
      })
  }

  const [pageSize, setPageSize] = useState(5)//显示数据数量
  const [page, setPage] = useState(1)//数据页数
  const [total, setTotal] = useState(null)//数据总是

  useEffect(() => {
    getData()
  }, [pageSize, page])

  const [ mutangchunBool,setMutangchunBool] = useState(false)

  return (<div style={{ padding: '30px' }} >
    <div style={{ padding: '30px', background: 'white' }} >

      <Row gutter={[20, 20]} style={{ marginBottom: '20px' }} >
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>调研编号</span>
          <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>调研大区</span>
          <AutoComplete
            onChange={e => { console.log(e); setScopeSelect(e); }}
            style={{ width: '240px', marginLeft: '10px' }}
            options={scopeOption.map(v => { return { value: v } })}
            value={scopeSelect}
          />
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>访问员</span>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
              surveyorOption.map((i, index) => (
                <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>代理人</span>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '25px' }} value={proxySelect} onChange={e => setProxySelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
              proxyOption.map((i, index) => (
                <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>DSR审核</span>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } style={{ width: '240px', marginLeft: '10px' }} value={audit2ByIdSelect} onChange={e => setAudit2ByIdSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
              audit2Option.map((i, index) => (
                <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>一审状态</span>
          <Select style={{ width: '240px', marginLeft: '10px' }} value={firstStatusSelect} onChange={e => setFirstStatusSelect(e)} >
            <Select.Option value={''}>取消选择</Select.Option>
            {
              audit1StatusOption.map((i, index) => <Select.Option key={index} value={i.value}>{i.text}</Select.Option>)
            }
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }} >二审状态</span>
          <Select style={{ width: '240px', marginLeft: '10px' }} value={secondStatusSelect} onChange={e => setSecondStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
              audit2StatusOption.map((i, index) => <Select.Option key={index} value={i.value} >{i.text}</Select.Option>)
            }
          </Select>
        </Col>
        <Col span={8} >
          <span style={{ lineHeight: '30px' }} >复核状态</span>
          <Select style={{ width: '240px', marginLeft: '10px' }} value={reviewStatusSelect} onChange={e => setReviewStatusSelect(e)} >
            <Select.Option value={''}>取消选择</Select.Option>
            {
              reviewStatusOption.map((i, index) => <Select.Option key={index} value={i.value} >{i.text}</Select.Option>)
            }
          </Select>
        </Col>
        <Col span={8} >
          <span style={{ lineHeight: '30px' }} >安排状态</span>
          <Select style={{ width: '240px', marginLeft: '10px' }} value={planStatusSelect} onChange={e => setPlanStatusSelect(e)} >
            <Select.Option value={''}>取消选择</Select.Option>
            <Select.Option key={1} value={1} >已安排</Select.Option>
            <Select.Option key={2} value={2} >未安排</Select.Option>
          </Select>
        </Col>
        <Col span={8}>
          <span style={{ lineHeight: '30px' }}>是否筛选木糖醇</span>
          <Checkbox checked={mutangchunBool} onChange={e=>setMutangchunBool(e.target.checked)}></Checkbox>
        </Col>
      </Row>
      <Row justify='space-between'
        style={{ marginBottom: '20px' }} >
        <Col >
          <Button style={{ margin: '0 10px' }} onClick={handleExport} >批量导出</Button>
          <Button style={{ margin: '0 10px' }} onClick={ExportPlop} >导出所有工单扫码数据</Button>
          <Button style={{ margin: '0 10px' }} onClick={e => { handleShowPlanMove() }} >批量安排</Button>
        </Col>
        <Col>
          <Button style={{ margin: '0 10px' }} type='primary' onClick={getData} >查询</Button>
          <Button style={{ margin: '0 10px' }} onClick={clearSelect} >重置</Button>
        </Col>
      </Row>

      {
        selectedRowKeys.length !== 0 ?
          <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }} >
            <Col >< Button danger type='primary' onClick={handleClearSelect} >清空</Button></Col >
            <Col >{'已选择' + selectedRowKeys.length + '项'}</Col>
          </Row>
          : null
      }
      <Table
        rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true, total, current: page, onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
          }
        }}
      />

      <Modal
        width={640}
        title={'审核安排'}
        visible={modalVisible == 'plan' || modalVisible == 'planMove'}
        onCancel={handleModalCancel}
        destroyOnClose
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleModalCancel}>取消</Button>,
          <Button key="submit" type="primary" onClick={e => { handleDoPlan(modalVisible) }}>确认</Button>,
        ]}
      >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
          <Col span={6} style={{ textAlign: 'right' }}>选择DSR审核:</Col>
          <Col span={14}>
            <Select style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              } value={dsrAuditById} onChange={e => { setDsrAuditById(e) }}>
              {
                audit2Option.map((i, index) => (
                  <Select.Option value={i.id} key={index}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
        </Row>
      </Modal>
    </div>
  </div>
  )
}

export default App