import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, InputNumber } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'


function App(props) {

    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    const handleShowBack = e => {
        setModalVisible('back')
        setTaskId(e.id)
    }
    const handleShowPass = e => {
        setModalVisible('pass')
        setTaskId(e.id)
    }

    // table
    const columns = [{
        title: '调研编号',
        dataIndex: 'surveyCode',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
        title: '调研大区',
        dataIndex: 'surveyScope',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    },
    {
        title: '访问员',
        dataIndex: 'principal',
        render: text => text ? text : '-',
        sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
        title: '调研对象',
        dataIndex: 'surveyTarget',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (< >
            <Button size='small'
                type='link'
                onClick={e => push_page(record)} > 查看 </Button>
            <Divider type='vertical' />
            <Button size='small'
                type='link'
                onClick={e => handleShowPass(record)} > 通过 </Button> <
                Divider type='vertical' />
            <Button size='small'
                type='link'
                onClick={e => handleShowBack(record)} > 退回 </Button>
        </>
        )
    }
    ]
    //
    const [getData_flag, setGetData_flag] = useState(false)

    const push_page = e => {
        store.dispatch({
            type: 'selectCache_init',
            value: {
                page: 'sx-checkfirst',
                select: {
                    surveyorSelect,
                    scopeSelect,
                    dsrSelect,
                    codeSelect,
                    targetSelect,
                }
            }
        })
        push('/sx/' + projectid + '/check/particular/' + e.id)
    }


    const [dataSource, setDataSource] = useState([])
    const [updateFlag, setUpdateFlag] = useState(0)
    const getData = e => {
        const queryCondition = []
        queryCondition.push('IN:taskAudit1Status=1')
        queryCondition.push('EQ:projectId=' + projectid)
        queryCondition.push('EQ:status=21')
        if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
        if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
        if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
        if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
        if (scopeSelect) queryCondition.push('EQ:surveyScope=' + scopeSelect)

        axios.get('/api/core/task/list', {
            params: {
                page,
                pageSize,
                queryCondition: queryCondition.join(','),
                userId: store.getState().user.uid,
                auditFlag: 1
            }
        }).then(res => {
            setTotal(res.data.totalSize)
            const arr = res.data.data.map(i => { return { ...i, key: i.id } })
            setDataSource(arr)
        })
    }

    const [pageSize,setPageSize] = useState(5)//显示数据数量
    const [page,setPage] = useState(1)//数据页数
    const [total,setTotal] = useState(null)//数据总是
  
    useEffect(()=>{
      getData()
    },[pageSize,page])
  

    const [labelValues, setlabelValues] = useState([])

    useEffect(() => {
        if (store.getState().selectCache.page === 'sx-checkfirst') {
            const data = store.getState().selectCache.select
            Promise.all([
                new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
                new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
                new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
                new Promise(r => setCodeSelect(data.codeSelect, () => r())),
                new Promise(r => setTargetSelect(data.targetSelect, () => r())),
            ]).then(res => {
                store.dispatch({ type: 'selectCache_clear' })
            })
        } else {
            store.dispatch({ type: 'selectCache_clear' })
        }
        axios.get('/api/sys/dict/' + 'sxTaskRefusedReasonType', {
            params: {
                pageSize: 1000000,
            }
        })
            .then(res => {
                setlabelValues(res.data.labelValues)
            })
    }, [])
    const [projectData, setprojectData] = useState({})

    useEffect(() => {
        if (store.getState().user.uid)
            getData()
        axios.get('/api/core/project/' + projectid).then(res => {
            axios.get('/api/sys/user/list', {
                params: {
                    pageSize: 1000000,
                    roleCode: 'shixiao_vistor',
                    groupId: res.data.userGroupId,
                    orgCode: 'shixiao'
                }
            }).then(res => {
                setSurveyorOption(res.data.data)
            })
            setprojectData(res.data)
        })
    }, [store.getState().user.uid, updateFlag, getData_flag])

    // table select
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const handleSelectChange = e => {
        setSelectedRowKeys(e)
    }
    const handleClearSelect = e => {
        setSelectedRowKeys([])
    }

    // option
    const [surveyorOption, setSurveyorOption] = useState([])
    const [audit1StatusOption, setAudit1StatusOption] = useState([])
    const [scopeOption, setScopeOption] = useState([])
    const [isbool, setIsbool] = useState(false)

    useEffect(() => {
        axios.get('/api/sys/dict/taskAudit1Status').then(res => {
            const arr = Object.entries(res.data.labelValues).map(i => {
                return {
                    text: i[1],
                    value: i[0]
                }
            })
            setAudit1StatusOption(arr)
        })
        axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
            setScopeOption(res.data)
        })
        axios.get('/api/core/project/' + projectid).then(res => {
            axios.get('/api/sys/user/list', {
                params: {
                    roleCode: 'shixiao_vistor',
                    pageSize: 1000000,
                    groupId: res.data.userGroupId,
                    orgCode: 'shixiao'
                }
            }).then(res => {
                setSurveyorOption(res.data.data)
            })
            axios.get('/api/sys/user/' + store.getState().user.uid)
                .then(resa => {
                    axios.get('/api/sys/user/group/org', {
                        params: {
                            id: store.getState().user.uid
                        }
                    })
                        .then(resc => {
                            resc.data.forEach((v, i) => {
                                if (v.groupId == res.data.userGroupId) {
                                    v.roles.forEach((v, i) => {
                                        if (v.name == '实效_管理员') {
                                            setIsbool(true)
                                        } else if (v.name == '超级管理员') {
                                            setIsbool(true)
                                        }
                                    });
                                }
                            });
                        })
                })
        })
    }, [])


    // select
    const [surveyorSelect, setSurveyorSelect] = useState(null)
    const [scopeSelect, setScopeSelect] = useState(null)
    const [dsrSelect, setDsrSelect] = useState('')
    const [targetSelect, setTargetSelect] = useState('')
    const [codeSelect, setCodeSelect] = useState('')
    const [firstStatusSelect, setFirstStatusSelect] = useState(null)

    const clearSelect = e => {
        setScopeSelect(null)
        setDsrSelect('')
        setTargetSelect('')
        setCodeSelect('')
        setFirstStatusSelect(null)
        setSurveyorSelect(null)
        setUpdateFlag(pre => pre + 1)
    }

    // 弹窗
    const [modalVisible, setModalVisible] = useState(null)
    const handleModalCancel = e => {
        setModalVisible(null)
        setRemark('')
        setTaskId(null)
        setType('')
        setisSelfCheck(null)
    }
    const [remark, setRemark] = useState('')
    const [taskId, setTaskId] = useState(null)
    //通过
    const handlePass = e => {
        axios.post('/api/core/taskAudit/audit1Passed', {
            audit1PassedReason: remark
        }, {
            params: {
                taskId: taskId
            }
        }).then(res => {
            message.success()
            handleModalCancel()
            getData()
        })
    }
    //批量通过
    const hanldePassMore = e => {
        if (selectedRowKeys.length === 0) return
        axios.post('/api/core/taskAudit/batchAudit1Passed', {
            audit1PassedReason: remark
        }, {
            params: {
                taskIds: selectedRowKeys.join(',')
            }
        }).then(res => {
            message.success()
            handleModalCancel()
            getData()
        })
    }
    // 退回
    const handleBack = e => {
        axios.post('/api/core/taskAudit/audit1Refused', {
            audit1RefusedReason: remark,
            audit1RefusedReasonType: Type,
            audit1RefusedRemark: remark,
            isSelfCheck
        }, {
            params: {
                taskId: taskId
            }
        }).then(res => {
            message.success()
            handleModalCancel()
            getData()
        })
    }

    const [Type, setType] = useState('')
    const [isSelfCheck, setisSelfCheck] = useState(null)
    //批量退回
    const handleBackMore = e => {
        if (selectedRowKeys.length === 0) return message.error('请选择要退回的工单')
        if (!Type || !remark || !isSelfCheck) return message.error('请填写完成信息')
        axios.post('/api/core/taskAudit/batchAudit1Refused', {
            audit1RefusedReason: remark,
            audit1RefusedReasonType: Type,
            audit1RefusedRemark: remark,
            isSelfCheck
        }, {
            params: {
                taskIds: selectedRowKeys.join(',')
            }
        }).then(res => {
            message.success()
            handleModalCancel()
            getData()
        })
    }
    //安排判断
    const handleEnsure = e => {
        if (modalVisible === 'back') handleBack()
        if (modalVisible === 'backMore') handleBackMore()
        if (modalVisible === 'pass') handlePass()
        if (modalVisible === 'passMore') hanldePassMore()
    }

    //导出
    const handleExport = e => {
        const queryCondition = []
        queryCondition.push('IN:taskAudit1Status=1')
        if (store.getState().user.uid != 4)
            queryCondition.push('EQ:agentId=' + store.getState().user.uid)
        if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
        if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
        if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
        if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
        if (firstStatusSelect) queryCondition.push('EQ:taskAudit1Status=' + firstStatusSelect)
        if (scopeSelect) queryCondition.push('EQ:surveyScope=' + scopeSelect)

        axios.get('/api/core/taskAudit/export', {
            params: {
                pageSize: 1000000,
                queryCondition: queryCondition.join(','),
                taskAuditExportType: 10
            },
            responseType: 'blob'
        }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "工单列表.xlsx");
            document.body.appendChild(link);
            link.click();
        })
    }

    const [visibool, setvisibool] = useState(false)


    const handleOk = () => {
        axios.put('/api/core/project/updateAutoAudit1PassedTime', {}, {
            params: {
                projectId: projectid,
                autoAudit1PassedTime: Hour * 60 + Minute + ''
            }
        })
            .then(res => {
                message.success('修改成功')
                setvisibool(false)
            })
    }

    const visishenhe = () => {
        setvisibool(true)
        axios.get('/api/core/project/' + projectid)
            .then(res => {
                setHour(parseInt(res.data.autoAudit1PassedTime / 60, 0))
                setMinute(res.data.autoAudit1PassedTime % 60)
            })
    }

    const [Hour, setHour] = useState(0)
    const [Minute, setMinute] = useState(0)

    return (<div style={{ padding: '30px' }} >
        <div style={{ padding: '30px', background: 'white' }} >

            <Row gutter={[20, 20]} style={{ marginBottom: '20px' }} >
                <Col span={8} >
                    <span style={{ lineHeight: '30px' }} > 调研编号 </span>
                    <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
                </Col>
                <Col span={8} >
                    <span style={{ lineHeight: '30px' }} > 调研大区 </span>
                    <Select style={{ width: '240px', marginLeft: '10px' }} value={scopeSelect} showSearch onChange={e => setScopeSelect(e)} >
                        <Select.Option value={''} > 取消选择 </Select.Option> {
                            scopeOption.map((i, index) => < Select.Option key={index}
                                value={i} > {i} </Select.Option>)
                        }
                    </Select>
                </Col>
                <Col span={8} >
                    <span style={{ lineHeight: '30px' }} > 访问员 </span>
                    <Select
                        showSearch
                        filterOption={
                            (input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '240px', marginLeft: '25px' }}
                        value={surveyorSelect}
                        onChange={e => setSurveyorSelect(e)} >
                        <Select.Option value={''} > 取消选择 </Select.Option> {
                            surveyorOption.map((i, index) => (<
                                Select.Option key={index}
                                value={i.id} > { i.name} </Select.Option>
                            ))
                        } </Select>
                </Col>
                <Col span={8} >
                    <span style={{ lineHeight: '30px' }} > 调研对象 </span>
                    <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
                </Col>
            </Row>
            <Row justify='space-between' style={{ marginBottom: '20px' }} >
                <Col>
                    <Button style={{ margin: '0 10px' }}
                        type='primary'
                        onClick={e => setModalVisible('passMore')} > 批量通过 </Button>
                    <Button style={{ margin: '0 10px' }}
                        type='primary'
                        onClick={e => setModalVisible('backMore')} > 批量退回 </Button>
                    <Button style={{ margin: '0 10px' }}
                        disabled={projectData.isAutoAudit1Passed != 1}
                        onClick={e => visishenhe()} > 审核设置 </Button>
                    <Button style={{ margin: '0 10px' }} > 批量下载 </Button>
                    <Button style={{ margin: '0 10px' }}
                        onClick={handleExport} > 批量导出 </Button>
                </Col>
                <Col>
                    <Button style={{ margin: '0 10px' }}
                        type='primary'
                        onClick={getData} > 查询 </Button>
                    <Button style={
                        { margin: '0 10px' }}
                        onClick={clearSelect} > 重置 </Button>
                </Col>
            </Row>

            {
                selectedRowKeys.length !== 0 ?
                    <Row align='middle'
                        gutter={
                            [20, 0]}
                        style={{ marginBottom: '10px' }} >
                        <Col > < Button danger type='primary'
                            onClick={handleClearSelect} > 清空 </Button></Col >
                        <
                            Col > {'已选择' + selectedRowKeys.length + '项'} </Col>
                    </Row> :
                    null
            } <Table
                rowSelection={
                    { selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
                dataSource={dataSource}
                columns={columns}
                pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
                        setPage(page)
                        setPageSize(pageSize)
                      }
                      }}
            />

            <Modal
                width={640}
                title={'退回工单'}
                visible={modalVisible === 'back' || modalVisible === 'backMore'}
                onCancel={handleModalCancel}
                destroyOnClose
                maskClosable={false}
                footer={
                    [<
                        Button key="back"
                        onClick={handleModalCancel} > 取消 </Button>, <
                            Button key="submit"
                            type="primary"
                            onClick={handleEnsure} > 确认 </Button>,
                    ]
                } >
                <Row gutter={[20, 20]} style={{ lineHeight: '30px' }} >
                                <Col span={6}
                                    style={{ textAlign: 'right' }} > < font color='red' > * </font>退回理由:</Col >
                                <Col span='14' >
                                    <Select style={{ width: 120 }}
                                        onChange={e => { setType(e) }} > {
                                            Object.keys(labelValues).map((v) => {
                                                return <Select.Option value={v} > {labelValues[v]} </Select.Option>
                                            })
                                        }
                                    </Select>
                                </Col> {
                                    Type == '2' ? < >
                                        <
                                            Col span={6}
                                            style={
                                                { textAlign: 'right' }} > </Col>
                                        <Col span='14' >
                                            <font color='red' > * </font><Checkbox checked={isSelfCheck} onChange={(e) => setisSelfCheck(e.target.checked ? 1 : 0)}>是否全部退回自检</Checkbox >
                                        </Col>
                                    </> : null
                                }
                                <Col span={6}
                                    style={{ textAlign: 'right' }} > < font color='red' > * </font>退回原因:</Col>
                                <Col span={14} >
                                    <Input style={{ width: '100%' }}
                                        value={remark}
                                        onChange={e => setRemark(e.target.value)}
                                    />
                                </Col>
                            </Row> 
            </Modal>

                        <Modal
                            width={640}
                            title={'通过工单'}
                            visible={modalVisible === 'pass' || modalVisible === 'passMore'}
                            onCancel={handleModalCancel}
                            destroyOnClose
                            maskClosable={false}
                            footer={
                                [<Button key="back"
                                    onClick={handleModalCancel} > 取消 </Button>,
                                <Button key="submit"
                                    type="primary"
                                    onClick={handleEnsure} > 确认 </Button>,
                                ]
                            } >
                            <Row gutter={
                                [20, 20]}
                                style={
                                    { lineHeight: '30px' }} >
                                <Col span={6}
                                    style={
                                        { textAlign: 'right' }} > 通过理由: </Col>
                                <Col span={14} >
                                    <Input style={
                                        { width: '100%' }}
                                        value={remark}
                                        onChange={e => setRemark(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Modal>

                        <Modal
                            title={"审核设置"}
                            visible={visibool}
                            onOk={handleOk}
                            onCancel={e => setvisibool(false)} >
                            设置一审自动通过时间: <InputNumber min={0}
                                max={23}
                                style={
                                    { margin: '0 5px' }}
                                value={Hour}
                                onChange={e => setHour(e)}
                            />h
            <InputNumber min={1}
                                max={59}
                                style={
                                    { margin: '0 5px' }}
                                value={Minute}
                                onChange={e => setMinute(e)}
                            />min
            </Modal>

            </div> 
            </div>
        )
    }

    export default App