import axios from 'axios'
import message from './message'
import { baseUrl } from './config'
import store from '@/redux/store'

  console.log(window.location.href)

  // request loading
  let number = 0
  const changeLoading = e => {
    if (e) {
      number++
      if (!store.getState().loading) store.dispatch({ type: 'loading_set', value: true })
    } else {
      number--
      if (number === 0 && store.getState().loading) store.dispatch({ type: 'loading_set', value: false })
    }
  }
  
  axios.defaults.baseURL = baseUrl;
 
  axios.interceptors.request.use(config => {
    // if(!config.url.includes('/api/core/barCode')){
      changeLoading(true)
    // }
    if(!config.url.includes('/work/order')&&!config.url.includes('access_token')){
      config.params = { ...config.params, appId: 'cms', appKey: 'cms@2020' }
    }
  
    if (store.getState().user.token) config.headers.token = store.getState().user.token
    if (store.getState().user.uid) config.headers.uid = store.getState().user.uid
  
    return config;
  }, error => {
    message.error()
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use(response => {
    changeLoading(false)
  
    if (response.status !== 200) message.error()
    return response.data;
  }, error => {
    changeLoading(false)
  
    if (error.response?.data?.code === 1002) {
      message.error('账户失效，请重新登陆')
      store.dispatch({ type: 'user_clear' })
      return Promise.reject(error)
    }
  
    message.error()
    return Promise.reject(error);
  });
  
  axios.get('/api/sys/dict/list', { params: { pageSize: 1000000, } })
  
export default axios