import { notification } from 'antd';

let s = null

const setS = e => {
  s = setTimeout(() => {
    e()
    s = null
  }, 0)
}

const message = {
  success: content => {
    if (s) {
      clearTimeout(s)
      setS(() => {
        notification.success({
          message: content || '操作成功',
        });
      })
    } else {
      setS(() => {
        notification.success({
          message: content || '操作成功',
        });
      })
    }
  },
  error: content => {
    if (s) {
      clearTimeout(s)
      setS(() => {
        notification.error({
          message: content || '操作有误或存在网络问题',
        });
      })
    } else {
      setS(() => {
        notification.error({
          message: content || '操作有误或存在网络问题',
        });
      })
    }
  },
  info: content => {
    if (s) {
      clearTimeout(s)
      setS(() => {
        notification.error({
          message: content,
        });
      })
    } else {
      setS(() => {
        notification.error({
          message: content,
        });
      })
    }
  }
}


export default message