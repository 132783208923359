import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, useHistory , useRouteMatch} from 'react-router-dom'

import Tousu12345 from  './Tousu12345'

function App(props) {
  return(
    <>
      <Route path="/ts" component={Tousu12345} />
    </>
  )
}
export default App
