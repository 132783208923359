import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';
function App(props) {

    const chartRef = useRef(null)

    const chartInit = e => {
        const myChart = echarts.init(document.getElementById('main2'));
        chartRef.current = myChart
        myChart.setOption({
            title: {
                text: '247',
                marginLeft: '30px'
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00',]
            },
            yAxis: {
                type: 'value',
                show: false
            },
            series: [{
                data: [20, 121, 300, 301, 305, 303, 400, 305, 301, 309, 302, 305, 303, 400, 300, 300, 350, 300, 306, 309, 340, 350, 344, 350, 360],
                type: 'line',
                smooth: true,
                areaStyle: {},
                color: 'rgba(62, 97, 155, 1)'
            }],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
        })
    }

    //初始化
    useEffect(() => {
        setTimeout(() => {
            chartInit()
        }, 0);
    }, [])

    const resize = e => {
        if (chartRef.current)
            chartRef.current.resize()
    }
    useEffect(() => {
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])


    return (
        <>
            <div id="main2" style={{ width: '100%', height: '200px' }}></div>
        </>
    );

}
export default App
