import React, { useEffect, useState, Component } from 'react';
import { Button, Row, Col, Popover } from 'antd'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

class App extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
    }
  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    setTimeout(function () {
      if (JSON.stringify(self.state.data) != JSON.stringify(nextProps.data)) {
        self.setState({
          data: self.state.data.length != 0 ? self.state.data : nextProps.data
        })
      }
    }, 0)
  }

  Time = (one,two)=>{
    const date1=moment(two);
    const date2=moment(one);
    const date3=date2.diff(date1,'minute');//计算相差的分钟数
    const h=Math.floor(date3/60);//相差的小时数
    const mm=date3%60;//计算相差小时后余下的分钟数
    return (h!=0?h + '小时':null) + mm +'分钟'
  }

  PopoverDom = (id) => {
    switch (id) {
      case 1: return (<div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{this.state.data.createdByName}</span><div style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: '5px', height: '5px', display: 'inline-block', borderRadius: '50%', background: this.state.data.startAt?'rgba(24, 144, 255, 1)':'red', whiteSpace: 'nowrap' }}></div>{this.state.data.startAt?'完成提交':'未提交'}</div></div>
        <div>{this.state.data.length != 0 && this.state.data.startAt != null ? this.state.data.startAt?.split('.')[0] : '-'}</div>
      </div>)
      case 2: return (<div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{this.state.data.agent}</span><div style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: '5px', height: '5px', display: 'inline-block', borderRadius: '50%', background: this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object && this.state.data.taskAudit1Status.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red', whiteSpace: 'nowrap' }}>
        </div>{this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object ? this.state.data.taskAudit1Status.display : null}</div></div>
        <div>耗时:{this.state.data.length != 0 && this.state.data.audit1At != null ? this.Time(this.state.data.audit1At?.split('.')[0],this.state.data.startAt?.split('.')[0]) : '-'}</div>
      </div>)
      case 3: return (<div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{this.state.data.audit2ByName}</span><div style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: '5px', height: '5px', display: 'inline-block', borderRadius: '50%', background: this.state.data.length != 0 && this.state.data.taskAudit2Status instanceof Object && this.state.data.taskAudit2Status.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red', whiteSpace: 'nowrap' }}>
        </div>{this.state.data.length != 0 && this.state.data.taskAudit2Status instanceof Object ? this.state.data.taskAudit2Status.display : null}</div></div>
        <div>耗时:{this.state.data.length != 0 && this.state.data.audit2At != null && this.state.data.audit1At != null  ? this.Time(this.state.data.audit2At.split('.')[0],this.state.data.audit1At.split('.')[0]) : '-'}</div>
      </div>)
      case 5: return (<div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{this.state.data.reviewByName}</span><div style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: '5px', height: '5px', display: 'inline-block', borderRadius: '50%', background: this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object && this.state.data.taskReviewStatus.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red', whiteSpace: 'nowrap' }}>
        </div>{this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object ? this.state.data.taskReviewStatus.display : null}</div></div>
        <div>耗时:{this.state.data.length != 0 && this.state.data.reviewAt != null && this.state.data.audit2At != null ? this.Time(this.state.data.reviewAt.split('.')[0],this.state.data.audit2At.split('.')[0]) : '-'}</div>
      </div>)
    }
  }

  render() {
    return (
      <Col style={{ padding: '20px 60px 10px', height: '150px' }} span={24}>
        {/* 圆点+文字 */}
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Popover content={this.PopoverDom(1)} trigger="hover">
            <Row justify='center' style={{ width: '7px', height: '7px', background: this.state.data.startAt?'rgba(24, 144, 255, 1)':'red', borderRadius: '50%', margin: '0 5px' }}>
              <div style={{ whiteSpace: 'nowrap', marginTop: '5px', padding: '15px 0 7px' }}>
                <span style={{ fontWeight: '550', padding: '15px 0 7px' }}>提交信息</span>
                <Col span='24' style={{ position: 'absolute' }}>
                  <p>{this.state.data.principal}</p>
                  <p>{this.state.data.length != 0 && this.state.data.startAt != null ? this.state.data.startAt?.split('.')[0] : '-'}</p>
                </Col>
              </div>
            </Row>
          </Popover>
          {/* 横线 */}
          {/* 卡片 */}
          <div style={{ height: '1px', background: this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object && this.state.data.taskAudit1Status.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'rgba(166,166,166, 1)', flex: '0.5' }}></div>
          <Popover content={this.PopoverDom(2)} trigger="hover">
            <Row justify='center' style={{ width: '7px', height: '7px', background: this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object && this.state.data.taskAudit1Status.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red', borderRadius: '50%', margin: '0 5px' }}>
              <div style={{ whiteSpace: 'nowrap', marginTop: '5px', padding: '15px 0 7px' }}>
                <span style={{ fontWeight: '550', padding: '15px 0 7px' }}>一审负责人</span>
                <Col span='24' style={{ position: 'absolute' }}>
                  <p>{this.state.data.agent}</p>
                  <p style={{ color:this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object && this.state.data.taskAudit1Status.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red' }}>{this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object ? this.state.data.taskAudit1Status.display : null}</p>
                </Col>
              </div>
            </Row>
          </Popover>
          <div style={{ height: '1px', background: this.state.data.length != 0 && this.state.data.taskAudit2Status instanceof Object && this.state.data.taskAudit2Status.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'rgba(166,166,166, 1)', flex: '0.5' }}></div>
          <Popover content={this.PopoverDom(3)} trigger="hover">
            <Row justify='center' style={{ width: '7px', height: '7px', background: this.state.data.length != 0 && this.state.data.taskAudit2Status instanceof Object && this.state.data.taskAudit2Status.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red', borderRadius: '50%', margin: '0 5px' }}>
              <div style={{ whiteSpace: 'nowrap', marginTop: '5px', padding: '15px 0 7px' }}>
                <span style={{ fontWeight: '550', padding: '15px 0 7px' }}>二审负责人</span>
                <Col span='24' style={{ position: 'absolute' }}>
                  <p>{this.state.data.audit2ByName}</p>
                  <p style={{ color:this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object && this.state.data.taskAudit2Status.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red' }}>{this.state.data.length != 0 && this.state.data.taskAudit2Status instanceof Object ? this.state.data.taskAudit2Status.display : null}</p>
                </Col>
              </div>
            </Row>
          </Popover>
          <div style={{ height: '1px', background: this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object && this.state.data.taskReviewStatus.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'rgba(166,166,166, 1)', flex: '0.5' }}></div>
          <Popover content={this.PopoverDom(5)} trigger="hover">
            <Row justify='center' style={{ width: '7px', height: '7px', background: this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object && this.state.data.taskReviewStatus.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red', borderRadius: '50%', margin: '0 5px' }}>
              <div style={{ whiteSpace: 'nowrap', marginTop: '5px', padding: '15px 0 7px' }}>
                <span style={{ fontWeight: '550', padding: '15px 0 7px' }}>复核负责人</span>
                <Col span='24' style={{ position: 'absolute' }}>
                  <p>{this.state.data.reviewByName}</p>
                  <p style={{ color:this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object && this.state.data.taskReviewStatus.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red' }}>{this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object ? this.state.data.taskReviewStatus.display : null}</p>
                </Col>
              </div>
            </Row>
          </Popover>
          <div style={{ height: '1px', background: this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object && this.state.data.taskReviewStatus.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'rgba(166, 166, 166, 1)', flex: '0.5' }}></div>
          <Popover content={this.PopoverDom(5)} trigger="hover">
            <Row justify='center' style={{ width: '7px', height: '7px', background: this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object && this.state.data.taskReviewStatus.display != '未审核' ? 'rgba(24, 144, 255, 1)' : 'red', borderRadius: '50%', margin: '0 5px' }}>
              <div style={{ whiteSpace: 'nowrap', marginTop: '5px', padding: '15px 0 7px' }}>
                <span style={{ fontWeight: '550', padding: '15px 0 7px' }}>完成</span>
              </div>
            </Row>
          </Popover>
        </Row>
      </Col>
    )
  }
}

export default withRouter(App)