
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Menu } from 'antd';
import { HomeFilled, GoldenFilled, TeamOutlined, SolutionOutlined, FileTextOutlined, LogoutOutlined } from '@ant-design/icons';
import store from '@/redux/store'

function App() {

  const { push } = useHistory()
  const { pathname } = useLocation()

  const [openKeys, setOpenKeys] = useState(['/' + pathname.split('/').slice(2, 3).join('/')])
  const [selectedKeys, setSelectedKeys] = useState(['/' + pathname.split('/').slice(2, 4).join('/')])

  useEffect(() => {
    if (pathname.includes('/project/edit')) {
      setSelectedKeys(['/project/list'])
      return
    }
    if (pathname.includes('/project/look')) {
      setSelectedKeys(['/project/list'])
      return
    }
    if (pathname.includes('/control/peopledetail')) {
      setSelectedKeys(['/control/people'])
      return
    }
    if (pathname.includes('/set/productdetail')) {
      setSelectedKeys(['/set/product'])
      return
    }
    if (pathname.includes('/set/projecttemplatedetail')) {
      setSelectedKeys(['/set/projecttemplate'])
      return
    }
    if (pathname.includes('/set/shop')) {
      setSelectedKeys(['/set/shop'])
      return
    }
    if (pathname.includes('/set/targetdetail')) {
      setSelectedKeys(['/set/target'])
      return
    }
    if (pathname.includes('/set/locationdetail')) {
      setSelectedKeys(['/set/location'])
      return
    }
    if (pathname.includes('/set/apptemplatedetail')) {
      setSelectedKeys(['/set/apptemplate'])
      return
    }
    if (pathname.includes('/control/xuzhongdetail/0')) {
      setSelectedKeys(['/control/xuzhongdetail/0'])
      return
    }
    if (pathname.includes('/set/standardItem')) {
      setSelectedKeys(['/set/standard'])
      return
    }
    if (pathname.includes('/home/data/visitorDSRType')) {
      setSelectedKeys(['/data/visitor'])
      return
    }
    if (pathname.includes('/home/data/visitorDSR')) {
      setSelectedKeys(['/data/visitor'])
      return
    }
    if (pathname.includes('/home/data/visitorchenlie')) {
      setSelectedKeys(['/data/visitor'])
      return
    }
    if (pathname.includes('/home/data/visitormutangchun')) {
      setSelectedKeys(['/data/visitor'])
      return
    }
    if (pathname.includes('/home/data/agencyDSRType')) {
      setSelectedKeys(['/data/agency'])
      return
    }
    if (pathname.includes('/home/data/agencyDSR')) {
      setSelectedKeys(['/data/agency'])
      return
    }
    if (pathname.includes('/home/data/agencymutangchun')) {
      setSelectedKeys(['/data/agency'])
      return
    }
    if (pathname.includes('/home/data/agencychenlie')) {
      setSelectedKeys(['/data/agency'])
      return
    }
    if (pathname.includes('/home/data/agencydaili')) {
      setSelectedKeys(['/data/agency'])
      return
    }
    if (pathname.includes('/home/data/firstuser')) {
      setSelectedKeys(['/data/first'])
      return
    }
    setSelectedKeys(['/' + pathname.split('/').slice(2, 4).join('/')])
  }, [pathname])
  useEffect(() => {
    setOpenKeys(['/' + pathname.split('/').slice(2, 3).join('/')])
  }, [pathname])


  const [menuList, setMenuList] = useState([]);
  useEffect(() => {
    const set = () => {
      setMenuList([
        {
          itemGroup: { link: '/project', text: '项目管理', icon: <GoldenFilled /> },
          itemList: [
            { link: '/project/create', text: '创建项目', code: 'CD_G_XIANGMUGUANLI_CJ' },
            { link: '/project/list', text: '项目列表', code: 'CD_G_XIANGMUGUANLI_LB' },
          ]
        },
        {
          itemGroup: { link: '/control', text: '权限控制', icon: <TeamOutlined /> },
          itemList: [
            { link: '/control/list', text: '权限列表', code: 'CD_G_QUANXIANKONGZHI_ZYLB' },
            { link: '/control/role', text: '角色列表', code: 'CD_G_QUANXIANKONGZHI_JSLB' },
            { link: '/control/people', text: '项目人员列表', code: 'CD_G_QUANXIANKONGZHI_XMRYLB' },
            { link: '/control/xuzhongdetail/0', text: '旭中人员管理', code: 'CD_G_QUANXIANKONGZHI_XZRYGL' },
          ]
        },
        {
          itemGroup: { link: '/set', text: '项目设置', icon: <SolutionOutlined /> },
          itemList: [
            { link: '/set/product', text: '家化产品管理', code: 'CD_G_XIANGMUSHEZHI_JHCPGL' },
            { link: '/set/shop', text: '家化门店管理', code: 'CD_G_XIANGMUSHEZHI_JHMLGL' },
            { link: '/set/target', text: '实效指标管理', code: 'CD_G_XIANGMUSHEZHI_SXZBGL' },
            { link: '/set/location', text: '实效位置管理', code: 'CD_G_XIANGMUSHEZHI_SXWZGL' },
            // { link: '/set/projecttemplate', text: '项目模板', code: 'CD_G_XIANGMUSHEZHI_XMMB' },
            { link: '/set/apptemplate', text: 'APP模板', code: 'CD_G_XIANGMUSHEZHI_AMB' },
            // { link: '/set/computetemplate', text: '算分模板', code: 'CD_G_XIANGMUSHEZHI_SFMB' },
            { link: '/set/region', text: '算分区域管理', code: 'CD_G_SUANFENQUYUGUANLI' },
            { link: '/set/standard', text: '算分标准管理', code: 'CD_G_SUANFENBIAOZHUNGUANLI' },
          ]
        },
        {
          item: { link: '/log', text: '操作日志', code: 'CD_G_CHAOZUORIZHI', icon: <FileTextOutlined /> }
        },
        {
          itemGroup: { link: '/data', text: '数据统计', icon: <FileTextOutlined /> },
          itemList: [
            { link: '/data/visitor', text: '访问员业绩报告', code: 'CD_G_SHUJUTONGJI_FWYYY' },//CD_G_SHUJUTONGJI_FWYYY
            { link: '/data/agency', text: '代理业绩报告', code: 'CD_G_SHUJUTONGJI_DLYYY' },//CD_G_SHUJUTONGJI_DLYYY
            { link: '/data/first', text: '审核业绩报告', code: 'CD_G_SHUJUTONGJI_YSSS' },//CD_G_SHUJUTONGJI_YSSS
          ]
        },
      ])
    }
    if (store.getState().permission.have) set()
    const s = store.subscribe(() => {
      if (store.getState().permission.have) {
        set()
        s()
      }
    })
  }, []);

  const handleExit = e => {
    store.dispatch({ type: 'user_clear' })
  }

  return (
    <Menu
      className='menu'
      theme="dark"
      openKeys={openKeys}
      onOpenChange={e => setOpenKeys(e)}
      selectedKeys={selectedKeys}
      mode="inline"
      style={{ overflow:'auto', }}
    >
      {
        menuList.map(i => {
          if (i.item) {
            return (
              <Menu.Item key={i.item.link} icon={i.item.icon}>
                <Link to={'/home' + i.item.link}>{i.item.text}</Link>
              </Menu.Item>
            )
          }
          if (i.itemGroup) {
            return (
              <Menu.SubMenu key={i.itemGroup.link} title={i.itemGroup.text} icon={i.itemGroup.icon}>
                {
                  i.itemList.map(i => {
                    return (store.getState().permission.main.includes(i.code) || i.code === '*') ?
                      <Menu.Item key={i.link}>
                        <Link to={'/home' + i.link}>{i.text}</Link>
                      </Menu.Item>
                      : null
                  })
                }
              </Menu.SubMenu>
            )
          }
        })
      }
      <Menu.Item icon={<LogoutOutlined />} onClick={handleExit}>
        退出
      </Menu.Item>
    </Menu>
  );
}
//rgba(36, 38, 51, 1)

export default App;