import React, { useEffect, useState, useRef } from 'react';
import { HashRouter, Switch, Route, useHistory, useParams } from 'react-router-dom'
import { Row, Col, Tabs, Layout, Menu, Tree, Cascader, Checkbox, Input, Table, Button, Radio, Select, message, Modal, DatePicker, InputNumber, Popover } from 'antd'
import axios from '@/utils/axios'
import E from 'wangeditor'
import { PlusCircleOutlined, MinusCircleOutlined, DownCircleOutlined, UpCircleOutlined, PlusSquareOutlined, FormOutlined, StarFilled, UserOutlined, PhoneOutlined, ConsoleSqlOutlined, CompassOutlined } from '@ant-design/icons';
import PopConfirmOnly from "@/utils/PopConfirmOnly";
import { options } from '../data/design'
import store from '@/redux/store'
import moment from 'moment'
import { audioUrl, baseUrl } from '@/utils/config'
import './index.css'

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

interface childProps {
  code: number
}

function App(props) {
  const projectId = useParams().projectid
  let wjid = useParams().wjid

  let { code, bools, heights, typedata } = props; // 接收父组件传参

  const Array = []
  typedata.forEach(v => {
    v.children.forEach(v => {
      Array.push(v)
    })
  })
  typedata = Array

  const expression = [
    {
      title: '姓名',
      expression: /^[a-zA-Z0-9]{4,8}$|^[\u4e00-\u9fa5]{2,4}$///4-8个字母 或者 2-4个中文字
    },
    {
      title: '身份证',
      expression: /^\d{17}([0-9]|x|X){1}/
    },
    {
      title: '数字',
      expression: /^\d{0,}$///最少0个数字 输入的只能是数字
    },
    {
      title: '手机号',
      expression: /^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6-7])|(17[1-8])|(18[0-9])|(19[1|3])|(19[5|6])|(19[8|9]))\d{8}$/
    },
    {
      title: '固话',
      expression: /^\d{6,8}$/ // 6-8个数字
    },
    {
      title: '日期',
      expression: moment('2007-05-05', 'YYYY-MM-DD', true).isValid()//删除
    },
    // {
    //   title:'省份',
    //   expression:
    // },
    // {
    //   title:'城市',
    //   expression:
    // },
    // {
    //   title:'区',
    //   expression:
    // },
    // {
    //   title:'街道',
    //   expression:
    // }
  ]

  const expressionChange = (e) => {
    getdata[dataIndex].validate = e
    setgetdata(getdata)
    setRefresh(true)
  }

  const [getdata, setgetdata] = useState([])

  let [dataIndex, setDataIndex] = useState(null)

  const [homedata, sethomedata] = useState({
    randomGroup:''
  })

  const [on_off, seton_off] = useState(false)

  useEffect(() => {
    setRefresh(true)
  }, [heights])

  useEffect(() => {
    getdata.forEach((v, i) => {
      v.oldidx = v.idx
      v.idx = i + 1
      if (v.skipType == 1) {
        v.options.forEach((a, i) => {
          if (a.skipTo) {
            a.no = getdata.filter((z) => {
              return z.idx == a.skipTo
            })[0]?.no
          }
        });
      }
    })
    getdata.forEach((v, i) => {
      if (v.type == '7') { // 配额
        v.options.forEach((a, b) => {
          let filter = getdata.filter(c => {
            return a.relatedTo?.includes(c.oldidx)
          })
          a.relatedTo = filter.map(a => {
            return a.idx
          }).join(',')
        })
      }
      if (v.skipType) { // 修改顺序时处理 跳题 
        if (v.skipType == 2) {
          let count = true
          getdata.forEach((a, b) => {
            if (v.skipTo == a.oldidx && count) {
              count = false
              v.skipTo = a.idx
            }
          })
        } else {
          let count = true
          v.options.forEach((l, f) => {
            getdata.forEach((a, b) => {
              if (v.options) {
                if (l.skipTo == a.oldidx && a.oldidx != null && count) {
                  count = false
                  l.skipTo = a.idx
                }
              }
            })
          })
          console.log(JSON.parse(JSON.stringify(getdata)))
        }
      }
      if (v.relatedList != null && v.relatedList.length != 0) {  //修改顺序时处理 关联
        v.relatedList.forEach((l, j) => {
          let count = true
          getdata.forEach((a, b) => {
            let arr = v.relatedList.filter((l, j) => {
              return l.timuIdx == a.oldidx
            })
            if (arr.length != 0 && count) {
              if (l.timuIdx == a.oldidx) {
                count = false
                l.timuIdx = a.idx
              }
            }
          })
        })
      }
      if (v.type == '1' && v.score > 1) {  //修改顺序时处理 选项关联
        v.options.forEach(a => {
          if (typeof a.relatedTo == 'string' && a.relatedTo.length != 4) {
            a.relatedTo = JSON.parse(a.relatedTo)
            console.log(a.relatedTo)
            a.relatedTo.forEach((l, j) => {
              let count = true
              getdata.forEach((c, b) => {
                let arr = a.relatedTo.filter((l, j) => {
                  return l.topicIdx == c.oldidx
                })
                if (arr.length != 0 && count) {
                  if (l.topicIdx == c.oldidx) {
                    count = false
                    l.topicIdx = c.idx
                  }
                }
              })
            })
            a.relatedTo = JSON.stringify(a.relatedTo)
          }
        })
      }
      if(v.wordReplace!=null){
        v.wordReplace.forEach(a=>{
          let bools = true
          getdata.forEach(b=>{
            if(a.topicIdx == b.oldidx && bools){
              bools = false
              a.topicIdx = b.idx
              console.log(b.idx,b.oldidx)
            }
          })
        })
      }
    })
    getdata.forEach((v, i) => {
      delete v.oldidx
    })
    setRefresh(true)
  }, [getdata])

  useEffect(() => { //添加问题
    if (document.getElementById('wenjuana').style.display == 'none') {
      let name = ''
      if (code == '61') {
        name = '您的姓名:'
      } else if (code == '62') {
        name = '您的性别:'
      } else if (code == '63') {
        name = '请输入您的手机号码:'
      } else if (code == '64') {
        name = '请输入日期:'
      }
      let options = []
      if (code == '62') {
        options.push(...[{ title: '男', idx: 1 }, { title: '女', idx: 2 }])
      }
      getdata.push({
        idx: getdata.length + 1,
        no: getdata.filter((v) => { return v.type != 31 && v.type != 32 }).length + 1,
        title: name,
        group: code?.length > 1 ? code[0] : '1',
        type: code,
        must: 1,
        isMultiSelect: code == 2 ? 1 : 0,
        chooseItemNames: [],
        options
      })
      console.log(getdata)
      if (on_off) {
        setTimeout(() => {
          show(getdata[getdata.length - 1], getdata.length - 1)
          document.getElementById('wenjuana').scrollIntoView(0, 0, 0, 0, false)
        }, 100)
      }
    } else {
      getdata[dataIndex].group = code?.length > 1 ? code[0] : '1'
      getdata[dataIndex].type = code
      getdata[dataIndex].isMultiSelect = code == 2 ? 1 : 0
      getdata[dataIndex].options.forEach(v=>{
        v.relatedTo=null
      })
    }
    setgetdata(getdata)
    const domwenjuan = document.getElementsByClassName('wenjuan')[dataIndex]
    setdisTop(domwenjuan?.offsetTop + domwenjuan?.offsetHeight)
    setRefresh(true)
    seton_off(true)
  }, [bools])

  const [refresh, setRefresh] = useState(false);

  useEffect(() => { //强制渲染页面
    refresh && setTimeout(() => setRefresh(false))
  }, [refresh])

  const addtopic = (i) => {//添加选项
    let arr = [...getdata[dataIndex].options]
    if (i != undefined) {
      let count = true
      console.log(i)
      arr.splice(i + 1, 0, { title: '', idx: i + 1 })
      arr.forEach((v, index) => {
        v.idx = index
      })
    } else {
      arr.push({ title: '', idx: arr.length })
    }
    console.log(arr)
    setgetdata(pre => {
      pre[dataIndex].options = arr
      return pre
    })
    setTimeout(() => {
      setTopHeight()
      let arr = document.getElementsByClassName('inputs')
      if (i != undefined) {
        arr[i + 1].focus()
      } else {
        arr = [...arr].reverse()
        arr[0].focus()
      }
    }, 100)
  }

  const deletetopic = (index, record) => {//删除选项
    let count = false
    if (record.skipTo || record.optionRelatedTo || record.relatedTo || record.score) {
      count = true
    }
    if (!count) {
      getdata.forEach(v => {
        if (v.relatedList && v.relatedList.filter(a => {
          return a.timuId == getdata[dataIndex].id
        }).length > 0) {
          count = true
          console.log(1)
        }
        v.options.forEach(a => {
          if (v.type == '7') {
            if (a.relatedTo && a.relatedTo.includes(',') && a.relatedTo.split(',').includes(getdata[dataIndex].idx)) {
              count = true
            }
          } else {
            if (a.relatedTo) {
              let arr = JSON.parse(a.relatedTo)
              if (arr && arr.filter(a => {
                return a.topicIdx == getdata[dataIndex].idx && a.options.includes(record.idx)
              }).length > 0) {
                count = true
              }
            }
          }
        })
      })
    }
    setDeleteIndex(index)

    if (count) {
      setDeleteRecord(record)
      setDeleteModal(true)
      return message.error('请确认删除关联或跳题选项后再次删除')
    }else{
      let arr = [...getdata[dataIndex].options]
      arr.splice(index, 1)
      setgetdata(pre => {
        pre[dataIndex].options = arr
        return pre
      })
      setTimeout(() => {
        setTopHeight()
      }, 100)
    }
  }

  const [deleteModal,setDeleteModal] = useState(false)
  const [deleteRecord,setDeleteRecord] = useState({})
  const [deleteIndex,setDeleteIndex] = useState(null)

  const deleteModalOk = () => {
    getdata.forEach(v => {
      if (v.relatedList && v.relatedList.filter(a => {
        return a.timuId == getdata[dataIndex].id
      }).length > 0) {
        let num = null
        v.relatedList.forEach((v,i)=>{
          if(v.timuId == getdata[dataIndex].id)num=i
        })
        if(num != null)delete v.relatedList[num]
      }
      v.options.forEach(a => {
        if (v.type == '7') {
          if (a.relatedTo && a.relatedTo.includes(',') && a.relatedTo.split(',').includes(getdata[dataIndex].idx)) {
            a.relatedTo=a.relatedTo.split(',').filter(v=>v!=getdata[dataIndex].idx).join(',')
          }
        } else {
          if (a.relatedTo) {
            let arr = JSON.parse(a.relatedTo)
            if (arr && arr.filter(a => {
              return a.topicIdx == getdata[dataIndex].idx && a.options.includes(deleteRecord.idx)
            }).length > 0) {
              let num = null
              arr.forEach((a,b) => {
                if(a.topicIdx == getdata[dataIndex].idx && a.options.includes(deleteRecord.idx))num = b
              })
              if(num != null){
                delete arr[num]
                a.relatedTo=arr
              }
            }
          }
        }
      })
    })
    let arr = [...getdata[dataIndex].options]
    arr.splice(deleteIndex, 1)
    setgetdata(pre => {
      pre[dataIndex].options = arr
      return pre
    })
    setTimeout(() => {
      setTopHeight()
    }, 100)
    setDeleteModal(false)
  }

  const setTopHeight = () => { //更改 输入框位置
    const domwenjuan = document.getElementsByClassName('wenjuan')[dataIndex]
    setdisTop(domwenjuan?.offsetTop + domwenjuan?.offsetHeight + 20)
    setRefresh(true)
  }

  const tiaoti = [
    {
      title: '选项',
      dataIndex: 'title'
    },
    {
      title: '跳题目标',
      render: (text, record, index) => (
        <Select style={{ width: '100%' }} value={record.skipTo ? record.skipTo + ',' + record.no : null} onChange={e => { record.skipTo = e.split(',')[0]; record.no = e.split(',')[1]; setRefresh(true) }}>
          <Option value={''}>无</Option>
          <Option value={'-1,'}>直接结束作答</Option>
          <Option value={'0,'}>直接跳转到末尾</Option>
          {
            getdata.filter(v => { return v.type != '31' && v.type != '32' && v.no > getdata[dataIndex].no }).map(v => {
              return <Option value={v.idx + ',' + v.no}>第{v.no}题,{v.title.replace(new RegExp("<.+?>", "g"), '')}</Option>
            })
          }
        </Select>
      )
    }
  ]

  const xiala = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'title',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}
          ></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const columns = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
            setdata(getdata)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}
          ></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '说明',
      dataIndex: 'remark',
      key: 'remark',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input style={{ width: '80px' }} value={text} onChange={e => {
            record.remark = e.target.value
            setRefresh(true)
          }}></Input>
        </div>
      ),
    },
    {
      title: '必答',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            getdata[dataIndex].type != '3' ||
              getdata[dataIndex].type != '62' ? <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
                if (homedata.status == 2 && record.statusBool) {
                  message.error('问卷已有发布,无法修改')
                  return
                }
                record.isNeedGap = e.target.checked ? 1 : 0
                setRefresh(true)
                setTimeout(() => {
                  setTopHeight()
                }, 100)
              }}>是否需要填空</Checkbox>
              : null
          }
          {
            record.isNeedGap == 1 ? <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
              record.isMust = e.target.checked ? 1 : 0
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}>必填</Checkbox>
              : null
          }
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
    {
      title: '配置额度',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <Input value={record.score} onChange={e => { record.score = e.target.value; setRefresh(true) }} style={{ width: '100px' }}></Input>
        </div>
      )
    },
    {
      title: '当前数量',
      dataIndex: 'isCount',
      key: 'isCount',
    },
  ]

  const columnsCheckbox = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}
          ></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '说明',
      dataIndex: 'remark',
      key: 'remark',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input style={{ width: '80px' }} value={text} onChange={e => {
            record.remark = e.target.value
            setRefresh(true)
          }}></Input>
        </div>
      ),
    },
    {
      title: '是否填空',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            getdata[dataIndex].type != '3' ||
              getdata[dataIndex].type != '62' ? <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
                if (homedata.status == 2 && record.statusBool) {
                  message.error('问卷已有发布,无法修改')
                  return
                }
                record.isNeedGap = e.target.checked ? 1 : 0
                setRefresh(true)
                setTimeout(() => {
                  setTopHeight()
                }, 100)
              }}>是否需要填空</Checkbox>
              : null
          }
          {
            record.isNeedGap == 1 ? <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
              record.isMust = e.target.checked ? 1 : 0
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}>必填</Checkbox>
              : null
          }
        </div>
      ),
    },
    {
      title: '互斥',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <Checkbox checked={record.excludeTo == 1 ? true : false} onChange={e => { e.target.checked ? record.excludeTo = 1 : record.excludeTo = 0; setRefresh(true) }}></Checkbox>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const columnsPaixu = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}
          ></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '是否填空',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            getdata[dataIndex].type != '3' ||
              getdata[dataIndex].type != '62' ? <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
                if (homedata.status == 2 && record.statusBool) {
                  message.error('问卷已有发布,无法修改')
                  return
                }
                record.isNeedGap = e.target.checked ? 1 : 0
                setRefresh(true)
                setTimeout(() => {
                  setTopHeight()
                }, 100)
              }}>是否需要填空</Checkbox>
              : null
          }
          {
            record.isNeedGap == 1 ? <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
              record.isMust = e.target.checked ? 1 : 0
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}>必填</Checkbox>
              : null
          }
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const columnsPeie = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}
          ></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '是否填空',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            getdata[dataIndex].type != '3' ||
              getdata[dataIndex].type != '62' ? <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
                if (homedata.status == 2 && record.statusBool) {
                  message.error('问卷已有发布,无法修改')
                  return
                }
                record.isNeedGap = e.target.checked ? 1 : 0
                setRefresh(true)
                setTimeout(() => {
                  setTopHeight()
                }, 100)
              }}>是否需要填空</Checkbox>
              : null
          }
          {
            record.isNeedGap == 1 ? <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
              record.isMust = e.target.checked ? 1 : 0
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}>必填</Checkbox>
              : null
          }
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
    {
      title: '配置额度',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <Input defaultValue={record.score} onChange={e => { record.score = e.target.value; setRefresh(true) }} style={{ width: '100px' }}></Input>
        </div>
      )
    },
    {
      title: '当前数量',
      dataIndex: 'isCount',
      key: 'isCount',
    },
    {
      title: '关联',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <Button type="link" onClick={e => { peieModalKai(record) }}>配置关联</Button>
        </div>
      )
    },
  ]

  const juzhendanxuan = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}
          ></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '说明',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            getdata[dataIndex].type != '3' ||
              getdata[dataIndex].type != '62' ? <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
                if (homedata.status == 2 && record.statusBool) {
                  message.error('问卷已有发布,无法修改')
                  return
                }
                record.isNeedGap = e.target.checked ? 1 : 0
                setRefresh(true)
                setTimeout(() => {
                  setTopHeight()
                }, 100)
              }}>是否需要填空</Checkbox>
              : null
          }
          {
            record.isNeedGap == 1 ? <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
              record.isMust = e.target.checked ? 1 : 0
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}>必填</Checkbox>
              : null
          }
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const juzhendanxuanCheckbox = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}
          ></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '说明',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            getdata[dataIndex].type != '3' ||
              getdata[dataIndex].type != '62' ? <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
                if (homedata.status == 2 && record.statusBool) {
                  message.error('问卷已有发布,无法修改')
                  return
                }
                record.isNeedGap = e.target.checked ? 1 : 0
                setRefresh(true)
                setTimeout(() => {
                  setTopHeight()
                }, 100)
              }}>是否需要填空</Checkbox>
              : null
          }
          {
            record.isNeedGap == 1 ? <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
              record.isMust = e.target.checked ? 1 : 0
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}>必填</Checkbox>
              : null
          }
        </div>
      ),
    },
    {
      title: '互斥',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <Checkbox checked={record.excludeTo == 1 ? true : false} onChange={e => { e.target.checked ? record.excludeTo = 1 : record.excludeTo = 0; setRefresh(true) }}></Checkbox>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const juzhendanxuantiankong = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}
          ></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '默认',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
            record.isMust = e.target.checked ? 1 : 0
            setRefresh(true)
          }}>是否必答</Checkbox>
        </div>
      ),
    },
    {
      title: '验证属性',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select style={{ width: 120 }} value={record.validate} onChange={e => { record.validate = e; setRefresh(true) }}>
            {
              expression.map((v, i) => {
                return <Option value={v.title}>{v.title}</Option>
              })
            }
          </Select>
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const juzhen = [
    {
      title: '行标题',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '默认',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
            if (homedata.status == 2 && record.statusBool) {
              message.error('问卷已有发布,无法修改')
              return
            }
            record.isNeedGap = e.target.checked ? 1 : 0
            setRefresh(true)
          }}>是否必答</Checkbox>
        </div>
      ),
    },
    {
      title: '验证属性',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select style={{ width: 120 }} value={record.validate} onChange={e => { record.validate = e; setRefresh(true) }}>
            {
              expression.map((v, i) => {
                return <Option value={v.title}>{v.title}</Option>
              })
            }
          </Select>
        </div>
      ),
    },
  ]

  const liangbiao = [ //量标题
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '分数',
      dataIndex: 'score',
      key: 'score',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input style={{ width: '20px', padding: '0', }} value={text} onChange={e => {
            record.score = e.target.value;
            setRefresh(true)
          }}></Input>
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const pingfenrudio = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '说明',
      dataIndex: 'remark',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input style={{ width: '80px' }} value={text} onChange={e => {
            record.remark = e.target.value
            setRefresh(true)
          }}></Input>
        </div>
      ),
    },
    {
      title: '默认',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <>
          <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
            if (homedata.status == 2 && record.statusBool) {
              message.error('问卷已有发布,无法修改')
              return
            }
            record.isNeedGap = e.target.checked ? 1 : 0
            setRefresh(true)
            setTimeout(() => {
              setTopHeight()
            }, 100)
          }}>是否需要填空</Checkbox>
          {
            record.isNeedGap == 1 ? <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
              record.isMust = e.target.checked ? 1 : 0
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}>必填</Checkbox>
              : null
          }
        </>
      ),//
    },
    {
      title: '正确选项',
      dataIndex: 'score',
      key: 'score',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox style={{ margin: '5px' }} checked={record.isScore == 1 ? true : false} onChange={e => { record.isScore = e.target.checked ? 1 : 0; setRefresh(true) }}></Checkbox>正确
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const pingfenrudioCheckbox = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '说明',
      dataIndex: 'remark',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input style={{ width: '80px' }} value={text} onChange={e => {
            record.remark = e.target.value
            setRefresh(true)
          }}></Input>
        </div>
      ),
    },
    {
      title: '默认',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
            if (homedata.status == 2 && record.statusBool) {
              message.error('问卷已有发布,无法修改')
              return
            }
            record.isNeedGap = e.target.checked ? 1 : 0
            setRefresh(true)
          }}>是否需要填空</Checkbox>
        </div>
      ),
    },
    {
      title: '正确选项',
      dataIndex: 'score',
      key: 'score',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox style={{ margin: '5px' }} checked={record.isScore == 1 ? true : false} onChange={e => { record.isScore = e.target.checked ? 1 : 0; setRefresh(true) }}></Checkbox>正确
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const JuzhenLiangbiao = [
    {
      title: '行标题',
      dataIndex: 'title',
      render: (text, record, index) => {
        const obj = {
          children: <TextArea
            rows={6}
            value={getdata[dataIndex].chooseItemNames.join('\n')}
            onChange={e => {
              getdata[dataIndex].chooseItemNames = e.target.value.split(/[\s\n]/).map((v, i) => {
                return v
              })
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}
          ></TextArea>,
          props: {},
        };
        if (index === 0) {
          obj.props.rowSpan = getdata[dataIndex].options.length;
        } else {
          obj.props.rowSpan = 0
        }
        return obj;
      },
    },
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '分数',
      dataIndex: 'score',
      key: 'score',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input style={{ width: '20px', padding: '0', }} value={text} onChange={e => {
            record.score = e.target.value;
            setRefresh(true)
          }}></Input>
        </div>
      ),
    },
    {
      title: '说明',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            getdata[dataIndex].type != '3' ||
              getdata[dataIndex].type != '62' ? <Checkbox checked={record.isNeedGap == 1 ? true : false} onChange={e => {
                if (homedata.status == 2 && record.statusBool) {
                  message.error('问卷已有发布,无法修改')
                  return
                }
                record.isNeedGap = e.target.checked ? 1 : 0
                setRefresh(true)
                setTimeout(() => {
                  setTopHeight()
                }, 100)
              }}>是否需要填空</Checkbox>
              : null
          }
          {
            record.isNeedGap == 1 ? <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
              if (homedata.status == 2 && record.statusBool) {
                message.error('问卷已有发布,无法修改')
                return
              }
              record.isMust = e.target.checked ? 1 : 0
              setRefresh(true)
              setTimeout(() => {
                setTopHeight()
              }, 100)
            }}>必填</Checkbox>
              : null
          }
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const pingfenti = [
    {
      title: '选项文字',
      dataIndex: 'title',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input className="inputs" tabindex={index + 1} style={{ width: '70%', }} value={text} onChange={e => {
            record.title = e.target.value;
            setRefresh(true)
          }}
            onPressEnter={e => homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index)}></Input>
          <PlusCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : addtopic(index) }} />
          <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 && record.statusBool ? message.error('问卷已有发布,无法修改') : deletetopic(index, record) }} />
        </div>
      ),
    },
    {
      title: '分数',
      dataIndex: 'score',
      key: 'score',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input style={{ width: '20px', padding: '0', }} value={text} onChange={e => {
            record.score = e.target.value;
            setRefresh(true)
          }}></Input>
        </div>
      ),
    },
    {
      title: '上移下移',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <UpCircleOutlined style={{ fontSize: '20px', margin: '0 5px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : upMove(record, index) }} />
          <DownCircleOutlined style={{ fontSize: '20px' }} onClick={e => { homedata.status == 2 ? message.error('问卷已有发布,无法修改') : downMove(record, index) }} />
        </div>
      )
    },
  ]

  const duoxiangtiankongColumns = [
    {
      title: '填空',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        '填空' + (index + 1)
      )
    },
    {
      title: '验证属性',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select style={{ width: 120 }} value={record.validate} onChange={e => { record.validate = e; setRefresh(true) }}>
            <Option value={null}>取消选择</Option>
            {
              expression.map((v, i) => {
                return <Option value={v.title}>{v.title}</Option>
              })
            }
          </Select>
        </div>
      ),
    },
    {
      title: '数字范围(下限)',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputNumber disabled={record.validate != '数字'}
            defaultValue={record.inputRange ? record.inputRange.split('-')[0] ?? 0 : 0}
            onBlur={e => {
              console.log(record.inputRange)
              const arr = record.inputRange ? record.inputRange.split('-') : null
              arr[0] = e.target.value
              console.log(arr.join('-'))
              record.inputRange = arr.join('-')
              setRefresh(true)
            }}
          ></InputNumber>
        </div>
      ),
    },
    {
      title: '数字范围(上限)',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputNumber disabled={record.validate != '数字'}
            defaultValue={record.inputRange ? record.inputRange.split('-')[1] ?? 0 : 0}
            onBlur={e => {
              console.log(record.inputRange)
              const arr = record.inputRange ? record.inputRange.split('-') : null
              arr[1] = e.target.value
              record.inputRange = arr.join('-')
              setRefresh(true)
            }}
          ></InputNumber>
        </div>
      ),
    },
    {
      title: '数字长度',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputNumber disabled={record.validate != '数字'} defaultValue={Number(record.inputLength) ?? 0} onChange={e => { record.inputLength = String(e) }}></InputNumber>
        </div>
      ),
    },
    {
      title: '默认',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox checked={record.isMust == 1 ? true : false} onChange={e => {
            record.isMust = e.target.checked ? 1 : 0
            setRefresh(true)
          }}>是否必答</Checkbox>
        </div>
      ),
    },
  ]

  const xuanxiangguanlianColumns = [
    {
      title: '选项',
      dataIndex: 'title',
      key: 'title',
      width: '10%'
    },
    {
      title: '默认',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record, index) => (
        <div>
          <Button type="link" onClick={e => xxglPush(record)} >添加关联题目</Button>
          {
            relatedTo(record).map((v, i) => {
              return <div style={{ position: 'relative' }}>
                <Button type="link" onClick={e => xxglDelete(record, i)} style={{ position: 'absolute', top: '0px', right: '0px' }} >删除</Button>
                <Select
                  style={{ width: '80%' }}
                  placeholder="请选择想要关联的题目"
                  value={v.topicIdx}
                  onChange={
                    e => {
                      v.topicIdx = e
                      v.xuanstatus = 1
                      v.xianstatus = 1
                      v.options = []
                      setRefresh(true)
                    }
                  }
                >
                  {
                    getdata.filter((v, i) => {
                      return i < dataIndex && v.type != '31' && v.type != '32' && (v.type == '2' || v.type == '1')
                    }).map((v, i) => {
                      return <Option key={v.idx + ''} value={v.idx + ''}>{v.no+'.'+v.title.replace(new RegExp("<.+?>", "g"), '')}</Option>
                    })
                  }
                </Select>
                <div>
                  {
                    v.topicIdx && getdata.filter(a => {
                      return a.idx == v.topicIdx
                    }).length != 0 ?
                      <div style={{ margin: '10px 0' }}>
                        当
                        <Select
                          style={{ margin: '0 5px' }}
                          placeholder="请选择方式"
                          value={v.xuanstatus}
                          onChange={
                            e => {
                              v.xuanstatus = e
                              setRefresh(true)
                            }
                          }
                        >
                          <Option key={1} value={1}>选择</Option>
                          <Option key={2} value={2}>没有选择</Option>
                        </Select>
                        下面选项时
                      </div>
                      : null
                  }
                  {
                    v.topicIdx && getdata.filter(a => {
                      return a.idx == v.topicIdx
                    }).length != 0 ?
                      getdata.filter(a => {
                        return a.idx == v.topicIdx
                      })[0].options.map(a => {
                        return <div>
                          <Checkbox
                            checked={v.options && v.options.includes(a.idx)}
                            onChange={e => {
                              if (v.options) {
                                if (v.options.includes(a.idx)) {
                                  v.options.splice(v.options.indexOf(a.idx), 1)
                                } else {
                                  v.options.push(a.idx)
                                }
                              } else {
                                v.options = [a.idx]
                              }
                              setRefresh(true)
                            }}
                          >{a.title.replace(new RegExp("<.+?>", "g"), '')}</Checkbox>
                        </div>
                      })
                      : null
                  }
                  {
                    v.topicIdx && getdata.filter(a => {
                      return a.idx == v.topicIdx
                    }).length != 0 ?
                      <div style={{ margin: '10px 0' }}>
                        中的
                        <Select
                          style={{ margin: '0 5px' }}
                          placeholder="请选择方式"
                          value={v.xianstatus}
                          onChange={
                            e => {
                              v.xianstatus = e
                              setRefresh(true)
                            }
                          }
                        >
                          <Option key={1} value={1}>其中一个</Option>
                          <Option key={2} value={2}>全部选项</Option>
                        </Select>
                        时,当前选项才会出现
                      </div>
                      : null
                  }
                </div>
              </div>
            })
          }
        </div>
      ),
    },
  ]

  const upMove = (record, index) => {// 选项上移
    const data = getdata[dataIndex].options
    console.log(data)
    if (index != 0) {
      data[index].idx--
      data[index - 1].idx++
      data.sort((a, b) => { return a.idx - b.idx })
      setgetdata(pre => { pre[dataIndex].options = data.concat([]); return pre })
      setRefresh(true)
    }
  }

  const downMove = (record, index) => {// 选项下移
    const data = getdata[dataIndex].options
    if (index != data.length - 1) {
      data[index].idx++
      data[index + 1].idx--
      data.sort((a, b) => { return a.idx - b.idx })
      setgetdata(pre => { pre[dataIndex].options = data.concat([]); return pre })
      setRefresh(true)
    }
  }



  const [paperTypearr, setpaperTypearr] = useState([])

  const getData = () => {
    axios.get('/api/question/template/' + wjid)
      .then(res => {
        console.log(res.data)
        if(!res.data.randomGroup){
          res.data.randomGroup=''
        }
        sethomedata(res.data)
        res.data.questions = res.data.questions.sort((a, b) => { return a.idx - b.idx })

        res.data.questions.filter((v, i) => {
          return v.type != '31' && v.type != '32'
        }).forEach((v, i) => {
          v.no = i + 1
        })
        res.data.questions.forEach((v, i) => {
          if (v.type == '7' || v.type == '1') {
            axios.get('/api/question/answer/question/' + v.id, {
              params: {
                pageSize: '100000'
              }
            })
              .then(res => {
                const brr = [] // 储存处理好的配额答题次数
                const crr = [] // 储存处理好的数据
                v.options.forEach(v => {
                  brr.push(0)
                })
                res.data.data.forEach((a, b) => {
                  a.resultMap = JSON.parse(a.resultMap).sort((a, b) => { return a.index - b.index })
                  crr.push(a.resultMap.filter(v => {
                    return v.isCount == 1
                  })[0])
                })
                crr.forEach((a, b) => {
                  if (a) {
                    brr[a.checked]++
                  }
                })
                v.options.forEach((v, i) => {
                  v.isCount = brr[i]
                })
              })
          }
          v.MovseBool = false
          v.options.forEach(a => {
            a.statusBool = true
          })
        })
        setgetdata(res.data.questions)
        setRefresh(true)
      })
  }

  useEffect(() => {                //获取数据
    // setInterval(()=>{  //自动保存
    //   wancheng()
    // },300000)
    if (wjid != 'null') {
      getData()
    }
    axios.get('/api/sys/dict/paperType')
      .then(res => {
        setpaperTypearr(res.data.labelValues)
      })
  }, [])

  const onChangecheck = e => {//是否必答
    getdata[dataIndex].must = e.target.checked ? 1 : 0
    setRefresh(true)
  }

  const editorRef = useRef(null)
  useEffect(() => {
    // 问题标题富文本输入
    const editor = new E('#div1')//设置富文本
    editorRef.current = editor

    editor.config.excludeMenus = [ //取消那些富文本选项
      'head', // 标题
      'italic',  // 斜体
      'list',  // 列表
      'quote',  // 引用
      'emoticon',  // 表情
      'table',  // 表格
      'video',  // 插入视频
      'code', // 插入代码 
      // 'image', //图片
    ]
    editor.config.onchange = function (newHtml) {
      setdata(pre => {
        if (pre) {
          pre.title = newHtml
        }
        return pre
      })
      setRefresh(true)
    }
    editor.config.onchangeTimeout = 500 // 修改为 500ms
    editor.config.height = 100
    editor.config.uploadImgServer = baseUrl + '/api/common/upload/?appId=cms&appKey=cms@2020'  // 这是java的一个servlet
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024 // 将图片大小限制为 5M
    editor.config.uploadImgMaxLength = 1// 限制一次最多上传 5 张图片
    editor.config.uploadFileName = "file"; // 后端接受上传文件的参数名
    editor.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
    editor.config.showLinkImg = false
    editor.config.uploadImgHooks = {
      customInsert: function (insertImg, result, editor) {
        var url = result.data[0].fullUrl;//获取后台返回的url
        insertImg(audioUrl + url);
      },
      success: function (xhr, editor, result) {
        console.log(xhr, editor, result)
      },
      fail: function (xhr, editor, result) {
        console.log(xhr, editor, result)
      },
      error: function (xhr, editor) {
        console.log(xhr, editor)
      }
    }
    setTimeout(() => {
      editor.config.uploadImgHeaders = {
        'uid': store.getState().user.uid,
        'token': store.getState().user.token,
      }
      editor.create()
    }, 200)
  }, [])


  const [disTop, setdisTop] = useState(null)
  const [doms, setdoms] = useState(null)

  let [isShow, setIsShow] = useState(false)

  const [data, setdata] = useState({})

  const show = (v, i) => {  //展示 / 隐藏 编辑框
    setDataIndex(i)
    if (doms != null) doms.style.marginBottom = 0
    const domwenjuan = document.getElementsByClassName('wenjuan')[i]
    const dom = document.getElementById('div1')
    setdisTop(domwenjuan.offsetTop + domwenjuan.offsetHeight)
    if (doms == domwenjuan || isShow == false) {
      isShow = !isShow
      setIsShow(isShow)
    } else {
      domwenjuan.style.marginBottom = dom.offsetHeight + 40 + 'px'
    }
    // if (v.type == '31') { dom.style.display = 'none' } else { dom.style.display = 'block' }
    editorRef.current.txt.html(v.title);
    setdoms(domwenjuan)
    setdata(getdata[i])
    setRefresh(true)
  }

  useEffect(() => { //isshow 改变后调用
    if (isShow) {
      if (dataIndex == getdata.length) {
        dataIndex -= 1
      }
      const domwenjuan = document.getElementsByClassName('wenjuan')[dataIndex]
      const dom = document.getElementById('wenjuana')
      if (isShow && domwenjuan) domwenjuan.style.marginBottom = dom.offsetHeight + 40 + 'px'
      if (!domwenjuan && isShow) {
        setIsShow(false)
      }
      let img = [...dom.getElementsByTagName('img')]
      if (img.length != 0) {
        img.forEach(v => {
          let widths = v.offsetWidth / 10
          let heights = v.offsetHeight / 10
          v.onwheel = e => {
            console.log(e, widths, heights)
            console.log(v.offsetWidth, v.offsetHeight)
            if (e.deltaY < 0) {
              v.style.width = v.offsetWidth + widths + 'px'
              v.style.height = v.offsetHeight + heights + 'px'
            } else {
              if (v.offsetWidth > (widths)) {
                v.style.width = v.offsetWidth - widths + 'px'
                v.style.height = v.offsetHeight - heights + 'px'
              }
            }
            e.preventDefault();
            domwenjuan.style.marginBottom = dom.offsetHeight + 40 + 'px'
            setTimeout(() => {
              setdisTop(domwenjuan?.offsetTop + domwenjuan?.offsetHeight)
            }, 100)
          }
        })
      }
    }
  }, [isShow, doms])

  const wancheng = e => { //保存/提交/修改
    getdata.forEach((v, i) => {
      delete v.no
      delete v.NPSindex
      delete v.tiaotibool
      delete v.MovseBool
      v.options.forEach(v => {
        delete v.statusBool
        delete v.no
        delete v.isCount
      })
      //设置单项填空 和 多项填空的题目信息
      let arr = v.title?.split('')
      if (v.type == '21') {
        v.options = [{ title: '', idx: 0 }]
      }
      if (v.type == '22') {
        const array = []
        if (v.options.length == 0) {
          arr.forEach((a, i) => {
            if (a == '_' && arr[i + 1] != '_') {
              array.push({ title: '', idx: array.length })
            }
          });
          v.options = array
        }
      }
    })
    if (e == 1) {
      if (wjid == 'null') {
        axios.post('/api/question/template', {
          ...homedata,
          questions: getdata
        }, {
          params: {
            projectId
          }
        })
          .then(res => {
            message.success('完成编辑')
            window.history.go(-1);
          })
      } else {
        axios.put('/api/question/template', {
          ...homedata,
          questions: getdata
        })
          .then(res => {
            message.success('完成编辑')
            window.history.go(-1);
          })
      }
    } else {
      if (wjid != 'null') {
        axios.put('/api/question/template', {
          ...homedata,
          questions: getdata
        })
          .then(res => {
            message.success('完成编辑')
            // document.getElementById('wenjuana').style.display = 'none'
            setIsShow(false)
            if (dataIndex != null && document.getElementsByClassName('wenjuan')[dataIndex]) {
              document.getElementsByClassName('wenjuan')[dataIndex].style.marginBottom = '10px'
            }
            getdata.filter((v, i) => {
              v.options.forEach(a => {
                a.statusBool = true
              })
              return v.type != '31' && v.type != '32'
            }).forEach((v, i) => {
              v.no = i + 1
            })
            setgetdata(JSON.parse(JSON.stringify(getdata)))
          })
      } else {
        if (wjid == 'null') {
          axios.post('/api/question/template', {
            ...homedata,
            questions: getdata
          }, {
            params: {
              projectId
            }
          })
            .then(res => {
              message.success('完成编辑')
              let url = window.location.href.split('/')
              url[url.length - 1] = res.data.id
              window.location.href = url.join('/')
              window.location.reload();
            })
        }
      }
    }
    setRefresh(true)
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [title, setTitle] = useState(null)
  const [remark, setRemark] = useState(null)
  const [type, setType] = useState(null)
  const [thanksMsg, setThanksMsg] = useState(null)
  const [quotaMsg, setQuotaMsg] = useState(null)
  const [category, setCategory] = useState(null)
  const [limitFlag, setLimitFlag] = useState(null)
  const [limitUp, setLimitUp] = useState(null)
  const [limitDown, setLimitDown] = useState(null)
  const [alertThreshold, setAlertThreshold] = useState(null)
  const [alertPhone, setAlertPhone] = useState(null)
  const [isCalcu, setIsCalcu] = useState(null)

  const showModal = () => {
    setTitle(homedata.title)
    setType(homedata.type)
    setCategory(homedata.category)
    setThanksMsg(homedata.thanksMsg)
    setQuotaMsg(quotaMsg)
    setLimitFlag(homedata.limitFlag)
    setLimitUp(homedata.limitUp)
    setLimitDown(homedata.limitDown)
    setAlertThreshold(homedata.alertThreshold)
    setAlertPhone(homedata.alertPhone)
    setIsCalcu(homedata.isCalcu)
    setIsModalVisible(true);
    setTimeout(() => {
      // 问卷说明富文本输入
      const editor2 = new E('#div2')//设置富文本

      editor2.config.excludeMenus = [ //取消那些富文本选项
        'head', // 标题
        'italic',  // 斜体
        'list',  // 列表
        'quote',  // 引用
        'emoticon',  // 表情
        'table',  // 表格
        'video',  // 插入视频‘head’, 
        'list', // 列表
        'image', // 插入图片
        'redo', // 重复
        'qgs', // 去格式
        'link', // 链接
        'code', // 插入代码 
      ]
      editor2.config.onchange = function (newHtml) {
        setdata(pre => {
          if (pre) {
            pre.remark = newHtml
          }
          return pre
        })
        setRefresh(true)
      }
      editor2.config.onchangeTimeout = 500 // 修改为 500ms
      editor2.config.height = 150
      editor2.config.onchange = function (newHtml) {
        setRemark(newHtml)
        setRefresh(true)
      }
      editor2.create()
      editor2.txt.html(homedata.remark)
    }, 200)
    setTimeout(() => {
      // 问卷说明富文本输入
      const editor3 = new E('#div3')//设置富文本

      editor3.config.excludeMenus = [ //取消那些富文本选项
        'head', // 标题
        'italic',  // 斜体
        'list',  // 列表
        'quote',  // 引用
        'emoticon',  // 表情
        'table',  // 表格
        'video',  // 插入视频‘head’, 
        'list', // 列表
        'image', // 插入图片
        'redo', // 重复
        'qgs', // 去格式
        'link', // 链接
        'code', // 插入代码 
      ]
      editor3.config.onchange = function (newHtml) {
        setdata(pre => {
          if (pre) {
            pre.thanksMsg = newHtml
          }
          return pre
        })
        setRefresh(true)
      }
      editor3.config.onchangeTimeout = 500 // 修改为 500ms
      editor3.config.height = 150
      editor3.config.onchange = function (newHtml) {
        setThanksMsg(newHtml)
        setRefresh(true)
      }
      editor3.create()
      editor3.txt.html(homedata.thanksMsg)
    }, 200)
    setTimeout(() => {
      // 问卷说明富文本输入
      const editor3 = new E('#div4')//设置富文本

      editor3.config.excludeMenus = [ //取消那些富文本选项
        'head', // 标题
        'italic',  // 斜体
        'list',  // 列表
        'quote',  // 引用
        'emoticon',  // 表情
        'table',  // 表格
        'video',  // 插入视频‘head’, 
        'list', // 列表
        'image', // 插入图片
        'redo', // 重复
        'qgs', // 去格式
        'link', // 链接
        'code', // 插入代码 
      ]
      editor3.config.onchange = function (newHtml) {
        setdata(pre => {
          if (pre) {
            pre.thanksMsg = newHtml
          }
          return pre
        })
        setRefresh(true)
      }
      editor3.config.onchangeTimeout = 500 // 修改为 500ms
      editor3.config.height = 150
      editor3.config.onchange = function (newHtml) {
        setQuotaMsg(newHtml)
        setRefresh(true)
      }
      editor3.create()
      editor3.txt.html(homedata.quotaMsg)
    }, 200)
  };

  const handleOk = () => {//确认修改标题数据 / 说明数据 / 问卷类型
    sethomedata(pre => {
      pre.title = title
      pre.remark = remark
      pre.type = type
      pre.category = category
      pre.thanksMsg = thanksMsg
      pre.limitFlag = limitFlag
      pre.remain = limitFlag
      pre.limitUp = limitUp
      pre.isCalcu = isCalcu
      pre.limitDown = limitDown
      pre.alertThreshold = alertThreshold
      pre.alertPhone = alertPhone
      pre.quotaMsg = quotaMsg
      return pre
    })
    setRefresh(true)
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deletegetdata = (i) => { //删除问题
    let dataIndexs = dataIndex

    let count = false
    let ti = getdata[dataIndexs]
    if ((ti.skipType == 1 && ti.skipTo) || ti.skipType == 2 || ti.relatedList) {
      count = true
    }
    ti.options.forEach(a => {
      if (a.skipTo || a.optionRelatedTo || a.relatedTo ) {
        count = true
      }
    })
    getdata.forEach(v => {
      if (v.skipTo == ti.idx ) {
        count = true
      }
      if(v.relatedList?.length){
        v.relatedList.forEach((v,c)=>{
          if(v.timuIdx==ti.idx){
            count = true
          }
        })
      }
      v.options.forEach(a => {
        if (a.skipTo == ti.idx ) {
          count = true
        }
        ti.options.forEach(b => {
          if (v.type == '7') {
            if (a.relatedTo && a.relatedTo.includes(',') && a.relatedTo.split(',').includes(ti.idx)) {
              count = true
            }
          } else {
            if (a.relatedTo) {
              let arr = JSON.parse(a.relatedTo)
              if (arr && arr.filter(a => {
                return a.topicIdx == ti.idx && a.options.includes(b.idx)
              }).length > 0) {
                count = true
              }
            }
          }
        })
      })
    })
    if (count) {
      setDeleteModalTi(true)
      return message.error('请确认删除关联或跳题选项后再次删除')
    }else{
      if (i) {
        dataIndexs = i
      }
      getdata.splice(dataIndexs, 1)
  
      getdata.filter((v, i) => {
        return v.type != '31' && v.type != '32'
      }).forEach((v, i) => {
        v.no = i + 1
      })
      if (!i) {
        document.getElementsByClassName('wenjuan')[dataIndexs].style.marginBottom = 0
      }
      if (dataIndexs == getdata.length) {
        dataIndex -= 1
        dataIndexs -= 1
      }
      // editorRef.current.txt.html(getdata[dataIndexs].title)
      if (getdata.length != 0) {
        const title = getdata[dataIndexs].title
        setTimeout(() => {
          getdata[dataIndexs].title = title
          setRefresh(true)
        }, 100)
      }
      setgetdata([...getdata])
      setRefresh(true)
      setIsShow(false)
    }
  }

  const [deleteModalTi,setDeleteModalTi] = useState(false)

  const deleteModalOkTi = () => {
    let dataIndexs = dataIndex
    
    let ti = getdata[dataIndexs]
    getdata.forEach(v => {
      if (v.skipTo == ti.idx ) {
        v.skipTo = null
        v.skipType = 0
      }
      if(v.relatedList?.length){
        let i = null
        v.relatedList.forEach((v,c)=>{
          if(v.timuIdx==ti.idx){
            i=c
          }
        })
        if(i!=null){
          v.relatedList.splice(i,1)
        }
      }
      v.options.forEach(a => {
        if (a.skipTo == ti.idx ) {
          a.skipTo = null
          if(v.options.filter(a=>a.skipTo).length==0){
            v.skipType = 0
          }
        }
        ti.options.forEach(b => {
          if (v.type == '7') {
            if (a.relatedTo && a.relatedTo.includes(',') && a.relatedTo.split(',').includes(ti.idx)) {
              a.relatedTo=a.relatedTo.split(',').filter(v=>v!=ti.idx).join(',')
            }
          } else {
            if (a.relatedTo) {
              let arr = JSON.parse(a.relatedTo)
              if (arr && arr.filter(a => {
                return a.topicIdx == ti.idx && a.options.includes(b.idx)
              }).length > 0) {
                let num = null
                arr.forEach((a,b) => {
                  if(a.topicIdx == ti.idx && a.options.includes(b.idx))num = b
                })
                if(num != null){
                  delete arr[num]
                  a.relatedTo=arr
                }
              }
            }
          }
        })
      })
    })
    setTimeout(()=>{
      getdata.splice(dataIndexs, 1)

      getdata.filter((v, i) => {
        return v.type != '31' && v.type != '32'
      }).forEach((v, i) => {
        v.no = i + 1
      })
        document.getElementsByClassName('wenjuan')[dataIndexs].style.marginBottom = 0
      if (dataIndexs == getdata.length) {
        dataIndex -= 1
        dataIndexs -= 1
      }
      // editorRef.current.txt.html(getdata[dataIndexs].title)
      if (getdata.length != 0) {
        const title = getdata[dataIndexs].title
        setTimeout(() => {
          getdata[dataIndexs].title = title
          setRefresh(true)
        }, 100)
      }
      setgetdata([...getdata])
      setRefresh(true)
      setIsShow(false)
      setDeleteModalTi(false)
    },1000)
  }

  const juzhenxuanze = (value) => { //矩阵选择
    getdata[dataIndex].chooseItemNames = document.getElementById("txtArea").value.split(/[\s\n]/).map((v, i) => {
      return v
    })
    const domwenjuan = document.getElementsByClassName('wenjuan')[dataIndex]
    setdisTop(domwenjuan?.offsetTop + domwenjuan?.offsetHeight)
    setgetdata(getdata)
    setRefresh(true)
  }

  const NPSfun = (b, a) => {   //NPS 点击
    a.NPSindex = b
    setRefresh(true)
  }

  const mouseover = (b, a, i) => {  //NPS 划入
    if (a.type == '52') {
      let domarr = [...document.getElementsByClassName('NPS' + i)]
      domarr.forEach((v, i) => {
        if (i <= b) {
          v.style.background = 'none'
        } else {
          v.style.background = '#fff'
        }
      })
    } else if (a.type == '56') {
      let domarr = [...document.getElementsByClassName('xing' + i)]
      domarr.forEach((v, i) => {
        if (i <= b) {
          v.style.color = '#00b4ff'
        } else {
          v.style.color = '#ddd'
        }
      })
    }
  }

  const mouseout = (b, index, a, i) => {
    if (a.type == '52') {
      let domarr = [...document.getElementsByClassName('NPS' + i)]
      domarr.forEach((v, i) => {  //NPS 划出
        if (i <= a.NPSindex && a.NPSindex != null) {
          v.style.background = 'none'
        } else {
          v.style.background = '#fff'
        }
      })
    } else if (a.type == '56') {
      let domarr = [...document.getElementsByClassName('xing' + i)]
      domarr.forEach((v, i) => {
        if (i <= a.NPSindex && a.NPSindex != null) {
          v.style.color = '#00b4ff'
        } else {
          v.style.color = '#ddd'
        }
      })
    }
    setRefresh(true)
  }

  const [isdabl, setIsdabl] = useState(false)
  const [dablvalue, setDablvalue] = useState('')

  const showModaldabl = () => { //多项添加
    setIsdabl(true);
  };

  const handleOkdabl = () => {
    let brr = [...getdata[dataIndex].options]
    dablvalue.split(/[\s\n]/).forEach((v, i) => {
      brr.push({ title: v, idx: brr.length })
    })
    setgetdata(pre => {
      pre[dataIndex].options = brr
      return pre
    })
    setTimeout(() => {
      setTopHeight()
    }, 100)
    setIsdabl(false);
  };

  const handleCanceldabl = () => {
    setIsdabl(false);
  };

  const [cv, setCv] = useState(false)

  const [cvkuai, setCvkuai] = useState(null)

  const cvOk = (bool) => {
    let dataIndexs = dataIndex
    if (cvkuai) {
      dataIndexs = cvkuai
    }
    if (bool) {
      let obj = JSON.parse(JSON.stringify(getdata[dataIndexs]))
      delete obj.id
      getdata.splice(dataIndexs, 0, obj)
      getdata.filter((v, i) => {
        return v.type != '31' && v.type != '32'
      }).forEach((v, i) => {
        v.no = i + 1
      })
      setTimeout(() => {
        show(getdata[dataIndexs], dataIndexs)
      }, 100)
    } else {
      getdata.push(JSON.parse(JSON.stringify(getdata[dataIndexs])))
      getdata.filter((v, i) => {
        return v.type != '31' && v.type != '32'
      }).forEach((v, i) => {
        v.no = i + 1
      })
      setTimeout(() => {
        show(getdata[getdata.length - 1], getdata.length - 1)
        document.getElementById('wenjuana').scrollIntoView(0, 0, 0, 0, false)
      }, 100)
    }
    setgetdata([...getdata])
    setCv(false)
    setCvkuai(null)
    setIsShow(false)
    showHeight()
    console.log(getdata)
  }

  const cvCancel = () => {
    setCv(false)
  }

  const [guanlian, setGuanlian] = useState(false) // model 开关

  const guanlianRemake = () => {//关联清除
    getdata[dataIndex].relatedList = null
    setGuanlian(false)
  }

  const guanlianOk = () => {//关联完成  timuId 关联对象的id  options 关联的选项
    getdata[dataIndex].relatedList = guanlianlist
    setGuanlian(false)
  }

  const guanlianCancel = () => {//关联关闭
    setGuanlian(false)
  }

  const guanlianModal = () => {//关联打开
    if (getdata[dataIndex].relatedList != null) {
      setGuanlianlist(getdata[dataIndex].relatedList)
    } else {
      setGuanlianlist([{ timuId: null, options: null }])
    }
    setGuanlian(true)
  }

  const [guanlianlist, setGuanlianlist] = useState([{ timuId: null, options: null }])

  const OptionValueFunction = (value, a, v) => { // 关联选择关联选项
    console.log(v)
    if (v.options != null && v.options.includes(value)) {
      const index = v.options.indexOf(value)
      v.options.splice(index, 1)
      setRefresh(true)
    } else {
      if (v.options == null) {
        v.options = [value]
      } else {
        v.options = [...v.options, value]
      }
      setRefresh(true)
    }
    setRefresh(true)
  }

  const jilianxuanxiang = (e, v) => {
    let arr = e.split('\n')
    let brr = []
    arr.forEach(v => {
      v = v.split('/')
      recursive(brr, v, 0)
    })
    v.multiLinkList = brr
    v.validate = e
  }

  const recursive = (brr, a, b) => {
    if (a.length == b) return
    let count = 0
    brr.forEach(v => {
      console.log(v.value == a[b])
      if (v.value == a[b]) {
        if (v.children) {
          return recursive(v.children, a, b + 1)
        } else {
          v.children = []
          return recursive(v.children, a, b + 1)
        }
      } else {
        count++
      }
    })
    if (count == brr.length) {
      brr.push({ value: a[b], label: a[b] })
      return recursive(brr, a, b)
    }
  }

  const [tiaoModal, setTiaoModal] = useState(false)

  const tiaotiModal = () => {
    setTiaoModal(true)
  }

  const tiaotiOk = () => {
    setTiaoModal(false)
  }

  const tiaotiCancel = () => {
    setTiaoModal(false)
  }

  const [duoxiangtiankongModel, setDuoxiangtiankongModel] = useState(false)

  const [duoxiangtiankongData, setDuoxiangtiankongData] = useState([])

  const duoxiangtiankongyanzheng = () => {
    getdata.forEach((v, i) => {//处理多项天空
      v.tiaotibool = true
      let arr = v.title.split('')
      if (v.type == '22' && i == dataIndex) {
        if (v.options.length == 0) {
          arr.forEach((a, i) => {
            if (a == '_' && arr[i + 1] != '_') {
              duoxiangtiankongData.push({ title: '', idx: duoxiangtiankongData.length })
              setDuoxiangtiankongData(duoxiangtiankongData)
            }
          });
        } else {
          setDuoxiangtiankongData(v.options)
        }
      }
    })
    setDuoxiangtiankongModel(true)
  }

  const duoxiangtiankongOk = () => {
    console.log(duoxiangtiankongData)
    getdata[dataIndex].options = duoxiangtiankongData
    setDuoxiangtiankongModel(false)
    setDuoxiangtiankongData([])
  }

  const duoxiangtiankongCancel = () => {
    setDuoxiangtiankongModel(false)
    setDuoxiangtiankongData([])
  }

  const Moseover = (v, e) => {
    v.MovseBool = true
    setRefresh(true)
  }

  const Moseout = (v, e) => {
    v.MovseBool = false
    setRefresh(true)
  }

  const showHeight = () => {
    let domwenjuan = document.getElementsByClassName('wenjuan')
    domwenjuan = [...domwenjuan]
    domwenjuan.forEach(v => {
      v.style.marginBottom = '0px'
    })
  }

  const timuUp = (i, e) => {
    if (i == 0) return
    const arr = getdata[i]
    arr.MovseBool = false
    arr.no -= 1
    getdata[i - 1].no += 1
    getdata.splice(i, 1)
    getdata.splice(i - 1, 0, arr)
    setgetdata([...getdata])
    setDataIndex(dataIndex - 1)
    setRefresh(true)
    setIsShow(false)
    e.stopPropagation()
    showHeight()
  }

  const timuDown = (i, e) => {
    if (i == getdata.length - 1) return
    const arr = getdata[i]
    arr.MovseBool = false
    arr.no += 1
    getdata[i + 1].no -= 1
    getdata.splice(i, 1)
    getdata.splice(i + 1, 0, arr)
    setgetdata([...getdata])
    setDataIndex(dataIndex + 1)
    setRefresh(true)
    setIsShow(false)
    e.stopPropagation()
    showHeight()
    console.log(getdata)
  }

  const timuMaxUp = (i, e) => {
    const arr = getdata[i]
    arr.MovseBool = false
    getdata.splice(i, 1)
    getdata.unshift(arr)
    getdata.forEach((v, i) => {
      v.no = i + 1
    })
    setgetdata([...getdata])
    setDataIndex(0)
    setRefresh(true)
    setIsShow(false)
    e.stopPropagation()
    showHeight()
  }

  const timuMaxDown = (i, e) => {
    const arr = getdata[i]
    arr.MovseBool = false
    getdata.splice(i, 1)
    getdata.push(arr)
    getdata.forEach((v, i) => {
      v.no = i + 1
    })
    setgetdata([...getdata])
    setDataIndex(getdata.length - 1)
    setRefresh(true)
    setIsShow(false)
    e.stopPropagation()
    showHeight()
  }

  const [charuModel, setCharuModel] = useState(false)

  const charuOk = () => {
    if (charuType == null) {
      message.error('请选择插入题目类型')
      return
    }
    let obj = {
      idx: null,
      no: null,
      title: '',
      group: charuType?.length > 1 ? charuType[0] : '1',
      type: charuType,
      must: 1,
      isMultiSelect: charuType == 2 ? 1 : 0,
      chooseItemNames: [],
      options: []
    }
    delete obj.id
    getdata.splice(dataIndex + 1, 0, obj)
    getdata.filter((v, i) => {
      return v.type != '31' && v.type != '32'
    }).forEach((v, i) => {
      v.no = i + 1
    })
    setTimeout(() => {
      show(getdata[dataIndex + 1], dataIndex + 1)
    }, 100)
    setRefresh(true)
    setCharuModel(false)
    setgetdata([...getdata])
    setTimeout(() => {
      editorRef.current.txt.html('');
    }, 100)
    // setCharuType(null)
  }

  const charuCancel = () => {
    setCharuModel(false)
    setCharuType(null)
  }

  const [charuType, setCharuType] = useState(null)

  const [peieBools, setPeieBools] = useState(false)
  const [peieobj, setPeieobj] = useState({})
  const [peiearr, setPeiearr] = useState([])

  const peieModalOk = () => {
    setPeieBools(false)
    console.log(peiearr)
    peieobj.relatedTo = peiearr.join(',')
    setRefresh(true)
  }

  const peieModalKai = (record, index) => {
    setPeieBools(true)
    setPeieobj(record)
    setPeiearr(record.relatedTo ? record.relatedTo.split(',').filter(v=>v>getdata[dataIndex].idx) : [])
  }



  const [xxglBools, setXxBools] = useState(false)
  const [xxglArr, setXxArr] = useState([])
  const [xxglObj, setXxObj] = useState({})

  const xxglModalKai = (record) => {
    setXxBools(true)
  }

  const xxglModalOk = () => {
    setXxBools(false)
    getdata[dataIndex].options.forEach(v => {
      v.relatedTo = JSON.stringify(v.relatedTo)
    })
    setRefresh(true)
  }

  const xxglModalCancel = () => {
    setXxBools(false)
    getdata[dataIndex].options.forEach(v => {
      v.relatedTo = JSON.stringify(v.relatedTo)
    })
    setRefresh(true)
  }

  const xxglPush = (record) => {
    record.relatedTo.push({})
    setRefresh(true)
  }

  const xxglDelete = (record, index) => {
    if (record.relatedTo.length == 0) {
      record.relatedTo = [{}]
    } else {
      record.relatedTo.splice(index, 1)
    }
    setRefresh(true)
  }

  const relatedTo = (record) => {
    try {
      if (record.relatedTo) {
        if (typeof record.relatedTo != 'string') {
          return record.relatedTo
        } else {
          record.relatedTo = JSON.parse(record.relatedTo)
        }
      } else {
        record.relatedTo = [{}]
      }
      return record.relatedTo
    } catch (e) {
      return record.relatedTo
    }
  }

  const [groupArr, setGroupArr] = useState([])
  const [randarShowBool, setRandarShowBool] = useState(false)

  const randarShow = (e) => {
    e.stopPropagation()
    const arr = []
    getdata.forEach(v => {
      if (v.subGroup && arr.filter(a => v.subGroup == a.Group).length == 0) {
        arr.push({ Group: v.subGroup })
      }
    })
    setGroupArr(arr)
    setRandarShowBool(true)
  }

  const Grouprandar = (bool, text) => {
    let arr = []
    if (homedata.randomGroup) {
      arr = homedata.randomGroup.split(',')
    }
    if (bool) arr.push(text)
    else {
      let count = null
      arr.forEach((v, i) => {
        if (v == text) count = i
      })
      arr.splice(count, 1)
    }
    homedata.randomGroup = arr.join(',')
    setGroupArr([...groupArr])
    setRefresh(true)
  }

  let GroupColums = [
    {
      title: '小组',
      dataIndex: 'Group',
      key: 'Group',
    },
    {
      title: '是否需要随机',
      dataIndex: 'Group',
      key: 'Group',
      render: (text) => <Checkbox checked={homedata.randomGroup.split(',').includes(text+'')} onChange={e => Grouprandar(e.target.checked, text)}></Checkbox>
    },
  ]

  const [abbrBool, setAbbrBool] = useState(false)
  const [addAbbrBool, setAddAbbrBool] = useState(false)

  const AbbrColums = [
    {
      title: '数据库',
      dataIndex: 'questionTempName',
      key: 'questionTempName',
      width: '30%',
      render: (text, record) => text
    },
    {
      title: '分数',
      dataIndex: 'grade',
      key: 'grade',
      render: (text, record) => text
    },
    {
      title: '总分',
      dataIndex: 'totalPoints',
      key: 'totalPoints',
      render: (text, record) => text
    },
    {
      title: '操作',
      dataIndex: 'totalPoints',
      key: 'totalPoints',
      render: (text, record , index) => (
        <Button type='link' onClick={e=>{deleteAbbr(index)}}>删除</Button>
      )
    },
  ]

  const [projectData, setProjectData] = useState([])
  const [abbrData, setAbbrData] = useState([])

  const [proId,setProId] = useState(null)
  const [questionTempId,setQuestionTempId] = useState(null)
  const [questionTempName,setQuestionTempName] = useState(null)
  const [grade,setGrade] = useState(null)
  const [totalPoints,setTotalPoints] = useState(null)

  const AbbrModal = (e) => {
    e.stopPropagation()
    setAbbrBool(true)
  }

  useEffect(() => {
    abbrDataGet()
    setQuestionTempId(null)
    setQuestionTempName(null)
  }, [proId])

  const abbrDataGet = () => {
    axios.get('/api/question/template/all', {
      params: {
        projectId:proId
      }
    })
      .then(res => {
        setAbbrData(res.data.data)
      })
  }
  

  const addAbbrModal = (e) => {
    setAddAbbrBool(true)
    axios.get('/api/core/project/list', {
      params: {
        queryCondition: 'EQ:type=4',
        pageSize: '10000'
      }
    }).then(res => {
      setProjectData(res.data.data)
      console.log(res)
    })
  }

  const addAbbrOk = () => {
    setAddAbbrBool(false)
    if(!homedata.randomTemp){
      homedata.randomTemp=[]
    }
    homedata.randomTemp.push({
      questionTempId,
      questionTempName,
      grade,
      totalPoints
    })
    homedata.randomTemp=JSON.parse(JSON.stringify(homedata.randomTemp))
    setRefresh(true)
    setQuestionTempId(null)
    setQuestionTempName(null)
    setGrade(null)
    setTotalPoints(null)
  }
  
  const deleteAbbr = (index) => {
    homedata.randomTemp.splice(index,1)
    homedata.randomTemp=JSON.parse(JSON.stringify(homedata.randomTemp))
    setRefresh(true)
  }

  const [wenziMadolBool,setWenziModalBool] = useState(false)

  const wenziMadolOk = () => {
    setWenziModalBool(false)
  }

  const wenziMadolUpdata = () => {
    if(getdata[dataIndex].wordReplace){
      getdata[dataIndex].wordReplace.push({
        topicIdx:null,
        tihuanBool:false,
        tihuanTitle:''
      })
      getdata[dataIndex].wordReplace=[...getdata[dataIndex].wordReplace]
      setgetdata([...getdata])
      setRefresh(true)
    }else{
      getdata[dataIndex].wordReplace=[{
        topicIdx:null,
        tihuanBool:false,
        tihuanTitle:''
      }]
      setgetdata([...getdata])
      setRefresh(true)
    }
  }

  const wenziMadolDelete = (i) => {
    getdata[dataIndex].wordReplace.splice(i,1)
    getdata[dataIndex].wordReplace=[...getdata[dataIndex].wordReplace]
    setgetdata([...getdata])
    setRefresh(true)
  }

  const wenziMadolTableColumns = [
    {
      title: '选择问题',
      dataIndex: 'topicIdx',
      key: 'topicIdx',
      width: '30%',
      render: (text, record) => (
        <Select defaultValue={text} style={{ width: 300 }} onChange={e=>{console.log(e);record.topicIdx=e}}>
          {
            getdata.filter((v,i)=>{
              return i<dataIndex&&(
                v.type == '1' ||
                v.type == '2' ||
                v.type == '21'
              )
            }).map(a=>{
              return <Option value={a.idx}>{a.no+'.'+a.title.replace(new RegExp("<.+?>", "g"), '')}</Option>
            })
          }
        </Select>
      )
    },
    {
      title: '是否开启',
      dataIndex: 'tihuanBool',
      key: 'tihuanBool',
      render: (text, record) => (<Checkbox defaultChecked={text} onClick={e=>{record.tihuanBool=e.target.checked;setRefresh(true)}}></Checkbox>)
    },
    {
      title: '替换文字',
      dataIndex: 'tihuanTitle',
      key: 'tihuanTitle',
      render: (text, record , index) => (
        <Input defaultValue={text} onChange={e=>record.tihuanTitle=e.target.value}></Input>
      )
    },
    {
      title: '操作',
      dataIndex: 'tihuanTitle',
      key: 'tihuanTitle',
      render: (text, record , index) => (
        <Button type='link' onClick={e=>{wenziMadolDelete(index)}}>删除</Button>
      )
    },
  ]

  return (
    <div id="wangEditor" style={{ width: '100%', height: heights + 'px', overflow: 'auto', background: '#fff', position: 'relative' }}>
      <Modal
        visible={wenziMadolBool}
        onCancel={e=>setWenziModalBool(false)}
        destroyOnClose
        width='50%'
        title="文字替换"
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={wenziMadolOk}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={e=>setWenziModalBool(false)}
          >
            取消
          </Button>
        ]}
      >
          <Table columns={wenziMadolTableColumns} dataSource={getdata[dataIndex]?.wordReplace} pagination={false}></Table>
          <div><Button type='link' onClick={e=>{wenziMadolUpdata()}}>添加</Button></div>
      </Modal>
      <Button onClick={e => { wancheng(2) }} type='primary' style={{ position: 'fixed', right: '5%', top: '10%', zIndex: '100' }}>
        保存
      </Button>
      <Modal
        title="您确认需要删除么"
        visible={deleteModalTi}
        width='80%'
        onCancel={e=>setDeleteModalTi(false)}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={deleteModalOkTi}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={e=>setDeleteModalTi(false)}
          >
            取消
          </Button>
        ]}
      >
        您确认需要删除么
      </Modal>
      <Modal
        title="您确认需要删除么"
        visible={deleteModal}
        width='80%'
        onCancel={e=>setDeleteModal(false)}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={deleteModalOk}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={e=>setDeleteModal(false)}
          >
            取消
          </Button>
        ]}
      >
        您确认需要删除么
      </Modal>
      <Modal
        title="选项关联"
        visible={xxglBools}
        width='80%'
        onCancel={xxglModalCancel}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={xxglModalOk}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={xxglModalCancel}
          >
            取消
          </Button>
        ]}
      >
        <Table columns={xuanxiangguanlianColumns} dataSource={getdata[dataIndex]?.options} ></Table>
      </Modal>
      <Modal
        title="配置配额"
        visible={peieBools}
        onCancel={() => { setPeieBools(false) }}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={peieModalOk}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={() => { setPeieBools(false) }}
          >
            取消
          </Button>,
        ]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="请选择想要关联的题目"
          defaultValue={peiearr}
          onChange={
            e => {
              console.log(e)
              setPeiearr(e)
            }
          }
        >
          {
            getdata.filter((v, i) => {
              return i > dataIndex && v.type != '31' 
            }).map((v, i) => {
              return <Option key={v.idx + ''} value={v.idx + ''}>{v.no+'.'+v.title.replace(new RegExp("<.+?>", "g"), '')}</Option>
            })
          }
        </Select>
      </Modal>
      <Modal
        title="插入新题"
        visible={charuModel}
        onCancel={charuCancel}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={charuOk}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={charuCancel}
          >
            取消
          </Button>,
        ]}
      >
        <Row>
          <Col span='4'>选择插入类型:</Col>
          <Col span='20'>
            <Select style={{ width: 120 }} onChange={(e) => { setCharuType(e) }}>
              <Option value="31">分页</Option>
              <Option value="32">段落说明</Option>
              <Option value="1">单选题</Option>
              <Option value="2">多选题</Option>
              <Option value="21">单项填空</Option>
            </Select>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="设置属性验证"
        width='80%'
        visible={duoxiangtiankongModel}
        onCancel={duoxiangtiankongCancel}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={duoxiangtiankongOk}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={duoxiangtiankongCancel}
          >
            取消
          </Button>,
        ]}
      >
        <Row>
          <Col span='4'>当前题目:</Col>
          <Col span='20'>
            <div dangerouslySetInnerHTML={{ __html: getdata[dataIndex]?.title }}></div>
          </Col>
          <Col span='24'>
            <Table columns={duoxiangtiankongColumns} dataSource={duoxiangtiankongData} style={{ padding: '0' }} pagination={false} />
          </Col>
        </Row>
      </Modal>
      <Modal
        title="跳题"
        visible={tiaoModal}
        onCancel={tiaotiCancel}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={tiaotiOk}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={tiaotiCancel}
          >
            取消
          </Button>,
        ]}
      >
        <Row>
          <Col span='8'>
            <Checkbox checked={getdata[dataIndex]?.skipType == 2} onClick={e => {
              if (e.target.checked) {
                getdata[dataIndex].skipType = 2
              } else {
                getdata[dataIndex].skipType = 0
                getdata[dataIndex].skipTo = null
              }
              setRefresh(true)
            }}>无条件跳题</Checkbox>
            {
              getdata[dataIndex]?.skipType == 2 ? '，填写此题后跳转到' : null
            }
          </Col>
          <Col span='16'>
            {
              getdata[dataIndex]?.skipType == 2 ?
                <Select style={{ width: '100%' }} defaultValue={getdata[dataIndex]?.skipTo} onChange={e => { getdata[dataIndex].skipTo = e; setRefresh(true) }}>
                  <Option value={-1}>直接结束作答</Option>
                  <Option value={0}>直接跳转到末尾</Option>
                  {
                    getdata.filter(v => { return v.type != '31' && v.type != '32' && v.no > getdata[dataIndex].no }).map(v => {
                      return <Option value={v.idx}>第{v.no}题</Option>
                    })
                  }
                </Select>
                : null
            }
          </Col>
          {
            getdata[dataIndex]?.type == '1' ?
              <>
                <Col span='8'>
                  <Checkbox checked={getdata[dataIndex]?.skipType == 1} onClick={e => {
                    if (e.target.checked) {
                      getdata[dataIndex].skipType = 1
                      getdata[dataIndex].skipTo = null
                    } else {
                      getdata[dataIndex].skipType = 0
                      getdata[dataIndex].skipTo = null
                    }
                    setRefresh(true)
                  }}>选项跳题</Checkbox>
                </Col>
                <Col span='16'></Col>
                {//
                  getdata[dataIndex]?.skipType == 1 ?
                    <Col span='24'>
                      <Table columns={tiaoti} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
                    </Col>
                    : null
                }
              </>
              //
              : null
          }
        </Row>
      </Modal>
      <Modal
        title="关联问题"
        visible={guanlian}
        onCancel={guanlianCancel}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            onClick={guanlianRemake}
          >
            清空关联
          </Button>,
          <Button
            type="primary"
            key="ok"
            onClick={guanlianOk}
          >
            确认
          </Button>,
          <Button
            type="primary"
            key="cancel"
            onClick={guanlianCancel}
          >
            取消
          </Button>,
        ]}
      >
        {
          guanlianlist.map(v => {
            return <Row>
              <Col span='5'>当前题目:</Col>
              <Col span='19'>第{getdata[dataIndex]?.no}题</Col>
              <Col span='5'>关联题目:</Col>
              <Col span='13'>
                <Select style={{ width: '100%' }} defaultValue={v.timuId?v.timuId + ',' + v.timuIdx + ',' + v.no:null} onChange={e => { v.timuId = e.split(',')[0]; v.timuIdx = e.split(',')[1]; v.no = e.split(',')[2]; setRefresh(true) }}>
                  {
                    getdata.filter((v, i) => {
                      return (v.type == '1' || v.type == '2' || v.type == '52') && i < dataIndex
                    }).map(v => {
                      return <Option value={v.id + ',' + v.idx + ',' + v.no}>{v.no+'.'+v.title.replace(new RegExp("<.+?>", "g"), '')}{v.type == '1' ? '[单选]' : '[多选]'}</Option>
                    })
                  }
                </Select>
              </Col>
              <Col span='6'><Button type='link' onClick={e => { setGuanlianlist([...guanlianlist, { timuId: null, options: null }]) }}>+添加关联信息</Button></Col>
              <Col span='5'></Col>
              {
                v.timuId != null ?
                  <Col span='19'>
                    {
                      getdata.filter(a => a.idx == v.timuIdx)[0]?.options.map(a => {
                        return <p><Checkbox value={a.idx} checked={v.options?.includes(a.idx)} onChange={e => { OptionValueFunction(e.target.value, a, v) }}>{a.title}</Checkbox></p>
                      })
                    }
                    中的任意一个 时，“当前题目”才出现
                  </Col>
                  : null
              }
            </Row>
          })
        }
      </Modal>
      <Modal
        title="复制问题"
        visible={cv}
        onCancel={cvCancel}
        destroyOnClose
        footer={[
          null,
        ]}
      >
        <Button onClick={e => { cvOk(true) }} style={{ margin: '0 10px' }}>复制到当前问题后</Button>
        <Button onClick={e => { cvOk(false) }}>复制到所有问题后</Button>
      </Modal>
      <Modal
        title="设置标题"
        width='1000px'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Row>
          <Col span="5" style={{ margin: '10px 0' }}>问卷标题:</Col>
          <Col span="19" style={{ margin: '10px 0' }}><Input defaultValue={title} onChange={e => { setTitle(e.target.value) }} /></Col>
          <Col span="5" style={{ margin: '10px 0' }}>问卷说明:</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            <div id='div2'></div>
          </Col>
          <Col span="5" style={{ margin: '10px 0' }}>问卷类型:</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            <Select defaultValue={type?.toString()} onChange={e => { setType(e) }} style={{ width: '100%' }}>
              {
                Object.keys(paperTypearr).map((v, i) => {
                  return <Option value={v}>{paperTypearr[v]}</Option>
                })
              }
            </Select>
          </Col>
          <Col span="5" style={{ margin: '10px 0' }}>问卷作用类型:</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            <Select defaultValue={category} onChange={e => { setCategory(e) }} style={{ width: '100%' }}>
              <Option value={1}>家化</Option>
              <Option value={2}>实效</Option>
            </Select>
          </Col>
          <Col span="5" style={{ margin: '10px 0' }}>感谢信息:</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            <div id='div3'></div>
          </Col>
          <Col span="5" style={{ margin: '10px 0' }}>致歉信息:</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            <div id='div4'></div>
          </Col>
          <Col span="5" style={{ margin: '10px 0' }}>红包功能:</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            总额度上限(大于0视为开启红包功能)<Input defaultValue={limitFlag / 100} onChange={e => { setLimitFlag(Number(e.target.value) * 100) }} />
            下限<Input defaultValue={limitDown / 100} onChange={e => { setLimitDown(Number(e.target.value) * 100) }} />
            上限<Input defaultValue={limitUp / 100} onChange={e => { setLimitUp(Number(e.target.value) * 100) }} />
          </Col>
          <Col span="5" style={{ margin: '10px 0' }}>是否开启计分</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            <Checkbox checked={isCalcu == 1} onClick={e => setIsCalcu(e.target.checked ? 1 : 0)}></Checkbox>
          </Col>
          <Col span="5" style={{ margin: '10px 0' }}>账户余额最低限制(达到限制后会发短信给填写的手机)</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            <Input defaultValue={alertThreshold / 100} onChange={e => { setAlertThreshold(Number(e.target.value) * 100) }} />
          </Col>
          <Col span="5" style={{ margin: '10px 0' }}>报警手机(达到限制后会发短信给填写的手机)</Col>
          <Col span="19" style={{ margin: '10px 0' }}>
            <Input defaultValue={alertPhone} onChange={e => { setAlertPhone(e.target.value) }} />
          </Col>
        </Row>
      </Modal>
      <Modal //分组随机题目
        title="配置随机"
        width='1000px'
        visible={randarShowBool}
        onOk={e => setRandarShowBool(false)}
        onCancel={e => setRandarShowBool(false)}
        destroyOnClose
      >
        <Table columns={GroupColums} dataSource={groupArr} style={{ padding: '0' }} pagination={false} />
      </Modal>
      <Modal
        title="题库库随机题目配置"
        width='1000px'
        visible={abbrBool}
        onOk={e => setAbbrBool(false)}
        onCancel={e => setAbbrBool(false)}
        destroyOnClose>
        <Row>
          <Col span="3">是否开启</Col>
          <Col span="21"><Checkbox checked={homedata.isCalcu == 2} onClick={e => { homedata.isCalcu = e.target.checked ? 2 : 0; setRefresh(true) }}></Checkbox></Col>
        </Row>
        <Button type='link' onClick={e=>addAbbrModal()}>+添加</Button>
        <Table columns={AbbrColums} dataSource={homedata.randomTemp} style={{ padding: '0' }} pagination={false} />
      </Modal>
      <Modal
        title="添加题库"
        width='1000px'
        visible={addAbbrBool}
        onOk={e => addAbbrOk(false)}
        onCancel={e => setAddAbbrBool(false)}
        destroyOnClose>
        <Row>
          <Col span="3">选择项目</Col>
          <Col span="21" style={{marginBottom:'10px'}}><Select style={{ width: '70%' }} defaultValue={proId} onChange={e => { setProId(e) }}>
            {
              projectData.map(v => {
                return <Option value={v.id}>{v.name}</Option>
              })
            }
          </Select>
          </Col>
          <Col span="3">选择问卷</Col>
          <Col span="21" style={{marginBottom:'10px'}}><Select style={{ width: '70%' }} defaultValue={questionTempId?questionTempId+','+questionTempName:null} onChange={e => { setQuestionTempId(e.split(',')[0]);setQuestionTempName(e.split(',')[1]) }}>
            {
              abbrData.map(v => {
                return <Option value={v.id+","+v.title}>{v.title}</Option>
              })
            }
          </Select>
          </Col>
          <Col span="3">设置单题分数</Col>
          <Col span="21" style={{marginBottom:'10px'}}>
            <InputNumber defaultValue={Number(grade) ?? 1} onChange={e => { setGrade(e) }}></InputNumber>
          </Col>

          <Col span="3">设置总分</Col>
          <Col span="21" style={{marginBottom:'10px'}}>
            <InputNumber defaultValue={Number(totalPoints) ?? 1} onChange={e => { setTotalPoints(e) }}></InputNumber>
          </Col>
        </Row>
      </Modal>
      <div style={{ padding: '0 10px', }}>{// 设置是否隐藏序号
      }
        <Checkbox style={{ fontSize: '8px' }} checked={homedata.idxFlag == 1 ? true : false} onChange={e => { e.target.checked == true ? homedata.idxFlag = 1 : homedata.idxFlag = 0; setRefresh(true) }}>隐藏序号</Checkbox>
        <Checkbox style={{ fontSize: '8px' }} checked={homedata.pageFlag == 1 ? true : false} onChange={e => { e.target.checked == true ? homedata.pageFlag = 1 : homedata.pageFlag = 0; setRefresh(true) }}>一页一题</Checkbox>
      </div>
      <div>
        <div style={{ borderBottom: '1px solid #ccc', padding: '0 50px 10px ', }} onClick={e => { showModal() }}>
          <h1 style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>{homedata.title}</h1>
          <h5 style={{ margin: '20px 0' }} dangerouslySetInnerHTML={{ __html: homedata.remark == '' ? '请输入问卷说明' : homedata.remark }} ></h5>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ lineHeight: '30px', marginRight: '20px' }}>问卷类型: {paperTypearr[homedata.type]}</p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ lineHeight: '30px', marginRight: '20px' }}>问卷作用类型: {homedata.category == 1 ? '家化' : null}{homedata.category == 2 ? '实效' : null}</p>
          </div>
          <h5 style={{ margin: '20px 0' }} dangerouslySetInnerHTML={{ __html: homedata.thanksMsg }} ></h5>
          <Button onClick={randarShow}>配置小组随机题目</Button>
          <Button style={{ marginLeft: '10px' }} onClick={AbbrModal}>配置数据库</Button>
        </div>
        {
          getdata.length != 0 ?
            getdata.map((v, i) => {
              return <Row onMouseEnter={e => { Moseover(v, e) }} onMouseLeave={e => { Moseout(v, e) }} className='wenjuan'
                style={{
                  padding: '40px 100px',
                  position: 'relative',
                  margin: '10px 0',
                  background: v.MovseBool ? '#f2f2f2' : 'none',
                  borderBottom: '1px solid #f2f2f2',
                }}
                onClick={e => show(v, i)}
              >
                <Col span='24' style={{ display: v.type == '32' ? 'block' : 'flex', }}>
                  { //分组情况 答题时不会显示
                    v.type != '31' &&
                      v.type != '32' &&
                      v.subGroup &&
                      v.subGroup != 0 ? <>
                      <span style={{ display: 'inline-block', width: '10px', lineHeight: '50px', marginRight: '10px' }}>组:{v.subGroup}</span>
                    </>
                      : null
                  }
                  { //显示隐藏 必填星号 和 序号
                    v.type != '31' &&
                      v.type != '32' ? <><span style={{ color: 'red', display: 'inline-block', width: '10px', lineHeight: '50px', marginLeft: '10px' }}>{v.must == 1 ? '*' : null}</span>
                      {
                        homedata.idxFlag != 1 ?
                          <b style={{ fontWeight: '600', lineHeight: '50px' }}>{v.no ? v.no + '.' : null}</b>
                          : null
                      }
                    </>
                      : null
                  }
                  {
                    v.type == '32' ? <p style={{ width: '100%', textAlign: 'center' }}></p> : null
                  }
                  { //显示隐藏 题目名称
                    v.type != '31' ? <span className='title' style={{ fontWeight: '600', lineHeight: '50px', flex: 1 }} dangerouslySetInnerHTML={{ __html: v.title == '' ? '请编辑题目' : v.title }} ></span> : null
                  }
                  { // 后面的小标题 标记是什么题
                    v.type != '31' ? <span style={{ marginLeft: "10px", color: "#aaa", fontSize: '5px', border: '0.5px #999 solid', padding: '0 5px', borderRadius: '5px', height: '20px' }}>{typedata.filter(a => { return a.code == v.type })[0]?.title}</span> : null
                  }
                  { //显示隐藏 分页
                    v.type == '31' ? <p style={{ height: '50px', width: '100%', borderBottom: '1px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column ' }}>
                      <p>分页</p>
                      第
                      {
                        getdata.filter(v => {
                          return v.type == '31'
                        })?.map((a, i) => {
                          if (a.idx == v.idx) { return i + 1 }
                        }).sort((a, b) => {
                          return b - a
                        })[0]
                      }
                      页/第
                      {
                        getdata.filter(v => {
                          return v.type == '31'
                        }).length
                      }
                      页
                    </p> : null
                    //
                  }
                </Col>
                <Col span='24'>
                  { //单选题
                    //
                    v.type == '1' ||
                      v.type == '62' ?
                      <Radio.Group>
                        {
                          v.options?.map((a, b) => {
                            return <div>
                              <Radio value={b} style={{ display: 'flex' }}>{a.title}
                                {
                                  a.isNeedGap == 1 ?
                                    <>
                                      {
                                        a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                      }
                                      <Input style={{ width: '200px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                                    </>
                                    : null
                                }
                                {
                                  a.relatedTo && a.relatedTo.length != 4 && typeof a.relatedTo == 'string' ?
                                    JSON.parse(a.relatedTo).map(v => {
                                      return <span style={{ color: 'red', marginLeft: '10px' }}>依赖于{getdata.filter(a => a.idx == v.topicIdx)[0]?.no}题,第{v?.options.map(v => v + 1)?.join(',')}选项</span>
                                    })
                                    : null
                                }
                              </Radio>
                              <p style={{ paddingLeft: '23px', color: '#ccc', fontSize: '8px' }}>{a.remark}</p>
                            </div>
                          })
                        }
                      </Radio.Group> : null
                    //
                  }
                  { //多选题
                    v.type == '2' ? v.options?.map((a, b) => {
                      return <div>
                        <Checkbox style={{  }} value={b}>{a.title}{
                          a.isNeedGap == 1 ?
                            <>
                              {
                                a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                              }
                              <Input style={{ width: '200px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                            </>
                            : null
                        }
                          {
                            a.relatedTo && a.relatedTo.length != 4 && typeof a.relatedTo == 'string' ?
                              JSON.parse(a.relatedTo).map(v => {
                                return <span style={{ color: 'red', marginLeft: '10px' }}>依赖于{getdata.filter(a => a.idx == v.topicIdx)[0]?.no}题,第{v?.options.map(v => v + 1)?.join(',')}选项</span>
                              })
                              : null
                          }
                        </Checkbox>

                        <p style={{ paddingLeft: '23px', color: '#ccc', fontSize: '8px' }}>{a.remark}</p>
                      </div>
                    }) : null
                  }
                  { //下拉框
                    v.type == '3' ?
                      <Select style={{ width: 120 }}>
                        {
                          v.options?.map((a, b) => {
                            return <Option value={b}>{a.title}</Option>
                          })
                        }
                      </Select> : null
                    //
                  }
                  { //多选下拉框
                    v.type == '4' ?
                      <Select
                        mode="tags"
                        style={{ width: '100%' }}
                      >
                        {
                          v.options?.map((a, b) => {
                            return <Option value={b}>{a.title}</Option>
                          })
                        }
                      </Select> : null
                    //
                  }
                  { //排序
                    v.type == '5' ||
                      v.type == '7' ? v.options?.map((a, b) => {
                        return <div>
                          <div style={{ width: '20px', height: '20px', display: 'inline-block', background: '#e2e2e0' }}></div>
                          {a.title}{
                            a.isNeedGap == 1 ?
                              <>
                                {
                                  a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                }
                                <Input style={{ width: '200px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                              </>
                              : null
                          }
                        </div>
                      }) : null
                  }
                  { //多级联动
                    v.type == '6' ? <Cascader options={v.multiLinkList} placeholder="请选择" /> : null
                  }
                  { // 填空
                    v.type == '21' ||
                      v.type == '61' ||
                      v.type == '63' ?
                      <Input prefix={
                        v.type == '61' ? <UserOutlined /> :
                          v.type == '63' ? <PhoneOutlined /> : null
                      } /> : null
                    //
                  }
                  { //矩阵填空
                    v.type == '23' ?
                      v.options.map((v, i) => {
                        return <Row style={{ marginTop: '10px' }}>
                          <Col span='14' style={{ marginRight: '20px' }}>{v.title}</Col>
                          <span style={{ color: 'red', display: 'inline-block', width: '10px' }}>{v.isNeedGap == 1 ? '*' : null}</span>
                          <input />
                        </Row>
                      }) : null
                    //
                  }
                  { //表格填空
                    v.type == '24' ?
                      <div>
                        <Row>
                          <Col span='8'>
                            { }
                          </Col>
                          {
                            v.options.map(a => {
                              return <Col span={parseInt(16 / v.options.length)} style={{ textAlign: 'center', marginLeft: '12px' }}>
                                <p>{a.title}</p>
                                <p style={{ color: '#ff9900' }}>{a.score}</p>
                              </Col>
                            })
                          }
                        </Row>
                        {
                          v.chooseItemNames.map((a, i) => {
                            return <Row style={{ borderBottom: '0.5px solid #ddd', padding: '10px 0', marginBottom: '10px' }}>
                              <Col span='8'>
                                {a}
                              </Col>
                              <Col span='16' style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                {
                                  v.options.map(b => {
                                    return <div style={{ width: 100 / v.options.length + '%', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                      <span style={{ color: 'red', display: 'inline-block', width: '15px' }}>{b.isMust == 1 ? '*' : null}</span>
                                      <Input></Input>
                                    </div>
                                  })
                                }
                              </Col>
                            </Row>
                          })
                        }
                      </div>
                      : null
                    //
                  }
                  { //矩阵选择
                    v.type == '41' ||
                      v.type == '42' ||
                      v.type == '55' ?
                      <div>
                        {
                          v.type == '55' ? <Row>
                            <Col span='8'>
                              <p></p>
                              <p style={{ fontWeight: '600', color: '#ff9900' }}>分值</p>
                            </Col>
                            <Col span='16' style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                              {
                                v.options.map(a => {
                                  return <div style={{ width: 100 / v.options.length + '%', textAlign: 'center' }}>
                                    <p>{a.title}</p>
                                    <p style={{ color: '#ff9900' }}>{a.score}</p>
                                  </div>
                                })
                              }
                            </Col>
                          </Row> : null
                        }
                        {
                          v.type == '41' ||
                            v.type == '42' ? <Row>
                            <Col span='8'>
                              <p></p>
                            </Col>
                            <Col span='16' style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                              {
                                v.options.map(a => {
                                  return <div style={{ width: 100 / v.options.length + '%', textAlign: 'center' }}>
                                    <p>{a.title}</p>
                                  </div>
                                })
                              }
                            </Col>
                          </Row> : null
                        }
                        {
                          v.chooseItemNames.map((a, i) => {
                            return <Row style={{ borderBottom: '0.5px solid #ddd', padding: '10px 0', marginBottom: '10px' }}>
                              <Col span='8'>
                                {a}
                              </Col>
                              <Col span='16'>
                                {//矩阵单选
                                  v.type == '41' ? <Radio.Group style={{ display: 'flex' }}>
                                    {
                                      v.options?.map((a, b) => {
                                        return <div style={{ width: 100 / v.options.length + '%', textAlign: 'center', display: 'inline-block' }}>
                                          <Radio value={b}>
                                            {
                                              a.isNeedGap == 1 ?
                                                <>
                                                  {
                                                    a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                                  }
                                                  <Input style={{ width: '100px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                                                </>
                                                : null
                                            }
                                          </Radio>
                                        </div>
                                      })
                                    }
                                  </Radio.Group> : null
                                  //
                                }
                                {//矩阵多选
                                  v.type == '42' ?
                                    v.options?.map((a, b) => {
                                      return <Checkbox style={{ width: 100 / v.options.length + '%', margin: '0', textAlign: 'center', display: 'inline-block' }} value={b}>{
                                        a.isNeedGap == 1 ?
                                          <>
                                            {
                                              a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                            }
                                            <Input style={{ width: '100px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                                          </>
                                          : null
                                      }</Checkbox>
                                    })
                                    : null
                                  //
                                }
                                {
                                  v.type == '55' ? <Radio.Group style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                    {
                                      v.options?.map((a, b) => {
                                        return <Radio value={b}></Radio>
                                      })
                                    }
                                  </Radio.Group> : null
                                  //
                                }
                              </Col>
                            </Row>
                          })
                        }
                      </div>
                      : null
                  }
                  {//量标题
                    v.type == 51 ?
                      <div>
                        <span style={{ margin: '10px 0' }}>{v.options[0]?.title}</span>
                        <Radio.Group>
                          {
                            //
                            v.options?.map((a, b) => {
                              return <Popover content={(<div>{a.title}</div>)} >
                                <Radio value={b}>{a.score}</Radio>
                              </Popover>
                            })
                          }
                        </Radio.Group>
                        <span style={{ margin: '10px 0' }}>{v.options[v.options.length - 1]?.title}</span>
                      </div>
                      : null
                  }
                  {//NPS量表
                    v.type == 52 ?
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ margin: '10px' }}>{v.options[0]?.title}</span>
                        <div style={{ border: '0.5px solid #ddd', padding: '5px', paddingRight: '0' }}>
                          <div style={{ backgroundImage: 'linear-gradient(to right,#fff, #09ceff)', display: 'flex', alignItems: 'center', height: 'auto', position: 'relative' }}>
                            {
                              v.options?.map((a, b) => {
                                return <><Popover content={(<div>{a.title}</div>)} >
                                  <span className={'NPS' + i} style={{ background: "#fff", padding: '5px', borderRight: '5px solid #fff' }}
                                    value={b}
                                    onClick={e => { NPSfun(b, v, i) }}
                                    onMouseOver={e => { mouseover(b, v, i) }}
                                    onMouseOut={e => { mouseout(b, dataIndex, v, i) }}
                                  >{a.score}<span style={{opacity:0}}>1</span></span>
                                </Popover>
                                </>
                              })
                              //
                            }
                          </div>
                        </div>
                        <span style={{ margin: '10px' }}>{v.options[v.options.length - 1]?.title}</span>
                      </div>
                      : null
                  }
                  {//评分单选
                    v.type == 53 ?
                      <Radio.Group>
                        {
                          v.options?.map((a, b) => {
                            return <>
                              <Radio style={{ display: 'block', marginLeft: '10px', display: 'flex' }} value={b}>{a.title}
                                {
                                  a.isNeedGap == 1 ?
                                    <>
                                      {
                                        a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                      }
                                      <Input style={{ width: '200px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                                    </>
                                    : null
                                }
                                <p style={{ paddingLeft: '0', color: '#ccc', fontSize: '8px' }}>{a.remark}</p>
                              </Radio>
                            </>
                          })
                        }
                      </Radio.Group>
                      : null
                  }
                  {//评分多选
                    //
                    v.type == 54 ?
                      v.options?.map((a, b) => {
                        return <div>
                          <Checkbox style={{ display: 'block', marginLeft: '10px', display: 'flex' }} value={b}>{a.title}
                            {
                              a.isNeedGap == 1 ?
                                <>
                                  {
                                    a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                  }
                                  <Input style={{ width: '200px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                                </>
                                : null
                            }
                          </Checkbox>
                          <p style={{ paddingLeft: '23px', color: '#ccc', fontSize: '8px' }}>{a.remark}</p>
                        </div>
                      })
                      : null
                  }
                  {//评分题
                    v.type == 56 ?
                      <div>
                        <span style={{ margin: '10px 0' }}>{v.options[0]?.title}</span>
                        {
                          v.options?.map((a, b) => {
                            return <StarFilled
                              style={{ color: '#ddd', fontSize: '20px' }}
                              className={"xing" + i}
                              onClick={e => { NPSfun(b, v, i) }}
                              onMouseOver={e => { mouseover(b, v, i) }}
                              onMouseOut={e => { mouseout(b, dataIndex, v, i) }}
                            />
                          })
                        }
                        <span style={{ margin: '10px 0' }}>{v.options[v.options.length - 1]?.title}</span>
                      </div>
                      : null
                  }
                  {//选择日期
                    v.type == 64 ?
                      <div>
                        <DatePicker />
                      </div>
                      : null
                  }
                  {//选择地区
                    v.type == 65 ?
                      <div>
                        <Cascader options={options} placeholder="Please select" />
                      </div>
                      : null
                  }
                </Col>
                <Col span='24' style={{ margin: '10px 0', color: 'red' }}>
                  {
                    v.skipType == 2 ? <span style={{}}>此题设置了跳题逻辑<font color='ffa842'>{
                      v.skipTo == -1 ? '(直接结束作答)'
                        : v.skipTo == 0 ? '(跳转到末尾)'
                          : '(跳转到第' + getdata.filter(a => v.skipTo == a.idx)[0]?.no + '题)'
                    }</font></span> : null
                  }
                  {
                    v.skipType == 1 ? '此题设置了跳题逻辑' + v.options.map(a => {
                      if (a.skipTo == 0) {
                        return ',第' + (a.idx + 1) + '个选项跳转到结尾'
                      } else if (a.skipTo == -1) {
                        return ',第' + (a.idx + 1) + '个选项结束作答'
                      } else if (a.skipTo) {
                        return ',第' + (a.idx + 1) + '个选项跳转到第' + getdata.filter(v => a.skipTo == v.idx)[0]?.no + '题'
                      } else {
                        return
                      }
                    }).join('') : null
                  }
                  {
                    v.relatedList && v.relatedList.length != 0 ?
                      <span>依赖于{
                        v.relatedList.map((a, b) => {
                          return '第' + getdata.filter(v => a.timuIdx == v.idx)[0].no + '题,' + a.options.sort((a, b) => a - b).map((l, j) => {
                            l += 1
                            if (j == 0 && a.options.length == j + 1) {
                              return '第' + l + '个选项'
                            } else if (j == 0) {
                              return '第' + l
                            } else if (v.relatedList.length != b + 1 && a.options.length == j + 1) {
                              return l + '个选项,'
                            } else if (a.options.length == j + 1) {
                              return l + '个选项'
                            } else {
                              return l + ','
                            }
                          })
                        }).join('')
                      }
                      </span>
                      : null
                  }
                </Col>
                <Col span='24' style={{ margin: '10px 0', position: 'relative' }}>
                  {
                    v.MovseBool ?
                      <div style={{ display: 'inline-block', position: 'absolute', right: '0px' }}>
                        <Button style={{ marginRight: '10px' }} onClick={e => { setCharuModel(true) }} >插入</Button>
                        <Button style={{ marginRight: '10px' }} onClick={e => { setCv(true); setCvkuai(i) }} >复制</Button>
                        <PopConfirmOnly confirm={e => { deletegetdata(i) }}>
                          <Button style={{ marginRight: '10px' }} >删除</Button>
                        </PopConfirmOnly>
                        <Button style={{ marginRight: '10px' }} onClick={e => (timuUp(i, e))} >上移</Button>
                        <Button style={{ marginRight: '10px' }} onClick={e => (timuDown(i, e))} >下移</Button>
                        <Button style={{ marginRight: '10px' }} onClick={e => (timuMaxUp(i, e))} >最前</Button>
                        <Button style={{ marginRight: '10px' }} onClick={e => (timuMaxDown(i, e))} >最后</Button>
                      </div>
                      : null
                  }
                </Col>
              </Row>
            })
            //
            : <Col span='24' style={{ margin: '10px 0' }}>
              <p style={{ lineHeight: '50px', textAlign: 'center' }}>
                可以开始编辑问卷了
              </p>
            </Col>
        }
      </div>
      {//
      }
      <div id='wenjuana' style={{ marginTop: '10px ', background: '#fff6e8', borderTop: '0.5px solid #ddd', zIndex: '100', display: isShow ? 'block' : 'none', position: 'absolute', top: disTop, padding: '10px 100px', width: '100%' }}>
        <div id='div1'></div>
        <Row>
          <Col span='24' style={{ height: '40px', lineHeight: '40px', display: "flex", alignItems: 'center' }}>
            <Button style={{ marginRight: '10px' }} onClick={e => { setCv(true); console.log(cv) }}>复制问题</Button>
            {
              getdata[dataIndex]?.type != '31' ||
                getdata[dataIndex]?.type != '7' ?
                <Checkbox checked={getdata[dataIndex]?.must} onChange={onChangecheck}>是否必答</Checkbox>
                : null
            }
            <PopConfirmOnly confirm={e => { deletegetdata() }}>
              <Button type='link'>删除</Button>
            </PopConfirmOnly>
            {//选择验证方式
              getdata[dataIndex]?.type == '21' ?
                <Select style={{ width: 120 }} placeholder='验证属性' value={getdata[dataIndex].validate} onChange={e => { expressionChange(e) }}>
                  <Option value=''>取消选择</Option>
                  {
                    expression.map((v, i) => {
                      return <Option value={v.title}>{v.title}</Option>
                    })
                  }
                </Select>
                : null
            }
            {
              getdata[dataIndex]?.type == '2' ||
                getdata[dataIndex]?.type == '42' ||
                getdata[dataIndex]?.type == '54' ||
                getdata[dataIndex]?.type == '5' ||
                (getdata[dataIndex]?.type == '21' && getdata[dataIndex].validate == '数字') ? <span style={{ display: 'flex', alignItems: 'center' }}>
                {getdata[dataIndex].validate == '数字' ? '输入区间(最小值)' : '最小选择上限'}<InputNumber size="small" style={{ height: '25px' }} value={getdata[dataIndex].rateMin} min={1} max={100000} onChange={e => {
                  getdata[dataIndex].rateMin = e
                  setgetdata(getdata)
                  setRefresh(true)
                }} />
              </span> : null
            }
            {
              getdata[dataIndex]?.type == '2' ||
                getdata[dataIndex]?.type == '42' ||
                getdata[dataIndex]?.type == '54' ||
                getdata[dataIndex]?.type == '5' ||
                (getdata[dataIndex]?.type == '21' && getdata[dataIndex].validate == '数字') ? <span style={{ display: 'flex', alignItems: 'center' }}>
                {getdata[dataIndex].validate == '数字' ? '输入区间(最大值)' : '最大选择上限'}<InputNumber size="small" style={{ height: '25px' }} value={getdata[dataIndex].rateMax} min={1} max={100000} onChange={e => {
                  getdata[dataIndex].rateMax = e
                  setgetdata(getdata)
                  setRefresh(true)
                }} />
              </span> : null
            }
            {
              getdata[dataIndex]?.type == '7' ? <span style={{ display: 'flex', alignItems: 'center' }}>
                配额自增数量<InputNumber size="small" style={{ height: '25px' }} value={getdata[dataIndex].rateMin} min={0} max={100000} onChange={e => {
                  getdata[dataIndex].rateMin = e
                  setgetdata(getdata)
                  setRefresh(true)
                }} />
              </span> : null
            }
            {//限制数字输入长度
              getdata[dataIndex]?.type == '21' && getdata[dataIndex].validate == '数字' ?
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  最小长度<InputNumber size="small" style={{ height: '25px' }} value={getdata[dataIndex].rateMinText} min={1} max={100000} onChange={e => {
                    getdata[dataIndex].rateMinText = e
                    setgetdata(getdata)
                    setRefresh(true)
                  }} />
                </span>
                : null
            }
            {//分组
              getdata[dataIndex]?.type != '31' &&
                getdata[dataIndex]?.type != '32' ?
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  分组<InputNumber size="small" style={{ height: '25px' }} value={getdata[dataIndex]?.subGroup} min={1} max={100000} onChange={e => {
                    getdata[dataIndex].subGroup = e
                    setgetdata(getdata)
                    setRefresh(true)
                  }} />
                </span>
                : null
            }
            {//选项是否随机
              (getdata[dataIndex]?.type == '1' ||
                getdata[dataIndex]?.type == '2' ||
                getdata[dataIndex]?.type == '53' ||
                getdata[dataIndex]?.type == '54') &&
                getdata[dataIndex]?.type != '31' &&
                getdata[dataIndex]?.type != '32' ?
                <span style={{ display: 'flex', marginLeft: '10px' }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>随机选项</span>
                  <Checkbox size="small" style={{ height: '25px', marginLeft: '10px' }} checked={getdata[dataIndex]?.randomFlag == 1} onClick={e => {
                    if (e.target.checked) {
                      getdata[dataIndex].randomFlag = 1
                    } else {
                      getdata[dataIndex].randomFlag = 0
                    }
                    setgetdata(getdata)
                    setRefresh(true)
                  }} />
                </span>
                : null
            }
            { //设置分数
              getdata[dataIndex]?.type == '53' ||
                getdata[dataIndex]?.type == '54' ?
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  分数<InputNumber size="small" style={{ height: '25px' }} value={getdata[dataIndex]?.scoreCalcu} min={1} max={100000} onChange={e => {
                    getdata[dataIndex].scoreCalcu = e
                    setgetdata(getdata)
                    setRefresh(true)
                  }} />
                </span>
                : null
            }
          </Col>
          <Col span='10'></Col>
          { //设置多级联动
            getdata[dataIndex]?.type == '6' ?
              <div style={{ width: '100%' }}>
                <p style={{ background: '#fff', padding: "10px", }}>选项内容</p>
                <TextArea placeholder='例:大区/省份/城市/区县' id='txtArea' defaultValue={getdata[dataIndex].validate} rows={5} onChange={e => { jilianxuanxiang(e.target.value, getdata[dataIndex]) }} />
              </div>
              : null
          }
          { //设置矩阵单选
            getdata[dataIndex]?.type == '41' ||
              getdata[dataIndex]?.type == '42' ||
              getdata[dataIndex]?.type == '24' ?
              <div style={{ width: '100%' }}>
                <p style={{ background: '#fff', padding: "10px", }}>行标题</p>
                <TextArea id='txtArea' value={getdata[dataIndex].chooseItemNames.join('\n')} rows={5} onChange={e => { juzhenxuanze(e.target.value) }} />
              </div>
              : null
          }
          { //下拉
            getdata[dataIndex]?.type == '3' ||
              getdata[dataIndex]?.type == '4' ? <Col span='24'>
              <Table columns={xiala} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          { //单选
            getdata[dataIndex]?.type == '1' ||
              getdata[dataIndex]?.type == '62' ? <Col span='24'>
              <Table columns={columns} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          { //多选
            getdata[dataIndex]?.type == '2' ? <Col span='24'>
              <Table columns={columnsCheckbox} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          { //排序
            getdata[dataIndex]?.type == '5' ? <Col span='24'>
              <Table columns={columnsPaixu} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          { //配额
            getdata[dataIndex]?.type == '7' ? <Col span='24'>
              <Table columns={columnsPeie} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          { //表格填空 
            getdata[dataIndex]?.type == '24' ? <Col span='24'>
              <Table columns={juzhendanxuantiankong} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          { //矩阵单选
            getdata[dataIndex]?.type == '41' ? <Col span='24'>
              <Table columns={juzhendanxuan} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          { //矩阵多选 
            getdata[dataIndex]?.type == '42' ? <Col span='24'>
              <Table columns={juzhendanxuanCheckbox} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          {//量表
            getdata[dataIndex]?.type == '51' ||
              getdata[dataIndex]?.type == '52' ? <Col span='24'>
              <Table columns={liangbiao} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          {//评分单选
            getdata[dataIndex]?.type == '53' ? <Col span='24'>
              <Table columns={pingfenrudio} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          {//评分多选
            getdata[dataIndex]?.type == '54' ? <Col span='24'>
              <Table columns={pingfenrudioCheckbox} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          {//矩阵量表
            getdata[dataIndex]?.type == '55' ? <Col span='24'>
              <Table columns={JuzhenLiangbiao} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          {//评分题
            getdata[dataIndex]?.type == '56' ? <Col span='24'>
              <Table columns={pingfenti} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          { //矩阵填空
            getdata[dataIndex]?.type == '23' ? <Col span='24'>
              <Table columns={juzhen} dataSource={dataIndex != null ? getdata[dataIndex].options : []} style={{ padding: '0' }} pagination={false} />
            </Col> : null
          }
          <Col span='24'>
            { //是否需要显示添加选项
              getdata[dataIndex]?.type == '1' ||
                getdata[dataIndex]?.type == '2' ||
                getdata[dataIndex]?.type == '3' ||
                getdata[dataIndex]?.type == '4' ||
                getdata[dataIndex]?.type == '5' ||
                getdata[dataIndex]?.type == '7' ||
                getdata[dataIndex]?.type == '23' ||
                getdata[dataIndex]?.type == '24' ||
                getdata[dataIndex]?.type == '41' ||
                getdata[dataIndex]?.type == '42' ||
                getdata[dataIndex]?.type == '51' ||
                getdata[dataIndex]?.type == '52' ||
                getdata[dataIndex]?.type == '53' ||
                getdata[dataIndex]?.type == '54' ||
                getdata[dataIndex]?.type == '55' ||
                getdata[dataIndex]?.type == '56' ||
                getdata[dataIndex]?.type == '62' ?
                <span style={{ margin: '0 10px' }} onClick={e => addtopic()}><PlusSquareOutlined />添加选项</span>
                : null
            }
            { //是否需要显示批量添加选项
              getdata[dataIndex]?.type == '1' ||
                getdata[dataIndex]?.type == '2' ||
                getdata[dataIndex]?.type == '3' ||
                getdata[dataIndex]?.type == '4' ||
                getdata[dataIndex]?.type == '5' ||
                getdata[dataIndex]?.type == '7' ||
                getdata[dataIndex]?.type == '23' ||
                getdata[dataIndex]?.type == '24' ||
                getdata[dataIndex]?.type == '41' ||
                getdata[dataIndex]?.type == '42' ||
                getdata[dataIndex]?.type == '51' ||
                getdata[dataIndex]?.type == '52' ||
                getdata[dataIndex]?.type == '53' ||
                getdata[dataIndex]?.type == '54' ||
                getdata[dataIndex]?.type == '55' ||
                getdata[dataIndex]?.type == '56' ||
                getdata[dataIndex]?.type == '62' ?
                <span onClick={e => { showModaldabl() }}>批量添加</span>
                : null
            }
            {
              getdata[dataIndex]?.type != '7' &&
                getdata[dataIndex]?.type != '31' ?
                <span style={{ margin: '0 10px' }} onClick={e => { if (dataIndex != 0) { guanlianModal() } else { message.error('第一题无法关联') } }}>题目关联</span>
                : null
            }
            {
              getdata[dataIndex]?.type != '7' &&
                getdata[dataIndex]?.type != '31' &&
                getdata[dataIndex]?.type != '32' ?
                <span onClick={e => { tiaotiModal() }}>跳题逻辑</span>
                : null
            }
            {
              getdata[dataIndex]?.type != '7' &&
                getdata[dataIndex]?.type != '31' &&
                getdata[dataIndex]?.type != '32' ?
                <span style={{ margin: '0 10px' }} onClick={e => { xxglModalKai(getdata[dataIndex]) }}>选项关联</span>
                : null
            }
            <span style={{ margin: '0 10px' }} onClick={e => { setWenziModalBool(true) }}>文字替换</span>
            {
              getdata[dataIndex]?.type == '22' ?
                <span style={{ margin: '0 10px' }} onClick={e => { duoxiangtiankongyanzheng() }}>验证属性</span>
                : null
            }
            {
              getdata[dataIndex]?.type == '1' ?
                <span style={{ margin: '0 10px' }}>是否验证位置<Checkbox checked={getdata[dataIndex].regionFlag==1} onClick={e=>{e.target.checked?getdata[dataIndex].regionFlag=1:getdata[dataIndex].regionFlag=0;setRefresh(true)}}></Checkbox></span>
                : null
            }
          </Col>
          <Col span='24'>
            <Button style={{ width: '100%', background: '#f5ae44' }} onClick={e => { wancheng() }}>完成编辑</Button>
          </Col>
        </Row>
      </div>
      {//
      }
      <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button style={{ width: '80%', background: '#0095FF', color: '#fff' }} onClick={e => { wancheng(1) }}>编辑完成</Button>
      </div>
      <Modal title="批量添加" visible={isdabl} onOk={handleOkdabl} onCancel={handleCanceldabl} destroyOnClose>
        <TextArea onChange={e => { setDablvalue(e.target.value) }} rows={10} />
      </Modal>
    </div>
  )
  //
}

export default App