import React, { useEffect, useRef, useState } from 'react';
import { HashRouter, Switch, Route, useHistory } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Tabs, List, Typography, Avatar } from 'antd'
import TimeHandle from "./component/TimeHandle";
import DateHandle from "./component/DateHandle";
import DayHandle from "./component/DayHandle";
import BottomHandle from "./component/BottomHandle"
import LampHandle from "./component/LampHandle"
import Particulars from "../work/component/Particulars";
import compatStyle from "echarts/src/preprocessor/helper/compatStyle";
function App(props) {

  //切换选择栏
  const { TabPane } = Tabs;

  const [curTab, setCurTab] = useState('day')

  //日期选择框
  const [dateSelect, setDateSelect] = useState([])
  //列表数据
  const data = [
    {
      title: '北蔡镇'
    },
    {
      title: '蔡徐坤镇'
    },
    {
      title: '王俊凯镇'
    },
    {
      title: '唱跳rap篮球镇'
    },
    {
      title: '卢本伟广场'
    },
    {
      title: '卢本伟街道'
    },
    {
      title: '全体起立'
    }
  ]
  const tabsoncheng = e => {
      setCurTab(e)
  }
  return (

    <div style={{ padding: '30px' }}>
      <Row gutter={[20, 20]} >
        <Col span={18}>
          <div style={{ background: 'white', padding: '20px' }} >
            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>项目实时处理情况</div>
            <Divider />
            <Row gutter={[24, 24]} style={{ marginLeft: '150px' }}>
              <Col span={8}>
                <div style={{ width: '160px', height: '58px' }}>
                  <div style={{ opacity: '0.6', fontSize: '14px' }}>检查数</div>
                  <div style={{ fontSize: '24px', marginTop: '10px' }}>124,543,233</div>
                </div>
              </Col>

              <Col span={8}>
                <div style={{ width: '160px', height: '58px' }}>
                  <div style={{ opacity: '0.6', fontSize: '14px' }}>检查剩余数</div>
                  <div style={{ fontSize: '24px', marginTop: '10px' }}>952,732</div>
                </div>
              </Col>

              <Col span={8}>
                <div style={{ width: '160px', height: '58px' }}>
                  <div style={{ opacity: '0.6', fontSize: '14px' }}>检查完成率</div>
                  <div style={{ fontSize: '24px', marginTop: '10px' }}>66%</div>
                </div>
              </Col>

            </Row>
            <div style={{ height: '250px', width: '100%' }}><TimeHandle /></div>

            <div style={{ height: '250px', width: '100%' }}><DateHandle /></div>
          </div>
        </Col>

        <Col span={6}>
          <div style={{ background: 'white', height: '338px', marginBottom: '20px', padding: '20px' }}>
            <nav style={{ fontSize: '20px', fontWeight: 'bold' }}>每日项目情况预测</nav>

            <Divider />
            <nav style={{ opacity: '0.6', fontSize: '14px', marginLeft: '30px' }}>当日目标评估</nav>

            <nav style={{ fontSize: '20px', marginLeft: '30px', marginTop: '5px', fontWeight: 'bold' }}>有望达到预期</nav>

            <DayHandle />
          </div>

          <div style={{ background: 'white', height: '338px', padding: '20px'}}>
            <nav style={{ fontSize: '20px', fontWeight: 'bold' }}>项目合格率</nav>
            <Divider />
            <LampHandle/>
          </div>
        </Col>

        <Col span={24}>
          <div style={{ background: 'white', height: '450px', padding: '20px' }}>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <nav style={{ fontSize: '20px', fontWeight: 'bold' }}>人员概况</nav>
                <Divider style={{ marginTop: '15px' }}/>
              </Col>
              <Col span={6}>
                <Tabs style={{ background: '#fff' }} onChange={e => tabsoncheng(e)}>
                  <TabPane style={{ background: '#eee' }} tab="今日" key="day" >
                  </TabPane>
                  <TabPane style={{ background: '#eee' }} tab="本周" key="week">
                  </TabPane>
                  <TabPane style={{ background: '#eee' }} tab="本月" key="month">
                  </TabPane>
                  <TabPane style={{ background: '#eee' }} tab="本年" key="year">
                  </TabPane>
                </Tabs>
              </Col>
              <Col span={6} style={{ textAlign: 'left' }}>
                <DatePicker.RangePicker style={{ width: '240px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
                <Divider style={{ marginTop: '12px' }} />
              </Col>
              <Col span={18}>
                <BottomHandle curTab={curTab}/>
              </Col>
              <Col span={6}>
                <List
                    size={'small'}
                    itemLayout="horizontal"
                    dataSource={data}
                    header={'Year past Year'}
                    split={false}
                >
                  {
                    data.map((v, i) => {
                      return (<List.Item>
                            <div style={{ width: '20px', height: '20px', border: i < 3 ? '1px solid black' : '', borderRadius: '50%', textAlign: 'center', fontWeight: 'bold', color: i < 3 ? 'white' : 'black', background: i < 3 ? 'black' : 'rgba(229, 229, 229, 1)' }}>{i + 1}</div>
                            <div style={{ align: 'left' }}>{v.title}</div>
                            <div>90%</div>
                          </List.Item>
                      )
                    })
                  }
                </List>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default App