import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, message } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'
import PopConfirmOnly from "@/utils/PopConfirmOnly";


function App(props) {
    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    const [columns, setColumns] = useState([
        {
            title: '问卷名称',
            dataIndex: 'title',
            render: (text, record, index) => (
                text
            ),
        },
        {
            title: '操作',
            dataIndex: 'projectId',
            render: (text, record, index) => (
                <>
                    <Button type='link' size='small' onClick={e => {
                        if(record.status==1){ 
                            push('/wjs/' + projectid + '/work/answer/' + record.id+'/' + store.getState().user.uid) 
                        }else{
                            message.error('请发布后在开始答题')
                        }
                    }}>开始答题</Button>
                </>
            ),
        }
    ])
    {//
    }
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        getdata()
    }, [])

    const getdata = () => {
        axios.get('/api/question/template/all', {
            params: {
                projectId: projectid,
                pageSize:10000
            }
        })
        .then(res => {
            console.log(res)
            setDataSource(res.data.data)
        })
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const handleClearSelect = e => {
        setSelectedRowKeys([])
    }

    const [modalVisible, setModalVisible] = useState(null)
    const handleModalCancel = e => {
        setModalVisible(null)
    }

    return (
        <div style={{ padding: '30px' }}>
            <div style={{ padding: '30px', background: 'white' }}>

                <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
                    <Col>
                    </Col>
                    <Col>
                        {/* <Button>导出列表</Button> */}
                    </Col>
                </Row>

                <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />

            </div>
        </div>
    )
}

export default App
