
function user(state = { token: null, uid: null, name: null }, action) {
  switch (action.type) {
    case 'user_init':
      return { token: action.value.token, uid: action.value.uid, name: action.value.name , role: action.value.role }
    case 'user_clear':
      localStorage.clear()
      window.location.hash = '#/login'
      return { token: null, uid: null, name: null }
    default:
      return state
  }
}
function permission(state = { main: [], project: [], have: false }, action) {
  switch (action.type) {
    case 'permission_init': return { main: action.value.main, project: action.value.project, have: true }
    case 'permission_clear': return { main: [], project: [], have: false }
    default: return state
  }
}
function header(state = { value: null }, action) {
  switch (action.type) {
    case 'header_init': return { value: null }
    case 'header_clear': return { value: action.value }
    default: return state
  }
}
function loading(state = false, action) {
  switch (action.type) {
    case 'loading_set': return action.value
    default: return state
  }
}
function selectCache(state = { page: null, select: null }, action) {
  switch (action.type) {
    case 'selectCache_init': return action.value
    case 'selectCache_clear': return { page: null, select: null }
    default: return state
  }
}

function reducer(state = {}, action) {
  return {
    user: user(state.user, action),
    permission: permission(state.permission, action),
    header: header(state.header, action),
    loading: loading(state.loading, action),
    selectCache: selectCache(state.selectCache, action)
  }
}

export default reducer