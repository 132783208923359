import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'


function App(props) {
  const { push } = useHistory()
  const { projectid , DSR } = useRouteMatch().params

  const [columns, setColumns] = useState([
      {
        title: 'SFA编号',
        dataIndex: 'sfaCode',
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '门店编号',
        dataIndex: 'shopCode',
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '省份',
        dataIndex: 'province',
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '门店名称',
        dataIndex: 'shopName',
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '门店地址',
        dataIndex: 'address',
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '类型',
        dataIndex: 'shopLevelName',
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '是否与陈列重复',
        dataIndex: 'reShop',
        render:(text,record)=>{
          return text?text:'--'
        }
      },
    ])

  const [dataSource, setDataSource] = useState([])

  const getdata = () => {
    axios.get('/api/core/project/' + projectid)
    .then(res => {
      console.log(res)
      axios.get('/api/core/shop/list',{
        params:{
          shopGroupId:res.data.shopGroupId
        }
      })
      .then(res=>{ 
        console.log(res)
        setDataSource(res.data.data)
      })
    })
}


  useEffect(() => {
    getdata()
  }, [])

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
           {//搜索模块位置
           }
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true }} />

      </div>
    </div>
  )
}

export default App
