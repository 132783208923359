import React, { useEffect, useState, } from 'react';
import { HashRouter, Switch, Route, useHistory, useParams } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, Input, Modal, DatePicker } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import store from '@/redux/store'

const { Option } = Select;

function App(props) {

  const [columns, setColumns] = useState([
    {
      title: '访问员',
      dataIndex: 'principal',
      key: 'principal'
    },
    {
      title: '上传进度',
      dataIndex: 'workorder_no',
      key: 'name'
    },
    {
      title: '一审负责人',
      dataIndex: 'research_no',
      key: 'researchno'
    },
    {
      title: '一审进度',
      dataIndex: 'project_area',
      key: 'project_area'
    },
  ])
  //
  const [projectId, setprojectId] = useState(useParams().projectid)//id
  const [list, setlist] = useState([])//表格数据
  const [listone, setlistone] = useState([])//表格数据
  const [yishen, setyishen] = useState([])//一审人员数据

  const requestlist = async () => {
    const res = await axios.get('/api/core/task/innerList', {
      params: {
        pageSize: 1000000,
        projectId: projectId,
        userId:store.getState().user.uid
      }
    })
    res.data.data.forEach((v, i) => {
      v.key = i + Math.random()
    });
    setlist(res.data.data)
    setlistone(res.data.data)
  }


  useEffect(() => {
    requestlist()
  }, [])
  useEffect(() => {
    axios.get('/api/sys/user/list', {
      params: {
        roleCode: 'xuzhong_checker',
        pageSize: 1000000,
        groupId: props.data.userGroupId
      }
    }).then(res => {
      setyishen(res.data.data)
    })
  }, [])

  const [dataSource, setDataSource] = useState([])
  useEffect(() => {
    const data = [];
    for (let i = 0; i < 46; i++) {
      data.push({
        key: i,
        workorder_no: '01',
        research_no: 'AY001',
        project_area: '东北三省',
        research_objects: '东角半岛',
        interviewer: '李四',
        completion_status: '未完成',
        score: '80',
        photos: '未上传',
        sound_recording: '已上传',
        video: '未上传'
      });
    }
    setDataSource(data)
  }, [])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
  }
  const [time, settime] = useState('')//时间/
  const [yishenrenyuan, setyishenrenyuan] = useState('')//完成状态
  const [seleaddress, setseleaddress] = useState('')//调研区域
  const [input1, setinput1] = useState('')//调研对象
  const [numberinp, setnumberinp] = useState('')//调研编号
  const [rens, setrens] = useState('')//访问员
  const [tablebool, settablebool] = useState([])//显示隐藏table

  const resetfrom = (e) => {
    settime('')
    setyishenrenyuan('')
    setseleaddress('')
    setinput1('')
    setnumberinp('')
    setrens('')
    requestlist()
    requestlist()
  }

  const find = () => {
    setlistone(list)
    var arr = []
    if (time != '') {
      arr = listone.filter((v, i) => {
        return v.startAt < time[0]._d.getTime() && v.endAt > time[1]._d.getTime()
      })
    } else {
      arr = listone
    }
    if (seleaddress != '') {
      arr = arr.filter((v, i) => {
        return v.surveyScope == seleaddress
      })
    }
    if (input1 != '') {
      arr = arr.filter((v, i) => {
        return v.surveyTarget != null ? v.surveyTarget.indexOf(input1) != -1 : null
      })
    }
    if (numberinp != '') {
      arr = arr.filter((v, i) => {
        return v.surveyCode == numberinp
      })
    }
    if (rens != '') {
      arr = arr.filter((v, i) => {
        return v.principal == rens
      })
    }
    setlistone(arr)
  }
  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>
        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px', width: '100px', display: 'inline-block' }}>查询时间</span>
            <DatePicker.RangePicker value={time} onChange={(e) => { settime(e) }} style={{ width: '240px', marginLeft: '10px' }}></DatePicker.RangePicker>
          </Col>

          <Col span={8}>
            <span style={{ lineHeight: '30px', width: '100px', display: 'inline-block' }}>调研对象</span>
            <Input value={input1} onChange={(e) => { setinput1(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
          </Col>

          <Col span={8}>
            <span style={{ lineHeight: '30px', width: '100px', display: 'inline-block' }}>调研编号</span>
            <Input value={numberinp} onChange={(e) => { setnumberinp(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
          </Col>

          <Col span={8}>
            <span style={{ lineHeight: '30px', width: '100px', display: 'inline-block' }}>访问员</span>
            <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
              value={rens} onChange={(e) => { setrens(e) }} style={{ width: '240px', marginLeft: '10px' }} >
            <Select.Option value={''}>取消选择</Select.Option>
            {
                props.userlist.map((v, i) => {
                  return <Option value={v.name}>{v.name}</Option>
                })
              }
            </Select>
          </Col>

          <Col span={8}>
            <span style={{ lineHeight: '30px', width: '100px', display: 'inline-block' }}>一审负责人</span>
            <Select value={yishenrenyuan} onChange={(e) => { setyishenrenyuan(e) }} style={{ width: '240px', marginLeft: '10px' }} >
            <Select.Option value={''}>取消选择</Select.Option>
            {
                JSON.stringify(yishen) != '{}' ? yishen.map((v, i) => {
                  return <Option value={v.name}>{v.name}</Option>
                }) : null
              }
            </Select>
          </Col>
          
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px' }}>批量导出</Button>
          </Col>
          <Col>
            <Button type='primary' style={{ margin: '0 10px' }} onClick={() => { find() }}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={() => { resetfrom() }}>重置</Button>
            <Button style={{ margin: '0 10px' }} onClick={() => { settablebool(!tablebool) }}>收起</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        {
          tablebool ? <Table
            rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
            dataSource={listone}
            columns={columns}
            pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger:true }} /> : null
        }
      </div>
    </div>
  )
}

export default App
