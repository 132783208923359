import React, { useEffect, useRef, useState } from 'react';
import echarts from 'echarts'
function App(props) {
    const chartRef = useRef(null)

    const chartInit = e => {
        const myChart = echarts.init(document.getElementById('main3'))
        chartRef.current = myChart
        var option = {
            tooltip: {
                formatter: '{a} <br/>{b} : {c}%',
            },
            series: [
                {
                    name: '项目合格率',
                    type: 'gauge',
                    detail: {
                        formatter: '{value}%',
                        textStyle: { fontSize: '14px' }
                    },
                    axisLine: { lineStyle: { width: 20 } },
                    splitLine: { length: 20 },
                    itemStyle: {//指针颜色
                        color: 'rgba(62, 97, 155, 1)'
                    },
                    center: ['50%', '40%'],
                    data: [{ value: 77.9, name: '完成率' }]
                }
            ],
        };
        myChart.setOption(option);

    }
    //初始化
    useEffect(() => {
        setTimeout(() => {
            chartInit()
        }, 0);
    }, [])

    const resize = e => {
        if (chartRef.current)
            chartRef.current.resize()
    }
    useEffect(() => {
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    return (
        <>
            <div id="main3" style={{ width: '100%', height: '300px' }}></div>
        </>
    );
}
export default App