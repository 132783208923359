import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'

import overView from './overview/index'

import WorkAssign from  './work/WorkAssign'
import WorkList from  './work/WorkList'
import WorkMessage from  './work/WorkMessage'

import CheckAgain from  './check/CheckAgain'
import Completed from  './check/CheckCompleted'
import CheckDsr from  './check/CheckDsr'
import CheckDsrPlan from  './check/CheckDsrPlan'
import CheckBack from  './check/CheckBack'
import CheckAffirm from  './check/CheckAffirm'
import CheckDlAffirm from  './check/CheckDlAffirm'
import CheckFirst from './check/CheckFirst'
import CheckList from  './check/CheckList'
import CheckPlan from  './check/CheckPlan'
import CheckRecord from './check/CheckRecord'
import CheckSecond from  './check/CheckSecond'
import CheckParticular from './check/CheckParticular'

import SetLog from './set/SetLog'
import SetPeople from './set/SetPeople'
import SetProduct from './set/SetProduct'
import SetShop from './set/SetShop'

import VisitorDSRType from './data/Visitor/DataVisitorDSRType'
import VisitorDSR from './data/Visitor/DataVisitorDSR'

import Agencydaili from './data/Agency/DaraAgencydaili'
import AgencyDSRType from './data/Agency/DataAgencyDSRType'
import AgencyDSR from './data/Agency/DataAgencyDSR'

import Firstuser from './data/First/DataFirstuser'
import Firstusertask from './data/First/DataFirstusertask'


function App(props) {
  return(
    <>
      <Route path="/jh/:projectid/index" component={overView} />
      <Route path="/jh/:projectid/work/list" component={WorkList} />
      <Route path="/jh/:projectid/work/assign" component={WorkAssign} />
      <Route path="/jh/:projectid/work/message" component={WorkMessage} />

      <Route path="/jh/:projectid/check/plan" component={CheckPlan} />
      <Route path="/jh/:projectid/check/back" component={CheckBack} />
      <Route path="/jh/:projectid/check/affirm" component={CheckAffirm} />
      <Route path="/jh/:projectid/check/dsr" component={CheckDsr} />
      <Route path="/jh/:projectid/check/sdsrplan" component={CheckDsrPlan} />
      <Route path="/jh/:projectid/check/dlaffirm" component={CheckDlAffirm} />
      <Route path="/jh/:projectid/check/list" component={CheckList} />
      <Route path="/jh/:projectid/check/first" component={CheckFirst} />
      <Route path="/jh/:projectid/check/second" component={CheckSecond} />
      <Route path="/jh/:projectid/check/again" component={CheckAgain} />
      <Route path="/jh/:projectid/check/completed" component={Completed} />
      <Route path="/jh/:projectid/check/record" component={CheckRecord} />
      <Route path="/jh/:projectid/check/particular/:id" component={CheckParticular} />

      <Route path="/jh/:projectid/set/people" component={SetPeople}/>
      <Route path="/jh/:projectid/set/product" component={SetProduct}/>
      <Route path="/jh/:projectid/set/shop" component={SetShop}/>
      <Route path="/jh/:projectid/set/log" component={SetLog}/>

      <Route path="/jh/:projectid/data/visitorDSRType" component={VisitorDSRType}/>
      <Route path="/jh/:projectid/data/visitorDSR/:DSR" component={VisitorDSR}/>

      <Route path="/jh/:projectid/data/agencydaili" component={Agencydaili}/>
      <Route path="/jh/:projectid/data/agencyDSRType/:agentid" component={AgencyDSRType}/>
      <Route path="/jh/:projectid/data/agencyDSR/:DSR" component={AgencyDSR}/>

      <Route path="/jh/:projectid/data/firstuser" component={Firstuser}/>
      <Route path="/jh/:projectid/data/firstusertask/:audit2id" component={Firstusertask}/>

    </>
  )
}
export default App
