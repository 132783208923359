import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu, Dropdown, Tabs } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import VistorChart from './components/VistorChart'


function App(props) {

  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [curTab, setCurTab] = useState('table')

  const columns = [
    {
      title: '调研大区',
      dataIndex: 'surveyScope',
      render: (text, recort) => text ?
        <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={e => push('/sx/' + projectid + '/data/DataFirstDetail/' + text)}>{text}</span>
        : '-'
    },
    {
      title: '工单数量',
      dataIndex: 'taskCount',
      render: text => text ? text : '-'
    },
    {
      title: '未开始',
      dataIndex: 'pendingCount',
      render: (text, record) => text ? text : '-'
    },
    {
      title: '进行中',
      dataIndex: 'doingCount',
      render: text => text ? text : '-'
    },
    {
      title: '已提交',
      dataIndex: 'submittedCount',
      render: text => text ? text : '-'
    },
    {
      title: '需要完善',
      dataIndex: 'perfectCount',
      render: text => text ? text : '-'
    },
    {
      title: '需补拍',
      dataIndex: 'reshootCount',
      render: text => text ? text : '-'
    },
    {
      title: '需重做',
      dataIndex: 'reformCount',
      render: text => text ? text : '-'
    },
    {
      title: '合格',
      dataIndex: 'qualifiedCount',
      render: (text, record) => text ? text : '-'
    },
    {
      title: '合格率',
      dataIndex: 'qualifiedRate',
      render: (text, record) => record.qualifiedCount ? ((record.qualifiedCount/record.taskCount) * 100 ).toFixed(2)+ '%' : '-'
    },
  ]
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    axios.get('/api/sxStatistic/surveyScopeStatistic', {
      params: {
        pageSize: 1000000,
        projectId: projectid,
      }
    }).then(res => {
      console.log(res)
      const arr = res.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  useEffect(() => {
    getData()
  }, [updateFlag])

  return (
    <>
      <div style={{ padding: '30px' }}>
        <div style={{ padding: '30px', background: 'white' }}>

          <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          </Row>

          {
            curTab === 'table' ?
              <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true  }} />
              : null
          }
        </div>
      </div>

    </>
  )
}

export default App
