import React, { useEffect, useState, Component } from 'react';
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu } from 'antd'
import { withRouter } from 'react-router-dom'

import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { audioUrl, baseUrl } from '@/utils/config'

const { SubMenu } = Menu;
class App extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            id: 0,
            audit1PassedReasonSum: 0,
            audit2PassedReasonSum: 0,
            reviewPassedReasonSum: 0,
            refusedCountSum: 0,
            isModalVisible: false,
            returnGuid: [],
            labelValues:[]
        }
    }

    componentDidMount(){
        axios.get('api/sys/dict/sxTaskRefusedReasonType')
        .then(res=>{
            this.setState({
                labelValues:res.data.labelValues
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        let self = this;
        setTimeout(function () {
            if (JSON.stringify(self.state.data) != JSON.stringify(nextProps.data)) {
                self.setState({
                    data: JSON.stringify(self.state.data) != '[]' ? self.state.data : nextProps.data,
                    id: self.state.id.length != 0 ? self.state.id : nextProps.id,
                }, () => {
                    axios.get('/api/core/taskReturnOrder/list', {
                        params: {
                            taskId: self.state.data.id
                        }
                    })
                        .then(res => {
                            let audit1PassedReasonSum = 0
                            let audit2PassedReasonSum = 0
                            let reviewPassedReasonSum = 0
                            res.data.data.forEach(v => {
                                if (v.taskRefusedNodeType == '1') {
                                    audit1PassedReasonSum++
                                }
                                if (v.taskRefusedNodeType == '2') {
                                    audit2PassedReasonSum++
                                }
                                if (v.taskRefusedNodeType == '3') {
                                    reviewPassedReasonSum++
                                }
                            });
                            self.setState({
                                audit1PassedReasonSum,
                                audit2PassedReasonSum,
                                reviewPassedReasonSum,
                                refusedCountSum: res.data.data.length
                            })
                        })
                })
            }
        }, 0)
    }

    showModal = (record) => {
        this.setState({
            isModalVisible:true
        })
        axios.get('/api/core/taskReturnOrder/list', {
            params: {
                taskId: record.id,
                pageSize: 1000,
            }
        })
        .then(res => {
            console.log(res)
            this.setState({
                returnGuid:res.data.data.reverse()
            })
        })
    };

    handleOk = () => {
        this.setState({
            isModalVisible:false
        })
    };

    render() {
        return (
            <>
                <Modal title="工单退回详情" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleOk} width="1000px">
                    <Menu
                        style={{ width: "100%", borderBottom: '1px solid #000' }}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={[this.state.returnGuid.length - 1]}
                        mode="inline">
                        {
                            this.state.returnGuid.map((v, i) => {
                                return (
                                    <SubMenu style={{ width: "100%" }} key={i} title={'第' + (i + 1) + '次退回'}>
                                        <Menu.Item key="1" style={{ display: 'flex', alignItems: 'center',height:'auto' }}>
                                            <Row style={{width:'100%',padding:'10px 10px 10px 0'}}>
                                                <Col span={24} style={{ lineHeight: '15px', }}>退回类型:{this.state.labelValues[v.taskRefusedReasonType]} {v.isSelfCheck == 1 ? '全部退回完善' : null}</Col>
                                                <Col span={24} style={{ lineHeight: '15px',}}>退回备注:<p style={{whiteSpace:'normal'}}>{v.taskRefusedRemark}</p></Col>
                                            </Row>
                                        </Menu.Item>
                                        {
                                            v.taskRefusedReason!=null && v.taskRefusedRemark!=v.taskRefusedReason && JSON.parse(v.taskRefusedReason) instanceof Array ? JSON.parse(v.taskRefusedReason).map((v, i) => {
                                                return (<Menu.Item key={i + 2} style={{ height: 'auto' }}>
                                                    <Row>
                                                        <Col span="2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #aaa' }}>{v.id}</Col>
                                                        <Col span="7" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #aaa' }}><img style={{ width: '100%', height: 'auto' }} src={audioUrl + v.before} /></Col>
                                                        <Col span="15" style={{ fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', borderBottom: '1px solid #aaa' }}>{v.description}</Col>
                                                    </Row>
                                                </Menu.Item>)
                                            }) : <Menu.Item key="2">未退回照片</Menu.Item>
                                        }
                                    </SubMenu>
                                )
                            })
                        }
                    </Menu>
                </Modal>
                <Col span='24' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>访问员</b>: {this.state.data.principal ? this.state.data.principal : '- -'}</Col>


                <Col span='10' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>一审负责人</b>: {this.state.data.agent ? this.state.data.agent : '- -'}</Col>
                <Col span='10' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>一审时间</b>: {this.state.data.audit1At ? this.state.data.audit1At.split('.')[0] : '- -'}</Col>
                <Col span='4' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>一审退回次数</b>: {this.state.audit1PassedReasonSum}</Col>

                <Col span='10' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>二审负责人</b>: {this.state.data.audit2ByName ? this.state.data.audit2ByName : '- -'}</Col>
                <Col span='10' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>二审时间</b>: {this.state.data.audit2At ? this.state.data.audit2At.split('.')[0] : '- -'}</Col>
                <Col span='4' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>二审退回次数</b>: {this.state.audit2PassedReasonSum}</Col>

                <Col span='10' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>复核负责人</b>: {this.state.data.reviewByName ? this.state.data.reviewByName : '- -'}</Col>
                <Col span='10' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>复核时间</b>: {this.state.data.reviewAt ? this.state.data.reviewAt.split('.')[0] : '- -'}</Col>
                <Col span='4' style={{ padding: '7px 0' }}><b style={{ fontWeight: '550' }}>复核退回次数</b>: {this.state.reviewPassedReasonSum}</Col>

                <Col span='20' style={{ padding: '7px 0' }}></Col>
                <Col span='4' style={{ padding: '7px 0' }}>
                    <b style={{ fontWeight: '550' }}>总退回次数</b>: {this.state.refusedCountSum}
                    <Button size='small' type='link' onClick={e => { this.showModal(this.state.data) }}>查看退回详情</Button>
                </Col>
            </>
        )
    }
}

export default withRouter(App)