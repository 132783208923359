import List from './component/List'
import Particulars from './component/Particulars'

import React, { useEffect, useState, } from 'react';
import { HashRouter, Switch, Route, useHistory , useParams } from 'react-router-dom'
import { Row, Col, Tabs } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'

const { TabPane } = Tabs;

function App(props) {
  const [projectId, setprojectId] = useState(useParams().projectid)//id


  const callback = (key) => {
    
  }

  const [data,setdata]=useState({})
  const [userlist,setuserlist]=useState([])//访问员数据
  const [daililist,setdaililist]=useState([])
  const [numsum,setnumsum]=useState([])
  const [labelValues,setlabelValues]=useState([])//项目状态
  const [locationlist,setlocationlist] =  useState([])//地址列表

  useEffect(()=>{
    (
      async () => {
        const res = await axios.get('/api/core/project/' + projectId)
        console.log(res.data)
        setdata(res.data)
        const userres = await axios.get('/api/sys/user/list', {
          params: {
            roleCode: 'shixiao_vistor',
            pageSize: 1000000,
            groupId: res.data.userGroupId,
            orgCode:'shixiao'
          }
        })
        const locationlistdata = await axios.get('/api/core/location/list',{
          params:{
            locationGroupId:res.data.locationGroupId,
            pageSize: 1000000,
          }
        })
        setlocationlist(locationlistdata.data.data)
        setuserlist(userres.data.data)
        const dailires = await axios.get('/api/sys/user/list', {
          params: {
            roleCode: 'shixiao_proxy',
            pageSize: 1000000,
            groupId: res.data.userGroupId,
            orgCode:'shixiao'
          }
        })
        setdaililist(dailires.data.data)
        const labelValuesdata = await axios.get('/api/sys/dict/projectStatus')
        setlabelValues(labelValuesdata.data.labelValues)
        const numsum = await axios.get('/api/core/project/summary',{
          params:{
            projectId:projectId
          }
        })
        setnumsum(numsum.data)
      }
    )()
  },[])

  return (
    <div style={{  }}>
      <Row style={{background:"#fff",padding: '30px'}}>
        <Col span='24' style={{display:'flex',alignItems:'center'}}>
          <font style={{ fontWeight:'600' ,fontSize:'20px'}}>{data.name}</font>
          <div style={{margin:'0 20px',width:'5px',height:'5px',borderRadius:'50%',background:data.status!=null && labelValues[data.status]!='未开始'?'rgba(82, 196, 25, 1)':'red'}}></div>
          {labelValues[data.status]}
        </Col>
        <Col span='6' style={{margin: '10px 0',}}>项目编号:{data.code}</Col>
        <Col span='8' style={{margin: '10px 0',}}>生效日期:{data.startDate} -- {data.endDate}</Col>
        <Col span='6' style={{margin: '10px 0',}}>预警日期:{data.warningDate}</Col>
        <Col span='4' style={{margin: '10px 0',}}>上传数:{numsum.totalUploadCount}</Col>
        <Col span='6' style={{margin: '10px 0',}}>未开始:{numsum.pendingCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>进行中:{numsum.doingCount}</Col>
        <Col span='6' style={{margin: '10px 0',}}>工单总数:{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',fontWeight:'600'}}>一审:</Col>
        <Col span='8' style={{margin: '10px 0',fontWeight:'600'}}>二审:</Col>
        <Col span='8' style={{margin: '10px 0',fontWeight:'600'}}>复核:</Col>

        <Col span='6' style={{margin: '10px 0',}}>(待审核/总数) : {numsum.audit1PendingCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(待审核/总数) : {numsum.audit2PendingCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(待复核/总数) : {numsum.reviewPendingCount}/{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.audit1ReturnCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.audit2ReturnCount}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(退回中/总数) : {numsum.reviewReturnCount}/{numsum.totalTaskCount}</Col>

        <Col span='6' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.audit1Count}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.audit2Count}/{numsum.totalTaskCount}</Col>
        <Col span='8' style={{margin: '10px 0',}}>(已完成/总数) : {numsum.reviewCount}/{numsum.totalTaskCount}</Col>
      </Row>
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        style={{ background: '#fff' }}
        tabBarStyle={{ padding: '0 30px' }}>
        <TabPane style={{ background: '#eee' }} tab="工单列表" key="1">
          <List userlist={userlist} locationlist={locationlist} daililist={daililist}  data={data}></List>
        </TabPane>
      {/*   <TabPane style={{ background: '#eee' }} tab="项目明细" key="2">
          <Particulars userlist={userlist} data={data}></Particulars>
        </TabPane> */} 
      </Tabs>
    </div>
  )
}

export default App
