import React, { useEffect, useState, useRef } from 'react';
import { HashRouter, Switch, Route, useHistory, useParams } from 'react-router-dom'
import { Row, Col, Tabs, Checkbox, Input, Button, Radio, Select, DatePicker, Cascader, Pagination, message, Popover, Tooltip, Modal } from 'antd'
import axios from 'axios'
import md5 from 'js-md5'
import { StarFilled, UserOutlined, PhoneOutlined } from '@ant-design/icons';
import { options } from './data/design'
import moment from 'moment'
import MD5 from 'js-md5'
// import VConsole from 'vconsole/dist/vconsole.min.js' //import vconsole


const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { data } = require('./data/issue')

interface childProps {
  code: number
}

function App(props) {
  // let vConsole = new VConsole() // 初始化

  const projectId = useParams().projectid
  const wjid = useParams().taskid

  const Arrays = []
  let typedata = data
  typedata.forEach(v => {
    v.children.forEach(v => {
      Arrays.push(v)
    })
  })
  typedata = Arrays

  const [getdata, setgetdata] = useState([])

  const expression = [
    {
      title: '姓名',
      expression: /^[a-zA-Z0-9]{4,8}$|^[\u4e00-\u9fa5]{2,4}$///4-8个字母 或者 2-4个中文字
    },
    {
      title: '身份证',
      expression: /^\d{17}([0-9]|x|X){1}/
    },
    {
      title: '数字',
      expression: /^\d{0,}$///最少0个数字 输入的只能是数字
    },
    {
      title: '手机号',
      expression: /^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6-7])|(17[1-8])|(18[0-9])|(19[1|3])|(19[5|6])|(19[8|9]))\d{8}$/
    },
    {
      title: '固话',
      expression: /^\d{8}$/ // 8个数字
    },
    {
      title: '日期',
      expression: moment('2007-05-05', 'YYYY-MM-DD', true).isValid()
    },
    // {
    //   title:'省份',
    //   expression:
    // },
    // {
    //   title:'城市',
    //   expression:
    // },
    // {
    //   title:'区',
    //   expression:
    // },
    // {
    //   title:'街道',
    //   expression:
    // }
  ]

  const [mobileIsBool, setMobileIsBool] = useState(false) // 是否移动端登录

  const [iPhoneIsBool, setIPhoneIsBool] = useState(false) // 是否移动端登录

  const IsMobile = () => {
    let info = navigator.userAgent;
    let agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod", "iPad"];
    setIPhoneIsBool(info.includes('iPhone'))
    for (let i = 0; i < agents.length; i++) {
      if (info.indexOf(agents[i]) >= 0) return true;
    }
    return false;
  }


  const [refresh, setRefresh] = useState(false);

  useEffect(() => { //强制渲染页面
    refresh && setTimeout(() => setRefresh(false))
  }, [refresh])

  const [pageNumber, setPageNumber] = useState(1) //页数

  let [homedata, sethomedata] = useState({
    title: '',
    type: '',
    remark: ''
  })

  const [paperTypearr, setpaperTypearr] = useState([])

  function str2asc(strstr) {
    return ("0" + strstr.charCodeAt(0).toString(16)).slice(-2);
  }

  useEffect(() => {
    setMobileIsBool(IsMobile())
      axios.get('/api/question/answer/' + wjid)
        .then(resa => {
          console.log(resa)
          if (resa.data?.questionTemplateId != null) {
            axios.get('/api/question/template/'+resa.data.questionTemplateId)
              .then(res => {
                console.log(res)
                console.log(resa.data.questionTemplateId)
                homedata=res.data
                sethomedata(res.data)
                res.data.questions = res.data.questions.sort((a, b) => { return a.idx - b.idx })
  
                res.data.questions.filter((v, i) => {
                  return v.type != '31' && v.type != '32'
                }).forEach((v, i) => {
                  v.no = i + 1
                })
  
                let count = 0
                resa.data.answerItems.forEach((v, i) => {
                  if (res.data.questions[i + count].type == '31') count++
                  res.data.questions[i + count].resultMap = v.resultMap
                  res.data.questions[i + count].result = v.result
                })
  
                res.data.questions.forEach((v, i) => {
                  const fun = () => {
                    let count = false
                    homedata.questions.forEach((a, b) => {
                      if (a.idx == v.skipTo) {
                        count = false
                      }
                      if (a.idx == v.idx) {
                        count = true
                      } else if (a.type != '31' && a.type != '32') {
                        if (count) {
                          a.tiaotibool = true
                        }
                      }
                    })
                  }
                  if (v.type == '31' || v.type == '32') {
                    return
                  }
              
                  fun()
              
                  if ((v.skipType == 0 || v.skipType == null) || v.resultMap?.length == 0) {
                    return
                  }
              
                  if (v.type == '21' || v.type == '22' || v.type == '23' || v.type == '24') {
                    let count = 0
                    v.resultMap.forEach(a => {
                      if (a.value == '') {
                        count++
                      }
                    })
                    if (count == v.resultMap.length) {
                      fun()
                      return
                    }
                  }
                  let count = false
                  if (v.type == '1' && v.skipType == '1') {
                    if (v.resultMap == null) {
                      return
                    }
                    let arr = v.options.filter(a => {
                      return a.idx == v.resultMap[0]?.checked
                    })
                    if (arr.length > 0) {
                      if (arr[0].skipTo != null) {
                        homedata.questions.forEach((a, b) => {
                          let num = arr[0].skipTo
                          if (num == 0) {
                            num = homedata.questions[homedata.questions.length - 1].idx
                          }
                          if (num == -1) {
                            num = -1
                          }
                          if (a.idx == num) {
                            count = false
                          }
                          if (a.idx == v.idx) {
                            count = true
                          } else if (count) {
                            a.tiaotibool = false
                          }
                        })
                      }
                    }
                  } else {
                    if (v.resultMap == null) {
                      return
                    }
                    homedata.questions.forEach((a, b) => {
                      let num = v.skipTo
                      if (v.skipTo == 0) {
                        num = homedata.questions[homedata.questions.length - 1].idx
                      }
                      if (v.skipTo == -1) {
                        num = -1
                      }
                      if (a.idx == num) {
                        count = false
                      }
                      if (a.idx == v.idx) {
                        count = true
                      } else if (count) {
                        a.tiaotibool = false
                      }
                    })
                  }
                })
  
                res.data.questions.forEach((v, i) => {//处理多项天空
                  v.tiaotibool = true
                  if (v.type == '22') {
                    v.num = 0
                    let arr = v.title.split('')
                    let count = 0
                    let src = ''
                    arr.forEach((a, i) => {
                      if (a == '_') {
                        if (arr[i + 1] == '_') {
                          count += 8
                        } else {
                          v.num = v.num + 1
                          count += 8
                          src += `<input class='duoxiang${v.id}' disabled={true}  style="border:0px;outline:none;border-bottom: 0.5px solid #000;;color:#000;width:${count}px;height:30px" />`
                          count = 0
                        }
                      } else {
                        src += a
                      }
                    });
                    v.title = src
                  }else if (v.type == '5') {
                    v.options.forEach(a=>{
                      let count = true
                      if(v.resultMap!=null){
                        v.resultMap.forEach(b=>{
                          if(a.idx==b.checked){
                            a.index=b.index
                            count = false
                          }
                        })
                      }
                      if(count){
                        a.index = 10000
                      }
                    })
                    v.options.sort((a,b)=>{
                      return a.index - b.index
                    })
                  }
                })
                count = 0
                let vrr = []
                res.data.questions.forEach(v => {
                  if (res.data.pageFlag != 1) {
                    if (v.type == '31') {
                      count++
                    } else {
                      if (!(vrr[count] instanceof Array)) {
                        vrr[count] = []
                      }
                      vrr[count].push(v)
                    }
                  } else {
                    if (v.type != '31') {
                      vrr.push([v])
                    }
                  }
                })
                console.log(vrr)
                setgetdata(vrr)
                setRefresh(true)
              })
          }
        })
    axios.get('/api/sys/dict/paperType')
      .then(res => {
        setpaperTypearr(res.data.labelValues)
      })
  }, [])

  const [inputBoolds, setInputBoolds] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      duoxiangtiankong()
      setRefresh(true)
    }, 300)
    let d = document.getElementsByClassName('inputss')
    d = [...d]
    if (d.length != 0) {
      setInputBoolds(false)
      setTimeout(() => {
        setInputBoolds(true)
      }, 100)
    }
  }, [getdata, pageNumber])

  const duoxiangtiankong = () => {  //多项填空处理
    if (getdata[pageNumber - 1] != undefined) {
      getdata[pageNumber - 1].forEach((v, i) => {//处理多项天空
        if (v.type == '22') {
          setTimeout(() => {
            let arr = document.getElementsByClassName('duoxiang' + v.id)
            console.log(arr)
            arr = [...arr]
            arr.forEach((a, b) => {
              if (v.resultMap != undefined) {
                a.value = v?.resultMap?.filter(a => a.checked == b)[0]?.value ?? ''
              }
            })
          }, 1000)
        } else if (v.type == '52') {
          mouseout(v, i, 0, i, true)
        }
      })
    }
  }

  const NPSfun = (b, v, e, i) => {   //NPS 点击
    if (v.resultMap != undefined) {
      v.resultMap[0].checked = b
    } else {
      v.resultMap = [{ checked: b + '' }]
    }
    setRefresh(true)
  }

  const mouseover = (a, b, i, e, v) => {  //NPS 划入
    if (a.type == '52') {
      let domarr = [...document.getElementsByClassName('NPS' + i)]
      domarr.forEach((v, i) => {
        if (i <= b) {
          v.style.background = 'none'
        } else {
          v.style.background = '#fff'
        }
      })
    } else if (a.type == '56') {
      let domarr = [...document.getElementsByClassName('xing' + i)]
      domarr.forEach((v, i) => {
        if (i <= b) {
          v.style.color = '#00b4ff'
        } else {
          v.style.color = '#ddd'
        }
      })
    }
  }

  const mouseout = (v, index, a, i, e, bool) => {
    if (v.type == '52') {
      let domarr = [...document.getElementsByClassName('NPS' + index)]
      domarr.forEach((a, i) => {  //NPS 划出
        if (i <= (v.resultMap != undefined ? v.resultMap[0].checked : -1)) {
          a.style.background = 'none'
        } else {
          a.style.background = '#fff'
        }
      })
    } else if (v.type == '56') {
      let domarr = [...document.getElementsByClassName('xing' + index)]
      domarr.forEach((a, i) => {
        if (i <= (v.resultMap != undefined ? v.resultMap[0].checked : -1)) {
          a.style.color = '#00b4ff'
        } else {
          a.style.color = '#ddd'
        }
      })
    }
    setRefresh(true)
  }

  const [zhixuanrancuoti, setZhixuanrancuoti] = useState(false)


  function onChange(e) {
    function jianyan(a) {
      let count = false
      getdata[pageNumber - a ].forEach(v => {
        if (!v.peieBools && v.tiaotibool && (v?.relatedList == null ||
          getdata.filter(a => {//判断关联的选项是否选中
            return a.filter(b => {
              let arr = v?.relatedList.filter(c => c.timuIdx == b.idx)[0]
              return b.idx == arr?.timuIdx &&
                b.resultMap?.filter(c => {
                  return arr?.options.includes(Number(c.checked))
                }).length > 0
            }).length > 0
          }).length > 0)) {
          count = true
        }
      })
      if (count) {
        return a
      }
      return jianyan(a + 1)
    }
    function jiayan(a) {
      let count = false
      getdata[pageNumber + a].forEach(v => {
        if (!v.peieBools && v.tiaotibool && (v?.relatedList == null ||
          getdata.filter(a => {//判断关联的选项是否选中
            return a.filter(b => {
              let arr = v?.relatedList.filter(c => c.timuIdx == b.idx)[0]
              return b.idx == arr?.timuIdx &&
                b.resultMap?.filter(c => {
                  return arr?.options.includes(Number(c.checked))
                }).length > 0
            }).length > 0
          }).length > 0)) {
          count = true
        }
      })
      if (count) {
        return a + 1
      }
      if (count == false && zhixuanrancuoti) {
        return getdata.length
      }
      return jiayan(a + 1)
    }
    if (e) {
      if (pageNumber != 1) {
        setPageNumber(pageNumber - jianyan(1))
      } else {
        message.error('已到第一题')
      }
    } else {
      if (pageNumber != getdata.length) {
            setPageNumber(pageNumber + jiayan(0))
      } else {
        message.error('已到最后一题')
      }
    }
  }

  const danxuanshuru = (v, a, e) => {//单选输入框
    if (v.resultMap[0]?.checked == a.idx) {
      v.resultMap[0].value = e.target.value
      setRefresh(true)
    }
  }

  const duoxuanshuru = (v, a, e) => {//多选输入框
    if (v.resultMap != null) {
      v.resultMap.forEach((v, i) => {
        if (v.checked == a.idx) {
          v.value = e.target.value
          setRefresh(true)
        }
      })
    }
  }

  const duoxuanxuanze = (v, a, e) => { //多选选择  
    if (a.excludeTo == 1) {
      v.resultMap = null
    }
    if (v.resultMap != null) {
      if (e.target.checked) {
        v.resultMap.push({ checked: a.idx, index: a.idx + '' })
      } else {
        const arr = v.resultMap
        v.resultMap.forEach((v, i) => {
          if (v.checked == a.idx) {
            arr.splice(i, 1)
          }
        })
        v.resultMap = arr
      }
    } else {
      v.resultMap = [{ checked: a.idx, index: a.idx + '' }]
    }
    if (a.excludeTo != 1) {
      v.options.forEach(c => {
        if (c.excludeTo == 1) {
          v.resultMap.forEach((b, i) => {
            if (b.checked == c.idx) {
              v.resultMap.splice(i, 1)
            }
          })
        }
      })
    }
    setRefresh(true)
  }

  const juzhenTiankong = (v, id, e, a) => { //矩阵填空
    if (v.type == '22') {
      a = v.options[id]
      let isbool = true
      if (a?.validate) {
        isbool = expression.filter(v => v.title == a.validate)[0].expression.test(e.target.value)
      }
      if (v.resultMap != null) {
        let count = true
        v.resultMap.forEach((v, i) => {
          if (v.checked == id) {
            if (isbool) {
              v.value = e.target.value
              count = false
            } else {
              v.value = ''
              count = false
            }
          }
        })
        if (count) {
          if (isbool) {
            v.resultMap.push({ checked: id, index: id + '', value: e.target.value })
          } else {
            v.resultMap.push({ checked: id, index: id + '', value: '' })
          }
        }
      } else {
        if (isbool) {
          v.resultMap = [{ checked: id, index: id + '', value: e.target.value }]
        } else {
          v.resultMap = [{ checked: id, index: id + '', value: '' }]
        }
      }
      let arr = document.getElementsByClassName('duoxiang' + v.id)
      arr = [...arr]
      arr.forEach((a, b) => {
        if (v.resultMap != undefined) {
          if (v.resultMap && v.resultMap.filter((v, i) => { return v.checked == b })[0]?.value == '') {
            a.style.border = '1px solid red'
          } else {
            a.style.border = '0px'
            a.style.borderBottom = '0.5px solid #000'
          }
        }
      })
      setRefresh(true)
    } else if (v.type == '23') {
      let isbool = true
      if (a.validate) {
        isbool = expression.filter(v => v.title == a.validate)[0].expression.test(e.target.value)
      }
      if (v.resultMap != null) {
        let count = true
        v.resultMap.forEach((v, i) => {
          if (v.checked == id) {
            if (isbool) {
              v.value = e.target.value
              count = false
            } else {
              v.value = ''
              count = false
            }
          }
        })
        if (count) {
          if (isbool) {
            v.resultMap.push({ checked: id, index: id + '', value: e.target.value })
          } else {
            v.resultMap.push({ checked: id, index: id + '', value: '' })
          }
        }
      } else {
        if (isbool) {
          v.resultMap = [{ checked: id, index: id + '', value: e.target.value }]
        } else {
          v.resultMap = [{ checked: id, index: id + '', value: '' }]
        }
      }
    }
    setRefresh(true)
  }

  const juzhendanxuan = (v, i, e) => {  //矩阵单选
    if (v.resultMap != undefined) {
      let count = true
      v.resultMap.forEach(a => {
        if (a.index == i) {
          a.checked = e.target.value
          count = false
        }
      })
      if (count) {
        v.resultMap.push({ checked: e.target.value + '', index: i + '' })
      }
    } else {
      v.resultMap = [{ checked: e.target.value + '', index: i + '' }]
    }
    setRefresh(true)
  }

  const juzhendanxuaninput = (v, i, e) => {  //矩阵单选输入框
    v.resultMap.forEach(a => {
      if (a.index == i && a.checked == v.idx) {
        a.value = e.target.value
      }
    })
    setRefresh(true)
  }

  const pingfendanxuaninput = (v, i, e) => {  //评分单选
    v.resultMap.forEach(a => {
      if (a.checked == i.idx) {
        a.value = e.target.value
      }
    })
    setRefresh(true)
  }

  const Jzduoxuanxuanze = (v, i, e, a) => { //  矩阵多选
    if (a.excludeTo == 1) { // 互斥
      v.resultMap = v.resultMap?.filter(v => {
        return v.index != i
      })
    }
    if (v.resultMap != null) {
      if (e.target.checked) {
        v.resultMap.push({ checked: a.idx + '', index: i + '' })
      } else {
        const arr = v.resultMap
        v.resultMap.forEach((v, l) => {
          if (v.checked == a.idx && v.index == i) {
            arr.splice(l, 1)
          }
        })
        v.resultMap = arr
      }
    } else {
      v.resultMap = [{ checked: a.idx + '', index: i + '' }]
    }
    if (a.excludeTo != 1) { // 互斥
      v.options.forEach((c, d) => {
        if (c.excludeTo == 1) {
          v.resultMap.forEach((b, l) => {
            if (b.checked == c.idx && b.index == i) {
              v.resultMap.splice(l, 1)
            }
          })
        }
      })
    }
    setRefresh(true)
  }


  const paixu = (v, a, b) => {
    let arr = []
    let count = null
    if (v.resultMap != null) {
      arr = v.resultMap.filter(v => v.checked == a.idx)
      if (arr.length > 0) {
        v.resultMap.forEach((v, i) => {
          if (v.checked == arr[0].checked) {
            count = i
          }
        })
      }
    }
    if (v.type == '7' && v.resultMap && v.resultMap.length + 1 == v.options.length && count == null) {
      axios.get('/api/question/answer/question/' + v.id)
        .then(res => {
          let arr = [] // 储存处理好的数据
          const brr = [] // 储存处理好的配额答题次数
          const crr = [] // 储存处理好的数据
          v.options.forEach(v => {
            brr.push(0)
          })
          res.data.data.forEach((a, b) => {
            a.resultMap = JSON.parse(a.resultMap).sort((a, b) => { return a.index - b.index })
            crr.push(a.resultMap.filter(v=>{
              return v.isCount == 1
            })[0])
          })
          crr.forEach((a,b)=>{
            if(a){
              brr[a.checked]++
            }
          })

          function zhanshi(){
            let count = true
            v.options.forEach(v => {
              if (v.score) {
                v.relatedTo.split(',').forEach(v => {
                  homedata.questions.forEach(a => {
                    if (v == a.idx) {
                      a.peieBools = true
                    }
                  })
                })
              }
            })

            v.resultMap.forEach((a,b)=>{
              v.options.forEach((l,j)=>{
                if(count){
                  if(a.checked == j){
                    if(l.score>brr[j]){
                      count = false
                      a.isCount=1
                      arr = homedata.questions.filter((a,b)=>{
                        return l.relatedTo.split(',').includes(String(a.idx))
                      })
                      arr.forEach(v=>{
                        v.peieBools = false
                      })
                    }
                  }
                }
              })
            })

            if(arr.length == 0){
              axios.put('/api/question/template/update/score',{},{
                params:{
                  questionId:v.id
                }
              })
              .then(res => {
                zhanshi()
              })
            }
          }
          zhanshi()
          setRefresh(true)
        })
    }else{
      if(v.type == '7'){
        v.options.forEach(v => {
          if (v.score) {
            v.relatedTo.split(',').forEach(v => {
              homedata.questions.forEach(a => {
                if (v == a.idx) {
                  a.peieBools = true
                }
              })
            })
          }
        })
      }
    }

    if (v.resultMap != null) {
      let arr = v.resultMap.filter(v => v.checked == a.idx)
      if (arr.length > 0) {
        let count = null
        v.resultMap.forEach((v, i) => {
          if (v.checked == arr[0].checked) {
            count = i
          }
        })
        if (count != null) {
          v.resultMap.splice(count, 1)
          let bools = false
          v.resultMap.forEach((v, i) => {
            v.index = i + 1 + ''
          })
        }
        setRefresh(true)
      } else {
        v.resultMap.push({ checked: a.idx, index: v.resultMap.length + 1 + '' })
        setRefresh(true)
      }
    } else {
      v.resultMap = [{ checked: a.idx, index: 1 + '' }]
      setRefresh(true)
    }
  }

  const tiaoti = (v) => {//跳题
    const fun = () => {
      let count = false
      homedata.questions.forEach((a, b) => {
        if (a.idx == v.skipTo) {
          count = false
        }
        if (a.idx == v.idx) {
          count = true
        } else if (a.type != '31' && a.type != '32') {
          if (count) {
            a.tiaotibool = true
          }
        }
      })
    }
    if (v.type == '31' || v.type == '32') {
      return
    }

    fun()

    if ((v.skipType == 0 || v.skipType == null) || v.resultMap?.length == 0 && v.type != '7') {
      return
    }

    if (v.type == '21' || v.type == '22' || v.type == '23' || v.type == '24') {
      let count = 0
      v.resultMap.forEach(a => {
        if (a.value == '') {
          count++
        }
      })
      if (count == v.resultMap.length) {
        fun()
        return
      }
    }

    let count = false
    if (v.type == '1' && v.skipType == '1') {
      if (v.resultMap == null) {
        return
      }
      let arr = v.options.filter(a => {
        return a.idx == v.resultMap[0]?.checked
      })
      if (arr.length > 0) {
        if (arr[0].skipTo != null) {
          homedata.questions.forEach((a, b) => {
            if (a.type != '32') {
              let num = arr[0].skipTo
              if (num == 0) {
                num = homedata.questions[homedata.questions.length - 1].idx
              }
              if (num == -1) {
                num = -1
                setTiaotiBool(true)
                setTiaotiData(v)
              }
              if (a.idx == num) {
                count = false
              }
              if (a.idx == v.idx) {
                count = true
              } else if (count) {
                a.tiaotibool = false
              }
            }
          })
        }
      }
    } else {
      if (v.resultMap == null) {
        return
      }
      homedata.questions.forEach((a, b) => {
        let num = v.skipTo
        if (v.skipTo == 0) {
          num = homedata.questions[homedata.questions.length - 1].idx
        }
        if (v.skipTo == -1) {
          num = -1
          setTiaotiBool(true)
          setTiaotiData(v)
        }
        if (a.idx == num) {
          count = false
        }
        if (a.idx == v.idx) {
          count = true
        } else if (count) {
          a.tiaotibool = false
        }
      })
    }
  }

  const [tiaotiData, setTiaotiData] = useState({})

  const [tiaotiBool, setTiaotiBool] = useState(false)

  const biaogetiankongChange = (v, value, a, b, i) => {
    let isbool = true
    if (b.validate) {
      isbool = expression.filter(v => v.title == b.validate)[0].expression.test(value)
    }
    if (v.resultMap != null) {
      let count = true
      v.resultMap.forEach((v) => {
        if (v.checked == b.idx && v.index == i) {
          if (isbool) {
            v.value = value
            count = false
          } else {
            v.value = ''
            count = false
          }
        }
      })
      if (count) {
        if (isbool) {
          v.resultMap.push({ checked: b.idx + '', index: i + '', value: value })
        } else {
          v.resultMap.push({ checked: b.idx + '', index: i + '', value: '' })
        }
      }
    } else {
      if (isbool) {
        v.resultMap = [{ checked: b.idx + '', index: i + '', value: value }]
      } else {
        v.resultMap = [{ checked: b.idx + '', index: i + '', value: '' }]
      }
    }
    setRefresh(true)
  }

  const handleOk = () => {
    
    setTiaotiBool(false);
  };

  const handleCancel = () => {
    tiaotiData.resultMap = null
    tiaoti(tiaotiData)
    getdata.forEach(v => {
      if (v.idx == tiaotiData.idx) {
        v.resultMap = null
      }
    })
    setRefresh(true)
    setTiaotiBool(false);
  };

  const [jieshu, setJieshu] = useState(false)
  const [wuxiao, setWuxiao] = useState(false)

  return (
    <div id='Answer' style={{ width: '100%', height: '100%', overflow: 'auto', background: '#fff', position: 'relative' }}>
      <div style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', zIndex: 10000, background: '#fff', display: jieshu || wuxiao ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          {
            jieshu?
              <span dangerouslySetInnerHTML={{ __html: homedata.thanksMsg }}></span>
            :null
          }
          {
            wuxiao?
              <p style={{textAlign:'center'}}>抱歉答题无效</p>
            :null
          }
        </div>
      </div>
      <Modal title="您确定结束作答么" visible={tiaotiBool} onOk={handleOk} onCancel={handleCancel}>
        您确定结束作答么
      </Modal>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        <div style={{ flex: '1', overflowX: 'auto', width: '100%' }}>
          <div style={{ borderBottom: '1px solid #ccc', padding: '0 0.5rem 10px ', }}>
            <h1 style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>{homedata.title}</h1>
            <h5 style={{ margin: '20px 0' }} dangerouslySetInnerHTML={{ __html: homedata.remark == '' ? '请输入问卷说明' : homedata.remark }}></h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ lineHeight: '30px', marginRight: '20px' }}>问卷类型: {paperTypearr[homedata.type]}</p>
            </div>
          </div>
          {
            getdata[pageNumber - 1]?.map((v, i) => {
              return !v.peieBools && v.tiaotibool && (v.relatedList == null ||
                getdata.filter(a => {//判断关联的选项是否选中
                  return a.filter(b => {
                    let arr = v.relatedList.filter(c => c.timuIdx == b.idx)[0]
                    return b.idx == arr?.timuIdx &&
                      b.resultMap?.filter(c => {
                        return arr?.options.includes(Number(c.checked))
                      }).length > 0
                  }).length > 0
                }).length > 0)
                ? <Row className='wenjuan' style={{
                  position: 'relative',
                  margin: '10px 10px',
                  border: v.isBool ? '0.5px red solid' : 'none',
                  borderBottom: '1px solid #f2f2f2',
                  boxSizing: 'border-box',
                }}>
                  <Col span='24' style={{ display: v.type == '32' ? 'block' : 'flex', }}>
                    { //显示隐藏 必填星号 和 序号
                      v.type != '31' &&
                        v.type != '32' ? <><span style={{ color: 'red', display: 'inline-block', width: '10px', lineHeight: '30px' }}>{v.must == 1 ? '*' : null}</span>
                        {
                          homedata.idxFlag != 1 ?
                            <b style={{ fontWeight: '600', lineHeight: '30px' }}>{v.no + '.'}</b>
                            : null
                        }
                      </>//
                        : null
                    }
                    {
                      v.type == '32' ? <p style={{ width: '100%', textAlign: 'center' }}></p> : null
                    }
                    { //显示隐藏 题目名称
                      v.type != '31' ? <span className='title' style={{ lineHeight: '30px', flex: 1 }} dangerouslySetInnerHTML={{ __html: v.title }} ></span> : null
                    }
                    { // 后面的小标题 标记是什么题
                      v.type != '31' && v.type != '32' ? <span style={{ textAlign: 'center', lineHeight: '1.2rem', marginTop: "3px", marginLeft: "10px", color: "#aaa", fontSize: '0.6rem', border: '0.5px #999 solid', padding: '0 5px', borderRadius: '5px', height: '1.2rem' }}>{typedata.filter(a => { return a.code == v.type })[0]?.title}</span> : null
                    }
                  </Col>
                  <Col span='24' >
                    { //单选题
                      //
                      v.type == '1' ||
                        v.type == '62' ?
                        <Radio.Group value={v.resultMap != null ? v.resultMap[0]?.checked : null} >
                          {
                            v.options?.map((a, b) => {
                              return <div>
                                <Radio value={a.idx} style={{ display: 'flex', alignItems: 'center', fontSize: '15px', height: '40px' }}>{a.title}
                                  {
                                    a.isNeedGap == 1 && v.resultMap?.filter(v => v.checked == a.idx).length > 0 ?
                                      <>
                                        {
                                          a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                        }
                                        <Input
                                          disabled={true}
                                          defaultValue={v.resultMap != null && v.resultMap[0]?.checked == a.idx ? v.resultMap[0]?.value : null}
                                          style={{ width: '200px', height: iPhoneIsBool ? '35px' : '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}
                                        ></Input>
                                      </>
                                      : null
                                  }
                                </Radio>
                                <p style={{ paddingLeft: '23px', color: '#ccc' }}>{a.remark}</p>
                              </div>
                            })
                          }
                        </Radio.Group> : null
                      //
                    }
                    { //多选题
                      v.type == '2' ? v.options?.map((a, b) => {
                        return <div>
                          <Checkbox 
                            checked={v.resultMap?.filter((v, i) => { return v.checked == a.idx }).length > 0}
                            disabled={!a.select && v?.resultMap?.length == v.rateMax && v.rateMax != null && v?.resultMap?.filter(v => { return v.checked == a.idx })?.length == 0}
                            style={{ display: 'flex', alignItems: 'center', fontSize: '15px', height: '40px' }}
                            value={a.idx}
                          >{a.title}{
                              a.isNeedGap == 1 && v.resultMap?.filter(v => v.checked == a.idx).length > 0 ?
                                <>
                                  {
                                    a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                  }
                                  <Input onClick={e => { e.stopPropagation() }}
                                    disabled={true}
                                    defaultValue={v.resultMap?.filter((v, i) => { return v.checked == a.idx })[0]?.value}
                                    style={{ width: '200px', height: iPhoneIsBool ? '35px' : '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                                </>
                                : null
                            }</Checkbox>
                          <p style={{ paddingLeft: '30px', color: '#ccc' }}>{a.remark}</p>
                        </div>
                      }) : null
                    }
                    { //单选下拉框
                      v.type == '3' ?
                        <Select style={{ width: 120 }} defaultValue={v.resultMap != null ? v.resultMap[0]?.checked : null} >
                          {
                            v.options?.map((a, b) => {
                              return <Option value={a.idx}>{a.title}</Option>
                            })
                          }
                        </Select> : null
                      //
                    }
                    { //多选下拉框
                      v.type == '4' ?
                        <Select mode="tags" style={{ width: '100%' }} defaultValue={v.resultMap != null ? v.resultMap.map(v => v.checked) : []} >
                          {
                            v.options?.map((a, b) => {
                              return <Option value={a.idx}>{a.title}</Option>
                            })
                          }
                        </Select> : null
                      //
                    }
                    { //排序
                      v.type == '5' ||
                        v.type == '7' ? v.options?.map((a, b) => {
                          return <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }} onClick={e => { !a.select && v?.resultMap?.length == v.rateMax && v.rateMax != null && v?.resultMap?.filter(v => { return v.checked == a.idx })?.length == 0 ? console.log(e) : paixu(v, a, b) }}>
                            <div style={{ width: '20px', height: '20px', display: 'inline-block', lineHeight: '20px', textAlign: 'center', color: '#fff', background: v.resultMap?.filter(v => v.checked == a.idx)?.length > 0 ? '#0095ff' : '#e2e2e0' }}>{v.resultMap?.filter(v => v.checked == a.idx)[0]?.index}</div>
                            <div style={{flex:1}}>
                              {a.title}{
                                a.isNeedGap == 1 && v.resultMap?.filter(v => v.checked == a.idx).length > 0 ?
                                  <>
                                    {
                                      a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                    }
                                    <Input onClick={e => { e.stopPropagation() }}
                                      disabled={true}
                                      defaultValue={v.resultMap?.filter((v, i) => { return v.checked == a.idx })[0]?.value}
                                      style={{ width: '200px', height: iPhoneIsBool ? '35px' : '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                                  </>
                                  : null
                              }
                            </div>
                          </div>
                        }) : null
                    }
                    { //多级联动
                      v.type == '6' ? <Cascader options={v.multiLinkList} placeholder="请选择" defaultValue={v.resultMap != null ? v.resultMap[0]?.checked : null} /> : null
                    }
                    { // 填空
                      v.type == '21' ||
                        v.type == '61' ||
                        v.type == '63' ?
                        <Tooltip title="填写错误请检查" visible={v.resultMap ? v.resultMap[0]?.value == '' : false}>
                          {
                            inputBoolds ? <Input
                              className='inputss'
                              disabled={true}
                              defaultValue={v.resultMap ? v.resultMap[0]?.value : null}
                              style={{ border: v.resultMap ? v.resultMap[0]?.value != '' ? '1px solid #d9d9d9' : '1px solid red' : null }}
                              prefix={
                                v.type == '61' ? <UserOutlined /> :
                                  v.type == '63' ? <PhoneOutlined /> : null
                              } /> : null
                          }
                        </Tooltip> : null
                      //
                    }
                    { //矩阵填空
                      v.type == '23' ?
                        v.options.map((a, i) => {
                          return <Row style={{ marginTop: '10px' }}>
                            <Col span='13'>{a.title}</Col>
                            <Col span='10'>
                              <span style={{ color: 'red', display: 'inline-block', width: '10px' }}>{a.isNeedGap == 1 ? '*' : null}</span>
                              <Tooltip title="填写错误请检查" visible={v.resultMap ? v.resultMap.filter((v, i) => { return v.checked == a.idx })[0]?.value == '' : false}>
                                {
                                  inputBoolds ? <Input
                                    className='inputss'
                                    disabled={true}
                                    style={{ height: iPhoneIsBool ? '35px' : '20px', width: '80%', border: v.resultMap ? v.resultMap.filter((v, i) => { return v.checked == a.idx })[0]?.value != '' ? '1px solid #d9d9d9' : '1px solid red' : '1px solid #d9d9d9' }}
                                    defaultValue={v.resultMap != null ?
                                      v.resultMap.filter((v, i) => {
                                        return v.checked == a.idx
                                      })[0]?.value : null} />
                                    : null
                                }
                              </Tooltip>
                            </Col>
                          </Row>
                        }) : null
                      //
                    }
                    { //表格填空
                      v.type == '24' ?
                        <div>
                          <Row justify='space-between'>
                            <Col span='8'>
                              { }
                            </Col>
                            {
                              v.options.map(a => {
                                return <Col flex='1' style={{ textAlign: 'center' }}>
                                  <p>{a.title}</p>
                                  <p style={{ color: '#ff9900' }}>{a.score}</p>
                                </Col>
                              })
                            }
                          </Row>
                          {
                            v.chooseItemNames.map((a, i) => {
                              return <Row style={{ borderBottom: '0.5px solid #ddd', padding: '10px 0', marginBottom: '10px' }}>
                                <Col span='8'>
                                  {a} 
                                </Col>
                                <Col span='16' style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                  {
                                    v.options.map(b => {
                                      return <div style={{ width: 100 / v.options.length + '%', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                        <span style={{ color: 'red', display: 'inline-block', width: '15px' }}>{b.isMust == 1 ? '*' : null}</span>
                                        <Tooltip title="填写错误请检查" visible={v.resultMap ? v.resultMap?.filter(v => v.checked == b.idx && v.index == i)[0]?.value == '' : false}>
                                          {
                                            inputBoolds ? <Input
                                              className='inputss'
                                              disabled={true}
                                              style={{ textAlign: 'center', height: iPhoneIsBool ? '35px' : '20px', border: v.resultMap ? v.resultMap?.filter(v => v.checked == b.idx && v.index == i)[0]?.value != '' ? '1px solid #d9d9d9' : '1px solid red' : '1px solid #d9d9d9' }}
                                              defaultValue={v.resultMap?.filter(v => v.checked == b.idx && v.index == i)[0]?.value}
                                              ></Input>
                                              : null
                                          }
                                        </Tooltip>
                                      </div>
                                    })
                                  }
                                </Col>
                              </Row>
                            })
                          }
                        </div>
                        : null
                      //
                    }
                    { //矩阵选择
                      v.type == '41' ||
                        v.type == '42' ||
                        v.type == '55' ?
                        <div>
                          { //矩阵量表 上部提示信息
                            v.type == '55' ? <Row>
                              <Col span='8'>
                                <p></p>
                                <p style={{ fontWeight: '600', color: '#ff9900' }}>分值</p>
                              </Col>
                              <Col span='16' style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                {
                                  v.options.map(a => {
                                    return <div style={{ width: 100 / v.options.length + '%', textAlign: 'center' }}>
                                      <p>{a.title}</p>
                                      <p style={{ color: '#ff9900' }}>{a.score}</p>
                                    </div>
                                  })
                                }
                              </Col>
                            </Row> : null
                          }
                          {
                            v.type == '41' ||
                              v.type == '42' ? <Row>
                              <Col span='8'>

                              </Col>
                              <Col span='16' style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                {
                                  v.options.map(a => {
                                    return <div style={{ width: 100 / v.options.length + '%', textAlign: 'center' }}>
                                      <p>{a.title}</p>
                                    </div>
                                  })
                                }
                              </Col>
                            </Row> : null
                          }
                          {
                            v.chooseItemNames.map((a, i) => {
                              return <Row style={{ borderBottom: '0.5px solid #ddd', padding: '10px 0', marginBottom: '10px' }}>
                                <Col span='8'>
                                  {a}
                                </Col>
                                <Col span='16'>
                                  { //矩阵单选
                                    v.type == '41' ? <Radio.Group style={{ display: 'flex' }} value={v.resultMap?.filter(v => { return v.index == i })[0]?.checked}
                                      
                                    >
                                      {
                                        v.options?.map((a, b) => {
                                          return <div style={{ width: 100 / v.options.length + '%', textAlign: 'center', display: 'inline-block' }}>
                                            <Radio value={a.idx}>
                                              {
                                                a.isNeedGap == 1 && v.resultMap?.filter(v => v.checked == a.idx && v.index == i).length > 0 ?
                                                  <>
                                                    {
                                                      a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                                    }
                                                    <Input
                                                      style={{ width: '200px', height: iPhoneIsBool ? '35px' : '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}
                                                      disabled={true}
                                                      value={v.resultMap?.filter(v => { return v.index == i && v.checked == a.idx })[0]?.value} disabled={v.resultMap != undefined ? v.resultMap?.filter(v => { return v.index == i && v.checked == a.idx }).length == 0 : true} style={{ width: '100px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }} ></Input>
                                                  </>
                                                  : null
                                              }
                                            </Radio>
                                          </div>
                                        })
                                      }
                                    </Radio.Group> : null
                                    //
                                  }
                                  {//矩阵多选
                                    v.type == '42' ?
                                      v.options?.map((a, b) => {
                                        return <Checkbox style={{ width: 100 / v.options.length + '%', margin: '0', textAlign: 'center', display: 'inline-block' }} value={a.idx}
                                          checked={v.resultMap != null && v.resultMap.length != 0 ? v.resultMap?.filter(v => { return v.checked == a.idx && v.index == i }).length > 0 : false}
                                          disabled={!a.select && v?.resultMap?.filter(v => { return v.index == i })?.length == v.rateMax && v.rateMax != null && v?.resultMap?.filter(v => { return v.checked == a.idx && v.index == i })?.length == 0}
                                          
                                        >
                                          {
                                            a.isNeedGap == 1 && v.resultMap?.filter(v => v.checked == a.idx && v.index == i).length > 0 ?
                                              <>
                                                {
                                                  a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                                }
                                                <Input
                                                  style={{ width: '200px', height: iPhoneIsBool ? '35px' : '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}
                                                  disabled={true}
                                                  value={v.resultMap?.filter(v => { return v.index == i && v.checked == a.idx })[0]?.value} disabled={v.resultMap != undefined ? v.resultMap?.filter(v => { return v.index == i && v.checked == a.idx }).length == 0 : true} style={{ width: '100px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }} ></Input>
                                              </>
                                              : null
                                          }</Checkbox>
                                      })
                                      : null
                                    //
                                  }
                                  {//矩阵量表 下部选择
                                    v.type == '55' ? <Radio.Group
                                      defaultValue={v.resultMap?.filter(v => { return v.index == i })[0]?.checked}
                                      style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                      {
                                        v.options?.map((a, b) => {
                                          return <Radio value={a.idx}>
                                            {
                                              a.isNeedGap == 1 && v.resultMap?.filter(v => v.checked == a.idx && v.index == i).length > 0 ?
                                                <>
                                                  {
                                                    a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                                  }
                                                  <Input
                                                  disabled={true}
                                                    style={{ width: '200px', height: iPhoneIsBool ? '35px' : '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}
                                                    value={v.resultMap?.filter(v => { return v.index == i && v.checked == a.idx })[0]?.value} disabled={v.resultMap != undefined ? v.resultMap?.filter(v => { return v.index == i && v.checked == a.idx }).length == 0 : true} style={{ width: '100px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }} ></Input>
                                                </>
                                                : null
                                            }</Radio>
                                        })
                                      }
                                    </Radio.Group> : null
                                    //
                                  }
                                </Col>
                              </Row>
                            })
                          }
                        </div>
                        : null
                    }
                    {//量标题
                      v.type == 51 ?
                        <div>
                          <span style={{ margin: '10px 0' }}>{v.options[0]?.title}</span>
                          <Radio.Group defaultValue={v.resultMap != null ? v.resultMap[0]?.checked : null}>
                            {
                              //
                              v.options?.map((a, b) => {
                                return <Popover content={(<div>{a.title}</div>)} >
                                  <Radio value={a.idx}>{a.score}</Radio>
                                </Popover>
                              })
                            }
                          </Radio.Group>
                          <span style={{ margin: '10px 0' }}>{v.options[v.options.length - 1]?.title}</span>
                        </div>
                        : null
                    }
                    {//NPS量表
                      //
                      v.type == 52 ?
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
                          <span style={{ margin: '10px', position: mobileIsBool ? 'absolute' : 'none', top: '0', left: '0' }}>{v.options[0]?.title}</span>
                          <div style={{ marginTop: mobileIsBool ? '2rem' : 0, border: '0.5px solid #ddd', padding: '5px', width: '100%' }}>
                            <div style={{ backgroundImage: 'linear-gradient(to right,#fff, #09ceff)', display: 'flex', alignItems: 'center', }}>
                              {
                                //
                                v.options?.map((a, b) => {
                                  return <><Popover content={(<div>{a.title}</div>)} >
                                    <button className={'NPS' + i}
                                      style={{ border: '0', cursor: 'pointer', width: 100 / v.options.length + '%', background: "#fff", padding: '10px', borderRight: mobileIsBool ? 0 : '5px solid #fff' }}
                                      // onClick={e => { NPSfun(b, v, e, i) }}
                                      onTouchStart={e => { iPhoneIsBool ? NPSfun(b, v, e, i) : console.log(e) }}
                                      // onMouseOver={e => { mouseover(v, b, i, e, a) }}
                                      onMouseOut={e => { mouseout(v, i, a, b, e) }}
                                    >{a.score}</button>
                                  </Popover>
                                  </>
                                })
                                //
                              }
                            </div>
                          </div>
                          <span style={{ margin: '10px', position: mobileIsBool ? 'absolute' : 'none', top: '0', right: '0' }}>{v.options[v.options.length - 1]?.title}</span>
                        </div>
                        : null
                    }
                    {//评分单选
                      v.type == 53 ?
                        <Radio.Group defaultValue={v.resultMap != null ? v.resultMap[0]?.checked : null}>
                          {
                            v.options?.map((a, b) => {
                              return <div>
                                <Radio style={{ display: 'block', marginLeft: '10px', display: 'flex', alignItems: 'center', fontSize: '15px', height: '40px' }} value={a.idx}>{a.title}(分数:{a.score})
                                  {
                                    a.isNeedGap == 1 && v.resultMap?.filter(v => v.checked == a.idx).length > 0 ?
                                      <>
                                        {
                                          a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                        }
                                        <Input
                                        disabled={true}
                                          style={{ width: '200px', height: iPhoneIsBool ? '35px' : '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}
                                          value={v.resultMap?.filter(v => { return v.checked == a.idx })[0]?.value}
                                          style={{ width: '100px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }} ></Input>
                                      </>
                                      : null
                                  }
                                </Radio>
                                <p style={{ paddingLeft: '32px', color: '#ccc', fontSize: '15px' }}>{a.remark}</p>
                              </div>
                            })
                          }
                        </Radio.Group>
                        : null
                    }
                    {//评分多选
                      //
                      v.type == 54 ?
                        v.options?.map((a, b) => {
                          return <div>
                            <Checkbox disabled={!a.select && v.options.filter(v => { return v?.select }).length == v.rateMax && v.rateMax != null} style={{ flexDirection: 'column' }}
                              style={{ display: 'block', marginLeft: '10px', display: 'flex', alignItems: 'center', fontSize: '15px', height: '40px' }} value={a.idx}
                              disabled={!a.select && v?.resultMap?.length == v.rateMax && v.rateMax != null && v?.resultMap?.filter(v => { return v.checked == a.idx })?.length == 0}
                              checked={v.resultMap?.filter((v, i) => { return v.checked == a.idx }).length > 0}
                              >{a.title}(分数:{a.score})
                              {
                                a.isNeedGap == 1 ?
                                  <>
                                    {
                                      a.isMust == 1 ? <span style={{ color: 'red' }}>*</span> : <span style={{ display: 'inline-block', width: '10px' }}></span>
                                    }
                                    <Input
                                      disabled={true}
                                      style={{ width: '200px', height: iPhoneIsBool ? '35px' : '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}
                                      value={v.resultMap?.filter(v => { return v.checked == a.idx })[0]?.value}
                                      style={{ width: '200px', height: '20px', border: '0', borderBottom: '1px solid #000', alignItems: 'center' }}></Input>
                                  </>
                                  : null
                              }
                            </Checkbox>
                            <p style={{ paddingLeft: '30px', color: '#ccc' }}>{a.remark}</p>
                          </div>
                        })
                        : null
                    }
                    {//评分题
                      v.type == 56 ?
                        <div>
                          <span style={{ margin: '10px 0' }}>{v.options[0]?.title}</span>
                          {
                            v.options?.map((a, b) => {
                              return <StarFilled
                                style={{ color: '#ddd', fontSize: '20px', cursor: 'pointer', }}
                                className={"xing" + i}
                                // onClick={e => { NPSfun(b, v, a.score, i) }}
                                // onMouseOver={e => { mouseover(v, b, i, a) }}
                                onMouseOut={e => { mouseout(v, i, a, b, e) }}
                              />
                            })
                          }
                          <span style={{ margin: '10px 0' }}>{v.options[v.options.length - 1]?.title}</span>
                        </div>
                        : null
                    }
                    {//选择日期
                      v.type == 64 ?
                        <div>
                          <DatePicker
                            defaultValue={v.resultMap != null ? moment(v.resultMap[0]?.checked) : null} />
                        </div>
                        : null
                    }
                    {//选择地区
                      v.type == 65 ?
                        <div>
                          <Cascader options={options} placeholder="Please select" />
                        </div>
                        : null
                    }
                  </Col>
                </Row>
                : null
            })
            //
          }
        </div>
        {//
        }
        <div style={{ width: '100%', justifyContent: 'center', padding: '10px', boxShadow: '0px 10px 20px #111' }}>
          <Row>
            <Col span='1'></Col>
            <Col span='10'><Button style={{ width: '100%', fontSize: '15px' }} onClick={() => onChange(true)}>上一页</Button></Col>
            <Col span='2'></Col>
            <Col span='10'><Button style={{ width: '100%', fontSize: '15px' }} onClick={() => onChange(false)} type="primary">下一页</Button></Col>
            <Col span='1'></Col>
          </Row>
          <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>{pageNumber}/{getdata.length}</p>
        </div>
      </div>
    </div>
  )
}

export default App