import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'


function App(props) {
  const { push } = useHistory()
  const { projectid } = useRouteMatch().params


  const [columns, setColumns] = useState([
      {
        title: '审核员',
        dataIndex: 'audit2byName',
        sorter: (a, b) => a.audit2byName?.localeCompare(b.audit2byName),
        key: 'audit2byName',
        render:(text,record)=>{
          return <Button type="link" onClick={e=>{
            sessionStorage.setItem('projectname',text)
            push('/jh/'+projectid+'/data/firstusertask/'+record.audit2ById)
          }}>{text}</Button>
        }
      },
      {
        title: '二审已分配',
        dataIndex: 'assignedCount',
        sorter: (a, b) => a.assignedCount-b.assignedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '二审已完成',
        dataIndex: 'audit2PassedCount',
        sorter: (a, b) => a.audit2PassedCount-b.audit2PassedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '待二审',
        dataIndex: 'toAudit2Count',
        sorter: (a, b) => a.toAudit2Count-b.toAudit2Count,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '扫DSR已分配',
        dataIndex: 'dsrAssignedCount',
        sorter: (a, b) => a.dsrAssignedCount-b.dsrAssignedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '扫DSR审核已完成',
        dataIndex: 'toadyAudit2PassedCount',
        sorter: (a, b) => a.toadyAudit2PassedCount-b.toadyAudit2PassedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '扫DSR待审核',
        dataIndex: 'toDsrAuditCount',
        sorter: (a, b) => a.toDsrAuditCount-b.toDsrAuditCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '今日增量',
        dataIndex: 'toadyAddAudit2PassedCount',
        sorter: (a, b) => a.toadyAddAudit2PassedCount-b.toadyAddAudit2PassedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '共产生退回店数量',
        dataIndex: 'refusedCount',
        sorter: (a, b) => a.refusedCount-b.refusedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '待确认退回店数量',
        dataIndex: 'confirmRefusedCount',
        sorter: (a, b) => a.confirmRefusedCount-b.confirmRefusedCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
      {
        title: '转派门店数量',
        dataIndex: 'forwardCount',
        sorter: (a, b) => a.forwardCount-b.forwardCount,
        render:(text,record)=>{
          return text?text:'0'
        }
      },
    ])

  const [dataSource, setDataSource] = useState([])

  const getdata = () =>{
    axios.get('/api/jhStatistic/auditStatisticItem',{
      params:{
        projectId:projectid
      }
    })
    .then(res=>{
      res.data.reverse()
      console.log(res.data)
      setDataSource(res.data)
      banma()
    })
  }

  const Export = () =>{
    axios.get('/api/jhStatistic/auditStatisticItemExport',{
      params:{
        projectId:projectid
      }
    })
    .then(res=>{
      console.log(res)
    })
  }

  const banma = ()=>{
    setTimeout(()=>{
      let domarr = document.getElementsByTagName('tr')
      domarr = [...domarr]
      domarr.forEach((v,i) => {
        if(i%2== 0){
          v.style.background='#f1f1f1'
        }else{
          v.style.background='#fff'
        }
      });
    },200)
  }

  useEffect(() => {
    getdata()
  }, [])

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            {
              //<Button onClick={Export}>导出列表</Button>
            }
          </Col>
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true }} onChange={e=>{banma()}}/>

      </div>
    </div>
  )
}

export default App
