import React, { useEffect, useRef, useState } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';

function App(props) {
    const chartRef = useRef(null)
    const getData = callback => {
        // axios.get()...
    }
    useEffect(() => {
        let data = []
        if (props.curTab == 'day') {
            data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
        } else if (props.curTab == 'week') {
            data = [11, 12, 13, 14, 15, 16, 17, 18, 19, 110, 111]
        } else if (props.curTab == 'month') {
            data = [21, 22, 23, 24, 25, 26, 27, 28, 29, 210, 211]
        } else if (props.curTab == 'year') {
            data = [31, 32, 33, 34, 35, 36, 37, 38, 39, 310, 311]
        }
        //初始化

        const myChart = echarts.init(document.getElementById('main4'))
        //窗口大小改变时，触发chart实例的resize事件
        //绘制图表
        chartRef.current = myChart
        myChart.setOption({
            title: {
                text: '项目完成量'
            },
            xAxis: {
                type: 'category',
                data: ['张三', '李四', '王五', '六六', '陈云', '王宇', '白玉', '张帆', '时间', '地点', '人物']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data,
                type: 'bar',
                color: 'rgba(62, 97, 155, 1)'
            }],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
        })

    }, [props.curTab])

    const resize = e => {
        if (chartRef.current)
            chartRef.current.resize()
    }
    useEffect(() => {
        setTimeout(() => {
            resize()
        }, 0);
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    return (
        <>
            <div id="main4" style={{ width: '100%', height: '300px' }}></div>
        </>
    );
}

export default App;