import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Tabs, message, Modal, InputNumber, Progress } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'
import PopConfirmOnly from "@/utils/PopConfirmOnly";
import echarts from 'echarts'

const { Option } = Select;
const { RangePicker } = DatePicker;
const { data } = require('./data/issue')
const { TabPane } = Tabs;

function App(props) {
    const { push } = useHistory()
    const templateId = useParams().templateId
    const projectid = useParams().projectid

    const [refresh, setRefresh] = useState(false);

    useEffect(() => { //强制渲染页面
        refresh && setTimeout(() => setRefresh(false))
    }, [refresh])

    const Arrays = []
    let typedata = data
    typedata.forEach(v => {
        v.children.forEach(v => {
            Arrays.push(v)
        })
    })
    typedata = Arrays


    const [datalist, setDatalist] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const bingtu = (i, v) => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementsByClassName('main')[i]);
        // 指定图表的配置项和数据

        const array = []
        v.question.options.forEach(a => {
            array.push({ name: a.title, value: v.idxCount.filter(v => v.idx == a.idx)[0].count })
        })

        var option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                top: '5%',
                left: '50px'
            },
            series: [
                {
                    type: 'pie',
                    radius: '50%',
                    data: array,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
    }

    const huantu = (i, v) => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementsByClassName('main')[i]);
        // 指定图表的配置项和数据
        const array = []
        v.question.options.forEach(a => {
            array.push({ name: a.title, value: v.idxCount.filter(v => v.idx == a.idx)[0].count })
        })
        var option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: '50px'
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '40',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: array
                }
            ]
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
    }


    const zhutu = (i, v) => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementsByClassName('main')[i]);
        // 指定图表的配置项和数据
        const arrayxAxis = []
        const arraydata = []
        v.question.options.forEach(a => {
            arrayxAxis.push(a.title)
            arraydata.push(v.idxCount.filter(v => v.idx == a.idx)[0].count)
        })
        var option = {
            xAxis: {
                type: 'category',
                data: arrayxAxis
            },
            yAxis: {
                type: 'value'
            },
            legend: {
                top: '5%',
                left: '50px'
            },
            series: [{
                data: arraydata,
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }]
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
    }


    const xiantu = (i, v) => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementsByClassName('main')[i]);
        // 指定图表的配置项和数据
        const arrayxAxis = []
        const arraydata = []
        v.question.options.forEach(a => {
            arrayxAxis.push(a.title)
            arraydata.push(v.idxCount.filter(v => v.idx == a.idx)[0].count)
        })
        var option = {
            xAxis: {
                type: 'category',
                data: arrayxAxis
            },
            legend: {
                top: '5%',
                left: '50px'
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: arraydata,
                type: 'line'
            }]
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
    }

    const [tempData, setTempData] = useState(null)

    const [answerAll, setAnswerAll] = useState([])

    const getData = () => {
        axios.get('/api/question/template/' + templateId)
            .then(res => {
                setTempData(res.data)
            })
        axios.get('/api/question/answer/all', {
            params: {
                queryCondition: 'EQ:questionTemplateId=' + templateId,
                pageSize: '100000'
            }
        })
            .then(res => {
                const arr = []
                const crr = []
                res.data.data.forEach(v => {
                    if (v.advisor != null) {
                        const brr = arr.filter(a => {
                            return a.advisor == v.advisor
                        })
                        if (brr.length != 0) {
                            brr[0].amount += 1
                        } else {
                            arr.push({ advisor: v.advisor, amount: 1 })
                        }
                        if (arr.length == 0) {
                            arr.push({ advisor: v.advisor, amount: 1 })
                        }
                    }
                })

                arr.sort((a, b) => a.advisor?.localeCompare(b.advisor, 'zh-CN'))
                function advisor(v, crr, num) {
                    let count = true
                    for (let a of crr) {
                        if (a.advisor == v.advisor) {
                            a.amount = v.amount
                            count = false
                            return
                        }
                        if (a.advisor == v.advisor.slice(0, a.advisor.length)) {
                            count = false
                            return advisor(v, a.options, ++num)
                        }
                    }
                    if (count) {
                        let str = []
                        for (let i = 0; i <= num; i++) {
                            if (v.advisor.includes('-')) {
                                str.push(v.advisor.split('-')[i])
                            } else {
                                str = [v.advisor]
                            }
                        }
                        crr.push({ advisor: str.join('-'), amount: 0, options: [] })
                        return advisor(v, crr, num)
                    }
                }

                arr.forEach(v => {
                    advisor(v, crr, 0)
                })
                setAnswerAll(crr)
            })
        axios.get('/api/question/template/summary/' + templateId)
            .then(res => {
                let arr = res.data.sort((a, b) => {
                    return a.question.idx - b.question.idx
                })
                arr = arr.filter((v, i) => {
                    const type = v.question.type
                    if (
                        (type != '31' && type != '32') &&
                        (type == 1 ||
                            type == 2 ||
                            type == 3 ||
                            type == 4 ||
                            type == 21 ||
                            type == 22 ||
                            type == 23 ||
                            type == 53 ||
                            type == 54 ||
                            type == 61 ||
                            type == 62 ||
                            type == 63)
                    ) {
                        return true
                    } else {
                        return false
                    }
                })
                arr.forEach((v, i) => {
                    v.biaogeBool = false
                    if (v.question.type == 1 ||
                        v.question.type == 2 ||
                        v.question.type == 3 ||
                        v.question.type == 4 ||
                        v.question.type == 53 ||
                        v.question.type == 54 ||
                        v.question.type == 62) {
                        v.biaogeBool = true
                    }
                    v.bingtuBool = false
                    v.huantuBool = false
                    v.zhutuBool = false
                    v.xiantuBool = false
                    v.question.no = i + 1
                })
                setDatalist(arr)
            })
    }

    const columns = [
        {
            title: '选项名',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
        },
        {
            title: '数量',
            dataIndex: 'count',
            key: 'count',
            width: '20%',
            align: 'center',
            onCell: () => {
                return {
                    style: {
                        background: '#fafafa'
                    }
                }
            }
        },
        {
            title: '比例',
            dataIndex: 'sum',
            key: 'sum',
            width: '30%',
            align: 'center',
            render: (text, record, index) => {
                return <Progress style={{ width: '90%' }} percent={((record.count / text) * 100).toFixed(2)} />
            }
        },
    ]

    const columnsInput = [
        {
            title: '填空',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
            render: (text, record, index) => {
                return text ? text : '填空' + (index + 1)
            }
        },
        {
            title: '详情',
            dataIndex: 'count',
            key: 'count',
            width: '20%',
            align: 'center',
            onCell: () => {
                return {
                    style: {
                        background: '#fafafa'
                    }
                }
            },
            render: (text, record, index) => {
                return <Button type="link" onClick={e => { showModal(record, index) }}>查看</Button>
            }
        },
    ]

    const columnsInputModal = [
        {
            title: '序号',
            dataIndex: 'title',
            key: 'title',
            width: '5%',
            render: (text, record, index) => {
                return index + 1
            }
        },
        {
            title: '提交答案时间',
            dataIndex: 'submitTime',
            key: 'submitTime',
            width: '20%',
            render: (text, record, index) => {
                return text
            },
            onCell: () => {
                return {
                    style: {
                        background: '#fafafa'
                    }
                }
            },
        },
        {
            title: '来源渠道',
            dataIndex: 'clientName',
            key: 'clientName',
            width: '20%',
            render: (text, record, index) => {
                return text
            }
        },
        {
            title: '答案文本',
            dataIndex: 'value',
            key: 'value',
            width: '55%',
            align: 'center',
            onCell: () => {
                return {
                    style: {
                        background: '#fafafa'
                    }
                }
            },
            render: (text, record, index) => {
                return text
            }
        },
        // {
        //     title: '详情',
        //     dataIndex: 'value',
        //     key: 'value',
        //     width: '10%',
        //     align: 'center',
        //     render: (text, record, index) => {
        //         return <Button type="link" onClick={e => {  }}>查看</Button>
        //     }
        // },
    ]


    const reset = (v) => {
        v.bingtuBool = false
        v.huantuBool = false
        v.zhutuBool = false
        v.xiantuBool = false
    }

    function shuju(v) {
        let count = 0
        if (!v || !v.idxCount) {
            return []
        }
        v.idxCount.forEach(v => {
            count += v.count
        });
        let arr = []
        v.question.options.forEach(a => {
            arr.push({ title: a.title, count: v.idxCount.filter(v => v.idx == a.idx)[0].count, sum: count })
        });
        return arr
    }

    const [isModalVisibleInput, setIsModalVisibleInput] = useState(false);//填空详情

    const [thetitleId, setThetitleId] = useState(null)
    const [indexInput, setIndexInput] = useState(null)
    const [wenbenInput, setWenbenInput] = useState(null)

    const getDataInput = () => {
        axios.get('/api/question/template/answer/detail', {
            params: {
                questionId: thetitleId,
                index: indexInput,
                value: wenbenInput,
                pageSize: 10000
            }
        })
            .then(res => {
                setInputData(res.data.data)
            })
    }

    const [inputData, setInputData] = useState([])

    const showModal = (v, i) => {
        setIsModalVisibleInput(true);
        setThetitleId(v.thetitleId)
        setIndexInput(v.idx)
        axios.get('/api/question/template/answer/detail', {
            params: {
                questionId: v.thetitleId,
                index: v.idx,
                pageSize: 10000
            }
        })
            .then(res => {
                setInputData(res.data.data)
            })
    };

    const handleOk = () => {
        setIsModalVisibleInput(false);
        setThetitleId(null)
        setIndexInput(null)
        setWenbenInput(null)
    };

    const handleCancel = () => {
        setIsModalVisibleInput(false);
        setThetitleId(null)
        setIndexInput(null)
        setWenbenInput(null)
    };

    const InputShuju = (v) => {
        const arr = []
        if (v.question.options?.length != 0) {
            v.question.options.forEach(a => {
                arr.push({ ...a, thetitleId: v.question.id })
            })
        } else {
            arr.push({})
        }
        return arr
    }

    const exportData = () => {
        axios.get('/api/question/template/export/report', {
            params: {
                questionTempId: templateId
            },
            responseType: 'blob'
        })
            .then(res => {
                let url = window.URL.createObjectURL(new Blob([res]));
                let link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                link.setAttribute("download", tempData.title + '' + moment().format("YYYY-MM-DD HH:mm:ss") + ".xlsx");
                document.body.appendChild(link);
                link.click();
            })
    }

    let [tongjiArr, setTongjiArr] = useState([{}])

    function kong(answerAll, is, ia) {
        let arr = []
        let count = true
        if (is == 0)
            answerAll.forEach((v) => {
                arr.push(v)
            })
        else
            for(let v of answerAll){
                if (v.advisor == tongjiArr[ia]?.advisor) {
                    kong(v.options, is - 1, ia + 1).forEach(v => {
                        arr.push(v)
                    })
                    count = false
                }
            }
        if (count) {
            for(let v of answerAll){
                kong(v.options, is - 1, ia + 1).forEach(v => {
                    arr.push(v)
                })
            }
        }
        return arr
    }

    function tongjiSelect(v, i, is) {
        if (tongjiArr.length - 1 == is) {
            if (i == 0) {
                if (is == 0) {
                    return answerAll.map((a, i) => {
                        return <Option value={a.advisor}>{a.advisor}</Option>
                    })
                }
                else if (tongjiArr.length != 1) {
                    if (tongjiArr[tongjiArr.length - 2].advisor == -2) {
                        return kong(answerAll, is, 0).map((a, i) => {
                            return <Option disabled value={a.advisor}>{a.advisor}</Option>
                        })
                    } else if (JSON.stringify(tongjiArr[tongjiArr.length - 2].options) != '{}') {
                        return tongjiArr[tongjiArr.length - 2].options.map((a, i) => {
                            return <Option value={a.advisor}>{a.advisor}</Option>
                        })
                    }
                }
            }
            return tongjiSelect(v, i - 1, is)
        } else {
            if (JSON.stringify(v) == '{}')
                return null
            else
                if (is >= 1) {
                    if(tongjiArr.filter((v,i)=>{return i<is&&v.advisor == -2}).length<1)
                    return kong(answerAll, is, 0).map((a, i) => {
                        return <Option disabled value={a.advisor}>{a.advisor}</Option>
                    })
                } else {
                    return answerAll.map((a, i) => {
                        return <Option value={a.advisor}>{a.advisor}</Option>
                    })
                }
        }
    }

    function tongjionChange(e, i, v) {
        let arr = JSON.parse(JSON.stringify(tongjiArr))
        tongjiArr.forEach((v, is) => {
            if (i < is) {
                arr.pop()
            }
        })
        arr[i].advisor = e == -1 ? -1 : -2
        tongjiArr = arr
        let count = true
        function chuli(v) {
            for (let a of v) {
                if (a.advisor == e) {
                    tongjiArr[i] = JSON.parse(JSON.stringify(a))
                    count = false
                    if (a.options.length != 0) {
                        setTongjiArr([...tongjiArr, {}])
                        setRefresh(true)
                    } else {
                        setTongjiArr([...tongjiArr])
                        setRefresh(true)
                    }
                    return
                }
            }
            for (let a of v) {
                chuli(a.options, i)
            }
        }
        chuli(answerAll)
        if (count) {
            if (tongjiArr[i].advisor == -2) {
                setTongjiArr([...tongjiArr, {}])
            } else {
                setTongjiArr([...tongjiArr])
            }
        }
        setRefresh(true)
    }

    const tongji = [
        {
            title: '编号',
            dataIndex: 'advisor',
            key: 'advisor',
            width: '40%',
            align: 'center',
        },
        {
            title: '个人问卷数',
            dataIndex: 'amount',
            key: 'amount',
            width: '40%',
            align: 'center',
        },
        {
            title: '总数',
            dataIndex: 'advisor',
            key: 'advisor',
            width: '40%',
            align: 'center',
            render: (text, record, index) => {
                return sumArr(record)
            }
        },
    ]

    function sumArr(record) {
        let nums = record.amount
        if (record.options.length != 0) {
            record.options.forEach(v => {
                nums += v.amount
                if (v.options.length != 0) {
                    nums += sumArr(v)
                }
            })
        }
        return nums
    }

    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        tongjiArr.forEach((v, i) => {
            if (JSON.stringify(v) != '{}') {
                if (v.advisor == -2) {
                    setDataSource([])
                } else if (v.advisor == -1) {
                    if (tongjiArr.length - 2 >= 0) {
                        setDataSource(kong(answerAll, i, 0))
                    } else {
                        setDataSource(answerAll)
                    }
                } else if (tongjiArr.length != 1 && v.advisor != -1 && v.advisor != -2) {
                    if (JSON.stringify([v]) != '{}') {
                        setDataSource([v])
                    }
                }
            }
        })

    }, [tongjiArr])

    return (
        <div style={{ padding: '30px' }}>
            <Modal width='80%' title="填空答题详情" visible={isModalVisibleInput} onOk={handleOk} onCancel={handleCancel} >
                <Row>
                    <Col span='24' style={{ marginBottom: '10px' }}>
                        <Row>
                            <Col span={8} >
                                <Input style={{ width: '240px', marginLeft: '10px' }} value={wenbenInput} placeholder="请输入关键字搜索" onChange={e => setWenbenInput(e.target.value)} />
                            </Col>
                            <Col span='12'></Col>
                            <Col span='4'>
                                <Button type='primary' onClick={getDataInput}>搜索</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span='24'>
                        <Table size='small' style={{ width: '100%' }} dataSource={inputData} columns={columnsInputModal} ></Table>
                    </Col>
                </Row>
            </Modal>
            <Row justify='end' style={{ padding: '10px 110px' }}>
                <Col>
                    <Button onClick={e => { exportData() }}>导出数据</Button>
                </Col>
            </Row>
            <Row style={{ padding: '10px 110px' }}>
                <Col span='24' style={{ margin: '10px 10px' }}> 筛选统计 </Col>
                {
                    tongjiArr.map((v, i) => {
                        return <Col span='6'>
                            <Select value={v.advisor} style={{ width: 120 }} onChange={e => { tongjionChange(e, i, v) }}>
                                <Option value={-1}>全选</Option>
                                <Option value={-2}>空</Option>
                                {
                                    tongjiSelect(v, i, i)
                                }
                            </Select>
                        </Col>
                    })
                }
                <Table dataSource={dataSource} columns={tongji} size='small' style={{ width: '100%' }}  ></Table>
            </Row>
            {
                datalist.map((v, i) => {
                    return (<>
                        <Row className='wenjuan' style={{ padding: '10px 100px', position: 'relative', margin: '10px 10px', border: v.isBool ? '0.5px red solid' : 'none', boxSizing: 'border-box' }}>
                            <Col span='24' style={{ background: '#fff', display: v.question.type == '32' ? 'block' : 'flex', alignItems: 'center' }}>
                                { //显示隐藏 必填星号 和 序号
                                    v.type != '31' &&
                                        v.type != '32' ? <>
                                        {
                                            v.question.idxFlag != 1 ?
                                                <b style={{ fontWeight: '600' }}>{v.question.no + '.'}</b>
                                                : null
                                        }
                                    </>//
                                        : null
                                }
                                {
                                    v.question.type == '32' ? <p style={{ width: '100%', textAlign: 'center' }}></p> : null
                                }
                                { //显示隐藏 题目名称
                                }
                                <span className='title' style={{ lineHeight: '50px' }} dangerouslySetInnerHTML={{ __html: v.question.title }} ></span>
                                { // 后面的小标题 标记是什么题
                                }
                                <span style={{ marginLeft: "10px", color: "#aaa", fontSize: '5px', border: '0.5px #999 solid', padding: '0 5px', borderRadius: '5px' }}>{typedata.filter(a => { return a.code == v.question.type })[0]?.title}</span>
                            </Col>
                            <Col span='24' style={{ background: '#fff', }}>
                                {
                                    v.biaogeBool ?
                                        <Table pagination={false} size='small' style={{ width: '100%' }} dataSource={shuju(v)} columns={columns} ></Table>
                                        : null
                                }
                                {
                                    v.question.type == 22 ||
                                        v.question.type == 23 ?
                                        <Table pagination={false} size='small' style={{ width: '100%' }} dataSource={InputShuju(v)} columns={columnsInput} ></Table>
                                        : null
                                }
                                {
                                    v.question.type == 21 ||
                                        v.question.type == 61 ||
                                        v.question.type == 63 ?
                                        <Button onClick={() => { showModal({ ...v.question.options[0], thetitleId: v.question.id }) }}>查看详情信息</Button>
                                        : null
                                }
                            </Col>
                            <Col span='24' style={{ background: '#fff', }}>
                                {
                                    v.question.type == 1 ||
                                        v.question.type == 2 ||
                                        v.question.type == 3 ||
                                        v.question.type == 4 ||
                                        v.question.type == 53 ||
                                        v.question.type == 54 ||
                                        v.question.type == 62 ?
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', }}>
                                            <Button type={v.biaogeBool ? 'primary' : ''} onClick={e => { v.biaogeBool = !v.biaogeBool; setRefresh(true) }} style={{ marginLeft: '10px' }}>表格</Button>
                                            <Button type={v.bingtuBool ? 'primary' : ''} onClick={e => { if (!v.bingtuBool) { reset(v) }; v.bingtuBool = !v.bingtuBool; setRefresh(true); setTimeout(() => { if (v.bingtuBool) { bingtu(i, v) } }, 500) }} style={{ marginLeft: '10px' }}>饼状图</Button>
                                            <Button type={v.huantuBool ? 'primary' : ''} onClick={e => { if (!v.huantuBool) { reset(v) }; v.huantuBool = !v.huantuBool; setRefresh(true); setTimeout(() => { if (v.huantuBool) { huantu(i, v) } }, 500) }} style={{ marginLeft: '10px' }}>环形图</Button>
                                            <Button type={v.zhutuBool ? 'primary' : ''} onClick={e => { if (!v.zhutuBool) { reset(v) }; v.zhutuBool = !v.zhutuBool; setRefresh(true); setTimeout(() => { if (v.zhutuBool) { zhutu(i, v) } }, 500) }} style={{ marginLeft: '10px' }}>柱状图</Button>
                                            <Button type={v.xiantuBool ? 'primary' : ''} onClick={e => { if (!v.xiantuBool) { reset(v) }; v.xiantuBool = !v.xiantuBool; setRefresh(true); setTimeout(() => { if (v.xiantuBool) { xiantu(i, v) } }, 500) }} style={{ marginLeft: '10px' }}>条形图</Button>
                                        </div>
                                        : null
                                }
                            </Col>
                            <Col span='24' style={{ background: '#fff', opacity: v.bingtuBool || v.huantuBool || v.zhutuBool || v.xiantuBool ? '1' : '0' }}>
                                <div className="main" style={{ width: '100%', height: v.bingtuBool || v.huantuBool || v.zhutuBool || v.xiantuBool ? '500px' : 0 }}></div>
                            </Col>
                        </Row>
                    </>)
                })
            }
        </div>
    )
}

export default App
