
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Form, Modal, Upload, Radio } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import file from '@/assets/jhmddr.xlsx'
import { stringToNumber, filterSet } from '@/utils/common'
import PopConfirmOnly from '@/utils/PopConfirmOnly'
import * as XLSX from 'xlsx'
import muban from '@/assets/sfdr.xlsx'

import locale from 'antd/lib/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

function App(props) {

  const { push } = useHistory()
  const [columns, setColumns] = useState([
    {
      title: 'ID',
      dataIndex: 'id',
      render: text => text ? text : '-'
    },
    {
      title: '产品组ID',
      dataIndex: 'productGroupId',
      render: text => text ? text : '-'
    },
    {
      title: '产品组名称',
      dataIndex: 'productGroupName',
      render: text => text ? text : '-'
    },
    {
      title: '区域ID',
      dataIndex: 'regionId',
      render: text => text ? text : '-'
    },
    {
      title: '区域名称（省份）',
      dataIndex: 'regionName',
      render: text => text ? text : '-'
    },
    {
      title: '年份',
      dataIndex: 'year',
      render: text => text ? text : '-'
    },
    {
      title: '月份',
      dataIndex: 'month',
      render: text => text ? text : '-'
    },
    {
      title: '门店等级',
      dataIndex: 'shopLevelName',
      render: text => text ? text : '-'
    },
    {
      title: '总标准数量',
      dataIndex: 'totalStandardQty',
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => {push('/home/set/standardItem/' + record.id +'-'+record.productGroupId)}}>查看算分明细</Button>
          <Divider type='vertical' />
          <Button size='small' type='link' onClick={e => {upData(record)}}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  //

  const upData = r =>{
    setobj(Object.assign({},r))
    setaddupdatabool(true)
    setIsModalVisible(true)
  }

  const [dataSource,setdataSource] = useState([])//数据
  const [labelValues,setlabelValues] = useState([])//字典 门店等级

  const [region,setregion] = useState([])//算分区域数据
  const [Group,setGroup] = useState([])//产品组数据

  const [addupdatabool,setaddupdatabool] = useState(false)

  const getdata = e =>{
    let queryCondition = ''
    if(regionName&&e==undefined)queryCondition+="LIKE:regionName="+regionName

    axios.get('/api/core/standard/list',{
      params:{
        page,
        pageSize,
        queryCondition
      }
    })
    .then(res=>{
      setTotal(res.data.totalSize)
      setdataSource(res.data.data)
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getdata()
  },[pageSize,page])


  const [productGroup,setproductGroup] = useState([])

  useEffect(()=>{
    getdata()
    axios.get('/api/sys/dict/shopLevel')
    .then(res=>{
      setlabelValues(res.data.labelValues)
    })
    axios.get('/api/core/region/list',{
      params:{
				pageSize: 1000000,
      }
    })
    .then(res=>{
      setregion(res.data.data)
    })
    axios.get('/api/core/productGroup/list',{
      params:{
				pageSize: 1000000,
      }
    })
    .then(res=>{
      setGroup(res.data.data)
    })
    axios.get('/api/core/productGroup/list')
    .then(res=>{
      setproductGroup(res.data.data)
    })
  },[])
  
  //删除
  const handleDelete = e => {
    axios.delete('/api/core/standard/' + e).then(res => {
      message.success()
      getdata()
    })
  }

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])//多选
  const handleSelectChange = e => {
    console.log(e)
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    if(!obj.year && !obj.month && !obj.regionName && !obj.shopLevel && !obj.productGroupId){message.error('请检查未填写的问题');return}
    if(addupdatabool){
      axios.put('/api/core/standard',obj)
      .then(res=>{
        message.success('修改成功');
        setobj({})
        setIsModalVisible(false);
        getdata()
      })
    }else{
      axios.post('/api/core/standard',obj)
      .then(res=>{
        message.success('添加成功');
        setobj({})
        setIsModalVisible(false);
        getdata()
      })
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setobj({})
  };

  const [obj,setobj] = useState({})

  const [regionName,setregionName] = useState('')

  const dataobject = (name,value)=>{
    obj[name]=value
    if(name == 'shopLevel') obj.shopLevelName = labelValues[value]
    if(name == 'regionId')obj.regionName = region.filter(v=>{return v.id == value})[0].regionName
    if(name == 'productGroupId')obj.productGroupName = Group.filter(v=>{return v.id == value})[0].productGroupName

    setobj(Object.assign({},obj))
  }

  const remove = e =>{//重置
    setregionName('')
    getdata(1)
  }

  const onImportExcel = file => {  //导入
    let data = [];// 存储获取到的数据
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);  //二进制
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以二进制流方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            // break; // 如果只取第一张表，就取消注释这行
          }
        }
        const datalist = []
        const labelValueslist = Object.keys(labelValues).map(v=>{
          return {index:v,value:labelValues[v]}
        })
        
        let productGroupName = '' //验证产品组是否重复

        let productGroupdata = []  //产品数据

        data.forEach((v,i)=>{
          
          var obj = {}
          obj.children=[]

          var strP=/^\d+(\.\d+)?$/

          Object.values(v).forEach((v,i)=>{
            if((v+'').indexOf('/')!=-1){
              let jianarr = []
              let chanpin = (v+'').split('/')[0]
              const standardQty = (v+'').split('/')[1]

              function skkl(params) {//验证 ! (减)
                let count = 0
                if(params.indexOf('!')!=-1){ 
                  for(let i=params.indexOf('!');i<=params.length-1;i++){
                    count++
                    console.log(i,params.length-1)
                    if(!strP.test(params[i+1])||i==params.length-1){
                      let cll = params.split('')
                      let str = cll.splice(params.indexOf('!'),count).join('')
                      jianarr.push(str)
                      return skkl(cll.join(''))
                    }
                  }
                  console.log(params,params.indexOf('!'),count)
                }else{
                  return params
                }
              }
              if(chanpin.indexOf('!')!=-1){
                chanpin=skkl(chanpin)
              }
              console.log(jianarr)

              if(chanpin.split('+')!=-1){ // 验证加
                chanpin=chanpin.split('+')
              }else{
                chanpin=[chanpin]
              }
              const arr = []
              chanpin.forEach(v=>{
                if(v.indexOf('-')!=-1){
                  for(var i = Number(v.split('-')[0]);i<=v.split('-')[1];i++){
                    arr.push(i)
                  }
                }else{
                  arr.push(Number(v))
                }
              })

              let jianbrr = []
              jianarr.forEach(v=>{
                jianbrr.push(v.split('!')[1])
              })
              jianbrr.forEach(v => {
                console.log(arr.indexOf(Number(v)),v,arr)
                arr.splice(arr.indexOf(Number(v)),1)
              });
              // console.log(arr)
              obj.children.push({standardQty,standardItemProducts:arr})
            }
          })
          datalist.push(obj)
          axios.post('/api/core/standard',{
            regionId:region.filter(a=>{return a.regionName == v.算分区域})[0].id,
            regionName:v.算分区域,
            year:v.年份,
            month:v.月份,
            shopLevel:labelValueslist.filter(a=>{return a.value == v.门店等级 })[0].index,
            shopLevelName:v.门店等级,
            productGroupName:v.产品组,
            productGroupId:productGroup.filter(a=>{return a.productGroupName == v.产品组})[0].id,
            totalStandardQty:v.总标准数量,
          })
          .then(res=>{
            const data = res.data
            if(productGroupName != v.产品组){
              axios.get('/api/core/product/list',{
                params:{
                  pageSize: 1000000,
                  productGroupId:productGroup.filter(a=>{return a.productGroupName == v.产品组})[0].id},
                })
              .then(res=>{
                productGroupdata=res.data.data
                chanpin(res.data.data)
                productGroupName=v.产品组
              })
            }else{
              chanpin(productGroupdata)
            }
            function chanpin(productGroupdata) {
              obj.children.forEach(v=>{
                const body = {}
                body.standardId=data.id
                body.standardQty=v.standardQty
                body.standardItemProducts=[]
                v.standardItemProducts.forEach((b,i)=>{
                  const stan = productGroupdata.filter(c=>{return c.seq == b})[0]
                  if( i==0 ){
                    const xilie = productGroupdata.filter(c=>{return c.productCode == stan?.parentCode})[0]
                    const pinpai = productGroupdata.filter(c=>{return c.productCode == xilie?.parentCode})[0]
                    body.brandId = pinpai.id
                    body.brandName = pinpai.productName
                  }
                  body.standardItemProducts.push({
                    productId:stan.id,
                    barCode:stan.barCode
                  })
                })
                axios.post('/api/core/standardItem',body)
                .then(e=>{
                })
              })
            }
          })
          // obj.regionName=v.算分区域
          // obj.regionId = region.filter(a=>{return a.regionName == v.算分区域})[0].id
          // obj.productGroupName=v.产品组
          // obj.productGroupId = productGroup.filter(a=>{return a.productGroupName == v.产品组})[0].id
          // obj.shopLevelName=v.门店等级
          // obj.shopLevel=labelValueslist.filter(a=>{return a.index == v.门店等级 })[0].index
          // obj.regionCode=v.省份编码
          // obj.year=v.年份
          // obj.month=v.月份
          getdata()
        })
      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        message.error('导入失败,请检查模板');
        return;
      }
    };
  }

  const deleteall = ()=>{
    console.log(selectedRowKeys)
    try{
      selectedRowKeys.forEach(v=>{
        axios.delete('/api/core/standard/' + v)
      })
    }
    finally{
      message.success('删除成功')
      getdata()
      setSelectedRowKeys([])
    }
  }

  const [isAbcdModalBool,setIsAbcdModalBool] = useState(false)
  const [abcdInput,setAbcdInput] = useState(null)

  function isNumber(val) {
    var regPos = /^\d+(\.\d+)?$/;//非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;//负浮点数
    if(regPos.test(val) || regNeg.test(val)) {
        return true;
        }else {
        return false;
        }
    }

  const abcd = () => {
    if(abcdInput>12 || abcdInput<1 || !isNumber(abcdInput)){
      return message.error('请检查输入的内容')
    }
    let queryCondition = ''
    if(regionName)queryCondition+="LIKE:regionName="+regionName
    axios.get('/api/core/standard/list',{
      params:{
        pageSize:100000,
        queryCondition
      }
    })
    .then(res=>{
      res.data.data.forEach(v=>{
        axios.put('/api/core/standard',{...v,month:abcdInput})
      })
      setAbcdInput(null)
      setIsAbcdModalBool(false)
      message.success('修改成功')
      setTimeout(v=>{
        getdata()
      },2000)
    })
    
  }

  return (
    <div style={{ padding: '30px' }}>
      <Modal title={'批量修改算分月份'} 
        visible={isAbcdModalBool} 
        onOk={abcd} 
        onCancel={e=>{setIsAbcdModalBool(false);setAbcdInput(null)}}
        destroyOnClose={true}
      >
        <Row>
          <Col span='6'>全部修改月份</Col>
          <Col span='18'>
            <Input onChange={e=>setAbcdInput(e.target.value)}></Input>
          </Col>
        </Row>
      </Modal>
      <Modal title={addupdatabool?'修改算分标准':"添加算分标准"} 
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <Row>
          <Col span={6} style={{ textAlign: 'right', lineHeight: '30px', marginBottom:'10px', display:'flex',  alignItems:'center' }}><font color='red'>*</font>区域(省份):</Col>
          <Col span={18} style={{marginBottom:'10px', display:'flex',  alignItems:'center' }}>
          <Select style={{ width: '100%' }} onChange={e=>{dataobject('regionId',e)}}>
            {
              region.map(v=>{
                return <Select.Option value={v.id}>{v.regionName}</Select.Option>
              })
            }
          </Select>
          </Col>
          <Col span={6} style={{ textAlign: 'right', lineHeight: '30px', marginBottom:'10px', display:'flex',  alignItems:'center' }}><font color='red'>*</font>产品组:</Col>
          <Col span={18} style={{marginBottom:'10px', display:'flex',  alignItems:'center' }}>
          <Select style={{ width: '100%' }} onChange={e=>{dataobject('productGroupId',e)}}>
            {
              Group.map(v=>{
                return <Select.Option value={v.id}>{v.productGroupName}</Select.Option>
              })
            }
          </Select>
          </Col>
          <Col span={6} style={{ textAlign: 'right', lineHeight: '30px', marginBottom:'10px', display:'flex',  alignItems:'center' }}><font color='red'>*</font>年份/月份:</Col>
          <Col span={18} style={{marginBottom:'10px', display:'flex',  alignItems:'center' }}>
            <DatePicker 
              defaultValue={obj.year&&obj.month?moment((obj.year+'-'+obj.month)):null} 
              onChange={(date, dateString)=>{dataobject('month',dateString.split('-')[1]);dataobject('year',dateString.split('-')[0]) }} 
              mode='month' 
              format={'YYYY-MM'} 
              picker="month"
              placeholder='请选择年/月份' />
          </Col>
          <Col span={6} style={{ textAlign: 'right', lineHeight: '30px', marginBottom:'10px', display:'flex',  alignItems:'center' }}><font color='red'>*</font>门店等级:</Col>
          <Col span={18} style={{marginBottom:'10px', display:'flex',  alignItems:'center' }}>
            <Radio.Group name="radiogroup" value={obj.shopLevel+''} onChange={e=>dataobject('shopLevel',e.target.value)}>
            {
              Object.keys(labelValues).map((i)=>{
                return <Radio value={i}>{labelValues[i]}</Radio>
              })
            }
            </Radio.Group>
          </Col>
          <Col span={6} style={{ textAlign: 'right', lineHeight: '30px', marginBottom:'10px', display:'flex',  alignItems:'center' }}><font color='red'>*</font>总标准数量:</Col>
          <Col span={18} style={{marginBottom:'10px', display:'flex',  alignItems:'center' }}>
            <Input value={obj.totalStandardQty} onChange={e=>{dataobject('totalStandardQty',e.target.value)}}></Input>
          </Col>
        </Row>
      </Modal>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            <span style={{ lineHeight: '30px' }}>区域名称/省份</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请填写信息' value={regionName} onChange={e => { setregionName(e.target.value) }}></Input>
          </Col>
          {/* <Col>
            <span style={{ lineHeight: '30px' }}>创建人员</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} placeholder='全选' onChange={e => { }}>
              {
                peopleOption.map(i => (
                  <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
                ))
              }
            </Select>
          </Col> */}
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <Button style={{ margin: '0 10px 10px' }} type='primary' onClick={()=>{setIsModalVisible(true);setaddupdatabool(false)}}><PlusOutlined />添加算分标准</Button>
              {/* 导入 */}
              <Upload name="excel" action="" listType="text" accept="file" beforeUpload={onImportExcel} showUploadList={false}>
                <Button style={{ margin: '0 10px' }}>导入清单</Button>
              </Upload>
              <Button style={{ margin: '0 10px' }} 
                href={muban}
              >下载模板</Button>
              <Button style={{ margin: '0 10px' }} 
                onClick={e=>setIsAbcdModalBool(true)}
              >批量修改</Button>
              <PopConfirmOnly confirm={e => deleteall()}>
                <Button style={{ margin: '0 10px' }} 
                  onClick={e=>{}}
                >批量删除</Button>
              </PopConfirmOnly>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e=>getdata()}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={e=>remove()}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect} >清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowKey="id"
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
            }
          }}
        />
      </div>
    </div>
  )
}

export default App