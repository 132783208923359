import React, { useEffect, useRef, useState } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';

function App(props) {
  const chartRef = useRef(null)

  useEffect(() => {
    if (!chartRef.current) {
      const myChart = echarts.init(document.getElementById('main4'))
      chartRef.current = myChart
    }
    // const dataset = {
    //   dimensions:[],
    //   source:[]
    // }
    // props.data.forEach(i => {
    // })

    chartRef.current.setOption({
      legend: {
        bottom: '0'
      },
      tooltip: {},
      dataset: {
        dimensions: ['name', '完成数量', '退回数量', '录入数量', '修改数量'],
        source: [
          { name: 'Matcha Latte', '完成数量': 43.3, '退回数量': 85.8, '录入数量': 93.7, '修改数量': 120 },
          { name: 'Milk Tea', '完成数量': 83.1, '退回数量': 73.4, '录入数量': 55.1, '修改数量': 120 },
          { name: 'Cheese Cocoa', '完成数量': 86.4, '退回数量': 65.2, '录入数量': 82.5, '修改数量': 120 },
          { name: 'Walnut Brownie', '完成数量': 72.4, '退回数量': 53.9, '录入数量': 39.1, '修改数量': 120 }
        ]
      },
      xAxis: { type: 'category' },
      yAxis: {},
      series: [
        { type: 'bar', },
        { type: 'bar' },
        { type: 'bar' },
        { type: 'bar' }
      ],
      color: ['#3c90f7', '#55bfc0', '#5ebe67', '#f4cd49']
    })
  }, [props.data])

  const resize = e => {
    if (chartRef.current)
      chartRef.current.resize()
  }
  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <>
      <div id="main4" style={{ width: '100%', height: '420px' }}></div>
    </>
  );
}

export default App;