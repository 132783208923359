
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload, Transfer } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import PopConfirmOnly from '@/utils/PopConfirmOnly'
import store from '@/redux/store'

const { Option } = Select;

function App(props) {

  const handleShowEdit = e => {
    setModalVisible('edit')
    setIdEdit(e.id)
    setNameEdit(e.name)
    setType(e.type)
    console.log(e)
    setCodeEdit(e.code)
    setResource0Edit(e.resources.filter(i => i.type === 0).map(i => i.id))
    setResource1Edit(e.resources.filter(i => i.type === 1).map(i => i.id))
    setResource2Edit(e.resources.filter(i => i.type === 2).map(i => i.id))
  }

  // table
  const [columns, setColumns] = useState([
    {
      title: '权限编号',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '角色名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '包含权限',
      dataIndex: 'resources',
      key: 'resources',
      render: text => <p style={{ maxWidth: '600px' }}>{text.map(i => i.name).join(',')}</p>
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size='small' type='link' onClick={e => handleShowEdit(record)}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    axios.get('/api/sys/role/list', {
      params: {
				pageSize: 1000000,
        key: selectText
      }
    }).then(res => {
      setDataSource(res.data.data)
    })
  }
  useEffect(() => {
    getData()
  }, [updateFlag])

  //select
  const [selectText, setSelectText] = useState('')
  const [roleselect,setRoleselect] = useState([])

  const clearSelect = e => {
    setSelectText('')
    setUpdateFlag(pre => pre + 1)
  }

  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setIdEdit(null)
    setNameEdit('')
    setCodeEdit('')
    setType('')
    setResource0Edit([])
    setResource1Edit([])
    setResource2Edit([])
    setSelectedKeys0Edit([])
    setSelectedKeys1Edit([])
    setSelectedKeys2Edit([])
  }

  // option
  const [resourceOption, setResourceOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/resource/list', { params: { pageSize: 1000000 } }).then(res => {
      setResourceOption(res.data.data.map(i => Object.assign({}, i, { key: i.id })))
    })
    axios.get('/api/sys/dict/projectType')
    .then(res=>{
      setRoleselect(res.data.labelValues)
    })
  }, [])
  //修改
  const [idEdit, setIdEdit] = useState(null)
  const [nameEdit, setNameEdit] = useState('')
  const [codeEdit, setCodeEdit] = useState('')
  const [type, setType] = useState('')
  const [resource0Edit, setResource0Edit] = useState([])
  const [resource1Edit, setResource1Edit] = useState([])
  const [resource2Edit, setResource2Edit] = useState([])
  const [selectedKeys0Edit, setSelectedKeys0Edit] = useState([])
  const [selectedKeys1Edit, setSelectedKeys1Edit] = useState([])
  const [selectedKeys2Edit, setSelectedKeys2Edit] = useState([])
  const handleChangeEdit = (nextTargetKeys, type) => {
    switch (type) {
      case 0:
        setResource0Edit(nextTargetKeys)
        break
      case 1:
        setResource1Edit(nextTargetKeys)
        break
      case 2:
        setResource2Edit(nextTargetKeys)
        break
    }
  };
  const handleSelectChangeEdit = (sourceSelectedKeys, targetSelectedKeys, type) => {
    switch (type) {
      case 0:
        setSelectedKeys0Edit([...sourceSelectedKeys, ...targetSelectedKeys])
        break
      case 1:
        setSelectedKeys1Edit([...sourceSelectedKeys, ...targetSelectedKeys])
        break
      case 2:
        setSelectedKeys2Edit([...sourceSelectedKeys, ...targetSelectedKeys])
        break
    }
  };
  const handleSaveAdd = e => {
    if (nameEdit != '' && codeEdit != '' && type != '') {
      axios.post('/api/sys/role', {
        name: nameEdit, code: codeEdit, type , resources: [...resource0Edit, ...resource1Edit, ...resource2Edit].map(i => { return { id: i } })
      }).then(res => {
        handleModalCancel()
        message.success()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const handleSaveEdit = e => {
    if (nameEdit != '' && codeEdit != '' && type != '') {
      console.log(idEdit)
      if (idEdit === 1 && store.getState().user.uid != 4) {
        handleModalCancel()
        return
      }
      console.log(type)
      axios.put('/api/sys/role', {
        id: idEdit, name: nameEdit, type , code: codeEdit, resources: [...resource0Edit, ...resource1Edit, ...resource2Edit].map(i => { return { id: i } })
      }).then(res => {
        handleModalCancel()
        message.success()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const handleSaveEnsure = e => {
    if (modalVisible === 'add') handleSaveAdd()
    if (modalVisible === 'edit') handleSaveEdit()
  }
  //删除
  const handleDelete = e => {
    axios.delete('/api/sys/role/' + e).then(res => {
      message.success()
      getData()
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <span>权限名称</span>
            <Input style={{ width: '320px', margin: '0 10px' }} placeholder='请输入权限名称' value={selectText} onChange={e => setSelectText(e.target.value)}></Input>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => getData()}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('add')}><PlusOutlined />添加权限</Button>
            {/* <Button style={{ margin: '0 10px' }}>导出列表</Button> */}
          </Col>
        </Row>

        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />

      </div>

      <Modal
        width={720}
        title={modalVisible === ' add' ? '添加权限' : '修改权限'}
        visible={modalVisible === 'add' || modalVisible === 'edit'}
        onCancel={handleModalCancel}
        destroyOnClose
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleModalCancel}>取消</Button>,
          <Button key="submit" type="primary" onClick={handleSaveEnsure}>确认</Button>,
        ]}
      >
        <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>权限编号:</Col>
          <Col span={14}><Input value={codeEdit} onChange={e => setCodeEdit(e.target.value)}></Input></Col>
          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>角色名称:</Col>
          <Col span={14}><Input value={nameEdit} onChange={e => setNameEdit(e.target.value)}></Input></Col>
          <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>角色类型:</Col>
          <Col span={14}>
            <Select defaultValue={type} style={{ width: 120 }} onChange={e=>{setType(e)}}>
              {
                Object.keys(roleselect).map(v=>{
                  return <Option value={v} >{roleselect[v]}</Option>
                })
              }
            </Select>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>分配权限:</Col>
          <Col span={14} style={{ height: '320px', overflowY: 'auto' }}>
            <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>接口</p>
            <Transfer
              style={{ marginBottom: '20px' }}
              dataSource={resourceOption.filter(i => i.type === 1)}
              titles={['可选权限', '选中权限']}
              targetKeys={resource1Edit}
              selectedKeys={selectedKeys1Edit}
              onChange={nextTargetKeys => handleChangeEdit(nextTargetKeys, 1)}
              onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => handleSelectChangeEdit(sourceSelectedKeys, targetSelectedKeys, 1)}
              render={i => i.name}
            />
            <Divider />
            <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>主菜单</p>
            <Transfer
              style={{ marginBottom: '20px' }}
              dataSource={resourceOption.filter(i => i.type === 0)}
              titles={['可选权限', '选中权限']}
              targetKeys={resource0Edit}
              selectedKeys={selectedKeys0Edit}
              onChange={nextTargetKeys => handleChangeEdit(nextTargetKeys, 0)}
              onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => handleSelectChangeEdit(sourceSelectedKeys, targetSelectedKeys, 0)}
              render={i => i.name}
            />
            <Divider />
            <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>项目菜单</p>
            <Transfer
              dataSource={resourceOption.filter(i => i.type === 2)}
              titles={['可选权限', '选中权限']}
              targetKeys={resource2Edit}
              selectedKeys={selectedKeys2Edit}
              onChange={nextTargetKeys => handleChangeEdit(nextTargetKeys, 2)}
              onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => handleSelectChangeEdit(sourceSelectedKeys, targetSelectedKeys, 2)}
              render={i => i.name}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default App