import React, { Component, useEffect, useState } from 'react';
import { Button, Row, Col, Modal, Input, Tooltip, Select, Checkbox, Table } from 'antd'
import { RobotOutlined, TrademarkOutlined } from '@ant-design/icons'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { audioUrl, wanggeUrl } from '@/utils/config'
import moment from 'moment'

const { Option } = Select;
const { TextArea } = Input;

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      id: '',
      visible: false,
      value: '',
      toolbool: false,
      visiblet: false,
      selectvalue: '1',
      tuihui: [],
      getdata: '',
      renyuan: [],
      renyuans: {},
      labelValues: [],
      isSelfCheck: false,
      isbool: false,
      gondgans:[],
      xiangmus:[],
      zhibiaos:[],
      tupian:[],
      wanggeBools:false
    }
  }
  componentDidMount() {
    axios.get('/api/sys/dict/' + 'sxTaskRefusedReasonType', {
      params: {
        pageSize: 1000000,
      }
    })
      .then(res => {
        this.setState({ labelValues: res.data.labelValues })
      })

  }

  componentWillReceiveProps(nextProps) {
    let self = this;
    setTimeout(function () {
      if (JSON.stringify(self.state.data) != JSON.stringify(nextProps.data)) {
        self.setState({
          data: self.state.data.length != 0 ? self.state.data : nextProps.data,
          id: self.state.id != '' ? self.state.id : nextProps.id,
          getdata: self.state.getdata != '' ? self.state.getdata : nextProps.getdata
        }, () => {
          axios.get('/api/core/project/' + nextProps.data.projectId)//指标组
            .then(res => {
              self.setState({
                xiangmus:res.data
              })
              axios.get('/api/sys/user/list', {
                params: {
                  pageSize: 1000000,
                  orgCode: 'shixiao',
                  roleCode: 'shixiao_vistor',
                  groupId: res.data.userGroupId,
                }
              })
                .then(res => {
                  self.setState({
                    renyuan: res.data.data
                  })
                })
            })
        })
      }
    }, 0)
  }

  showModal = () => {
    this.setState({
      visible: TrademarkOutlined,
      value: sessionStorage.getItem("reviewRefusedRemark")
    });
  };

  showModalt = () => {
    this.setState({
      visible: true,
      visiblet: true,
      tuihui: JSON.parse(sessionStorage.getItem("tuihui")),
      value: sessionStorage.getItem("reviewRefusedRemark")
    });
  };

  handleOk = e => {
    if (!this.state.isbool) {
      message.error('检查信息并勾选')
      return
    }
    if (this.state.labelValues[this.state.selectvalue] == '重做') {
      if (JSON.stringify(this.state.renyuans) == '{}') {
        message.error('请完整填写信息,再次提交')
        return
      }
    }
    if (this.state.data.taskAudit1Status.display != '已审核') {
      if (this.state.visiblet) {
        axios.post('/api/core/taskAudit/audit1Refused', {
          audit1RefusedReasonType: this.state.selectvalue,
          audit1RefusedReason: JSON.stringify(this.state.tuihui),
          audit1RefusedRemark: this.state.value,
          newPrincipalId: this.state.renyuans.id,
          newPrincipal: this.state.renyuans.name,
          isSelfCheck: this.state.isSelfCheck ? 1 : 0
        }, {
          params: {
            taskId: this.state.data.id,
          }
        })
          .then(res => {
            setTimeout(() => {
              message.success('操作成功')
              sessionStorage.removeItem("tuihui", '')
              sessionStorage.removeItem("reviewRefusedRemark", '')
              window.history.go(-1);
            }, 100)
            if (JSON.stringify(this.state.renyuans) != '{}') {
              axios.post('/api/core/taskAudit/confirmReturn', {}, {
                params: {
                  taskId: this.state.id
                }
              })
                .then(res => {
                })
            }
          })
      } else {
        axios.post('/api/core/taskAudit/audit1Passed', {
          audit1PassedReason: this.state.value
        }, {
          params: {
            taskId: this.state.id,
          }
        })
          .then(res => {
            setTimeout(() => {
              message.success('操作成功')
              sessionStorage.removeItem("tuihui", '')
              sessionStorage.removeItem("reviewRefusedRemark", '')
              window.history.go(-1);
            }, 100)
          })
      }
    } else if (this.state.data.taskAudit2Status.display != '已审核') {
      if (this.state.visiblet) {
        axios.post('/api/core/taskAudit/audit2Refused', {
          audit2RefusedReasonType: this.state.selectvalue,
          audit2RefusedReason: JSON.stringify(this.state.tuihui),
          audit2RefusedRemark: this.state.value,
          newPrincipalId: this.state.renyuans.id,
          newPrincipal: this.state.renyuans.name,
          isSelfCheck: this.state.isSelfCheck ? 1 : 0
        }, {
          params: {
            taskId: this.state.data.id,
          }
        })
          .then(res => {
            setTimeout(() => {
              message.success('操作成功')
              sessionStorage.removeItem("tuihui", '')
              sessionStorage.removeItem("reviewRefusedRemark", '')
              window.history.go(-1);
            }, 100)
            if (JSON.stringify(this.state.renyuans) != '{}') {
              axios.post('/api/core/taskAudit/confirmReturn', {}, {
                params: {
                  taskId: this.state.id
                }
              })
                .then(res => {
                })
            }
          })
      } else {
        axios.post('/api/core/taskAudit/audit2Passed', {
          audit2PassedReason: this.state.value
        }, {
          params: {
            taskId: this.state.id
          }
        })
          .then(res => {
            setTimeout(() => {
              message.success('操作成功')
              sessionStorage.removeItem("tuihui", '')
              sessionStorage.removeItem("reviewRefusedRemark", '')
              window.history.go(-1);
            }, 100)
          })
      }
    } else if (this.state.data.taskReviewStatus.display != '已审核') {
      if (this.state.visiblet) {
        axios.post('/api/core/taskAudit/reviewRefused', {
          reviewRefusedReasonType: this.state.selectvalue,
          reviewRefusedReason: JSON.stringify(this.state.tuihui),
          reviewRefusedRemark: this.state.value,
          newPrincipalId: this.state.renyuans.id,
          newPrincipal: this.state.renyuans.name,
          isSelfCheck: this.state.isSelfCheck ? 1 : 0
        }, {
          params: {
            taskId: this.state.data.id,
          }
        })
          .then(res => {
            setTimeout(() => {
              message.success('操作成功')
              sessionStorage.removeItem("tuihui", '')
              sessionStorage.removeItem("reviewRefusedRemark", '')
              window.history.go(-1);
            }, 100)
            if (JSON.stringify(this.state.renyuans) != '{}') {
              axios.post('/api/core/taskAudit/confirmReturn', {}, {
                params: {
                  taskId: this.state.id
                }
              })
                .then(res => {
                })
            }
          })
      } else {
        axios.post('/api/core/taskAudit/reviewPassed', {
          reviewPassedReason: this.state.value
        }, {
          params: {
            taskId: this.state.id,
          }
        })
          .then(res => {
            setTimeout(() => {
              message.success('操作成功')
              sessionStorage.removeItem("tuihui", '')
              sessionStorage.removeItem("reviewRefusedRemark", '')
              window.history.go(-1);
            }, 100)
          })
      }
    }
    this.setState({
      visible: false,
      visiblet: false,
      selectvalue: '1'
    });
  };

  handleCancel = e => {
    sessionStorage.setItem('reviewRefusedRemark', this.state.value)
    this.setState({
      visible: false,
      visiblet: false,
      isbool: false,
      value: '',
      selectvalue: '1'
    });
  };
  handleChange = (value) => {
    this.setState({
      selectvalue: value
    }, () => {
      if (this.state.selectvalue != '2') {
        this.setState({
          renyuans: {}
        })
      }
    })
  }

  renyuanChange = (value) => {
    this.setState({
      renyuans: JSON.parse(value)
    })
  }

  exporte = () => {
    axios.get('/api/core/task/detail/export', {
      params: {
        taskId: this.state.id
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出工单" + this.state.data.surveyCode + ".xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  exporteimg = () => {
    axios.get('/api/core/issue/export', {
      params: {
        taskId: this.state.id
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出图片" + this.state.data.surveyCode + ".zip");
      document.body.appendChild(link);
      link.click();
    })
  }

  wanggeUpload = () => {
    this.setState({
      wanggeBools:true
    })
    axios.get('/api/core/task/' + this.state.id) // 工单参数
      .then(gondgan => {
        this.setState({
          gondgans:gondgan.data
        })
        axios.get('/api/core/project/' + gondgan.data.projectId) // 项目参数
          .then(xiangmu => {
            axios.get('/api/core/metric/list', { // 指标参数
              params: {
                metricGroupId: xiangmu.data.metricGroupId,
                pageSize: 10000
              }
            })
              .then(zhibiao => {
                this.setState({
                  zhibiaos:zhibiao.data.data
                })
                axios.get('/api/core/issue/list', {//轮播图片
                  params: {
                    queryCondition: 'EQ:taskId=' + this.state.id,
                    pageSize: 1000000,
                  }
                })
                  .then(res => {
                    const arr = res.data.data.filter(v => {
                      return v.status == 2 && v.description != '已整改' && v.description != '未整改'
                    })
                    this.setState({
                      tupian:arr
                    })
                  })
              })
          })
      })
  }

  onOk = () => {
    this.state.tupian.forEach((a, i) => {
      const metric = this.state.zhibiaos.filter(v => {
        return v.description == a?.description
      })
      const metricarr = this.state.zhibiaos.filter(v => {
        return metric[0]?.metricCode.includes(v.metricCode)
      })
      const wanggeData = {
        "channel": "虹桥管商务区", //数据来源，场景发现步骤⽆无需提交
        "jkId": this.state.gondgans?.surveyCode+a.id, //工单编号
        "token": "31415926535897932381112", // ? 没有填写信息
        "title": a?.description, // 指标名称
        "address": a?.addressDesc+a?.addressManualDesc, // 经纬度
        "createTime": a?.createdAt, // 图片时间
        "createTimestamp": moment(a?.createdAt).valueOf(), // 图片时间戳
        "level": "2", // 严重程度 默认为 2
        "levelDesc": "一般警告", // 严重程度 默认为 一般警告
        "nowStep": 1, // 当前流程所处节点  默认为 1  
        "description": a?.description,
        "source": { // 来源场景信息
          "name": "虹桥管商务区",
          "sourceId": '035',
          "Id": '035',
          "partsOrEvent": "部件",
          "actionType": "主动发现",
        },
        "category": {
          "level1": metricarr.length != 0 ? metricarr.filter(v => v.parentCode == '')[0]?.description : '',
          "level2": metricarr.length != 0 ? metricarr.filter(v => v.parentCode == metricarr.filter(v => v.parentCode == '')[0].metricCode)[0].description : '',
          "level3": a?.description?.includes('.')?a?.description.split('.')[1]:a?.description
        },
        "location": { // 上报定位
          "coordX": a?.address?.split(',')[0], // 城市建设坐标 
          "coordY": a?.address?.split(',')[1], // 城市建设坐标 
          "city": "上海", // 市 
          "cityId": "3101", // 市的 id
          "district": this.state.gondgans.surveyScope, // 区 
          "districtId": this.state.gondgans.surveyCode.substring(this.state.gondgans.surveyCode.length - 2, this.state.gondgans.surveyCode.length), // 区的id
        },
        "eventProcessList": [{ // 存放当前步骤记录
          "caption": "发现",  // 处于哪个环节名称  默认 发现
          "step": "1", // 1:发现， 2:立案， 3:派遣， 4: 处置 5:核查， 6:结案  默认 1
          "createDate": moment().format("YYYY-MM-DD HH:mm:ss"), // 该流程创建时间  默认 数据提交时间
          "timestamp": moment(moment().format("YYYY-MM-DD HH:mm:ss")).valueOf(), // 该流程创建时间  默认 数据提交时间戳
          "person": {  // 人员信息
            "name": "虹桥管委会上报", // 该流程处理人员名称  默认 虹桥管委会上报
          },
          "stepSource": "旭中", // 该流程数据来源  默认 旭中
          "reporterAnonymous": "Y", // 是否匿名  Y/N 默认 Y
          "reportercallback": "Y", // 是否回访  Y/N 默认 Y
          "preEndingTime": "", // 该步骤的超期时间 可以填空 默认是空
          "preEndingrimestamp": "", // 该步骤的超期时间戳 可以填空 默认是空
          "multimedias": [], // 上报多媒体信息 图片地址 可以填 空
        }
        ],
        "hisEventprocessList": [] // 空
      }
      this.toImg(a.before,wanggeData)
      // axios.post(wanggeUrl,wanggeData)
      // .then(res=>{
      //     console.log(res)
      //     a.isUploadBool=res.message
      // })
    });
    this.setState({
      tupian:[...this.state.tupian]
    })
  }

  

  getBase64Image = (img) => {
    var canvas = document.createElement("canvas")
    canvas.width = img.width
    canvas.height = img.height
    var ctx = canvas.getContext("2d")
    ctx.drawImage(img, 0, 0, img.width, img.height)
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase()
    var dataURL = canvas.toDataURL("image/" + ext)
    return dataURL
}

toImg = (urlsa,wanggeData) => {
    var _this = this
    var image = new Image()
    image.src = audioUrl+urlsa
    image.setAttribute("crossOrigin", "Anonymous")
    // image.setAttribute("crossorigin", " *")
    image.onload = function () {
        var base64 = _this.getBase64Image(image)
        _this.base64 = base64

        //转换base64到file
        var file = _this.btof(base64, "test")
        const data = new FormData();
        data.append('file',file)
        data.append('data',JSON.stringify(wanggeData))
        axios({
            method:'post',
            url:wanggeUrl, 
            data,
            headers: {
                'Content-Type': 'form-data'
            }
        })
        .then(res => {
            console.log(res)
            message.success(res.message)
        })
        this.setState({
            wanggeBool: false
        })
    }
}

btof = (data, fileName) => {
    const dataArr = data.split(",")
    const byteString = atob(dataArr[1])

    const options = {
        type: "image/jpeg",
        endings: "native"
    }
    const u8Arr = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i)
    }
    return new File([u8Arr], fileName + ".jpg", options)
}

  onCancel = () => {
    this.setState({
      wanggeBools:false
    })
  }

  columns = [
    {
      title: '图片id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '是否上传成功',
      dataIndex: 'isUploadBool',
      key: 'isUploadBool',
      render:(text,record,index)=><div>{text?.length>50?'成功':text}</div>
    },
  ];
//


  render() {
    return (
      <div style={{ padding: '20px', paddingLeft: '60px', background: 'white' }}>
        <Modal
          title={'网格上报情况'}
          visible={this.state.wanggeBools}
          onOk={this.onOk}
          onCancel={this.onCancel}
          cancelText='取消'
          width="50%"
          destroyOnClose={true}
          maskClosable={false}
        >
          <Table columns={this.columns} dataSource={this.state.tupian} />
        </Modal>
        <Modal
          title={this.state.visiblet ? '退回' : '通过' + "工单"}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelText='取消'
          width="50%"
          destroyOnClose={true}
          maskClosable={false}
        >
          <Row>
            {
              this.state.visiblet ? <><Col span='5' style={{ display: 'flex', alignItems: 'center' }}>{this.state.visiblet ? '退回' : '通过'}理由:</Col>
                <Col span='16'>
                  <Select defaultValue="2" style={{ width: 120 }} onChange={this.handleChange}>
                    {
                      Object.keys(this.state.labelValues).map((v) => {
                        return <Option value={v}>{this.state.labelValues[v]}</Option>
                      })
                    }
                  </Select>
                </Col>

                {
                  this.state.selectvalue == '2' ? <>
                    <Col span='5' style={{ display: 'flex', alignItems: 'center' }}></Col>
                    <Col span='16'>
                      <Checkbox value={this.state.isSelfCheck} onChange={(e) => this.setState({ isSelfCheck: e })}>是否全部退回自检</Checkbox>
                    </Col>
                  </> : null
                }
                <Col span='5' style={{ display: 'flex', alignItems: 'center' }}></Col>
                <Col span='16'>
                  <Row style={{ height: '200px', overflow: 'auto', border: '1px solid #aaa', margin: '10px 0' }}>
                    {
                      this.state.tuihui.map(v => {
                        return <>
                          <Col span="5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', }}><p style={{ whiteSpace: 'normal' }}>{v.id}</p></Col>
                          <Col span="7" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', }}><img style={{ width: '100%', height: 'auto' }} src={audioUrl + v.before} /></Col>
                          <Col span="12" style={{ fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0', }}>{v.description}</Col>
                        </>
                      })
                    }
                    {
                      this.state.tuihui.length == 0 ? <Col style={{ textAlign: 'center' }}>暂无数据</Col> : null
                    }
                  </Row>
                </Col>
                {
                  this.state.selectvalue == '3' ? <>
                    <Col span='5' style={{ display: 'flex', alignItems: 'center' }}>选择访问员:</Col>
                    <Col span='16'>
                      <Select style={{ width: 120, margin: '10px 0' }} onChange={this.renyuanChange}>
                        {
                          this.state.renyuan.map((v, i) => {
                            return <Option value={JSON.stringify(v)}>{v.name}</Option>
                          })
                        }
                      </Select>
                    </Col>
                  </> : null
                  //
                }
              </> : null
              //
            }
            <Col span='5' style={{ display: 'flex', alignItems: 'center' }}>{this.state.visiblet ? '退回' : '通过'}备注:</Col>
            <Col span='16'><TextArea rows={4} value={this.state.value} onChange={e => {
              this.setState({ value: e.target.value })
              document.getElementById('inputkes').value = e.target.value
            }} placeholder={"请输入备注"} /></Col>
            <Col span='5' style={{ display: 'flex', alignItems: 'center' }}></Col>
            <Col span='16'>
              <Checkbox onChange={e => this.setState({ isbool: e.target.checked })}>请检查填写信息</Checkbox>
            </Col>

          </Row>
        </Modal>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
          <div><RobotOutlined style={{ position: 'absolute', left: '-30px', fontSize: '30px' }} /><span style={{ fontSize: '20px' }}>{this.state.data.code}</span></div>
          <div>
            {
              this.state.xiangmus.name?.includes('网格系统')?
                <Button style={{ margin: '0 10px' }} onClick={this.wanggeUpload}>全部上报</Button>
              :null
            }
            <Button style={{ margin: '0 10px' }} onClick={this.exporteimg}>图片导出</Button>
            <Button onClick={this.exporte}>工单导出</Button>
            {
              sessionStorage.getItem('sxCompleted') != 1 ?
                <Button onClick={this.showModalt} disabled={this.state.data.length != 0 && (this.state.data.taskAudit1Status instanceof Object && this.state.data.taskAudit1Status.display == '已退回' || this.state.data.taskAudit2Status instanceof Object && this.state.data.taskAudit2Status.display == '已退回' || this.state.data.taskReviewStatus instanceof Object && this.state.data.taskReviewStatus.display == '已退回' || this.state.data.taskReviewStatus.display == '已审核' || this.state.data.audit2ByName == null) ? true : false} style={{ margin: '0 10px' }}>退回</Button>
              :null
            }
            {
              sessionStorage.getItem('sxCompleted') != 1 ?
                <Button type="primary" disabled={this.state.data.length != 0 && (this.state.data.taskAudit1Status instanceof Object && this.state.data.taskAudit1Status.display == '已退回' || this.state.data.taskAudit2Status instanceof Object && this.state.data.taskAudit2Status.display == '已退回' || this.state.data.taskReviewStatus instanceof Object && this.state.data.taskReviewStatus.display == '已退回' || this.state.data.taskReviewStatus.display == '已审核' || this.state.data.audit2ByName == null) ? true : false} onClick={this.showModal}>通过</Button>
              :null
            }
          </div>
        </div>
        <Row>
          <Col span={5}><b style={{ fontWeight: '550' }}>项目区域</b>: <span> {this.state.data.surveyScope}</span></Col>
          <Col span={5}><b style={{ fontWeight: '550' }}>调研编号</b>: <span> {this.state.data.surveyCode}</span></Col>
          <Col span={5}><b style={{ fontWeight: '550' }}>原始得分</b>: <span> {this.state.data.checkScore}</span></Col>
          <Col span={9} style={{ textAlign: 'right', color: '#aaa', height: '20px', display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <span><b style={{ fontWeight: '550' }}>一审状态</b></span><br />
              <span style={{ float: 'riht', color: '#000' }}> {this.state.data.length != 0 && this.state.data.taskAudit1Status instanceof Object ? this.state.data.taskAudit1Status.display : null}</span>
            </div>
            <div style={{ margin: '0 10px' }}>
              <span><b style={{ fontWeight: '550' }}>二审状态</b></span><br />
              <span style={{ float: 'riht', color: '#000' }}> {this.state.data.length != 0 && this.state.data.taskAudit2Status instanceof Object ? this.state.data.taskAudit2Status.display : null}</span>
            </div>
            <div>
              <span><b style={{ fontWeight: '550' }}>复核状态</b></span><br />
              <span style={{ float: 'riht', color: '#000' }}> {this.state.data.length != 0 && this.state.data.taskReviewStatus instanceof Object ? this.state.data.taskReviewStatus.display : null}</span>
            </div>
          </Col>
          <Col span={5}><b style={{ fontWeight: '550' }}>调研对象</b>:<span> {this.state.data.surveyTarget}</span></Col>
        </Row>
      </div>
    )
  }
}

export default App