import React, { useEffect, useRef, useState } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';
function App(props) {
    //初始化
    const chartRef = useRef(null)

    //绘制图表
    const chartInit = e =>{
        const myChart = echarts.init(document.getElementById('main1'))
        chartRef.current = myChart
        myChart.setOption({
            legend: {},
            tooltip: {},
            dataset: {
                dimensions: ['product', '2015', '2016', '2017', '2018'],
                source: [
                    { product: '7月1', '2015': 43.3, '2016': 85.8, '2017': 93.7, '2018': 12.3 },
                    { product: '7月2', '2015': 83.1, '2016': 73.4, '2017': 55.1, '2018': 12.4 },
                    { product: '7月3', '2015': 86.4, '2016': 65.2, '2017': 82.5, '2018': 12.9 },
                    { product: '7月4', '2015': 72.4, '2016': 53.9, '2017': 39.1, '2018': 12.0 },
                    { product: '7月5', '2015': 72.4, '2016': 53.9, '2017': 39.1, '2018': 12.0 }
                ]
            },
            xAxis: { type: 'category' },
            yAxis: {},
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [
                { type: 'bar', color: 'rgba(66, 80, 107, 1)' },
                { type: 'bar', color: 'rgba(240, 74, 76, 1)' },
                { type: 'bar', color: 'rgba(62, 97, 155, 1)' },
                { type: 'bar', color: 'rgba(244, 205, 73, 1)' }

            ]
        });

    }
    //初始化
    useEffect(() => {
        setTimeout(() => {
            chartInit()
        }, 0);
    }, [])

    const resize = e => {
        if (chartRef.current)
            chartRef.current.resize()
    }
    useEffect(() => {
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])


    return (
        <>
            <div id="main1" style={{ width: '100%', height: '250px' }}></div>
        </>
    );
}
export default App


