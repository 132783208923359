import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu, Dropdown, Tabs } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'


function App(props) {

  const { push } = useHistory()
  const { projectid, surveyScope, principalId, type } = useRouteMatch().params

  const [curTab, setCurTab] = useState('table')

  const typeArr = {
    0:'未开始',
    1:'进行中',
    2:'已提交'
  }

  const columns = [
    {
      title: '访问员',
      dataIndex: 'principal',
      render: (text,recort) => text ? text : '-',
      sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '工单编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '工单编号',
      dataIndex: 'surveyScope',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
  ]
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition=[]
    queryCondition.push('EQ:projectid='+projectid)
    queryCondition.push('EQ:surveyScope='+surveyScope)
    queryCondition.push('EQ:status='+(type==2?'2!3!21':type))
    queryCondition.push('EQ:principalId='+principalId)

    axios.get('/api/core/task/list', {
      params: {
        pageSize: 1000000,
        queryCondition:queryCondition.join(',')
      }
    }).then(res => {
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  useEffect(() => {
    getData()
  }, [updateFlag])

  return (
    <>
      <div style={{ padding: '30px' }}>
        <div style={{ padding: '30px', background: 'white' }}>

          <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
            <Col>
              <Button>批量导出</Button>
            </Col>
          </Row>

          {
            curTab === 'table' ?
              <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true  }} />
              : null
          }
        </div>
      </div>

    </>
  )
}

export default App
