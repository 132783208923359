//开发 url

// export const baseUrl = 'http://47.110.152.136:8091/xz'
// // export const baseUrl = 'https://gee4.cn/xz'
// export const audioUrl = 'https://gee4.cn/xz'
// export const audioUrl1 = 'http://gee4.cn'
// export const tousuUrl = 'http://47.110.152.136:8097'
// export const wenjuanUrl = 'https://research.xxinxi.com/xz/cms/#/wjs/' // 生产
// export const wanggeUrl = 'https://gee4.cn/paidan_data_route/processedData/streamUpload' // 网格测试

// // 上线url
// // export const tousuUrl = 'https://gee4.cn/xzext/apiv2/service-special' // 测试
// export const wenjuanUrl = 'https://gee4.cn/xz/cms/index.html#/wjs/' // 测试

export const baseUrl = window.location.origin + '/xz'
export const audioUrl = window.location.origin
export const tousuUrl = 'https://research.xxinxi.com/xzext/apiv2/service-special' // 生产
export const wenjuanUrl = 'https://research.xxinxi.com/xz/cms/#/wjs/' // 生产
export const wanggeUrl = 'https://research.xxinxi.com/paidan_data_route/processedData/streamUpload'