
import React, { useEffect, useState, useRef } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Upload } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import axios from '@/utils/axios'
import message from '@/utils/message'
import { baseUrl } from '@/utils/config'
import { listToArray } from '@/utils/listToArray'
import file from '@/assets/jhcpdr.xlsx'
import PopConfirmOnly from "@/utils/PopConfirmOnly";
const { Option } = Select;

function App(props) {

  const { id } = useRouteMatch().params

  const handleShowChildAdd = e => {
    setModalVisible('addChild')
    setLvbool(!(e.treeLevel != 1))
    setdistributionTypeName('')
    setdistributionLevelName('')
    setdistributionType('')
    setdistributionLevel('')
    setAddChildData({
      parentCode: e.productCode,
      pname: e.productName,
      pid: e.id,
      treeLevel: e.treeLevel + 1,
      name: '',
      code: '',
      seq: '',
      barCode: '',
      isPrice: false
    })
  }
  const handleShowEdit = e => {
    setModalVisible('edit')
    setEditData(e)
    setdistributionTypeName(e.distributionTypeName)
    setdistributionLevelName(e.distributionLevelName)
    setdistributionType(e.distributionType)
    setdistributionLevel(e.distributionLevel)
    setLvbool(!(e.treeLevel != 1))
  }

  // table
  const [columns, setColumns] = useState([
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '名称',
      dataIndex: 'productName',
    },
    {
      title: '编号',
      dataIndex: 'productCode',
    },
    {
      title: '条形码',
      dataIndex: 'barCode',
    },
    {
      title: '产品规格',
      dataIndex: 'specification',
    },
    {
      title: '排序',
      dataIndex: 'seq',
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record) => (
        <>
          {
            record.treeLevel < 4 ?
              <Button size='small' type='link' onClick={e => handleShowChildAdd(record)}>添加子类</Button>
              : null
          }
          <Divider type='vertical' />
          <Button size='small' type='link' onClick={e => handleShowEdit(record)}>修改</Button>
          <Divider type='vertical' />
          <PopConfirmOnly confirm={e => handleDelete(record.id)}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ])
  //
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    const queryCondition = []
    if (selectText) queryCondition.push('LIKE:productName=' + selectText)
    if (barCode) queryCondition.push('LIKE:barCode=' + barCode)

    axios.get('/api/core/product/list', {
      params: {
        pageSize: 1000000, productGroupId: id, queryCondition: queryCondition.join(',')

      }
    }).then(res => {
      res.data.data=res.data.data.reverse()
      selectText != '' || barCode != '' ?setDataSource(res.data.data):setDataSource(listToArray(res.data.data))
    })
  }

  const [distributionTypeName, setdistributionTypeName] = useState('')//铺货等级
  const [distributionType, setdistributionType] = useState('')//铺货等级数值

  const [distributionLevelName, setdistributionLevelName] = useState('')//铺货类型
  const [distributionLevel, setdistributionLevel] = useState('')//铺货等级数值

  const [Lvbool, setLvbool] = useState(true)

  const [puLv, setpuLv] = useState([])
  const [puType, setpuType] = useState([])

  useEffect(() => {
    getData()
    axios.get('/api/sys/dict/distributionType')
      .then(res => {
        setpuType(res.data.labelValues)
      })
    axios.get('/api/sys/dict/distributionLevel')
      .then(res => {
        setpuLv(res.data.labelValues)
      })
  }, [updateFlag])

  //select
  const [selectText, setSelectText] = useState('')
  const [barCode, setbarCode] = useState('')
  const clearSelect = e => {
    setSelectText('')
    setUpdateFlag(pre => pre + 1)
  }
  
  // 弹窗
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
    setFileListImport([])
    setNameAddParent('')
    setCodeAddParent('')
    setSeqAddParent('')
    setIsPriceAddParent(false)
    setAddChildData({
      pname: '',
      pid: '',
      name: '',
      code: '',
      seq: '',
      barCode: '',
      treeLevel: null,
      isNeedPrice: false
    })
    setEditData({})
  }
  
  // 导入
  const handleImport = e => {
    const formData = new FormData()
    formData.append('file', fileListImport[0])
    axios.post('/api/core/product/import', formData, { params: { productGroupId: id }, headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
      message.success()
      handleModalCancel()
      getData()
    })
  }
  const [fileListImport, setFileListImport] = useState([])
  const uploadProps = {
    onRemove: (file) => {
      setFileListImport([])
      return []
    },
    beforeUpload: (file) => {
      setFileListImport([file])
      return false
    }
  }
  // 添加父类
  const handleAddParent = e => {
    if (nameAddParent != '' && codeAddParent != '' && seqAddParent != '') {
      axios.post('/api/core/product', {
        parentCode: '',
        productName: nameAddParent,
        productCode: codeAddParent,
        seq: seqAddParent,
        isNeedPrice: isPriceAddParent ? 1 : 0,
        parent: 0,
        treeLevel: 1,
        isLeaf: 0
      }, { params: { productGroupId: id } }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  }
  const [nameAddParent, setNameAddParent] = useState('')
  const [codeAddParent, setCodeAddParent] = useState('')
  const [seqAddParent, setSeqAddParent] = useState('')
  const [isPriceAddParent, setIsPriceAddParent] = useState(false)
  //添加子类
  const handleAddChild = e => {
    if (addChildData.name != '' && addChildData.code != '' && addChildData.seq != ''
      && addChildData.barCode != '' && addChildData.treeLevel != '' && editData.specification != '') {
      axios.post('/api/core/product', {
        parentCode: addChildData.parentCode,
        parent: addChildData.pid,
        productName: addChildData.name,
        productCode: addChildData.code,
        seq: addChildData.seq,
        specification:addChildData.specification,
        barCode: addChildData.barCode,
        isNeedPrice: addChildData.isPrice ? 1 : 0,
        treeLevel: addChildData.treeLevel,
        isLeaf: 1,
        distributionTypeName,
        distributionType,
        distributionLevelName,
        distributionLevel
      }, { params: { productGroupId: id } }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }

  }
  const [addChildData, setAddChildData] = useState({
    parentCode: '',
    pname: '',
    pid: '',
    name: '',
    code: '',
    seq: '',
    barCode: '',
    isNeedPrice: false,
    treeLevel: null
  })
  const handleChangeChildData = (type, value) => {
    const target = Object.assign({}, addChildData, { [type]: value })
    setAddChildData(target)
  }
  // 修改
  const handleEdit = e => {
    if (editData.productName != ''
      && editData.productCode != ''
      && editData.seq
      && editData.barCode != ''
      && editData.specification != '') {
      const target = Object.assign({}, editData)
      delete target.key
      delete target.children
      axios.put('/api/core/product', {
        ...target,
        distributionTypeName,
        distributionType,
        distributionLevelName,
        distributionLevel
      }).then(res => {
        message.success()
        handleModalCancel()
        getData()
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }

  }
  const [editData, setEditData] = useState({})
  const handleChangeEdit = (type, value) => {
    const target = Object.assign({}, editData, { [type]: value })
    setEditData(target)
  }

  // 删除
  const handleDelete = e => {
    axios.delete('/api/core/product/' + e).then(res => {
      message.success()
      getData()
    })
  }
  //导出
  const handleExport = e => {
    axios.get('/api/core/product/export', {
      params: {
        productGroupId: id,
      },
      responseType: 'blob'
    }).then(res => {
      let url = window.URL.createObjectURL(new Blob([res]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "导出产品.xlsx");
      document.body.appendChild(link);
      link.click();
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <span>产品名称</span>
            <Input style={{ width: '320px', margin: '0 10px' }} placeholder='请输入产品名称' value={selectText} onChange={e => setSelectText(e.target.value)}></Input>
            <span>条形码</span>
            <Input style={{ width: '320px', margin: '0 10px' }} placeholder='请输入产品条形码' value={barCode} onChange={e => setbarCode(e.target.value)}></Input>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => getData()}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
          <Col style={{margin:'10px 0'}}>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={e => setModalVisible('addParent')}><PlusOutlined />添加父类</Button>
            <Button style={{ margin: '0 10px' }} onClick={e => setModalVisible('import')}>导入产品</Button>
            <Button style={{ margin: '0 10px' }} onClick={handleExport}>导出列表</Button>
          </Col>
        </Row>

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger:true }}
        />

        <Modal
          width={640}
          title="添加父类"
          visible={modalVisible === 'addParent'}
          onCancel={handleModalCancel}
          maskClosable={false}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleModalCancel}>
              取消
          </Button>,
            <Button key="submit" type="primary" onClick={handleAddParent}>
              确认
          </Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>产品名称:</Col>
            <Col span={14}><Input value={nameAddParent} onChange={e => setNameAddParent(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>产品编号:</Col>
            <Col span={14}><Input value={codeAddParent} onChange={e => setCodeAddParent(e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>排序值:</Col>
            <Col span={14}><Input value={seqAddParent} onChange={e => setSeqAddParent(e.target.value)}></Input></Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title="添加子类"
          visible={modalVisible === 'addChild'}
          onCancel={handleModalCancel}
          maskClosable={false}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleModalCancel}>
              取消
          </Button>,
            <Button key="submit" type="primary" onClick={handleAddChild}>
              确认
          </Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>父类名称:</Col>
            <Col span={14}><Input disabled value={addChildData.pname}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>产品名称:</Col>
            <Col span={14}><Input value={addChildData.name} onChange={e => handleChangeChildData('name', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>产品编号:</Col>
            <Col span={14}><Input value={addChildData.code} onChange={e => handleChangeChildData('code', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>排序值:</Col>
            <Col span={14}><Input value={addChildData.seq} onChange={e => handleChangeChildData('seq', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>产品规格:</Col>
            <Col span={14}><Input value={addChildData.specification} onChange={e => handleChangeChildData('specification', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>条形码:</Col>
            <Col span={14}><Input value={addChildData.barCode} onChange={e => handleChangeChildData('barCode', e.target.value)}></Input></Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title="导入产品"
          visible={modalVisible === 'import'}
          onCancel={handleModalCancel}
          maskClosable={false}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleImport}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}>批量上传:</Col>
            <Col span={14}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>上传文件</Button>
              </Upload>
            </Col>
            <Col span={4}><Button onClick={e => window.open(file)}>下载模板</Button></Col>
          </Row>
        </Modal>

        <Modal
          width={640}
          title="修改"
          visible={modalVisible === 'edit'}
          onCancel={handleModalCancel}
          maskClosable={false}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleModalCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={handleEdit}>确认</Button>,
          ]}
        >
          <Row gutter={[20, 20]} style={{ lineHeight: '30px' }}>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>产品名称:</Col>
            <Col span={14}><Input value={editData.productName} onChange={e => handleChangeEdit('productName', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>产品编号:</Col>
            <Col span={14}><Input value={editData.productCode} onChange={e => handleChangeEdit('productcode', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>排序值:</Col>
            <Col span={14}><Input value={editData.seq} onChange={e => handleChangeEdit('seq', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>产品规格:</Col>
            <Col span={14}><Input value={editData.specification} onChange={e => handleChangeEdit('specification', e.target.value)}></Input></Col>
            <Col span={6} style={{ textAlign: 'right' }}><font color='red'>*</font>条形码:</Col>
            <Col span={14}><Input value={editData.barCode} onChange={e => handleChangeEdit('barCode', e.target.value)}></Input></Col>
          </Row>
        </Modal>
      </div>
    </div>
  )
}

export default App