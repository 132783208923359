import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import PopConfirmOnly from "@/utils/PopConfirmOnly";

function App(props) {

  const [columns, setColumns] = useState([
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '编号',
        dataIndex: 'number',
        key: 'number'
      },
      {
        title: '条形码',
        dataIndex: 'bar_code',
        key: 'bar_code'
      },
      {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort'
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: text => (
          <>
            <Button>添加子类</Button>
            <Button>修改</Button>
            <PopConfirmOnly confirm={()=>{}}>
              <Button type='link' size='small'>删除</Button>
            </PopConfirmOnly>
          </>
        )
      },
    ]

  )
  const [dataSource, setDataSource] = useState([
    {
      name: '12222223',
      number: '01',
      bar_code: '189023937',
      sort: '1'

    },
    {
      name: '12222223',
      number: '01',
      bar_code: '189023937',
      sort: '1'
    },
    {
      name: '12222223',
      number: '01',
      bar_code: '189023937',
      sort: '1'
    }
  ])
  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            <span style={{ lineHeight: '30px' }}>产品名称</span>
            <Input style={{ width: '240px', marginLeft: '10px' }}></Input>
            <Button type='primary'>查询</Button>
            <Button>重置</Button>
          </Col>
          <Col>
            <Button type='primary' onClick={e => setModalVisible('add')}><PlusOutlined />添加父类</Button>
            <Button>导入产品</Button>
            <Button>导出列表</Button>

          </Col>
        </Row>
        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />
      </div>
    </div>
  )
}

export default App
