import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'
const { Option } = Select;


function App(props) {

    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    // table
    const columns = [{
        title: '调研编号',
        dataIndex: 'surveyCode',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
        title: '调研对象',
        dataIndex: 'surveyTarget',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
        title: '调研大区',
        dataIndex: 'surveyScope',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    }, {
        title: '访问员',
        dataIndex: 'principal',
        render: text => text ? text : '-',
        sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
        title: '代理人',
        dataIndex: 'agent',
        render: text => text ? text : '-',
        sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
        title: '访问员',
        dataIndex: 'principal',
        render: text => text ? text : '-',
        sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
        title: '进场时间',
        dataIndex: 'startAt',
        render: text => text ? text : '-',
        sorter: (a, b) => a.startAt?.localeCompare(b.startAt),
    },
    {
        title: '离场时间',
        dataIndex: 'endAt',
        render: text => text ? text : '-',
        sorter: (a, b) => a.endAt?.localeCompare(b.endAt),
    },
    {
        title: <> <p> 素材数 </p><p>照片/录音 / 视频 / 条码 </p></>,
        dataIndex: 'issueCount',
        render: text => text ? text : '-',
        sorter: (a, b) => a.issueCount-b.issueCount,
    },
    {
        title: '进场状态',
        dataIndex: 'status',
        render: text => taskStatusMap[text]
    }
    ]


    const [okstate, setokstate] = useState({}) //完成状态数据

    const [dataSource, setDataSource] = useState([])
    const [updateFlag, setUpdateFlag] = useState(0)
    const getData = e => {
        const params = {
            page,
            pageSize,
            projectId: projectid
        }
        if (dateSelect) params.createdAtFrom = moment(dateSelect[0]).format('YYYYMMDD')
        if (dateSelect) params.createdAtTo = moment(dateSelect[1]).format('YYYYMMDD')
        if (targetSelect) params.surveyTarget = targetSelect
        if (codeSelect) params.surveyCode = codeSelect
        if (vistorSelect) params.principalId = vistorSelect
        if (scopeSelect) params.surveyScope = scopeSelect
        if (overstate) params.status = overstate
        axios.get('/api/core/task/innerList', {
            params:{
              ...params,
              userId:store.getState().user.uid
            },
        }).then(res => {
            setTotal(res.data.totalSize)
            const arr = res.data.data.map(i => { return { ...i, key: i.id } })
            setDataSource(arr)
        })
    }

    const [pageSize,setPageSize] = useState(5)//显示数据数量
    const [page,setPage] = useState(1)//数据页数
    const [total,setTotal] = useState(null)//数据总是
  
    useEffect(()=>{
      getData()
    },[pageSize,page])
  
    useEffect(() => {
        if (store.getState().user.uid) // 进场状态数据
            getData()
        axios.get('/api/sys/dict/' + 'taskStatus')
            .then(res => {
                setokstate(res.data.labelValues)
            })
    }, [store.getState().user.uid, updateFlag])

    // table select
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const handleSelectChange = e => {
        setSelectedRowKeys(e)
    }
    const handleClearSelect = e => {
        setSelectedRowKeys([])
    }

    // option
    const [scopeOption, setScopeOption] = useState([])
    const [taskStatusMap, setTaskStatusMap] = useState({})
    const [surveyorOption, setSurveyorOption] = useState([])
    useEffect(() => {
        axios.get('/api/sys/dict/taskStatus').then(res => {
            const arr = Object.entries(res.data.labelValues).map(i => {
                return { value: i[0], text: i[1] }
            })
            setTaskStatusMap(res.data.labelValues)
        })
        axios.get('/api/core/project/' + projectid).then(res => {
            axios.get('/api/sys/user/list', {
                params: {
                    pageSize: 1000000,
                    roleCode: 'shixiao_vistor',
                    groupId: res.data.userGroupId,
                    orgCode: 'shixiao'
                }
            }).then(res => {
                setSurveyorOption(res.data.data)
            })
        })
        axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
            setScopeOption(res.data)
        })
    }, [])

    // select
    const [dateSelect, setDateSelect] = useState(null)
    const [scopeSelect, setScopeSelect] = useState(null)
    const [codeSelect, setCodeSelect] = useState('')
    const [targetSelect, setTargetSelect] = useState('')
    const [vistorSelect, setVistorSelect] = useState(null)
    const [overstate, setoverstate] = useState(null) //完成状态

    const clearSelect = e => {
        setDateSelect(null)
        setScopeSelect(null)
        setCodeSelect('')
        setTargetSelect('')
        setVistorSelect(null)
        setoverstate(null)
        setUpdateFlag(pre => pre + 1)
    }
    //导出
    const handleExport = e => {
        const params = {
            pageSize: 1000000,
            projectId: projectid,
            export: true
        }
        if (dateSelect) params.createdAtFrom = moment(dateSelect[0]).format('YYYYMMDD')
        if (dateSelect) params.createdAtTo = moment(dateSelect[1]).format('YYYYMMDD')
        if (targetSelect) params.surveyTarget = targetSelect
        if (codeSelect) params.surveyCode = codeSelect
        if (vistorSelect) params.principalId = vistorSelect
        if (overstate) params.status = overstate
        axios.get('/api/core/task/innerList', {
            params:{
              ...params,
              userId:store.getState().user.uid
            },
            responseType: 'blob'
        }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "导出进场记录.xlsx");
            document.body.appendChild(link);
            link.click();
        })
    }

    return (
        <div style={{ padding: '30px' }} >
            <div style={{ padding: '30px', background: 'white' }} >
                <Row gutter={[20, 20]} style={{ marginBottom: '20px' }} >
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} > 调研编号 </span>
                        <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
                    </Col>
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} > 调研对象 </span>
                        <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }} > 调研大区 </span>
                        <Select style={{ width: '240px', marginLeft: '10px' }} value={scopeSelect} showSearch onChange={e => setScopeSelect(e)} >
                            <Select.Option value={''} > 取消选择 </Select.Option> {
                                scopeOption.map((i, index) => (
                                    <Select.Option key={index} value={i} > { i} </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} > 访问员 </span>
                        <Select style={{ width: '240px', marginLeft: '26px' }} value={vistorSelect} onChange={e => setVistorSelect(e)} >
                            <Select.Option value={''} > 取消选择 </Select.Option> {
                                surveyorOption.map((i, index) => (
                                    <Select.Option key={index} value={i.id} > { i.name} </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} > 查询时间 </span>
                        <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)} > </DatePicker.RangePicker>
                    </Col>
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} > 进场状态 </span>
                        <Select value={overstate}
                            onChange={(e) => { setoverstate(e) }} style={{ width: '240px', marginLeft: '10px' }} >
                            <Select.Option value={''} > 取消选择 </Select.Option> {
                                Object.keys(okstate).map((v, i) => {
                                    return <Option value={v} > {okstate[v]} </Option>
                                })
                            } </Select>
                    </Col>
                </Row>
                <Row justify='space-between' style={{ marginBottom: '20px' }} >
                    <Col>
                        <Button style={{ margin: '0 10px' }} onClick={handleExport} > 批量导出 </Button>
                    </Col>
                    <Col>
                        <Button style={{ margin: '0 10px' }} type='primary' onClick={getData} > 查询 </Button> 
                        <Button style={{ margin: '0 10px' }} onClick={clearSelect} > 重置 </Button>
                    </Col>
                </Row>

                {
                    selectedRowKeys.length !== 0 ?
                        <Row align='middle'
                            gutter={
                                [20, 0]}
                            style={
                                { marginBottom: '10px' }} >
                            <Col > < Button danger type='primary'
                                onClick={handleClearSelect} > 清空 </Button></Col>
                            <Col > {'已选择' + selectedRowKeys.length + '项'} </Col>
                        </Row> :
                        null
                }
                <Table rowSelection={
                    { selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
                            setPage(page)
                            setPageSize(pageSize)
                          }
                     }}
                />

            </div>
        </div>
    )
}

export default App