import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'
import store from '@/redux/store'
import { baseUrl } from '@/utils/config'
import { connect } from 'echarts/lib/echarts';


function App(props) {

    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    // table
    const columns = [{
        title: '调研编号',
        dataIndex: 'surveyCode',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
        title: '调研大区',
        dataIndex: 'surveyScope',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
    }, {
        title: '访问员',
        dataIndex: 'principal',
        render: text => text ? text : '-',
        sorter: (a, b) => a.principal?.localeCompare(b.principal),
    }, {
        title: '代理人',
        dataIndex: 'agent',
        render: text => text ? text : '-',
        sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },{
        title: '二审人员',
        dataIndex: 'audit2ByName',
        render: text => text ? text : '-',
        sorter: (a, b) => a.agent?.localeCompare(b.agent),
    },
    {
        title: '门店类型',
        dataIndex: 'shopLevel',
        render: text => text ? text : '-',
        sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
    },
    {
        title: '调研对象',
        dataIndex: 'surveyTarget',
        render: text => text ? text : '-',
        sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
    },
    {
        title: '退回原因',
        dataIndex: 'audit2RefusedReason',
        render: (text, record) => {
            let texts = '-'
            if (text != null) {
                texts = text
            }
            if (record.reviewRefusedReason != null) {
                texts = record.reviewRefusedReason
            }
            return texts
        }
    },
    {
        title: '门店地址',
        dataIndex: 'startPointDesc',
        render: text => text ? text : '-',
        sorter: (a, b) => a.startPointDesc?.localeCompare(b.startPointDesc),
    },
    {
        title: '退回备注',
        dataIndex: 'audit2RefusedRemark',
        render: (text, record) => {
            let texts = '-'
            if (text != null) {
                texts = text
            }
            if (record.reviewRefusedRemark != null) {
                texts = record.reviewRefusedRemark
            }
            return texts
        }
    },
    {
        title: '总退回次数',
        dataIndex: 'refusedCount',
        render: text => text ? text : '0',
        sorter: (a, b) => a.refusedCount-b.refusedCount,
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
            <>
                <Button size='small' type='link' onClick={e => push_page(record)}>查看</Button>
                <Divider type='vertical' />
                <Button size='small' type='link' onClick={e => showModal(record, 'affirm')}>确认退回</Button>
                <Divider type='vertical' />
                <Button size='small' type='link' onClick={e => showModal(record, 'cancel')}>申诉提交</Button>
            </>
        )
    },
    ]
    //
    // 查看详情函数
    const push_page = e => {
        sessionStorage.setItem('diaffirm', 'true')
        store.dispatch({
            type: 'selectCache_init', value: {
                page: 'jh-checksecond',
                select: {
                    surveyorSelect,
                    scopeSelect,
                    dsrSelect,
                    codeSelect,
                }
            }
        })
        push('/jh/' + projectid + '/check/particular/' + e.id)
    }

    const [confirmRefusedRemark, setconfirmRefusedRemark] = useState(null)




    // 查看详情后返回的触发函数  定义一个getData_flag用来设置什么时候触发getdata()
    useEffect(() => {
        if (store.getState().selectCache.page === 'jh-checksecond') {
            const data = store.getState().selectCache.select
            Promise.all([
                new Promise(r => setSurveyorSelect(data.surveyorSelect, () => r())),
                new Promise(r => setScopeSelect(data.scopeSelect, () => r())),
                new Promise(r => setDsrSelect(data.dsrSelect, () => r())),
                new Promise(r => setCodeSelect(data.codeSelect, () => r())),
            ]).then(res => {
                setGetData_flag(true)
                store.dispatch({ type: 'selectCache_clear' })
            })
        } else {
            setGetData_flag(true)
            store.dispatch({ type: 'selectCache_clear' })
        }
    }, [])

    const [getData_flag, setGetData_flag] = useState(false)

    const [dataSource, setDataSource] = useState([])
    const [updateFlag, setUpdateFlag] = useState(0)
    const getData = e => {
        const queryCondition = []
        queryCondition.push('IN:taskAudit1Status=31')
        queryCondition.push('EQ:projectId=' + projectid)
        if (surveyorSelect) queryCondition.push('EQ:principalId=' + surveyorSelect)
        if (dsrSelect) queryCondition.push('LIKE:dsrCode=' + dsrSelect)
        if (targetSelect) queryCondition.push('LIKE:surveyTarget=' + targetSelect)
        if (codeSelect) queryCondition.push('LIKE:surveyCode=' + codeSelect)
        if (scopeSelect) queryCondition.push('LIKE:surveyScope=' + scopeSelect)
        if (proxyid) queryCondition.push('EQ:agentId=' + proxyid)
        if (audit2ByIdSelect) queryCondition.push('EQ:audit2ById=' + audit2ByIdSelect)

        axios.get('/api/core/task/list', {
            params: {
                pageSize: 1000000,
                queryCondition: queryCondition.join(','),
                userId: store.getState().user.uid,
                auditFlag: 1
            }
        }).then(res => {
            const arr = res.data.data.map(i => { return { ...i, key: i.id } })
            console.log(arr)
            setDataSource(arr)
        })
    }
    useEffect(() => {
        getData()
        axios.get('/api/core/project/' + projectid).then(res => {
            axios.get('/api/sys/user/list', {
              params: {
                pageSize: 1000000,
                roleCode: 'jiahua_checker',
                groupId: res.data.userGroupId
              }
            }).then(res => {
              setAudit2Option(res.data.data)
            })
            axios.get('/api/sys/user/list', {
                params: {
                    pageSize: 1000000,
                    roleCode: 'jiahua_vistor',
                    groupId: res.data.userGroupId,
                    orgCode: 'jiahua'
                }
            }).then(res => {
                setSurveyorOption(res.data.data)
            })
        })
    }, [store.getState().user.uid, updateFlag, getData_flag])

    // table select
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const handleSelectChange = e => {
        setSelectedRowKeys(e)
    }
    const handleClearSelect = e => {
        setSelectedRowKeys([])
    }

    const [proxyList, setProxyList] = useState([])

    // option
    const [surveyorOption, setSurveyorOption] = useState([])
    const [scopeOption, setScopeOption] = useState([])
    const [audit2Option, setAudit2Option] = useState([])
    
    useEffect(() => {
        axios.get('/api/core/task/scope', { params: { projectId: projectid } }).then(res => {
            setScopeOption(res.data)
        })
        axios.get('/api/core/project/' + projectid).then(res => {
            axios.get('/api/sys/user/list', {
                params: {
                    roleCode: 'jiahua_vistor',
                    pageSize: 1000000,
                    groupId: res.data.userGroupId,
                    orgCode: 'jiahua'
                }
            }).then(resa => {
                axios.get('/api/sys/user/list', {
                    params: {
                        roleCode: 'jiahua_proxy',
                        pageSize: 1000000,
                        groupId: res.data.userGroupId,
                        orgCode: 'jiahua'
                    }
                }).then(ress => {
                    setProxyList(ress.data.data)
                    const user = ress.data.data.filter((v => {
                        return v.id == store.getState().user.uid
                    }))
                    axios.get('/api/sys/user/group/org', {
                        params: {
                            id: user[0]?.id
                        }
                    })
                        .then(resc => {
                            const count = resc.data.filter(v => {
                                return v.groupId == res.data.userGroupId
                            })[0]?.roles.filter(v => {
                                return v.code == 'jiahua_proxy'
                            })
                            if (count?.length > 0) {
                                const visi = resa.data.data.filter(v => {
                                    return v.proxyId == user[0].id
                                })
                                setSurveyorOption([...visi, user[0]])
                            } else {
                                setSurveyorOption([...resa.data.data, ...ress.data.data])
                            }
                        })
                })
            })
        })
    }, [])

    // select
    const [surveyorSelect, setSurveyorSelect] = useState(null)
    const [scopeSelect, setScopeSelect] = useState(null)
    const [dsrSelect, setDsrSelect] = useState('')
    const [targetSelect, setTargetSelect] = useState('')
    const [codeSelect, setCodeSelect] = useState('')
    const [audit2ByIdSelect, setAudit2ByIdSelect] = useState(null)

    const clearSelect = e => {
        setScopeSelect(null)
        setDsrSelect('')
        setTargetSelect('')
        setProxyid(null)
        setCodeSelect('')
        setSurveyorSelect(null)
        setUpdateFlag(pre => pre + 1)
        setAudit2ByIdSelect(null)
    }

    const [isModalVisible, setIsModalVisible] = useState(false);//对话框开关

    const [audit1RefusedRemark, setaudit1RefusedRemark] = useState(null)//备注
    const [audit1RefusedReason, setaudit1RefusedReason] = useState(null)//原因
    const [audit1RefusedReasonType, setaudit1RefusedReasonType] = useState(null)//原因类型

    const [taskid, settaskid] = useState(null)

    const [string, setstring] = useState(null)

    const [title, settitle] = useState(null)
    const showModal = (record, string) => {
        if (string == 'affirm') {
            settitle('确认退回')
            setaudit1RefusedRemark(record.audit2RefusedRemark != '' ? record.audit2RefusedRemark : record.reviewRefusedRemark)
        } else {
            console.log(1)
            settitle('申诉提交')
            setaudit1RefusedRemark('')
        }
        console.log(record)
        setIsModalVisible(true);
        setaudit1RefusedReason(record.audit2RefusedReason != null ? record.audit2RefusedReason : record.reviewRefusedReason)
        setaudit1RefusedReasonType(record.audit2RefusedReasonType != null ? record.audit2RefusedReasonType : record.reviewRefusedReasonType)
        setstring(string)
        settaskid(record.id)
    };

    const handleOk = () => {
        setIsModalVisible(false);
        if (string == 'affirm') affirm(taskid)
        if (string == 'cancel') cancel(taskid)
    };

    //确认退回
    const affirm = (taskId) => {
        console.log(returnbool)
        axios.post('/api/core/jhTaskAudit/audit1Refused', {
            audit1RefusedRemark,
            audit1RefusedReason: returnbool ? '重做' : audit1RefusedReason,
            audit1RefusedReasonType: returnbool ? 9 : audit1RefusedReasonType
        }, {
            params: {
                taskId
            }
        })
            .then(res => {
                message.success('确认退回成功')
                getData()
                setReturnbool(false)
            })
    }

    //取消退回
    const cancel = (taskId) => {
        axios.post('/api/core/jhTaskAudit/audit1Passed', {
            audit1AppealRemark: audit1RefusedRemark
        }, {
            params: {
                taskId
            }
        })
            .then(res => {
                message.success('申诉提交成功')
                getData()
            })
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setReturnbool(false)
    };

    const [returnbool, setReturnbool] = useState(false)

    const [proxyid, setProxyid] = useState(null)

    return (
        <div style={{ padding: '30px' }}>
            <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} destroyOnClose>
                <Row>
                    <Col span='6' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{title != "申诉提交"?'退回备注':'申诉备注'}</Col>
                    <Col span='18'><Input value={audit1RefusedRemark} onChange={e => { setaudit1RefusedRemark(e.target.value) }} ></Input></Col>
                    {
                        title != "申诉提交" ? <>
                            <Col span='6' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>是否重做</Col>
                            <Col span='18' style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <Checkbox checked={returnbool} onChange={e => { setReturnbool(e.target.checked); console.log(e) }}></Checkbox>
                            </Col>
                        </> : null
                    }
                </Row>
            </Modal>
            <div style={{ padding: '30px', background: 'white' }} >

                <Row gutter={[20, 20]} style={{ marginBottom: '20px' }} >
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} >调研编号</span>
                        <Input style={{ width: '240px', marginLeft: '10px' }} value={codeSelect} onChange={e => setCodeSelect(e.target.value)} />
                    </Col>
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} >调研大区</span>
                        <AutoComplete
                            onChange={e => { console.log(e); setScopeSelect(e); }}
                            style={{ width: '240px', marginLeft: '10px' }}
                            options={scopeOption.map(v => { return { value: v } })}
                            value={scopeSelect}
                        />
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>访问员</span>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '240px', marginLeft: '25px' }} value={surveyorSelect} onChange={e => setSurveyorSelect(e)}>
                            <Select.Option value={''}>取消选择</Select.Option>
                            {
                                surveyorOption.map((i, index) => (
                                    <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    {
                        //
                        store.getState().user.role != '代理人' ?
                            <Col span={8}>
                                <span style={{ lineHeight: '30px' }}>代理人</span>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '240px', marginLeft: '25px' }} value={proxyid} onChange={e => setProxyid(e)}>
                                    <Select.Option value={''}>取消选择</Select.Option>
                                    {
                                        proxyList.map((i, index) => (
                                            <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                            : null
                    }
                    <Col span={8}>
                      <span style={{ lineHeight: '30px' }}>二审人员</span>
                      <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      } style={{ width: '240px', marginLeft: '10px' }} value={audit2ByIdSelect} onChange={e => setAudit2ByIdSelect(e)}>
                      <Select.Option value={''}>取消选择</Select.Option>
                        {
                          audit2Option.map((i, index) => (
                            <Select.Option key={index} value={i.id}>{i.name}</Select.Option>
                          ))
                        }
                      </Select>
                    </Col>
                    <Col span={8} >
                        <span style={{ lineHeight: '30px' }} >调研对象</span>
                        <Input style={{ width: '240px', marginLeft: '10px' }} value={targetSelect} onChange={e => setTargetSelect(e.target.value)} />
                    </Col>
                </Row>
                <Row justify='space-between' style={{ marginBottom: '20px' }} >
                    <Col>

                    </Col>
                    <Col>
                        <Button style={{ margin: '0 10px' }} type='primary' onClick={getData} >查询</Button>
                        <Button style={{ margin: '0 10px' }} onClick={clearSelect} >重置</Button>
                    </Col>
                </Row>

                {
                    selectedRowKeys.length !== 0 ?
                        <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }} >
                            <Col >
                                < Button danger type='primary' onClick={handleClearSelect} >清空</Button>
                            </Col >
                            <Col >{'已选择' + selectedRowKeys.length + '项'}</Col>
                        </Row>
                        : null
                }
                <Table
                    rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true }}
                />
            </div>
        </div>
    )
}

export default App