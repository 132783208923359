import React, { useEffect, useState, Component } from 'react';
import { Button, Row, Col, Radio, Input, Modal} from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons'
import axios from '@/utils/axios'
import message from '@/utils/message'

import Head from './component/ParticularHead'
import State from './component/ParticularState'
import Data from './component/ParticularData'
import Maps from './component/ParticularMaps'
import Swiper from './component/ParticularSwiper'
import Audio from './component/ParticularAudio'

class App extends Component {
  constructor() {
    super()

    this.state = {
      answer: {
        answersone: '1',
        answerstwo: '1',
        answersthree: '1',
      },
      data: [],
      questionnaire: [],
      answer: [],
      isModalVisible:false,
      inputValue:'',
    }
  }

  getdata = () => {
    axios.get('/api/core/task/' + this.props.match.params.id)
      .then(res => {
        this.setState({
          data: res.data
        })
        axios.get('/api/core/appTemplateField/getByAppTemplateId',{//小问卷问题
          params:{
            appTemplateId:res.data.appId
          }
        })
        .then(res => {
          var arr = res.data.sort((a,b)=>{
            return a.seq-b.seq
          })
          this.setState({
            questionnaire: arr
          })
        })
        axios.get('/api/core/answer/all', {//小问卷答案
          params: {
            taskId: res.data.id
          }
        }).then(res => {
          this.setState({
            answer: res.data
          })
        })
      })
  }

  componentDidMount() {
    sessionStorage.removeItem('tuihuijh');
    this.getdata()
    // axios.get('/api/core/task/genJhCheckScore/1946?appId=app&appKey=app2020')     //测试算分
    // .then(res=>{
    //   console.log(res)
    // })
  }

  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible:false
    })
  };

  upwentidata= ()=>{
    this.state.answerupdata.value=this.state.inputValue
    axios.post('/api/core/answer/batch',this.state.answer).then(res => {
      this.getwentidata()
      message.success('修改成功')
      this.setState({
        isModalVisible:false
      })
    })
  }

  render() {
    return (
      <>
        <Head data={this.state.data} id={this.state.data.id} getdata={this.getdata}></Head>
        <Row style={{ margin: '20px', marginTop: '40px', background: 'white' }}>
          <Col style={{ padding: '20px' }} span={24}>审核进度</Col>
          <hr color='#ddd' width='100%' height='1px' style={{ margin: '0' }} />
          <State data={this.state.data}></State>
        </Row>
        <Row style={{ margin: '20px', padding: '00 20px 20px', marginTop: '40px', background: 'white' }}>
          <Col style={{ padding: '20px 0' }} span={24}>项目信息</Col>
          <hr color='#ddd' width='100%' height='1px' style={{ margin: '0', position: 'relative', left: '-20px' }} />
          <hr color='#ddd' width='100%' height='1px' style={{ margin: '0', position: 'relative', right: '-20px', top: '-2px' }} />
          <Data data={this.state.data}></Data>
          <p style={{ padding: '10px 0 10px' }}>调研结果</p>
          <Maps data={this.state.data}></Maps>
          <Swiper data={this.state.data}></Swiper>
          <Audio datas={this.state.data}></Audio>
          <Col span='24' style={{ border: '1px solid rgba(233, 233, 233, 1)', borderRadius: '5px', marginTop: '10px' }}>
          <div style={{ padding: '10px 20px', background: 'rgba(250, 250, 250, 1)', borderBottom: '1px solid rgba(233, 233, 233, 1)' }}>
            调查问卷<CloudDownloadOutlined />{/* 未加图片 标记 */}
          </div>
          <Row style={{ padding: '10px 20px' }}>
            <Col span='12' style={{ padding: '10px 20px' ,fontWeight:'550', borderBottom:'2px solid #ddd'}}>
              问卷标题
                </Col>
            <Col span='12' style={{ padding: '10px 20px' ,fontWeight:'550', borderBottom:'2px solid #ddd'}}>
              答案
                </Col>
            {
              JSON.stringify(this.state.questionnaire) != '' ?
                this.state.questionnaire.map((v, i) => {
                  return (
                    <>
                      <Col span='12' style={{ padding: '5px 20px', borderBottom:'1px solid #ddd'}}>
                        {v.fieldName}
                      </Col>
                      <Col span='9' style={{ padding: '5px 20px', borderBottom:'1px solid #ddd' }}>
                        {
                          this.state.answer.lenght != 0 && this.state.answer[i] ?
                            this.state.answer.filter(l=>l.questionId == v.id)[0]?.value 
                          : null
                        }
                      </Col>
                      <Col span='3' style={{ padding: '5px 20px', borderBottom:'1px solid #ddd' }}>
                        <span style={{color:'#53b4ff',fontSize:'10px'}} 
                        onClick={()=>{
                          this.setState({wnetistr:v.fieldName,
                          answerupdata:this.state.answer[i],
                          isModalVisible:true,
                          inputValue:this.state.answer[i].value
                        })
                        }}>修改问卷</span>
                      </Col>
                    </>
                  )

                }) : null
            }

          </Row>
        </Col>
        </Row>

        <Modal title="修改问题" 
          visible={this.state.isModalVisible} 
          onOk={this.upwentidata} 
          onCancel={this.handleCancel}>
          <p>{this.state.wnetistr}</p>
          <Input value={this.state.inputValue} onChange={ e=>this.setState({inputValue:e.target.value}) } ></Input>
        </Modal>
      </>
    )
  }
}

export default App