import React, { useEffect, useState, useRef, } from 'react';
import { HashRouter, Switch, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Collapse, Button, Table, Checkbox, Input, Modal, DatePicker, Upload, Popconfirm } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import { audioUrl, wanggeUrl } from '@/utils/config'
import moment from 'moment'
import message from '@/utils/message'
import muban from '@/assets/gdsc.xlsx'
import PopConfirmOnly from "@/utils/PopConfirmOnly";
import store from '@/redux/store'
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const App = (props) => {

    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    const [list, setlist] = useState([]) //表格数据
    const [projectId, setprojectId] = useState(useParams().projectid) //id
    const [selectedRowKeys, setselectedRowKeys] = useState([]) //复选框选中
    const [tablebool, settablebool] = useState([]) //显示隐藏table
    const [surveyScopelist, setsurveyScopelist] = useState('') // 项目区域数据

    const requestlist = async () => {
        const params = {
            page,
            pageSize,
            projectId: projectId,
        }
        if (seleaddress) params.surveyScope = seleaddress
        if (rens) params.principalId = rens
        if (overstate) params.status = overstate
        if (input1) params.surveyTarget = input1
        if (numberinp) params.surveyCode = numberinp
        if (time.length !== 0) {
            params.createdAtFrom = moment(time[0]).format('YYYYMMDD')
            params.createdAtTo = moment(time[1]).format('YYYYMMDD')
        }

        const res = await axios.get('/api/core/task/innerList', { //数据
            params: {
                ...params,
                userId: store.getState().user.uid
            },
        })
        setTotal(res.data.totalSize)
        const s = await axios.get('/api/sys/dict/' + 'taskStatus')
        setokstate(s.data.labelValues)
        res.data.data.forEach((v, i) => {
            v.key = i + Math.random()
            v.lablelValues = s.data.labelValues
        });
        setlist(res.data.data)
    }

    const [pageSize, setPageSize] = useState(5)//显示数据数量
    const [page, setPage] = useState(1)//数据页数
    const [total, setTotal] = useState(null)//数据总是

    useEffect(() => {
        requestlist()
    }, [pageSize, page])

    const appmubanfun = () => {
        axios.get('/api/core/appTemplate/list', {
            params: {
                pageSize: 1000000
            }
        })
            .then(res => {
                setappmuban(res.data.data)
            })
        axios.get('/api/core/task/scope', { params: { projectId } })
            .then(res => {
                setsurveyScopelist(res.data)
            })
    }

    const [okstate, setokstate] = useState({}) //完成状态

    const [projectData, setprojectData] = useState({})

    useEffect(() => {
        (
            async () => {
                const res = await axios.get('/api/sys/dict/' + 'taskStatus')
                requestlist()
                setokstate(res.data.labelValues)
                appmubanfun()
                const resson = await axios.get('/api/core/project/' + projectId)
                setprojectData(resson.data)
                console.log(resson.data)
            }
        )()
    }, [])

    const alldel = () => {
        let arr = []
        selectedRowKeys.forEach((v, i) => {
            list.forEach((a, b) => {
                if (v == a.key) {
                    arr.push(a.id)
                }
            });
        });
        axios.delete('/api/core/task/batch', {
            data: arr
        })
            .then(res => {
                requestlist()
            })
    }

    const removedata = (record) => {
        axios.delete('/api/core/task/' + record.id)
            .then(res => {
                requestlist()
                message.success('删除成功')
            })
    }

    const [columns, setColumns] = useState([ //table样式
        {
            title: '调研编号',
            dataIndex: 'surveyCode',
            key: 'surveyCode',
            sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
        },
        {
            title: '项目区域',
            dataIndex: 'surveyScope',
            key: 'surveyScope',
            sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
            render: (text, record) => (<
                > {
                    text ? text : '- -'
                }</>
            )
        },
        {
            title: '调研对象',
            dataIndex: 'surveyTarget',
            key: 'surveyTarget',
            sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
            render: (text, record) => (<
                > {
                    text ? text : '- -'
                }</>
            )
        },
        {
            title: '访问员',
            dataIndex: 'principal',
            key: 'principal',
            render: (text, record) => (<> {
                text ? text : '- -'
            }</>
            ),
            sorter: (a, b) => a.principal?.localeCompare(b.principal),
        },
        {
            title: '代理人',
            dataIndex: 'agent',
            key: 'agent',
            render: (text, record) => (<
                > {
                    text ? text : '- -'
                }</>
            ),
            sorter: (a, b) => a.agent?.localeCompare(b.agent),
        },
        {
            title: '完成状态',
            key: 'status',
            render: (text, record) => (<
                > {
                    record.lablelValues != undefined && record.lablelValues != null ? Object.keys(record.lablelValues).map((v, i) => {
                        if (v == record.status) {
                            return Object.values(record.lablelValues)[i]
                        }
                    }) : null

                }</>
            )
        },
        {
            title: '得分',
            dataIndex: 'checkScore',
            key: 'checkScore',
            sorter: (a, b) => a.checkScore?.localeCompare(b.checkScore),
            render: (text, record) => (<
                > {
                    text ? (text * 100).toFixed(1) : '- -'
                }</>
            )
        },
        {
            title: '照片',
            dataIndex: 'issueCount',
            key: 'issueCount',
            render: (text, record) => (<> {
                text ? text : '- -'
            }</>
            )
        },
        {
            title: '录音',
            dataIndex: 'recordCount',
            key: 'recordCount',
            render: (text, record) => (<
                > {
                    text ? text : '- -'
                }</>
            )
        },
        {
            title: '操作',
            key: 'key',
            render: (text, record) => (<>
                <span style={{ margin: '0 10px', color: '#3ab6fe' }} onClick={() => { updatalist(record) }}> 修改</span>
                <PopConfirmOnly confirm={e => removedata(record)}>
                    <Button type='link' size='small'> 删除</Button>
                </PopConfirmOnly>
            </>
            )
        }
    ])
    //

    const handleSelectChange = e => {
        setselectedRowKeys(e)
    }

    const handleClearSelect = () => {
        setselectedRowKeys([])
    }

    const [visible, setvisible] = useState(false)
    const [addandupdatabool, setaddandupdatabool] = useState(false)

    const showModal = () => {
        setvisible(true)
        setaddandupdatabool(true)
    };


    const [surveyCode, setsurveyCode] = useState('') //调研编号
    const [surveyTarget, setsurveyTarget] = useState('') //调研对象
    const [code, setcode] = useState('') //工作单号
    const [surveyScope, setsurveyScope] = useState('') //项目区域
    const [agent, setagent] = useState('') //代理人
    const [agentId, setagentId] = useState('')//代理人ID
    const [startPointDesc, setstartPointDesc] = useState('') //起始地点
    const [endPointDesc, setendPointDesc] = useState('') //结束地点
    const [startPoint, setstartPoint] = useState('') //起始地点x y轴
    const [endPoint, setendPoint] = useState('') //结束地点x y轴
    const [appId, setappId] = useState('') //appid
    const [description, setdescription] = useState('')
    const [targetId, settargetId] = useState('')

    const [updataid, setupdataid] = useState(null)

    const handleOk = e => {
        if (surveyCode != '' && surveyTarget != '' && surveyScope != '' &&
            dlsdate.length != 0 && description != '') {
            if (addandupdatabool) {
                if (!appId) return
                axios.post('/api/core/task', {
                    projectId,
                    surveyCode,
                    surveyTarget,
                    surveyScope,
                    startPointDesc,
                    endPointDesc,
                    planStartAt: moment(dlsdate[0]._d).format('YYYY-MM-DD HH:mm:ss.SSS'),
                    planEndAt: moment(dlsdate[1]._d).format('YYYY-MM-DD HH:mm:ss.SSS'),
                    agent,
                    agentId,
                    appId,
                    description,
                    targetId,
                    startPointDesc,
                    endPointDesc,
                    startPoint,
                    endPoint
                }, { params: { type: 2 } }).then(res => {
                    requestlist()
                })
            } else {
                axios.put('/api/core/task/updCms', {
                    id: updataid,
                    surveyCode,
                    surveyTarget,
                    surveyScope,
                    startPointDesc,
                    endPointDesc,
                    planStartAt: moment(dlsdate[0]._d).format('YYYY-MM-DD HH:mm:ss.SSS'),
                    planEndAt: moment(dlsdate[1]._d).format('YYYY-MM-DD HH:mm:ss.SSS'),
                    agent,
                    agentId,
                    appId,
                    description,
                    targetId,
                    startPoint,
                    endPoint
                }, { params: { type: 2 } }).then(res => {
                    requestlist()
                })
            }
            setvisible(false)
            setsurveyCode('')
            setsurveyTarget('')
            setsurveyScope('')
            setstartPointDesc('')
            setendPointDesc('')
            setagent('')
            setagentId('')
            setstartPoint('')
            setendPoint('')
            setdlsdate([])
            setappId('')
            setdescription('')
            settargetId('')
        } else {
            message.error('请完整填写信息,再次提交')
        }
    };

    const handleCancel = e => {
        setvisible(false)
        setsurveyCode('')
        setsurveyTarget('')
        setsurveyScope('')
        setstartPointDesc('')
        setendPointDesc('')
        setagent('')
        setagentId('')
        setstartPoint('')
        setendPoint('')
        setdlsdate([])
        setappId('')
        setdescription('')
        settargetId('')
    };

    const updatalist = (record) => {
        axios.get('/api/core/task/' + record.id)
            .then(res => {
                setupdataid(res.data.id)
                setsurveyCode(res.data.surveyCode) //调研编号
                setsurveyTarget(res.data.surveyTarget) //调研对象
                setsurveyScope(res.data.surveyScope)
                setstartPointDesc(res.data.startPointDesc)
                setendPointDesc(res.data.endPointDesc)
                setdlsdate([moment(res.data.planStartAt), moment(res.data.planEndAt)])
                setagent(res.data.agent)
                setagentId(res.data.agentId)
                setaddandupdatabool(false)
                setvisible(true)
                setstartPoint(res.data.startPoint)
                setendPoint(res.data.endPoint)
                setappId(res.data.appId)
                setdescription(res.data.description)
                settargetId(res.data.targetId)
            })
    }


    const [time, settime] = useState('') //时间/
    const [overstate, setoverstate] = useState(null) //完成状态
    const [seleaddress, setseleaddress] = useState(null) //调研区域
    const [input1, setinput1] = useState('') //调研对象
    const [numberinp, setnumberinp] = useState('') //调研编号
    const [rens, setrens] = useState(null) //访问员

    const [dlsdate, setdlsdate] = useState([]) // 用于控制时间的
    const [appmuban, setappmuban] = useState([]) //app模板

    useEffect(() => {
        if (!time && !overstate && !seleaddress && !input1 && !numberinp && !rens) {
            requestlist()
        }
    }, [time, overstate, seleaddress, input1, numberinp, rens, visible])

    const resetfrom = (e) => {
        settime('')
        setoverstate(null)
        setseleaddress(null)
        setinput1('')
        setnumberinp('')
        setrens(null)
    }

    const [loadvisible, setloadvisible] = useState(false)
    const [fileListAdd, setFileListAdd] = useState([])

    const loadhandleOk = () => {
        const formData = new FormData()
        formData.append('file', fileListAdd[0])
        axios.post('/api/core/task/import',
            formData, {
            params: { projectId },
        })
            .then(res => {
                message.success()
                requestlist()
            })
        setloadvisible(false)
    }

    const loadhandleCancel = () => {
        setloadvisible(false)
    }

    const uploadProps = { //导入按钮属性
        onRemove: (file) => {
            setFileListAdd([])
            return []
        },
        beforeUpload: (file) => {
            setFileListAdd([file])
            return false
        }
    }

    const exports = () => {
        axios.get('/api/core/task/innerList', {
            params: {
                projectId: projectId,
                pageSize: 1000000,
                export: true,
                userId: store.getState().user.uid
            },
            responseType: 'blob'
        }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "导出工单列表.xlsx");
            link.click();
        })
    }

    const updata = e => {
        const arr = []
        list.forEach(v => {
            if (JSON.stringify(selectedRowKeys).indexOf(v.key) != -1) arr.push(v.id)
        })
        axios.put('/api/core/task/updApp/batch',
            arr
            , {
                params: {
                    appTempId: appId
                }
            })
            .then(res => {
                message.success('修改完成')
                setIsModalVisible(false);
                setappId(null)
            })
    }
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModalApp = () => {
        setIsModalVisible(true);
    };

    const handleCancelApp = () => {
        setIsModalVisible(false);
        setappId(null)
    };


    const ExportPlop = e => {
        axios.get('/api/core/project/export/taskData', {
            params: {
                projectId: projectid
            },
            responseType: 'blob'
        }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", projectData.name + ".xlsx");
            document.body.appendChild(link);
            link.click();
        })
    }

    // const [shuju, setShuju] = useState([])//注释的都是网格
    // const [xiangmus, setXiangmus] = useState([])
    // const [zhibiaos, setZhibiaos] = useState([])

    // const [wanggeBools, setWanggeBools] = useState(false)

    // const wanggeUpload = () => {
    //     setWanggeBools(true)
    //     axios.get('/api/core/project/' + projectid) // 项目参数
    //     .then(xiangmu => {
    //         setXiangmus(xiangmu.data)
    //         axios.get('/api/core/metric/list', { // 指标参数
    //             params: {
    //                 metricGroupId: xiangmu.data.metricGroupId,
    //                 pageSize: 10000
    //             }
    //         })
    //         .then(zhibiao => {
    //             setZhibiaos(zhibiao.data.data)
    //         })
    //         axios.get('/api/core/task/list',{// 所有工单
    //             params:{
    //                     queryCondition: 'EQ:projectId=' + projectid,
    //                     pageSize:100000
    //             }
    //         }) 
    //         .then(res => {
    //             const brr = []
    //             res.data.data.forEach(v=>{
    //                 axios.get('/api/core/issue/list', {//轮播图片
    //                     params: {
    //                         queryCondition: 'EQ:taskId=' + v.id,
    //                         pageSize: 10000,
    //                     }
    //                 })
    //                 .then(res => {
    //                     const arr = res.data.data.filter(v => {
    //                         return v.status == 2 && (v.description.includes('新增') || v.description.includes('未整改'))
    //                     })
    //                     if(arr.length != 0 && !v.surveyCode.includes('重做')){
    //                         brr.push({gongdan:v,tupian:arr})
    //                     }
    //                 })
    //             })
    //             setShuju(brr)
    //             console.log(brr)
    //         })
    //     })
    // }

    // const onOk = () => {
    //     shuju.forEach((a, i) => {
    //         a.tupian.forEach(v=>{
    //             const metric = zhibiaos.filter(a => {
    //                 return a.description == v?.description
    //             })
    //             const metricarr = zhibiaos.filter(v => {
    //                 return metric[0]?.metricCode.includes(v.metricCode)
    //             })
    //             const wanggeData = {
    //                 "channel": "虹桥管商务区", //数据来源，场景发现步骤⽆无需提交
    //                 "jkId": a.gongdan?.surveyCode + v.id, //工单编号
    //                 "token": "31415926535897932381112", // ? 没有填写信息
    //                 "title": v?.description, // 指标名称
    //                 "address": v?.addressDesc+v?.addressManualDesc, // 经纬度
    //                 "createTime": v?.createdAt, // 图片时间
    //                 "createTimestamp": moment(v?.createdAt).valueOf(), // 图片时间戳
    //                 "level": "2", // 严重程度 默认为 2
    //                 "levelDesc": "一般警告", // 严重程度 默认为 一般警告
    //                 "nowStep": 1, // 当前流程所处节点  默认为 1  
    //                 "source": { // 来源场景信息
    //                     "name": "虹桥管商务区",
    //                     "sourceId": '035',
    //                     "Id": '035',
    //                     "partsOrEvent": "部件",
    //                     "actionType": "主动发现",
    //                 },
    //                 "category": {
    //                     "level1": metricarr.length != 0 ? metricarr.filter(v => v.parentCode == '')[0]?.description : '',
    //                     "level2": metricarr.length != 0 ? metricarr.filter(v => v.parentCode == metricarr.filter(v => v.parentCode == '')[0].metricCode)[0].description : '',
    //                     "level3": v?.description
    //                 },
    //                 "location": { // 上报定位
    //                     "coordX": v?.address?.split(',')[0], // 城市建设坐标 
    //                     "coordY": v?.address?.split(',')[1], // 城市建设坐标 
    //                     "city": "上海", // 市 
    //                     "cityId": "3101", // 市的 id
    //                     "district": a.gongdan.surveyScope, // 区 
    //                     "districtId": a.gongdan.surveyCode.substring(a.gongdan.surveyCode.length - 2, a.gongdan.surveyCode.length), // 区的id
    //                 },
    //                 "eventProcessList": [{ // 存放当前步骤记录
    //                     "caption": "发现",  // 处于哪个环节名称  默认 发现
    //                     "step": "1", // 1:发现， 2:立案， 3:派遣， 4: 处置 5:核查， 6:结案  默认 1
    //                     "createDate": moment().format("YYYY-MM-DD HH:mm:ss"), // 该流程创建时间  默认 数据提交时间
    //                     "timestamp": moment(moment().format("YYYY-MM-DD HH:mm:ss")).valueOf(), // 该流程创建时间  默认 数据提交时间戳
    //                     "person": {  // 人员信息
    //                         "name": "虹桥管委会上报", // 该流程处理人员名称  默认 虹桥管委会上报
    //                     },
    //                     "stepSource": "旭中", // 该流程数据来源  默认 旭中
    //                     "reporterAnonymous": "Y", // 是否匿名  Y/N 默认 Y
    //                     "reportercallback": "Y", // 是否回访  Y/N 默认 Y
    //                     "preEndingTime": "", // 该步骤的超期时间 可以填空 默认是空
    //                     "preEndingrimestamp": "", // 该步骤的超期时间戳 可以填空 默认是空
    //                     "multimedias": [ // 上报多媒体信息 图片地址 可以填 空
    //                         'http://10.0.69.180/Images/'+v.id+'.jpeg',
    //                     ],
    //                 }
    //                 ],
    //                 "hisEventprocessList": [] // 空
    //             }
    //             // console.log(i)
    //             toImg(v.before,wanggeData)
    //         })
    //     });
    //     setShuju(JSON.parse(JSON.stringify(shuju)))
    // }

    const getBase64Image = (img) => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height
        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, img.width, img.height)
        var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase()
        var dataURL = canvas.toDataURL("image/" + ext)
        return dataURL
    }
    
    const toImg = (urlsa,wanggeData) => {
        var image = new Image()
        image.src = audioUrl+urlsa
        image.setAttribute("crossOrigin", "Anonymous")
        // image.setAttribute("crossorigin", " *")
        image.onload = function () {
            var base64 = getBase64Image(image)
           base64 = base64
    
            //转换base64到file
            var file = btof(base64, "test")
            const data = new FormData();
            data.append('file',file)
            data.append('data',JSON.stringify(wanggeData))
            axios({
                method:'post',
                url:wanggeUrl, 
                data,
                headers: {
                    'Content-Type': 'form-data'
                }
            })
            .then(res => {
                message.success(res.message)
            })
            this.setState({
                wanggeBool: false
            })
        }
    }
    
    const btof = (data, fileName) => {
        const dataArr = data.split(",")
        const byteString = atob(dataArr[1])
    
        const options = {
            type: "image/jpeg",
            endings: "native"
        }
        const u8Arr = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
            u8Arr[i] = byteString.charCodeAt(i)
        }
        return new File([u8Arr], fileName + ".jpg", options)
    }

    // const onCancel = () => {
    //     setWanggeBools(false)
    // }

    // const columnsas = [
    //     {
    //         title: '图片id',
    //         dataIndex: 'id',
    //         key: 'id',
    //     },
    //     {
    //         title: '是否上传成功',
    //         dataIndex: 'isUploadBool',
    //         key: 'isUploadBool',
    //         render: (text, record, index) => <div>{text?.length > 50 ? '成功' : text}</div>
    //     },
    // ];
    // //

    return (<div style={{ padding: '30px' }}>
        {
            // <Modal
            //     title={'网格上报情况'}
            //     visible={wanggeBools}
            //     onOk={onOk}
            //     onCancel={onCancel}
            //     cancelText='取消'
            //     width="50%"
            //     destroyOnClose={true}
            //     maskClosable={false}
            // >
            //     <Collapse>
            //     {
            //         shuju.map((v,i)=>{
            //             return <Panel header={v.gongdan.surveyCode + ',' + v.gongdan.surveyTarget} key={i}>
            //                 <Table columns={columnsas} dataSource={v.tupian} />
            //             </Panel>
            //         })
            //     }
            //     </Collapse>
            // </Modal>
        }
        <Modal title="批量修改App模板" visible={isModalVisible} onOk={updata} onCancel={handleCancelApp}>
            <Row>
                <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>App模板:</Col>
                <Col span='16'>
                    <Select value={appId} onChange={(e) => { setappId(e) }} style={{ width: '100%', }}> {
                        appmuban.map((v, i) => {
                            return <Option value={v.id}> {v.appTemplateName}</Option>
                        })
                    }</Select>
                </Col>
            </Row>
        </Modal>
        <Modal title={addandupdatabool ? '新增' : '修改'}
            visible={visible}
            onOk={() => { handleOk() }}
            onCancel={() => { handleCancel() }}
            okText={addandupdatabool ? '新增' : '修改'}
            cancelText='取消'
            destroyOnClose={true}
            maskClosable={false}>
            <Row>
                {/* <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>工单编号:</Col>
                <Col span='16'><Input value={code} onChange={(e) => { setcode(e.target.value) }} placeholder="请输入工单编号" /></Col>

                <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>调研编号:</Col>
                <Col span='16'><Input value={surveyCode} onChange={(e) => { setsurveyCode(e.target.value) }} placeholder="请输入调研编号" /></Col>

                <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>调研对象:</Col>
                <Col span='16'><Input value={surveyTarget} onChange={(e) => { setsurveyTarget(e.target.value) }} placeholder="请输入调研对象" /></Col>

                <Col span='8'
                    style={
                        { textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>项目区域:</Col>
                <Col span='16'><Input value={surveyScope} onChange={(e) => { setsurveyScope(e.target.value) }} placeholder="请输入项目区域" /></Col> */}

                <Col span='8'
                    style={
                        { textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>位置选择:</Col>
                <Col span='16'>
                    <Select showSearch style={{ width: 200 }}
                        placeholder="请选择地点"
                        optionFilterProp="children"
                        value={targetId}
                        filterOption={(input, option) => JSON.stringify(option.children).indexOf(input.toLowerCase()) >= 0}
                        onChange={(e) => {
                            const address = props.locationlist.filter(v => {
                                return v.id == e
                            })
                            settargetId(e)
                            setsurveyScope(address[0].surveyScope)
                            setsurveyCode(address[0].shopCode)
                            setcode(address[0].code)
                            setsurveyTarget(address[0].shopName)
                            setstartPointDesc(address[0].address)
                            setendPointDesc(address[0].locationEnd)
                            setstartPoint(address[0].latitude + ',' + address[0].longitude)
                            setendPoint(address[0].latitudenEnd + ',' + address[0].longitudeEnd)
                        }} style={{ width: '100%', }}> {
                            props.locationlist.map((v, i) => {
                                return <Option key={v.id}
                                    value={v.id}> {v.locationCode}</Option>
                            })
                        }</Select>
                </Col>
                <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>起始/结束时间:</Col>
                <Col span='16'>
                    <DatePicker.RangePicker
                        showTime={false}
                        ranges
                        value={dlsdate}
                        onChange={(e) => { setdlsdate(e) }}
                        style={{ width: '100%', }}
                        disabledDate={(current) => {
                            return current < moment(projectData.startDate) || current > moment(projectData.endDate)// 选择时间要大于等于当前天。若今天不能被选择，去掉等号即可。
                        }}
                    >
                    </DatePicker.RangePicker></Col>

                <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>App模板:</Col>
                <Col span='16'>
                    <Select value={appId} onChange={(e) => { setappId(e) }} style={{ width: '100%', }}> {
                        appmuban.map((v, i) => {
                            return <Option value={v.id}> {v.appTemplateName}</Option>
                        })
                    }</Select>
                </Col>

                <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>代理人:</Col>
                <Col span='16'>
                    <Select value={agent} onChange={(e) => { setagent(JSON.parse(e).name); setagentId(JSON.parse(e).id) }} style={{ width: '100%', }}> {
                        props.daililist.map((v, i) => {
                            return <Option value={JSON.stringify(v)}> {v.name}</Option>
                        })
                    }</Select>
                </Col>

                <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>描述:</Col>
                <Col span='16'>
                    <TextArea rows={4} showTime value={description} onChange={(e) => { setdescription(e.target.value) }} style={{ width: '100%', }} />
                </Col>
            </Row>
        </Modal> { /* 上面那个是添加的模态框 */}
        <Modal title='批量导入'
            visible={loadvisible}
            onOk={
                () => { loadhandleOk() }}
            onCancel={
                () => { loadhandleCancel() }}
            okText='导入'
            cancelText='取消'
            destroyOnClose={true}
            maskClosable={false}>
            <Row>
                <Col span='6' style={{ textAlign: 'right', lineHeight: '30px' }}><font color='red'> *</font>导入工单:</Col>
                <Col span='12'><Upload {...uploadProps}><Button> 点击导入</Button></Upload></Col>
                <Col span='6' style={{ textAlign: 'right' }}><Button href={muban}> 下载模板</Button></Col>
            </Row>
        </Modal>
        <div style={
            { padding: '30px', background: 'white' }}>
            <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
                <Col span={8}>
                    <span style={{ lineHeight: '30px' }}> 调研编号</span>
                    <Input value={numberinp} onChange={(e) => { setnumberinp(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
                </Col>
                <Col span={8}>
                    <span style={
                        { lineHeight: '30px' }}> 项目区域</span>
                    <Select value={seleaddress} onChange={(e) => { setseleaddress(e) }} showSearch style={{ width: '240px', marginLeft: '10px' }}>
                        <Select.Option value={''}>取消选择</Select.Option>
                        {
                            Object.values(surveyScopelist).map((v, i) => {
                                return <Option value={v}> {v}</Option>
                            })
                        }
                    </Select>
                </Col>
                <Col span={8}>
                    <span style={{ lineHeight: '30px' }}> 调研对象</span>
                    <Input value={input1} onChange={(e) => { setinput1(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
                </Col>

                <Col span={8}>
                    <span style={{ lineHeight: '30px', marginRight: '16px' }}> 访问员</span>
                    <Select
                        showSearch
                        filterOption={(input, option) =>{
                            console.log(option)
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        }
                        value={rens} onChange={(e) => { setrens(e) }} style={{ width: '240px', marginLeft: '10px' }}>
                        <Select.Option value={''}>取消选择</Select.Option>
                        {
                            props.userlist.map((v, i) => {
                                return <Option key={i} value={v.id}>{v.name}</Option>
                            })
                        }
                    </Select>
                </Col>

                <Col span={8}>
                    <span style={
                        { lineHeight: '30px' }}> 完成状态</span>
                    <Select value={overstate}
                        onChange={
                            (e) => { setoverstate(e) }}
                        style={
                            { width: '240px', marginLeft: '10px' }}>
                        <Select.Option value={''}>取消选择</Select.Option>
                        {
                            Object.keys(okstate).map((v, i) => {
                                return <Option value={v}> {okstate[v]}</Option>
                            })
                        }</Select>
                </Col>

                <Col span={8}>
                    <span style={{ lineHeight: '30px' }}> 查询时间</span>
                    <DatePicker.RangePicker value={time} onChange={(e) => { settime(e) }} style={{ width: '240px', marginLeft: '10px' }}></DatePicker.RangePicker>
                </Col>




            </Row>
            <Row justify='space-between' style={{ marginBottom: '20px' }}>
                <Col>
                    <Button type='primary' style={{ margin: '0 10px' }} onClick={e => showModal()}>< PlusOutlined /> 添加工单</Button>
                    <Button type='primary' style={{ margin: '0 10px' }} onClick={() => { setloadvisible(true) }}> 批量上传</Button>
                    <Button style={{ margin: '0 10px' }} onClick={ExportPlop} >导出所有工单数据</Button>
                    <PopConfirmOnly confirm={alldel}>
                        <Button style={{ margin: '0 10px' }}> 批量删除</Button>
                    </PopConfirmOnly>
                    <Button style={{ margin: '0 10px' }} onClick={() => { exports() }}> 批量导出</Button>
                    <Button style={{ margin: '0 10px' }} onClick={() => { showModalApp() }}> 批量修改App模板</Button>
                    {
                        // xiangmus.name?.includes('网格系统')?
                        //     <Button style={{ margin: '0 10px' }} onClick={() => { wanggeUpload() }}> 批量网格上传</Button>
                        // :null
                    }
                </Col>
                <Col>
                    <Button type='primary' style={{ margin: '0 10px' }} onClick={() => { requestlist() }}> 查询</Button>
                    <Button style={{ margin: '0 10px' }} onClick={resetfrom}> 重置</Button>
                    <Button style={{ margin: '0 10px' }} onClick={() => { settablebool(!tablebool) }}> 收起</Button>
                </Col>
            </Row>

            {
                selectedRowKeys.length !== 0 ?
                    <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
                        <Col>< Button danger type='primary'
                            onClick={handleClearSelect}> 清空</Button></Col>
                        <Col> {'已选择' + selectedRowKeys.length + '项'}</Col>
                    </Row> : null
            } {
                tablebool ? <Table
                    rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
                    dataSource={list}
                    columns={columns}
                    pagination={{
                        defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true, total, current: page, onChange: (page, pageSize) => {
                            setPage(page)
                            setPageSize(pageSize)
                        }
                    }}
                /> : null
            }</div>
    </div>
    )
}

export default App