import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu, Dropdown, Tabs } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'


function App(props) {

  const { push } = useHistory()
  const { projectid, surveyScope, principalId } = useRouteMatch().params

  const [curTab, setCurTab] = useState('table')

  const columns = [
    {
      title: '访问员',
      dataIndex: 'principal',
      render: (text,recort) => text ? text : '-',
      sorter: (a, b) => a.principal?.localeCompare(b.principal),
    },
    {
      title: '工单编号',
      dataIndex: 'surveyCode',
      render: text => text ? text : '-',
      sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
      title: '开始时间',
      dataIndex: 'workAt',
      render: (text, record) => text ? moment(text).format('YYYY-MM-DD') : '-'
    },
    {
      title: '工作时长',
      dataIndex: 'work_time',
      render: (text,record) => {
        return moment(record.doneAt).diff(moment(record.workAt), 'minute') + '分钟'
      }
    },
    {
      title: '照片数量',
      dataIndex: 'picCount',
      render: text => text ? text : '-',
    },
    {
      title: '不合格照片数量',
      dataIndex: 'unQualifiedPicCount',
      render: text => text ? text : '-'
    },
  ]
  const [dataSource, setDataSource] = useState([])
  const [updateFlag, setUpdateFlag] = useState(0)
  const getData = e => {
    axios.get('/api/sxStatistic/surveyScopePrincipalStatisticItem', {
      params: {
        pageSize: 1000000,
        projectId: projectid,
        surveyScope,
        principalId,
      }
    }).then(res => {
      const arr = res.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }
  useEffect(() => {
    getData()
  }, [updateFlag])

  return (
    <>
      <div style={{ padding: '30px' }}>
        <div style={{ padding: '30px', background: 'white' }}>

          <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          </Row>

          {
            curTab === 'table' ?
              <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true, showSizeChanger: true  }} />
              : null
          }
        </div>
      </div>

    </>
  )
}

export default App
