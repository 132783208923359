import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import PopConfirmOnly from "@/utils/PopConfirmOnly";

function App(props) {

  const [columns, setColumns] = useState([
    {
      title: '用户名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '部门',
      dataIndex: 'department',
      key: 'department'
    },
    {
      title: '职位',
      dataIndex: 'job',
      key: 'job'
    },
    {
      title: '日志信息',
      dataIndex: 'message',
      key: 'message'
    },
    {
      title: 'IP',
      dataIndex: 'IP',
      key: 'IP'
    },
    {
      title: '记录日期',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action', 
      render: text => (
        <>
          <PopConfirmOnly confirm={() => {}}>
            <Button size='small' type='link'>删除</Button>
          </PopConfirmOnly>
        </>
      )
    },
  ]

  )
  const [dataSource, setDataSource] = useState([
    {
      name: '12222223',
      department: 'mss',
      job: 'job is ...',
      message: 'this is message',
      IP:'192.168.2.3',
      time:'2018-9-6'
    },
    {
      name: '123',
      department: 'mss',
      job: 'job is ...',
      message: 'this is message',
      IP:'192.168.2.3',
      time:'2018-9-6'
    },
    {
      name: '123',
      department: 'mss',
      job: 'job is ...',
      message: 'this is message',
      IP:'192.168.2.3',
      time:'2018-9-6'
    },
  ])

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            <span style={{ lineHeight: '30px' }}>项目状态</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }}></DatePicker.RangePicker>
          </Col>
          <Col>
            <span style={{ lineHeight: '30px' }}>用户名</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder="请输入用户名"></Input>
            <Button style={{margin: '0 10px'}} type='primary'>查询</Button>
            <Button style={{margin: '0 10px'}}>重置</Button>
          </Col>
          <Col>
            {/* <Button style={{margin: '0 10px'}} style={{ marginLeft: 'auto' }}>导出列表</Button> */}
          </Col>
        </Row>
        
        <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />

      </div>
    </div>
  )
}

export default App