import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, message, Modal, InputNumber  } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'
import PopConfirmOnly from "@/utils/PopConfirmOnly";

const { Option } = Select;
const { RangePicker } = DatePicker;

function App(props) {
    const { push } = useHistory()
    const { projectid } = useRouteMatch().params

    const [columns, setColumns] = useState([
        {
            title: '问卷名称',
            dataIndex: 'title',
            render: (text, record, index) => (
                text
            ),
        },
        {
            title: '操作',
            dataIndex: 'projectId',
            render: (text, record, index) => (
                <>
                    <Button type='link' size='small' onClick={e => { 
                        if(record.status==1){
                            push('/wj/' + projectid + '/work/statistical/' + record.id)
                        }else{
                            message.error('问卷发布后才可以查看统计')
                        }
                     }}>查看答题统计</Button>
                     <Button type='link' size='small' onClick={e => { Export(record) }}>导出答题信息</Button>
                </>
            ),
        }
    ])
    {//
    }

    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        getdata()
    }, [])

    const Export = (record) => {
        axios.get('/api/question/template/export/report',{
            params:{
                questionTempId:record.id
            },
			responseType: 'blob'
        })
        .then(res=>{
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", record.title+''+moment().format("YYYY-MM-DD HH:mm:ss")+".xlsx");
            document.body.appendChild(link);
            link.click();
        })
    }

    const getdata = () => {
        axios.get('/api/question/template/all', {
            params: {
                projectId: projectid,
                pageSize:10000
            }
        })
        .then(res => {
            setDataSource(res.data.data)
        })
    }
    
    return (
        <div style={{ padding: '30px' }}>
            <div style={{ padding: '30px', background: 'white' }}>

                <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
                    <Col>
                        {/* <Button>导出列表</Button> */}
                    </Col>
                </Row>

                <Table dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />

            </div>
        </div>
    )
}

export default App
