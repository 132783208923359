import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, message } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import moment from 'moment'
import store from '@/redux/store'


function App(props) {
  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [columns, setColumns] = useState([
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        text
      ),
    },
    {
      title: '问卷id',
      dataIndex: 'questionTemplateId',
      key: 'questionTemplateId',
      render: (text, record, index) => (
        text
      ),
    },
    {
      title: '答题人信息',
      dataIndex: 'clientName',
      key: 'clientName',
      render: (text, record, index) => (
        text
      ),
    },
    {
      title: '操作',
      dataIndex: 'projectId',
      key: 'projectId',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={e => { push('/wj/' + projectid + '/work/particulars/' + record.id) }}>查看答题详情</Button>
          <Button type='link' size='small' onClick={e => { datadelete(record) }}>删除</Button>
        </>
      ),
    }
  ])
  {//
  }
  const [dataSource, setDataSource] = useState([])

  const getdata = () => {
    const queryCondition = ['EQ:projectId=' + projectid]
    if(clientName)queryCondition.push("LIKE:clientName="+clientName)
    if(id)queryCondition.push("EQ:id="+id)
    
    axios.get('/api/question/answer/all', {
      params: {
        queryCondition: queryCondition.join(','),
        pageSize: 10000
      }
    })
      .then(res => {
        console.log(res)
        setDataSource(res.data.data)
      })
  }

  useEffect(() => {
    getdata()
  }, [])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const datadelete = (record) => {
    axios.delete('/api/question/answer/delete/' + record.id)
      .then(res => {
        message.success('删除成功')
        getdata()
      })
  }

  const datadeletes = () => {
    selectedRowKeys.forEach(v => {
      axios.delete('/api/question/answer/delete/' + v)
        .then(res => {
          getdata()
        })
    })
  }

  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  const handleSelectChange = e => {
    console.log(e)
    setSelectedRowKeys(e)
  }

  const [modalVisible, setModalVisible] = useState(null)
  const handleModalCancel = e => {
    setModalVisible(null)
  }

  const exportData = () => {
    axios.get('/api/question/template/answer/detail', {
      params: {
        export: true,

      }
    })
  }

  const [clientName,setClientName] = useState(null)
  const [id,setId] = useState(null)

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>
      <Row style={{ marginBottom: '20px' }}>
          <Col span={8}>
              <span style={{ lineHeight: '30px' }}>问卷id</span>
              <Input value={id} onChange={(e) => { setId(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
          </Col>
          <Col span={8}>
              <span style={{ lineHeight: '30px' }}>微信名</span>
              <Input value={clientName} onChange={(e) => { setClientName(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
          </Col>
        </Row>

        <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col>
            <Button onClick={() => { datadeletes() }}>批量删除</Button>
          </Col>
          <Col>
            <Button type='primary' style={{ margin: '0 10px' }} onClick={() => { getdata() }}>查询</Button>
            <Button onClick={() => { exportData() }}>导出列表</Button>
          </Col>
        </Row>
        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }

        <Table rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }} rowKey={record => record.id} dataSource={dataSource} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />

      </div>
    </div>
  )
}

export default App
