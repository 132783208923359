import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, useHistory , useRouteMatch} from 'react-router-dom'

import overView from './overview/index'

import WorkAssign from  './work/WorkAssign'
import WorkList from  './work/WorkList'
import WorkMessage from  './work/WorkMessage'

import CheckAgain from  './check/CheckAgain'
import CheckBack from  './check/CheckBack'
import CheckFirst from './check/CheckFirst'
import CheckList from  './check/CheckList'
import CheckPlan from  './check/CheckPlan'
import CheckRecord from './check/CheckRecord'
import CheckSecond from  './check/CheckSecond'
import CheckParticular from './check/CheckParticular'
import CheckCompleted from './check/CheckCompleted'

import SetLog from './set/SetLog'
import SetPeople from './set/SetPeople'
import SetProduct from './set/SetProduct'
import SetShop from './set/SetShop'
import SetTarget from './set/SetTarget'
import SetLocation from './set/SetLocation'

import DataFirst from './data/DataFirst'
import DataFirstDetail from './data/DataFirstDetail'
import DataFirstDetails from './data/DataFirstDetails'
import DataSecond from './data/DataSecond'
import DataVisitor from './data/DataVisitor'
import DataVistorDetail from './data/DataVistorDetail'
import DataVisitorDetails from './data/DataVisitorDetails'
import DataVisitorDetailDeb from './data/DataVisitorDetailDeb'

function App(props) {
  return(
    <>
      <Route path="/sx/:projectid/index" component={overView} />
      <Route path="/sx/:projectid/work/list" component={WorkList} />
      <Route path="/sx/:projectid/work/assign" component={WorkAssign} />
      <Route path="/sx/:projectid/work/message" component={WorkMessage} />

      <Route path="/sx/:projectid/check/plan" component={CheckPlan} />
      <Route path="/sx/:projectid/check/back" component={CheckBack} />
      <Route path="/sx/:projectid/check/list" component={CheckList} />
      <Route path="/sx/:projectid/check/first" component={CheckFirst} />
      <Route path="/sx/:projectid/check/second" component={CheckSecond} />
      <Route path="/sx/:projectid/check/again" component={CheckAgain} />
      <Route path="/sx/:projectid/check/completed" component={CheckCompleted} />
      <Route path="/sx/:projectid/check/record" component={CheckRecord} />
      <Route path="/sx/:projectid/check/particular/:id" component={CheckParticular} />

      <Route path="/sx/:projectid/set/people" component={SetPeople}/>
      <Route path="/sx/:projectid/set/product" component={SetProduct}/>
      <Route path="/sx/:projectid/set/shop" component={SetShop}/>
      <Route path="/sx/:projectid/set/log" component={SetLog}/>
      <Route path="/sx/:projectid/set/target" component={SetTarget}/>
      <Route path="/sx/:projectid/set/location" component={SetLocation}/>

      <Route path="/sx/:projectid/data/visitor" component={DataVisitor}/>
      <Route path="/sx/:projectid/data/visitordetail/:surveyScope" component={DataVistorDetail}/>
      <Route path="/sx/:projectid/data/visitordetails/:surveyScope/:principalId" component={DataVisitorDetails}/>
      <Route path="/sx/:projectid/data/visitordetaildeb/:surveyScope/:principalId/:type" component={DataVisitorDetailDeb}/>
      <Route path="/sx/:projectid/data/first" component={DataFirst}/>
      <Route path="/sx/:projectid/data/DataFirstDetail/:surveyScope" component={DataFirstDetail}/>
      <Route path="/sx/:projectid/data/DataFirstDetails/:surveyScope/:audit2ById" component={DataFirstDetails}/>
      <Route path="/sx/:projectid/data/second" component={DataSecond}/>
    </>
  )
}
export default App
