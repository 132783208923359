
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { Menu } from 'antd';
import { HomeFilled, GoldenFilled, TeamOutlined, SolutionOutlined, FileTextOutlined, LogoutOutlined } from '@ant-design/icons';
import store from '@/redux/store'

function App() {

  const [menuList, setMenuList] = useState([]);
  const {  } = useHistory()
  const { pathname } = useLocation()
  const { projectid } = useRouteMatch().params

  const [openKeys, setOpenKeys] = useState(['/' + pathname.split('/').slice(3, 4).join('/')])
  const [selectedKeys, setSelectedKeys] = useState(['/' + pathname.split('/').slice(3, 5).join('/')])

  useEffect(() => {
    if (pathname.includes('/work/particulars')) {
      setSelectedKeys(['/work/list'])
      return
    }
    if (pathname.includes('/work/questionnaire')) {
      setSelectedKeys(['/work/questionnairelist'])
      return
    }
    if (pathname.includes('/work/previewy')) {
      setSelectedKeys(['/work/questionnairelist'])
      return
    }
    if (pathname.includes('/work/answer')) {
      setSelectedKeys(['/work/answerlist'])
      return
    }
    if (pathname.includes('/work/issue')) {
      setSelectedKeys(['/work/questionnairelist'])
      return
    }
    if (pathname.includes('/work/statistical')) {
      setSelectedKeys(['/work/statisticallist'])
      return
    }
    setSelectedKeys('/' + pathname.split('/').slice(3, 5).join('/'))
  }, [pathname])
  useEffect(() => {
    setOpenKeys(['/' + pathname.split('/').slice(3, 4).join('/')])
  }, [pathname])


  useEffect(() => {
    const set = () => {
      setMenuList([
        {
          itemGroup: { link: '/work', text: '问卷管理', icon: <GoldenFilled /> },
          itemList: [
            { link: '/work/questionnairelist', text: '编辑问卷', code: '*' },
            { link: '/work/list', text: '答题管理', code: '*' },
            { link: '/work/answerlist', text: '问卷答题', code: '*' },
            { link: '/work/statisticallist', text: '问卷统计', code: '*' },
            { link: '/work/message', text: '消息反馈', code: '*' }//CD_S_WENJUANN
          ]
        },
        // {
        //   itemGroup: { link: '/set', text: '项目设置', icon: <SolutionOutlined /> },
        //   itemList: [
        //     { link: '/set/people', text: '人员管理', code: 'CD_S_XIANGMUSHEZHI_RYXQ' },
        //     { link: '/set/target', text: '指标管理', code: 'CD_S_XIANGMUSHEZHI_ZBGL' },
        //     { link: '/set/location', text: '位置管理', code: 'CD_S_XIANGMUSHEZHI_WZGL' },
        //     { link: '/set/log', text: '操作日志', code: 'CD_S_XIANGMUSHEZHI_CZRZ' }
        //   ]
        // },
      ])
    }
    if (store.getState().permission.have) set()
    const s = store.subscribe(() => {
      if (store.getState().permission.have) {
        set()
        s()
      }
    })
  }, []);

  const handleExit = e => {
    store.dispatch({ type: 'user_clear' })
  }

  return (
    <Menu
      className='menu'
      theme="dark"
      openKeys={openKeys}
      onOpenChange={e => setOpenKeys(e)}
      selectedKeys={selectedKeys}
      mode="inline"
    >
      {
        menuList.map(i => {
          if (i.item) {
            return (
              <Menu.Item key={i.item.link} icon={i.item.icon}>
                <Link to={'/sx/' + projectid + i.item.link}>{i.item.text}</Link>
              </Menu.Item>
            )
          }
          if (i.itemGroup) {
            return (
              <Menu.SubMenu key={i.itemGroup.link} title={i.itemGroup.text} icon={i.itemGroup.icon} >
                {
                  i.itemList.map(i => {
                    return store.getState().permission.project.includes(i.code) || i.code === '*' ?
                      <Menu.Item key={i.link}>
                        <Link to={'/wj/' + projectid + i.link}>{i.text}</Link>
                      </Menu.Item>
                      : null
                  })
                }
              </Menu.SubMenu>
            )
          }
        })
      }
      < Menu.Item icon={< LogoutOutlined />} onClick={handleExit} >
        退出
      </Menu.Item >
    </Menu >
  );
}


export default App;