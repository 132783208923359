import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Form, Upload, Radio, Modal, Tooltip, Popconfirm } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined, DownOutlined, } from '@ant-design/icons';
import message from '@/utils/message'
import axios from '@/utils/axios'
import * as XLSX from 'xlsx'
import PopConfirmOnly from "@/utils/PopConfirmOnly";
import muban from '@/assets/appmbdr.xlsx'
const { Option } = Select

function App(props) {

  const { id } = useRouteMatch().params

  const [data, setData] = useState({
    appTemplateCode: '',
    appTemplateName: '',
    remark: '',
    isSupportScan: 0,
    isFillPrice: 0,
    isSupportAudio: 0,
    isAutoAudio: 0,
    isSupportPicture: 0,
    isManualPosition: 0,
    allowEditPosition: 0,
    isSupportVideo: 0,
  })

  const [appTemplateCode, setappTemplateCode] = useState('')
  const [appTemplateName, setappTemplateName] = useState('')
  const [isFillPrice, setisFillPrice] = useState(0)
  const [isSupportScan, setisSupportScan] = useState(0)
  const [isSupportAudio, setisSupportAudio] = useState(0)
  const [isAutoAudio, setisAutoAudio] = useState(0)
  const [isSupportVideo, setisSupportVideo] = useState(0)
  const [isSupportPicture, setisSupportPicture] = useState(0)
  const [isManualPosition, setisManualPosition] = useState(0)
  const [allowEditPosition, setallowEditPosition] = useState(0)
  const [remark, setremark] = useState('')



  const remarks = (e) => {
    setremark(e.target.value)
  }

  const appTemplateCodes = (e) => {
    setappTemplateCode(e.target.value)
  }

  const appTemplateNames = (e) => {
    setappTemplateName(e.target.value)
  }

  const [labelValues, setlabelValues] = useState([])

  useEffect(() => {
    axios.get('/api/sys/dict/' + 'questionType')
      .then(res => {
        console.log(res.data.labelValues)
        setlabelValues(res.data.labelValues)
      })
  }, [])

  const handleSave = e => {
    if (appTemplateName != '' && appTemplateCode != '' && importdata.length != 0) {
      if (id == 'add') {
        axios.post('/api/core/appTemplate', {
          appTemplateFieldList: importdata,
          appTemplateName,
          appTemplateCode,
          isFillPrice,
          isSupportPicture,
          isSupportScan,
          isSupportAudio,
          isManualPosition,
          allowEditPosition,
          isAutoAudio,
          isSupportVideo,
          remark
        })
          .then(res => {
            window.history.go(-1)
          })
      } else {
        axios.put('/api/core/appTemplate', {
          appTemplateFieldList: importdata,
          id,
          appTemplateName,
          appTemplateCode,
          isFillPrice,
          isSupportPicture,
          isSupportScan,
          isSupportAudio,
          isManualPosition,
          allowEditPosition,
          isAutoAudio,
          isSupportVideo,
          remark
        })
          .then(res => {
            window.history.go(-1)
          })
      }
    } else {
      message.error('请完整填写信息,再次提交')
    }

  }


  const [importdata, setimportdata] = useState([])

  const [importdate, setimportdate] = useState(Math.random())
  const onImportExcel = file => {
    let data = [];// 存储获取到的数据
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    console.log(fileReader)
    fileReader.readAsBinaryString(file);  //二进制
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以二进制流方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            // break; // 如果只取第一张表，就取消注释这行
          }
        }
        const datalist = []
        const labelValueslist = Object.keys(labelValues).map(v => {
          return { index: v, value: labelValues[v] }
        })
        console.log(labelValueslist)
        data.forEach(v => {
          console.log(labelValueslist.filter((a, b) => {
            return a.value == v.题型
          })[0]?.index)
          datalist.push({
            // Object.values(labelValues).map(a=>{
            //   return a==v.题型
            // })
            fieldName: v.问题名称,
            questionType: labelValueslist.filter((a, b) => {
              return a.value == v.题型
            })[0]?.index,
            refValues: v.问题答案,
            isRequired: v.是否必填 == '是' ? 1 : 0,
          })
        })

        let count = true
        datalist.forEach((v, i) => {
          if (!v.fieldName) {
            v.fieldName = ''
          }
          if (!v.questionType) {
            v.questionType = null
          }
          if (!v.refValues) {
            v.refValues = ''
          }
          if (!v.isRequired) {
            v.isRequired = 0
          }
          data.forEach((a, b) => {
            if (a.fieldName == v.fieldName && b != i) {
              message.error('问题不能重复,请重新导入')
              count = false
            }
          })
        })
        if (count) {
          let arr = importdata.concat(datalist)
          arr.forEach((v, i) => {
            v.seq = i + 1
          })
          setimportdata(arr);
        }
      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        message.error('导入失败,请检查模板');
        return;
      }
    };
  }

  const radios = (record, num, index) => {
    importdata[index].questionType = num
    setimportdata(importdata)
    setimportdate(Math.random())
  }


  const [visible, setvisible] = useState(false)
  const [gaivisible, setGaivisible] = useState(false)
  const [visibleqipao, setvisibleqipao] = useState(false)
  const [obj, setobj] = useState({
    fieldName: null,
    questionType: 1,
    refValues: null,
    isRequired: '',
    remark: ''
  })

  const devs = () => {
    if (id != 'add') {
      axios.get('/api/core/appTemplateField/getByAppTemplateId', {
        params: {
          appTemplateId: id
        }
      })
        .then(res => {
          setappTemplateName(res.data[0].appTemplate.appTemplateName)//模板名称
          setappTemplateCode(res.data[0].appTemplate.appTemplateCode)//模版编号
          setremark(res.data[0].appTemplate.remark)//备注
          setisSupportScan(res.data[0].appTemplate.isSupportScan)//扫码
          setisFillPrice(res.data[0].appTemplate.isFillPrice)//扫码后填写价格
          setisSupportAudio(res.data[0].appTemplate.isSupportAudio)//录音
          setisAutoAudio(res.data[0].appTemplate.isAutoAudio)//开始任务自动录音
          setisSupportPicture(res.data[0].appTemplate.isSupportPicture)//拍照
          setisManualPosition(res.data[0].appTemplate.isManualPosition)//拍照后手动定位自定义记录位置
          setallowEditPosition(res.data[0].appTemplate.allowEditPosition)//拍照后手动定位自定义记录位置
          setisSupportVideo(res.data[0].appTemplate.isSupportVideo)//录像
          setimportdata(res.data.sort((a, b) => a.seq - b.seq))
        })
    }
  }

  useEffect(() => {
    devs()
  }, [])

  const showModal = () => {
    setvisible(true)
  };

  const handleInit = () => {
    window.history.go(-1)
  }

  const handleOk = e => {
    if (obj.fieldName != null && obj.questionType != null && isRequired != '') {
      if (obj.questionType != 3) if (obj.refValues == null) { message.error('请完整填写信息,再次提交'); return }
      setvisible(false)
      let arr = importdata.concat(obj)
      arr.forEach((v, i) => {
        v.seq = i + 1
      })
      setimportdata(arr)
      setobj({
        fieldName: null,
        questionType: 1,
        refValues: null,
        isRequired: '',
        remark: ''
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  };

  const gaihandleOk = e => {
    if (obj.fieldName != null && obj.questionType != null && isRequired != '') {
      if (obj.questionType != 3) if (obj.refValues == null) { message.error('请完整填写信息,再次提交'); return }
      setGaivisible(false)
      datas.fieldName = obj.fieldName
      datas.questionType = obj.questionType
      datas.refValues = obj.refValues
      datas.isRequired = obj.isRequired
      datas.remark = obj.remark
      setimportdata(JSON.parse(JSON.stringify(importdata)))
      setobj({
        fieldName: null,
        questionType: 1,
        refValues: null,
        isRequired: '',
        remark: ''
      })
    } else {
      message.error('请完整填写信息,再次提交')
    }
  };

  const handleCancel = e => {
    setvisible(false)
    setGaivisible(false)
    setobj({
      fieldName: null,
      questionType: 1,
      refValues: null,
      isRequired: '',
      remark: ''
    })
  };

  const wens = e => {
    obj.fieldName = e.target.value
    setobj(Object.assign({}, obj))
  }

  const isRequired = e => {
    obj.isRequired = e
    setobj(Object.assign({}, obj))
  }

  const types = e => {
    obj.questionType = e.target.value
    setobj(Object.assign({}, obj))
  }

  const tis = e => {
    obj.refValues = e.target.value
    setobj(Object.assign({}, obj))
  }

  const delclidata = (record) => {
    let arr = importdata.concat([])
    importdata.forEach((v, i) => {
      if (v.fieldName == record.fieldName) {
        arr.splice(i, 1)
      }
    })
    arr.forEach((v, i) => {
      v.seq = i + 1
    })
    setimportdata(arr.concat([]))
    message.success('删除成功');
  }

  const columns = [
    {
      title: '排序',
      dataIndex: 'seq',
      key: 'seq',
      render: (text, record, index) => text
    },
    {
      title: '显示名称',
      dataIndex: 'fieldName',
      key: 'fieldName',

    },
    {
      title: '题型',
      dataIndex: 'questionType',
      key: 'questionType',
      render: (text, record, index) => (
        <>
          {
            text == 1 ? '选择题' : text == 2 ? '多选题' : '简答题'
          }
        </>
      )
    },
    {
      title: '选择问题',
      dataIndex: 'refValues',
      key: 'refValues',
      render: (text, record) => (
        <>
          {text}
        </>
      )
    },
    {
      title: '是否必填',
      dataIndex: 'isRequired',
      key: 'isRequired',
      render: (text, record) => (
        <>
          {text ? '是' : '否'}
        </>
      )
    },
    {
      title: '操作',
      dataIndex: 'refValues',
      key: 'refValues',
      render: (text, record, index) => (
        <div>
          <Button type='link' size='small' onClick={e => updata(record, index)}>修改</Button>
          <PopConfirmOnly confirm={e => delclidata(record)}>
            <Button type='link' size='small'>删除</Button>
          </PopConfirmOnly>
          <Button type='link' size='small' onClick={e => yidong(record, true)}>上移</Button>
          <Button type='link' size='small' onClick={e => yidong(record, false)}>下移</Button>
        </div>
      )
    },
  ];

  const yidong = (record, bools) => {
    let obj = importdata.filter(v => v.seq == record.seq)[0]
    let arr = JSON.parse(JSON.stringify(importdata))
    importdata.forEach((v, i) => {
      if (v.seq == obj.seq) {
        if (bools) {
          
          if(i!=0){
            arr[i] = arr[i - 1]
            arr[i - 1] = obj
          }else{
            message.error('当前题不可上移')
          }
        } else {
          if(i+1!=arr.length){
            arr[i] = arr[i + 1]
            arr[i + 1] = obj
          }else{
            message.error('当前题不可下移')
          }
        }
      }
    })
    arr.forEach((v,i)=>{
      v.seq = i+1
    })
    setimportdata(arr)
  }

  const [datas, setDatas] = useState()

  const updata = (record, index) => {
    setGaivisible(true)
    setDatas(record)
    setobj({
      fieldName: record.fieldName,
      questionType: record.questionType,
      refValues: record.refValues,
      isRequired: record.isRequired,
      remark: record.remark
    })

  }
  //
  return (
    <div style={{ padding: '30px' }}>
      <Modal
        title="修改"                                       //弹出框
        visible={gaivisible}
        onOk={gaihandleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认修改'
      >
        <Row>
          <Col span={5} style={{ textAlign: 'right', lineHeight: '30px', marginBottom: '10px' }}><font color='red'>*</font>问题：</Col>
          <Col span={19}>
            <Input defaultValue={obj.fieldName} onChange={wens} ></Input>
          </Col>
          <Col span={5} style={{ textAlign: 'right', lineHeight: '30px', marginBottom: '10px' }}></Col>
          <Col span={19} style={{ marginBottom: '10px' }}><font color='red'>*</font>
            <Radio.Group onChange={types} defaultValue={String(obj.questionType)} >
              {
                Object.keys(labelValues).map((i) => {
                  return <Radio value={i}>{labelValues[i]}</Radio>
                })
              }
            </Radio.Group>
          </Col>
          <Col span={5} style={{ textAlign: 'right', lineHeight: '30px', marginBottom: '10px' }}><font color='red'>*</font>是否必填：</Col>
          <Col span={19}>
            <Select defaultValue={obj.isRequired} style={{ width: 120 }} onChange={(e) => { isRequired(e) }}>
              <Option value={1}>是</Option>
              <Option value={0}>否</Option>
            </Select>
          </Col>
          {
            obj.questionType != 3 ? <>
              <Col span={5} style={{ textAlign: 'right', lineHeight: '30px', marginBottom: '10px' }}><font color='red'>*</font>未选择项：</Col>
              <Col span={19}>
                <Input.TextArea defaultValue={obj.refValues} onChange={tis} style={{ width: '420px' }} rows={4}></Input.TextArea>
              </Col>
            </> : null
          }
        </Row>
      </Modal>

      <Modal
        title="新增"                                       //弹出框
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认添加'
      >
        <Row>
          <Col span={5} style={{ textAlign: 'right', lineHeight: '30px', marginBottom: '10px' }}><font color='red'>*</font>问题：</Col>
          <Col span={19}>
            <Input onChange={wens} ></Input>
          </Col>
          <Col span={5} style={{ textAlign: 'right', lineHeight: '30px', marginBottom: '10px' }}></Col>
          <Col span={19} style={{ marginBottom: '10px' }}><font color='red'>*</font>
            <Radio.Group name="radiogroup" onChange={types} defaultValue={1}>
              {
                Object.keys(labelValues).map((i) => {
                  return <Radio value={i}>{labelValues[i]}</Radio>
                })
              }
            </Radio.Group>
          </Col>
          <Col span={5} style={{ textAlign: 'right', lineHeight: '30px', marginBottom: '10px' }}><font color='red'>*</font>是否必填：</Col>
          <Col span={19}>
            <Select style={{ width: 120 }} onChange={(e) => { isRequired(e) }}>
              <Option value={1}>是</Option>
              <Option value={0}>否</Option>
            </Select>
          </Col>
          {
            obj.questionType != 3 ? <>
              <Col span={5} style={{ textAlign: 'right', lineHeight: '30px', marginBottom: '10px' }}><font color='red'>*</font>未选择项：</Col>
              <Col span={19}>
                <Input.TextArea onChange={tis} style={{ width: '420px' }} rows={4}></Input.TextArea>
              </Col>
            </> : null
          }
        </Row>
      </Modal>

      <Row gutter={[0, 20]} style={{ padding: '30px', background: 'white' }}>
        <Col span={24} style={{ fontWeight: 'bold' }}>基本信息</Col>
        <Col span={3} style={{ textAlign: 'right', lineHeight: '30px' }}><font color='red'>*</font>模版名称：</Col>
        <Col span={21}>
          <Input onChange={appTemplateNames} value={appTemplateName} style={{ width: '420px' }}></Input>
        </Col>
        <Col span={3} style={{ textAlign: 'right', lineHeight: '30px' }}><font color='red'>*</font>模版编号：</Col>
        <Col span={21}>
          <Input onChange={appTemplateCodes} value={appTemplateCode} style={{ width: '420px' }}></Input>
        </Col>
        <Col span={3} style={{ textAlign: 'right', lineHeight: '30px' }}>描述/备注：</Col>
        <Col span={21}>
          <Input.TextArea onChange={remarks} value={remark} style={{ width: '420px' }} rows={4}></Input.TextArea>
        </Col>
        <Divider />

        <Col span={24} style={{ fontWeight: 'bold' }}>
          APP功能
          <Form.Item label="报表上传">{/* 导入 */}
            <Upload name="excel" action="" listType="text" accept="file" beforeUpload={onImportExcel} showUploadList={false}>
              <Button style={{ margin: '0 10px' }}>导入清单</Button>
            </Upload>
            <Button style={{ margin: '0 10px' }}
              href={muban}
            >下载模板</Button>
          </Form.Item>

        </Col>
        <Col span='24' style={{ textAlign: 'center' }}>
          <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={showModal} ><PlusOutlined style={{ color: '#15a7fb', fontSize: '30px', }} /><b style={{ fontWeight: '550' }}>添加</b></span>
        </Col>
        <Col span={24}>
          <div style={{ marginLeft: '40px' }}>
            <Checkbox checked={isSupportScan == 1} onChange={e => setisSupportScan(e.target.checked ? 1 : 0)}>扫码</Checkbox>
            <Checkbox checked={isFillPrice == 1} onChange={e => setisFillPrice(e.target.checked ? 1 : 0)}>扫码后填写价格</Checkbox>
            <Checkbox checked={isSupportAudio == 1} onChange={e => setisSupportAudio(e.target.checked ? 1 : 0)}>录音</Checkbox>
            <Checkbox checked={isAutoAudio == 1} onChange={e => setisAutoAudio(e.target.checked ? 1 : 0)}>开始任务自动录音</Checkbox>
            <Checkbox checked={isSupportPicture == 1} onChange={e => setisSupportPicture(e.target.checked ? 1 : 0)}>拍照</Checkbox>
            <Checkbox checked={isManualPosition == 1} onChange={e => setisManualPosition(e.target.checked ? 1 : 0)}>手动输入地址（默认gps）</Checkbox>
            <Checkbox checked={allowEditPosition == 1} onChange={e => setallowEditPosition(e.target.checked ? 1 : 0)}>允许修改地址</Checkbox>
            {/* <Checkbox checked={isSupportVideo == 1} onChange={e => setisSupportVideo(e.target.checked ? 1 : 0)}>录像</Checkbox> */}
          </div>
        </Col>
        <Col span={24} style={{ marginLeft: '40px' }}>
          <Row gutter={[20, 20]} align='middle'>
            <Col span='24'>
              <Table style={{ width: '100%', paddingRight: '50px' }} dataSource={importdata} columns={columns} pagination={{ defaultPageSize: 5, showQuickJumper: true }} />
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ marginLeft: '40px' }}>
          <Button type='primary' style={{ margin: '0 10px' }} onClick={handleSave}>{id == 'add' ? '提交' : '修改'}</Button>
          <Button style={{ margin: '0 10px' }} onClick={handleInit}>取消</Button>
        </Col>
      </Row>
    </div>
  )
}

export default App