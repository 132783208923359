
import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, DatePicker, Button, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import moment from 'moment'
import axios from '@/utils/axios'
import message from '@/utils/message'

function Title(props) {
  return (
    <>
      <Col span={24} style={{ padding: '15px 30px' }}>
        <Row justify='space-between' align='middle'>
          <Col style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.text}</Col>
          <Col>
            {
              props.children
            }
          </Col>
        </Row>
      </Col>

      <Divider style={{ margin: '0' }} />
    </>
  )
}


function App(props) {

  const { push } = useHistory()
  const { id } = useRouteMatch().params

  // options
  const [projectTypeOption, setProjectTypeOption] = useState([])
  const [projectTempOption, setProjectTempOption] = useState([])
  const [peopleOption, setPeopleOption] = useState([])
  const [shopOption, setShopOption] = useState([])
  const [computeTempOption, setComputeTempOption] = useState([])

  useEffect(() => {
    axios.get('/api/sys/dict/projectType').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setProjectTypeOption(arr)
    })
    axios.get('/api/core/projectTemplate/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.projectTemplateName }
      })
      setProjectTempOption(arr)
    })
    axios.get('/api/core/shop/list', { params: { pageSize: 1000000} }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.shopName }
      })
      setShopOption(arr)
    })
    axios.get('/api/core/calScoreTemplate/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.calScoreTemplateName }
      })
      setComputeTempOption(arr)
    })
    axios.get('/api/sys/user/list', { params: { pageSize: 1000000 } }).then(res => {
      const arr = res.data.data.map(i => {
        return { value: i.id, text: i.name }
      })
      setPeopleOption(arr)
    })
  }, [])

  // option select
  const [projectTypeSelect, setProjectTypeSelect] = useState(null)
  const [projectTempSelect, setProjectTempSelect] = useState(null)
  const [peopleSelect, setPeopleSelect] = useState(null)
  const [shopSelect, setShopSelect] = useState(null)
  const [computeTempSelect, setComputeTempSelect] = useState(null)
  // other params
  const [nameText, setNameText] = useState('')
  const [codeText, setCodeText] = useState('')
  const [areaText, setAreaText] = useState('')
  const [date, setDate] = useState([])
  const [warnDate, setWarnDate] = useState(null)
  const [dsrAvgScore,setDsrAvgScore] = useState(null)
  const [dsrTaskCount,setDsrTaskCount] = useState(null)


  const handleClear = e => {
    setProjectTypeSelect(null)
    setProjectTempSelect(null)
    setPeopleSelect(null)
    setShopSelect(null)
    setComputeTempSelect(null)
    setNameText('')
    setCodeText('')
    setAreaText('')
    setDate([])
    setWarnDate(null)
  }

  const handleSave = e => {
    axios.post('/api/core/project', {
      code: codeText,
      name: nameText,
      type: projectTypeSelect,
      userGroupId: peopleSelect,
      projectTempId: projectTempSelect,
      shopId: shopSelect,
      calScoreId: computeTempSelect,
      startDate: date[0] ? moment(date[0]).format('YYYY-MM-DD') : '',
      endDate: date[1] ? moment(date[1]).format('YYYY-MM-DD') : '',
      warningDate: warnDate ? moment(warnDate).format('YYYY-MM-DD') : '',
      scope: areaText,
      dsrAvgScore,
      dsrTaskCount,
    }).then(res => {
      message.success()
      handleClear()
    })
  }

  useEffect(() => {
    axios.get('/api/core/product/' + id).then(res => {

    })
  }, [])


  return (
    <div style={{ padding: '30px', paddingBottom: '0' }}>
      <div style={{ background: 'white', marginBottom: '20px' }}>
        <Row>
          <Title text='项目基本信息'>
            <Select style={{ width: '180px' }} placeholder='请选择项目模板' value={projectTempSelect} onChange={e => setProjectTempSelect(e)}>
              {
                projectTempOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Title>
          <Col span={24} style={{ padding: '15px 30px' }}>
            <Row gutter={[0, 30]}>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>项目类型</p>
                <Select style={{ width: '280px' }} placeholder='请选择项目类型' value={projectTypeSelect} onChange={e => setProjectTypeSelect(e)}>
                  {
                    projectTypeOption.map((i, index) => (
                      <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                    ))
                  }
                </Select>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>项目名称</p>
                <Input style={{ width: '280px' }} placeholder='请输入项目名称' value={nameText} onChange={e => setNameText(e.target.value)}></Input>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>项目编号</p>
                <Input style={{ width: '280px' }} placeholder='请输入项目编号' value={codeText} onChange={e => setCodeText(e.target.value)}></Input>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>生效日期</p>
                <DatePicker.RangePicker style={{ width: '280px' }} value={date} onChange={e => setDate(e)}></DatePicker.RangePicker>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>预警日期</p>
                <DatePicker style={{ width: '280px' }} value={warnDate} onChange={e => setWarnDate(e)}></DatePicker>
              </Col>
              <Col span={8}>
                <p style={{ lineHeight: '30px' }}>项目区域</p>
                <Input style={{ width: '280px' }} value={areaText} onChange={e => setAreaText(e.target.value)}></Input>
              </Col>
              {
                projectTypeSelect === '1' ?
                  <Col span={8}>
                    <p style={{ lineHeight: '30px' }}>DSR平均分标准</p>
                    <Input style={{ width: '280px' }} placeholder='请输入DSR平均分标准' value={dsrAvgScore} onChange={e => setDsrAvgScore(e.target.value)}></Input>
                  </Col>
                :null
              }
              {
                projectTypeSelect === '1' ?
                  <Col span={8}>
                    <p style={{ lineHeight: '30px' }}>DSR工单数量标准字段</p>
                    <Input style={{ width: '280px' }} placeholder='请输入DSR平均分标准' value={dsrTaskCount} onChange={e => setDsrTaskCount(e.target.value)}></Input>
                  </Col>
                :null
              }
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ background: 'white', marginBottom: '20px' }}>
        <Row>
          <Title text='人员导入' />
          <Col span={24} style={{ padding: '15px 30px' }}>
            <p span={24} style={{ lineHeight: '30px' }}>本期人员</p>
            <Col span={24}>
              <Select style={{ width: '280px' }} placeholder='请选择人员列表' value={peopleSelect} onChange={e => setPeopleSelect(e)}>
                {
                  peopleOption.map((i, index) => (
                    <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                  ))
                }
              </Select>
              <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/control/people')}><EditOutlined />编辑</span>
            </Col>
          </Col>
        </Row>
      </div>
      <div style={{ background: 'white', marginBottom: '20px' }}>
        <Row>
          <Title text='门店导入' />
          <Col span={24} style={{ padding: '15px 30px' }}>
            <p span={24} style={{ lineHeight: '30px' }}>本期门店</p>
            <Col span={24}>
              <Select style={{ width: '280px' }} placeholder='请选择门店列表' value={shopSelect} onChange={e => setShopSelect(e)}>
                {
                  shopOption.map((i, index) => (
                    <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                  ))
                }
              </Select>
              <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/set/shop')}><EditOutlined />编辑</span>
            </Col>
          </Col>
        </Row>
      </div>
      <div style={{ background: 'white', marginBottom: '20px' }}>
        <Row>
          <Title text='算分公式' />
          <Col span={24} style={{ padding: '15px 30px' }}>
            <p span={24} style={{ lineHeight: '30px' }}>本期算分公式</p>
            <Col span={24}>
              <Select style={{ width: '280px' }} placeholder='请选择算分公式' value={computeTempSelect} onChange={e => setComputeTempSelect(e)}>
                {
                  computeTempOption.map((i, index) => (
                    <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                  ))
                }
              </Select>
              <span style={{ marginLeft: '40px', color: 'rgb(42, 130, 228)' }} onClick={e => push('/home/set/computetemplate')}><EditOutlined />编辑</span>
            </Col>
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Button style={{margin: '0 10px'}} type='primary' onClick={handleSave}>提交</Button>
        <Button style={{margin: '0 10px'}} onClick={handleClear}>重置</Button>
      </div>
    </div>
  )
}

export default App