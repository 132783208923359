import React, { useEffect, useState, Component } from 'react'
import { Button, Row, Col, Carousel, Cascader, Modal, Select, Tabs, Table, Image, Popover, Popconfirm, Upload } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, CloudDownloadOutlined, UploadOutlined, UndoOutlined, RedoOutlined, ConsoleSqlOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { baseUrl } from '@/utils/config'
import '@/assets/swiper-bundle.min.css'
import store from '@/redux/store'

import loading from '@/assets/timg.jpg'
import message from '@/utils/message'

import { Swiper, SwiperSlide } from 'swiper/react';

import { audioUrl } from '@/utils/config.js'
import { listToArray } from '@/utils/listToArray'


import axios from '@/utils/axios'
import { responsiveArray } from 'antd/lib/_util/responsiveObserve'

import ImageMagnifier from "./ImageMagnifier";

const { Option } = Select;
const { TabPane } = Tabs;
const { Dragger } = Upload;

let tuihui = []

class App extends Component {
    constructor() {
        super()
        this.state = {
            x: 0,
            y: 0,
            mobool: false,
            contentStyle: {
                height: '160px',
                color: '#fff',
                lineHeight: '160px',
                textAlign: 'center',
                background: '#364d79',
            },
            swiper: [],
            cloneswiper: [],
            num: 1,
            defaultsrc: {
                id: '',
                before: '',
                status: 0,
                isbool: true
            },
            data: {},
            current: 'mail',
            visible: false,
            taskId: '',
            labelValues: {},
            swipers: {},
            swipersnum: [],
            numbool: 0,
            tuihui: [],
            gbool: false,
            lenum: 1,
            Image: true,
            downbool: false,
            option: [],
            optionchild: [],
            optionchildname: '全部',
            optionchilddefault: [],
            optionchildlist: [],
            optionchildlists: [],
            ismentoubool: true,
            ishuojiabool: true,
            Colnum: null,
            saohuo: [],
            id: 0,
            refusedCountArr: [],
            xuanzhongModal: false,
            productGroupId: null,
            innerHTML: null,
            saomachanpin: [],
            current: 0,
            chanpindata: [],
            ismodel: false,
            // props: { //上传图片配置
            //     name: 'file',
            //     accept: '.jpg',
            //     action: baseUrl + '/api/common/upload?appId=cms&appKey=cms@2020',
            //     headers: {
            //         authorization: 'authorization-text',
            //     },
            //     maxCount:20
            // },
            typeimg: null,
            uplaodimg: null,
            selectbool: false,
            isModalVisible: false,
            uploadbool: false,
            axiosArr: [],
            axiosBool: false,
            Intervaltimer: null,
            fileimgdata: [],
            timeIndex: 0,
            numarrIndex:0,
            numarrs:[]
        }
    }

    columns = [
        {
            dataIndex: 'fileName',
            key: 'fileName',
            render: (text, record, index) => (text)
        },
    ]

    //模态框
    // showModal = () => {
    //     document.body.parentNode.style.overflow = "hidden"
    //     this.setState({
    //         visible: true,
    //         mobool: false,
    //         current: 0
    //     });
    //     document.body.addEventListener('touchmove', function (e) {
    //         e.preventDefault();
    //     }, { passive: false });  //passive 参数不能省略，用来兼容ios和android

    //     setTimeout(()=>{
    //         document.getElementById('img1').style.position = 'absolute'
    //         let e=document.getElementById('img1')
    //         let a=document.getElementsByClassName('stop')[0]
    //         var x = a.clientWidth/2 - e.clientWidth/2
    //         var y = 0
    //         sltp.setState({
    //             x: x,
    //             y: y
    //         })
    //     })

    //     const sltp = this
    // };



    handleCancel = e => {
        document.body.parentNode.style.overflow = "scroll";
        this.setState({
            visible: false,
        });
        document.body.addEventListener('touchmove', function (e) {
            e.preventDefault();
        }, { passive: true });  //passive 参数不能省略，用来兼容ios和android
    };

    stop = e => {
        e.stopPropagation()
    }

    getchanpindata = (id) => {
        axios.get('/api/core/product/list', {//查找产品组
            params: {
                pageSize: '1000000',
                productGroupId: id
            }
        })
            .then(res => {
                let slpt = this
                this.setState({
                    chanpindata: res.data.data
                })
                axios.get('/api/core/barCode/list', {
                    params: {
                        pageSize: 1000000,
                        queryCondition: "EQ:taskId=" + this.props.match.params.id
                    }
                })
                    .then(ress => {
                        this.setState({
                            saomachanpin: ress.data.data
                        })
                        function list(data) {
                            data.forEach((v, i) => {
                                if (v.children instanceof Array) {
                                    list(v.children)
                                }
                                v.saohuobool = JSON.stringify(ress.data.data).indexOf('"productId":' + v.id) != -1
                                v.tuihuibool = ress.data.data.filter(a => { return a.productId == v.id })[0]?.isDeleted == 1
                                v.updatabool = ress.data.data.filter(a => { return a.productId == v.id })[0]?.flag == 1
                                v.price = ress.data.data.filter(a => { return a.productId == v.id })[0]?.productPrice
                            })
                            return data
                        }
                        const data = list(listToArray(res.data.data))
                        data.forEach((v, i) => {//遍历品牌
                            if (v.children) {
                                v.children.forEach((a, b) => {//遍历系列
                                    const count = [0, 0]
                                    if (a.children) {
                                        a.children.forEach((a, b) => {//遍历产品
                                            if (a.saohuobool) {
                                                count[0]++
                                                if (a.updatabool) {
                                                    count[1]++
                                                }
                                            }
                                        })
                                        a.number = count
                                    }
                                })
                            }
                        })
                        this.setState({
                            option: data.reverse()
                        }, () => {
                            slpt.setState({
                                tuihui: tuihui
                            })
                            if (this.state.innerHTML) {
                                this.state.option.forEach(v => {
                                    if (this.state.innerHTML == v.productName) {
                                        let arr = []
                                        v.children.forEach(v => {
                                            arr.push({ productName: v.productName, children: [], bool: true, number: v.number })
                                            if (v.children != undefined) {
                                                arr = arr.concat(v.children)
                                            }
                                        })
                                        this.setState({
                                            optionchild: v.children,
                                            optionchildname: v.productName,
                                            optionchilddefault: v.children ? v.children[0].id : '',
                                            optionchildlist: arr
                                        })
                                    }
                                })
                            }
                        })
                    })
            })
    }

    componentDidMount() {
        this.state.Intervaltimer = setInterval(() => {
            if (this.state.axiosArr.length != 0 && this.state.axiosBool) {
                this.state.axiosBool = false
                this.state.axiosArr[0]()
                this.state.axiosArr.shift()
            }
        }, 500);
        const sltp = this
        document.onkeydown = function (e) {
            if (e.keyCode == 37) {
                sltp.leftsrc()
                e.preventDefault();
            }
            if (e.keyCode == 39) {
                sltp.rightsrc()
                e.preventDefault();
            }
        }
        axios.get('/api/core/task/' + this.props.match.params.id)
            .then(ress => {
                axios.get('/api/core/project/' + ress.data.projectId)//指标组
                    .then(res => {
                        this.getchanpindata(res.data.productGroupId)
                        this.setState({
                            productGroupId: res.data.productGroupId
                        })
                    })

                if (JSON.stringify(ress.data) != '[]') {
                    this.setState({
                        data: ress.data
                    }, () => {
                        this.setState({
                            labelValues: {
                                '0': '门头',
                                '1': '货架',
                                '2': '产品'
                            }
                        })

                        this.getimgData(ress.data.id)
                        this.setState({
                            id: ress.data.id
                        })
                    })

                }
            })
    }

    getimgData = (ress) => {
        axios.get('/api/core/issue/list', {//轮播图片
            params: {
                queryCondition: 'EQ:taskId=' + ress,
                pageSize: 1000000,
            }
        })//接口
            .then(res => {
                this.setState({
                    swiper: res.data.data,
                }, () => {
                    let arr = []
                    this.state.swiper.forEach((v, i) => {
                        arr.push(v)
                        v.isbool = true
                        if (v.refusedCount && this.state.refusedCountArr.indexOf(v.refusedCount) == -1) {
                            this.state.refusedCountArr.push(v.refusedCount)
                        }
                    })
                    this.setState({
                        cloneswiper: arr,
                        swiper: arr,
                        swipers: arr.filter((v, i) => {
                            return v.status == this.state.defaultsrc.status
                        }),
                        defaultsrc: arr.filter((v, i) => {
                            return v.status == this.state.defaultsrc.status
                        })[0]
                    })
                })
            })
    }

    roll = (e) => {
        e.stopPropagation()
        //滚轮上下滚动值
        let zooming = document.getElementById("zoomimg")
        //大于0滚轮向上滚动,小于0滚轮向下滚动
        if (e.deltaY < 0) {
            zooming.style.height = Number(zooming.style.height.split('px')[0]) + 20 + 'px';
        } else {
            zooming.style.height = Number(zooming.style.height.split('px')[0]) - 20 + 'px';
        }
    }

    leftsrc = (e) => {//向左移动图片
        // if(document.getElementById('img1')!=null)document.getElementById('img1').style.transform = 'rotate(0deg)'; // 清空旋转方向
        // if(document.getElementById('img1')!=null)document.getElementById('imga').style.transform = 'rotate(0deg)';
        // e.stopPropagation()
        if (this.state.num > 1) {
            this.setState({
                num: this.state.num - 1,
                defaultsrc: this.state.swipers[this.state.num - 2],
            }, () => {
                let crr = this.state.swipersnum
                crr.forEach((v, i) => {
                    let count = false
                    tuihui.forEach((a, b) => {
                        if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                            count = true
                        }
                    })
                    if (count) {
                        v.tuihuibool = true
                    } else {
                        v.tuihuibool = false
                    }
                });
                this.setState({
                    swipersnum: crr,
                    // current:0  //清空旋转方向
                })
            })
        }
    }

    rightsrc = (e) => {//向有右移动图片
        // if(document.getElementById('img1')!=null)document.getElementById('img1').style.transform = 'rotate(0deg)';// 清空旋转方向
        // if(document.getElementById('img1')!=null)document.getElementById('imga').style.transform = 'rotate(0deg)';
        // e.stopPropagation()
        if (this.state.num + 1 == this.state.swipers.length) {
            this.setState({
                isModalVisible: true
            })
            setTimeout(e => {
                this.setState({
                    isModalVisible: false
                })
            }, 1000)
        }
        if (this.state.num < this.state.swipers.length) {
            this.setState({
                num: this.state.num + 1,
                defaultsrc: this.state.swipers[this.state.num],
            }, () => {
                let crr = this.state.swipersnum
                crr.forEach((v, i) => {
                    let count = false
                    tuihui.forEach((a, b) => {
                        if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                            count = true
                        }
                    })
                    if (count) {
                        v.tuihuibool = true
                    } else {
                        v.tuihuibool = false
                    }
                });
                this.setState({
                    swipersnum: crr,
                    // current:0  //清空旋转方向
                })
            })
        } else {
            this.setState({
                num: 1,
                defaultsrc: this.state.swipers[0],
            }, () => {
                let crr = this.state.swipersnum
                crr.forEach((v, i) => {
                    let count = false
                    tuihui.forEach((a, b) => {
                        if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                            count = true
                        }
                    })
                    if (count) {
                        v.tuihuibool = true
                    } else {
                        v.tuihuibool = false
                    }
                });
                this.setState({
                    swipersnum: crr,
                    // current:0
                })
            })
        }
    }

    clisrc = (val, i, e) => {
        this.setState({
            defaultsrc: val,
            num: i + 1,
        }, () => {
            let crr = this.state.swipersnum
            crr.forEach((v, i) => {
                let count = false
                tuihui.forEach((a, b) => {
                    if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                        count = true
                    }
                })
                if (count) {
                    v.tuihuibool = true
                } else {
                    v.tuihuibool = false
                }
            });
            this.setState({
                swipersnum: crr
            })
        })
    }

    clifilter = (val, i) => {
        this.setState({
            swipers: val.description == this.state.defaultsrc.description ? this.state.swipers : this.state.swiper.filter((v, i) => {
                return v.description == val.description
            }),
            defaultsrc: val.description == this.state.defaultsrc.description ? this.state.defaultsrc : val,
            num: val.description == this.state.defaultsrc.description ? this.state.num : 1,
            numbool: i,
        }, () => {
            let crr = this.state.swipersnum
            crr.forEach((v, i) => {
                let count = false
                tuihui.forEach((a, b) => {
                    if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                        count = true
                    }
                })
                if (count) {
                    v.tuihuibool = true
                } else {
                    v.tuihuibool = false
                }
            });
            this.setState({
                swipersnum: crr
            })
        })
    }

    callback = (key) => {
        key != '1' ? key = parseInt(key) + 2 : key = 0
        this.state.numarrs[this.state.numarrIndex] = this.state.num

        this.setState({
            swipers: this.state.swiper.filter((v, i) => {
                return v.status == key
            }),
            defaultsrc: this.state.swiper.filter((v, i) => {
                return v.status == key
            })[this.state.numarrs[key]?this.state.numarrs[key]-1:0],
            numarrIndex:key,
            num: this.state.numarrs[key]?this.state.numarrs[key]:1
        })
    }

    backbool = (val, boaval) => {
        if (sessionStorage.getItem('Completed') == 'true') return
        if (!val.tuihuibool) {
            if (val.saohuobool) {
                if (val.updatabool == false) {
                    val.saohuobool = true
                    val.tuihuibool = true
                } else if (val.updatabool == true) {
                    val.saohuobool = false
                }
                this.setState({
                    axiosArr: [...this.state.axiosArr,
                    () => {
                        axios.delete('/api/core/barCode/batch', { data: [this.state.saomachanpin.filter(v => { return v.productId == val.id })[0]?.id] })
                            .then(res => {
                                message.success('删除成功')
                                this.getchanpindata(this.state.productGroupId)
                                this.state.axiosBool = true
                            })
                            .catch(error => {
                                message.error(error.response.data.msg)
                                this.state.axiosBool = true
                            })

                    }
                    ]
                }, () => {
                    if (this.state.axiosArr.length == 1) {
                        this.setState({
                            axiosBool: true
                        })
                    }
                })
                tuihui.push(val)
            } else {
                val.saohuobool = true
                val.updatabool = true
                this.setState({
                    axiosArr: [...this.state.axiosArr,
                    () => {
                        axios.post('/api/core/barCode', {
                            flag: 1,
                            taskId: this.state.data.id,
                            productId: val.id
                        })
                            .then(res => {
                                message.success('添加成功')
                                this.getchanpindata(this.state.productGroupId)
                                this.state.axiosBool = true
                            })
                            .catch(error => {
                                message.error(error.response.data.msg)
                                this.state.axiosBool = true
                            })
                    }
                    ]
                }, () => {
                    if (this.state.axiosArr.length == 1) {
                        this.setState({
                            axiosBool: true
                        })
                    }
                })
            }
        } else {
            val.tuihuibool = !val.tuihuibool
            val.updatabool = false
            tuihui.forEach((v, i) => {
                if (v.id == val.id) {
                    tuihui.splice(i, 1)
                }
            })
            this.setState({
                axiosArr: [...this.state.axiosArr,
                () => {
                    axios.post('/api/core/barCode', {
                        flag: 1,
                        taskId: this.state.data.id,
                        productId: val.id
                    })
                        .then(res => {
                            message.success('添加成功')
                            this.state.axiosBool = true
                        })
                        .catch(error => {
                            message.error(error.response.data.msg)
                            this.state.axiosBool = true
                        })
                }
                ]
            }, () => {
                if (this.state.axiosArr.length == 1) {
                    this.setState({
                        axiosBool: true
                    })
                }
            })
        }
        // axios.get('/api/core/barCode/list', {
        //     params: {
        //         pageSize: 1000000,
        //         queryCondition: "EQ:taskId=" + this.props.match.params.id
        //     }
        // })
        // .then(ress => {
        //     this.setState({
        //         saomachanpin: ress.data.data,
        //         tuihui: tuihui.concat([]),
        //         optionchildlists: this.state.optionchildlists
        //     })
        // })
    }

    componentDidUpdate(prevProps, prevState) {
        const value = JSON.stringify(this.state.tuihui)
        sessionStorage.removeItem('tuihuijh');
        sessionStorage.setItem("tuihuijh", value);
        if (prevState.swipers != this.state.swipers) {
            this.state.swipers.forEach((v, i) => {
                this.state.swipersnum.forEach((a, b) => {
                    if (v.id == a.id) {
                        this.setState({
                            lenum: b
                        })
                    }
                })
            })
        }
        if (JSON.stringify(prevState.defaultsrc) != JSON.stringify(this.state.defaultsrc)) {
            if (document.getElementById('img1') != null) {
                document.getElementById('img1').style.height = 800 + 'px'
                document.getElementById('img1').style.position = 'static'
                this.setState({
                    downbool: false
                })
            }
        }
        if (JSON.stringify(prevState.optionchildlist) != JSON.stringify(this.state.optionchildlist)) {
            let arr = []
            if (this.state.optionchildlist) {
                this.state.optionchildlist.forEach(v => {
                    let count = true
                    arr.forEach(a => {
                        if (v.productName == a.productName) {
                            a.children.push(v)
                            count = false
                        }
                    })
                    if (count) {
                        count = false
                        arr.push({ productName: v.productName, children: [v], number: v.number, productCode: v.productCode })
                    }
                })
                for (let i = 0; i < arr.length; i++) {
                    if (i == 0) {
                        this.max = arr[i].children.length
                    } else {
                        if (this.max < arr[i].children.length) {
                            this.max = arr[i].children.length
                        }
                    }
                }
                const brr = []
                let count = 0
                arr.filter(v => {
                    if (v.productCode == undefined || v.productCode.length == 11) {
                        brr.push([])
                        count += 1
                    }
                    if (v.productCode != undefined) {
                        brr[count - 1].push(v)
                    }
                })
                brr.forEach(v => {
                    v.sort((a, b) => {
                        return Number(a.productCode.substring(a.productCode.length - 6, a.productCode.length)) - Number(b.productCode.substring(b.productCode.length - 6, b.productCode.length))
                    })
                });
                brr.reverse()
                let crr = []
                count = 0
                arr.reverse().filter(v => {
                    if (this.state.selectbool) {
                        crr = JSON.parse(JSON.stringify([...brr[0]]))
                    } else {
                        if (v.productCode == undefined) {
                            crr.push(v, ...brr[count])
                            count += 1
                        }
                    }
                })
                if (this.state.selectbool) {
                    crr[0].children[0].bool = true
                    crr[0].children[0].children = []
                    // crr.splice(-1,1)
                }
                crr.forEach(v => {
                    if (v.children) {
                        v.children.sort((a, b) => {
                            return Number(a.productCode.substring(a.productCode.length - 6, a.productCode.length)) - Number(b.productCode.substring(b.productCode.length - 6, b.productCode.length))
                        })
                    }
                })
                // axios.get('/api/core/barCode/list', {
                //     params: {
                //         pageSize: 1000000,
                //         queryCondition: "EQ:taskId=" + this.props.match.params.id
                //     }
                // })
                // .then(ress => {
                //     this.setState({
                //         saomachanpin: ress.data.data,
                //         tuihui: tuihui.concat([]),
                //         optionchildlists: this.state.optionchildlists
                //     })
                // })
                //标记
                this.setState({
                    optionchildlists: crr,
                    Colnum: this.max
                })
            }
        }
    }



    handleMouseMove = e => {
        const sltp = this
        document.getElementById('img1').style.position = 'absolute'
        let dom = document.getElementById('img1')
        this.setState({
            mobool: true
        })
        document.onmousemove = function (e) {
            if (sltp.state.mobool) {
                if (sltp.state.downbool) {
                    sltp.setState({
                        x: e.clientX - e.clientX - sltp.state.x,
                        y: e.clientY - e.clientY - sltp.state.y
                    })
                } else {
                    sltp.setState({
                        x: e.clientX - parseInt(dom.width) / 2,
                        y: e.clientY - parseInt(dom.height) / 2,
                    })
                }
            }
        }
    }


    handleScroll(e) {
        this.setState({
            downbool: true
        })
        var dom = document.getElementById('img1')
        if (e.nativeEvent.deltaY < 0) {
            dom.style.height = parseInt(dom.style.height) + 80 + 'px'
        } else {
            if (dom.style.height < 100) return
            dom.style.height = parseInt(dom.style.height) - 80 + 'px'
        }

    }

    max = null

    leiclick = (e) => {
        this.setState({
            selectbool: false
        })
        let dom = [...document.getElementById('lei').childNodes]
        dom.forEach(v => {
            v.style.color = '#15a7fb'
            v.style.background = 'none'
            if (v.innerHTML == e.target.innerHTML) {
                e.target.style.borderRadius = '5px'
                e.target.style.background = '#15a7fb'
                e.target.style.color = '#fff'
            }
        })
        this.setState({
            optionchilddefault: '',
            optionchildlist: ''
        })
        if (e.target.innerHTML == '全部') {
            this.setState({
                optionchild: [],
                optionchildname: '全部'
            })
            let arr = []
            this.state.option.forEach(v => {
                v.children.forEach(v => {
                    arr.push({ productName: v.productName, children: [], bool: true, number: v.number })
                    if (v.children != undefined) {
                        arr = arr.concat(v.children)
                    }
                })
            })
            this.setState({
                optionchildlist: arr
            })
        } else {
            this.setState({
                innerHTML: e.target.innerHTML
            })
            this.state.option.forEach(v => {
                if (e.target.innerHTML == v.productName) {
                    let arr = []
                    v.children.forEach(v => {
                        arr.push({ productName: v.productName, children: [], bool: true, number: v.number })
                        if (v.children != undefined) {
                            arr = arr.concat(v.children)
                        }
                    })
                    this.setState({
                        optionchild: v.children,
                        optionchildname: v.productName,
                        optionchilddefault: v.children ? v.children[0].id : '',
                        optionchildlist: arr
                    })
                }
            })
        }

    }

    handleChange = (value) => {
        this.setState({
            selectbool: true
        })
        this.state.optionchild.map(v => {
            if (v.id == value) {
                this.setState({
                    optionchildlist: [v, ...v.children]
                })
            }
        })
        this.setState({
            optionchilddefault: value
        })
    }

    content = function (name) {
        return (
            <div>
                <p>{name}</p>
            </div>
        );
    }

    ChangerefusedCount = (val) => {
        if (val == 0) {
            this.getimgData(this.state.id)
        } else {
            this.state.swiper = this.state.cloneswiper.filter((v, i) => {
                return v.refusedCount == val - 1
            })
            this.state.swipers = this.state.swiper.filter((v, i) => {
                return v.refusedCount == val - 1 && v.status == this.state.defaultsrc.status
            })
            this.state.defaultsrc = this.state.swipers.filter((v, i) => {
                return v.refusedCount == val - 1 && v.status == this.state.defaultsrc.status
            })[0]
            this.setState({
                swiper: this.state.swiper,
                swipers: this.state.swipers,
                defaultsrc: this.state.defaultsrc,
                num: 1,
                numbool: val,
            }, () => {
                let crr = this.state.swipersnum
                crr.forEach((v, i) => {
                    const arr = []
                    const brr = this.state.swiper.filter((v, i) => {
                        if (arr.indexOf(v.description) == -1 && v.description != null) {
                            arr.push(v.description)
                            return true
                        }
                        return false
                    })
                    brr.forEach((v, i) => {
                        let count = false
                        tuihui.forEach((a, b) => {
                            if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                                count = true
                            }
                        })
                        if (count) {
                            v.tuihuibool = true
                        } else {
                            v.tuihuibool = false
                        }
                    });
                    this.setState({
                        swipersnum: brr,
                        swipers: brr.filter((v, i) => {
                            if (v.description != null) {
                                return v.description == brr[brr.length - 1].description
                            }
                        }),
                        defaultsrc: brr.filter((v, i) => {
                            if (v.description != null) {
                                return v.description == brr[brr.length - 1].description
                            }
                        })[0]
                    })
                });
                this.setState({
                    swipersnum: crr
                })
            })
        }
    }

    deleteimg = () => {
        axios.delete('/api/core/issue/' + this.state.defaultsrc.id)
            .then(res => {
                message.success('删除成功')
                this.getimgData(this.state.id)
            })
    }

    left = function () {
        this.state.current = (this.state.current + 90) % 360;
        const doms = document.getElementsByClassName('imga')
        // doms[0].style.transform = 'rotate(' + this.state.current + 'deg)';
        // doms[1].style.transform = 'rotate(' + this.state.current + 'deg)';
        if (this.state.current / 90 % 2 == 0) {
            doms[0].style.transform = 'rotate(' + this.state.current + 'deg)'
            doms[1].style.transform = 'rotate(' + this.state.current + 'deg)'
        } else {
            doms[0].style.transform = 'rotate(' + this.state.current + 'deg)'
            doms[1].style.transform = 'rotate(' + this.state.current + 'deg)'
        }
    }

    right = function () {
        const doms = document.getElementsByClassName('imga')
        this.state.current = (this.state.current - 90) % 360;
        // doms[0].style.transform = 'rotate(' + this.state.current + 'deg)';
        // doms[1].style.transform = 'rotate(' + this.state.current + 'deg)';
        if (this.state.current / 90 % 2 == 0) {
            doms[0].style.transform = 'rotate(' + this.state.current + 'deg)'
            doms[1].style.transform = 'rotate(' + this.state.current + 'deg)'
        } else {
            doms[0].style.transform = 'rotate(' + this.state.current + 'deg)'
            doms[1].style.transform = 'rotate(' + this.state.current + 'deg)'
        }
    }

    importimg = () => {
        if (this.state.typeimg == null) {
            message.error('请选择类型')
            return
        }
        this.setState({ xiugaiModal: false })
        this.state.fileimgdata.forEach(v => {
            const obj = {
                taskId: this.props.match.params.id,
                status: this.state.typeimg == 0 ? 0 : this.state.typeimg == 1 ? 4 : 5,
                before: v.fullUrl,
                beforeMini: v.fullUrl,
            }
            axios.post('/api/core/issue', obj)
        })
        setTimeout(e => {
            message.success('添加成功')
            this.getimgData(this.state.id)
            this.setState({
                uplaodimg: null,
                typeimg: null,
                ismodel: false,
                fileimgdata: []
            })
        }, 1000)
    }

    uploadProps = {
        onRemove: (file) => {
            this.setState({
                FilelistAdd: []
            })
            return []
        },
        listType: "picture",
        maxCount: 100,
        multiple: true,
        beforeUpload: (file) => {
            const formData = new FormData()
            formData.append('file', file)
            this.state.timeIndex += 200
            setTimeout(() => {
                axios.post('/api/common/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then(res => {
                        this.state.timeIndex = 100
                        this.setState({
                            fileimgdata: [...this.state.fileimgdata, res.data[0]]
                        })
                    })
            }, this.state.timeIndex)
            return false
        },
        showUploadList: false,
        accept: ['.jpeg', '.jpg'],
    }

    componentWillUnmount() {
        clearInterval(this.state.Intervaltimer)
        axios.get('/api/core/task/genJhCheckScore/' + this.state.id)
            .then(res => {
                message.success()
            })
    }


    render() {
        const { x, y } = this.state
        return (
            <>
                <Modal
                    title='添加图片'
                    visible={this.state.ismodel}
                    footer={[
                        <Button onClick={this.importimg}>添加</Button>,
                        <Button onClick={() => { this.setState({ ismodel: false, uplaodimg: null, typeimg: null, fileimgdata: [] }) }}>关闭</Button>
                    ]}
                    closable={false}
                    destroyOnClose
                >
                    <Row>
                        <Col span='5'>
                            <font color='red'>*</font>选择照片
                        </Col>
                        <Col span='19'>
                            <Upload {...this.uploadProps} >
                                <Button icon={<UploadOutlined />}>选择图片</Button>
                            </Upload>
                        </Col>
                        <Col span='5'></Col>
                        <Col span='19'>
                            <Table scroll={{ y: 240 }} showHeader={false} columns={this.columns} dataSource={this.state.fileimgdata} style={{ padding: '0' }} pagination={false} />
                        </Col>
                        <Col span='5' style={{ margin: '10px 0' }}>
                            <font color='red'>*</font>选择类型
                        </Col>
                        <Col span='19' style={{ margin: '10px 0' }}>
                            <Select style={{ width: 120 }} onChange={e => { this.setState({ typeimg: e }) }}>
                                {
                                    Object.keys(this.state.labelValues).map(v => {
                                        return <Option value={v}>{this.state.labelValues[v]}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    title={'查看退回重传照片'}
                    visible={this.state.uploadbool}
                    footer={[
                        <Button onClick={e => { this.setState({ uploadbool: false }) }}>关闭</Button>
                    ]}
                    bodyStyle={{ height: '500px', overflow: 'auto' }}
                    closable={false}
                    width='80%'
                >
                    {
                        this.state.swiper.filter(v => {
                            return v.uploadFlag == 1
                        }).map(v => {
                            return <Row style={{ borderBottom: '1px solid #000', marginBottom: '7px' }}>
                                <Col span='4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {v.id}
                                </Col>
                                <Col span='10'>
                                    <img src={audioUrl + v.before} style={{ width: '80%', height: 'auto' }} />
                                    {//
                                    }
                                </Col>
                            </Row>
                        })
                    }
                </Modal>
                <Modal title="" visible={this.state.isModalVisible} footer={[]}>
                    已到最后一张
                </Modal>
                <div style={{ display: 'none' }}>
                    <Image.PreviewGroup preview={{ visible: this.state.visible, onVisibleChange: (vis, prevVisible) => this.setState({ visible: vis }) }}>
                        {
                            //<img id='imga' style={{ width: '100%', height: 'auto' }} src={audioUrl + this.state.defaultsrc.before} onClick={this.showModal} />
                            JSON.stringify(this.state.swipers) != '{}' && this.state.swipers.length != 0 ?
                                <Image
                                    preview={{ visible: false }}
                                    width='100%'
                                    src={audioUrl + this.state.defaultsrc.before}
                                    onClick={() => this.setState({ visible: true })}
                                />
                                // <ImageMagnifier minImg={audioUrl + this.state.defaultsrc.before} maxImg={audioUrl + this.state.defaultsrc.before} />
                                : <img style={{ width: '100%', height: 'auto' }} src={loading} />
                        }
                    </Image.PreviewGroup>
                </div>
                <Col span='24' style={{ border: '1px solid rgba(233, 233, 233, 1)', borderRadius: '5px', marginTop: '20px' }}>
                    <div style={{ padding: '10px 20px', borderBottom: '1px solid rgba(233,233,233, 1)', display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                            调研素材<CloudDownloadOutlined />{/* 未加图片 标记 */}
                        </span>
                        <span>
                            {
                                sessionStorage.getItem('Completed') != 'true' ? <>
                                    <Popconfirm
                                        title="您确认删除这张图片么"
                                        onConfirm={e => this.deleteimg()}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button style={{ margin: '0 10px' }} >删除当前照片</Button>
                                    </Popconfirm>
                                    <Button style={{ margin: '0 10px' }} onClick={e => { this.setState({ ismodel: true }) }}>添加照片</Button>
                                    <Button style={{ margin: '0 10px' }} onClick={e => {
                                        this.state.defaultsrc.uploadFlag = 1
                                        const isbool = this.state.defaultsrc.isbool
                                        delete this.state.defaultsrc.isbool
                                        axios.put('/api/core/issue', {
                                            ...this.state.defaultsrc,
                                        })
                                            .then(res => {
                                                this.state.defaultsrc.isbool = isbool
                                                message.success('添加退回重新上传成功')
                                            })
                                    }}>重新上传照片
                                    </Button>
                                    <Button style={{ margin: '0 10px' }} onClick={e => { this.setState({ uploadbool: true }) }}>查看重新上传照片</Button>
                                </> : null
                            }

                            <Select style={{ width: 120, margin: '0 10px' }} defaultValue='0' onChange={e => this.ChangerefusedCount(e)}>
                                <Option value="0">全部显示</Option>
                                <Option value="1">原始照片</Option>
                                {
                                    JSON.stringify(this.state.refusedCountArr) != "[]" ?
                                        this.state.refusedCountArr.map((v, i) => {
                                            return <Option value={i + 2}>{v + '次退回照片'}</Option>
                                        }) : null
                                }
                            </Select>
                        </span>
                    </div>
                    <Row style={{ padding: '10px 20px' }}>
                        <Col span='16' style={{ padding: '20px', position: 'relative' }}>
                            <div style={{ display: 'flex' }}>
                                {//图片放大窗口
                                    //<ZoomInOutlined onClick={this.showModal} style={{ marginRight: '10px', fontSize: '35px' }} />
                                }
                                <UndoOutlined onClick={e => { this.right() }} style={{ marginRight: '10px', fontSize: '25px' }} />
                                <RedoOutlined onClick={e => { this.left() }} style={{ marginRight: '10px', fontSize: '25px' }} />
                                <p style={{ flex: '1', textAlign: 'center' }}>{this.state.swipers.length != undefined ? this.state.num + '/' + this.state.swipers.length : '0/0'}</p>
                                <span>{this.state.defaultsrc ? this.state.defaultsrc.refusedCount != 0 ? '第' + this.state.defaultsrc.refusedCount + '次退回的照片' : '原始照片' : null}</span>
                            </div>
                            <div style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                position: 'relative',
                                overflow: 'none',
                                listSstyle: 'none',
                                padding: 0,
                                zIndex: 1,
                            }}>
                                <div>
                                    <div onClick={this.leftsrc} style={{ width: '50px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '0', zIndex: '1000' }}><ArrowLeftOutlined style={{ fontSize: '40px', color: '#fff' }} /></div>
                                    {
                                        JSON.stringify(this.state.swipers) != '{}' && this.state.swipers.length != 0 ?
                                            // <Image
                                            //     preview={{ visible: false }}
                                            //     width='100%'
                                            //     src={audioUrl + this.state.defaultsrc.before}
                                            //     onClick={() => this.setState({ visible: true })}
                                            // />
                                            <ImageMagnifier minImg={audioUrl + this.state.defaultsrc.before} maxImg={audioUrl + this.state.defaultsrc.before} />
                                            : <img style={{ width: '100%', height: 'auto' }} src={loading} />
                                    }
                                    <div onClick={this.rightsrc} style={{ width: '50px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '750px', top: '0', zIndex: '1000' }} ><ArrowRightOutlined style={{ fontSize: '40px', color: '#fff' }} /></div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px', height: '120px' }}>
                                <div onClick={this.leftsrc} style={{ width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: this.state.num == 1 ? '#555' : 'rgba(166,166,166,1)' }}><ArrowLeftOutlined /></div>
                                <Swiper
                                    style={{ flex: '1' }}
                                    slidesPerView={4}
                                    initialSlide={this.state.num}
                                >
                                    {
                                        JSON.stringify(this.state.swipers) != '{}' ? this.state.swipers.map((val, i) => {
                                            return <SwiperSlide style={{ background: this.state.num - 1 == i ? '#ddd' : 'none', padding: '0 10px' }} onClick={() => { this.clisrc(val, i) }}><img style={{ width: '100%', height: '100%' }} src={audioUrl + val.before} /></SwiperSlide>
                                        }) : null
                                    }
                                </Swiper>
                                <div onClick={this.rightsrc} style={{ width: '50px', background: 'rgba(166,166,166,1)', display: 'flex', justifyContent: 'center', alignItems: 'center', background: this.state.num == this.state.swipers.length ? '#555' : 'rgba(166,166,166,1)' }} ><ArrowRightOutlined /></div>
                            </div>
                            <div>
                                图片ID:{this.state.defaultsrc?.id}
                            </div>
                        </Col>
                        <Col span='8' style={{ paddingLeft: '20px' }}>{/* 右侧 tabbar */}
                            <Tabs defaultActiveKey='0' onChange={this.callback}>
                                {JSON.stringify(this.state.labelValues) != "{}" ? Object.keys(this.state.labelValues).map((v, i) => {
                                    return (
                                        <TabPane tab={this.state.labelValues[v] + ' ' + this.state.swiper.filter(a => { return a.status == (v == 0 ? 0 : parseInt(v) + 3) }).length} key={i + 1 + ""}></TabPane>
                                    )
                                }) : null}
                            </Tabs>
                            <Row style={{ overflow: 'auto', fontSize: '15px' }}>
                                <Col span='5' style={{ borderBottom: '1px solid #ccc', lineHeight: '30px', fontWeight: '550' }}>所属类目:</Col>
                                <Col span='19' style={{ borderBottom: '1px solid #ccc' }} id='lei' onClick={e => this.leiclick(e)}>
                                    <span style={{ padding: '5px', color: '#15a7fb', lineHeight: '30px' }}>全部</span>
                                    {
                                        this.state.option.map(v => {
                                            return <span style={{ padding: '5px', color: '#15a7fb', lineHeight: '30px' }}>{v.productName}</span>
                                        })
                                    }
                                </Col>
                                <Col span='5' style={{ lineHeight: '40px' }}>{this.state.optionchildname}:</Col>
                                <Col span='19' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Select disabled={!(this.state.optionchild && JSON.stringify(this.state.optionchild) != '[]')} value={this.state.optionchilddefault} style={{ width: 120 }} onChange={this.handleChange}>
                                        {
                                            this.state.optionchild ? this.state.optionchild.map(v => {
                                                return <Option value={v.id}>{v.productName}</Option>
                                            }) : null
                                        }
                                    </Select>
                                </Col>
                                <Col span='5' style={{ borderBottom: '1px solid #ccc', lineHeight: '30px', fontWeight: '550' }}>产品列表:</Col>
                                <Col span='19' style={{ borderBottom: '1px solid #ccc', textAlign: 'center' }}>
                                    规格/状态
                                </Col>
                                <div style={{ overflow: 'auto', height: '800px' }}>
                                    <Row>
                                        {
                                            JSON.stringify(this.state.optionchildlists) != '[]' ? this.state.optionchildlists.map((v, i) => {
                                                return <>
                                                    <Col span='5' style={{ borderBottom: '1px solid #ccc', background: v.children[0].bool ? '#ccc' : 'none', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
                                                        <Popover content={this.content(v.productName)}><span style={{ fontSize: v.children[0].bool ? '15px' : '12px' }}>{v.productName}</span></Popover>

                                                    </Col>
                                                    {
                                                        JSON.stringify(this.state.optionchildlists) != '[]' && JSON.stringify(this.state.optionchildlists[i].children[0].children) != '[]' ? this.state.optionchildlists[i].children.map(a => {
                                                            return <Col span={parseInt(19 / this.state.Colnum)} style={{ borderBottom: '1px solid #ccc', borderLeft: '1px solid #ccc', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                                <div style={{ width: '20px', height: '20px', background: a.saohuobool ? a.tuihuibool ? a.updatabool ? 'none' : '#aaa' : a.updatabool ? '#ffac5a' : '#3dc6ff' : 'none', border: !a.saohuobool ? '0.5px solid #000' : a.updatabool ? '0.5px solid #000' : 'none', borderRadius: '50%', display: 'inline-block' }} onClick={() => this.backbool(a, v)}></div>
                                                                <p>{a.specification}</p>
                                                                <p>{a.price != 0 && a.price ? a.price + '元' : null}</p>
                                                            </Col>
                                                        }) : null
                                                    }
                                                    {
                                                        v.children[0].bool ? <Col span={parseInt(19 / this.state.Colnum)} style={{ background: v.children[0].bool ? '#ccc' : 'none', borderBottom: '1px solid #ccc', borderLeft: '1px solid #ccc', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>{v.number != undefined ? '扫码数量:' + v.number[0] + '  修改数量:' + v.number[1] : null}</Col> : null
                                                    }
                                                    {
                                                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(a => {
                                                            if (this.state.Colnum - this.state.optionchildlists[i].children.length - a > 0) {
                                                                return <Col span={parseInt(19 / this.state.Colnum)} style={{ background: v.children[0].bool ? '#ccc' : 'none', borderBottom: '1px solid #ccc', borderLeft: '1px solid #ccc', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}></Col>
                                                            }
                                                        })
                                                    }
                                                </>
                                            }) : null//
                                        }
                                    </Row>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}

export default withRouter(App)