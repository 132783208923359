import React, { useEffect, useState, useRef } from 'react';
import { HashRouter, Switch, Route, useHistory, useParams, } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, Input, Modal, DatePicker, Upload, Popconfirm, AutoComplete } from 'antd'
import { EditOutlined, PlusOutlined, TrophyOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import axios1 from 'axios'
import { baseUrl } from '@/utils/config.js'
import moment from 'moment'
import message from '@/utils/message'
import muban from '@/assets/gdsc.xlsx'
import PopConfirmOnly from "@/utils/PopConfirmOnly";
import store from '@/redux/store'

const { Option } = Select;
const { TextArea } = Input;
const App = (props) => {

    const [list, setlist] = useState([])//表格数据
    const [projectId, setprojectId] = useState(useParams().projectid)//id
    const [selectedRowKeys, setselectedRowKeys] = useState([])//复选框选中
    const [tablebool, settablebool] = useState([])//显示隐藏table
    const [surveyScopelist, setsurveyScopelist] = useState('')  // 项目区域数据
    const [number, setnumber] = useState('')  // 计数

    const [isbool, setIsbool] = useState(false)

    const [refresh, setRefresh] = useState(false);

    useEffect(() => { //强制渲染页面
        refresh && setTimeout(() => setRefresh(false))
    }, [refresh])

    const requestlist = () => {
        const params = {
            page,
            pageSize,
            projectId: projectId,
        }
        if (seleaddress) params.surveyScope = seleaddress
        if (rens) params.principalId = rens
        if (overstate) params.status = overstate
        if (input1) params.surveyTarget = input1
        if (numberinp) params.surveyCode = numberinp
        if (agentIds) params.agentId = agentIds
        if (audit2ByIds) params.audit2Id = audit2ByIds
        if (reviewByIds) params.reviewId = reviewByIds
        if (time.length !== 0) {
            params.createdAtFrom = moment(time[0]).format('YYYYMMDD')
            params.createdAtTo = moment(time[1]).format('YYYYMMDD')
        }
        
        axios.get('/api/core/task/innerList', {//数据
            params: {
                ...params,
                userId: store.getState().user.uid
            },
        }).then(res => {
            setTotal(res.data.totalSize)
            console.log(res)
            axios.get('/api/sys/dict/' + 'taskStatus')
                .then(ress => {
                    setokstate(ress.data.labelValues)
                    let count = true
                    res.data.data.forEach((v, i) => {
                        v.key = i + Math.random()
                        v.lablelValues = ress.data.labelValues
                        if (ress.data.labelValues[v.status] != '已完成') {
                            count = false
                        }
                    });

                    if (count && number) {
                        if (okstate[overstate] != '已完成') {
                            setnumber(false)
                            axios.get('/api/core/task/genJhDsrScore/' + projectId)
                                .then(res => {
                                    message.success('门店已全部完成,算分成功');
                                })
                        }

                    }
                    setlist(res.data.data)
                })
        })
    }

    const [pageSize,setPageSize] = useState(5)//显示数据数量
    const [page,setPage] = useState(1)//数据页数
    const [total,setTotal] = useState(null)//数据总是
  
    useEffect(()=>{
        requestlist()
    },[pageSize,page])
  

    const appmubanfun = () => {
        axios.get('/api/core/appTemplate/list', {
            params: {
                pageSize: 1000000
            }
        })
            .then(res => {
                setappmuban(res.data.data)
            })
        axios.get('/api/core/task/scope', { params: { projectId } })
            .then(res => {
                setsurveyScopelist(res.data)
            })
    }

    const [okstate, setokstate] = useState({})//完成状态

    const [projectData, setprojectData] = useState({})

    useEffect(() => {
        (
            async () => {
                const res = await axios.get('/api/sys/dict/' + 'taskStatus')
                requestlist()
                setokstate(res.data.labelValues)
                appmubanfun()
                const resson = await axios.get('/api/core/project/' + projectId)
                setprojectData([])
                axios.get('/api/sys/user/' + store.getState().user.uid)
                    .then(res => {
                        axios.get('/api/sys/user/group/org', {
                            params: {
                                id: store.getState().user.uid
                            }
                        })
                            .then(resc => {
                                resc.data.forEach((v, i) => {
                                    if (v.groupId == resson.data.userGroupId) {
                                        console.log(v.roles)
                                        v.roles.forEach((v, i) => {
                                            if (v.name == '家化_管理员') {
                                                setIsbool(true)
                                            }
                                        });
                                    } else if (v.roles.length != 0 && v.roles[0].name == '超级管理员') {
                                        setIsbool(true)
                                    }
                                });
                                setRefresh(true)
                            })
                    })
            }
        )()
    }, [])

    const alldel = () => {
        let arr = []
        selectedRowKeys.forEach((v, i) => {
            list.forEach((a, b) => {
                if (v == a.key) {
                    arr.push(a.id)
                }
            });
        });
        axios.delete('/api/core/task/batch', {
            data: arr
        })
            .then(res => {
                requestlist()
            })
    }

    const removedata = (record) => {
        axios.delete('/api/core/task/' + record.id)
            .then(res => {
                requestlist()
                message.success('删除成功');
            })
    }

    const [columns, setColumns] = useState([//table样式
        {
            title: '调研编号',
            dataIndex: 'surveyCode',
            key: 'surveyCode',
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            ),
            sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
        },
        {
            title: '项目区域',
            dataIndex: 'surveyScope',
            key: 'surveyScope',
            sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        {
            title: '调研对象',
            dataIndex: 'surveyTarget',
            key: 'surveyTarget',
            sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        {
            title: '门店类型',
            dataIndex: 'shopLevel',
            key: 'shopLevel',
            sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        {
            title: '访问员',
            dataIndex: 'principal',
            key: 'principal',
            sorter: (a, b) => a.principal?.localeCompare(b.principal),
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        {
            title: '代理人',
            dataIndex: 'agent',
            key: 'agent',
            sorter: (a, b) => a.agent?.localeCompare(b.agent),
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        {
            title: '二审人员',
            dataIndex: 'audit2ByName',
            key: 'audit2ByName',
            sorter: (a, b) => a.audit2ByName?.localeCompare(b.audit2ByName),
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        {
            title: '复核人员',
            dataIndex: 'reviewByName',
            key: 'reviewByName',
            sorter: (a, b) => a.reviewByName?.localeCompare(b.reviewByName),
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        // {
        //   title: '自动审核通过',
        //   dataIndex: 'taskAudit2Status',
        //   render: text => text.display == '已自动审核' ? text.display : '-',
        // },
        {
            title: '完成状态',
            key: 'status',
            render: (text, record) => (
                <>
                    {
                        record.lablelValues != undefined && record.lablelValues != null ? Object.keys(record.lablelValues).map((v, i) => {
                            if (v == record.status) {
                                return Object.values(record.lablelValues)[i]
                            }
                        }) : null

                    }
                </>
            )
        },
        {
            title: '考核得分',
            dataIndex: 'checkScore',
            key: 'checkScore',
            sorter: (a, b) => a.checkScore-b.checkScore,
            render: (text, record) => (
                <>
                    {
                        text ? (text * 100).toFixed(1) + '%' : '- -'
                    }
                </>
            )
        },
        {
            title: '照片',
            dataIndex: 'issueCount',
            key: 'issueCount',
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        {
            title: '录音',
            dataIndex: 'recordCount',
            key: 'recordCount',
            render: (text, record) => (
                <>
                    {
                        text ? text : '- -'
                    }
                </>
            )
        },
        {
            title: '操作',
            key: 'key',
            render: (text, record) => (<>
                <span style={{ margin: '0 10px', color: '#3ab6fe' }} onClick={() => { updatalist(record) }}>修改</span>
                <PopConfirmOnly confirm={e => removedata(record)}>
                    <Button type='link' size='small'>删除</Button>
                </PopConfirmOnly>
            </>
            )
        }
    ])
    //

    const colum = [{
        title: '调研编号',
        dataIndex: 'surveyCode',
        key: 'surveyCode',
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        ),
        sorter: (a, b) => a.surveyCode?.localeCompare(b.surveyCode),
    },
    {
        title: '项目区域',
        dataIndex: 'surveyScope',
        key: 'surveyScope',
        sorter: (a, b) => a.surveyScope?.localeCompare(b.surveyScope),
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        )
    },
    {
        title: '调研对象',
        dataIndex: 'surveyTarget',
        key: 'surveyTarget',
        sorter: (a, b) => a.surveyTarget?.localeCompare(b.surveyTarget),
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        )
    },
    {
        title: '门店类型',
        dataIndex: 'shopLevel',
        key: 'shopLevel',
        sorter: (a, b) => a.shopLevel?.localeCompare(b.shopLevel),
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        )
    },
    {
        title: '访问员',
        dataIndex: 'principal',
        key: 'principal',
        sorter: (a, b) => a.principal?.localeCompare(b.principal),
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        )
    },
    {
        title: '代理人',
        dataIndex: 'agent',
        key: 'agent',
        sorter: (a, b) => a.agent?.localeCompare(b.agent),
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        )
    },
    // {
    //   title: '自动审核通过',
    //   dataIndex: 'taskAudit2Status',
    //   render: text => text.display == '已自动审核' ? text.display : '-',
    // },
    {
        title: '完成状态',
        key: 'status',
        render: (text, record) => (
            <>
                {
                    record.lablelValues != undefined && record.lablelValues != null ? Object.keys(record.lablelValues).map((v, i) => {
                        if (v == record.status) {
                            return Object.values(record.lablelValues)[i]
                        }
                    }) : null

                }
            </>
        )
    },
    {
        title: '照片',
        dataIndex: 'issueCount',
        key: 'issueCount',
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        )
    },
    {
        title: '录音',
        dataIndex: 'recordCount',
        key: 'recordCount',
        render: (text, record) => (
            <>
                {
                    text ? text : '- -'
                }
            </>
        )
    },
    ]
    //

    const handleSelectChange = e => {
        setselectedRowKeys(e)
    }

    const handleClearSelect = () => {
        setselectedRowKeys([])
    }

    const [visible, setvisible] = useState(false)
    const [addandupdatabool, setaddandupdatabool] = useState(false)

    const showModal = () => {
        setvisible(true)
        setaddandupdatabool(true)
    };


    const [surveyCode, setsurveyCode] = useState('')//调研编号
    const [surveyTarget, setsurveyTarget] = useState('')//调研对象
    const [code, setcode] = useState('')//工作单号
    const [surveyScope, setsurveyScope] = useState('')//项目区域
    const [agent, setagent] = useState('')//代理人
    const [agentId, setagentId] = useState('')//代理人ID
    const [startPointDesc, setstartPointDesc] = useState('')//起始地点
    const [startPoint, setstartPoint] = useState('')//起始地点x y轴
    const [endPointDesc, setendPointDesc] = useState('')//结束地点
    const [endPoint, setendPoint] = useState('')//结束地点x y轴
    const [endAt, setendAt] = useState('')//结束时间
    const [appId, setappId] = useState('')//appid
    const [description, setdescription] = useState('')
    const [targetId, settargetId] = useState('')

    const [updataid, setupdataid] = useState(null)

    const handleOk = e => {
        if (surveyCode != '' && surveyTarget != '' && surveyScope != ''
            && dlsdate.length != 0 && description != '') {
            if (addandupdatabool) {
                if (!appId) return
                axios.post('/api/core/task', {
                    projectId,
                    surveyCode,
                    surveyTarget,
                    surveyScope,
                    startPointDesc,
                    endPointDesc,
                    planStartAt: moment(dlsdate[0]._d).format('YYYY-MM-DD HH:mm:ss.SSS'),
                    planEndAt: moment(dlsdate[1]._d).format('YYYY-MM-DD HH:mm:ss.SSS'),
                    agent,
                    agentId,
                    appId,
                    description,
                    targetId,
                    startPoint,
                    endPoint
                }, { params: { type: 1 } }).then(res => {
                    requestlist()
                    message.success('添加完成')
                })
            } else {
                axios.put('/api/core/task/updCms', {
                    id: updataid,
                    surveyCode,
                    surveyTarget,
                    surveyScope,
                    startPointDesc,
                    endPointDesc,
                    planStartAt: moment(dlsdate[0]._d).format('YYYY-MM-DD HH:mm:ss.SSS') != 'Invalid date' ? moment(dlsdate[0]._d).format('YYYY-MM-DD HH:mm:ss.SSS') : null,
                    planEndAt: moment(dlsdate[1]._d).format('YYYY-MM-DD HH:mm:ss.SSS') != 'Invalid date' ? moment(dlsdate[1]._d).format('YYYY-MM-DD HH:mm:ss.SSS') : null,
                    agent,
                    agentId,
                    description,
                    targetId,
                    appId,
                    startPoint,
                    endPoint
                }, { params: { type: 1 } }).then(res => {
                    requestlist()
                    message.success('修改完成')
                })
            }
            setvisible(false)
            setsurveyCode('')
            setsurveyTarget('')
            setsurveyScope('')
            setstartPointDesc('')
            setendPointDesc('')
            setagent('')
            setagentId('')
            setstartPoint('')
            setendPoint('')
            setdlsdate([])
            setappId('')
            setdescription('')
            settargetId('')
        } else {
            message.error('请完整填写信息,再次提交')
        }
    };

    const handleCancel = e => {
        setvisible(false)
        setsurveyCode('')
        setsurveyTarget('')
        setsurveyScope('')
        setstartPointDesc('')
        setendPointDesc('')
        setagent('')
        setagentId('')
        setstartPoint('')
        setendPoint('')
        setdlsdate([])
        setappId('')
        setdescription('')
        settargetId('')
    };

    const updatalist = (record) => {
        axios.get('/api/core/task/' + record.id)
            .then(res => {
                setupdataid(res.data.id)
                setsurveyCode(res.data.surveyCode)//调研编号
                setsurveyTarget(res.data.surveyTarget)//调研对象
                setsurveyScope(res.data.surveyScope)
                setstartPointDesc(res.data.startPointDesc)
                setendPointDesc(res.data.endPointDesc)
                setdlsdate([moment(res.data.planStartAt), moment(res.data.planEndAt)])
                setagent(res.data.agent)
                setagentId(res.data.agentId)
                setaddandupdatabool(false)
                setvisible(true)
                setstartPoint(res.data.startPoint)
                setendPoint(res.data.endPoint)
                setappId(res.data.appId)
                setdescription(res.data.description)
                settargetId(res.data.targetId)
                setprojectData([res.data.planStartAt, res.data.planEndAt])
                console.log(res)
                console.log([res.data.planStartAt, res.data.planStartAt])
            })
    }


    const [time, settime] = useState('')//时间/
    const [overstate, setoverstate] = useState(null)//完成状态
    const [seleaddress, setseleaddress] = useState(null)//调研区域
    const [input1, setinput1] = useState('')//调研对象
    const [numberinp, setnumberinp] = useState('')//调研编号
    const [rens, setrens] = useState(null)//访问员
    const [agentIds, setagentIds] = useState(null)//代理
    const [audit2ByIds, setaudit2ByIds] = useState(null)//二审
    const [reviewByIds, setreviewByIds] = useState(null)//复核

    const [dlsdate, setdlsdate] = useState([]) // 用于控制时间的
    const [appmuban, setappmuban] = useState([])//app模板

    useEffect(() => {
        if (!time && !overstate && !seleaddress && !input1 && !numberinp && !rens) {
            requestlist()
        }
    }, [time, overstate, seleaddress, input1, numberinp, rens])

    const resetfrom = (e) => {
        settime('')
        setoverstate(null)
        setseleaddress(null)
        setinput1('')
        setnumberinp('')
        setrens(null)
        setagentIds(null)
        setaudit2ByIds(null)
        setreviewByIds(null)
    }

    const [loadvisible, setloadvisible] = useState(false)
    const [fileListAdd, setFileListAdd] = useState([])

    const loadhandleOk = () => {
        const formData = new FormData()
        formData.append('file', fileListAdd[0])
        axios.post('/api/core/task/import',
            formData,
            {
                params: { projectId },
            })
            .then(res => {
                message.success()
                requestlist()
            })
        setloadvisible(false)
    }

    const loadhandleCancel = () => {
        setloadvisible(false)
    }

    const uploadProps = {//导入按钮属性
        onRemove: (file) => {
            setFileListAdd([])
            return []
        },
        beforeUpload: (file) => {
            setFileListAdd([file])
            return false
        }
    }

    const exports = () => {
        axios.get('/api/core/task/innerList', {
            params: {
                projectId: projectId,
                pageSize: 1000000,
                export: true,
                userId: store.getState().user.uid
            },
            responseType: 'blob'
        }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "导出门店列表.xlsx");
            document.body.appendChild(link);
            link.click();
        })
    }

    const updata = e => {
        const arr = []
        list.forEach(v => {
            if (JSON.stringify(selectedRowKeys).indexOf(v.key) != -1) arr.push(v.id)
        })
        axios.put('/api/core/task/updApp/batch',
            arr
            , {
                params: {
                    appTempId: appId
                }
            })
            .then(res => {
                message.success('修改完成')
                setIsModalVisible(false);
                setappId(null)
            })
    }

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModalApp = () => {
        setIsModalVisible(true);
    };

    const handleCancelApp = () => {
        setIsModalVisible(false);
        setappId(null)
    };
    
    const [arr,setArr] = useState([])

    return (
        <div style={{ padding: '30px' }}>
            <Modal title="批量修改App模板" visible={isModalVisible} onOk={updata} onCancel={handleCancelApp}>
                <Row>
                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>App模板:</Col>
                    <Col span='16'>
                        <Select value={appId} onChange={(e) => { setappId(e) }} style={{ width: '100%', }}> {
                            appmuban.map((v, i) => {
                                return <Option value={v.id}> {v.appTemplateName}</Option>
                            })
                        }</Select>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title={addandupdatabool ? '新增' : '修改'}
                visible={visible}
                onOk={() => { handleOk() }}
                onCancel={() => { handleCancel() }}
                okText={addandupdatabool ? '新增' : '修改'}
                cancelText='取消'
                destroyOnClose={true}
                maskClosable={false}
            >
                <Row>
                    {/* <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}><font color='red'>*</font>工单编号:</Col>
                    <Col span='16'><Input value={code} onChange={(e) => { setcode(e.target.value) }} placeholder="请输入工单编号" /></Col>

                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}><font color='red'>*</font>调研编号:</Col>
                    <Col span='16'><Input value={surveyCode} onChange={(e) => { setsurveyCode(e.target.value) }} placeholder="请输入调研编号" /></Col>

                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}><font color='red'>*</font>调研对象:</Col>
                    <Col span='16'><Input value={surveyTarget} onChange={(e) => { setsurveyTarget(e.target.value) }} placeholder="请输入调研对象" /></Col>

                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}><font color='red'>*</font>项目区域:</Col>
                    <Col span='16'><Input value={surveyScope} onChange={(e) => { setsurveyScope(e.target.value) }} placeholder="请输入项目区域" /></Col> */}

                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}><font color='red'>*</font>位置选择:</Col>
                    <Col span='16'>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="请选择地点"
                            optionFilterProp="children"
                            value={targetId}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } onChange={(e) => {
                                const address = props.locationlist.filter(v => {
                                    return v.id == e
                                })
                                settargetId(e)
                                setsurveyScope(address[0].surveyScope)
                                setsurveyCode(address[0].shopCode)
                                setcode(address[0].code)
                                setsurveyTarget(address[0].shopName)
                                setstartPointDesc(address[0].address)
                                setendPointDesc(address[0].address)
                                setstartPoint(address[0].latitude + ',' + address[0].longitude)
                                setendPoint(address[0].latitude + ',' + address[0].longitude)
                            }} style={{ width: '100%', }} >
                            {
                                props.locationlist.map((v, i) => {
                                    return <Option key={v.id} value={v.id}>{v.shopCode}</Option>
                                })
                            }
                        </Select>
                    </Col>
                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}><font color='red'>*</font>起始/结束时间:</Col>
                    <Col span='16'>
                        <DatePicker.RangePicker
                            showTime={false}
                            value={dlsdate}
                            onChange={(e) => { setdlsdate(e) }}
                            style={{ width: '100%', }}
                            disabledDate={(current) => {
                                console.log(projectData)
                                console.log(current)
                                console.log(moment(projectData[0]), projectData[0])
                                console.log(moment(projectData[1]), projectData[1])
                                return current < moment(projectData[0]) || current > moment(projectData[1])// 选择时间要大于等于当前天。若今天不能被选择，去掉等号即可。
                            }}
                        >
                        </DatePicker.RangePicker>
                    </Col>

                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}>< font color='red'> *</font>App模板:</Col>
                    <Col span='16'>
                        <Select value={appId} onChange={(e) => { setappId(e) }} style={{ width: '100%', }}> {
                            appmuban.map((v, i) => {
                                return <Option value={v.id}> {v.appTemplateName}</Option>
                            })
                        }</Select>
                    </Col>
                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}><font color='red'>*</font>代理人:</Col>
                    <Col span='16'>
                        <Select value={agent} onChange={(e) => { setagent(JSON.parse(e).name); setagentId(JSON.parse(e).id) }} style={{ width: '100%', }} >
                            {
                                props.daililist.map((v, i) => {
                                    return <Option value={JSON.stringify(v)}>{v.name}</Option>
                                })
                            }
                        </Select>
                    </Col>

                    <Col span='8' style={{ textAlign: 'right', lineHeight: '30px', paddingRight: '10px', marginBottom: '10px' }}><font color='red'>*</font>描述:</Col>
                    <Col span='16'><TextArea rows={4} showTime value={description} onChange={(e) => { setdescription(e.target.value) }} style={{ width: '100%', }} /></Col>

                </Row>
            </Modal>
            {/* 上面那个是添加的模态框 */}
            <Modal
                title='批量导入'
                visible={loadvisible}
                onOk={() => { loadhandleOk() }}
                onCancel={() => { loadhandleCancel() }}
                okText='导入'
                cancelText='取消'
                destroyOnClose={true}
                maskClosable={false}
            >
                <Row>
                    <Col span='6' style={{ textAlign: 'right', lineHeight: '30px' }}><font color='red'>*</font>导入门店:</Col>
                    <Col span='12'><Upload {...uploadProps}><Button>点击导入</Button></Upload></Col>
                    <Col span='6' style={{ textAlign: 'right' }}><Button href={muban}>下载模板</Button></Col>
                </Row>
            </Modal>
            <div style={{ padding: '30px', background: 'white' }}>
                <Row justify='space-between' gutter={[20, 20]} style={{ marginBottom: '20px' }}>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>调研编号</span>
                        <Input value={numberinp} onChange={(e) => { setnumberinp(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>项目区域</span>
                        <AutoComplete
                            onChange={e => { console.log(e); setseleaddress(e); }}
                            style={{ width: '240px', marginLeft: '10px' }}
                            options={Object.values(surveyScopelist).map(v => { return { value: v } })}
                            value={seleaddress}
                        />
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>调研对象</span>
                        <Input value={input1} onChange={(e) => { setinput1(e.target.value) }} style={{ width: '240px', marginLeft: '10px' }}></Input>
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px', marginRight: '16px' }}>访问员</span>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={rens} onChange={(e) => { setrens(e) }} style={{ width: '240px', marginLeft: '10px' }} >
                            <Select.Option value={''}>取消选择</Select.Option>
                            {
                                props.userlist.map((v, i) => {
                                    return <Option value={v.id}>{v.name}</Option>
                                })
                            }
                        </Select>
                    </Col>
                    {
                        store.getState().user.role != '代理人' ?
                            <Col span={8}>
                                <span style={{ lineHeight: '30px', marginRight: '16px' }}>代理人</span>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={agentIds} onChange={(e) => { setagentIds(e) }} style={{ width: '240px', marginLeft: '10px' }} >
                                    <Select.Option value={''}>取消选择</Select.Option>
                                    {
                                        props.proxylist.map((v, i) => {
                                            return <Option value={v.id}>{v.name}</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        : null
                    }
                    {
                        store.getState().user.role != '代理人' ?
                            <Col span={8}>
                                <span style={{ lineHeight: '30px' }}>二审人员</span>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={audit2ByIds} onChange={(e) => { setaudit2ByIds(e) }} style={{ width: '240px', marginLeft: '10px' }} >
                                    <Select.Option value={''}>取消选择</Select.Option>
                                    {
                                        props.checkerlist.map((v, i) => {
                                            return <Option value={v.id}>{v.name}</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        : null
                    }
                    {
                        store.getState().user.role != '代理人' ?
                            <Col span={8}>
                                <span style={{ lineHeight: '30px' }}>复核人员</span>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={reviewByIds} onChange={(e) => { setreviewByIds(e) }} style={{ width: '240px', marginLeft: '10px' }} >
                                    <Select.Option value={''}>取消选择</Select.Option>
                                    {
                                        props.reviewerlist.map((v, i) => {
                                            return <Option value={v.id}>{v.name}</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        : null
                    }
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>查询时间</span>
                        <DatePicker.RangePicker value={time} onChange={(e) => { settime(e) }} style={{ width: '240px', marginLeft: '10px' }}></DatePicker.RangePicker>
                    </Col>
                    <Col span={8}>
                        <span style={{ lineHeight: '30px' }}>完成状态</span>
                        <Select value={overstate} onChange={(e) => { setoverstate(e) }} style={{ width: '240px', marginLeft: '10px' }}>
                            <Select.Option value={''}>取消选择</Select.Option>
                            {
                                Object.keys(okstate).map((v, i) => {
                                    return <Option value={v}>{okstate[v]}</Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>
                <Row justify='space-between' style={{ marginBottom: '20px' }}>
                    <Col>
                        {
                            isbool ? <>
                                <Button type='primary' style={{ margin: '0 10px' }} onClick={e => showModal()}><PlusOutlined />添加门店</Button>
                                <Button type='primary' style={{ margin: '0 10px' }} onClick={() => { setloadvisible(true) }}>批量上传</Button>
                                <PopConfirmOnly confirm={alldel}>
                                    <Button style={{ margin: '0 10px' }} >批量删除</Button>
                                </PopConfirmOnly>
                            </> : null
                            //
                        }
                        <Button style={{ margin: '0 10px' }} onClick={() => { exports() }}>批量导出</Button>
                        {
                            isbool ?
                                <Button style={{ margin: '0 10px' }} onClick={() => { showModalApp() }}> 批量修改App模板</Button>
                                : null
                        }
                    </Col>
                    <Col>
                        <Button type='primary' style={{ margin: '0 10px' }} onClick={() => { requestlist() }}>查询</Button>
                        <Button style={{ margin: '0 10px' }} onClick={() => { resetfrom() }}>重置</Button>
                        <Button style={{ margin: '0 10px' }} onClick={() => { settablebool(!tablebool) }}>收起</Button>
                    </Col>
                </Row>

                {
                    selectedRowKeys.length !== 0 ?
                        <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
                            <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
                            <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
                        </Row>
                        :
                        null
                }
                {
                    tablebool ? <Table
                        rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
                        dataSource={list}
                        columns={isbool ? columns : colum}
                        pagination={{  defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
                            setPage(page)
                            setPageSize(pageSize)
                            }
                           }} /> : null
                }
            </div>
        </div>
    )
}

export default App
