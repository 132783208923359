import React, { useEffect, useState, Component } from 'react'
import { Button, Row, Col, Carousel, Cascader, Modal, Select, Tabs, Table, DatePicker, Popconfirm, Input, Upload, InputNumber, Image } from 'antd'
import {  DownloadOutlined, ZoomInOutlined, ArrowLeftOutlined, ArrowRightOutlined, CloudDownloadOutlined, CheckOutlined, UploadOutlined, UndoOutlined, RedoOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { audioUrl, wanggeUrl } from '@/utils/config'
import '@/assets/swiper-bundle.min.css'
import 'lazysizes'
import message from '@/utils/message'
import moment from 'moment'

import loading from '@/assets/timg.jpg'

import { Swiper, SwiperSlide } from 'swiper/react';
import { listToArray } from '@/utils/listToArray'

import axios from '@/utils/axios'
import { responsiveArray } from 'antd/lib/_util/responsiveObserve'

import ImageMagnifier from "./ImageMagnifier";

const { Option } = Select;
const { TabPane } = Tabs;

let tuihui = []

class App extends Component {
    constructor() {
        super()
        this.state = {
            x: 0,
            y: 0,
            mobool: false,
            contentStyle: {
                height: '160px',
                color: '#fff',
                lineHeight: '160px',
                textAlign: 'center',
                background: '#364d79',
            },
            swiper: [],
            cloneswiper: [],
            num: 1,
            options: [
                {
                    value: '合格',
                    label: '合格'
                },
                {
                    value: '不合格',
                    label: '不合格',
                    children: [],
                },
            ],
            defaultsrc: {
                id: '',
                before: '',
                status: 0,
            },
            data: {},
            current: 'mail',
            visible: false,
            taskId: '',
            labelValues: {},
            swipers: {},
            swipersnum: [],
            numbool: null,
            tuihui: [],
            gbool: false,
            lenum: 1,
            Image: true,
            refusedCountArr: [],
            id: 0,
            xuanzhongModal: false,
            xiugaiModal: false,
            value: [],
            selectedOptions: [],
            downbool: false,
            pan: '',
            locationValue: '',
            addpicturebool: false,
            uplaodimg: {},
            point: {},
            pointone: '',
            floor: '',
            pagination: [0, 0, 0],
            key: 0,
            cssbool: false,
            current: 0,
            uploadbool: false,
            fileimgdata: [],
            timeIndex: 0,
            copyIsbools: false,
            wanggeBool: false,
            getData: {},
            projectData: {},
            metricGroup: [],
            wanggeData: {
                "channel": "虹桥管商务区", //数据来源，场景发现步骤⽆无需提交
                "jkId": "", //工单编号
                "token": "31415926535897932381112", // ? 没有填写信息
                "title": "", // 指标名称
                "address": "", // 经纬度
                "createTime": "", // 图片时间
                "createTimestamp": "", // 图片时间戳
                "level": "2", // 严重程度 默认为 2
                "levelDesc": "一般警告", // 严重程度 默认为 一般警告
                "nowStep": 1, // 当前流程所处节点  默认为 1
                "source": { // 来源场景信息
                    "name": "虹桥管商务区",
                    "sourceId": '035',
                    "Id": '20190905',
                    "partsOrEvent": " 部件",
                    "actionType": "主动发现",
                },
                "category": {
                    "level1": "综合管理理",
                    "level2": "其他设施",
                    "level3": "违法搭建"
                },
                "location": { // 上报定位
                    "wgs84Latitude": "", // 城市建设坐标 
                    "wgs84Longitude": "", // 城市建设坐标 
                    "city": "上海", // 市 
                    "cityId": "3101", // 市的 id
                    "district": "", // 区 
                    "districtId": "", // 区的id
                },
                "eventProcessList": [{ // 存放当前步骤记录
                    "caption": "发现",  // 处于哪个环节名称  默认 发现
                    "step": "1", // 1:发现， 2:立案， 3:派遣， 4: 处置 5:核查， 6:结案  默认 1
                    "createDate": "", // 该流程创建时间  默认 数据提交时间
                    "timestamp": "", // 该流程创建时间  默认 数据提交时间戳
                    "person": {  // 人员信息
                        "name": "虹桥管委会上报", // 该流程处理人员名称  默认 虹桥管委会上报
                    },
                    "stepSource": "旭中", // 该流程数据来源  默认 旭中
                    "reporterAnonymous": "Y", // 是否匿名  Y/N 默认 Y
                    "reportercallback": "Y", // 是否回访  Y/N 默认 Y
                    "preEndingTime": "", // 该步骤的超期时间 可以填空 默认是空
                    "preEndingrimestamp": "", // 该步骤的超期时间戳 可以填空 默认是空
                    "multimedias": [ // 上报多媒体信息 图片地址 可以填 空
                        "192.168.123.100:8000/fi1e1.jpg",
                    ],
                }
                ],
                "hisEventprocessList": [] // 空
            },
            jilianArr:[]
        }
    }

    columns = [
        {
            dataIndex: 'fileName',
            key: 'fileName',
            render: (text, record, index) => (text)
        },
    ]

    //模态框
    showModal = () => {
        document.body.parentNode.style.overflow = "hidden"
        this.setState({
            visible: true,
            current: 0
        });
        const sltp = this
        document.onkeydown = function (e) {
            if (sltp.state.visible) {
                if (e.keyCode == 37) {
                    sltp.leftsrc()
                }
                if (e.keyCode == 39) {
                    sltp.rightsrc()
                }

            }
        }
    };

    handleCancel = e => {
        document.body.parentNode.style.overflow = "scroll";
        this.setState({
            visible: false,
            xuanzhongModal: false,
            uploadbool: false
        });
    };

    stop = e => {
        e.stopPropagation()
    }

    componentDidMount() {
        const sltp = this
        document.onkeydown = function (e) {
            if (e.keyCode == 37) {
                sltp.leftsrc()
                e.preventDefault();
            }
            if (e.keyCode == 39) {
                sltp.rightsrc()
                e.preventDefault();
            }
        }

        axios.get('/api/sys/dict/' + "issuePoint").then(res => {
            this.setState({
                point: res.data.labelValues
            })
        })
        axios.get('/api/core/task/' + this.props.match.params.id)
            .then(ress => {
                this.setState({
                    getData: ress.data
                })
                axios.get('/api/core/project/' + ress.data.projectId)//指标组
                    .then(res => {
                        this.setState({
                            projectData: res.data
                        })
                        axios.get('/api/core/metric/list', {//指标
                            params: {
                                pageSize: 1000000,
                                metricGroupId: res.data.metricGroupId
                            }
                        })
                            .then(res => {
                                this.setState({
                                    metricGroup: res.data.data
                                })
                                this.state.options[1].children = listToArray(res.data.data)
                                function shu(arr) {
                                    arr.reverse().forEach((v, i) => {
                                        v.value = v.id
                                        v.label = v.description
                                        if (v.children instanceof Array) {
                                            return shu(v.children)
                                        }
                                    })
                                }
                                shu(this.state.options[1].children)
                                this.setState({
                                    options: [{
                                        value: '门头',
                                        label: '门头'
                                    }, ...this.state.options]
                                })
                            })

                    })
                if (JSON.stringify(ress.data) != '[]') {
                    this.setState({
                        data: ress.data
                    }, () => {
                        axios.get('/api/sys/dict/issueStatus')
                            .then(res => {
                                this.setState({
                                    labelValues: Object.values(res.data.labelValues).filter((v, i) => {
                                        return v == '门头' || v == '合格' || v == '不合格'
                                    })
                                })
                            })
                    })
                    this.getimgData(ress.data.id)
                    this.setState({
                        id: ress.data.id
                    })
                }
            })
    }

    getimgData = (ress) => {
        axios.get('/api/core/issue/list', {//轮播图片
            params: {
                queryCondition: 'EQ:taskId=' + ress,
                pageSize: 1000,
            }
        })//接口
            .then(res => {
                const arr = res.data.data.sort((a, b) => {
                    return a.takeAt - b.takeAt
                })

                this.setState({
                    swiper: res.data.data,
                }, () => {
                    let arr = []
                    this.state.swiper.forEach((v, i) => {
                        v.tuihuibool = false
                        arr.push(v)
                        if (v.refusedCount && this.state.refusedCountArr.indexOf(v.refusedCount) == -1) {
                            this.state.refusedCountArr.push(v.refusedCount)
                        }
                    })
                    arr.reverse()
                    let brr = []
                    this.setState({
                        cloneswiper: arr,
                        swiper: arr,
                        swipers: arr.filter((v, i) => {
                            if (this.state.pan == '合格') return v.status == 1
                            if (this.state.pan == '门头') return v.status == 0
                            if (JSON.stringify(this.state.defaultsrc) == '[]') return false
                            return v.status == this.state.defaultsrc?.status && v.description == this.state.defaultsrc?.description
                        }),
                        swipersnum: arr.filter((v, i) => {
                            if (brr.indexOf(v.description) == -1 && v.description != null) {
                                brr.push(v.description)
                                return true
                            }
                            return false
                        }),
                        defaultsrc: arr.filter((v, i) => {
                            if (this.state.pan == '合格') return v.status == 1
                            if (this.state.pan == '门头') return v.status == 0
                            if (JSON.stringify(this.state.defaultsrc) == '[]') return false
                            return v.status == this.state.defaultsrc?.status && v.description == this.state.defaultsrc?.description
                        })[0],
                        refusedCountArr: this.state.refusedCountArr.concat([]),
                        pan: ''
                    })
                })
            })
    }

    componentWillUnmount() {
        this.setState({
            tuihui: []
        })
        tuihui = []
    }

    roll = (e) => {
        e.stopPropagation()
        //滚轮上下滚动值
        let zooming = document.getElementById("zoomimg")
        //大于0滚轮向上滚动,小于0滚轮向下滚动
        if (e.deltaY < 0) {
            zooming.style.height = Number(zooming.style.height.split('px')[0]) + 20 + 'px';
        } else {
            zooming.style.height = Number(zooming.style.height.split('px')[0]) - 20 + 'px';
        }
    }

    leftsrc = (e) => {//向左移动图片
        if (document.getElementById('img1') != null) document.getElementById('img1').style.transform = 'rotate(0deg)';
        if (document.getElementById('img1') != null) document.getElementById('imga').style.transform = 'rotate(0deg)';
        // e.stopPropagation()
        if (this.state.num > 1) {
            this.setState({
                num: this.state.num - 1,
                defaultsrc: this.state.swipers[this.state.num - 2],
            }, () => {
                let crr = this.state.swipersnum
                crr.forEach((v, i) => {
                    let count = false
                    tuihui.forEach((a, b) => {
                        if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                            count = true
                        }
                    })
                    if (count) {
                        v.tuihuibool = true
                    } else {
                        v.tuihuibool = false
                    }
                });
                this.setState({
                    swipersnum: crr,
                    current: 0
                })
            })
        }
    }

    rightsrc = (e) => {//向有右移动图片
        if (document.getElementById('img1') != null) document.getElementById('img1').style.transform = 'rotate(0deg)';
        if (document.getElementById('img1') != null) document.getElementById('imga').style.transform = 'rotate(0deg)';
        // e.stopPropagation()
        if (this.state.num < this.state.swipers.length) {
            this.setState({
                num: this.state.num + 1,
                defaultsrc: this.state.swipers[this.state.num],
            }, () => {
                let crr = this.state.swipersnum
                crr.forEach((v, i) => {
                    let count = false
                    tuihui.forEach((a, b) => {
                        if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                            count = true
                        }
                    })
                    if (count) {
                        v.tuihuibool = true
                    } else {
                        v.tuihuibool = false
                    }
                });
                this.setState({
                    swipersnum: crr,
                    current: 0
                })
            })
        }
    }

    clisrc = (val, i, e) => {
        this.setState({
            defaultsrc: val,
            num: i + 1,
        }, () => {
            let crr = this.state.swipersnum
            crr.forEach((v, i) => {
                let count = false
                tuihui.forEach((a, b) => {
                    if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                        count = true
                    }
                })
                if (count) {
                    v.tuihuibool = true
                } else {
                    v.tuihuibool = false
                }
            });
            this.setState({
                swipersnum: crr
            })
        })
    }

    clifilter = (val, i) => {
        this.setState({
            swipers: val.description == this.state.defaultsrc.description ? this.state.swipers : this.state.swiper.filter((v, i) => {
                return v.description == val.description
            }),
            defaultsrc: val.description == this.state.defaultsrc.description ? this.state.defaultsrc : val,
            num: val.description == this.state.defaultsrc.description ? this.state.num : 1,
            numbool: i,
        }, () => {
            let crr = this.state.swipersnum
            crr.forEach((v, i) => {
                let count = false
                tuihui.forEach((a, b) => {
                    if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                        count = true
                    }
                })
                if (count) {
                    v.tuihuibool = true
                } else {
                    v.tuihuibool = false
                }
            });
            this.setState({
                swipersnum: crr
            })
        })
    }

    callback = (key) => {
        this.state.num = this.state.pagination[key] + 1
        this.setState({
            num: this.state.pagination[key] + 1,
            key
        })
        this.setState({
            swipers: this.state.swiper.filter((v, i) => {
                return v.status == key
            }),
            defaultsrc: this.state.swiper.filter((v, i) => {
                return v.status == key
            })[this.state.num - 1],
        })
        if (key == '2') {
            const arr = []
            this.setState({
                swipersnum: this.state.swiper.filter((v, i) => {
                    if (arr.indexOf(v.description) == -1 && v.description != null) {
                        arr.push(v.description)
                        return true
                    }
                    return false
                }).sort((a,b)=>a.metric-b.metric),
            }, () => {
                this.setState({
                    swipers: this.state.swiper.filter((v, i) => {
                        if (v.description != null) {
                            return v.description == this.state.swipersnum[this.state.swipersnum.length - 1].description
                        }
                    }),
                    defaultsrc: this.state.swiper.filter((v, i) => {
                        if (v.description != null) {
                            return v.description == this.state.swipersnum[this.state.swipersnum.length - 1].description
                        }
                    })[0]
                })
            })
        }
    }

    backbool = (num) => {
        this.setState({
            numbool: num
        })
        if (this.state.numbool == num) {
            this.state.defaultsrc.tuihuibool = !this.state.defaultsrc.tuihuibool
            let brr = this.state.swipers
            let count = true
            tuihui.forEach((v, i) => {
                if (v.id == this.state.defaultsrc.id) {
                    count = false
                    tuihui.splice(i, 1)
                }
            })
            if (count) {
                tuihui.push(this.state.defaultsrc)
            }
            const drr = []
            this.setState({
                tuihui: tuihui,
                swipers: brr.length != 0 ? brr : this.state.swiper,
                swipersnum: this.state.swiper.filter((v, i) => {
                    if (drr.indexOf(v.description) == -1 && v.description != null) {
                        drr.push(v.description)
                        return true
                    }
                    return false
                })
            }, () => {
                this.setState({
                    defaultsrc: this.state.defaultsrc,
                    num: this.state.num < this.state.swipers.length ? this.state.num : this.state.swipers.length,
                    swipersj: this.state.swipers
                })
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.num != this.state.num && prevState.key == this.state.key) {
            this.state.pagination[this.state.key] = this.state.num - 1
            // this.setState({
            //     pagination:this.state.pagination
            // })
        }
        if(JSON.stringify(prevState.swipersnum) != JSON.stringify(this.state.swipersnum)){
            // this.state.swipersnum.forEach(v=>{
            //     v.metric = this.state.metricGroup.filter(a => {
            //         return a.description == v.description
            //     })[0]?.metricCode
            // })
            this.state.swipersnum=this.state.swipersnum.sort((a,b)=>a.metric-b.metric)
            this.setState({
                swipersnum:this.state.swipersnum
            })
        }
        const value = JSON.stringify(this.state.tuihui)
        sessionStorage.removeItem('tuihui');
        sessionStorage.setItem("tuihui", value);
        if (prevState.swipers != this.state.swipers) {
            this.state.swipers.forEach((v, i) => {
                this.state.swipersnum.forEach((a, b) => {
                    if (v.id == a.id) {
                        this.setState({
                            lenum: b
                        })
                    }
                })
            })
        }
        if (JSON.stringify(prevState.defaultsrc) != JSON.stringify(this.state.defaultsrc)) {
            if (document.getElementById('img1') != null) {
                document.getElementById('img1').style.height = 800 + 'px'
                document.getElementById('img1').style.position = 'static'
                this.setState({
                    downbool: false
                })
            }
        }
    }

    handleMouseMove = e => {
        var dom = document.getElementById('img1')
        const sltp = this
        document.getElementById('img1').style.position = 'absolute'
        this.setState({
            mobool: true
        })

        var x = e.clientX - sltp.state.x
        var y = e.clientY - sltp.state.y
        document.onmousemove = function (e) {
            if (sltp.state.mobool) {
                if (sltp.state.downbool) {
                    sltp.setState({
                        x: e.clientX - x,
                        y: e.clientY - y
                    })
                } else {
                    sltp.setState({
                        x: e.clientX - parseInt(dom.width) / 2,
                        y: e.clientY - parseInt(dom.height) / 2,
                    })
                }
            }
        }
    }


    handleScroll(e) {
        this.setState({
            downbool: true
        })
        var dom = document.getElementById('img1')
        if (e.nativeEvent.deltaY < 0) {
            dom.style.height = parseInt(dom.style.height) + 50 + 'px'
        } else {
            dom.style.height = parseInt(dom.style.height) - 50 + 'px'
        }
    }

    exportimg = () => {
        axios.get('/api/core/issue/export', {
            params: {
                taskId: this.state.id
            },
            responseType: 'blob'
        }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "导出图片" + this.state.data.surveyCode + ".zip");
            document.body.appendChild(link);
            link.click();
        })
    }

    ChangerefusedCount = (val) => {
        if (val == 0) {
            this.getimgData(this.state.id)
        } else {
            this.state.swiper = this.state.cloneswiper.filter((v, i) => {
                return v.refusedCount == val - 2
            })
            if (this.state.defaultsrc?.description != undefined) {
                this.state.swipers = this.state.swiper.filter((v, i) => {
                    return v.refusedCount == val - 2 && v.status == this.state.defaultsrc?.status && v.description == this.state.defaultsrc?.description
                })
                this.state.defaultsrc = this.state.swipers.filter((v, i) => {
                    return v.refusedCount == val - 2 && v.status == this.state.defaultsrc?.status && v.description == this.state.defaultsrc?.description
                })[0]
            } else {
                this.state.swipers = []
                this.state.defaultsrc = []
            }
            this.setState({
                swiper: this.state.swiper,
                swipers: this.state.swipers,
                defaultsrc: this.state.defaultsrc,
                num: 1,
                numbool: 0,
            }, () => {
                const arr = []
                const brr = this.state.swiper.filter((v, i) => {
                    if (arr.indexOf(v.description) == -1 && v.description != null) {
                        arr.push(v.description)
                        return true
                    }
                    return false
                })
                brr.forEach((v, i) => {
                    let count = false
                    tuihui.forEach((a, b) => {
                        if (a.id == this.state.defaultsrc.id && v.description == this.state.defaultsrc.description) {
                            count = true
                        }
                    })
                    if (count) {
                        v.tuihuibool = true
                    } else {
                        v.tuihuibool = false
                    }
                });
                this.setState({
                    swipersnum: brr,
                    swipers: JSON.stringify(this.state.defaultsrc) != '[]' ? brr.filter((v, i) => {
                        if (v.description != null) {
                            return v.description == brr[brr.length - 1].description
                        }
                    }) : [],
                    defaultsrc: JSON.stringify(this.state.defaultsrc) != '[]' ? brr.filter((v, i) => {
                        if (v.description != null) {
                            return v.description == brr[brr.length - 1].description
                        }
                    })[0] : {}
                })
            })
        }
    }

    xuanzhong = function (description) { //       √ 的提示
        const arr = this.state.cloneswiper.filter(v => {
            return v.description == description
        })
        let count = false
        arr.forEach(v => {
            if (JSON.stringify(this.state.tuihui).indexOf('"id":' + v.id) != -1) count = true
        })
        if (count) return true
        return false
    }

    kai = () => {
        this.onChangetiao()
        this.setState({ xiugaiModal: false })
    }

    onChangetiao = (value, selectedOptions) => {
        // if (JSON.stringify(this.state.defaultsrc) != '{}' && this.state.value.length != 0) {
        if (this.state.addpicturebool) {
            const slpt = this
            function shangchuan() {
                slpt.state.defaultsrc.metric = null
                delete slpt.state.defaultsrc.description
                slpt.state.defaultsrc.addressManualDesc = slpt.state.locationValue
                slpt.state.defaultsrc.point = slpt.state.pointone
                slpt.state.defaultsrc.floor = slpt.state.floor
                delete slpt.state.defaultsrc.tuihuibool
                axios.put('/api/core/issue', slpt.state.defaultsrc, {
                    params: {
                        auditStatus: slpt.state.data.taskAudit1Status.display == '已审核' ? slpt.state.data.taskAudit2Status.display == '已审核' ? 3 : 2 : 1
                    }
                })
                    .then(res => {
                        message.success('修改成功')
                        // slpt.getimgData(slpt.state.id)
                        let arr = slpt.state.swipers.filter(v=>{
                            return v.id != slpt.state.defaultsrc.id
                        })
                        setTimeout(() => {
                            slpt.setState({
                                selectedOptions: [],
                                value: [],
                                swipers: arr,
                                num: slpt.state.num < arr.length - 1 ? slpt.state.num  : arr.length ,
                                defaultsrc: JSON.parse(JSON.stringify(arr[slpt.state.num < arr.length - 1 ? slpt.state.num - 1 : arr.length - 1])),
                                pointone: '',
                                floor: '',
                                locationValue: '',

                            })
                        }, 1000);
                    })
            }
            if (slpt.state.value[0] == '门头') {
                slpt.state.defaultsrc.status = 0
                shangchuan()
            } else if (slpt.state.value[0] == '合格') {
                slpt.state.defaultsrc.status = 1
                shangchuan()
            } else {
                if (slpt.state.locationValue == '' || (slpt.state.selectedOptions.length == 0 && slpt.state.jilianArr.length ==0) || slpt.state.pointone == '') return message.error('请将信息填写完整')
                else {
                    slpt.state.defaultsrc.metric = slpt.state.selectedOptions.length?slpt.state.selectedOptions[slpt.state.selectedOptions.length - 1].id:slpt.state.jilianArr[slpt.state.jilianArr.length-1]
                    slpt.state.defaultsrc.description = slpt.state.selectedOptions.length?slpt.state.selectedOptions[slpt.state.selectedOptions.length - 1].description:slpt.state.metricGroup.filter(v=>v.id==slpt.state.jilianArr[slpt.state.jilianArr.length-1])[0].description
                    slpt.state.defaultsrc.status = 2
                    slpt.state.defaultsrc.addressManualDesc = slpt.state.locationValue
                    slpt.state.defaultsrc.point = slpt.state.pointone
                    slpt.state.defaultsrc.floor = slpt.state.floor
                }
                delete slpt.state.defaultsrc.tuihuibool
                axios.put('/api/core/issue', slpt.state.defaultsrc, {
                    params: {
                        auditStatus: slpt.state.data.taskAudit1Status.display == '已审核' ? slpt.state.data.taskAudit2Status.display == '已审核' ? 3 : 2 : 1
                    }
                })
                    .then(res => {
                        message.success('修改成功')
                        // slpt.getimgData(slpt.state.id)
                        let arr = JSON.parse(JSON.stringify(slpt.state.swipers))
                        if(slpt.state.defaultsrc.metric != slpt.state.jilianArr[slpt.state.jilianArr.length-1]){
                            arr = slpt.state.swipers.filter(v=>{
                                return v.id != slpt.state.defaultsrc.id
                            })
                        }
                        setTimeout(() => {
                            slpt.setState({
                                selectedOptions: [],
                                value: [],
                                swipers: arr,
                                num: slpt.state.num < arr.length - 1 ? slpt.state.num  : arr.length ,
                                defaultsrc: arr[slpt.state.num < arr.length - 1 ? slpt.state.num - 1 : arr.length - 1],
                                pointone: '',
                                floor: '',
                                locationValue: '',
                                swipersnum:[...this.state.swipersnum]
                            })
                        }, 1000);
                    })
            }
            slpt.setState({
                selectedoptions: [],
                locationValue: '',
                pointone: '',
                floor: '',
                locationValue: '',
            })
        } else {
            if (this.state.selectedOptions.length == 0) {
                return message.error('请将信息填写完整')
            }
            if (this.state.uplaodimg != "{}") {
                var obj = {}
                if (this.state.cloneswiper.length != 0) {
                    obj = JSON.parse(JSON.stringify(this.state.cloneswiper[0]))
                    shangchuan(this)
                } else {
                    axios.get('/api/core/issue/list', {//轮播图片
                        params: {
                            pageSize: 1,
                        }
                    })//接口
                        .then(res => {
                            obj = res.data.data[0]
                            obj.taskId = this.state.data.id
                            shangchuan(this)
                        })
                }
                function shangchuan(slpt) {
                    obj.addressManualDesc = slpt.state.locationValue
                    delete obj.id
                    if (slpt.state.value[0] == '门头') {
                        obj.metric = null
                        delete obj.description
                        obj.status = 0
                        obj.deduction = 0
                    }
                    else if (slpt.state.value[0] == '合格') {
                        obj.metric = null
                        delete obj.description
                        obj.status = 1
                        obj.deduction = 0
                    } else {
                        obj.metric = slpt.state.selectedOptions[slpt.state.selectedOptions.length - 1].id
                        obj.description = slpt.state.selectedOptions[slpt.state.selectedOptions.length - 1].description
                        obj.status = 2
                        obj.point = slpt.state.pointone
                        obj.floor = slpt.state.floor
                    }
                    delete obj.tuihuibool
                    slpt.state.fileimgdata.forEach(v => {
                        const objs = { ...obj, before: v.fullUrl, beforeMini: v.fullUrl }
                        axios.post('/api/core/issue', objs)
                    })
                    setTimeout(e => {
                        message.success('添加成功')
                        slpt.getimgData(slpt.state.id)
                        slpt.setState({
                            selectedoptions: [],
                            locationValue: '',
                            pointone: '',
                            floor: '',
                            locationValue: '',
                            fileimgdata: []
                        })
                    }, 1000)
                }
            } else {
                message.error('请将信息填写完整')
            }
        }
        // } else {
        //     message.error('请填写完整信息')
        // }
    }

    deleteimg = () => {
        axios.delete('/api/core/issue/' + this.state.defaultsrc.id)
            .then(res => {
                message.success('删除成功')
                this.getimgData(this.state.id)
            })
    }

    left = function () {
        this.state.current = (this.state.current + 90) % 360;
        const doms = document.getElementsByClassName('imga')
        // doms[0].style.transform = 'rotate(' + this.state.current + 'deg)';
        // doms[1].style.transform = 'rotate(' + this.state.current + 'deg)';
        if (this.state.current / 90 % 2 == 0) {
            doms[0].style.transform = 'rotate(' + this.state.current + 'deg)'
            doms[1].style.transform = 'rotate(' + this.state.current + 'deg)'
        } else {
            doms[0].style.transform = 'rotate(' + this.state.current + 'deg)'
            doms[1].style.transform = 'rotate(' + this.state.current + 'deg)'
        }
    }

    right = function () {
        const doms = document.getElementsByClassName('imga')
        this.state.current = (this.state.current - 90) % 360;
        // doms[0].style.transform = 'rotate(' + this.state.current + 'deg)';
        // doms[1].style.transform = 'rotate(' + this.state.current + 'deg)';
        if (this.state.current / 90 % 2 == 0) {
            doms[0].style.transform = 'rotate(' + this.state.current + 'deg)'
            doms[1].style.transform = 'rotate(' + this.state.current + 'deg)'
        } else {
            doms[0].style.transform = 'rotate(' + this.state.current + 'deg)'
            doms[1].style.transform = 'rotate(' + this.state.current + 'deg)'
        }
    }


    uploadProps = {
        onRemove: (file) => {
            this.setState({
                FilelistAdd: []
            })
            return []
        },
        listType: "picture",
        maxCount: 100,
        multiple: true,
        beforeUpload: (file) => {
            const formData = new FormData()
            formData.append('file', file)
            this.state.timeIndex += 200
            setTimeout(() => {
                axios.post('/api/common/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then(res => {
                        this.state.timeIndex = 0
                        this.setState({
                            fileimgdata: [...this.state.fileimgdata, res.data[0]]
                        })
                    })
            }, this.state.timeIndex)
            return false
        },
        showUploadList: false,
        accept: ['.jpeg', '.jpg'],
    }

    copyOk = () => {
        let temporary = JSON.parse(JSON.stringify(this.state.defaultsrc))
        delete temporary.tuihuibool
        delete temporary.id
        let status = null
        if (this.state.value[0] == '门头') {
            status = 0
        } else if (this.state.value[0] == '合格') {
            status = 1
        } else if (this.state.value[0] == '不合格') {
            status = 2
        }
        axios.post('/api/core/issue', {
            ...temporary,
            status,
            metric: this.state.selectedOptions[this.state.selectedOptions.length - 1].id,
            description: this.state.selectedOptions[this.state.selectedOptions.length - 1].description
        }).then(res => {
            this.getimgData(this.state.id)
            this.setState({
                selectedOptions: [],
                copyIsbools: false
            })
        })
    }

    getBase64Image = (img) => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height
        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, img.width, img.height)
        var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase()
        var dataURL = canvas.toDataURL("image/" + ext)
        return dataURL
    }

    toImg = (urlsa) => {
        var _this = this
        var image = new Image()
        image.src = audioUrl+urlsa
        image.setAttribute("crossOrigin", "Anonymous")
        // image.setAttribute("crossorigin", " *")
        image.onload = function () {
            var base64 = _this.getBase64Image(image)
            _this.base64 = base64

            //转换base64到file
            var file = _this.btof(base64, "test")
            const data = new FormData();
            data.append('file',file)
            data.append('data',JSON.stringify(_this.state.wanggeData))
            axios({
                method:'post',
                url:wanggeUrl, 
                data,
                headers: {
                    'Content-Type': 'form-data'
                }
            })
            .then(res => {
                console.log(res)
                message.success(res.message)
            })
            this.setState({
                wanggeBool: false
            })
        }
    }

    btof = (data, fileName) => {
        const dataArr = data.split(",")
        const byteString = atob(dataArr[1])

        const options = {
            type: "image/jpeg",
            endings: "native"
        }
        const u8Arr = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
            u8Arr[i] = byteString.charCodeAt(i)
        }
        return new File([u8Arr], fileName + ".jpg", options)
    }

    wanggeOK = () => {
        this.toImg(this.state.defaultsrc.before)
    }

    wanggeDown = () => {
        this.setState({
            wanggeBool: false
        })
    }

    wanggeFun = () => {
        const metric = this.state.metricGroup.filter(v => {
            return v.description == this.state.defaultsrc?.description
        })
        const metricarr = this.state.metricGroup.filter(v => {
            return metric[0]?.parentCode==(v?.metricCode)
        })
        const metricarrs = this.state.metricGroup.filter(v => {
            return metricarr[0]?.parentCode==(v?.metricCode)
        })
        this.setState({
            wanggeBool: true,
            wanggeData: {
                "channel": "虹桥管商务区", //数据来源，场景发现步骤⽆无需提交
                "jkId": this.state.getData?.surveyCode + this.state.defaultsrc?.id, //工单编号
                "token": "31415926535897932381112", // ? 没有填写信息
                "title": this.state.defaultsrc?.description, // 指标名称
                "address": this.state.defaultsrc?.addressDesc + this.state.defaultsrc?.addressManualDesc, // 经纬度
                "createTime": this.state.defaultsrc?.createdAt, // 图片时间
                "createTimestamp": moment(this.state.defaultsrc?.createdAt).valueOf(), // 图片时间戳
                "level": "2", // 严重程度 默认为 2
                "levelDesc": "一般警告", // 严重程度 默认为 一般警告
                "nowStep": 1, // 当前流程所处节点  默认为 1  
                "description": this.state.defaultsrc?.description,
                "source": { // 来源场景信息
                    "name": "虹桥管商务区",
                    "sourceId": '035',
                    "Id": '035',
                    "partsOrEvent": "部件",
                    "actionType": "主动发现",
                },
                "category": {
                    "level1": metricarr.length != 0 ? metricarrs[0]?.description : '1',
                    "level2": metricarr.length != 0 ? metricarr[0]?.description : '1',
                    "level3": this.state.defaultsrc?.description?.includes('.')?this.state.defaultsrc?.description.split('.')[1]:this.state.defaultsrc?.description
                },
                "location": { // 上报定位
                    "wgs84Latitude": this.state.defaultsrc?.address?.split(',')[0], // 城市建设坐标 
                    "wgs84Longitude": this.state.defaultsrc?.address?.split(',')[1], // 城市建设坐标 
                    "city": "上海", // 市 
                    "cityId": "3101", // 市的 id
                    "district": this.state.getData.surveyScope.split('-')[0], // 区 
                    "districtId": this.state.getData.surveyScope.split('-')[1], // 区的id
                },
                "eventProcessList": [{ // 存放当前步骤记录
                    "caption": "发现",  // 处于哪个环节名称  默认 发现
                    "step": "1", // 1:发现， 2:立案， 3:派遣， 4: 处置 5:核查， 6:结案  默认 1
                    "createDate": moment().format("YYYY-MM-DD HH:mm:ss"), // 该流程创建时间  默认 数据提交时间
                    "timestamp": moment(moment().format("YYYY-MM-DD HH:mm:ss")).valueOf(), // 该流程创建时间  默认 数据提交时间戳
                    "person": {  // 人员信息
                        "name": "虹桥管委会上报", // 该流程处理人员名称  默认 虹桥管委会上报
                    },
                    "stepSource": "旭中", // 该流程数据来源  默认 旭中
                    "reporterAnonymous": "Y", // 是否匿名  Y/N 默认 Y
                    "reportercallback": "Y", // 是否回访  Y/N 默认 Y
                    "preEndingTime": "", // 该步骤的超期时间 可以填空 默认是空
                    "preEndingrimestamp": "", // 该步骤的超期时间戳 可以填空 默认是空
                    "multimedias": [], // 上报多媒体信息 图片地址 可以填 空
                }
                ],
                "hisEventprocessList": [] // 空
            }
        })
    }

    ModalKai = () => {
        const metric = this.state.metricGroup.filter(v => {
            return v.description == this.state.defaultsrc?.description
        })
        let arr = []
        let seft = this
        function chuli(metric){
            const metricarr = seft.state.metricGroup.filter(v => {
                return metric[0]?.parentCode==(v?.metricCode)
            })
            arr.unshift(metricarr[0]?.id)
            if(seft.state.metricGroup.filter(v => {
                return metricarr[0]?.parentCode==(v?.metricCode)
            }).length>0){
                return chuli(metricarr)
            }else{
                return
            }
        }
        let status = null
        console.log(this.state.defaultsrc?.description?.status)
        if(this.state.defaultsrc?.status==2){
            status = '不合格'
        }
        if(this.state.defaultsrc?.status==1){
            status = '合格'
        }
        if(this.state.defaultsrc?.status==0){
            status = '门头'
        }
        chuli(metric)
        this.setState({
            xiugaiModal: this.state.defaultsrc?.id != undefined ? true : false,
            jilianArr:[status,...arr,metric[0]?.id],
            addpicturebool: true,
            locationValue: this.state.defaultsrc.addressManualDesc,
            pointone: this.state.defaultsrc.point,
            floor: this.state.defaultsrc.floor,
        })
    }

    render() {
        const { x, y } = this.state
        const styleHead = { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0 10px' }

        const styleMargin = { margin: '5px 0', display: 'flex', alignItems: 'center' }

        return (
            <>
                <Modal
                    title={'单个网格照片上传'}
                    visible={this.state.wanggeBool}
                    footer={[
                        <Button onClick={this.wanggeOK}>上传</Button>,
                        <Button onClick={this.wanggeDown}>关闭</Button>
                    ]}
                    closable={false}
                    destroyOnClose={true}
                    width='80%'
                >
                    <p style={{ fontSize: '20px' }}>基本信息</p>
                    <Row>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">渠道</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.channel} onChange={e => { this.state.wanggeData.channel = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">案件编号(工单编号)</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.jkId} onChange={e => { this.state.wanggeData.jkId = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">上报标题(指标)</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.title} onChange={e => { this.state.wanggeData.title = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">上报地址(经纬度)</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.address} onChange={e => { this.state.wanggeData.address = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">严重程度</Col>
                        <Col style={styleMargin} span="9">
                            <Select defaultValue={this.state.wanggeData.level + ',' + this.state.wanggeData.levelDesc} onChange={e => { this.state.wanggeData.level = e.split(',')[0]; this.state.wanggeData.levelDesc = e.split(',')[1] }} style={{ width: '100%' }}>
                                <Option value="1,严重报警">严重报警</Option>
                                <Option value="2,一般报警">一般报警</Option>
                                <Option value="3,预警">预警</Option>
                                <Option value="4,故障">故障</Option>
                                <Option value="5,其它">其它</Option>
                            </Select>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">当前流程所处节点</Col>
                        <Col style={styleMargin} span="9">
                            <InputNumber defaultValue={this.state.wanggeData.nowStep} onChange={e => { this.state.wanggeData.nowStep = e }} />
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">该数据的创建时间</Col>
                        <Col style={styleMargin} span="9">
                            <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime={{ defaultValue: moment(this.state.wanggeData.createTime, 'HH:mm:ss') }}
                                defaultValue={moment(this.state.wanggeData.createTime, 'YYYY-MM-DD HH:mm:ss')}
                                onChange={e => {
                                    this.setState({
                                        wanggeData: {
                                            ...this.state.wanggeData,
                                            createTime: moment(e).format('YYYY-MM-DD HH:mm:ss'),
                                            createTimestamp: moment(e).valueOf()
                                        }
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <p style={{ fontSize: '20px' }}>来源场景信息</p>
                    <Row>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">来源场景</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.source.name} onChange={e => { this.state.wanggeData.source.name = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">来源场景ID</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.source.sourceId} onChange={e => { this.state.wanggeData.source.sourceId = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">场景自身该事件的唯⼀编码</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.source.Id} onChange={e => { this.state.wanggeData.source.Id = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">三动发现</Col>
                        <Col style={styleMargin} span="9">
                            <Select defaultValue={this.state.wanggeData.source.actionType} onChange={e => { this.state.wanggeData.source.actionType = e }} style={{ width: '100%' }}>
                                <Option value="自动发现">自动发现</Option>
                                <Option value="主动发现">主动发现</Option>
                                <Option value="被动发现">被动发现</Option>
                            </Select>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">部件或者事件</Col>
                        <Col style={styleMargin} span="9">
                            <Select defaultValue={this.state.wanggeData.source.partsOrEvent} onChange={e => { this.state.wanggeData.source.partsOrEvent = e }} style={{ width: '100%' }}>
                                <Option value="部件">部件</Option>
                                <Option value="事件">事件</Option>
                            </Select>
                        </Col>
                    </Row>
                    <p style={{ fontSize: '20px' }}>上报数据的分类信息</p>
                    <Row>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">一级分类</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.category.level1} onChange={e => { this.state.wanggeData.category.level1 = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">二级分类</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.category.level2} onChange={e => { this.state.wanggeData.category.level2 = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">三级分类</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.category.level3} onChange={e => { this.state.wanggeData.category.level3 = e.target.value }} ></Input>
                        </Col>
                    </Row>
                    <p style={{ fontSize: '20px' }}>上报定位</p>
                    <Row>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">城市建设坐标X</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.location.wgs84Latitude} onChange={e => { this.state.wanggeData.location.wgs84Latitude = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">城市建设坐标Y</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.location.wgs84Longitude} onChange={e => { this.state.wanggeData.location.wgs84Longitude = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">所处城市名称</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.location.city} onChange={e => { this.state.wanggeData.location.city = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">城市ID统一编码</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.location.cityId} onChange={e => { this.state.wanggeData.location.cityId = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">所处区县名称</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.location.district} onChange={e => { this.state.wanggeData.location.district = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">区县ID统一编码</Col> {/* 未定义 */}
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.location.districtId} onChange={e => { this.state.wanggeData.location.districtId = e.target.value }} ></Input>
                        </Col>
                    </Row>
                    {//
                    }
                    <p style={{ fontSize: '20px' }}>存放当前步骤记录</p>
                    <Row>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">处于哪个环节名称</Col>
                        <Col style={styleMargin} span="9">
                            <Select defaultValue={this.state.wanggeData.eventProcessList[0].step + ',' + this.state.wanggeData.eventProcessList[0].caption} onChange={e => { this.state.wanggeData.step = e.split(',')[0]; this.state.wanggeData.caption = e.split(',')[1] }} style={{ width: '100%' }}>
                                <Option value="1,发现">发现</Option>
                                <Option value="2,立案">立案</Option>
                                <Option value="3,派遣">派遣</Option>
                                <Option value="4,处置">处置</Option>
                                <Option value="5,核查">核查</Option>
                                <Option value="6,结案">结案</Option>
                            </Select>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">该流程处理人员名称</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.eventProcessList[0].person.name} onChange={e => { this.state.wanggeData.eventProcessList[0].person.name = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">该流程数据来源</Col>
                        <Col style={styleMargin} span="9">
                            <Input defaultValue={this.state.wanggeData.eventProcessList[0].stepSource} onChange={e => { this.state.wanggeData.eventProcessList[0].stepSource = e.target.value }} ></Input>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">是否匿名</Col>
                        <Col style={styleMargin} span="9">
                            <Select defaultValue={this.state.wanggeData.eventProcessList[0].reporterAnonymous} onChange={e => { this.state.wanggeData.eventProcessList[0].reporterAnonymous = e }} style={{ width: '100%' }}>
                                <Option value="Y">Y</Option>
                                <Option value="N">N</Option>
                            </Select>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">是否回访</Col>
                        <Col style={styleMargin} span="9">
                            <Select defaultValue={this.state.wanggeData.eventProcessList[0].reportercallback} onChange={e => { this.state.wanggeData.eventProcessList[0].reportercallback = e }} style={{ width: '100%' }}>
                                <Option value="Y">Y</Option>
                                <Option value="N">N</Option>
                            </Select>
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">该步骤的超期时间</Col>
                        <Col style={styleMargin} span="9">
                            <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime={{ defaultValue: moment(this.state.wanggeData.eventProcessList[0].createDate, 'HH:mm:ss') }}
                                onChange={e => {
                                    this.setState({
                                        wanggeData: {
                                            ...this.state.wanggeData,
                                            eventProcessList: [{
                                                ...this.state.wanggeData.eventProcessList[0],
                                                preEndingTime: moment(e).format('YYYY-MM-DD HH:mm:ss'),
                                                preEndingrimestamp: moment(e).valueOf()
                                            }]

                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col style={{ ...styleHead, ...styleMargin }} span="3">该流程创建时间</Col>
                        <Col style={styleMargin} span="9">
                            <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime={{ defaultValue: moment(this.state.wanggeData.eventProcessList[0].createDate, 'HH:mm:ss') }}
                                defaultValue={moment(this.state.wanggeData.eventProcessList[0].createDate, 'YYYY-MM-DD HH:mm:ss')}
                                onChange={e => {
                                    this.setState({
                                        wanggeData: {
                                            ...this.state.wanggeData,
                                            eventProcessList: [{
                                                ...this.state.wanggeData.eventProcessList[0],
                                                createDate: moment(e).format('YYYY-MM-DD HH:mm:ss'),
                                                timestamp: moment(e).valueOf()
                                            }]

                                        }
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    title={'查看退回重传照片'}
                    visible={this.state.uploadbool}
                    footer={[
                        <Button onClick={this.handleCancel}>关闭</Button>
                    ]}
                    closable={false}
                    width='80%'
                >
                    {
                        this.state.swiper.filter(v => {
                            return v.uploadFlag == 1
                        }).map(v => {
                            return <Row style={{ borderBottom: '1px solid #000', marginBottom: '7px' }}>
                                <Col span='4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {v.id}
                                </Col>
                                <Col span='10'>
                                    <img src={audioUrl + v.before} style={{ width: '80%', height: 'auto' }} />
                                    {//
                                    }
                                </Col>
                            </Row>
                        })
                    }
                </Modal>
                <Modal
                    title={'选中退回照片'}
                    visible={this.state.xuanzhongModal}
                    footer={[
                        <Button onClick={this.handleCancel}>关闭</Button>
                    ]}
                    closable={false}
                    width='80%'
                >
                    <div style={{ height: '400px', overflow: 'auto' }}>
                        {
                            this.state.defaultsrc?.status == 2 ? this.state.tuihui?.map(v => {
                                return (<Row style={{ borderBottom: '1px solid #000', marginBottom: '7px' }}>
                                    <Col span='4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {v.id}
                                    </Col>
                                    <Col span='10'>
                                        <img src={audioUrl + v.before} style={{ width: '80%', height: 'auto' }} />
                                        {//
                                        }
                                    </Col>
                                    <Col span='10' style={{ display: 'flex', alignItems: 'center' }}>
                                        {v.description}
                                    </Col>
                                </Row>)
                            }) : null
                        }
                    </div>
                </Modal>
                <Modal
                    title={'复制照片'}
                    visible={this.state.copyIsbools}
                    footer={[
                        <Button onClick={this.copyOk}>确认</Button>,
                        <Button onClick={() => { this.setState({ copyIsbools: false, selectedOptions: [] }) }}>关闭</Button>
                    ]}
                    closable={false}
                    destroyOnClose
                >
                    <Row>
                        <Col style={{ marginTop: '10px' }} span='5'>
                            <font color='red'>*</font>选择指标
                        </Col>
                        <Col style={{ marginTop: '10px' }} span='19'>
                            <Cascader style={{ width: '70%' }}
                                options={this.state.options}
                                onChange={(value, selectedOptions) => {this.setState({ value, selectedOptions }) }}
                                placeholder="选择指标" />
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    title={!this.state.addpicturebool ? '添加图片指标' : '修改图片指标信息'}
                    visible={this.state.xiugaiModal}
                    footer={[
                        <Button onClick={this.kai}>{!this.state.addpicturebool ? '添加' : "修改"}</Button>,
                        <Button onClick={() => { this.setState({ xiugaiModal: false, pointone: '', floor: '', locationValue: '', fileimgdata: [] }) }}>关闭</Button>
                    ]}
                    closable={false}
                    destroyOnClose
                >
                    <Row>
                        {
                            !this.state.addpicturebool ? <>
                                <Col span='5'>
                                    <font color='red'>*</font>选择照片
                                </Col>
                                <Col span='19'>
                                    <Upload {...this.uploadProps} >
                                        <Button icon={<UploadOutlined />}>选择图片</Button>
                                    </Upload>
                                    <Col span='19'>
                                        <Table scroll={{ y: 240 }} showHeader={false} columns={this.columns} dataSource={this.state.fileimgdata} style={{ padding: '0' }} pagination={false} />
                                    </Col>
                                </Col>
                            </> : null
                            //
                        }
                        <Col style={{ marginTop: '10px' }} span='5'>
                            <font color='red'>*</font>选择指标
                        </Col>
                        <Col style={{ marginTop: '10px' }} span='19'>
                            <Cascader style={{ width: '70%' }}
                                defaultValue={this.state.jilianArr}
                                options={this.state.options}
                                onChange={(value, selectedOptions) => {  console.log(value, selectedOptions);this.setState({ value, selectedOptions }) }}
                                placeholder="选择指标" />
                        </Col>
                        <Col style={{ marginTop: '10px' }} span='5'>
                            填写问题地址
                        </Col>
                        <Col style={{ marginTop: '10px' }} span='19'>
                            <Input value={this.state.locationValue} onChange={e => this.setState({ locationValue: e.target.value })} ></Input>
                        </Col>
                        <Col style={{ marginTop: '10px' }} span='5'>
                            选择具体位置
                        </Col>
                        <Col style={{ marginTop: '10px' }} span='19'>
                            <Select style={{ width: 120 }} value={this.state.pointone} onChange={e => { this.setState({ pointone: e }); if (e != 'middle') this.setState({ floor: '' }) }}>
                                {
                                    Object.keys(this.state.point).map(v => {
                                        return <Option value={v}>{this.state.point[v]}</Option>
                                    })
                                }
                            </Select>
                            <Input style={{ width: '10%', margin: '0 10px' }} disabled={this.state.pointone != 'middle'} value={this.state.floor} onChange={e => this.setState({ floor: e.target.value })} ></Input>层
                        </Col>
                    </Row>
                </Modal>
                <div style={{ display: 'none' }}>
                    <Image.PreviewGroup preview={{ visible: this.state.visible, onVisibleChange: (vis, prevVisible) => {
                        this.setState({
                            visible:false
                        })
                    } }}>
                        {
                            JSON.stringify(this.state.swipers) != '{}' && this.state.swipers.length != 0 ?
                                <Image
                                    preview={{ visible: false }}
                                    width='100%'
                                    src={audioUrl + this.state.defaultsrc.before}
                                    onClick={() => this.setState({ visible: true })}
                                />
                                : <img style={{ width: '100%', height: 'auto' }} src={loading} />
                        }
                    </Image.PreviewGroup>
                </div>
                <Col span='24' style={{ border: '1px solid rgba(233, 233, 233, 1)', borderRadius: '5px', marginTop: '20px' }}>
                    <div style={{ padding: '10px 20px', borderBottom: '1px solid rgba(233,233,233, 1)', display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                            调研素材<CloudDownloadOutlined />{/* 未加图片 标记 */}
                        </span>
                        <span>

                            {
                                this.state.projectData.name?.includes('网格系统')?
                                <Button style={{ margin: '0 10px' }} onClick={this.wanggeFun}>网格上传照片</Button>
                                :null
                            }
                            <Button style={{ margin: '0 10px' }} onClick={e => { this.setState({ copyIsbools: true }) }}>复制照片</Button>
                            <Popconfirm
                                title="您确认删除这张图片么"
                                onConfirm={this.deleteimg}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button style={{ margin: '0 10px' }}>删除当前照片</Button>
                            </Popconfirm>
                            <Button style={{ margin: '0 10px' }} onClick={e => this.setState({ addpicturebool: false, xiugaiModal: true })}>添加照片</Button>
                            <Button style={{ margin: '0 10px' }} onClick={e => {
                                this.state.defaultsrc.uploadFlag = 1
                                const isbool = this.state.defaultsrc.isbool
                                const tuihuibool = this.state.defaultsrc.tuihuibool
                                delete this.state.defaultsrc.tuihuibool
                                delete this.state.defaultsrc.isbool
                                axios.put('/api/core/issue', {
                                    ...this.state.defaultsrc
                                })
                                    .then(res => {
                                        this.state.defaultsrc.tuihuibool = tuihuibool
                                        this.state.defaultsrc.isbool = isbool
                                        message.success('添加退回重新上传成功')
                                    })
                            }}>重新上传照片
                            </Button>
                            <Button style={{ margin: '0 10px' }} onClick={e => { this.setState({ uploadbool: true }) }}>查看重新上传照片</Button>
                            <Button disabled={this.state.defaultsrc?.status != 2} onClick={e => this.setState({ xuanzhongModal: true, addpicturebool: true })}>显示选中</Button>
                            <Select style={{ width: 120, margin: '0 10px' }} defaultValue='0' onChange={e => this.ChangerefusedCount(e)}>
                                <Option value="0">全部显示</Option>
                                <Option value="1">原始照片</Option>
                                {
                                    JSON.stringify(this.state.refusedCountArr) != "[]" ?
                                        this.state.refusedCountArr.map((v, i) => {
                                            return <Option value={v + 2}>{v + '次退回照片'}</Option>
                                        }) : null
                                }
                            </Select>
                            <span onClick={this.exportimg}><DownloadOutlined />图片下载</span></span>
                    </div>
                    <Row style={{ padding: '10px 20px' }}>
                        <Col span='16' style={{ padding: '20px' }}>
                            <div style={{ display: 'flex' }}>
                                <UndoOutlined onClick={e => { this.right() }} style={{ marginRight: '10px', fontSize: '25px' }} />
                                <RedoOutlined onClick={e => { this.left() }} style={{ marginRight: '10px', fontSize: '25px' }} />
                                <p style={{ flex: '1', textAlign: 'center' }}>{this.state.swipers.length != undefined ? this.state.num + '/' + this.state.swipers.length : '0/0'}</p>
                                <Button
                                    style={{ margin: '0 10px' }}
                                    onClick={() => {
                                        this.ModalKai()
                                    }}
                                >修改图片信息</Button>
                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{this.state.defaultsrc?.id != '' && this.state.defaultsrc?.id != undefined ? this.state.defaultsrc.refusedCount != 0 ? '第' + this.state.defaultsrc.refusedCount + '次退回的照片' : '原始照片' : null}</span>
                            </div>
                            <div className='swiper-container'>
                                <div style={{ textAlign: 'center', position: 'relative' }} onMouseEnter={e => { this.setState({ cssbool: true }) }} onMouseLeave={e => { this.setState({ cssbool: false }) }}>
                                    <div onClick={this.leftsrc} style={{ width: '50px', height: '100%', display: 'flex', alignItems: 'center', position: 'absolute', left: '0', zIndex: 100 }}><ArrowLeftOutlined style={{ fontSize: '40px', color: this.state.cssbool ? '#fff' : '#000' }} /></div>
                                    {
                                        JSON.stringify(this.state.swipers) != '{}' && this.state.swipers.length != 0 ?
                                            // <Image
                                            //     preview={{ visible: false }}
                                            //     width='100%'
                                            //     src={audioUrl + this.state.defaultsrc.before}
                                            //     onClick={e=>{this.setState({
                                            //         visible: true,
                                            //     })}}
                                            // />
                                            <ImageMagnifier minImg={audioUrl + this.state.defaultsrc.before} maxImg={audioUrl + this.state.defaultsrc.before} />
                                            : <img style={{ width: '100%', height: 'auto' }} src={loading} />
                                    }
                                    <div onClick={this.rightsrc} style={{ width: '50px', height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'absolute', left: '750px', top: '0', zIndex: '1000' }} ><ArrowRightOutlined style={{ fontSize: '40px', color: this.state.cssbool ? '#fff' : '#000' }} /></div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px', height: '120px' }}>
                                <div onClick={this.leftsrc} style={{ width: '50px', background: '#555', display: 'flex', justifyContent: 'center', alignItems: 'center', background: this.state.num == 1 ? '#555' : 'rgba(166,166,166,1)' }}><ArrowLeftOutlined /></div>
                                <Swiper
                                    style={{ flex: '1' }}
                                    slidesPerView={4}
                                    initialSlide={this.state.num + 1}
                                >
                                    {
                                        JSON.stringify(this.state.swipers) != '{}' ? this.state.swipers.map((val, i) => {
                                            return <SwiperSlide style={{ background: this.state.num - 1 == i ? '#ddd' : 'none', padding: '0 10px' }} onClick={() => { this.clisrc(val, i) }}><img style={{ width: '100%', height: '100%' }} class='lazyload' key={i + Math.random()} data-src={audioUrl + val.before} /></SwiperSlide>
                                        }) : null
                                    }
                                </Swiper>
                                <div onClick={this.rightsrc} style={{ width: '50px', background: 'rgba(166,166,166,1)', display: 'flex', justifyContent: 'center', alignItems: 'center', background: this.state.num == this.state.swipers.length ? '#555' : 'rgba(166,166,166,1)' }} ><ArrowRightOutlined /></div>
                            </div>
                            <div>
                                图片ID:{this.state.defaultsrc?.id}
                            </div>
                        </Col>
                        { //
                        }
                        
                        <Col span='8' style={{ paddingLeft: '20px' }}>{/* 右侧 tabbar */}
                            <Tabs defaultActiveKey='0' onChange={this.callback}>
                                {JSON.stringify(this.state.labelValues) != "{}" ? Object.values(this.state.labelValues).map((v, i) => {
                                    return (
                                        <TabPane tab={v} key={i + ""}>
                                            <Row>
                                                <Col span='17' style={{ fontWeight: '600', lineHeight: '50px' }}>指标列表</Col>
                                                <Col span='4' style={{ fontWeight: '600', lineHeight: '50px', textAlign: "center" }}>状态</Col>
                                                <Col span='3' style={{ fontWeight: '600', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>是否退回</Col>
                                                {
                                                    v == '不合格' && JSON.stringify(this.state.swipersnum) != '[]' ? this.state.swipersnum.map((v, i) => {
                                                        return <Row onClick={() => { this.clifilter(v, i) }} style={{ width: '100%', background: this.state.defaultsrc?.tuihuibool && JSON.stringify(this.state.swipers).indexOf('id":' + v.id) != -1 ? '#eee' : 'none', borderRadius: '10px', paddingLeft: '10px', marginBottom: "10px" }}>
                                                            <Col span='17' style={{ padding: '20px 0', color: this.state.lenum == i ? '#47bcff' : '#000' }}>{v.description}</Col>
                                                            <Col span='4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <div style={{ width: '20px', height: '20px', background: v.tuihuibool ? 'red' : '#ddd', borderRadius: '50%', display: 'inline-block' }} onClick={() => this.backbool(i)}></div>
                                                            </Col>
                                                            <Col span='3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{
                                                                this.xuanzhong(v.description) ? <CheckOutlined style={{ color: '#47bcff' }} /> : null
                                                            }</Col>
                                                            {
                                                                this.state.lenum == i ?
                                                                    <Col span='24' style={{ color: '#47bcff', display: 'flex', alignItems: 'center' }}>图片地址:{(this.state.defaultsrc?.addressManualDesc != null ?
                                                                        this.state.defaultsrc?.addressManualDesc :
                                                                        this.state.defaultsrc?.addressDesc) + '/' + this.state.point[this.state.defaultsrc?.point] + (this.state.defaultsrc?.floor != "" ? '/' + this.state.defaultsrc?.floor + '层' : '')}</Col> : null
                                                            }
                                                        </Row>
                                                    }) : null
                                                }
                                                {
                                                    v == '合格' ? <Row style={{ width: '100%', }}>
                                                        <Col span='20' style={{ padding: '20px 0' }}>合格</Col>
                                                        <Col span='4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <div style={{ width: '20px', height: '20px', background: this.state.defaultsrc && this.state.defaultsrc.tuihuibool ? 'red' : '#ddd', borderRadius: '50%', display: 'inline-block' }}
                                                                onClick={() => this.backbool(this.state.numbool)}></div></Col></Row> : null
                                                }
                                                {
                                                    v == '门头' ? <Row style={{ width: '100%', }}>
                                                        <Col span='20' style={{ padding: '20px 0' }}>门头不符</Col>
                                                        <Col span='4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <div style={{ width: '20px', height: '20px', background: this.state.defaultsrc && this.state.defaultsrc.tuihuibool ? 'red' : '#ddd', borderRadius: '50%', display: 'inline-block' }}
                                                                onClick={() => this.backbool(this.state.numbool)}></div></Col></Row> : null
                                                }
                                            </Row>
                                        </TabPane>
                                    )
                                }) : null}
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}

export default withRouter(App)