import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, Modal, Cascader, Menu, Dropdown } from 'antd'
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import axios from '@/utils/axios'
import axios1 from 'axios'
import message from '@/utils/message'
import { stringToNumber, filterSet } from '@/utils/common'
import PopConfirmOnly from '@/utils/PopConfirmOnly'
import store from '@/redux/store'


function App(props) {

  const { push } = useHistory()

  const goDetail = e => {
    let type = null
    if (e.type === 2) type = 'sx'
    if (e.type === 1) type = 'jh'
    if (e.type === 4) type = 'wj'
    if(type == 'wj'){
      push('/' + type + '/' + e.id + '/work/questionnairelist')
    }else if (type)
      push('/' + type + '/' + e.id + '/work/list')
  }

  // table

  const [dataSource, setDataSource] = useState([])
  const columns = [
    {
      title: '项目ID',
      dataIndex: 'id',
      width:200
    },
    {
      title: '项目名称/编号',
      dataIndex: 'name',
      render: (text, record) => (
        <>
          <p>{record.name}</p>
          <p>{record.code}</p>
        </>
      )
    },
    {
      title: '创建人员',
      dataIndex: 'createdByName',
      render: text => text ? text : '-',
      // filters: filterSet(dataSource, 'createdBy').map(i => { return { text: i, value: i } }),
      // onFilter: (value, record) => record.createdBy === value
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: text => <>{labelValues[text]}</>,
      sorter: (a, b) => stringToNumber(a.status) - stringToNumber(b.status)
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: text => text ? text.split('.')[0] : '-',
      sorter: (a, b) => stringToNumber(a.createdAt) - stringToNumber(b.createdAt)
    },
    {
      title: "项目区域",
      dataIndex: 'scope',
      render: text => text ? text : '-',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <>
          {/* <Button onClick={e => push('/home/project/edit/' + record.id)}>修改</Button> */}
          {
            store.getState().user.role == '管理员' || store.getState().user.role == '超级管理员'?<>
              <Button size='small' type='link' onClick={e => push('/home/project/look/' + record.id)}>查看</Button>
              <Divider type='vertical' />
            </>:null
          }
          <Button size='small' type='link' onClick={e => goDetail(record)}>管理</Button>
          {
            store.getState().user.role == '管理员' || store.getState().user.role == '超级管理员'?<>
              <Divider type='vertical' />
              <PopConfirmOnly confirm={e => handleDelete(record.id)}>
                <Button size='small' type='link'>删除</Button>
              </PopConfirmOnly>
            </>:null
          }
        </>
      )
    }
  ]
  //

  const [updateFlag, setUpdateFlag] = useState(0)

  const getData = e => {
    if(store.getState().user.uid == null)return 
    const queryCondition = []
    if (nameSelect) queryCondition.push('LIKE:name=' + nameSelect)
    if (codeSelect) queryCondition.push('EQ:code=' + codeSelect)
    if (typeSelect) queryCondition.push('EQ:type=' + typeSelect)
    if (areaSelect) queryCondition.push('EQ:scope=' + areaSelect)
    if (statusSelect) queryCondition.push('EQ:status=' + statusSelect)
    if (dateSelect.length !== 0) {
      queryCondition.push('GTE:createdAt=' + moment(dateSelect[0]).format('YYYYMMDD'))
      queryCondition.push('LTE:createdAt=' + moment(dateSelect[1]).format('YYYYMMDD'))
    }

    axios.get('/api/core/project/list', {
      params: {
        page,
        pageSize,
        queryCondition: queryCondition.join(','),
        userId:store.getState().user.uid
      }
    }).then(res => {
      setTotal(res.data.totalSize)
      console.log(res.data)
      if(store.getState().user.uid!=4){
        res.data.data=res.data.data.filter(v=>{
          return v.type !=4 && v.type != 5
        })
      }
      const arr = res.data.data.map(i => { return { ...i, key: i.id } })
      setDataSource(arr)
    })
  }

  const [pageSize,setPageSize] = useState(5)//显示数据数量
  const [page,setPage] = useState(1)//数据页数
  const [total,setTotal] = useState(null)//数据总是

  useEffect(()=>{
    getData()
  },[pageSize,page])


  const [labelValues, setlabelValues] = useState([])

  useEffect(() => {
    getData()
  }, [updateFlag])

  useEffect(() => {
    axios.get('/api/sys/dict/projectStatus')
      .then(res => {
        setlabelValues(res.data.labelValues)
      })
  }, [])

  // table select
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const handleSelectChange = e => {
    setSelectedRowKeys(e)
  }
  const handleClearSelect = e => {
    setSelectedRowKeys([])
  }

  // option
  const [projectTypeOption, setProjectTypeOption] = useState([])
  const [projectStatusOption, setProjectStatusOption] = useState([])
  const [areaOption, setAreaOption] = useState([])
  useEffect(() => {
    axios.get('/api/sys/dict/projectType').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setProjectTypeOption(arr)
    })
    axios.get('/api/sys/dict/projectStatus').then(res => {
      const arr = Object.entries(res.data.labelValues).map(i => {
        return { value: i[0], text: i[1] }
      })
      setProjectStatusOption(arr)
    })
    axios.get('/api/core/project/scope').then(res => {
      const arr = res.data.map((i, index) => {
        return { value: i, text: i }
      })
      setAreaOption(arr)
    })
  }, [])


  // select
  const [dateSelect, setDateSelect] = useState([])
  const [statusSelect, setStatusSelect] = useState(null)
  const [typeSelect, setTypeSelect] = useState(null)
  const [nameSelect, setNameSelect] = useState('')
  const [codeSelect, setCodeSelect] = useState('')
  const [areaSelect, setAreaSelect] = useState(null)

  const clearSelect = e => {
    setDateSelect([])
    setStatusSelect(null)
    setTypeSelect(null)
    setNameSelect('')
    setCodeSelect('')
    setAreaSelect(null)
    setUpdateFlag(pre => pre + 1)
  }

  // 删除
  const handleDelete = e => {
    axios.delete('/api/core/project/' + e).then(res => {
      message.success()
      getData()
    })
  }
  const handleDeleteMore = e => {
    if (selectedRowKeys.length === 0) return
    axios.delete('/api/core/project/batch', { data: selectedRowKeys }).then(res => {
      message.success()
      handleClearSelect()
      getData()
    })
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>

        <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>项目名称</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请输入项目名称' value={nameSelect} onChange={e => setNameSelect(e.target.value)}></Input>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>项目状态</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} placeholder='请输入项目状态' value={statusSelect} onChange={e => setStatusSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                projectStatusOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>查询时间</span>
            <DatePicker.RangePicker style={{ width: '240px', marginLeft: '10px' }} value={dateSelect} onChange={e => setDateSelect(e)}></DatePicker.RangePicker>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>项目区域</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} placeholder='请输入项目区域' value={areaSelect} onChange={e => setAreaSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                areaOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>项目类型</span>
            <Select style={{ width: '240px', marginLeft: '10px' }} placeholder='请输入项目类型' value={typeSelect} onChange={e => setTypeSelect(e)}>
            <Select.Option value={''}>取消选择</Select.Option>
            {
                projectTypeOption.map((i, index) => (
                  <Select.Option key={index} value={i.value}>{i.text}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={8}>
            <span style={{ lineHeight: '30px' }}>项目编号</span>
            <Input style={{ width: '240px', marginLeft: '10px' }} placeholder='请输入项目编号' value={codeSelect} onChange={e => setCodeSelect(e.target.value)}></Input>
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col>
            {
              store.getState().user.role == '管理员' || store.getState().user.role == '超级管理员'?
                <PopConfirmOnly confirm={handleDeleteMore}>
                  <Button style={{ margin: '0 10px' }} type='primary' >批量删除</Button>
                </PopConfirmOnly>
              :null
            }
          </Col>
          <Col>
            <Button style={{ margin: '0 10px' }} type='primary' onClick={getData}>查询</Button>
            <Button style={{ margin: '0 10px' }} onClick={clearSelect}>重置</Button>
          </Col>
        </Row>

        {
          selectedRowKeys.length !== 0 ?
            <Row align='middle' gutter={[20, 0]} style={{ marginBottom: '10px' }}>
              <Col><Button danger type='primary' onClick={handleClearSelect}>清空</Button></Col>
              <Col>{'已选择' + selectedRowKeys.length + '项'}</Col>
            </Row>
            :
            null
        }
        <Table
          rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }}
          dataSource={dataSource}
          columns={columns}
          pagination={{defaultPageSize: pageSize, showQuickJumper: true, showSizeChanger: true,total,current:page , onChange:(page,pageSize)=>{
            setPage(page)
            setPageSize(pageSize)
            }
          }}
        />

      </div>
    </div>
  )
}

export default App