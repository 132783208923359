import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Divider, Select, Button, Table, Checkbox, DatePicker, Input, message } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import axios from '@/utils/axios'
import axios1 from 'axios'
import {tousuUrl,audioUrl1} from '@/utils/config'
import store from '@/redux/store'
import moment from 'moment'
import { baseUrl,audioUrl } from '@/utils/config.js'


const { RangePicker } = DatePicker;

function App(props) {
  const { push } = useHistory()
  const { projectid } = useRouteMatch().params

  const [data,setData] = useState([])

  const getData = (value)=>{
    
    let res={
      "code":0,
      "msg":"success",
      "data":[
          {
              "t":{
                  "id":1,
                  "teamNo":"NO1",
                  "teamName":"战队1",
                  "status":0,
                  "createdAt":"2022-04-25 10:05:31.000",
                  "updatedAt":"2022-04-25 10:05:31.000"
              },
              "topN":[
                  {
                      "today_step":-80000,
                      "open_id":"ooMFf42iCIm2nDTDEspdOTNx9eIs",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJwIA2WteU2QwMdxoeRUjq9QBS72C2pVBCa137gBtprXiak7mTiaHVLtnyc5jW1ny5LibekELrmBb3ZA/132",
                      "user_name":"樂",
                      "total_step":-81852,
                      "mobile":"15633669676"
                  },
                  {
                      "today_step":-80000,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/IlkVVC2bz8sZyKhVEXibtvlWWicCT3HB0Np4b0ALEe04d136zoO7Wk88JKvA7aARfice4PIVxbUf9qbpIoiaibg37cw/132",
                      "total_step":-84281,
                      "user_name":"",
                      "mobile":"13015471847",
                      "open_id":"ooMFf40C64mAhGczIBXfFCLZlyXU"
                  },
                  {
                      "total_step":-132658,
                      "today_step":-76192,
                      "mobile":"17897848007",
                      "user_name":"请叫我阿穷ᯤ⁶ᴳ",
                      "open_id":"ooMFf42Zn9WiGAyS-HTgBMHuwe0E",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erycUticOtickouwARiaWcxPXNTmqewr3vU8q0WGOJu3q3nogJSGuhKdnYB3ibH5SZZtHNket0BP8RcIQ/132"
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/AFObj1pTWjtcXP0Ea8UDBzOLKm4pV5Aibqy7abTdbS7cib3QgkljTP9sd2CYFK1Qicc5Z4DqjMy6AfR5UfUd1eq0w/132",
                      "mobile":"15364540866",
                      "user_name":"微",
                      "today_step":-45000,
                      "total_step":-111776,
                      "open_id":"ooMFf45WfL0DmKC9PwAZvUf0JRuI"
                  },
                  {
                      "user_name":"醉生梦死",
                      "mobile":"15835059449",
                      "today_step":-45000,
                      "open_id":"ooMFf45HXrLOGmg1jtEHG-FSTuIo",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKYu6n1Xib4l2FQorVHV7CdQZhbI9kojfOTsnsShzibtxroETOXrcQzsOymJNlZPQeeFc4TicPF6j2jg/132",
                      "total_step":-109202
                  },
                  {
                      "open_id":"ooMFf43xtpKEOxfC8ABOs6XExqe4",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/1dD8rnYEI7UcZmJ27bJkK0iaiaDTyJ7r4Aoz55bXwTS6jrODQFjS09uKzl2VZTzibTS8AaywDqPh1icacp1zYA93kw/132",
                      "total_step":-111149,
                      "mobile":"18734472789",
                      "today_step":-41039,
                      "user_name":"樱桃丸子"
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/0kSicBiceJpa34gr3yLR8zkuAZqA809eRicSebcLXYBJjUYNDa7aU7ibKbwgM462g6doHtBDu8FbyJENNlMmPDkAGQ/132",
                      "total_step":-106396,
                      "open_id":"ooMFf4-iBHLFCnDb2_894W0FXqk8",
                      "mobile":"18234488789",
                      "user_name":"加卡里",
                      "today_step":-40649
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/tVlPXqkOgibV7NEF0aANULk5juibibCUMeuFPmO2SoVfcvYhV5iaKic7mcgKgPoFgeU4IOlBXHgRLfhUUnf1H6V8guw/132",
                      "today_step":-40000,
                      "user_name":"",
                      "total_step":-80000,
                      "open_id":"ooMFf4yC7DxYBdkLKL_Cx90ooGx0",
                      "mobile":"15581843217"
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/eTUqLIcjOgg04fM1ajROXCn93MU7JkweOOcJdSGX8BdXdAKYR9cNI54PIRWKsWCpNpoJF1rAOGoFx2o7EbsicPw/132",
                      "total_step":-44281,
                      "mobile":"13015474787",
                      "today_step":-40000,
                      "user_name":"",
                      "open_id":"ooMFf4_pKHCsODp3cH3CPrsKfptc"
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/RYSoaAcXDTptGI0zTyhrqWATMoF8iaHVqUCdtqGHXMTA7dY2c2ZsRgA9IrtzxxLa771C4WCwX5icI2EsClbquyrw/132",
                      "total_step":-44281,
                      "mobile":"13015474247",
                      "today_step":-40000,
                      "open_id":"ooMFf45PH-rV5EDPoQa45WujOpnU",
                      "user_name":""
                  }
              ]
          },
          {
              "t":{
                  "id":2,
                  "teamNo":"NO2",
                  "teamName":"战队2",
                  "status":0,
                  "createdAt":"2022-04-25 10:05:31.000",
                  "updatedAt":"2022-04-25 10:05:31.000"
              },
              "topN":[
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/vgx2iaknIqBDc67o44WI5vkXLsZibYbPjcGltia9iaZLXq3kJY0Rrep7EdFENV8r3YwwLTccTEmmJRDjP0jicS1fuKw/132",
                      "total_step":-55821,
                      "mobile":"13292502091",
                      "open_id":"ooMFf46I0hocBtVaz6O8nzrrAe4w",
                      "user_name":"义",
                      "today_step":-26566
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/SLiaibZYRlWGxwA6AUeG8CF3FGiaty11cmtcIx0muLLicqWOh4EUz7qULunE9fZU0FdLVpj8ZibaicuY0SXWPo7z8Oibw/132",
                      "user_name":"韩雨",
                      "open_id":"ooMFf4xf9W34H_PAhqcHPE9Ddo_M",
                      "today_step":-23233,
                      "mobile":"13188477055",
                      "total_step":-58312
                  },
                  {
                      "user_name":"乐在其中",
                      "mobile":"18519255540",
                      "today_step":-23190,
                      "total_step":-69585,
                      "open_id":"ooMFf406mrnZug0e3k8jGH9GVxLo",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/UjfHAxiaKrqZ4oXQj3HVGoOLyPUrqdiasaxSqiac1HwYJ3ic53R555yMT8egxd0DpiaIbLjZJeU9QA3cw677vazP26A/132"
                  },
                  {
                      "user_name":"商永胜",
                      "mobile":"13831553092",
                      "today_step":-22715,
                      "open_id":"ooMFf4xklfTVMrK6A-4pzNw2RHv0",
                      "total_step":-62166,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/gnubyuV2Z7fe7rs75EOjBvhGNNOysAa6E3gMIY69WeDRglfvKswoAtlvwQzSpDtpZibVCMcGoC6IPLlWhTUgMug/132"
                  },
                  {
                      "today_step":-22286,
                      "open_id":"ooMFf47-NqQTiQrLz6vAVhIRDps4",
                      "mobile":"15333151920",
                      "user_name":"林毅",
                      "total_step":-58912,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKhycujyahiaQ4OFTjiaF6CLgKOibLI1iaialYATpvtxlsUYzjfcjKsNLQNX29DWiaHwFj5FVnGmI1P1Wfg/132"
                  },
                  {
                      "user_name":"Sj",
                      "total_step":-54564,
                      "mobile":"13513150768",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epYQOymRxftOu3N9ibGL8aczqhcBfrm3WF3ethdic2e4bUvaFRj8oe3jz1SEia7SdCMeFI7s3leHbNPg/132",
                      "today_step":-21244,
                      "open_id":"ooMFf4_qpbiOAdE04qRt04yPaQaU"
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eo30ZfwejhicTicV8EuPdgb9tLyJ0icl3c3xqbq7ADZekFkCsdLvgAdpJeLghZSPz4oy3HVXaAp63JIA/132",
                      "user_name":"阿来",
                      "today_step":-20031,
                      "open_id":"ooMFf49U29cPdtjAwNsEZN06ZVOw",
                      "mobile":"18264188618",
                      "total_step":-37728
                  },
                  {
                      "mobile":"15533595488",
                      "total_step":-31186,
                      "open_id":"ooMFf406GaXMyedF44VcruJ-OrMk",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/fAQWJhZt4p25xCvVtdj2DBicm0R6YdmBXxe5ic3UniaaHb2K2ZRrDgWibk0Jp1zx9Gr5LTZZKzrTuNNdWsHpicWVJHQ/132",
                      "user_name":"宁翔",
                      "today_step":-18450
                  },
                  {
                      "user_name":"玻璃鱼",
                      "today_step":-18281,
                      "mobile":"13831578894",
                      "total_step":-39512,
                      "open_id":"ooMFf48d5tJEav9e0N4nwYwCXQqQ",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/yibuibuL1rMx0uKm3sJKrPwic5moqUqgq7fh0RQcG7tEAiaAia3CKq1euz8mQDichrP1GXqj40CFeibXiblg0wicbo8M4bQ/132"
                  },
                  {
                      "open_id":"ooMFf4wiHPXW1azSHf898D-6VuPs",
                      "total_step":-45000,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/caUgVMmqBGloaE9l0prAULIPnva4UGILNJW3Jkkia2h7ypDtfLCCblQgdM3E6iahibPoAicENcdVslXtnm0icWichAzg/132",
                      "today_step":-16936,
                      "mobile":"13230822852",
                      "user_name":"秀秀"
                  }
              ]
          },
          {
              "t":{
                  "id":3,
                  "teamNo":"NO3",
                  "teamName":"战队3",
                  "status":0,
                  "createdAt":"2022-04-25 10:05:31.000",
                  "updatedAt":"2022-04-25 10:05:31.000"
              },
              "topN":[
                  {
                      "user_name":"小帮，帮看，帮跑",
                      "today_step":-37632,
                      "total_step":-84992,
                      "mobile":"18059647007",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epXnSNIxzE1bkoqDO2FR8CKP5J4rsZuPwIborCmeNHdWkLgLXaHadicV0p0lUcN1ib3Whx6PKibcYicTA/132",
                      "open_id":"ooMFf43a5WiC_-9KfuyqV2zXhHNA"
                  },
                  {
                      "user_name":"双塔",
                      "today_step":-36851,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIdRzJ65COm8pMY4TW1hPFa3IM0IOExmiaKS3AKnJhmU7V8H8icYk3oviaBYNgqiaTwaKrw21tI9bNSdQ/132",
                      "mobile":"13903324410",
                      "open_id":"ooMFf49zQ7YzFGySvs3GCTGeJeEo",
                      "total_step":-36851
                  },
                  {
                      "mobile":"13831993159",
                      "today_step":-36529,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKq510bhIySL1vic1702sCTSGnF7WVZmaj9CKlsRTGUzK8Wq6vs928HvcAH5DicMuUhPF33trUtYshw/132",
                      "user_name":"\uD83C\uDF51",
                      "open_id":"ooMFf4zu-Iyt8ePzYq29C9S9WAvg",
                      "total_step":-71586
                  },
                  {
                      "mobile":"13623292260",
                      "today_step":-35751,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIJ7qNgcOodXLNypQYfbjs2f22ZqGxmn0Qg1p7vhyQXxzrfr5hF2QkTKIUGsQhJxibiappSIc1Kptuw/132",
                      "total_step":-97352,
                      "open_id":"ooMFf4zda8eBuKoSdM8Avbo5P4Tc",
                      "user_name":"周"
                  },
                  {
                      "user_name":"夏目",
                      "total_step":-34782,
                      "mobile":"13123322735",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/zYNm1iay2BtD5n4ZDsykEQCH25QMiaLcXYt5RTgHR1sm5rhhrFKvU6ibbzicic2SCh84PSo0ibNFBJlSnHGObf2Tytew/132",
                      "open_id":"ooMFf4-hIXJ4rbjZFyWIoOwAu3Ak",
                      "today_step":-34782
                  },
                  {
                      "today_step":-33828,
                      "user_name":"平凡",
                      "total_step":-103530,
                      "open_id":"ooMFf43Pn3w8-CvU0_xpzcb0DKUA",
                      "mobile":"13292105292",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Yicib1XP4ia9VHOVjFOwqm63D5DnWWAovgHnJEvRKYiagRGibOo2rClh6vjucJXzDEibDXzDpRdc4kbicteicNuicvs3icXQ/132"
                  },
                  {
                      "open_id":"ooMFf43mNSvPRqzsUsF_xLB7zvn4",
                      "mobile":"13328348256",
                      "total_step":-74971,
                      "today_step":-33602,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/H9O9fay6booAUibviaKyZPohp04ibN9UJ1EekTVePxGfcO1lFaWFaibtQRyetfYmAlCf6OuR5pQVyl300zHm7dGn8g/132",
                      "user_name":"花\uD83C\uDF38"
                  },
                  {
                      "user_name":"阿伟",
                      "open_id":"ooMFf4ylw-wzKjteLKsEd7RnbZ7E",
                      "total_step":-94090,
                      "today_step":-32374,
                      "mobile":"13803125520",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eq8qpSvBoZz82yN9WPFsVenZREdVvsqp0wpibTRvQI82jU0HRhLAPnIb31fzJl722pOS9l1ibRYxPEw/132"
                  },
                  {
                      "open_id":"ooMFf4ysV5o5ngX6HE-OQEbzWLh8",
                      "total_step":-92230,
                      "today_step":-31032,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM5Ies5taLicaZEYahEek0icpfjRxl9AUx8gRI36xxG8e1apxiad9UwvOGnRR6u2RKibPibFKzGMzUcYQrw/132",
                      "user_name":"方小雯",
                      "mobile":"13599579903"
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/4vNosr1iajSgRv7Lp9H1kDyVJSsn6Tkbv9Fj2p6ea7HRvT9h6RBt2x1y2cqctOmQLU0Ysb7KiaKWubvPnoxmyPng/132",
                      "open_id":"ooMFf4x4Do-A7q2UIZg_6dwO6haU",
                      "total_step":-69926,
                      "mobile":"13105022827",
                      "today_step":-30293,
                      "user_name":"岁月静好"
                  }
              ]
          },
          {
              "t":{
                  "id":4,
                  "teamNo":"NO4",
                  "teamName":"战队4",
                  "status":0,
                  "createdAt":"2022-04-25 10:05:31.000",
                  "updatedAt":"2022-04-25 10:05:31.000"
              },
              "topN":[
                  {
                      "mobile":"18953147729",
                      "open_id":"ooMFf4zuV3oTV-9JN11lNE8bdetM",
                      "total_step":-83288,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/ZpibNKW1I3lE5ibpqeUrQOSj2WoZh0sLib6Pj3AmicWLq9mtQhGr2qpquDDjGiaxEv9IL3jDdogPKqzOVohFYYB95KA/132",
                      "today_step":-83288,
                      "user_name":"山水之间"
                  },
                  {
                      "total_step":-60578,
                      "open_id":"ooMFf47WorzlavC1VamPoPfAUcwc",
                      "user_name":"落寞的荒年",
                      "today_step":-60578,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLxAc1Npbp9uUIAvO8DyNDib5VTibWGYuicOFQ448GdbEHoicrMVzFFerxqnnGQ21GIPOyYn6Rribf9Klw/132",
                      "mobile":"13513260893"
                  },
                  {
                      "icon_url":"",
                      "today_step":-33863,
                      "total_step":-105000,
                      "mobile":"13831028123",
                      "user_name":"张文武",
                      "open_id":"ooMFf43vkv_XRGFRPFCyje1Swz1M"
                  },
                  {
                      "user_name":"王晨曦",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/qpRNrCzawGGeP8U8Z5jQLVaLa1Rxic4von2IvgTDm3On2GjHSU68UQwoGxFn0qWSGBLLCaNLAAZsZPrvQajJ2Bg/132",
                      "open_id":"ooMFf49x5E3qTcJb7uTZ5BpkebXs",
                      "mobile":"15537010795",
                      "today_step":-32113,
                      "total_step":-85832
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/hIxY8K1PHwZojoAataRhGsiaL1tJysnbYoNFfJxibW4Ox2Tj6IY67fj4h6MyoBt6dfU8Ccibic5pg29zqz0EcD68GA/132",
                      "today_step":-30025,
                      "total_step":-79724,
                      "mobile":"15205183150",
                      "open_id":"ooMFf491Iir11h_bwqubAmWQok0c",
                      "user_name":"岁月如梭"
                  },
                  {
                      "open_id":"ooMFf4x3lJ_-kv_5ZGrW7PXdypjA",
                      "today_step":-28223,
                      "total_step":-75000,
                      "mobile":"18042015772",
                      "user_name":"掩于岁月",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqnkJjNMI9GI2eVQPMl6IXdkSYk346BU2YMNKhv4nyiapDx3ZeNvf2ANry7A8PrHq2A6nSkw1aT8Iw/132"
                  },
                  {
                      "today_step":-25006,
                      "mobile":"13231098863",
                      "open_id":"ooMFf4_7PGxRYruj6RvmlhkpltGQ",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqnchGaR5IbndiboibxGPHn7hDVbC8kU6nNZAGrZfBv3dxEwTkeVSUcicJSJB3pkWhlFgtQrHcGOFqQQ/132",
                      "user_name":"扬帆启航",
                      "total_step":-67746
                  },
                  {
                      "total_step":-60000,
                      "today_step":-23112,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/iceeficauWPXLSGR2Efr3FSicn1HZJvn0fEwrzJBLNdLIibpuP54XM1NowForchKfoEGclHV1DuUCw8oLVcEDHeBuw/132",
                      "user_name":"和",
                      "mobile":"13932088907",
                      "open_id":"ooMFf48UzsPgOPaLd1yHy6_IZ0xs"
                  },
                  {
                      "mobile":"13283432686",
                      "open_id":"ooMFf4-fnKKXs5Pf2N9JREALc52Y",
                      "user_name":"权少",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/868afDa0VXb5WRaO8Z6h1zhjHRicZhFGUCeO73sWWtqCjiafcjJ3sSSxtyNFXvDSaj2K6aZlVYvEMvdLO6ANaxIg/132",
                      "today_step":-21939,
                      "total_step":-41394
                  },
                  {
                      "mobile":"13731609272",
                      "user_name":"风中的彩铃",
                      "total_step":-55342,
                      "today_step":-21302,
                      "open_id":"ooMFf4zbbguT_ll5cjRrQkzBBxOc",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/N04Oan9FA40ytTeZAlZ2hNnrvuKuibggeicaLsibgIF8Q9P7NvPicXqv8yUMvtPzR57oT85pPicv0q3EmtZWADdJDFg/132"
                  }
              ]
          },
          {
              "t":{
                  "id":5,
                  "teamNo":"NO5",
                  "teamName":"战队5",
                  "status":0,
                  "createdAt":"2022-04-25 10:05:31.000",
                  "updatedAt":"2022-04-25 10:05:31.000"
              },
              "topN":[
                  {
                      "mobile":"13932434013",
                      "open_id":"ooMFf47sWY1dvipF7IAfUdY4UacQ",
                      "total_step":-141325,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/w53X2zzOWSLhYscDrEYicDjzXRzwPjyd8JubtMOPXEXPZZhtaeia8PGJL5NuUD4rqr2UriclSicjzbBlADzKJrAibbw/132",
                      "user_name":"幽若谷",
                      "today_step":-50915
                  },
                  {
                      "total_step":-110923,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epx3CDc9CQvibxSFsDD8zwASUnaOHr05c0xJkcBT9Jf2LVOje7riapbPsEeyJPhN7zNNLhcu3M8Uz2A/132",
                      "open_id":"ooMFf4xzsB1lO4e8_EDpuQBnmhYw",
                      "user_name":"相逢一笑",
                      "mobile":"18631406989",
                      "today_step":-45597
                  },
                  {
                      "today_step":-38964,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLgsx7Jic0oLz7IOc1yVMFF9WNdFNvvaX3icicBJSCUqiaUCfmNOOibIV98OIYRZmibIAt63Ye3jTM6ia2iaA/132",
                      "total_step":-84850,
                      "open_id":"ooMFf45g-ME8zgS66s5kwCR0wtas",
                      "user_name":"联合",
                      "mobile":"13932415082"
                  },
                  {
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKxR1xib3oSvKlO7gWEZW4aAWnBlrG9WyjMPfbvKicibogJZvoIWeJwuFQsqkFAVBPY23GicpuuicNM8ibw/132",
                      "total_step":-98800,
                      "open_id":"ooMFf4_ge_L4sEKqYqlePTsL-YJI",
                      "mobile":"15903144459",
                      "today_step":-30690,
                      "user_name":"X k L C F"
                  },
                  {
                      "mobile":"18019575597",
                      "open_id":"ooMFf4yfz3jzbMyprnc3euyyOxrc",
                      "today_step":-30025,
                      "total_step":-69913,
                      "user_name":"半世浮华",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/KBJO5q2JNib09HzMERgNI5C2QmMDT1IV6qt1dozfwbRO31llmic7BdRtW0s1G23TrE6RZMPlHyWk46K2hSMb7Rsw/132"
                  },
                  {
                      "mobile":"15324068088",
                      "today_step":-28317,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLtjqoUNGKOVQGqzjWcJticePic1pvK8SXykkI4MeSfR5MHAwFVeTeuibdhsWxPibaI59Ult47n0W1eyA/132",
                      "total_step":-85000,
                      "open_id":"ooMFf4xZE3NLRhsedMNcMZ65mSfI",
                      "user_name":"dyx...."
                  },
                  {
                      "mobile":"15830321113",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIKzibSmweoUKrOgOCx4dVXsoTrSXib7hRawuZicNcAdhdgpkUQwXgTYSzln2SZpKyyVJVR3vpRQWVxw/132",
                      "today_step":-28156,
                      "total_step":-68872,
                      "open_id":"ooMFf447FMl8AAz36_0-Z1m-h03g",
                      "user_name":"老衲法号不戒"
                  },
                  {
                      "mobile":"13785380658",
                      "total_step":-51040,
                      "user_name":"虎视鹰扬",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/beG9Kn8SRRYJ8ibr9IUoibx4hXbF8jVKDF8olia8LpPNn54KKGJ2Ezr8WKqCvLQE4wZv6jCKXUbQY7Ir6q3TyddDQ/132",
                      "today_step":-27938,
                      "open_id":"ooMFf4-DlSveqtgOns4H_Gz1osx4"
                  },
                  {
                      "user_name":"杰",
                      "open_id":"ooMFf4wD_v_IPPCEpC9D0vjKEF9U",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoEia8b2zh1kx0pUmBMbdj26x3FMryrf3icPgMbEE4KPV1vFBrPyPWh8uJFlW8vHfC9GycT1vCpicTeA/132",
                      "today_step":-27090,
                      "total_step":-60539,
                      "mobile":"15922161623"
                  },
                  {
                      "open_id":"ooMFf4yDMBSl3pIHS5NShMkdTAdI",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/ueDfxKiaib9SnurP54VZYwibUaUr0hCC0rYEOvAwJy6ZbicaOeOAhPZU11KmV1oKFbgFicshldjpFbM3S1KoWuUxtkw/132",
                      "total_step":-47724,
                      "today_step":-25630,
                      "user_name":"岳宏光",
                      "mobile":"15304541317"
                  }
              ]
          },
          {
              "t":{
                  "id":6,
                  "teamNo":"NO6",
                  "teamName":"战队6",
                  "status":0,
                  "createdAt":"2022-04-25 10:05:31.000",
                  "updatedAt":"2022-04-25 10:05:31.000"
              },
              "topN":[
                  {
                      "open_id":"ooMFf4-kAebC5-JPwmqoxWx6VDHo",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/icKGPZ8Gu6zLtOemdsMFxIvVfw9eGmic7aAXdUZ0Ewt3icksfoWXh2gtmsRxaAEwj9a0hibz0IrV0ksACcicGt4lqgw/132",
                      "total_step":-105000,
                      "user_name":"A00晓冉名车俱乐部",
                      "mobile":"15632785679",
                      "today_step":-62355
                  },
                  {
                      "open_id":"ooMFf4xhjCv-G3OY9syCo91cEsSA",
                      "today_step":-42866,
                      "mobile":"13803240318",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erdzuL8ibILIfczcJPVSKDh4ManvnCeJVtpSEVzAnKTOftNdAjAico79tahQqpt3iaSf6SqmK9y0OSUg/132",
                      "total_step":-111440,
                      "user_name":"观鱼"
                  },
                  {
                      "total_step":-118185,
                      "today_step":-40099,
                      "open_id":"ooMFf4xwl92GgnasTiobXfcdlKMU",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL2uBsGc2ZrMzgvjlmCoPCq1njwEvU75JBhauLZIZyJJHON9uyBTse1XwXaxorfMyzEQWMIYVicFqQ/132",
                      "user_name":"黄志强",
                      "mobile":"15703301581"
                  },
                  {
                      "today_step":-38453,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/YI5msfoQs0lvOFhPdGib8Yq8fvZbKNYvbicdZDEkIpxxx9d4r70eTK0u7wS7t7bLEzZnXjONsqZEQ2QpSG4icByvA/132",
                      "total_step":-133814,
                      "user_name":"刘振河",
                      "open_id":"ooMFf49Z0UP6uMSI4yto3gWQ2Vws",
                      "mobile":"13731786898"
                  },
                  {
                      "today_step":-35125,
                      "mobile":"13731799033",
                      "open_id":"ooMFf49fQfR5kqPkWlaK1jVTsAeY",
                      "user_name":"深蓝",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEKnLmWeMz9vTB22fgM915bLianulu9mca9ApxktaRrqQTTPLJCpibEPV2xDrSKLNtCBkwkOBpGsEaHw/132",
                      "total_step":-71223
                  },
                  {
                      "mobile":"13933675678",
                      "user_name":"月是故乡明",
                      "total_step":-80140,
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/7M35dvMChrHpQhBCRPS2b4sDMx0CXQviclCFhAwS0wLyJFORF1HRDHIsjdZqcxPTfO7wd8TO6atjosSJvgqI7XA/132",
                      "today_step":-30000,
                      "open_id":"ooMFf40unhYjKHAIVp-gt5Jgu1Qk"
                  },
                  {
                      "total_step":-90000,
                      "open_id":"ooMFf42QtdgrCqA6pjwz5xf677t0",
                      "user_name":"自然",
                      "today_step":-30000,
                      "mobile":"13703358070",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIkkg9icSGleYB9z06eYN7Y7MGg6xq4Kfw15B8TuamuprQYquJSKHMsuGux5wdxLcufpMvsep1LzaQ/132"
                  },
                  {
                      "today_step":-29259,
                      "user_name":"大道至简",
                      "open_id":"ooMFf44oJheCIKE-oLmnpgSzDZM0",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Fbw5C4CguVYaJumnqHguDOxgeicN9EicGFuCRS1jkGsU7YNqeXF8r1UeZxXnmjibfB65zdGjSC8ticMqGUCOibUeuqw/132",
                      "mobile":"13932717032",
                      "total_step":-67763
                  },
                  {
                      "today_step":-28699,
                      "open_id":"ooMFf494d9BeWRKkbnmDhxbrxLkk",
                      "mobile":"15503189358",
                      "user_name":"感悟",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/1o1TKDfl8I95sqicwb55zZTE3micD9MVZ60icJQYBiapTdxDzMMbhutAicOVITkRegTTelJDFLBAaKGL8tYxHlYxxicA/132",
                      "total_step":-63374
                  },
                  {
                      "user_name":"猪猪侠1206",
                      "total_step":-79964,
                      "mobile":"15632706116",
                      "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/lE1u95C1WYDwM9HhHwrFMNOvh8nHyTjGEOughqyPbyaN1nOhTfm6jiatibKaX0amnxJkec2Xibh1TeNXNDic5ZySCA/132",
                      "open_id":"ooMFf44TXOAaDvkiX9xJmi-ABkgE",
                      "today_step":-26741
                  }
              ]
          }
      ]
  }
    console.log(res)
    setData(res.data)
    axios1.get(tousuUrl+'/smart-game/team/rank/list?appId=test&appKey=test&topN=10&date=20220509',{},{
        // params:{
        //     pageNo:value,
        //     pageSize:10,
        //     createTime_btw:time
        // }
      })
      .then(res=>{
        res={
          "code":0,
          "msg":"success",
          "data":[
              {
                  "t":{
                      "id":1,
                      "teamNo":"NO1",
                      "teamName":"战队1",
                      "status":0,
                      "createdAt":"2022-04-25 10:05:31.000",
                      "updatedAt":"2022-04-25 10:05:31.000"
                  },
                  "topN":[
                      {
                          "today_step":-80000,
                          "open_id":"ooMFf42iCIm2nDTDEspdOTNx9eIs",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJwIA2WteU2QwMdxoeRUjq9QBS72C2pVBCa137gBtprXiak7mTiaHVLtnyc5jW1ny5LibekELrmBb3ZA/132",
                          "user_name":"樂",
                          "total_step":-81852,
                          "mobile":"15633669676"
                      },
                      {
                          "today_step":-80000,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/IlkVVC2bz8sZyKhVEXibtvlWWicCT3HB0Np4b0ALEe04d136zoO7Wk88JKvA7aARfice4PIVxbUf9qbpIoiaibg37cw/132",
                          "total_step":-84281,
                          "user_name":"",
                          "mobile":"13015471847",
                          "open_id":"ooMFf40C64mAhGczIBXfFCLZlyXU"
                      },
                      {
                          "total_step":-132658,
                          "today_step":-76192,
                          "mobile":"17897848007",
                          "user_name":"请叫我阿穷ᯤ⁶ᴳ",
                          "open_id":"ooMFf42Zn9WiGAyS-HTgBMHuwe0E",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erycUticOtickouwARiaWcxPXNTmqewr3vU8q0WGOJu3q3nogJSGuhKdnYB3ibH5SZZtHNket0BP8RcIQ/132"
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/AFObj1pTWjtcXP0Ea8UDBzOLKm4pV5Aibqy7abTdbS7cib3QgkljTP9sd2CYFK1Qicc5Z4DqjMy6AfR5UfUd1eq0w/132",
                          "mobile":"15364540866",
                          "user_name":"微",
                          "today_step":-45000,
                          "total_step":-111776,
                          "open_id":"ooMFf45WfL0DmKC9PwAZvUf0JRuI"
                      },
                      {
                          "user_name":"醉生梦死",
                          "mobile":"15835059449",
                          "today_step":-45000,
                          "open_id":"ooMFf45HXrLOGmg1jtEHG-FSTuIo",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKYu6n1Xib4l2FQorVHV7CdQZhbI9kojfOTsnsShzibtxroETOXrcQzsOymJNlZPQeeFc4TicPF6j2jg/132",
                          "total_step":-109202
                      },
                      {
                          "open_id":"ooMFf43xtpKEOxfC8ABOs6XExqe4",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/1dD8rnYEI7UcZmJ27bJkK0iaiaDTyJ7r4Aoz55bXwTS6jrODQFjS09uKzl2VZTzibTS8AaywDqPh1icacp1zYA93kw/132",
                          "total_step":-111149,
                          "mobile":"18734472789",
                          "today_step":-41039,
                          "user_name":"樱桃丸子"
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/0kSicBiceJpa34gr3yLR8zkuAZqA809eRicSebcLXYBJjUYNDa7aU7ibKbwgM462g6doHtBDu8FbyJENNlMmPDkAGQ/132",
                          "total_step":-106396,
                          "open_id":"ooMFf4-iBHLFCnDb2_894W0FXqk8",
                          "mobile":"18234488789",
                          "user_name":"加卡里",
                          "today_step":-40649
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/tVlPXqkOgibV7NEF0aANULk5juibibCUMeuFPmO2SoVfcvYhV5iaKic7mcgKgPoFgeU4IOlBXHgRLfhUUnf1H6V8guw/132",
                          "today_step":-40000,
                          "user_name":"",
                          "total_step":-80000,
                          "open_id":"ooMFf4yC7DxYBdkLKL_Cx90ooGx0",
                          "mobile":"15581843217"
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/eTUqLIcjOgg04fM1ajROXCn93MU7JkweOOcJdSGX8BdXdAKYR9cNI54PIRWKsWCpNpoJF1rAOGoFx2o7EbsicPw/132",
                          "total_step":-44281,
                          "mobile":"13015474787",
                          "today_step":-40000,
                          "user_name":"",
                          "open_id":"ooMFf4_pKHCsODp3cH3CPrsKfptc"
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/RYSoaAcXDTptGI0zTyhrqWATMoF8iaHVqUCdtqGHXMTA7dY2c2ZsRgA9IrtzxxLa771C4WCwX5icI2EsClbquyrw/132",
                          "total_step":-44281,
                          "mobile":"13015474247",
                          "today_step":-40000,
                          "open_id":"ooMFf45PH-rV5EDPoQa45WujOpnU",
                          "user_name":""
                      }
                  ]
              },
              {
                  "t":{
                      "id":2,
                      "teamNo":"NO2",
                      "teamName":"战队2",
                      "status":0,
                      "createdAt":"2022-04-25 10:05:31.000",
                      "updatedAt":"2022-04-25 10:05:31.000"
                  },
                  "topN":[
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/vgx2iaknIqBDc67o44WI5vkXLsZibYbPjcGltia9iaZLXq3kJY0Rrep7EdFENV8r3YwwLTccTEmmJRDjP0jicS1fuKw/132",
                          "total_step":-55821,
                          "mobile":"13292502091",
                          "open_id":"ooMFf46I0hocBtVaz6O8nzrrAe4w",
                          "user_name":"义",
                          "today_step":-26566
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/SLiaibZYRlWGxwA6AUeG8CF3FGiaty11cmtcIx0muLLicqWOh4EUz7qULunE9fZU0FdLVpj8ZibaicuY0SXWPo7z8Oibw/132",
                          "user_name":"韩雨",
                          "open_id":"ooMFf4xf9W34H_PAhqcHPE9Ddo_M",
                          "today_step":-23233,
                          "mobile":"13188477055",
                          "total_step":-58312
                      },
                      {
                          "user_name":"乐在其中",
                          "mobile":"18519255540",
                          "today_step":-23190,
                          "total_step":-69585,
                          "open_id":"ooMFf406mrnZug0e3k8jGH9GVxLo",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/UjfHAxiaKrqZ4oXQj3HVGoOLyPUrqdiasaxSqiac1HwYJ3ic53R555yMT8egxd0DpiaIbLjZJeU9QA3cw677vazP26A/132"
                      },
                      {
                          "user_name":"商永胜",
                          "mobile":"13831553092",
                          "today_step":-22715,
                          "open_id":"ooMFf4xklfTVMrK6A-4pzNw2RHv0",
                          "total_step":-62166,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/gnubyuV2Z7fe7rs75EOjBvhGNNOysAa6E3gMIY69WeDRglfvKswoAtlvwQzSpDtpZibVCMcGoC6IPLlWhTUgMug/132"
                      },
                      {
                          "today_step":-22286,
                          "open_id":"ooMFf47-NqQTiQrLz6vAVhIRDps4",
                          "mobile":"15333151920",
                          "user_name":"林毅",
                          "total_step":-58912,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKhycujyahiaQ4OFTjiaF6CLgKOibLI1iaialYATpvtxlsUYzjfcjKsNLQNX29DWiaHwFj5FVnGmI1P1Wfg/132"
                      },
                      {
                          "user_name":"Sj",
                          "total_step":-54564,
                          "mobile":"13513150768",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epYQOymRxftOu3N9ibGL8aczqhcBfrm3WF3ethdic2e4bUvaFRj8oe3jz1SEia7SdCMeFI7s3leHbNPg/132",
                          "today_step":-21244,
                          "open_id":"ooMFf4_qpbiOAdE04qRt04yPaQaU"
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eo30ZfwejhicTicV8EuPdgb9tLyJ0icl3c3xqbq7ADZekFkCsdLvgAdpJeLghZSPz4oy3HVXaAp63JIA/132",
                          "user_name":"阿来",
                          "today_step":-20031,
                          "open_id":"ooMFf49U29cPdtjAwNsEZN06ZVOw",
                          "mobile":"18264188618",
                          "total_step":-37728
                      },
                      {
                          "mobile":"15533595488",
                          "total_step":-31186,
                          "open_id":"ooMFf406GaXMyedF44VcruJ-OrMk",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/fAQWJhZt4p25xCvVtdj2DBicm0R6YdmBXxe5ic3UniaaHb2K2ZRrDgWibk0Jp1zx9Gr5LTZZKzrTuNNdWsHpicWVJHQ/132",
                          "user_name":"宁翔",
                          "today_step":-18450
                      },
                      {
                          "user_name":"玻璃鱼",
                          "today_step":-18281,
                          "mobile":"13831578894",
                          "total_step":-39512,
                          "open_id":"ooMFf48d5tJEav9e0N4nwYwCXQqQ",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/yibuibuL1rMx0uKm3sJKrPwic5moqUqgq7fh0RQcG7tEAiaAia3CKq1euz8mQDichrP1GXqj40CFeibXiblg0wicbo8M4bQ/132"
                      },
                      {
                          "open_id":"ooMFf4wiHPXW1azSHf898D-6VuPs",
                          "total_step":-45000,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/caUgVMmqBGloaE9l0prAULIPnva4UGILNJW3Jkkia2h7ypDtfLCCblQgdM3E6iahibPoAicENcdVslXtnm0icWichAzg/132",
                          "today_step":-16936,
                          "mobile":"13230822852",
                          "user_name":"秀秀"
                      }
                  ]
              },
              {
                  "t":{
                      "id":3,
                      "teamNo":"NO3",
                      "teamName":"战队3",
                      "status":0,
                      "createdAt":"2022-04-25 10:05:31.000",
                      "updatedAt":"2022-04-25 10:05:31.000"
                  },
                  "topN":[
                      {
                          "user_name":"小帮，帮看，帮跑",
                          "today_step":-37632,
                          "total_step":-84992,
                          "mobile":"18059647007",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epXnSNIxzE1bkoqDO2FR8CKP5J4rsZuPwIborCmeNHdWkLgLXaHadicV0p0lUcN1ib3Whx6PKibcYicTA/132",
                          "open_id":"ooMFf43a5WiC_-9KfuyqV2zXhHNA"
                      },
                      {
                          "user_name":"双塔",
                          "today_step":-36851,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIdRzJ65COm8pMY4TW1hPFa3IM0IOExmiaKS3AKnJhmU7V8H8icYk3oviaBYNgqiaTwaKrw21tI9bNSdQ/132",
                          "mobile":"13903324410",
                          "open_id":"ooMFf49zQ7YzFGySvs3GCTGeJeEo",
                          "total_step":-36851
                      },
                      {
                          "mobile":"13831993159",
                          "today_step":-36529,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKq510bhIySL1vic1702sCTSGnF7WVZmaj9CKlsRTGUzK8Wq6vs928HvcAH5DicMuUhPF33trUtYshw/132",
                          "user_name":"\uD83C\uDF51",
                          "open_id":"ooMFf4zu-Iyt8ePzYq29C9S9WAvg",
                          "total_step":-71586
                      },
                      {
                          "mobile":"13623292260",
                          "today_step":-35751,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIJ7qNgcOodXLNypQYfbjs2f22ZqGxmn0Qg1p7vhyQXxzrfr5hF2QkTKIUGsQhJxibiappSIc1Kptuw/132",
                          "total_step":-97352,
                          "open_id":"ooMFf4zda8eBuKoSdM8Avbo5P4Tc",
                          "user_name":"周"
                      },
                      {
                          "user_name":"夏目",
                          "total_step":-34782,
                          "mobile":"13123322735",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/zYNm1iay2BtD5n4ZDsykEQCH25QMiaLcXYt5RTgHR1sm5rhhrFKvU6ibbzicic2SCh84PSo0ibNFBJlSnHGObf2Tytew/132",
                          "open_id":"ooMFf4-hIXJ4rbjZFyWIoOwAu3Ak",
                          "today_step":-34782
                      },
                      {
                          "today_step":-33828,
                          "user_name":"平凡",
                          "total_step":-103530,
                          "open_id":"ooMFf43Pn3w8-CvU0_xpzcb0DKUA",
                          "mobile":"13292105292",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Yicib1XP4ia9VHOVjFOwqm63D5DnWWAovgHnJEvRKYiagRGibOo2rClh6vjucJXzDEibDXzDpRdc4kbicteicNuicvs3icXQ/132"
                      },
                      {
                          "open_id":"ooMFf43mNSvPRqzsUsF_xLB7zvn4",
                          "mobile":"13328348256",
                          "total_step":-74971,
                          "today_step":-33602,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/H9O9fay6booAUibviaKyZPohp04ibN9UJ1EekTVePxGfcO1lFaWFaibtQRyetfYmAlCf6OuR5pQVyl300zHm7dGn8g/132",
                          "user_name":"花\uD83C\uDF38"
                      },
                      {
                          "user_name":"阿伟",
                          "open_id":"ooMFf4ylw-wzKjteLKsEd7RnbZ7E",
                          "total_step":-94090,
                          "today_step":-32374,
                          "mobile":"13803125520",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eq8qpSvBoZz82yN9WPFsVenZREdVvsqp0wpibTRvQI82jU0HRhLAPnIb31fzJl722pOS9l1ibRYxPEw/132"
                      },
                      {
                          "open_id":"ooMFf4ysV5o5ngX6HE-OQEbzWLh8",
                          "total_step":-92230,
                          "today_step":-31032,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM5Ies5taLicaZEYahEek0icpfjRxl9AUx8gRI36xxG8e1apxiad9UwvOGnRR6u2RKibPibFKzGMzUcYQrw/132",
                          "user_name":"方小雯",
                          "mobile":"13599579903"
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/4vNosr1iajSgRv7Lp9H1kDyVJSsn6Tkbv9Fj2p6ea7HRvT9h6RBt2x1y2cqctOmQLU0Ysb7KiaKWubvPnoxmyPng/132",
                          "open_id":"ooMFf4x4Do-A7q2UIZg_6dwO6haU",
                          "total_step":-69926,
                          "mobile":"13105022827",
                          "today_step":-30293,
                          "user_name":"岁月静好"
                      }
                  ]
              },
              {
                  "t":{
                      "id":4,
                      "teamNo":"NO4",
                      "teamName":"战队4",
                      "status":0,
                      "createdAt":"2022-04-25 10:05:31.000",
                      "updatedAt":"2022-04-25 10:05:31.000"
                  },
                  "topN":[
                      {
                          "mobile":"18953147729",
                          "open_id":"ooMFf4zuV3oTV-9JN11lNE8bdetM",
                          "total_step":-83288,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/ZpibNKW1I3lE5ibpqeUrQOSj2WoZh0sLib6Pj3AmicWLq9mtQhGr2qpquDDjGiaxEv9IL3jDdogPKqzOVohFYYB95KA/132",
                          "today_step":-83288,
                          "user_name":"山水之间"
                      },
                      {
                          "total_step":-60578,
                          "open_id":"ooMFf47WorzlavC1VamPoPfAUcwc",
                          "user_name":"落寞的荒年",
                          "today_step":-60578,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLxAc1Npbp9uUIAvO8DyNDib5VTibWGYuicOFQ448GdbEHoicrMVzFFerxqnnGQ21GIPOyYn6Rribf9Klw/132",
                          "mobile":"13513260893"
                      },
                      {
                          "icon_url":"",
                          "today_step":-33863,
                          "total_step":-105000,
                          "mobile":"13831028123",
                          "user_name":"张文武",
                          "open_id":"ooMFf43vkv_XRGFRPFCyje1Swz1M"
                      },
                      {
                          "user_name":"王晨曦",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/qpRNrCzawGGeP8U8Z5jQLVaLa1Rxic4von2IvgTDm3On2GjHSU68UQwoGxFn0qWSGBLLCaNLAAZsZPrvQajJ2Bg/132",
                          "open_id":"ooMFf49x5E3qTcJb7uTZ5BpkebXs",
                          "mobile":"15537010795",
                          "today_step":-32113,
                          "total_step":-85832
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/hIxY8K1PHwZojoAataRhGsiaL1tJysnbYoNFfJxibW4Ox2Tj6IY67fj4h6MyoBt6dfU8Ccibic5pg29zqz0EcD68GA/132",
                          "today_step":-30025,
                          "total_step":-79724,
                          "mobile":"15205183150",
                          "open_id":"ooMFf491Iir11h_bwqubAmWQok0c",
                          "user_name":"岁月如梭"
                      },
                      {
                          "open_id":"ooMFf4x3lJ_-kv_5ZGrW7PXdypjA",
                          "today_step":-28223,
                          "total_step":-75000,
                          "mobile":"18042015772",
                          "user_name":"掩于岁月",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqnkJjNMI9GI2eVQPMl6IXdkSYk346BU2YMNKhv4nyiapDx3ZeNvf2ANry7A8PrHq2A6nSkw1aT8Iw/132"
                      },
                      {
                          "today_step":-25006,
                          "mobile":"13231098863",
                          "open_id":"ooMFf4_7PGxRYruj6RvmlhkpltGQ",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqnchGaR5IbndiboibxGPHn7hDVbC8kU6nNZAGrZfBv3dxEwTkeVSUcicJSJB3pkWhlFgtQrHcGOFqQQ/132",
                          "user_name":"扬帆启航",
                          "total_step":-67746
                      },
                      {
                          "total_step":-60000,
                          "today_step":-23112,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/iceeficauWPXLSGR2Efr3FSicn1HZJvn0fEwrzJBLNdLIibpuP54XM1NowForchKfoEGclHV1DuUCw8oLVcEDHeBuw/132",
                          "user_name":"和",
                          "mobile":"13932088907",
                          "open_id":"ooMFf48UzsPgOPaLd1yHy6_IZ0xs"
                      },
                      {
                          "mobile":"13283432686",
                          "open_id":"ooMFf4-fnKKXs5Pf2N9JREALc52Y",
                          "user_name":"权少",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/868afDa0VXb5WRaO8Z6h1zhjHRicZhFGUCeO73sWWtqCjiafcjJ3sSSxtyNFXvDSaj2K6aZlVYvEMvdLO6ANaxIg/132",
                          "today_step":-21939,
                          "total_step":-41394
                      },
                      {
                          "mobile":"13731609272",
                          "user_name":"风中的彩铃",
                          "total_step":-55342,
                          "today_step":-21302,
                          "open_id":"ooMFf4zbbguT_ll5cjRrQkzBBxOc",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/N04Oan9FA40ytTeZAlZ2hNnrvuKuibggeicaLsibgIF8Q9P7NvPicXqv8yUMvtPzR57oT85pPicv0q3EmtZWADdJDFg/132"
                      }
                  ]
              },
              {
                  "t":{
                      "id":5,
                      "teamNo":"NO5",
                      "teamName":"战队5",
                      "status":0,
                      "createdAt":"2022-04-25 10:05:31.000",
                      "updatedAt":"2022-04-25 10:05:31.000"
                  },
                  "topN":[
                      {
                          "mobile":"13932434013",
                          "open_id":"ooMFf47sWY1dvipF7IAfUdY4UacQ",
                          "total_step":-141325,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/w53X2zzOWSLhYscDrEYicDjzXRzwPjyd8JubtMOPXEXPZZhtaeia8PGJL5NuUD4rqr2UriclSicjzbBlADzKJrAibbw/132",
                          "user_name":"幽若谷",
                          "today_step":-50915
                      },
                      {
                          "total_step":-110923,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epx3CDc9CQvibxSFsDD8zwASUnaOHr05c0xJkcBT9Jf2LVOje7riapbPsEeyJPhN7zNNLhcu3M8Uz2A/132",
                          "open_id":"ooMFf4xzsB1lO4e8_EDpuQBnmhYw",
                          "user_name":"相逢一笑",
                          "mobile":"18631406989",
                          "today_step":-45597
                      },
                      {
                          "today_step":-38964,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLgsx7Jic0oLz7IOc1yVMFF9WNdFNvvaX3icicBJSCUqiaUCfmNOOibIV98OIYRZmibIAt63Ye3jTM6ia2iaA/132",
                          "total_step":-84850,
                          "open_id":"ooMFf45g-ME8zgS66s5kwCR0wtas",
                          "user_name":"联合",
                          "mobile":"13932415082"
                      },
                      {
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKxR1xib3oSvKlO7gWEZW4aAWnBlrG9WyjMPfbvKicibogJZvoIWeJwuFQsqkFAVBPY23GicpuuicNM8ibw/132",
                          "total_step":-98800,
                          "open_id":"ooMFf4_ge_L4sEKqYqlePTsL-YJI",
                          "mobile":"15903144459",
                          "today_step":-30690,
                          "user_name":"X k L C F"
                      },
                      {
                          "mobile":"18019575597",
                          "open_id":"ooMFf4yfz3jzbMyprnc3euyyOxrc",
                          "today_step":-30025,
                          "total_step":-69913,
                          "user_name":"半世浮华",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/KBJO5q2JNib09HzMERgNI5C2QmMDT1IV6qt1dozfwbRO31llmic7BdRtW0s1G23TrE6RZMPlHyWk46K2hSMb7Rsw/132"
                      },
                      {
                          "mobile":"15324068088",
                          "today_step":-28317,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLtjqoUNGKOVQGqzjWcJticePic1pvK8SXykkI4MeSfR5MHAwFVeTeuibdhsWxPibaI59Ult47n0W1eyA/132",
                          "total_step":-85000,
                          "open_id":"ooMFf4xZE3NLRhsedMNcMZ65mSfI",
                          "user_name":"dyx...."
                      },
                      {
                          "mobile":"15830321113",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIKzibSmweoUKrOgOCx4dVXsoTrSXib7hRawuZicNcAdhdgpkUQwXgTYSzln2SZpKyyVJVR3vpRQWVxw/132",
                          "today_step":-28156,
                          "total_step":-68872,
                          "open_id":"ooMFf447FMl8AAz36_0-Z1m-h03g",
                          "user_name":"老衲法号不戒"
                      },
                      {
                          "mobile":"13785380658",
                          "total_step":-51040,
                          "user_name":"虎视鹰扬",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/beG9Kn8SRRYJ8ibr9IUoibx4hXbF8jVKDF8olia8LpPNn54KKGJ2Ezr8WKqCvLQE4wZv6jCKXUbQY7Ir6q3TyddDQ/132",
                          "today_step":-27938,
                          "open_id":"ooMFf4-DlSveqtgOns4H_Gz1osx4"
                      },
                      {
                          "user_name":"杰",
                          "open_id":"ooMFf4wD_v_IPPCEpC9D0vjKEF9U",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoEia8b2zh1kx0pUmBMbdj26x3FMryrf3icPgMbEE4KPV1vFBrPyPWh8uJFlW8vHfC9GycT1vCpicTeA/132",
                          "today_step":-27090,
                          "total_step":-60539,
                          "mobile":"15922161623"
                      },
                      {
                          "open_id":"ooMFf4yDMBSl3pIHS5NShMkdTAdI",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/ueDfxKiaib9SnurP54VZYwibUaUr0hCC0rYEOvAwJy6ZbicaOeOAhPZU11KmV1oKFbgFicshldjpFbM3S1KoWuUxtkw/132",
                          "total_step":-47724,
                          "today_step":-25630,
                          "user_name":"岳宏光",
                          "mobile":"15304541317"
                      }
                  ]
              },
              {
                  "t":{
                      "id":6,
                      "teamNo":"NO6",
                      "teamName":"战队6",
                      "status":0,
                      "createdAt":"2022-04-25 10:05:31.000",
                      "updatedAt":"2022-04-25 10:05:31.000"
                  },
                  "topN":[
                      {
                          "open_id":"ooMFf4-kAebC5-JPwmqoxWx6VDHo",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/icKGPZ8Gu6zLtOemdsMFxIvVfw9eGmic7aAXdUZ0Ewt3icksfoWXh2gtmsRxaAEwj9a0hibz0IrV0ksACcicGt4lqgw/132",
                          "total_step":-105000,
                          "user_name":"A00晓冉名车俱乐部",
                          "mobile":"15632785679",
                          "today_step":-62355
                      },
                      {
                          "open_id":"ooMFf4xhjCv-G3OY9syCo91cEsSA",
                          "today_step":-42866,
                          "mobile":"13803240318",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erdzuL8ibILIfczcJPVSKDh4ManvnCeJVtpSEVzAnKTOftNdAjAico79tahQqpt3iaSf6SqmK9y0OSUg/132",
                          "total_step":-111440,
                          "user_name":"观鱼"
                      },
                      {
                          "total_step":-118185,
                          "today_step":-40099,
                          "open_id":"ooMFf4xwl92GgnasTiobXfcdlKMU",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL2uBsGc2ZrMzgvjlmCoPCq1njwEvU75JBhauLZIZyJJHON9uyBTse1XwXaxorfMyzEQWMIYVicFqQ/132",
                          "user_name":"黄志强",
                          "mobile":"15703301581"
                      },
                      {
                          "today_step":-38453,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/YI5msfoQs0lvOFhPdGib8Yq8fvZbKNYvbicdZDEkIpxxx9d4r70eTK0u7wS7t7bLEzZnXjONsqZEQ2QpSG4icByvA/132",
                          "total_step":-133814,
                          "user_name":"刘振河",
                          "open_id":"ooMFf49Z0UP6uMSI4yto3gWQ2Vws",
                          "mobile":"13731786898"
                      },
                      {
                          "today_step":-35125,
                          "mobile":"13731799033",
                          "open_id":"ooMFf49fQfR5kqPkWlaK1jVTsAeY",
                          "user_name":"深蓝",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEKnLmWeMz9vTB22fgM915bLianulu9mca9ApxktaRrqQTTPLJCpibEPV2xDrSKLNtCBkwkOBpGsEaHw/132",
                          "total_step":-71223
                      },
                      {
                          "mobile":"13933675678",
                          "user_name":"月是故乡明",
                          "total_step":-80140,
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/7M35dvMChrHpQhBCRPS2b4sDMx0CXQviclCFhAwS0wLyJFORF1HRDHIsjdZqcxPTfO7wd8TO6atjosSJvgqI7XA/132",
                          "today_step":-30000,
                          "open_id":"ooMFf40unhYjKHAIVp-gt5Jgu1Qk"
                      },
                      {
                          "total_step":-90000,
                          "open_id":"ooMFf42QtdgrCqA6pjwz5xf677t0",
                          "user_name":"自然",
                          "today_step":-30000,
                          "mobile":"13703358070",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIkkg9icSGleYB9z06eYN7Y7MGg6xq4Kfw15B8TuamuprQYquJSKHMsuGux5wdxLcufpMvsep1LzaQ/132"
                      },
                      {
                          "today_step":-29259,
                          "user_name":"大道至简",
                          "open_id":"ooMFf44oJheCIKE-oLmnpgSzDZM0",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/Fbw5C4CguVYaJumnqHguDOxgeicN9EicGFuCRS1jkGsU7YNqeXF8r1UeZxXnmjibfB65zdGjSC8ticMqGUCOibUeuqw/132",
                          "mobile":"13932717032",
                          "total_step":-67763
                      },
                      {
                          "today_step":-28699,
                          "open_id":"ooMFf494d9BeWRKkbnmDhxbrxLkk",
                          "mobile":"15503189358",
                          "user_name":"感悟",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/1o1TKDfl8I95sqicwb55zZTE3micD9MVZ60icJQYBiapTdxDzMMbhutAicOVITkRegTTelJDFLBAaKGL8tYxHlYxxicA/132",
                          "total_step":-63374
                      },
                      {
                          "user_name":"猪猪侠1206",
                          "total_step":-79964,
                          "mobile":"15632706116",
                          "icon_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/lE1u95C1WYDwM9HhHwrFMNOvh8nHyTjGEOughqyPbyaN1nOhTfm6jiatibKaX0amnxJkec2Xibh1TeNXNDic5ZySCA/132",
                          "open_id":"ooMFf44TXOAaDvkiX9xJmi-ABkgE",
                          "today_step":-26741
                      }
                  ]
              }
          ]
      }
        console.log(res)
        setData(res.data)
      })
  } 

  useEffect(() => {
      getData(1)
  }, [])
  
  return (
    <div style={{ padding: '30px' }}>
      <div style={{ padding: '30px', background: 'white' }}>
          {
            data.map(v=>{
              return <div>
                <p style={{'width':'515px','display':'flex','justifyContent':'space-between'}}><span>{v.t.teamName}</span><span>{v.t.createdAt}</span></p>
                <table style={{'margin':"10px 0"}}>
                    <thead>
                      <tr style={{'width':'800px'}}>
                          <td style={{'width':'50px'}}>排名</td>
                          <td style={{'width':'50px'}}>头像</td>
                          <td style={{'width':'150px'}}>用户名</td>
                          <td style={{'width':'200px'}}>手机号</td>
                          <td style={{'width':'200px'}}>捐赠步数</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        v.topN.map((a,i)=>{
                          return <tr>
                            <td>{i+1}</td>
                            <td>
                              <img src={a.icon_url} style={{'height':'20px','width':'20px','borderRadius':'20px'}} />  
                            </td>
                            <td>{a.user_name}</td>
                            <td>{a.mobile}</td>
                            <td>{a.today_step}</td>
                          </tr>
                        })
                      }
                    </tbody>
                </table>
              </div>
            })
          }
      </div>
    </div>
  )
}

export default App
