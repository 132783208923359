import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Table, Upload, Popconfirm } from 'antd'
import { DownloadOutlined, CloudDownloadOutlined, UploadOutlined } from '@ant-design/icons'
import axios from '@/utils/axios'
import { baseUrl, audioUrl } from '@/utils/config'
import store from '@/redux/store'


class App extends React.Component {
    constructor() {
        super()
        this.state = {
            columns: [
                {
                    title: '录音列表',
                    dataIndex: 'createdAt',
                    render: (text,record,index) => <a>录音{index+1}</a>,
                },
                {
                    title: '操作',
                    dataIndex: 'id',
                    render: text => 
                        sessionStorage.getItem('Completed') != 'true'?
                        <Popconfirm
                        title="您确认删除这张图片么"
                        onConfirm={e => {axios.delete('/api/core/record/'+text).then(res=>this.getdata(this.state.idprops))}}
                        okText="Yes"
                        cancelText="No"
                    ><Button type='link'>删除录音</Button></Popconfirm>
                    :null
                },
            ],
            data: [],
            defaultsrc: {},
            showbool: true,
            audioTime: 0,
            datas: {},
            rowId: 0,
            idprops: 0,
            FilelistAdd: []
        }
    }

    getdata = (id) =>{
        axios.get('/api/core/record/list', {
            params: {
                queryCondition: "EQ:taskId=" + id
            }
        })
        .then(res => {
            res.data.data.forEach(v => {
                v.createdAt = v.createdAt.split('.')[0]
            });
            this.setState({
                data: res.data.data,
                defaultsrc: JSON.stringify(res.data.data) != '[]' ? res.data.data[0] : {},
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        let self = this;
        setTimeout(function () {
            if (self.state.idprops != nextProps.datas.id) {
                self.setState({
                    datas: JSON.stringify(self.state.datas) != '{}' ? self.state.datas : nextProps.datas,
                    idprops: nextProps.datas.id
                }, () => {
                    if (nextProps.datas.id != undefined) {
                        self.getdata(nextProps.datas.id)
                    }
                })
            }
        }, 0)

    }

    componentDidMount() {

    }

    begin = () => {//开始
        document.getElementById('audio').play()
        this.setState({
            showbool: false,
            audioTime: this.audioTime(document.getElementById('audio'))
        })
    }

    stop = () => {//暂停
        document.getElementById('audio').pause()
        this.setState({
            showbool: true
        })
    }

    audioTime = (audio) => {
        let time = parseInt(audio)
        let shi = time / 60 / 60
        let fen = time / 60 % 60
        let miao = time % 60
        shi = shi > 9 ? parseInt(shi) : '0' + parseInt(shi)
        fen = fen > 9 ? parseInt(fen) : '0' + parseInt(fen)
        miao = miao > 9 ? parseInt(miao) : '0' + parseInt(miao)
        return shi >= 1 ? shi + ":" + fen + ":" + miao : fen + ":" + miao
    }

    uploadProps = {
        onRemove: (file) => {
            this.setState( {
                FilelistAdd: []
            })
            return []
        },
        beforeUpload: (file) => {
            console.log(file)
            const formData = new FormData()
            formData.append('file', file)
            axios.post('/api/common/upload',formData,{
                headers: { 'Content-Type': 'multipart/form-data' },
                type:'audio/mp3'
            })
            .then(res=>{
                console.log(res)
                axios.post('/api/core/record', {
                    audio: res.data[0].fullUrl,
                    taskId: this.state.idprops
                })
                .then(res=>{
                    this.getdata(this.state.idprops)
                })
            })
            return false
        },
        showUploadList:false,
        accept: ['.mp3','.wav'],
    }

    render() {
        return (
            <>
                <Col span='24' style={{ border: '1px solid rgba(233, 233, 233, 1)', borderRadius: '5px', marginTop: '20px' }}>
                    <div style={{ padding: '10px 20px', background: 'rgba(250, 250, 250, 1)', borderBottom: '1px solid rgba(233,233,233, 1)', display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                            录音素材<CloudDownloadOutlined />{/* 未加图片 标记 */}
                        </span>
                        <span>
                            {/* <DownloadOutlined />
                            录音下载 */}
                            {/*  <Upload {...this.state.props}>
                                <Button icon={<UploadOutlined />}>上传录音</Button>
                            </Upload> */}
                            {
                                sessionStorage.getItem('Completed') != 'true'?
                                    <Upload {...this.uploadProps}  >
                                        <Button icon={<UploadOutlined />}>上传录音</Button>
                                    </Upload>
                                :null
                            }
                        </span>
                    </div>
                    <Row style={{ padding: '10px 20px' }}>
                        <Col span='8' style={{ padding: '10px 20px' }}>
                            <Table
                                key='1'
                                columns={this.state.columns}
                                dataSource={this.state.data}
                                pagination={false}
                                scroll={{ x: true, y: '200px' }}
                                onRow={(record, index, e) => {
                                    return {
                                        onClick: event => {// 点击行
                                            let tr = Array.prototype.slice.call(document.getElementsByTagName('tr'))
                                            tr.forEach((v, i) => {
                                                if (i - 2 == index) {
                                                    v.style.background = '#aaa'
                                                    v.style.color = '#47bcff'
                                                } else {
                                                    v.style.background = 'none'
                                                    v.style.color = '#000'
                                                }
                                            })

                                            this.setState({
                                                defaultsrc: record,
                                                rowId: record.key,
                                                showbool: true
                                            }, () => {
                                                setTimeout(() => {
                                                    this.setState({
                                                        audioTime: this.audioTime(document.getElementById('audio'))
                                                    })
                                                }, 100)
                                            })
                                        },
                                    };
                                }}
                                rowClassName={this.setRowClassName}
                            />
                        </Col>
                        <Col span='16' style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                            <p style={{ fontWeight: '600' }}>{JSON.stringify(this.state.defaultsrc) != '{}' ? this.state.defaultsrc.createdAt.split('.')[0] : null}</p>
                            <Row>
                                {/*<Col span="4">
                                    {
                                        this.state.showbool?
                                        <PlayCircleFilled style={{fontSize:'30px',color:'#aaa'}} onClick={this.begin} />:
                                        <PauseCircleFilled style={{fontSize:'30px',color:'#aaa'}} onClick={this.stop} />
                                    }
                                </Col>*/}
                                <Col span="24">
                                    {/*<span>{ this.state.audioTime }</span>
                                        <span></span>*/}
                                    <div>
                                        {
                                            JSON.stringify(this.state.defaultsrc) != '{}' ? <audio style={{ width: '100%', outline: 'none' }} controls id='audio' src={audioUrl + this.state.defaultsrc.audio}></audio> : null
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}

export default App