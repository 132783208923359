
import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, useHistory } from 'react-router-dom'


import ProjectCreate from './project/ProjectCreate'
import ProjectList from './project/ProjectList'
import ProjectEdit from './project/ProjectEdit'
import ProjectLook from './project/ProjectLook'

import ControlList from './control/ControlList'
import ControlPeople from './control/ControlPeople'
import ControlRole from './control/ControlRole'
import ControlPeopleDetail from './control/ControlPeopleDetail'

import SetLocation from './set/SetLocation'
import SetProduct from './set/SetProduct'
import SetShop from './set/SetShop'
import SetTarget from './set/SetTarget'
import SetProdectDetail from './set/SetProdectDetail'
import SetShopDetail from './set/SetShopDetail'
import SetLocationDetail from './set/SetLocationDetail'
import SetTargetDetail from './set/SetTargetDetail'
import SetProjectTemplate from './set/SetProjectTemplate'
import SetAppTemplate from './set/SetAppTemplate'
import SetComputeTemplate from './set/SetComputeTemplate'
import SetProjectTemplateDetail from './set/SetProjectTemplateDetail'
import SetAppTemplateDetail from './set/SetAppTemplateDetail'
import SetStandard from './set/SetStandard'
import SetStandardItem from './set/SetStandardItem'
import SetRegion from './set/SetRegion'

import Log from './log/Log'

import DataAgency from './data/DataAgency'
import DataAgencyDSR from './data/Agency/DataAgencyDSR'
import DataAgencychenlie from './data/Agency/DataAgencychenlie'
import DataAgencymutangchun from './data/Agency/DataAgencymutangchun'
import DataAgencyDSRType from './data/Agency/DataAgencyDSRType'
import DataAgencydaili from './data/Agency/DaraAgencydaili'

import DataVisitor from './data/DataVisitor'
import DataVisitorDSRType from './data/Visitor/DataVisitorDSRType'
import DataVisitorDSR from './data/Visitor/DataVisitorDSR'
import DataVisitorchenlie from './data/Visitor/DataVisitorchenlie'
import DataVisitormutangchun from './data/Visitor/DataVisitormutangchun'

import DataFirst from './data/DataFirst'
import DataFirstuser from './data/First/DataFirstuser'
import DataFirstusertask from './data/First/DataFirstusertask'

import DataSecond from './data/DataSecond'

function App(props) {
  return (
    <>
      {console.log(1)}
      <Route path="/home/project/create" component={ProjectCreate} />
      <Route path="/home/project/list" component={ProjectList} />
      <Route path="/home/project/edit/:id" component={ProjectEdit} />
      <Route path="/home/project/look/:id" component={ProjectLook} />

      <Route path="/home/control/list" component={ControlList} />
      <Route path="/home/control/role" component={ControlRole} />
      <Route path="/home/control/people" component={ControlPeople} />
      <Route path="/home/control/peopledetail/:id" component={ControlPeopleDetail} />
      <Route path="/home/control/xuzhongdetail/:id" component={ControlPeopleDetail} />

      <Route path="/home/set/product" component={SetProduct} />
      <Route path="/home/set/shop" component={SetShop} />
      <Route path="/home/set/target" component={SetTarget} />
      <Route path="/home/set/location" component={SetLocation} />
      <Route path="/home/set/productdetail/:id" component={SetProdectDetail} />
      <Route path="/home/set/shopdetail/:id" component={SetShopDetail} />
      <Route path="/home/set/targetdetail/:id" component={SetTargetDetail} />
      <Route path="/home/set/locationdetail/:id" component={SetLocationDetail} />
      <Route path="/home/set/projecttemplate" component={SetProjectTemplate} />
      <Route path="/home/set/apptemplate" component={SetAppTemplate} />
      <Route path="/home/set/computetemplate" component={SetComputeTemplate} />
      <Route path="/home/set/projecttemplatedetail/:id" component={SetProjectTemplateDetail} />
      <Route path="/home/set/apptemplatedetail/:id" component={SetAppTemplateDetail} />
      <Route path="/home/set/standard" component={SetStandard} />
      <Route path="/home/set/standardItem/:id" component={SetStandardItem} />
      <Route path="/home/set/region" component={SetRegion} />
      {/* <Route path="/home/set/standardItemdetail/:id" component={SetStandardItemdetail} /> */}

      <Route path="/home/log" component={Log} />

      <Route path="/home/data/visitor" component={DataVisitor}/>
      <Route path="/home/data/visitorDSRType/:projectid" component={DataVisitorDSRType}/>
      <Route path="/home/data/visitorDSR/:projectid/:DSR" component={DataVisitorDSR}/>
      <Route path="/home/data/visitorchenlie/:projectid" component={DataVisitorchenlie}/>
      <Route path="/home/data/visitormutangchun/:projectid" component={DataVisitormutangchun}/>
      
      <Route path="/home/data/agency" component={DataAgency}/>
      <Route path="/home/data/agencyDSR/:projectid/:DSR" component={DataAgencyDSR}/>
      <Route path="/home/data/agencychenlie/:projectid" component={DataAgencychenlie}/>
      <Route path="/home/data/agencymutangchun/:projectid" component={DataAgencymutangchun}/>
      <Route path="/home/data/agencydaili/:projectid" component={DataAgencydaili}/>
      <Route path="/home/data/agencyDSRType/:projectid/:agentid" component={DataAgencyDSRType}/>

      <Route path="/home/data/first" component={DataFirst}/>
      <Route path="/home/data/firstuser/:projectid" component={DataFirstuser}/>
      <Route path="/home/data/firstusertask/:audit2id" component={DataFirstusertask}/>
    </>
  )
}

export default App